import BusinessMemberRep from "reps/BusinessMemberRep";

const getBusinessMemberByBusinessMemberGuid = (
  businessMembers: BusinessMemberRep.Complete[],
  businessMemberGuid: string
) => {
  return businessMembers.find((businessMember) => businessMember.guid === businessMemberGuid);
};

export default getBusinessMemberByBusinessMemberGuid;
