import PublicLayout from "layouts/PublicLayout";
import QRCode from "qrcode.react";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import Text from "ui/typography/Text";
import { useIsDesktop } from "utils/device/useMediaQuery";

import AddToHomeScreenView from "../AddToHomeScreenView";

import styles from "./AddToHomeScreenPage.module.scss";

const AddToHomeScreenPage = () => {
  const isDesktop = useIsDesktop();

  return (
    <PublicLayout header={<PublicLayout.Header />}>
      <div className={styles.container}>
        {isDesktop && (
          <>
            <div className={styles.qrGroupContainer}>
              <div className={styles.qrContainer}>
                <QRCode
                  fgColor={colors.purple[500]}
                  value={`${window.location.origin}/mobile`}
                  className={styles.qrCode}
                />
              </div>

              <Text weight="medium" size={16} color={colors.purple[500]}>
                To get started, <strong>scan this code</strong> with your phone to open Highbeam
              </Text>
            </div>
            <Divider className="my-8" />
          </>
        )}

        <AddToHomeScreenView />
      </div>
    </PublicLayout>
  );
};

export default AddToHomeScreenPage;
