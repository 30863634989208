import {
  getValidator,
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import TextInput, { useTextInput } from "ui/inputs/TextInput";

type Props = {
  selectedCountry: string;
};

const SortCode: React.FC<Props> = ({ selectedCountry }) => {
  const [internationalBankingInfo, setInternationalBankingInfo] = useRecoilState(
    internationalBankingInfoState
  );
  const sortCode = useTextInput({
    initialValue: internationalBankingInfo.sortCode?.value,
    onChange: getValidator(selectedCountry, INTERNATIONAL_WIRE_INPUTS.SORT_CODE),
  });

  useEffect(() => {
    setInternationalBankingInfo({
      ...internationalBankingInfo,
      sortCode: {
        inputName: INTERNATIONAL_WIRE_INPUTS.SORT_CODE,
        value: sortCode.value,
        isValid: sortCode.isValid,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortCode.value, sortCode.isValid]);

  return (
    <TextInput
      type="text"
      value={sortCode.value}
      onChange={(value) => {
        sortCode.onChange(value);
      }}
      label={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.SORT_CODE].label}
      onBlur={sortCode.onBlur}
      hasError={sortCode.hasError}
      errorMessage={
        INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.SORT_CODE].errorMessage
      }
    />
  );
};

export default SortCode;
