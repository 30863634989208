import BusinessAddressRep from "reps/BusinessAddressRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BusinessAddressApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(businessGuid: string): Promise<BusinessAddressRep | null> {
    const url = `/businesses/${businessGuid}/address`;
    return await this.api.get<BusinessAddressRep>(url);
  }

  async update(
    businessGuid: string,
    updater: BusinessAddressRep.Updater
  ): Promise<BusinessAddressRep> {
    const url = `/businesses/${businessGuid}/address`;
    return (await this.api.put<BusinessAddressRep>(url, updater))!;
  }
}
