import { X } from "@phosphor-icons/react";
import IconWithTooltip from "components/common/IconWithTooltip";
import CreditComparisonSnapshotRep from "reps/CreditComparisonSnapshotRep";
import colors from "styles/colors";
import { Paragraph } from "ui/typography";
import Text from "ui/typography/Text";
import { getDurationFromPeriod } from "utils/date";
import { roundEpsilon } from "utils/math";
import { getDollarsFromCents } from "utils/money";
import { formatAmount, pluralize } from "utils/string";

import getCreditProviderLogo from "../../utils/getCreditProviderLogo";

import CreditComparisonColumnLayout from "./CreditComparisonColumnLayout";
import styles from "./CreditComparisonTable.module.scss";

const DisplayApr: React.FC<{ apr: number | null }> = ({ apr }) => {
  if (!apr)
    // The generic "Could not calculate"
    return (
      <div className={styles.cellContent}>
        N/A{" "}
        <IconWithTooltip
          className="ml-2"
          tooltip={
            <Paragraph>
              We cannot calculate the APR for payback periods longer than 2 years. This usually
              happens when the offer details or revenue projections are incorrectly entered.
            </Paragraph>
          }
        />
      </div>
    );

  const aprPercent = roundEpsilon(apr * 100, 2);
  const aprCopy = aprPercent <= 500000 ? `${aprPercent}%` : "Over 5,000%";
  return <>{aprCopy}</>;
};

/**
 * Pluralize, but return a blank string if the amount is zero
 */
const pluralizeOmitZero = (amount: number, noun: string, nounPlural?: string) =>
  amount ? pluralize(amount, noun, nounPlural) : "";

/**
 * Converts `Duration` to eg: "1 year 2 months 23 days"
 */
const convertDurationToYMDString = (duration: plugin.Duration) => {
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  // prettier-ignore
  return `${pluralizeOmitZero(years, "year")} ${pluralizeOmitZero(months, "month")} ${pluralizeOmitZero(days, "day")}`.trim();
};

type Props = CreditComparisonSnapshotRep.Calculation;

const CreditComparisonColumn: React.FC<Props> = ({
  providerName,
  fundingAmount,
  feeAmount,
  repaymentPeriod,
  apr,
  averageWeeklyRepayment,
  remittanceRate,
}) => {
  const xIcon = <X size={14} color={colors.red[500]} />;
  const repaymentDuration = getDurationFromPeriod(repaymentPeriod);
  const isRepaymentGreaterThan2Years = repaymentDuration.asDays() > 730;

  const fundingAmountDollars = formatAmount(getDollarsFromCents(fundingAmount), {
    withCents: false,
  });
  const effectiveFeeDollars = formatAmount(getDollarsFromCents(feeAmount), { withCents: false });
  const effectiveFeePercent = roundEpsilon((100 ** 2 * (feeAmount / fundingAmount)) / 100, 2);
  const totalPaybackAmountDollars = formatAmount(
    getDollarsFromCents(fundingAmount) + getDollarsFromCents(feeAmount),
    { withCents: false }
  );
  const averageWeeklyRepaymentAmount = formatAmount(getDollarsFromCents(averageWeeklyRepayment), {
    withCents: false,
  });

  return (
    <CreditComparisonColumnLayout
      rows={[
        <img
          key={`${providerName}-icon`}
          src={getCreditProviderLogo(providerName)}
          alt="Credit provider icon"
          className={styles.icon}
        />,

        <>{fundingAmountDollars}</>,

        <>
          {effectiveFeeDollars}
          <Text color={colors.grey[400]} size={12}>
            {effectiveFeePercent}%
          </Text>
        </>,

        <>{totalPaybackAmountDollars}</>,

        <>
          {isRepaymentGreaterThan2Years ? (
            // Does calculate beyond a two year repayment period
            <Text color={colors.grey[400]} size={12}>
              {"Payback period too long"}
            </Text>
          ) : (
            <DisplayApr apr={apr} />
          )}
        </>,

        <>
          {isRepaymentGreaterThan2Years ? (
            <>{"Over 2 years"}</>
          ) : (
            <>
              {Math.ceil(repaymentDuration.asWeeks()) + " weeks"}

              <Text color={colors.grey[400]} size={12}>
                {convertDurationToYMDString(repaymentDuration)}
              </Text>
            </>
          )}
        </>,

        <>
          {averageWeeklyRepaymentAmount}
          <Text color={colors.grey[400]} size={12}>
            {remittanceRate}% of every payout
          </Text>
        </>,
        <>{xIcon}</>,
        <>{xIcon}</>,
        <>{xIcon}</>,
        <>{xIcon}</>,
        <></>,
      ]}
    />
  );
};

export default CreditComparisonColumn;
