import CreditComparisonSessionRep from "reps/CreditComparisonSessionRep";
import CreditComparisonSnapshotRep from "reps/CreditComparisonSnapshotRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CreditComparisonApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async createCreditComparisonSession(
    businessGuid: string | null
  ): Promise<CreditComparisonSessionRep.Complete | null> {
    const pathname = "/credit-comparison-sessions";
    const url = businessGuid ? pathname + `?businessGuid=${businessGuid}` : pathname;
    return await this.api.post<CreditComparisonSessionRep.Complete | null>(url);
  }

  async updateCreditComparisonSession(
    creditComparisonSessionGuid: string,
    body: CreditComparisonSessionRep.Updater
  ): Promise<CreditComparisonSessionRep.Complete | null> {
    const url = `/credit-comparison-sessions/${creditComparisonSessionGuid}`;
    return await this.api.put<CreditComparisonSessionRep.Complete | null>(url, body);
  }

  async createCreditComparisonSnapshot(
    creditComparisonSessionGuid: string,
    body: CreditComparisonSnapshotRep.Creator
  ): Promise<CreditComparisonSnapshotRep.Complete | null> {
    const url = `/credit-comparison-sessions/${creditComparisonSessionGuid}/credit-comparison-snapshots`;
    return await this.api.post<CreditComparisonSnapshotRep.Complete | null>(url, body);
  }

  async getLatestCreditComparisonSnapshot(
    creditComparisonSessionGuid: string
  ): Promise<CreditComparisonSnapshotRep.Complete | null> {
    const url = `/credit-comparison-sessions/${creditComparisonSessionGuid}/credit-comparison-snapshots`;
    return await this.api.get<CreditComparisonSnapshotRep.Complete | null>(url);
  }

  async calculateCreditComparison(
    creditComparisonSnapshotSlug: string
  ): Promise<CreditComparisonSnapshotRep.Calculation[] | null> {
    const url = `/credit-comparison-snapshots/${creditComparisonSnapshotSlug}/calculate`;
    return await this.api.get<CreditComparisonSnapshotRep.Calculation[] | null>(url);
  }
}
