import { MagnifyingGlass } from "@phosphor-icons/react";
import { ComponentProps, FC } from "react";
import TextInputV2 from "ui/inputs/TextInputV2";
import cn from "utils/tailwind/cn";

type Props = ComponentProps<typeof TextInputV2> & {
  wide?: boolean;
};

const SearchBar: FC<Props> = ({ wide = true, ...textInputProps }) => {
  return (
    <TextInputV2
      placeholder="Search for transaction"
      endAdornment={<MagnifyingGlass className="size-4 shrink-0 text-grey-500" />}
      className={cn("extra-large-desktop:col-span-1", wide && "extra-large-desktop:col-span-2")}
      {...textInputProps}
    />
  );
};

export default SearchBar;
