import { useMutation } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { downloadString } from "utils/download";

type Variables = {
  capitalAccountGuid: string;
  sinceInclusive: Dayjs;
  untilInclusive: Dayjs;
};

const useDownloadCapitalAccountTransactionsCsvMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutation({
    mutationFn: async ({ capitalAccountGuid, sinceInclusive, untilInclusive }: Variables) => {
      const csvText = await highbeamApi.capitalTransactions.getCsv(
        businessGuid,
        capitalAccountGuid,
        sinceInclusive,
        untilInclusive
      );

      const fileName = `capital-transactions_${capitalAccountGuid}_${sinceInclusive.format(
        "YYYY-MM-DD"
      )}_${untilInclusive.format("YYYY-MM-DD")}.csv`;

      downloadString(fileName, csvText);
    },
    onError: () => {
      notify(
        "info",
        "Something went wrong while exporting transactions. Please try again or contact support."
      );
    },
  });
};

export default useDownloadCapitalAccountTransactionsCsvMutation;
