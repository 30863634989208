import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { COUNTERPARTY_REPORT_QUERY_KEY } from "../queries/useCounterpartyReportQueryOptions";

type Params = {
  originalCounterparty: string;
};

const useUnmergeCounterpartyMutation = ({ originalCounterparty }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshCounterpartyReportQuery = useRefreshQuery([COUNTERPARTY_REPORT_QUERY_KEY]);

  return useMutation({
    mutationFn: () => {
      return highbeamApi.counterpartyAliasManagement.deleteByOriginalCounterparty({
        businessGuid: businessGuid,
        originalCounterparty: originalCounterparty,
      });
    },
    onError: () => {
      notify("error", "There was an issue unmerging. Please try again or contact support.");
    },
    onSuccess: async () => {
      await refreshCounterpartyReportQuery();
    },
  });
};

export default useUnmergeCounterpartyMutation;
