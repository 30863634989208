import HighbeamCard from "components/HighbeamCard";
import { FC } from "react";
import { BankAccountBarByUnitCoDepositAccountId } from "resources/bank-accounts/components/BankAccountBar";
import { CapitalAccountBarByUnitCoCreditAccountId } from "resources/capital-accounts/components/CapitalAccountBar";
import ApplePayInfoText from "resources/cards/components/ApplePayInfoText";
import useCard from "resources/cards/queries/useCard";
import useCardLimits from "resources/cards/queries/useCardLimits";
import { getCardBillingAddress, getCardholderName } from "resources/cards/utils";
import { checkIsCreditCard, DebitCard } from "resources/cards/utils/typeguards";
import colors from "styles/colors";
import MetadataList from "ui/data-display/MetadataList";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import ModalV4 from "ui/overlay/ModalV4";
import { Span } from "ui/typography";
import Text from "ui/typography/Text";

type CreateCardModalCardCreatedSuccessfullyHeaderProps = {
  cardId: string;
};

export const CreateCardModalCardCreatedSuccessfullyHeader: FC<
  CreateCardModalCardCreatedSuccessfullyHeaderProps
> = ({ cardId }) => {
  const card = useCard({ cardId, required: true });

  return (
    <div className="flex flex-col gap-2">
      <Text as="h4" size={16} weight="bold" color={colors.grey[800]} lineHeight={24}>
        Here are the card details.
      </Text>
      <Text as="p" size={14} weight="regular" color={colors.grey[600]} lineHeight={20}>
        {getCardholderName(card)} can now access and use this virtual card from the{" "}
        <strong>Cards</strong> page in their Highbeam account.
      </Text>
    </div>
  );
};

const DebitCardBankAccountLabel: FC<{ debitCard: DebitCard }> = ({ debitCard }) => {
  return (
    <BankAccountBarByUnitCoDepositAccountId
      unitCoDepositAccountId={debitCard.relationships.account.data.id}
    />
  );
};

type Props = {
  cardId: string;
};

const CreateCardModalCardCreatedSuccessfully: FC<Props> = ({ cardId }) => {
  const card = useCard({ cardId, required: true });
  const billingAddress = getCardBillingAddress(card);
  const cardLimits = useCardLimits({ cardId: cardId });

  return (
    <>
      <ModalV4.Body>
        <div className="flex items-center justify-center">
          <HighbeamCard card={card} clickable={false} />
        </div>

        <MetadataList className="mt-6">
          {billingAddress && (
            <MetadataList.Item>
              <MetadataList.ItemLabel className="items-start">
                Billing address
              </MetadataList.ItemLabel>
              <MetadataList.ItemValue className="flex-col items-end gap-y-0">
                <Span>{billingAddress.street}</Span>
                {billingAddress.street2 && <Span>{billingAddress.street2}</Span>}
                <Span>
                  {billingAddress.city}, {billingAddress.state} {billingAddress.postalCode}
                </Span>
              </MetadataList.ItemValue>
            </MetadataList.Item>
          )}

          <MetadataList.Item>
            <MetadataList.ItemLabel>Associated account</MetadataList.ItemLabel>
            <MetadataList.ItemValue>
              {checkIsCreditCard(card) ? (
                <CapitalAccountBarByUnitCoCreditAccountId
                  unitCoCreditAccountId={card.relationships.account.data.id}
                />
              ) : (
                <DebitCardBankAccountLabel debitCard={card} />
              )}
            </MetadataList.ItemValue>
          </MetadataList.Item>

          {cardLimits.attributes.limits?.monthlyPurchase && (
            <MetadataList.Item>
              <MetadataList.ItemLabel>Monthly spend limit</MetadataList.ItemLabel>
              <MetadataList.ItemValue>
                <MoneyAmount
                  cents={cardLimits.attributes.limits.monthlyPurchase}
                  size={14}
                  weight="medium"
                />
              </MetadataList.ItemValue>
            </MetadataList.Item>
          )}
          {cardLimits.attributes.limits?.dailyPurchase && (
            <MetadataList.Item>
              <MetadataList.ItemLabel>Daily spend limit</MetadataList.ItemLabel>
              <MetadataList.ItemValue>
                <MoneyAmount
                  cents={cardLimits.attributes.limits.dailyPurchase}
                  size={14}
                  weight="medium"
                />
              </MetadataList.ItemValue>
            </MetadataList.Item>
          )}
        </MetadataList>

        <ApplePayInfoText className="my-2" />
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.CloseButton />
      </ModalV4.Footer>
    </>
  );
};

export default CreateCardModalCardCreatedSuccessfully;
