import { useSuspenseQuery } from "@tanstack/react-query";
import { PropsWithChildren } from "react";
import useUnitCoAccountLimitQueryOptions from "resources/unit-co-account-limits/queries/useUnitCoAccountLimitQueryOptions";
import Text from "ui/typography/Text";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";

import styles from "./CreateDeposit.module.scss";

// We slice the formatted values to get rid of the decimals
const formatAndSlice = (amount: number) => formatAmount(amount).slice(0, -3);

type LimitSectionProps = {
  title: string;
} & PropsWithChildren;

const LimitSection: React.FC<LimitSectionProps> = ({ title, children }) => (
  <div className={styles.depositLimitsContainer}>
    <div className={styles.depositLimitsHeaderContainer}>
      <Text size={14} weight="medium">
        {title}
      </Text>
    </div>
    <div className={styles.depositLimitsBodyContainer}>{children}</div>
  </div>
);

type LimitRowProps = {
  title: string;
  remaining: string;
  totalLimit: string;
};

const LimitRow: React.FC<LimitRowProps> = ({ title, remaining, totalLimit }) => (
  <div className={styles.depositLimitsLineContainer}>
    <Text size={14} weight="medium">
      {title}
    </Text>
    <div className={styles.textContainer}>
      <Text size={14}>{remaining} </Text>
      <Text size={14} weight="medium">
        remaining
      </Text>
      <Text size={14} weight="medium">
        /
      </Text>
      <Text size={14}>{totalLimit}</Text>
      <Text size={14} weight="medium">
        limit
      </Text>
    </div>
  </div>
);

type Props = {
  accountId: string;
};

const DepositLimitInfo: React.FC<Props> = ({ accountId }) => {
  const { data: accountLimits } = useSuspenseQuery(useUnitCoAccountLimitQueryOptions(accountId));

  const formattedDailyLimit = formatAndSlice(
    getDollarsFromCents(accountLimits.attributes.checkDeposit.limits.daily)
  );
  const formattedMonthlyLimit = formatAndSlice(
    getDollarsFromCents(accountLimits.attributes.checkDeposit.limits.monthly)
  );
  const formattedDailyRemaining = formatAndSlice(
    getDollarsFromCents(
      accountLimits.attributes.checkDeposit.limits.daily -
        accountLimits.attributes.checkDeposit.totalsDaily
    )
  );
  const formattedMonthlyUsage = formatAndSlice(
    getDollarsFromCents(
      accountLimits.attributes.checkDeposit.limits.monthly -
        accountLimits.attributes.checkDeposit.totalsMonthly
    )
  );

  return (
    <LimitSection title={"Deposit limits"}>
      <LimitRow
        title={"Daily"}
        remaining={formattedDailyRemaining}
        totalLimit={formattedDailyLimit}
      />
      <LimitRow
        title={"Monthly"}
        remaining={formattedMonthlyUsage}
        totalLimit={formattedMonthlyLimit}
      />
    </LimitSection>
  );
};

export default DepositLimitInfo;
