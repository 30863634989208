import { captureMessage } from "@sentry/react";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { downloadBlob } from "utils/download";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type DownloadLineOfCreditAgreementVariables = {
  lineOfCreditGuid: string;
  unsignedAgreementGuid?: string;
  signedAgreementGuid?: string;
};

const useDownloadLineOfCreditAgreementMutation = (
  additionalOptions: MutationAdditionalOptions<void, DownloadLineOfCreditAgreementVariables>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ lineOfCreditGuid, unsignedAgreementGuid, signedAgreementGuid }) => {
        const agreements = await highbeamApi.lineOfCredit.getLineOfCreditAgreement(
          businessGuid,
          lineOfCreditGuid,
          unsignedAgreementGuid,
          signedAgreementGuid
        );

        const agreement = agreements?.signedAgreementUrl || agreements?.unsignedAgreementUrl;

        if (agreement) {
          const res = await fetch(agreement, {
            headers: {
              "Content-Type": "application/octet-stream",
            },
          });
          downloadBlob(
            "HighbeamLineOfCreditAgreement.pdf",
            window.URL.createObjectURL(await res.blob())
          );
        } else {
          throw new Error("No agreement available");
        }
      },
      onError: () => {
        notify(
          "error",
          "Unable to download your document. Please contact support if this problem persists."
        );
        captureMessage("Download agreement failed");
      },
    },
    additionalOptions
  );
};

export default useDownloadLineOfCreditAgreementMutation;
