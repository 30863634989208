import SubcategoryRep from "reps/Insights/SubcategoryRep";

import HighbeamBaseApi from "../HighbeamBaseApi";

export default class SubcategoryApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByBusiness(businessGuid: string): Promise<SubcategoryRep.Complete[]> {
    const queryParams = new URLSearchParams({ businessGuid: businessGuid });
    const url = `/subcategories?${queryParams.toString()}`;
    return (await this.api.get<SubcategoryRep.Complete[]>(url))!;
  }
}
