import AddressAutocompleteDropdown from "modules/address-autocomplete/AddressAutocompleteDropdown";
import { FC } from "react";
import { Controller } from "react-hook-form";
import useBusinessAddress from "resources/business-details/queries/useBusinessAddress";
import Button from "ui/inputs/Button";
import DropdownV2 from "ui/inputs/DropdownV2";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import TextInputV2 from "ui/inputs/TextInputV2";
import ModalV4 from "ui/overlay/ModalV4";
import { stateOptions, states } from "utils/address";
import { FormSubmitHandlerProps } from "utils/react-hook-form/GetUseFormSubmitHandler";

import { SectionHeading, SectionSubheading } from "../headings";

import useCreateCardModalShippingAddressForm from "./useCreateCardModalShippingAddressForm";

type Props = FormSubmitHandlerProps<typeof useCreateCardModalShippingAddressForm> & {
  onBack: () => void;
  isSubmitLoading: boolean;
};

const CreateCardModalShippingAddressForm: FC<Props> = ({
  onValid,
  onInvalid,
  onBack,
  isSubmitLoading,
}) => {
  const { line1, line2, city, state, postalCode, country } = useBusinessAddress();

  const form = useCreateCardModalShippingAddressForm({
    defaultValues: {
      // TODO(alex): Will do upstack.
      address: {
        // NB(alex): This is a bit of a hack. Would be nice to figure out a more elegant solution than to pass in this `autocompleteOption` value.
        autocompleteOption: {
          fullAddress: "",
          googleMapsPlaceId: "",
          streetAddress: line1,
        },
        street1: line1,
        street2: line2 ?? "",
        city: city,
        state: state,
        postalCode: postalCode,
        country: country,
      },
    },
  });

  return (
    <ModalV4.Form onSubmit={form.handleSubmit(onValid, onInvalid)}>
      <ModalV4.Body className="mb-6">
        <SectionHeading>Shipping address</SectionHeading>
        <SectionSubheading>We will send this card to the following address.</SectionSubheading>

        <FieldsetV2>
          <Controller
            control={form.control}
            name="address.autocompleteOption"
            render={({ field }) => {
              return (
                <AddressAutocompleteDropdown
                  componentRestrictionsCountry="US"
                  onSelectAddress={(address) => {
                    form.reset({
                      ...form.getValues(),
                      address,
                    });
                  }}
                  {...field}
                />
              );
            }}
          />

          <Controller
            control={form.control}
            name="address.street2"
            render={({ field }) => {
              return <TextInputV2 label="Address line 2" {...field} />;
            }}
          />

          <FieldsetV2.Row columns={3} className="@lg:grid-cols-3">
            <Controller
              control={form.control}
              name="address.city"
              render={({ field }) => {
                return <TextInputV2 label="City" {...field} />;
              }}
            />

            <Controller
              control={form.control}
              name="address.state"
              render={({ field: { value, onChange, ...field } }) => {
                return (
                  <DropdownV2
                    label="State"
                    options={stateOptions}
                    value={value ? { label: states[value], value: value } : null}
                    getOptionLabel={(option) => option.label}
                    onChange={(option) => {
                      onChange(option?.value ?? null);
                    }}
                    {...field}
                  />
                );
              }}
            />

            <Controller
              control={form.control}
              name="address.postalCode"
              render={({ field }) => {
                return <TextInputV2 label="ZIP" {...field} />;
              }}
            />
          </FieldsetV2.Row>
        </FieldsetV2>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton isLoading={isSubmitLoading}>Create card</ModalV4.SubmitButton>
        <Button onClick={onBack} variant="ghost">
          Back
        </Button>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

export default CreateCardModalShippingAddressForm;
