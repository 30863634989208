import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import React, { useReducer } from "react";
import ChatMessageRequest from "reps/chat/ChatMessageRequest";
import ChatMessageResponse from "reps/chat/ChatMessageResponse";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import ChatConversationSection from "./ChatConversationSection";
import ChatInputSection from "./ChatInputSection";

export type State = {
  request: Omit<ChatMessageRequest, "businessGuid" | "userGuid">;
  response?: ChatMessageResponse;
}[];

export type Action =
  | { type: "request"; request: Omit<ChatMessageRequest, "businessGuid" | "userGuid"> }
  | { type: "response"; response: ChatMessageResponse };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "request":
      return [...state, { request: action.request }];
    case "response":
      return [...state.slice(0, -1), { request: state.at(-1)!.request, response: action.response }];
  }
};

const ChatPage: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, []);

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>AI Chat</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <div className="flex h-0 flex-grow flex-col">
        <ChatConversationSection className="flex-grow overflow-y-auto" state={state} />
        <ChatInputSection dispatch={dispatch} />
      </div>
    </>
  );
};

export default ChatPage;
