import { PlusCircle } from "@phosphor-icons/react";
import logos from "assets/add-connection-modal-logos.svg";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./PlaidNewConnectionCardContents.module.scss";

type Props = {
  openPlaidLinkModal: () => void;
};

const PlaidNewConnectionCardContents: React.FC<Props> = ({ openPlaidLinkModal }) => {
  return (
    <>
      <div className={styles.plaidNewConnectionCardContents}>
        <div>
          <img src={logos} alt="" />
        </div>
        <div className={styles.textArea}>
          <Text size={16} weight={"bold"}>
            Connect a bank or card
          </Text>
          <Text size={14} lineHeight={20} align={"center"} color={colors.grey[600]}>
            Connect a new bank account or card by logging in through Plaid.
          </Text>
        </div>
        <Button variant={"default"} onClick={openPlaidLinkModal}>
          <PlusCircle />
          <Text>New connection</Text>
        </Button>
      </div>
    </>
  );
};

export default PlaidNewConnectionCardContents;
