import {
  getInternationalWireCountry,
  InternationalWireOption,
} from "pages/SendMoneyPage/internationalWires";
import { selector } from "recoil";

import { internationalAddressState } from "./address";

export const selectedCountryOptionState = selector<InternationalWireOption | null>({
  key: "selectedCountry",
  get: ({ get }) => {
    const address = get(internationalAddressState);
    if (!address.country?.value) return null;
    return getInternationalWireCountry(address.country.value);
  },
});
