import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import BankAccountRep from "reps/BankAccountRep";
import BankAccountChartOfAccountSelect from "resources/accounting-accounts/components/BankAccountChartOfAccountSelect";
import useSetBankAccountForChartOfAccountMutation from "resources/accounting-accounts/mutations/useSetBankAccountOnAccountingAccountMutation";
import useRefreshAccountingAccountsQueries from "resources/accounting-accounts/queries/useRefreshAccountingAccountsQueries";
import BankAccountBar from "resources/bank-accounts/components/BankAccountBar";
import { notify } from "ui/feedback/Toast";
import Helper from "ui/inputs/Helper";
import ModalV4 from "ui/overlay/ModalV4";
import { TextButton } from "ui/typography";
import { z } from "zod";

const missingAccountingCategoryFormSchema = z.object({
  accountingAccountId: z.string().nonempty(),
});

type MissingAccountingCategoryFormInputs = z.infer<typeof missingAccountingCategoryFormSchema>;

type MissingAccountingCategoryModalContentProps = {
  bankAccount: BankAccountRep.Complete;
  onClose: () => void;
};

const MissingAccountingCategoryModalContent: FC<MissingAccountingCategoryModalContentProps> = ({
  bankAccount,
  onClose,
}) => {
  const refreshAccountingAccountsQueries = useRefreshAccountingAccountsQueries();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<MissingAccountingCategoryFormInputs>({
    resolver: zodResolver(missingAccountingCategoryFormSchema),
    mode: "onChange",
  });

  const {
    mutate: setBankAccountForChartOfAccount,
    isPending: isSettingBankAccountForChartOfAccount,
  } = useSetBankAccountForChartOfAccountMutation(bankAccount.guid, {
    onSuccess: () => {
      notify("success", "Accounting category added");
      onClose();
    },
    onError: (error) => {
      notify("error", error.message ?? "Failed to add accounting category");
    },
  });

  const onSubmit = useCallback(
    ({ accountingAccountId }: MissingAccountingCategoryFormInputs) => {
      setBankAccountForChartOfAccount({ accountingAccountId });
    },
    [setBankAccountForChartOfAccount]
  );

  return (
    <ModalV4.Form onSubmit={handleSubmit(onSubmit)}>
      <ModalV4.Header>Missing accounting category</ModalV4.Header>
      <ModalV4.Body>
        <ModalV4.Paragraph>
          The payment bank account is missing an accounting category. Please assign a chart of
          account from your accounting software to complete this payment.
        </ModalV4.Paragraph>

        <div className="mt-6 flex flex-col gap-4 border-t border-grey-100 pt-6">
          <BankAccountBar bankAccount={bankAccount} />
          <Controller
            control={control}
            name="accountingAccountId"
            render={({ field }) => (
              <>
                <BankAccountChartOfAccountSelect
                  bankAccount={bankAccount}
                  value={field.value}
                  onValueChange={field.onChange}
                />
                <Helper className="mt-0">
                  Missing an accounting category? Create one inside your accounting software and{" "}
                  <TextButton onClick={() => refreshAccountingAccountsQueries()}>
                    refresh this list
                  </TextButton>
                  .
                </Helper>
              </>
            )}
          />
        </div>
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.SubmitButton disabled={!isValid} isLoading={isSettingBankAccountForChartOfAccount}>
          Save changes
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton>Close</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = MissingAccountingCategoryModalContentProps;

const MissingAccountingCategoryModal: FC<Props> = (props) => {
  return (
    <ModalV4 onClose={props.onClose}>
      <MissingAccountingCategoryModalContent {...props} />
    </ModalV4>
  );
};

export default MissingAccountingCategoryModal;
