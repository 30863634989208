import CardRep from "reps/CardRep";
import { CardAttributes } from "resources/cards/types";

type Params = CardAttributes;

const getCardTypeByAttributes = ({
  virtualOrPhysical,
  creditOrDebit,
}: Params): CardRep.CardType => {
  if (virtualOrPhysical === "physical") {
    if (creditOrDebit === "credit") {
      return "PHYSICAL_CREDIT";
    } else {
      return "PHYSICAL";
    }
  } else {
    if (creditOrDebit === "credit") {
      return "VIRTUAL_CREDIT";
    } else {
      return "VIRTUAL";
    }
  }
};

export default getCardTypeByAttributes;
