import {
  ActionItem,
  FundYourAccountStep,
  GetStartedSetupGuideStep,
  Milestone,
  SignupStep,
} from "./types";

const DEFAULT_SIGNUP_ACTIONS: ActionItem[] = [
  {
    order: 1,
    name: SignupStep.AuthorizeWithUnit,
    milestone: Milestone.Signup,
    title: "Authorize with Unit",
    primaryActionText: "",
    primaryActionPath: "",
  },
];

const DEFAULT_GET_STARTED_ACTIONS: ActionItem[] = [
  {
    order: 1,
    name: GetStartedSetupGuideStep.ConnectStore,
    milestone: Milestone.GetStarted,
    title: "Connect your store",
    description:
      "When Highbeam is connected to your store, we are able to provide you with better insights.",
    primaryActionText: "Connect stores",
    primaryActionPath: "/settings/stores",
  },
  {
    order: 2,
    name: GetStartedSetupGuideStep.CreateVirtualCard,
    milestone: Milestone.GetStarted,
    title: "Create your first virtual card",
    description: "Create up to 5 virtual cards to help separate your business expenses.",
    primaryActionText: "Create virtual card",
    primaryActionPath: "/cards",
    locationState: { state: { openVirtualCard: true } },
  },
  {
    order: 3,
    name: GetStartedSetupGuideStep.ConnectBank,
    milestone: Milestone.GetStarted,
    title: "Connect your other banks and cards",
    description: "Easily transfer funds between Highbeam and other bank accounts.",
    primaryActionText: "Connect your accounts",
    primaryActionPath: "/settings/banks-and-cards",
    locationState: { state: { fromStep: GetStartedSetupGuideStep.ConnectBank } },
  },
  {
    order: 4,
    name: GetStartedSetupGuideStep.ReceiveStorePayouts,
    milestone: Milestone.GetStarted,
    title: "Connect your store payouts",
    description: "Set up automatic payouts to fund your Highbeam account.",
    primaryActionText: "Set up payouts",
    primaryActionPath: "/accounts",
    locationState: { state: { fromStep: GetStartedSetupGuideStep.ReceiveStorePayouts } },
  },
];

const DEFAULT_FUND_YOUR_ACCOUNT_ACTIONS: ActionItem[] = [
  {
    order: 1,
    name: FundYourAccountStep.FundYourAccount,
    milestone: Milestone.FundYourAccount,
    title: "Fund your Highbeam account",
    description:
      "Transfer funds from your existing bank or connect your store payouts to flow to Highbeam.",
    primaryActionText: "Fund account",
    primaryActionPath: "/transfer-money",
  },
];

export const DEFAULT_ACTIONS_BY_MILESTONE = {
  [Milestone.Signup]: DEFAULT_SIGNUP_ACTIONS,
  [Milestone.GetStarted]: DEFAULT_GET_STARTED_ACTIONS,
  [Milestone.FundYourAccount]: DEFAULT_FUND_YOUR_ACCOUNT_ACTIONS,
};
