import { ArrowLeft, X } from "@phosphor-icons/react";
import classNames from "classnames";
import { CSSProperties, FC, ReactNode } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";
import Text, { TextProps } from "ui/typography/Text";

import useFullPageModalContext from "../context/useFullPageModalContext";

import styles from "./FullPageModalHeader.module.scss";

const FullPageModalHeaderBackButton = (props: ButtonProps) => {
  return (
    <Button paddingVariant="square" variant="ghost" {...props}>
      <ArrowLeft size={24} />
    </Button>
  );
};

const FullPageModalHeaderTitle = ({ children, ...textProps }: TextProps) => {
  return (
    <Text size={18} weight="bold" {...textProps}>
      {children}
    </Text>
  );
};

const FullPageModalHeaderCloseButton = (props: ButtonProps) => {
  const { onClose } = useFullPageModalContext();

  return (
    <Button paddingVariant="square" variant="ghost" onClick={onClose} {...props}>
      <X size={24} />
    </Button>
  );
};

type Props = {
  title: ReactNode;
  // Renders the default back button if `onBack` is set, otherwise is `undefined`. Option to pass in a custom component. `onBack` gets ignored if a custom `backButton` is used.
  onBack?: () => void;
  backButton?: ReactNode;
  closeButton?: ReactNode;
  className?: string;
  style?: CSSProperties;
};

const FullPageModalHeader: FC<Props> = ({
  title,
  onBack,
  backButton = onBack ? <FullPageModalHeaderBackButton onClick={onBack} /> : undefined,
  closeButton = <FullPageModalHeaderCloseButton />,
  className,
  style,
}) => {
  return (
    <header className={classNames(className, styles.header)} style={style}>
      {backButton}

      <div className={styles.titleContainer}>
        <FullPageModalHeaderTitle>{title}</FullPageModalHeaderTitle>
      </div>

      {closeButton}
    </header>
  );
};

export default Object.assign(FullPageModalHeader, {
  Title: FullPageModalHeaderTitle,
  CloseButton: FullPageModalHeaderCloseButton,
});
