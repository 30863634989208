import shopifyIconGrey from "assets/shopify-icon-grey.svg";
import stripeIconGrey from "assets/stripe-icon-grey.svg";

import { ConnectedStoreType } from "../types";

type Params = {
  connectedStoreType: ConnectedStoreType;
  isGrey?: boolean;
};

const getConnectedStoreIcon = ({ connectedStoreType, isGrey }: Params): string => {
  switch (connectedStoreType) {
    case "Shopify":
      return isGrey ? shopifyIconGrey : "/assets/merchants/shopify.svg";
    case "Amazon":
      return isGrey ? "/assets/merchants/amazon-icon-grey.svg" : "/assets/merchants/amazon.svg";
    case "Stripe":
      return isGrey ? stripeIconGrey : "/assets/merchants/stripe.svg";
    case "PayPal":
      return isGrey ? "/assets/merchants/paypal-icon-grey.svg" : "/assets/merchants/paypal.svg";
  }
};

export default getConnectedStoreIcon;
