import { ArrowsOutSimple, Storefront } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC } from "react";
import useUnmergeCounterpartyMutation from "resources/counterparty-report/mutations/useUnmergeCounterpartyMutation";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import { useMergedCounterpartiesModalContext } from "../../context";

import styles from "./MergedCounterpartiesCounterpartyRow.module.scss";

type Props = {
  originalCounterparty: string;
  isLastRow: boolean;
};

const MergedCounterpartiesCounterpartyRow: FC<Props> = ({ originalCounterparty, isLastRow }) => {
  const { counterparty, onClose } = useMergedCounterpartiesModalContext();
  const isOriginalCounterparty = counterparty === originalCounterparty;

  const { mutateAsync: unmergeCounterparty, isPending } = useUnmergeCounterpartyMutation({
    originalCounterparty: originalCounterparty,
  });

  const onClick = async () => {
    await unmergeCounterparty();
    onClose();
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.lastRow]: isLastRow,
      })}
    >
      <div className={styles.counterpartyName}>
        <Storefront size={24} />

        <Text color={colors.grey[800]} size={16}>
          {originalCounterparty}
        </Text>
      </div>

      {!isOriginalCounterparty && (
        <Button variant="tertiary" size="sm" onClick={onClick} isLoading={isPending}>
          <ArrowsOutSimple size={16} />
          Unmerge
        </Button>
      )}
    </div>
  );
};

export default MergedCounterpartiesCounterpartyRow;
