import { useSuspenseQuery } from "@tanstack/react-query";

import useTransactionSubcategoriesQueryOptions from "../queries/useTransactionSubcategoriesQueryOptions";
import getTransactionSubcategoryOption from "../utils/getTransactionSubcategoryOption";

const useTransactionSubcategoryOptions = () => {
  const { data: subcategoryOptions } = useSuspenseQuery({
    ...useTransactionSubcategoriesQueryOptions(),
    select: (data) => {
      return data.map(getTransactionSubcategoryOption);
    },
  });

  return subcategoryOptions;
};

export default useTransactionSubcategoryOptions;
