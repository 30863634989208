import { createContext, useContext } from "react";
import { atom } from "recoil";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

type UserInfo = {
  userGuid: string;
  initials: string;
  fullName: string;
  userRoleName: string;
};

export type ChangeOrganizationOwnerModalOpenParams = {
  currentOwner: UserInfo;
  nextOwner: UserInfo;
};

type ChangeOrganizationOwnerModalState = DialogState<ChangeOrganizationOwnerModalOpenParams>;

const changeOrganizationOwnerModalState = atom<ChangeOrganizationOwnerModalState>({
  key: "settings/users/changeOrganizationOwnerModalState",
  default: { isOpen: false },
});

export default changeOrganizationOwnerModalState;

export const useChangeOrganizationOwnerModal = () => {
  return useModal<ChangeOrganizationOwnerModalOpenParams>(changeOrganizationOwnerModalState);
};

export const ChangeOrganizationOwnerModalContext =
  createContext<ChangeOrganizationOwnerModalOpenParams>(
    {} as ChangeOrganizationOwnerModalOpenParams
  );

export const useChangeOrganizationOwnerModalContext = () =>
  useContext(ChangeOrganizationOwnerModalContext);
