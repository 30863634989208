import { useSuspenseQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import BankApplicationRep from "reps/BankApplicationRep";
import useBankApplicationQueryOptions from "resources/bank-application/queries/useBankApplicationQueryOptions";
import { useCurrentBusinessMember } from "resources/business-members/queries/businessMemberQueryHooks";
import useBusiness from "resources/business/queries/useBusiness";
import useRutterConnectionsQueryOptions from "resources/connected-stores/queries/useRutterConnectionsQueryOptions";
import useShopifyConnectionsQueryOptions from "resources/connected-stores/queries/useShopifyConnectionsQueryOptions";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";

const OnboardingNavigate = () => {
  const { data: bankApplication } = useSuspenseQuery(useBankApplicationQueryOptions());

  const { data: amazonConnections } = useSuspenseQuery({
    ...useRutterConnectionsQueryOptions(),
    select: (rutterConnections) =>
      rutterConnections.filter((rutterConnection) => rutterConnection.platformName === "AMAZON"),
  });

  const { data: shopifyConnections } = useSuspenseQuery(useShopifyConnectionsQueryOptions());

  const hasShopifyOrAmazonConnection = amazonConnections.length + shopifyConnections.length > 0;

  const businessMember = useCurrentBusinessMember();
  const business = useBusiness();

  const personalInfoFormEnabled = useFeatureFlag("ONBOARDING_PERSONAL_INFORMATION_FORM");
  const connectStoresEnabled = useFeatureFlag("CONNECT_STORES_DURING_ONBOARDING");
  const isAllowedToConnectStores = useIsAllowedToConnectStores();

  const to = (() => {
    const filledOutBankApplication =
      bankApplication && bankApplication.status !== BankApplicationRep.Status.FormCreated;

    const hasDateOfBirth = Boolean(businessMember?.dateOfBirth);

    const isBusinessOwner = businessMember && business.ownerUserGuid === businessMember.userGuid;

    if (
      personalInfoFormEnabled &&
      filledOutBankApplication &&
      !hasDateOfBirth &&
      !isBusinessOwner
    ) {
      return "/onboarding/personal-info";
    }

    if (
      isAllowedToConnectStores &&
      connectStoresEnabled &&
      filledOutBankApplication &&
      !hasShopifyOrAmazonConnection
    ) {
      return "/onboarding/connect-stores";
    }
    return "/onboarding/bank-application";
  })();

  return <Navigate replace to={to} />;
};

export default OnboardingNavigate;
