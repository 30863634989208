import { Payment } from "@highbeam/unit-node-sdk";
import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import { FC } from "react";
import getIsInternationalWireUnitCoPayment from "resources/unit-co-payments/utils/getIsInternationalWireUnitCoPayment";
import Flexpane from "ui/overlay/Flexpane";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

import InternationalWirePaymentInfoFlexpaneAmountSection from "./InternationalWirePaymentInfoFlexpaneAmountSection";

type Props = {
  payment: Payment;
};

const PaymentInfoFlexpaneAmountSection = () => {
  const { payment } = usePaymentInfoFlexpaneContext();
  const isInternationalWirePayment = getIsInternationalWireUnitCoPayment(payment);

  if (isInternationalWirePayment) {
    return <InternationalWirePaymentInfoFlexpaneAmountSection payment={payment} />;
  } else {
    return <BasePaymentFlexpaneAmount payment={payment} />;
  }
};

export default PaymentInfoFlexpaneAmountSection;

const BasePaymentFlexpaneAmount: FC<Props> = ({ payment }) => {
  return (
    <Flexpane.Section>
      <TransactionFlexpaneAmount
        cents={payment.attributes.amount}
        direction={payment.attributes.direction === "Debit" ? "positive" : "negative"}
      />
    </Flexpane.Section>
  );
};
