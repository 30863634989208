import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";
import { BaseHighbeamTransaction } from "utils/types/transactionsTypes";

type Props = {
  transaction: BaseHighbeamTransaction;
};

const BaseTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>
      {transaction.longMethodName ? `${transaction.longMethodName} details` : "Transaction details"}
    </Heading3>
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.summary}</MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default BaseTransactionDetailsSection;
