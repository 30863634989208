import { DEFAULT_MONTHS_SINCE } from "config/constants/filters";
import dayjs from "dayjs";
import DashboardPage from "layouts/DashboardPage";
import { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountTransactionsTable from "resources/capital-account-transactions/components/CapitalAccountTransactionsTable";
import PendingDrawdownsTable from "resources/capital-drawdown-approvals/components/PendingDrawdownsTable";
import PendingDrawdownFlexpane from "resources/capital-drawdown-approvals/dialogs/PendingDrawdownFlexpane";
import { useCapitalDrawdownApprovalsQuery } from "resources/capital-drawdown-approvals/queries/useCapitalDrawdownApprovals";
import { DISABLE_SCROLL_TO_TOP_STATE } from "ui/navigation/ScrollToTopOnNavigate";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useSearchParamDayjs from "utils/search-params/useSearchParamDayjs";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

import CapitalAvailableHighlightItems from "../../components/CapitalAvailableHighlightItems";
import CapitalAvailableSection from "../../components/CapitalAvailableSection";
import CapitalAccountBanners from "../../sections/CapitalAccountBanners";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";

import LineOfCreditDetailsSection from "./LineOfCreditDetailsSection";

type Props = {
  capitalAccountSummaryWithCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const LineOfCreditView: FC<Props> = ({ capitalAccountSummaryWithCashAccessOnly }) => {
  const navigate = useNavigate();
  const { pendingDrawdownGuid } = useParams();

  const { data: pendingDrawdowns = [] } = useCapitalDrawdownApprovalsQuery({
    capitalAccountGuid: capitalAccountSummaryWithCashAccessOnly.guid,
  });

  const [since, setSince] = useSearchParamDayjs(
    "since",
    dayjs().subtract(DEFAULT_MONTHS_SINCE, "months")
  );
  const [until, setUntil] = useSearchParamDayjs("until", dayjs());
  const [search, setSearch] = useSearchParamValue("search", "");

  return (
    <>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => {
          // NB(alex): Necessary for resetting the error boundary after navigation takes place.
          resetErrorBoundary();

          if (error instanceof RequiredButNotFoundError) {
            return (
              <Navigate
                to={`/capital/${capitalAccountSummaryWithCashAccessOnly.guid}`}
                state={DISABLE_SCROLL_TO_TOP_STATE}
              />
            );
          }

          throw error;
        }}
      >
        <PendingDrawdownFlexpane
          capitalAccountGuid={capitalAccountSummaryWithCashAccessOnly.guid}
          pendingDrawdownGuid={pendingDrawdownGuid}
          onClose={() => {
            navigate(`/capital/${capitalAccountSummaryWithCashAccessOnly.guid}`, {
              state: DISABLE_SCROLL_TO_TOP_STATE,
            });
          }}
        />
      </ErrorBoundary>

      <CapitalAccountBanners capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly} />

      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly} />

      <DashboardPage.Section className="flex flex-col justify-between gap-14 @4xl:flex-row">
        <div className="flex-1">
          <CapitalAvailableSection
            showAvailableOutstandingBalance
            capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly}
          />
          {capitalAccountSummaryWithCashAccessOnly.lender ===
            CapitalAccountRep.CapitalLender.Highbeam && (
            <CapitalAvailableHighlightItems
              capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly}
            />
          )}
        </div>

        <div className="flex-1">
          {capitalAccountSummaryWithCashAccessOnly.lender ===
            CapitalAccountRep.CapitalLender.Highbeam && (
            <>
              <DashboardPage.Section.HeaderHeading className="mb-1 font-medium">
                Details
              </DashboardPage.Section.HeaderHeading>

              <LineOfCreditDetailsSection
                capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly}
              />
            </>
          )}
        </div>
      </DashboardPage.Section>

      {pendingDrawdowns.length > 0 && (
        <DashboardPage.Section>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>
              Pending drawdowns
            </DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>

          <PendingDrawdownsTable
            pendingDrawdowns={pendingDrawdowns}
            onRowClick={({ guid, capitalAccountGuid }) => {
              navigate(`/capital/${capitalAccountGuid}/pending-drawdowns/${guid}`, {
                state: DISABLE_SCROLL_TO_TOP_STATE,
              });
            }}
          />
        </DashboardPage.Section>
      )}

      <DashboardPage.Section>
        <DashboardPage.Section.Header>
          <DashboardPage.Section.HeaderHeading>
            Line of credit transactions
          </DashboardPage.Section.HeaderHeading>
        </DashboardPage.Section.Header>

        <CapitalAccountTransactionsTable
          capitalAccountGuid={capitalAccountSummaryWithCashAccessOnly.guid}
          sinceControl={[since, setSince]}
          untilControl={[until, setUntil]}
          searchControl={[search, setSearch]}
        />
      </DashboardPage.Section>
    </>
  );
};

export default LineOfCreditView;
