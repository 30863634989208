import env from "env";
import { atom } from "recoil";
import { stringLocalStorageEffect } from "utils/recoil";

const localStorageKey = import.meta.env.DEV
  ? `businessGuid-${env.AUTH0_CUSTOM_DOMAIN}` // Prevents overriding the value when switching environments.
  : "businessGuid";

const businessGuidState = atom<string>({
  key: "auth/businessGuid",
  default: "",
  effects: [stringLocalStorageEffect(localStorageKey)],
});

export default businessGuidState;
