import { atom, selector } from "recoil";
import { ActionItem, Milestone } from "resources/action-items/types";
import actionItemsState from "state/actionItems/actionItems";

const noActionsComplete = (actionItems: ActionItem[]) => {
  return actionItems.every((item) => !item.finishedActionItemRep);
};

const showGetStartedSetupGuideState = atom<boolean | null>({
  key: "showGetStartedSetupGuide",
  default: null,
});

const isGettingStartedSetupGuideOpenState = selector<boolean | null>({
  key: "isGettingStartedSetupGuideOpen",
  get: ({ get }) => {
    const userSelectedSetupGuide = get(showGetStartedSetupGuideState);

    if (userSelectedSetupGuide !== null) {
      return userSelectedSetupGuide;
    }

    const actionItems = get(actionItemsState(Milestone.GetStarted));

    return noActionsComplete(actionItems);
  },
  set: ({ set }, show) => set(showGetStartedSetupGuideState, show),
});

export default isGettingStartedSetupGuideOpenState;
