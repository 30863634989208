import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { PAYMENT_METADATA_QUERY_KEY } from "resources/payment-metadata/queries/usePaymentMetadataQueryOptions";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

type Params = {
  paymentMetadataGuids: string[];
};

const useBatchGetPaymentMetadataQueryOptions = ({ paymentMetadataGuids }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [PAYMENT_METADATA_QUERY_KEY, paymentMetadataGuids],
    queryFn: async () => {
      // NB(alex): Backend throws 500 error when array is empty.
      if (paymentMetadataGuids.length === 0) {
        return [];
      }

      const response = await highbeamApi.generalPaymentMetadata.batchGet({
        businessGuid: businessGuid,
        guids: paymentMetadataGuids,
      });

      return response;
    },
  });
};

export default useBatchGetPaymentMetadataQueryOptions;
