import classNames from "classnames";
import { FC, ReactNode } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./TransactionFlexpaneParties.module.scss";

type Props = {
  from: ReactNode;
  status: ReactNode;
  to: ReactNode;
};

const TransactionFlexpaneParties: FC<Props> = ({ from, status, to }) => {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <Text size={14} color={colors.grey[900]}>
          From
        </Text>
        {from}
      </div>

      <div className={classNames(styles.column, styles.center)}>{status}</div>

      <div className={styles.column}>
        <Text size={14} color={colors.grey[900]}>
          To
        </Text>
        {to}
      </div>
    </div>
  );
};

export default TransactionFlexpaneParties;
