import classNames from "classnames";
import { FixedLengthArray } from "type-fest";

import styles from "./CreditComparisonTable.module.scss";

type Props = {
  className?: string;
  rows: FixedLengthArray<React.ReactNode, 12>;
};

const CreditComparisonColumnLayout: React.FC<Props> = ({ className, rows }) => (
  <div className={classNames(styles.column, className)}>
    {rows.map((row, index) => (
      <div
        key={index}
        className={classNames({
          [styles.cell]: true,
          [styles.noBorderBottom]: [
            0, // Header
            10, // Last labeled row
            11, // Last row with buttons on highbeam's column
          ].includes(index),
        })}
      >
        {row}
      </div>
    ))}
  </div>
);

export default CreditComparisonColumnLayout;
