import { Dayjs } from "dayjs";
import LineOfCreditTransactionRep from "reps/LineOfCreditTransactionRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class LineOfCreditTransactionsApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async createDrawdown(
    businessGuid: string,
    lineOfCreditGuid: string,
    amount: number,
    idempotencyKey: string,
    bankAccountGuid: string
  ) {
    const url = `/businesses/${businessGuid}/credit-accounts/${lineOfCreditGuid}/drawdown`;
    return await this.api.post(url, { amount, idempotencyKey, bankAccountGuid });
  }

  async createRepayment(
    businessGuid: string,
    lineOfCreditGuid: string,
    amount: number,
    idempotencyKey: string,
    bankAccountGuid: string
  ) {
    const url = `/businesses/${businessGuid}/credit-accounts/${lineOfCreditGuid}/repayment`;
    return await this.api.post(url, { amount, idempotencyKey, bankAccountGuid });
  }

  async getTransactions(
    businessGuid: string,
    since: Dayjs,
    until: Dayjs
  ): Promise<LineOfCreditTransactionRep.Complete[]> {
    const queryParams = new URLSearchParams({
      since: since.format("YYYY-MM-DD"),
      until: until.format("YYYY-MM-DD"),
    });
    const url = `/businesses/${businessGuid}/lines-of-credit/transactions?${queryParams}`;
    const transactions = await this.api.get<LineOfCreditTransactionRep.Complete[]>(url);
    return transactions || [];
  }

  async getTransactionsCsv(
    businessGuid: string,
    since: Dayjs,
    until: Dayjs
  ): Promise<string | null> {
    const queryParams = new URLSearchParams({
      since: since.format("YYYY-MM-DD"),
      until: until.format("YYYY-MM-DD"),
    });
    const url = `/businesses/${businessGuid}/lines-of-credit/transactions?${queryParams}`;
    return await this.api.get<string>(url, {
      Accept: "text/csv",
    });
  }
}
