import { useRecoilValue } from "recoil";

import useShouldShowActivateThreadBanner from "../../ActivateThreadBanner/hooks/useShouldShowActivateThreadBanner";
import activateThreadViewState from "../state/activateThreadViewState";

const useShouldShowActivateThreadView = () => {
  const { isOpen } = useRecoilValue(activateThreadViewState);
  const shouldShowActivateThreadBanner = useShouldShowActivateThreadBanner();

  return isOpen && shouldShowActivateThreadBanner;
};

export default useShouldShowActivateThreadView;
