import { FC } from "react";
import ModalV4 from "ui/overlay/ModalV4";

import ApplicationRequirementsForm from "./ApplicationRequirementsForm";

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

const ApplicationRequirementsModal: FC<Props> = ({ onClose, onSuccess }) => {
  return (
    <ModalV4 onClose={onClose} dropdownOverflowHack>
      <ApplicationRequirementsForm onSuccess={onSuccess} />
    </ModalV4>
  );
};

export default ApplicationRequirementsModal;
