import { capitalize } from "lodash-es";
import { maskAccountNumber } from "utils/account";

type Params = {
  accountName: string;
  accountNumber?: string;
  status?: string;
};

const getAccountDisplayName = ({ accountName, accountNumber, status }: Params) => {
  return `${accountName}${accountNumber ? ` ${maskAccountNumber(accountNumber)}` : ""}${status ? ` (${capitalize(status.toLowerCase())})` : ""}`;
};

export default getAccountDisplayName;
