import { withErrorBoundary } from "@sentry/react";
import OnboardingLayout from "layouts/OnboardingLayout";
import OnboardingCardLayout from "modules/onboarding/layouts/OnboardingCardLayout";
import useGetStarted from "pages/Onboarding/hooks/useGetStarted";
import { Controller } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import useUpdateBusinessMemberMutation from "resources/business-members/mutations/useUpdateBusinessMemberMutation";
import { useCurrentBusinessMember } from "resources/business-members/queries/businessMemberQueryHooks";
import { useIsSuperusering } from "state/auth/isSuperusering";
import colors from "styles/colors";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import DateInputFieldset from "ui/inputs/DateInputFieldset";
import Fieldset from "ui/inputs/Fieldset";
import TextInput from "ui/inputs/TextInput";
import Text from "ui/typography/Text";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useOnboardingPersonalInfoForm, {
  ONBOARDING_PERSONAL_INFO_FORM_ID,
} from "../hooks/useOnboardingPersonalInfoForm";

import styles from "./OnboardingPersonalInfoPage.module.scss";

const OnboardingPersonalInfoPage = () => {
  const { getStarted, isPending: getStartedIsPending } = useGetStarted({
    onSuccess: () => {
      navigate("/onboarding/get-started");
    },
  });

  const businessMember = useCurrentBusinessMember();
  const isSuperusering = useIsSuperusering();

  // NB(alex): This should only happen when superusering. Related: https://linear.app/highbeam/issue/HB-4436/cards-page-broken-during-superuser#comment-28d37919
  if (!isSuperusering && !businessMember) {
    notify("error", "We couldn't find your account. Please try again later.");
    throw new RequiredButNotFoundError();
  }

  const { handleSubmit, control } = useOnboardingPersonalInfoForm();
  const { mutateAsync: updateBusinessMember, isPending: updateBusinessMemberIsPending } =
    useUpdateBusinessMemberMutation();
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    if (!businessMember) {
      // NB(alex): This should only occur when superusering.
      return notify("error", "We couldn't find your account. Please try again later.");
    }

    await updateBusinessMember({
      memberGuid: businessMember.guid,
      dateOfBirth: data.dateOfBirth.format("YYYY-MM-DD"),
    });
    await getStarted();
  });

  return (
    <OnboardingLayout showProgressBar={false}>
      <OnboardingCardLayout
        title="Verify your personal information"
        subtitle="We’ll use this information for your Highbeam Cards and debit cards."
        footer={
          <Button
            type="submit"
            form={ONBOARDING_PERSONAL_INFO_FORM_ID}
            variant="primary"
            isLoading={updateBusinessMemberIsPending || getStartedIsPending}
          >
            Complete signup
          </Button>
        }
      >
        <form onSubmit={onSubmit} id={ONBOARDING_PERSONAL_INFO_FORM_ID}>
          <div className={styles.fields}>
            <Fieldset width="wide">
              <Controller
                control={control}
                name="firstName"
                render={({ field, formState: { errors, defaultValues } }) => (
                  <TextInput
                    label="First name"
                    hasError={Boolean(errors.firstName)}
                    errorMessage={errors.firstName?.message}
                    // NB(alex): Disabled these fields for now, until we migrate `useUser` to `useBusinessMember` so we can safely update user names.
                    disabled={Boolean(defaultValues?.firstName)}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="lastName"
                render={({ field, formState: { errors, defaultValues } }) => (
                  <TextInput
                    label="Last name"
                    hasError={Boolean(errors.lastName)}
                    errorMessage={errors.lastName?.message}
                    disabled={Boolean(defaultValues?.firstName)}
                    {...field}
                  />
                )}
              />
            </Fieldset>

            <div className={styles.dobFieldsetContainer}>
              <Text size={14} weight="medium" color={colors.grey[900]}>
                Date of birth
              </Text>
              <Controller
                control={control}
                name="dateOfBirth"
                render={({
                  field: {
                    ref: _ref, // NB(alex): Still figuring out the best way to handle passing a ref down to a custom fieldset.
                    ...field
                  },
                  formState: { errors },
                }) => (
                  <>
                    <DateInputFieldset
                      fieldsetErrorMessage={errors.dateOfBirth?.message}
                      {...field}
                    />
                  </>
                )}
              />
            </div>
          </div>
        </form>
      </OnboardingCardLayout>
    </OnboardingLayout>
  );
};

export default withErrorBoundary(OnboardingPersonalInfoPage, {
  fallback: ({ error }) => {
    if (error instanceof RequiredButNotFoundError) {
      return <Navigate to="/" />;
    } else {
      throw error;
    }
  },
});
