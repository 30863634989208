import LeadRep from "reps/LeadRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class LeadApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async set(leadRep: LeadRep.Creator): Promise<LeadRep.Complete> {
    const url = "/leads";
    return (await this.api.put<LeadRep.Complete>(url, leadRep))!;
  }

  async getByUser(userGuid: string): Promise<LeadRep.Complete | null> {
    const queryParams = new URLSearchParams({ userGuid });
    const url = `/leads?${queryParams.toString()}`;
    return await this.api.get<LeadRep.Complete>(url);
  }
}
