export type RequestMethod = "GET" | "POST" | "PATCH" | "PUT" | "DELETE";

export class ApiError extends Error {
  public readonly statusCode: number;

  constructor(statusCode: number, message: string) {
    super(message);
    this.statusCode = statusCode;
    this.message = message;
  }
}

export class HighbeamApiError extends ApiError {
  public readonly error: string;
  public readonly properties: any;

  constructor(e: any) {
    super(e.statusCode, e.message);
    this.error = e.error;
    this.properties = e;
  }
}

export function request<T>(
  method: RequestMethod,
  url: string,
  body?: BodyInit,
  headers?: HeadersInit
): Promise<T | null> {
  const options: RequestInit = {
    body,
    headers,
    method,
  };

  return fetch(url, options).then((response) => resolveResponse<T>(response));
}

function resolveResponse<T>(response: Response): Promise<T | null> {
  if (response.status >= 200 && response.status < 300) {
    return resolveContent(response);
  }
  return resolveServerError(response);
}

function resolveContent<T>(response: Response): Promise<T | null> {
  if (!response || response.status === 204) {
    return Promise.resolve(null);
  }

  const contentType = response.headers.get("content-type");
  return contentType && contentType.startsWith("application/json")
    ? response.json()
    : response.text();
}

function resolveServerError(response: Response) {
  return resolveContent(response).then((error: any) => {
    const highbeamApiException = asHighbeamApiException(error);
    if (highbeamApiException) throw highbeamApiException;
    throw new ApiError(
      response.status,
      error.message || response.statusText || response.status.toString()
    );
  });
}

function asHighbeamApiException(e: any): HighbeamApiError | undefined {
  const isHighbeamApiException =
    Boolean(e.error) &&
    Boolean(e.statusCode) &&
    Boolean(e.statusCodeDescription) &&
    Boolean(e.message);
  if (!isHighbeamApiException) return undefined;
  return new HighbeamApiError(e);
}
