import { forwardRef, ForwardRefRenderFunction, useId } from "react";

import Radio, { RadioProps } from "../Radio";
import WithLabel, { SharedWithLabelProps } from "../WithLabel";

type Props = SharedWithLabelProps & RadioProps;

const RadioWithLabel: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { label, className, id: idProp, ...radioProps },
  ref
) => {
  const internalId = useId();
  const id = idProp ?? internalId;

  return (
    <WithLabel htmlFor={id} label={label} disabled={radioProps.disabled} className={className}>
      <Radio ref={ref} id={id} {...radioProps} />
    </WithLabel>
  );
};

export default forwardRef(RadioWithLabel);
