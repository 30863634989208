import BillSummaryRep from "reps/BillSummaryRep";

const getIsBillBlank = (bill: BillSummaryRep.Complete) => {
  const {
    payeeGuid,
    amount,
    invoiceDate,
    invoiceDueDate,
    invoiceNumber,
    paymentTerms,
    purchaseOrderNumber,
    memo,
  } = bill;

  return [
    payeeGuid,
    amount,
    invoiceDate,
    invoiceDueDate,
    invoiceNumber,
    paymentTerms,
    purchaseOrderNumber,
    memo,
  ].every((value) => !Boolean(value));
};

export default getIsBillBlank;
