import { DefaultValue, selectorFamily } from "recoil";

import offerInputsState from "./offerInputs";

const feeState = selectorFamily<string, number>({
  key: "compareCreditOffers/feeState",
  get:
    (offerIndex) =>
    ({ get }) => {
      const offerInputs = get(offerInputsState);
      const fee = offerInputs[offerIndex].fee;
      return fee === null ? "" : fee.toString();
    },
  set:
    (offerIndex) =>
    ({ set }, newValue) => {
      set(offerInputsState, (prev) => [
        ...prev.slice(0, offerIndex),
        {
          ...prev[offerIndex],
          fee: newValue === "" || newValue instanceof DefaultValue ? null : parseInt(newValue),
        },
        ...prev.slice(offerIndex + 1),
      ]);
    },
});

export default feeState;
