import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Variables = {
  userInvitationGuid: string;
};

const useResendInvitationEmailMutation = (
  additionalOptions?: MutationAdditionalOptions<void, Variables>
) => {
  const highbeamApi = useHighbeamApi();

  return useMutationWithDefaults(
    {
      mutationFn: ({ userInvitationGuid }: Variables) => {
        return highbeamApi.userInvitationAction.reSendEmail(userInvitationGuid);
      },
    },
    additionalOptions ?? {}
  );
};

export default useResendInvitationEmailMutation;
