import DailyInterestFeeRep from "reps/DailyInterestFeeRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class LineOfCreditInterestFeeApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getAccruedInterestFees(
    businessGuid: string,
    lineOfCreditGuid: string,
    since: string,
    untilInclusive: string
  ): Promise<DailyInterestFeeRep.Complete[]> {
    const qp = new URLSearchParams({
      since: since,
      untilInclusive: untilInclusive,
    });
    const url = `/businesses/${businessGuid}/lines-of-credit/${lineOfCreditGuid}/accrued-interest?${qp}`;
    return (await this.api.get<DailyInterestFeeRep.Complete[]>(url)) || [];
  }
}
