import { PlusCircle } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import Button from "ui/inputs/Button";

const RequestAdditionalCapitalButton = () => {
  const chat = useChatWidget();
  const onRequestAdditionalCapital = () => {
    chat.message("Hi, I’d like to apply for additional capital.");
  };

  return (
    <Button variant="tertiary" onClick={onRequestAdditionalCapital}>
      <PlusCircle /> Request additional capital
    </Button>
  );
};

export default RequestAdditionalCapitalButton;
