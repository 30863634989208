import { zodResolver } from "@hookform/resolvers/zod";
import dayjs, { Dayjs, isDayjs } from "dayjs";
import { useForm } from "react-hook-form";
import { useCurrentBusinessMember } from "resources/business-members/queries/businessMemberQueryHooks";
import { z } from "zod";

export const ONBOARDING_PERSONAL_INFO_FORM_ID = "onboarding-personal-info-form";

const schema = z.object({
  firstName: z.string().min(1, { message: "This field is required" }),
  lastName: z.string().min(1, { message: "This field is required" }),
  dateOfBirth: z
    .custom<Dayjs>((val) => isDayjs(val), "This field is required")
    .refine((val) => {
      const age = dayjs().diff(val, "year");
      return age >= 13;
    }, "Please enter a valid age"),
});
export type OnboardingPersonalInfoFormInputs = z.infer<typeof schema>;

const useOnboardingPersonalInfoForm = () => {
  const businessMember = useCurrentBusinessMember();

  return useForm<OnboardingPersonalInfoFormInputs>({
    defaultValues: {
      firstName: businessMember?.firstName ?? "",
      lastName: businessMember?.lastName ?? "",
      dateOfBirth: businessMember?.dateOfBirth ? dayjs(businessMember.dateOfBirth) : undefined,
    },
    resolver: zodResolver(schema),
  });
};

export default useOnboardingPersonalInfoForm;
