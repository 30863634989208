import { useCallback, useSyncExternalStore } from "react";

export const MOBILE_MAX = 768;
export const TABLET_MAX = 991;
export const LARGE_DESKTOP_MAX = 1280;

// Based on useMediaQuery from https://usehooks.com/useMediaQuery/.
const useMediaQuery = (query: string) => {
  const subscribe = useCallback(
    (callback: () => void) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener("change", callback);
      return () => {
        matchMedia.removeEventListener("change", callback);
      };
    },
    [query]
  );

  const getSnapshot = () => {
    return window.matchMedia(query).matches;
  };

  return useSyncExternalStore(subscribe, getSnapshot);
};

export const useIsMobile = () => useMediaQuery(`(max-width: ${MOBILE_MAX}px)`);
export const useIsTablet = () => useMediaQuery(`(max-width: ${TABLET_MAX}px)`);
export const useIsDesktop = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  return !isMobile && !isTablet;
};

export const useMotionSafe = () => useMediaQuery("(prefers-reduced-motion: no-preference)");

export default useMediaQuery;
