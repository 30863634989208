import InvoiceUpload from "components/InvoiceUpload";
import { PaymentMethod } from "pages/SendMoneyPage/utils";

import styles from "./PaymentInvoice.module.scss";

type Props = {
  paymentMethod?: PaymentMethod;
  paymentMetadataGuid: string;
  setPaymentMetadataGuid?: (paymentMetadataGuid: string) => void;
  setInvoiceName?: (invoiceName: string) => void;
  showDelete?: boolean;
};

const PaymentInvoice: React.FC<Props> = ({
  paymentMethod,
  paymentMetadataGuid,
  setPaymentMetadataGuid,
  setInvoiceName,
  showDelete = true,
}) => {
  const legendCopyForPaymentMethod =
    paymentMethod === PaymentMethod.INTERNATIONAL ? "(recommended)" : "(optional)";
  return (
    <fieldset>
      <div className={styles.container}>
        <legend className={styles.headingText}>
          {"Invoice " + (paymentMethod ? legendCopyForPaymentMethod : "")}
        </legend>
        <InvoiceUpload
          attachmentType="invoice"
          paymentMetadataGuid={paymentMetadataGuid}
          setPaymentMetadataGuid={setPaymentMetadataGuid}
          setInvoiceName={setInvoiceName}
          showDelete={showDelete}
        />
      </div>
    </fieldset>
  );
};

export default PaymentInvoice;
