import classNames from "classnames";
import Text, { TextProps } from "ui/typography/Text";
import { TextSize, TextWeight } from "ui/typography/Text/TextTypes";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";

import styles from "./MoneyAmount.module.scss";

type Props = TextProps & {
  cents: number;
  currency?: string;
  withCents?: boolean;
  trailingCurrencyCode?: boolean;
  direction?: "positive" | "negative" | "none";
  as?: React.ElementType;
  centsTextSize?: TextSize;
  centsTextWeight?: TextWeight;
};

const MoneyAmount: React.FC<Props> = ({
  cents: centsProp,
  withCents = true,
  trailingCurrencyCode,
  direction = "none",
  currency,
  size = 14,
  as = "span",
  centsTextSize = size,
  centsTextWeight,
  ...textProps
}) => {
  const showDirection = direction !== "none";
  const dollarsFromCents = getDollarsFromCents(showDirection ? Math.abs(centsProp) : centsProp);

  const [dollars, cents] = formatAmount(dollarsFromCents, {
    withCents,
    currency,
    trailingCurrencyCode,
  }).split(".");

  return (
    <Text numeric as={as} size={size} {...textProps}>
      {showDirection && (
        <span
          className={classNames(styles.direction, {
            [styles.positive]: direction === "positive",
            [styles.negative]: direction === "negative",
          })}
        >
          {direction === "positive" ? "+" : "−"}
        </span>
      )}
      {dollars}
      {cents && (
        <Text numeric as="span" weight={centsTextWeight} size={centsTextSize}>
          .{cents}
        </Text>
      )}
    </Text>
  );
};

export default MoneyAmount;
