import { atom } from "recoil";
import BankAccountRep from "reps/BankAccountRep";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

type CancelPaymentParams = {
  counterpartyName: string;
  paymentAmountInCents: number;
  paymentGuid: string;
  account: BankAccountRep.Complete;
};

type CancelPaymentModalState = DialogState<CancelPaymentParams>;

const cancelPaymentModalState = atom<CancelPaymentModalState>({
  key: "cancelPaymentModalState",
  default: { isOpen: false },
});

export default cancelPaymentModalState;

export const useCancelPaymentModal = () => {
  return useModal<CancelPaymentParams>(cancelPaymentModalState);
};
