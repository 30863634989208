import { forwardRef, ForwardRefRenderFunction, useState } from "react";
import CurrencyInputField, { CurrencyInputProps } from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import cn from "utils/tailwind/cn";

import InputWrapper, { SharedInputProps } from "../InputWrapperV2";

type CurrencyInputPropsWithoutChangeHandlers = Omit<
  CurrencyInputProps,
  "onChange" | "onValueChange"
>;

type Props = CurrencyInputPropsWithoutChangeHandlers &
  SharedInputProps & {
    onChange: (value: string, name?: string, values?: CurrencyInputOnChangeValues) => void;
  };

const CurrencyInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    value,
    placeholder,
    className,
    variant,
    disabled,
    id,
    label,
    startAdornment,
    endAdornment,
    showErrorOutline,
    onFocus,
    onChange,
    onBlur,
    ...currencyInputProps
  },
  ref
) => {
  const [focused, setFocused] = useState(false);
  // We don't show the label in minimal variant.
  const hasLabel = Boolean(label) && variant !== "minimal";
  const shouldShrinkLabel = hasLabel && Boolean(value || placeholder || focused);

  return (
    <InputWrapper
      shouldShrinkLabel={shouldShrinkLabel}
      showFocusOutline={focused}
      disabled={disabled}
      className={className}
      variant={variant}
      inputId={id}
      label={label}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      showErrorOutline={showErrorOutline}
    >
      <CurrencyInputField
        ref={ref}
        onValueChange={(value, name, values) => onChange(value ?? "", name, values)}
        className={cn("mt-4 placeholder:text-grey-400", variant === "minimal" && "mt-0")}
        onFocus={(e) => {
          setFocused(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          onBlur?.(e);
        }}
        id={id}
        value={value}
        disabled={disabled}
        {...currencyInputProps}
      />
    </InputWrapper>
  );
};

export default forwardRef(CurrencyInput);
