import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import CardAuthorizations from "modules/card-authorizations-table/CardAuthorizations";
import useCardAuthorizations from "modules/card-authorizations-table/CardAuthorizations/hooks/useCardAuthorizations";
import FailedDebitsTable from "modules/failed-debits/components/FailedDebitsTable";
import useFailedDebitPayments from "modules/failed-debits/queries/useFailedDebitPayments";
import IncomingPaymentsTable from "modules/incoming-payments/components/IncomingPaymentsTable";
import useIncomingPayments from "modules/incoming-payments/queries/useIncomingPayments";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Tabs, { Tab } from "ui/navigation/Tabs";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { useIsTablet } from "utils/device/useMediaQuery";

import Transactions from "./Transactions";

const AccountsTransactionsPageContent = () => {
  const isTablet = useIsTablet();
  const [activeTab, setActiveTab] = useState<string>("incoming");
  const [scrollTransactionsIntoView, setScrollTransactionsIntoView] = useState(false);
  const transactionScrollRef = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();

  const tabs: Tab[] = [
    { id: "incoming", label: "Incoming" },
    { id: "transactions", label: "Transactions" },
  ];

  useEffect(() => {
    if (hash === "#transactions") {
      setActiveTab("transactions");
      setScrollTransactionsIntoView(true);
    }
  }, [hash]);

  const incomingPayments = useIncomingPayments();
  const hasIncoming = incomingPayments.length > 0;
  const hasAuthorizations = useCardAuthorizations({ accountType: "deposit" }).pagination.total > 0;

  const showFailedDebits = useFeatureFlag("FAILED_DEBITS_UI");
  const hasFailedDebits = useFailedDebitPayments().length > 0;

  return (
    <>
      {isTablet && hasIncoming && (
        <Tabs
          tabsWrapperAdditionalClassName="px-4 w-auto tablet:px-8"
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}

      {showFailedDebits && hasFailedDebits && (
        <DashboardPage.Section>
          <DashboardPage.Section.Header className="flex-col items-start">
            <DashboardPage.Section.HeaderHeading>Failed debits</DashboardPage.Section.HeaderHeading>
            <DashboardPage.Section.HeaderSubheading>
              These debits failed due to insufficient funds. You can fund the accounts and retry the
              debits until 2PM ET today.
            </DashboardPage.Section.HeaderSubheading>
          </DashboardPage.Section.Header>

          <FailedDebitsTable />
        </DashboardPage.Section>
      )}

      {(!isTablet || activeTab === "incoming") && hasIncoming && (
        <DashboardPage.Section>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>In transit</DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>
          <DashboardPage.Section.Body>
            <IncomingPaymentsTable />
          </DashboardPage.Section.Body>
        </DashboardPage.Section>
      )}

      {(!isTablet || activeTab === "transactions" || !hasIncoming) && (
        <>
          {hasAuthorizations && (
            <DashboardPage.Section>
              <DashboardPage.Section.Body>
                <CardAuthorizations accountType="deposit" />
              </DashboardPage.Section.Body>
            </DashboardPage.Section>
          )}

          <DashboardPage.Section>
            <DashboardPage.Section.Header>
              <DashboardPage.Section.HeaderHeading>
                Transactions
              </DashboardPage.Section.HeaderHeading>
            </DashboardPage.Section.Header>

            <Transactions
              scrollRef={scrollTransactionsIntoView ? transactionScrollRef : undefined}
            />
          </DashboardPage.Section>
        </>
      )}
    </>
  );
};

const AccountsTransactionsPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Transactions</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <AccountsTransactionsPageContent />
      </DashboardPage>
    </>
  );
};

export default AccountsTransactionsPage;
