import CardRep from "reps/CardRep";

import { CardAttributes } from "../types";

const getCardAttributesByCardType = (cardType: CardRep.CardType): CardAttributes => {
  switch (cardType) {
    case "PHYSICAL":
      return { virtualOrPhysical: "physical", creditOrDebit: "debit" };
    case "VIRTUAL":
      return { virtualOrPhysical: "virtual", creditOrDebit: "debit" };
    case "PHYSICAL_CREDIT":
      return { virtualOrPhysical: "physical", creditOrDebit: "credit" };
    case "VIRTUAL_CREDIT":
      return { virtualOrPhysical: "virtual", creditOrDebit: "credit" };
  }
};

export default getCardAttributesByCardType;
