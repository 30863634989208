import InvoiceUpload from "components/InvoiceUpload";
import Button from "ui/inputs/Button";
import StepHeader from "ui/navigation/Steps/StepHeader";

type Props = {
  onPrevPress: () => void;
  onNextPress: () => void;
  paymentMetadataGuid: string;
  setPaymentMetadataGuid: (paymentMetadataGuid: string) => void;
};

const config = {
  pageTitle: "Additional details",
  stepNumber: 4,
};

const AdditionalDetails: React.FC<Props> = ({
  onPrevPress,
  onNextPress,
  paymentMetadataGuid,
  setPaymentMetadataGuid,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNextPress();
  };

  return (
    <>
      <StepHeader
        stepNumber={config.stepNumber}
        title={config.pageTitle}
        disableBackButton={false}
        handleBackButtonClick={onPrevPress}
        handleForwardButtonClick={onNextPress}
      />
      <form id="additional-info" onSubmit={handleSubmit}>
        <InvoiceUpload
          attachmentType="invoice"
          paymentMetadataGuid={paymentMetadataGuid}
          setPaymentMetadataGuid={setPaymentMetadataGuid}
        />

        <Button
          className="mt-6 w-full tablet:w-24"
          type="submit"
          form="additional-info"
          variant="primary"
          onClick={onNextPress}
        >
          Next
        </Button>
      </form>
    </>
  );
};

export default AdditionalDetails;
