import { FC, Suspense, lazy, useState, useEffect } from "react";

import styles from "./DevTools.module.scss";

const ReactQueryDevtools = lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

const DevTools: FC = () => {
  const [isShowReactQueryDevtools, setIsShowReactQueryDevtools] = useState(false);

  useEffect(() => {
    // @ts-expect-error
    window.toggleReactQueryDevtools = () => setIsShowReactQueryDevtools((current) => !current);
  }, []);

  return (
    <div className={styles.container} aria-hidden={!isShowReactQueryDevtools}>
      <Suspense fallback={null}>
        {isShowReactQueryDevtools && <ReactQueryDevtools initialIsOpen buttonPosition="relative" />}
      </Suspense>
    </div>
  );
};

export default DevTools;
