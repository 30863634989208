import { uploadHints } from "pages/DepositCheckPage/CaptureCheck/CaptureHints";
import { useState } from "react";
import { CheckDepositValidation } from "state/checkDeposit";

export type mitekDocumentType = "CHECK_FRONT" | "CHECK_BACK";

export const useMitekUploadCapture = (
  documentType: mitekDocumentType,
  setValidationState: (state: CheckDepositValidation) => void
) => {
  const [isProcessing, setIsProcessing] = useState(false);

  // Have to manually close SDK's camera
  const processUpload = (img: string) => {
    window.mitekScienceSDK.on("FRAME_CAPTURE_RESULT", (result: any) => {
      setIsProcessing(false);
      if (result.response.status === "success") {
        setValidationState({ isValidated: true, errorMessage: null });
      }
      window.mitekScienceSDK.cmd("SDK_STOP");
    });

    window.mitekScienceSDK.on("FRAME_PROCESSING_FEEDBACK", (result: any) => {
      setIsProcessing(false);
      const recentHint = result.key as string;
      const hintMsg = uploadHints[recentHint];
      if (hintMsg !== undefined) {
        setValidationState({ isValidated: false, errorMessage: uploadHints[recentHint] });
      } else {
        setValidationState({ isValidated: false, errorMessage: uploadHints["DEFAULT_HINT"] });
      }
    });

    window.mitekScienceSDK.on("SDK_ERROR", (_: any) => {
      setValidationState({ isValidated: false, errorMessage: uploadHints["DEFAULT_HINT"] });
    });

    setIsProcessing(true);
    window.mitekScienceSDK.cmd("PROCESS_FRAME", {
      documentType: documentType,
      mitekSDKPath: "vendors/mi-snap-sdk/mitekSDK/",
      options: {
        frame: img,
        qualityPercent: 80,
      },
    });
  };

  return {
    processUpload,
    isProcessing,
  };
};
