import dayjs, { Dayjs } from "dayjs";
import CapitalRepaymentAmountRep from "reps/CapitalRepaymentAmountRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = {
  capitalAccountGuid: string;
  enabled?: boolean;
};

const capitalRepaymentAmountsQueryHooks = makeQueryHooks({
  name: "capitalRepaymentAmounts",
  useQueryVariables: ({ capitalAccountGuid, enabled = true }: Params) => {
    const businessGuid = useBusinessGuid();
    return { businessGuid, capitalAccountGuid, enabled };
  },
  useQueryFnMaker: ({ businessGuid, capitalAccountGuid, enabled }) => {
    const highbeamApi = useHighbeamApi();

    return () => {
      // NB(alex): Experimental - this allows us to cancel firing the request at all and to return a
      // default response instead. This is _might_ be a terrible pattern because it conflicts with
      // `useQuery`'s `enabled` param, but it _might_ actually be a genius pattern because it will
      // never reach this point when `!enabled`, for `useQuery` but gives us a matching interface
      // that can be used with `useSuspenseQuery`.
      if (!enabled) {
        return { amounts: [] } satisfies CapitalRepaymentAmountRep.Complete;
      }
      return highbeamApi.capitalRepaymentAmount.get(businessGuid, capitalAccountGuid);
    };
  },
});

export default capitalRepaymentAmountsQueryHooks;

// Hooks

const getNextAutoRepaymentDate = (
  amounts: CapitalRepaymentAmountRep.Complete["amounts"]
): Dayjs | null => {
  const amountDatesToDayjs = amounts.map((amount) => {
    return {
      ...amount,
      nextRepaymentDate: amount.nextRepaymentDate ? dayjs(amount.nextRepaymentDate) : null,
    };
  });

  return amountDatesToDayjs.reduce((acc: Dayjs | null, amount) => {
    if (amount.nextRepaymentDate) {
      return acc
        ? amount.nextRepaymentAmount === 0 || acc.isBefore(amount.nextRepaymentDate)
          ? acc
          : amount.nextRepaymentDate
        : amount.nextRepaymentDate;
    }
    return acc;
  }, null);
};

export type NextCapitalAutoPayAmount = {
  amount: number;
  date: Dayjs;
};

export const useNextCapitalAutoPayAmount = (params: Params): NextCapitalAutoPayAmount | null => {
  return capitalRepaymentAmountsQueryHooks.useData({
    ...params,
    select: (data) => {
      const nextAutoRepaymentDate = getNextAutoRepaymentDate(data.amounts);

      if (!nextAutoRepaymentDate) {
        return null;
      }

      const nextRepaymentAmounts = data.amounts.filter((amount) => {
        return (
          amount.nextRepaymentDate &&
          dayjs(amount.nextRepaymentDate).isSame(nextAutoRepaymentDate, "day")
        );
      });

      return nextRepaymentAmounts.reduce(
        (acc, amount) => {
          return {
            ...acc,
            amount: acc.amount + amount.nextRepaymentAmount,
          };
        },
        { amount: 0, date: nextAutoRepaymentDate } as NextCapitalAutoPayAmount
      );
    },
  });
};
