import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";

type AddToHomeScreenInstructionsSheetState = {
  isOpen: boolean;
};

const addToHomeScreenInstructionsSheetState = atom<AddToHomeScreenInstructionsSheetState>({
  key: "addToHomeScreenInstructionsSheetState",
  default: { isOpen: false },
});

export default addToHomeScreenInstructionsSheetState;

// Controls

export const useOpenAddToHomeScreenInstructionsSheet = () => {
  const setSheetState = useSetRecoilState(addToHomeScreenInstructionsSheetState);
  return useCallback(() => setSheetState({ isOpen: true }), [setSheetState]);
};

export const useCloseAddToHomeScreenInstructionsSheet = () => {
  const setSheetState = useSetRecoilState(addToHomeScreenInstructionsSheetState);
  return useCallback(() => setSheetState({ isOpen: false }), [setSheetState]);
};
