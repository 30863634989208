import classNames from "classnames";
import React, { CSSProperties, ReactNode } from "react";

import styles from "./TableCell.module.scss";
import { useTable } from "./TableWrapper";

import { Column, Scroll, StickySide } from ".";

type Props = {
  column: Column<unknown>;
  header?: boolean;

  children: ReactNode;
};

const TableCell: React.FC<Props> = ({ column, header = false, children }) => {
  const { scroll } = useTable();

  const X = header ? "th" : "td";

  const style: CSSProperties = {
    left: column.left === undefined ? undefined : `${column.left}px`,
    minWidth: column.width === undefined ? undefined : `${column.width}px`,
    right: column.right === undefined ? undefined : `${column.right}px`,
    width: column.width === undefined ? undefined : `${column.width}px`,
  };

  return (
    <X
      className={classNames(
        styles.cell,
        column.align === "right" && styles.alignRight,
        header && styles.header,
        stickyClassName(column.sticky, scroll),
        !header && column.cellClassName
      )}
      style={style}
    >
      {children}
    </X>
  );
};

export default TableCell;

const stickyClassName = (sticky: StickySide | undefined, scroll: Scroll): string | undefined => {
  switch (sticky) {
    case "left":
      return classNames(styles.stickyLeft, !scroll.isAtStart && styles.canScrollLeft);
    case "right":
      return classNames(styles.stickyRight, !scroll.isAtEnd && styles.canScrollRight);
    case undefined:
      return undefined;
  }
};
