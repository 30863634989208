export type Copacker = {
  businessName: string;
  address: {
    country: string;
  };
};

export type CopackersValidationErrors = {
  businessName?: string;
  country?: string;
};

export const isValidCopackersInfo = (
  copackers: Copacker[],
  validationErrors: CopackersValidationErrors[]
) => {
  return copackers.every((copackerInfo, i) => {
    const copackerValidationErrors = validationErrors[i];
    return (
      Boolean(copackerInfo?.businessName) &&
      !Boolean(copackerValidationErrors?.businessName) &&
      Boolean(copackerInfo?.address.country) &&
      !Boolean(copackerValidationErrors?.country)
    );
  });
};

export const isValidLeadTimeInDays = (input: string) => {
  const daysToNumber = Number(input);
  return !isNaN(daysToNumber) && daysToNumber >= 0;
};

export const getCopackersList = (copackers: Copacker[]) => {
  return copackers.map((copacker) => ({
    businessName: copacker.businessName,
    address: {
      country: copacker.address.country,
    },
  }));
};
