import { ErrorBoundary } from "@sentry/react";
import ErrorCard from "modules/error/components/ErrorCard";
import { FC, ReactNode, Suspense } from "react";
import DotsLoader from "ui/feedback/DotsLoader";
import cn from "utils/tailwind/cn";

import Header from "./Header";
import Section from "./Section";

const DashboardPageDotsLoader = () => {
  return (
    <div className="flex w-full items-center justify-center py-64">
      <DotsLoader dots={5} />
    </div>
  );
};

type DashboardPageProps = {
  children: ReactNode;
  className?: string;
  suspenseFallback?: ReactNode;
};

const DashboardPage: FC<DashboardPageProps> = ({
  children,
  className,
  suspenseFallback = <DashboardPageDotsLoader />,
}) => (
  <div
    className={cn(
      "mx-auto my-4 min-h-full w-full max-w-[var(--hb-page-max-width)] px-4 pb-24 tablet:px-8 extra-large-desktop:my-8 extra-large-desktop:px-16",
      className
    )}
  >
    <ErrorBoundary
      fallback={({ error }) => (
        <div className="min-h-full w-full max-w-[var(--hb-content-max-width)] pb-64 @container">
          <ErrorCard error={error} />
        </div>
      )}
    >
      <div className="min-h-full w-full max-w-[var(--hb-content-max-width)] pb-64 @container">
        <Suspense fallback={suspenseFallback}>{children}</Suspense>
      </div>
    </ErrorBoundary>
  </div>
);

export default Object.assign(DashboardPage, {
  Header: Header,
  Section: Section,
  DotsLoader: DashboardPageDotsLoader,
});
