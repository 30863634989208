import PayeeRep from "reps/PayeeRep";
import { Option } from "ui/inputs/Dropdown";
import { OnTextInputChange, validator } from "ui/inputs/TextInput";
import { Address, getStateOptionForCountry } from "utils/address";
import { Entity } from "utils/entity";

import { InternationalBankingInfo } from "./utils";

export enum INTERNATIONAL_WIRE_INPUTS {
  /*
   * Address
   */
  ADDRESS,
  ADDRESS_2,
  CITY,
  POSTAL_CODE,
  ZIP_CODE,
  STATE,
  PROVINCE,
  REGION,
  /*
   * Banking
   */
  ACCOUNT_NUMBER,
  BANK_CODE,
  BRANCH_CODE,
  BSB_CODE,
  CLABE,
  CNAPS,
  BIC_SWIFT,
  IBAN,
  IFSC,
  SORT_CODE,

  /*
   * Misc
   */
  INVOICE_DATE,
  INVOICE_NUMBER,
  PURPOSE_CODE,
}

type InternationalWireInputDetail = {
  defaultValidation: RegExp;
  label: string;
  errorMessage: string;
};

export const INTERNATIONAL_WIRE_INPUT_DETAILS: { [key: number]: InternationalWireInputDetail } = {
  /*
   * Address
   */
  [INTERNATIONAL_WIRE_INPUTS.ADDRESS]: {
    label: "Address",
    defaultValidation: new RegExp("^.{1,255}"),
    errorMessage: "Invalid address",
  },
  [INTERNATIONAL_WIRE_INPUTS.ADDRESS_2]: {
    label: "Address line 2",
    defaultValidation: new RegExp("^.{1,255}"),
    errorMessage: "Invalid address",
  },
  [INTERNATIONAL_WIRE_INPUTS.CITY]: {
    label: "City",
    defaultValidation: new RegExp("^.{1,255}"),
    errorMessage: "Invalid city",
  },
  [INTERNATIONAL_WIRE_INPUTS.POSTAL_CODE]: {
    label: "Postal code",
    defaultValidation: new RegExp("^.{1,12}$"),
    errorMessage: "Invalid postal code",
  },
  [INTERNATIONAL_WIRE_INPUTS.ZIP_CODE]: {
    label: "Zip code",
    defaultValidation: new RegExp("^.{1,12}$"),
    errorMessage: "Invalid zip code",
  },
  [INTERNATIONAL_WIRE_INPUTS.STATE]: {
    label: "State",
    defaultValidation: new RegExp("^.{1,255}"),
    errorMessage: "Invalid state",
  },
  [INTERNATIONAL_WIRE_INPUTS.PROVINCE]: {
    label: "Province",
    defaultValidation: new RegExp("^.{1,255}"),
    errorMessage: "Invalid province",
  },
  [INTERNATIONAL_WIRE_INPUTS.REGION]: {
    label: "Region",
    defaultValidation: new RegExp("^.{1,255}"),
    errorMessage: "Invalid region",
  },

  /*
   * Banking
   */
  [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: {
    label: "Account number",
    defaultValidation: new RegExp("^[0-9A-Z]{1,50}$"),
    errorMessage: "Invalid account number",
  },
  [INTERNATIONAL_WIRE_INPUTS.BANK_CODE]: {
    label: "Bank code",
    defaultValidation: new RegExp("^\\d{3}$"),
    errorMessage: "Invalid bank code",
  },
  [INTERNATIONAL_WIRE_INPUTS.BRANCH_CODE]: {
    label: "Branch code",
    defaultValidation: new RegExp("^\\d{5}$"),
    errorMessage: "Invalid branch code",
  },
  [INTERNATIONAL_WIRE_INPUTS.BSB_CODE]: {
    label: "Bank state branch code",
    defaultValidation: new RegExp("^\\d{6}$"),
    errorMessage: "Invalid bank state branch code",
  },
  [INTERNATIONAL_WIRE_INPUTS.CLABE]: {
    label: "CLABE",
    defaultValidation: new RegExp("^\\d{18}$"),
    errorMessage: "Invalid CLABE",
  },
  [INTERNATIONAL_WIRE_INPUTS.CNAPS]: {
    label: "CNAPS code",
    defaultValidation: new RegExp("^\\d{12}$"),
    errorMessage: "Invalid CNAPS code",
  },
  [INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT]: {
    label: "BIC SWIFT",
    defaultValidation: new RegExp("^[0-9A-Z]{8}$|^[0-9A-Z]{11}$"),
    errorMessage: "Invalid swift code",
  },
  [INTERNATIONAL_WIRE_INPUTS.IBAN]: {
    label: "IBAN",
    defaultValidation: new RegExp("([A-Z0-9]\\s*){15,34}"),
    errorMessage: "Invalid international bank account number",
  },
  [INTERNATIONAL_WIRE_INPUTS.IFSC]: {
    label: "IFSC",
    defaultValidation: new RegExp("^[A-Za-z]{4}[a-zA-Z0-9]{7}$"),
    errorMessage: "Invalid Indian Financial System Code",
  },
  [INTERNATIONAL_WIRE_INPUTS.SORT_CODE]: {
    label: "Sort code",
    defaultValidation: new RegExp("^\\d{6}$"),
    errorMessage: "Invalid sort code",
  },

  /*
   * Misc
   */
  [INTERNATIONAL_WIRE_INPUTS.INVOICE_DATE]: {
    label: "Invoice date",
    // Has to be YYYY-MM-DD
    defaultValidation: new RegExp("^\\d{4}-\\d{2}-\\d{2}$"),
    errorMessage: "Invalid invoice date",
  },
  [INTERNATIONAL_WIRE_INPUTS.INVOICE_NUMBER]: {
    label: "Invoice number",
    defaultValidation: new RegExp("^.{1,30}$"),
    errorMessage: "Invalid invoice number",
  },
  [INTERNATIONAL_WIRE_INPUTS.PURPOSE_CODE]: {
    label: "Purpose code",
    defaultValidation: new RegExp("^.{1,255}"),
    errorMessage: "Invalid purpose code",
  },
};

export type InternationalWireLocalPurposeOption = {
  description: string;
} & Option;

type InternationalWirePaymentMethod = {
  supported: boolean;
  deliveryMethod: PayeeRep.InternationalWirePaymentType;
  requiredFields: Set<INTERNATIONAL_WIRE_INPUTS>;
  paymentLimitinCents?: number;
};

export type InternationalWireOption = {
  countryCode: string;
  currencyCode: string;
  currencyName: string;
  currencySymbol: string;
  currencyFlag: string;
  usd: InternationalWirePaymentMethod;
  local: InternationalWirePaymentMethod;
  overrideInputValidation?: { [key: number]: RegExp };
  purposeCodes?: InternationalWireLocalPurposeOption[];
} & Option;

/*
 * How do add support for a new country
 * 1) Find the two letter country code on https://www.iban.com/country-codes
 * 2) Get the requirements by calling https://developer.currencycloud.com/docs/item/get-beneficiary-requirements/
 *    with USD as the currency and the country code from 1.
 * 3) Fill out the information for Priority only
 * 4) Ignore entity requirements (first name, last name, company name) as these are standard across all countries
 * 5) Currency codes can be found on https://www.iban.com/currency-codes
 */
export const supportedInternationalWireCountries: { [key: string]: InternationalWireOption } = {
  AD: {
    label: "Andorra",
    value: "AD",
    countryCode: "AD",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  AF: {
    label: "Afghanistan",
    value: "AF",
    countryCode: "AF",
    currencyCode: "AFN",
    currencySymbol: "؋",
    currencyName: "Afghan afghani",
    currencyFlag: "🇦🇫",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AG: {
    label: "Antigua and Barbuda",
    value: "AG",
    countryCode: "AG",
    currencyCode: "XCD",
    currencySymbol: "$",
    currencyName: "East Caribbean dollar",
    currencyFlag: "🇦🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AI: {
    label: "Anguilla",
    value: "AI",
    countryCode: "AI",
    currencyCode: "XCD",
    currencySymbol: "$",
    currencyName: "East Caribbean dollar",
    currencyFlag: "🇦🇮",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AL: {
    label: "Albania",
    value: "AL",
    countryCode: "AL",
    currencyCode: "ALL",
    currencySymbol: "L",
    currencyName: "Albanian lek",
    currencyFlag: "🇦🇱",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AE: {
    label: "United Arab Emirates",
    value: "AE",
    countryCode: "AE",
    currencyCode: "AED",
    currencySymbol: "د.إ",
    currencyName: "UAE Dirham",
    currencyFlag: "🇦🇪",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
        INTERNATIONAL_WIRE_INPUTS.PURPOSE_CODE,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    purposeCodes: [
      {
        label: "Employee compensation",
        value: "COP",
        description: "Compensation",
      },
      {
        label: "Goods sold",
        value: "GDE",
        description: "Goods Sold",
      },
      {
        label: "Goods bought",
        value: "GDI",
        description: "Goods bought",
      },
      {
        label: "Financial Services",
        value: "FIS",
        description: "Financial services",
      },
      {
        label: "Information services",
        value: "IFS",
        description: "Information services",
      },
      {
        label: "Professional and management consulting services",
        value: "PMS",
        description: "Professional and management consulting services",
      },
      {
        label: "Technical trade-related and other business services ",
        value: "TTS",
        description: "Technical trade-related and other business services",
      },
      // Skipping other codes to keep it simple
      // See https://support.currencycloud.com/hc/en-gb/articles/360017430000
    ],
  },
  AM: {
    label: "Armenia",
    value: "AM",
    countryCode: "AM",
    currencyCode: "AMD",
    currencySymbol: "֏",
    currencyName: "Armenian dram",
    currencyFlag: "🇦🇲",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AO: {
    label: "Angola",
    value: "AO",
    countryCode: "AO",
    currencyCode: "AOA",
    currencySymbol: "Kz",
    currencyName: "Angolan kwanza",
    currencyFlag: "🇦🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AR: {
    label: "Argentina",
    value: "AR",
    countryCode: "AR",
    currencyCode: "ARS",
    currencySymbol: "$",
    currencyName: "Argentine peso",
    currencyFlag: "🇦🇷",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AS: {
    label: "American Samoa",
    value: "AS",
    countryCode: "AS",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "United States dollar",
    currencyFlag: "🇺🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AT: {
    label: "Austria",
    value: "AT",
    countryCode: "AT",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  AQ: {
    label: "Antarctica",
    value: "AQ",
    countryCode: "AQ",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "Antarctican dollar",
    currencyFlag: "🇦🇶",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AU: {
    label: "Australia",
    value: "AU",
    countryCode: "AU",
    currencyCode: "AUD",
    currencyName: "Australian Dollar",
    currencySymbol: "$",
    currencyFlag: "🇦🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BSB_CODE,
      ]),
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^[0-9A-Z]{5,20}$"),
    },
  },
  AW: {
    label: "Aruba",
    value: "AW",
    countryCode: "AW",
    currencyCode: "AWG",
    currencySymbol: "ƒ",
    currencyName: "Aruban florin",
    currencyFlag: "🇦🇼",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AX: {
    label: "Åland Islands",
    value: "AX",
    countryCode: "AX",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  AZ: {
    label: "Azerbaijan",
    value: "AZ",
    countryCode: "AZ",
    currencyCode: "AZN",
    currencySymbol: "₼",
    currencyName: "Azerbaijani manat",
    currencyFlag: "🇦🇿",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BA: {
    label: "Bosnia and Herzegovina",
    value: "BA",
    countryCode: "BA",
    currencyCode: "BAM",
    currencySymbol: "KM",
    currencyName: "Bosnia and Herzegovina convertible mark",
    currencyFlag: "🇧🇦",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BB: {
    label: "Barbados",
    value: "BB",
    countryCode: "BB",
    currencyCode: "BBD",
    currencySymbol: "$",
    currencyName: "Barbadian dollar",
    currencyFlag: "🇧🇧",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BE: {
    label: "Belgium",
    value: "BE",
    countryCode: "BE",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  BD: {
    label: "Bangladesh",
    value: "BD",
    countryCode: "BD",
    currencyCode: "BDT",
    currencySymbol: "৳",
    currencyName: "Bangladeshi Taka",
    currencyFlag: "🇧🇩",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BF: {
    label: "Burkina Faso",
    value: "BF",
    countryCode: "BF",
    currencyCode: "XOF",
    currencySymbol: "Fr",
    currencyName: "West African CFA franc",
    currencyFlag: "🇧🇫",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BG: {
    label: "Bulgaria",
    value: "BG",
    countryCode: "BG",
    currencyCode: "BGN",
    currencySymbol: "лв",
    currencyName: "Bulgarian lev",
    currencyFlag: "🇧🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  BH: {
    label: "Bahrain",
    value: "BH",
    countryCode: "BH",
    currencyCode: "BHD",
    currencySymbol: ".د.ب",
    currencyName: "Bahraini Dinar",
    currencyFlag: "🇧🇭",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    // prettier-ignore
    purposeCodes: [
      { value: 'GDE', label: 'GDE', description: 'Goods sold (Exports in fob value)' },
      { value: 'GDI', label: 'GDI', description: 'Goods bought (Imports in cif value)' },
      { value: 'STS', label: 'STS', description: 'Sea Transport' },
      { value: 'ATS', label: 'ATS', description: 'Air transport' },
      { value: 'OTS', label: 'OTS', description: 'OTS Other methods of transport (including Postal and courier services)' },
      { value: 'STR', label: 'STR', description: 'Travel' },
      { value: 'GMS', label: 'GMS', description: 'Processing repair and maintenance services on goods' },
      { value: 'SCO', label: 'SCO', description: 'Construction' },
      { value: 'INS', label: 'INS', description: 'Insurance Services' },
      { value: 'FIS', label: 'FIS', description: 'Financial Services' },
      { value: 'IPC', label: 'IPC', description: 'Charges for the use of intellectual property royalties' },
      { value: 'TCS', label: 'TCS', description: 'Telecommunications services' },
      { value: 'ITS', label: 'ITS', description: 'Computer services' },
      { value: 'IFS', label: 'IFS', description: 'Information services' },
      { value: 'RDS', label: 'RDS', description: 'Research and development services' },
      { value: 'PMS', label: 'PMS', description: 'Professional and management consulting services' },
      { value: 'TTS', label: 'TTS', description: 'Technical, trade- related and other business services' },
      { value: 'PRS', label: 'PRS', description: 'Personal, cultural, audiovisual and recreational services' },
      { value: 'IGD', label: 'IGD', description: 'Dividends intragroup' },
      { value: 'IID', label: 'IID', description: 'Interest on debt intragroup' },
      { value: 'PIP', label: 'PIP', description: 'Profits on Islamic products' },
      { value: 'PRR', label: 'PRR', description: 'Profits or rents on real estate' },
      { value: 'DOE', label: 'DOE', description: 'Dividends on equity not Intragroup' },
      { value: 'ISH', label: 'ISH', description: 'Income on investment funds shares' },
      { value: 'ISL', label: 'ISL', description: 'Interest on securities more than a year' },
      { value: 'ISS', label: 'ISS', description: 'Interest on securities less than a year' },
      { value: 'IOL', label: 'IOL', description: 'Income on loans' },
      { value: 'IOD', label: 'IOD', description: 'Income on deposits' },
      { value: 'GOS', label: 'GOS', description: 'Government goods and services embassies etc' },
      { value: 'GRI', label: 'GRI', description: 'Government related income taxes, tariffs, capital transfers, etc' },
      { value: 'CHC', label: 'CHC', description: 'Charitable Contributions (Charity and Aid)' },
      { value: 'FAM', label: 'FAM', description: 'Family Support (Workers’ remittances)' },
      { value: 'SAL', label: 'SAL', description: 'Salary (Compensation of employees)' },
      { value: 'PPA', label: 'PPA', description: 'Purchase of real estate abroad from residents' },
      { value: 'PPL', label: 'PPL', description: 'Purchase of real estate in Bahrain from non-residents' },
      { value: 'CEA', label: 'CEA', description: 'Equity and Investment fund shares for the establishment of new company from residents abroad, equity of merger or acquisition of companies abroad from residents and participation to capital increase of related companies abroad' },
      { value: 'DSF', label: 'DSF', description: 'Debt instruments Intragroup foreign securities' },
      { value: 'REL', label: 'REL', description: 'Reverse equity share in Bahrain' },
      { value: 'RDL', label: 'RDL', description: 'Reverse debt instruments in Bahrain' },
      { value: 'FSA', label: 'FSA', description: 'Equity other than investment fund shares in not related companies abroad' },
      { value: 'FIA', label: 'FIA', description: 'Investment fund shares foreign' },
      { value: 'DSA', label: 'DSA', description: 'Purchases and sales of foreign debt securities in not related companies- Less than a year' },
      { value: 'DLA', label: 'DLA', description: 'Purchases and sales of foreign debt securities in not related companies- More than a year' },
      { value: 'FDA', label: 'FDA', description: 'Financial derivatives foreign' },
      { value: 'DLF', label: 'DLF', description: 'Debt Instruments Intragroup loans, deposits foreign (above 10% share)' },
      { value: 'AFA', label: 'AFA', description: 'Receipts or payments from personal residents bank account or deposits abroad' },
      { value: 'SLA', label: 'SLA', description: 'Loans- Drawings or Repayments on loans extended to nonresidents- Short-term' },
      { value: 'LLA', label: 'LLA', description: 'Loans- Drawings or Repayments on loans extended to nonresidents- Long-term' },
      { value: 'LEA', label: 'LEA', description: 'Leasing abroad' },
      { value: 'RFS', label: 'RFS', description: 'Repos on foreign securities' },
      { value: 'TCR', label: 'TCR', description: 'Trade credits and advances receivable' },
      { value: 'CEL', label: 'CEL', description: 'Equity and Investment fund shares for the establishment of new company in Bahrain from non-residents, equity of merger or acquisition of companies in Bahrain from non-residents and participation to capital increase of related companies from non-residents in Bahrain' },
      { value: 'LDS', label: 'LDS', description: 'Debt instruments intragroup securities in Bahrain' },
      { value: 'REA', label: 'REA', description: 'Reverse equity share abroad' },
      { value: 'RDA', label: 'RDA', description: 'Reverse debt instruments abroad' },
      { value: 'FSL', label: 'FSL', description: 'Equity other than investment fund shares in not related companies in Bahrain' },
      { value: 'FIL', label: 'FIL', description: 'Investment fund shares in Bahrain' },
      { value: 'DSL', label: 'DSL', description: 'Purchases and sales of securities issued by residents in not related companies- Less than a year' },
      { value: 'DLL', label: 'DLL', description: 'Purchases and sales of securities issued by residents in not related companies- More than a year' },
      { value: 'FDL', label: 'FDL', description: 'Financial derivatives in Bahrain' },
      { value: 'LDL', label: 'LDL', description: 'Debt Instruments Intragroup loans, deposits in Bahrain (above 10% share)' },
      { value: 'AFL', label: 'AFL', description: 'Receipts or payments from personal nonresidents bank account in Bahrain' },
      { value: 'SLL', label: 'SLL', description: 'Loans- Drawings or Repayments on foreign loans extended to residents- Short-term' },
      { value: 'LLL', label: 'LLL', description: 'Loans- Drawings or Repayments on foreign loans extended to residents- Long-term' },
      { value: 'LEL', label: 'LEL', description: 'Leasing in Bahrain' },
      { value: 'RLS', label: 'RLS', description: 'Repos on securities issued by residents' },
      { value: 'TCP', label: 'TCP', description: 'Trade credits and advances payable' },
    ],
  },
  BI: {
    label: "Burundi",
    value: "BI",
    countryCode: "BI",
    currencyCode: "BIF",
    currencySymbol: "Fr",
    currencyName: "Burundian Franc",
    currencyFlag: "🇧🇮",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BJ: {
    label: "Benin",
    value: "BJ",
    countryCode: "BJ",
    currencyCode: "XOF",
    currencySymbol: "Fr",
    currencyName: "West African CFA franc",
    currencyFlag: "🇧🇯",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BM: {
    label: "Bermuda",
    value: "BM",
    countryCode: "BM",
    currencyCode: "BMD",
    currencySymbol: "$",
    currencyName: "Bermudian Dollar",
    currencyFlag: "🇧🇲",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BN: {
    label: "Brunei Darussalam",
    value: "BN",
    countryCode: "BN",
    currencyCode: "BND",
    currencySymbol: "$",
    currencyName: "Brunei Dollar",
    currencyFlag: "🇧🇳",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BO: {
    label: "Bolivia",
    value: "BO",
    countryCode: "BO",
    currencyCode: "BOB",
    currencySymbol: "Bs.",
    currencyName: "Bolivian Boliviano",
    currencyFlag: "🇧🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BQ: {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "BQ",
    countryCode: "BQ",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "United States Dollar",
    currencyFlag: "🇧🇶",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BR: {
    label: "Brazil",
    value: "BR",
    countryCode: "BR",
    currencyCode: "BRL",
    currencySymbol: "R$",
    currencyName: "Brazilian Real",
    currencyFlag: "🇧🇷",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BS: {
    label: "Bahamas",
    value: "BS",
    countryCode: "BS",
    currencyCode: "BSD",
    currencySymbol: "$",
    currencyName: "Bahamian Dollar",
    currencyFlag: "🇧🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BT: {
    label: "Bhutan",
    value: "BT",
    countryCode: "BT",
    currencyCode: "BTN",
    currencySymbol: "Nu.",
    currencyName: "Bhutanese Ngultrum",
    currencyFlag: "🇧🇹",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BV: {
    label: "Bouvet Island",
    value: "BV",
    countryCode: "BV",
    currencyCode: "NOK",
    currencySymbol: "kr",
    currencyName: "Norwegian Krone",
    currencyFlag: "🇧🇻",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  BW: {
    label: "Botswana",
    value: "BW",
    countryCode: "BW",
    currencyCode: "BWP",
    currencySymbol: "P",
    currencyName: "Botswana Pula",
    currencyFlag: "🇧🇼",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BY: {
    label: "Belarus",
    value: "BY",
    countryCode: "BY",
    currencyCode: "BYN",
    currencySymbol: "Br",
    currencyName: "Belarusian Ruble",
    currencyFlag: "🇧🇾",
    usd: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  BZ: {
    label: "Belize",
    value: "BZ",
    countryCode: "BZ",
    currencyCode: "BZD",
    currencySymbol: "$",
    currencyName: "Belize Dollar",
    currencyFlag: "🇧🇿",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CA: {
    label: "Canada",
    value: "CA",
    countryCode: "CA",
    currencyCode: "CAD",
    currencySymbol: "$",
    currencyName: "Canadian Dollar",
    currencyFlag: "🇨🇦",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.PROVINCE,
        INTERNATIONAL_WIRE_INPUTS.POSTAL_CODE,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.PROVINCE,
        INTERNATIONAL_WIRE_INPUTS.POSTAL_CODE,
        INTERNATIONAL_WIRE_INPUTS.BANK_CODE,
        INTERNATIONAL_WIRE_INPUTS.BRANCH_CODE,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
      ]),
      // Limit of 100,000 CAD per transaction for local transfers
      paymentLimitinCents: 10000000,
    },
  },
  CC: {
    label: "Cocos (Keeling) Islands",
    value: "CC",
    countryCode: "CC",
    currencyCode: "AUD",
    currencySymbol: "$",
    currencyName: "Australian Dollar",
    currencyFlag: "🇨🇨",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  CH: {
    label: "Switzerland",
    value: "CH",
    countryCode: "CH",
    currencyCode: "CHF",
    currencySymbol: "CHF",
    currencyName: "Swiss Franc",
    currencyFlag: "🇨🇭",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  CD: {
    label: "Congo, Democratic Republic of the",
    value: "CD",
    countryCode: "CD",
    currencyCode: "CDF",
    currencySymbol: "FC",
    currencyName: "Congolese Franc",
    currencyFlag: "🇨🇩",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CF: {
    label: "Central African Republic",
    value: "CF",
    countryCode: "CF",
    currencyCode: "XAF",
    currencySymbol: "FCFA",
    currencyName: "Central African CFA Franc",
    currencyFlag: "🇨🇫",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CG: {
    label: "Congo, Republic of the",
    value: "CG",
    countryCode: "CG",
    currencyCode: "XAF",
    currencySymbol: "FCFA",
    currencyName: "Central African CFA Franc",
    currencyFlag: "🇨🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CI: {
    label: "Cote d'Ivoire",
    value: "CI",
    countryCode: "CI",
    currencyCode: "XOF",
    currencySymbol: "CFA",
    currencyName: "West African CFA Franc",
    currencyFlag: "🇨🇮",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CK: {
    label: "Cook Islands",
    value: "CK",
    countryCode: "CK",
    currencyCode: "NZD",
    currencySymbol: "$",
    currencyName: "New Zealand Dollar",
    currencyFlag: "🇨🇰",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  CL: {
    label: "Chile",
    value: "CL",
    countryCode: "CL",
    currencyCode: "CLP",
    currencySymbol: "$",
    currencyName: "Chilean Peso",
    currencyFlag: "🇨🇱",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CM: {
    label: "Cameroon",
    value: "CM",
    countryCode: "CM",
    currencyCode: "XAF",
    currencySymbol: "FCFA",
    currencyName: "Central African CFA Franc",
    currencyFlag: "🇨🇲",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CN: {
    label: "China",
    value: "CN",
    countryCode: "CN",
    currencyCode: "CNY",
    currencySymbol: "¥",
    currencyName: "Chinese Yuan",
    currencyFlag: "🇨🇳",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.CNAPS,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
        INTERNATIONAL_WIRE_INPUTS.PURPOSE_CODE,
      ]),
    },
    purposeCodes: [
      {
        label: "Capital transfer ",
        value: "CTF",
        description: "Cross-border capital transfer",
      },
      {
        label: "Goods",
        value: "GOD",
        description: "Cross-border goods trade",
      },
      {
        label: "Individual remittance",
        value: "RMT",
        description: "Cross-border individual remittance",
      },
      {
        label: "Transfer",
        value: "CTF",
        description: "Cross-border capital transfer",
      },
      {
        label: "Service",
        value: "STR",
        description: "Cross-border service transfer",
      },
      {
        label: "Other",
        value: "OTF",
        description: "Other transfers",
      },
    ],
  },
  CO: {
    label: "Colombia",
    value: "CO",
    countryCode: "CO",
    currencyCode: "COP",
    currencySymbol: "$",
    currencyName: "Colombian Peso",
    currencyFlag: "🇨🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CR: {
    label: "Costa Rica",
    value: "CR",
    countryCode: "CR",
    currencyCode: "CRC",
    currencySymbol: "₡",
    currencyName: "Costa Rican Colon",
    currencyFlag: "🇨🇷",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CV: {
    label: "Cape Verde",
    value: "CV",
    countryCode: "CV",
    currencyCode: "CVE",
    currencySymbol: "$",
    currencyName: "Cape Verdean Escudo",
    currencyFlag: "🇨🇻",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CW: {
    label: "Curaçao",
    value: "CW",
    countryCode: "CW",
    currencyCode: "ANG",
    currencySymbol: "ƒ",
    currencyName: "Netherlands Antillean Guilder",
    currencyFlag: "🇨🇼",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  CX: {
    label: "Christmas Island",
    value: "CX",
    countryCode: "CX",
    currencyCode: "AUD",
    currencySymbol: "$",
    currencyName: "Australian Dollar",
    currencyFlag: "🇨🇽",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  CY: {
    label: "Cyprus",
    value: "CY",
    countryCode: "CY",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  CZ: {
    label: "Czech Republic",
    value: "CZ",
    countryCode: "CZ",
    currencyCode: "CZK",
    currencySymbol: "Kč",
    currencyName: "Czech Koruna",
    currencyFlag: "🇨🇿",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
      // https://support.currencycloud.com/hc/en-gb/articles/360017498759-Czech-Koruna-CZK-
      paymentLimitinCents: 100000000,
    },
  },
  DE: {
    label: "Germany",
    value: "DE",
    countryCode: "DE",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  DJ: {
    label: "Djibouti",
    value: "DJ",
    countryCode: "DJ",
    currencyCode: "DJF",
    currencySymbol: "Fdj",
    currencyName: "Djiboutian Franc",
    currencyFlag: "🇩🇯",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  DK: {
    label: "Denmark",
    value: "DK",
    countryCode: "DK",
    currencyCode: "DKK",
    currencySymbol: "kr",
    currencyName: "Danish Krone",
    currencyFlag: "🇩🇰",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.BANK_CODE,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
      ]),
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.BANK_CODE]: new RegExp("^\\d{4}$"),
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^\\d{4,10}$"),
    },
  },
  DM: {
    label: "Dominica",
    value: "DM",
    countryCode: "DM",
    currencyCode: "XCD",
    currencySymbol: "$",
    currencyName: "East Caribbean Dollar",
    currencyFlag: "🇩🇲",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  DO: {
    label: "Dominican Republic",
    value: "DO",
    countryCode: "DO",
    currencyCode: "DOP",
    currencySymbol: "RD$",
    currencyName: "Dominican Peso",
    currencyFlag: "🇩🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  DZ: {
    label: "Algeria",
    value: "DZ",
    countryCode: "DZ",
    currencyCode: "DZD",
    currencySymbol: "د.ج",
    currencyName: "Algerian Dinar",
    currencyFlag: "🇩🇿",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  EC: {
    label: "Ecuador",
    value: "EC",
    countryCode: "EC",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇪🇨",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  EE: {
    label: "Estonia",
    value: "EE",
    countryCode: "EE",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  EG: {
    label: "Egypt",
    value: "EG",
    countryCode: "EG",
    currencyCode: "EGP",
    currencySymbol: "E£",
    currencyName: "Egyptian Pound",
    currencyFlag: "🇪🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  ER: {
    label: "Eritrea",
    value: "ER",
    countryCode: "ER",
    currencyCode: "ERN",
    currencySymbol: "Nfk",
    currencyName: "Nakfa",
    currencyFlag: "🇪🇷",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  EH: {
    label: "Western Sahara",
    value: "EH",
    countryCode: "EH",
    currencyCode: "MAD",
    currencySymbol: "MAD",
    currencyName: "Moroccan Dirham",
    currencyFlag: "🇲🇦",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  ES: {
    label: "Spain",
    value: "ES",
    countryCode: "ES",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  ET: {
    label: "Ethiopia",
    value: "ET",
    countryCode: "ET",
    currencyCode: "ETB",
    currencySymbol: "Br",
    currencyName: "Ethiopian Birr",
    currencyFlag: "🇪🇹",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  FI: {
    label: "Finland",
    value: "FI",
    countryCode: "FI",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  FJ: {
    label: "Fiji",
    value: "FJ",
    countryCode: "FJ",
    currencyCode: "FJD",
    currencySymbol: "FJ$",
    currencyName: "Fijian dollar",
    currencyFlag: "🇫🇯",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  FK: {
    label: "Falkland Islands (Malvinas)",
    value: "FK",
    countryCode: "FK",
    currencyCode: "FKP",
    currencySymbol: "£",
    currencyName: "Falkland Islands Pound",
    currencyFlag: "🇫🇰",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  FM: {
    label: "Micronesia, Federated States of",
    value: "FM",
    countryCode: "FM",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇺🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  FO: {
    label: "Faroe Islands",
    value: "FO",
    countryCode: "FO",
    currencyCode: "DKK",
    currencySymbol: "kr",
    currencyName: "Danish Krone",
    currencyFlag: "🇩🇰",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.BANK_CODE,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
      ]),
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.BANK_CODE]: new RegExp("^\\d{4}$"),
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^\\d{4,10}$"),
    },
  },
  FR: {
    label: "France",
    value: "FR",
    countryCode: "FR",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  GA: {
    label: "Gabon",
    value: "GA",
    countryCode: "GA",
    currencyCode: "XAF",
    currencySymbol: "FCFA",
    currencyName: "CFA Franc BEAC",
    currencyFlag: "🇨🇫",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GB: {
    label: "United Kingdom",
    value: "GB",
    countryCode: "GB",
    currencyCode: "GBP",
    currencySymbol: "£",
    currencyName: "Pound Sterling",
    currencyFlag: "🇬🇧",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.SORT_CODE,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
      ]),
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^\\d{8}$"),
    },
  },
  GD: {
    label: "Grenada",
    value: "GD",
    countryCode: "GD",
    currencyCode: "XCD",
    currencySymbol: "$",
    currencyName: "East Caribbean Dollar",
    currencyFlag: "🇬🇩",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GE: {
    label: "Georgia",
    value: "GE",
    countryCode: "GE",
    currencyCode: "GEL",
    currencySymbol: "₾",
    currencyName: "Georgian Lari",
    currencyFlag: "🇬🇪",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GQ: {
    label: "Equatorial Guinea",
    value: "GQ",
    countryCode: "GQ",
    currencyCode: "XAF",
    currencySymbol: "FCFA",
    currencyName: "Central African CFA Franc",
    currencyFlag: "🇬🇶",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GF: {
    label: "French Guiana",
    value: "GF",
    countryCode: "GF",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GG: {
    label: "Guernsey",
    value: "GG",
    countryCode: "GG",
    currencyCode: "GBP",
    currencySymbol: "£",
    currencyName: "Pound Sterling",
    currencyFlag: "🇬🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.SORT_CODE,
      ]),
    },
  },
  GH: {
    label: "Ghana",
    value: "GH",
    countryCode: "GH",
    currencyCode: "GHS",
    currencySymbol: "GH₵",
    currencyName: "Ghanaian Cedi",
    currencyFlag: "🇬🇭",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GI: {
    label: "Gibraltar",
    value: "GI",
    countryCode: "GI",
    currencyCode: "GIP",
    currencySymbol: "£",
    currencyName: "Gibraltar Pound",
    currencyFlag: "🇬🇮",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GL: {
    label: "Greenland",
    value: "GL",
    countryCode: "GL",
    currencyCode: "DKK",
    currencySymbol: "kr",
    currencyName: "Danish Krone",
    currencyFlag: "🇩🇰",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.BANK_CODE,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
      ]),
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.BANK_CODE]: new RegExp("^\\d{4}$"),
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^\\d{4,10}$"),
    },
  },
  GM: {
    label: "Gambia",
    value: "GM",
    countryCode: "GM",
    currencyCode: "GMD",
    currencySymbol: "D",
    currencyName: "Dalasi",
    currencyFlag: "🇬🇲",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GN: {
    label: "Guinea",
    value: "GN",
    countryCode: "GN",
    currencyCode: "GNF",
    currencySymbol: "FG",
    currencyName: "Guinean Franc",
    currencyFlag: "🇬🇳",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GP: {
    label: "Guadeloupe",
    value: "GP",
    countryCode: "GP",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GR: {
    label: "Greece",
    value: "GR",
    countryCode: "GR",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  GT: {
    label: "Guatemala",
    value: "GT",
    countryCode: "GT",
    currencyCode: "GTQ",
    currencySymbol: "Q",
    currencyName: "Guatemalan quetzal",
    currencyFlag: "🇬🇹",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GU: {
    label: "Guam",
    value: "GU",
    countryCode: "GU",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇺🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GW: {
    label: "Guinea-Bissau",
    value: "GW",
    countryCode: "GW",
    currencyCode: "XOF",
    currencySymbol: "CFA",
    currencyName: "CFA Franc BCEAO",
    currencyFlag: "🇬🇼",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  GY: {
    label: "Guyana",
    value: "GY",
    countryCode: "GY",
    currencyCode: "GYD",
    currencySymbol: "$",
    currencyName: "Guyanese Dollar",
    currencyFlag: "🇬🇾",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  HK: {
    label: "Hong Kong",
    value: "HK",
    countryCode: "HK",
    currencyCode: "HKD",
    currencySymbol: "$",
    currencyName: "Hong Kong Dollar",
    currencyFlag: "🇭🇰",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.BANK_CODE,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.BANK_CODE]: new RegExp("^[0-9A-Za-z]{3}$"),
    },
  },
  HN: {
    label: "Honduras",
    value: "HN",
    countryCode: "HN",
    currencyCode: "HNL",
    currencySymbol: "L",
    currencyName: "Lempira",
    currencyFlag: "🇭🇳",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  HR: {
    label: "Croatia",
    value: "HR",
    countryCode: "HR",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  HT: {
    label: "Haiti",
    value: "HT",
    countryCode: "HT",
    currencyCode: "HTG",
    currencySymbol: "G",
    currencyName: "Gourde",
    currencyFlag: "🇭🇹",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  HU: {
    label: "Hungary",
    value: "HU",
    countryCode: "HU",
    currencyCode: "HUF",
    currencySymbol: "Ft",
    currencyName: "Forint",
    currencyFlag: "🇭🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
      // https://support.currencycloud.com/hc/en-gb/articles/360017510599-Hungarian-Forint-HUF-
      paymentLimitinCents: 1000000000,
    },
  },
  ID: {
    label: "Indonesia",
    value: "ID",
    countryCode: "ID",
    currencyCode: "IDR",
    currencySymbol: "Rp",
    currencyName: "Rupiah",
    currencyFlag: "🇮🇩",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
      // https://support.currencycloud.com/hc/en-gb/articles/360017457700-Indonesian-Rupiah-IDR-
      paymentLimitinCents: 35000000000,
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^\\d{1,16}$"),
    },
  },
  IE: {
    label: "Ireland",
    value: "IE",
    countryCode: "IE",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  IO: {
    label: "British Indian Ocean Territory",
    value: "IO",
    countryCode: "IO",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇺🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  IL: {
    label: "Israel",
    value: "IL",
    countryCode: "IL",
    currencyCode: "ILS",
    currencySymbol: "₪",
    currencyName: "Shekel",
    currencyFlag: "🇮🇱",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  IM: {
    label: "Isle of Man",
    value: "IM",
    countryCode: "IM",
    currencyCode: "GBP",
    currencySymbol: "£",
    currencyName: "Pound Sterling",
    currencyFlag: "🇬🇧",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.SORT_CODE,
      ]),
    },
  },
  IQ: {
    label: "Iraq",
    value: "IQ",
    countryCode: "IQ",
    currencyCode: "IQD",
    currencySymbol: "ع.د",
    currencyName: "Dinar",
    currencyFlag: "🇮🇶",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  IS: {
    label: "Iceland",
    value: "IS",
    countryCode: "IS",
    currencyCode: "ISK",
    currencySymbol: "kr",
    currencyName: "Krona",
    currencyFlag: "🇮🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  KH: {
    label: "Cambodia",
    value: "KH",
    countryCode: "KH",
    currencyCode: "KHR",
    currencySymbol: "៛",
    currencyName: "Cambodian Riel",
    currencyFlag: "🇰🇭",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  KM: {
    label: "Comoros",
    value: "KM",
    countryCode: "KM",
    currencyCode: "KMF",
    currencySymbol: "CF",
    currencyName: "Comorian Franc",
    currencyFlag: "🇰🇲",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  KY: {
    label: "Cayman Islands",
    value: "KY",
    countryCode: "KY",
    currencyCode: "KYD",
    currencySymbol: "$",
    currencyName: "Cayman Islands Dollar",
    currencyFlag: "🇰🇾",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  IN: {
    label: "India",
    value: "IN",
    countryCode: "IN",
    currencyCode: "INR",
    currencySymbol: "₹",
    currencyName: "Indian Rupee",
    currencyFlag: "🇮🇳",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.IFSC,
        INTERNATIONAL_WIRE_INPUTS.PURPOSE_CODE,
        INTERNATIONAL_WIRE_INPUTS.INVOICE_DATE,
        INTERNATIONAL_WIRE_INPUTS.INVOICE_NUMBER,
      ]),
      // Limit of 500,000 INR per transaction
      // Up to 1,500,000 INR per day but we don't enforce that in code atm
      paymentLimitinCents: 50000000,
    },
    purposeCodes: [
      {
        label: "Advertising",
        value: "advertising",
        description: "Advertising and public relations-related expenses",
      },
      {
        label: "Advisor Fees",
        value: "advisor_fees",
        description: "Fees for advisory, technical, academic or specialist assistance",
      },
      {
        label: "Construction",
        value: "construction",
        description: "Construction costs/expenses",
      },
      {
        label: "Education",
        value: "education",
        description: "Education-related student expenses",
      },
      {
        label: "Exports",
        value: "exports",
        description: "Payments for exported goods",
      },
      {
        label: "Family",
        value: "family",
        description: "Family maintenance",
      },
      {
        label: "Fund Investment",
        value: "fund_investment",
        description: "Mutual fund investment",
      },
      {
        label: "Goods",
        value: "goods",
        description: "Trade settlement for goods and general goods trades",
      },
      {
        label: "Hotel",
        value: "hotel",
        description: "Hotel accommodation",
      },
      {
        label: "Insurance Claims",
        value: "insurance_claims",
        description: "Insurance claims payment",
      },
      {
        label: "Insurance Premium",
        value: "insurance_premium",
        description: "Insurance premium",
      },
      {
        label: "Product Indemnity",
        value: "product_indemnity",
        description: "Product indemnity insurance",
      },
      {
        label: "Loan Repayment",
        value: "loan_repayment",
        description: "Repayment of loans",
      },
      {
        label: "Medical",
        value: "medical",
        description: "Medical treatment and expenses",
      },
      {
        label: "Other Fees",
        value: "other_fees",
        description: "Broker, front end, commitment, guarantee and custodian fees",
      },
      {
        label: "Property Purchase",
        value: "property_purchase",
        description: "Purchase of residential property",
      },
      {
        label: "Property Rental",
        value: "property_rental",
        description: "Payment of Property Rental",
      },
      {
        label: "Royalties",
        value: "royalties",
        description: "Royalty, trademark, patent and copyright fees",
      },
      {
        label: "Salary",
        value: "salary",
        description: "Salary",
      },
      {
        label: "Services",
        value: "services",
        description: "Information service charges",
      },
      {
        label: "Share Investment",
        value: "share_investment",
        description: "Investment in Shares",
      },
      {
        label: "Tax",
        value: "tax",
        description: "Tax payment",
      },
      {
        label: "Transfer",
        value: "transfer",
        description: "Transfer to own account",
      },
      {
        label: "Travel",
        value: "travel",
        description: "Travel",
      },
      {
        label: "Utilities",
        value: "utilities",
        description: "Utility bills",
      },
    ],
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^[0-9A-Z]{1,20}$"),
    },
  },
  IT: {
    label: "Italy",
    value: "IT",
    countryCode: "IT",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  JE: {
    label: "Jersey",
    value: "JE",
    countryCode: "JE",
    currencyCode: "GBP",
    currencySymbol: "£",
    currencyName: "British Pound",
    currencyFlag: "🇬🇧",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.SORT_CODE,
      ]),
    },
  },
  JM: {
    label: "Jamaica",
    value: "JM",
    countryCode: "JM",
    currencyCode: "JMD",
    currencySymbol: "J$",
    currencyName: "Jamaican Dollar",
    currencyFlag: "🇯🇲",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  JP: {
    label: "Japan",
    value: "JP",
    countryCode: "JP",
    currencyCode: "JPY",
    currencySymbol: "¥",
    currencyName: "Japanese Yen",
    currencyFlag: "🇯🇵",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  JO: {
    label: "Jordan",
    value: "JO",
    countryCode: "JO",
    currencyCode: "JOD",
    currencySymbol: "د.ا",
    currencyName: "Jordanian Dinar",
    currencyFlag: "🇯🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  KE: {
    label: "Kenya",
    value: "KE",
    countryCode: "KE",
    currencyCode: "KES",
    currencySymbol: "KSh",
    currencyName: "Kenyan Shilling",
    currencyFlag: "🇰🇪",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  KG: {
    label: "Kyrgyzstan",
    value: "KG",
    countryCode: "KG",
    currencyCode: "KGS",
    currencySymbol: "лв",
    currencyName: "Kyrgyzstani Som",
    currencyFlag: "🇰🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  KI: {
    label: "Kiribati",
    value: "KI",
    countryCode: "KI",
    currencyCode: "AUD",
    currencySymbol: "$",
    currencyName: "Australian Dollar",
    currencyFlag: "🇦🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  KN: {
    label: "Saint Kitts and Nevis",
    value: "KN",
    countryCode: "KN",
    currencyCode: "XCD",
    currencySymbol: "$",
    currencyName: "East Caribbean Dollar",
    currencyFlag: "🇰🇳",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  KR: {
    label: "Korea, Republic of",
    value: "KR",
    countryCode: "KR",
    currencyCode: "KRW",
    currencySymbol: "₩",
    currencyName: "South Korean Won",
    currencyFlag: "🇰🇷",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  KW: {
    label: "Kuwait",
    value: "KW",
    countryCode: "KW",
    currencyCode: "KWD",
    currencySymbol: "د.ك",
    currencyName: "Kuwaiti Dinar",
    currencyFlag: "🇰🇼",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  KZ: {
    label: "Kazakhstan",
    value: "KZ",
    countryCode: "KZ",
    currencyCode: "KZT",
    currencySymbol: "₸",
    currencyName: "Kazakhstani Tenge",
    currencyFlag: "🇰🇿",
    usd: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  LA: {
    label: "Lao People's Democratic Republic",
    value: "LA",
    countryCode: "LA",
    currencyCode: "LAK",
    currencySymbol: "₭",
    currencyName: "Lao Kip",
    currencyFlag: "🇱🇦",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  LB: {
    label: "Lebanon",
    value: "LB",
    countryCode: "LB",
    currencyCode: "LBP",
    currencySymbol: "ل.ل",
    currencyName: "Lebanese Pound",
    currencyFlag: "🇱🇧",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  LC: {
    label: "Saint Lucia",
    value: "LC",
    countryCode: "LC",
    currencyCode: "XCD",
    currencySymbol: "$",
    currencyName: "East Caribbean Dollar",
    currencyFlag: "🇱🇨",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  LI: {
    label: "Liechtenstein",
    value: "LI",
    countryCode: "LI",
    currencyCode: "CHF",
    currencySymbol: "CHF",
    currencyName: "Swiss Franc",
    currencyFlag: "🇨🇭",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  LK: {
    label: "Sri Lanka",
    value: "LK",
    countryCode: "LK",
    currencyCode: "LKR",
    currencySymbol: "₨",
    currencyName: "Sri Lankan Rupee",
    currencyFlag: "🇱🇰",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  LR: {
    label: "Liberia",
    value: "LR",
    countryCode: "LR",
    currencyCode: "LRD",
    currencySymbol: "$",
    currencyName: "Liberian Dollar",
    currencyFlag: "🇱🇷",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  LS: {
    label: "Lesotho",
    value: "LS",
    countryCode: "LS",
    currencyCode: "LSL",
    currencySymbol: "L",
    currencyName: "Lesotho Loti",
    currencyFlag: "🇱🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  LT: {
    label: "Lithuania",
    value: "LT",
    countryCode: "LT",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  LU: {
    label: "Luxembourg",
    value: "LU",
    countryCode: "LU",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  LV: {
    label: "Latvia",
    value: "LV",
    countryCode: "LV",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  MA: {
    label: "Morocco",
    value: "MA",
    countryCode: "MA",
    currencyCode: "MAD",
    currencySymbol: "د.م.",
    currencyName: "Moroccan Dirham",
    currencyFlag: "🇲🇦",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MC: {
    label: "Monaco",
    value: "MC",
    countryCode: "MC",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  MD: {
    label: "Moldova",
    value: "MD",
    countryCode: "MD",
    currencyCode: "MDL",
    currencySymbol: "L",
    currencyName: "Moldovan Leu",
    currencyFlag: "🇲🇩",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  ME: {
    label: "Montenegro",
    value: "ME",
    countryCode: "ME",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MG: {
    label: "Madagascar",
    value: "MG",
    countryCode: "MG",
    currencyCode: "MGA",
    currencySymbol: "Ar",
    currencyName: "Malagasy Ariary",
    currencyFlag: "🇲🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MH: {
    label: "Marshall Islands",
    value: "MH",
    countryCode: "MH",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇺🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MK: {
    label: "North Macedonia",
    value: "MK",
    countryCode: "MK",
    currencyCode: "MKD",
    currencySymbol: "ден",
    currencyName: "Macedonian Denar",
    currencyFlag: "🇲🇰",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  ML: {
    label: "Mali",
    value: "ML",
    countryCode: "ML",
    currencyCode: "XOF",
    currencySymbol: "Fr",
    currencyName: "CFA Franc BCEAO",
    currencyFlag: "🇲🇱",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MM: {
    label: "Myanmar",
    value: "MM",
    countryCode: "MM",
    currencyCode: "MMK",
    currencySymbol: "Ks",
    currencyName: "Myanmar Kyat",
    currencyFlag: "🇲🇲",
    usd: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MN: {
    label: "Mongolia",
    value: "MN",
    countryCode: "MN",
    currencyCode: "MNT",
    currencySymbol: "₮",
    currencyName: "Mongolian Tögrög",
    currencyFlag: "🇲🇳",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MO: {
    label: "Macao",
    value: "MO",
    countryCode: "MO",
    currencyCode: "MOP",
    currencySymbol: "MOP$",
    currencyName: "Macanese Pataca",
    currencyFlag: "🇲🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MQ: {
    label: "Martinique",
    value: "MQ",
    countryCode: "MQ",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MR: {
    label: "Mauritania",
    value: "MR",
    countryCode: "MR",
    currencyCode: "MRO",
    currencySymbol: "UM",
    currencyName: "Mauritanian Ouguiya",
    currencyFlag: "🇲🇷",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MT: {
    label: "Malta",
    value: "MT",
    countryCode: "MT",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  MU: {
    label: "Mauritius",
    value: "MU",
    countryCode: "MU",
    currencyCode: "MUR",
    currencySymbol: "Rs",
    currencyName: "Mauritian Rupee",
    currencyFlag: "🇲🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MV: {
    label: "Maldives",
    value: "MV",
    countryCode: "MV",
    currencyCode: "MVR",
    currencySymbol: "Rf",
    currencyName: "Maldivian Rufiyaa",
    currencyFlag: "🇲🇻",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MW: {
    label: "Malawi",
    value: "MW",
    countryCode: "MW",
    currencyCode: "MWK",
    currencySymbol: "MK",
    currencyName: "Malawian Kwacha",
    currencyFlag: "🇲🇼",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  MX: {
    label: "Mexico",
    value: "MX",
    countryCode: "MX",
    currencyCode: "MXN",
    currencySymbol: "$",
    currencyName: "Mexican Peso",
    currencyFlag: "🇲🇽",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.STATE,
        INTERNATIONAL_WIRE_INPUTS.POSTAL_CODE,
        INTERNATIONAL_WIRE_INPUTS.CLABE,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.STATE,
        INTERNATIONAL_WIRE_INPUTS.POSTAL_CODE,
        INTERNATIONAL_WIRE_INPUTS.CLABE,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  MY: {
    label: "Malaysia",
    value: "MY",
    countryCode: "MY",
    currencyCode: "MYR",
    currencySymbol: "RM",
    currencyName: "Malaysian Ringgit",
    currencyFlag: "🇲🇾",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
        INTERNATIONAL_WIRE_INPUTS.PURPOSE_CODE,
      ]),
      // https://support.currencycloud.com/hc/en-gb/articles/360017457100-Malaysian-Ringgit-MYR-
      paymentLimitinCents: 10000000,
    },
    purposeCodes: [
      {
        label: "Advertising",
        value: "advertising",
        description: "Advertising and public relations-related expenses",
      },
      {
        label: "Advisor Fees",
        value: "advisor_fees",
        description: "Fees for advisory, technical, academic or specialist assistance",
      },
      {
        label: "Business Insurance",
        value: "business_insurance",
        description: "Product indemnity insurance",
      },
      {
        label: "Construction",
        value: "construction",
        description: "Construction costs/expenses",
      },
      {
        label: "Delivery",
        value: "delivery",
        description: "Delivery fees for goods",
      },
      {
        label: "Education",
        value: "education",
        description: "Education-related student expenses",
      },
      {
        label: "Exports",
        value: "exports",
        description: "Payments for exported goods",
      },
      {
        label: "Family",
        value: "family",
        description: "Family maintenance",
      },
      {
        label: "Fund Investment",
        value: "fund_investment",
        description: "Mutual fund investment",
      },
      {
        label: "Hotel",
        value: "hotel",
        description: "Hotel accommodation",
      },
      {
        label: "Insurance Claims",
        value: "insurance_claims",
        description: "Insurance claims payment",
      },
      {
        label: "Insurance Premium",
        value: "insurance_premium",
        description: "Insurance premium",
      },
      {
        label: "Loan Repayment",
        value: "loan_repayment",
        description: "Repayment of loans",
      },
      {
        label: "Medical",
        value: "medical",
        description: "Medical treatment and expenses",
      },
      {
        label: "Office",
        value: "office",
        description: "Representative office expenses",
      },
      {
        label: "Other Fees",
        value: "other_fees",
        description: "Broker, front end, commitment, guarantee and custodian fees",
      },
      {
        label: "Property Purchase",
        value: "property_purchase",
        description: "Purchase of residential property",
      },
      {
        label: "Property Rental",
        value: "property_rental",
        description: "Property rental payment",
      },
      {
        label: "Royalties",
        value: "royalties",
        description: "Royalty, trademark, patent and copyright fees",
      },
      {
        label: "Services",
        value: "services",
        description: "Information service charges",
      },
      {
        label: "Share Investment",
        value: "share_investment",
        description: "Investment in shares",
      },
      {
        label: "Tax",
        value: "tax",
        description: "Tax payment",
      },
      {
        label: "Transfer",
        value: "transfer",
        description: "Transfer to own account",
      },
      {
        label: "Transportation",
        value: "transportation",
        description: "Transportation fees for goods",
      },
      {
        label: "Travel",
        value: "travel",
        description: "Travel",
      },
      {
        label: "Utilities",
        value: "utilities",
        description: "Utility bills",
      },
    ],
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^\\d{1,16}$"),
    },
  },
  MZ: {
    label: "Mozambique",
    value: "MZ",
    countryCode: "MZ",
    currencyCode: "MZN",
    currencySymbol: "MT",
    currencyName: "Mozambican Metical",
    currencyFlag: "🇲🇿",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  NA: {
    label: "Namibia",
    value: "NA",
    countryCode: "NA",
    currencyCode: "NAD",
    currencySymbol: "$",
    currencyName: "Namibian Dollar",
    currencyFlag: "🇳🇦",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  NC: {
    label: "New Caledonia",
    value: "NC",
    countryCode: "NC",
    currencyCode: "XPF",
    currencySymbol: "₣",
    currencyName: "CFP Franc",
    currencyFlag: "🇳🇨",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  NE: {
    label: "Niger",
    value: "NE",
    countryCode: "NE",
    currencyCode: "XOF",
    currencySymbol: "CFA",
    currencyName: "West African CFA Franc",
    currencyFlag: "🇳🇪",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  NG: {
    label: "Nigeria",
    value: "NG",
    countryCode: "NG",
    currencyCode: "NGN",
    currencySymbol: "₦",
    currencyName: "Nigerian Naira",
    currencyFlag: "🇳🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  NI: {
    label: "Nicaragua",
    value: "NI",
    countryCode: "NI",
    currencyCode: "NIO",
    currencySymbol: "C$",
    currencyName: "Nicaraguan Córdoba",
    currencyFlag: "🇳🇮",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  NL: {
    label: "Netherlands",
    value: "NL",
    countryCode: "NL",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  NO: {
    label: "Norway",
    value: "NO",
    countryCode: "NO",
    currencyCode: "NOK",
    currencySymbol: "kr",
    currencyName: "Norwegian Krone",
    currencyFlag: "🇳🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.BANK_CODE,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
      ]),
      // https://support.currencycloud.com/hc/en-gb/articles/360017508939-Norwegian-Krone-NOK-
      paymentLimitinCents: 2500000000,
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.BANK_CODE]: new RegExp("^\\d{4}$"),
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^\\d{7}$"),
    },
  },
  NP: {
    label: "Nepal",
    value: "NP",
    countryCode: "NP",
    currencyCode: "NPR",
    currencySymbol: "₨",
    currencyName: "Nepalese Rupee",
    currencyFlag: "🇳🇵",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  NZ: {
    label: "New Zealand",
    value: "NZ",
    countryCode: "NZ",
    currencyCode: "NZD",
    currencySymbol: "$",
    currencyName: "New Zealand Dollar",
    currencyFlag: "🇳🇿",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^.{1,16}$"),
    },
  },
  OM: {
    label: "Oman",
    value: "OM",
    countryCode: "OM",
    currencyCode: "OMR",
    currencySymbol: "﷼",
    currencyName: "Omani Rial",
    currencyFlag: "🇴🇲",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  PA: {
    label: "Panama",
    value: "PA",
    countryCode: "PA",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇵🇦",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  PE: {
    label: "Peru",
    value: "PE",
    countryCode: "PE",
    currencyCode: "PEN",
    currencySymbol: "S/.",
    currencyName: "Peruvian Sol",
    currencyFlag: "🇵🇪",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  PF: {
    label: "French Polynesia",
    value: "PF",
    countryCode: "PF",
    currencyCode: "XPF",
    currencySymbol: "₣",
    currencyName: "CFP Franc",
    currencyFlag: "🇵🇫",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  PG: {
    label: "Papua New Guinea",
    value: "PG",
    countryCode: "PG",
    currencyCode: "PGK",
    currencySymbol: "K",
    currencyName: "Papua New Guinean Kina",
    currencyFlag: "🇵🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  PL: {
    label: "Poland",
    value: "PL",
    countryCode: "PL",
    currencyCode: "PLN",
    currencySymbol: "zł",
    currencyName: "Polish Zloty",
    currencyFlag: "🇵🇱",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
      // https://support.currencycloud.com/hc/en-gb/articles/360017508479-Polish-Zloty-PLN-
      paymentLimitinCents: 100000000,
    },
  },
  PT: {
    label: "Portugal",
    value: "PT",
    countryCode: "PT",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  PK: {
    label: "Pakistan",
    value: "PK",
    countryCode: "PK",
    currencyCode: "PKR",
    currencySymbol: "Rs.",
    currencyName: "Pakistani rupee",
    currencyFlag: "🇵🇰",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  PH: {
    label: "Philippines",
    value: "PH",
    countryCode: "PH",
    currencyCode: "PHP",
    currencyFlag: "🇵🇭",
    currencySymbol: "₱",
    currencyName: "Philippine Peso",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
      // Up to 1,300,000 PHP per transaction
      paymentLimitinCents: 130000000,
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^\\d{1,17}$"),
    },
  },
  PR: {
    label: "Puerto Rico",
    value: "PR",
    countryCode: "PR",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇺🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  PS: {
    label: "Palestinian, State of",
    value: "PS",
    countryCode: "PS",
    currencyCode: "ILS",
    currencySymbol: "₪",
    currencyName: "Israeli New Shekel",
    currencyFlag: "🇮🇱",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  PW: {
    label: "Palau",
    value: "PW",
    countryCode: "PW",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇺🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  PY: {
    label: "Paraguay",
    value: "PY",
    countryCode: "PY",
    currencyCode: "PYG",
    currencySymbol: "₲",
    currencyName: "Paraguayan Guarani",
    currencyFlag: "🇵🇾",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  QA: {
    label: "Qatar",
    value: "QA",
    countryCode: "QA",
    currencyCode: "QAR",
    currencySymbol: "﷼",
    currencyName: "Qatari Rial",
    currencyFlag: "🇶🇦",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  RE: {
    label: "Réunion",
    value: "RE",
    countryCode: "RE",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  RO: {
    label: "Romania",
    value: "RO",
    countryCode: "RO",
    currencyCode: "RON",
    currencySymbol: "lei",
    currencyName: "Romanian Leu",
    currencyFlag: "🇷🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
      paymentLimitinCents: 20000000,
    },
  },
  RS: {
    label: "Serbia",
    value: "RS",
    countryCode: "RS",
    currencyCode: "RSD",
    currencySymbol: "Дин.",
    currencyName: "Serbian Dinar",
    currencyFlag: "🇷🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  RU: {
    label: "Russian Federation",
    value: "RU",
    countryCode: "RU",
    currencyCode: "RUB",
    currencySymbol: "₽",
    currencyName: "Russian Ruble",
    currencyFlag: "🇷🇺",
    usd: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  RW: {
    label: "Rwanda",
    value: "RW",
    countryCode: "RW",
    currencyCode: "RWF",
    currencySymbol: "Fr",
    currencyName: "Rwandan Franc",
    currencyFlag: "🇷🇼",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  SA: {
    label: "Saudi Arabia",
    value: "SA",
    countryCode: "SA",
    currencyCode: "SAR",
    currencySymbol: "﷼",
    currencyName: "Saudi Riyal",
    currencyFlag: "🇸🇦",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  SB: {
    label: "Solomon Islands",
    value: "SB",
    countryCode: "SB",
    currencyCode: "SBD",
    currencySymbol: "$",
    currencyName: "Solomon Islands Dollar",
    currencyFlag: "🇸🇧",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  SC: {
    label: "Seychelles",
    value: "SC",
    countryCode: "SC",
    currencyCode: "SCR",
    currencySymbol: "₨",
    currencyName: "Seychellois Rupee",
    currencyFlag: "🇸🇨",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  SE: {
    label: "Sweden",
    value: "SE",
    countryCode: "SE",
    currencyCode: "SEK",
    currencySymbol: "kr",
    currencyName: "Swedish Krona",
    currencyFlag: "🇸🇪",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.BANK_CODE,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
      ]),
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.BANK_CODE]: new RegExp("^\\d{4,5}$"),
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^\\d{1,15}$"),
    },
  },
  SG: {
    label: "Singapore",
    value: "SG",
    countryCode: "SG",
    currencyCode: "SGD",
    currencySymbol: "$",
    currencyName: "Singapore Dollar",
    currencyFlag: "🇸🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
      paymentLimitinCents: 20000000,
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^[0-9A-Z]{6,34}"),
    },
  },
  SI: {
    label: "Slovenia",
    value: "SI",
    countryCode: "SI",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  SJ: {
    label: "Svalbard and Jan Mayen",
    value: "SJ",
    countryCode: "SJ",
    currencyCode: "NOK",
    currencySymbol: "kr",
    currencyName: "Norwegian Krone",
    currencyFlag: "🇳🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  SK: {
    label: "Slovakia",
    value: "SK",
    countryCode: "SK",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  SL: {
    label: "Sierra Leone",
    value: "SL",
    countryCode: "SL",
    currencyCode: "SLL",
    currencySymbol: "Le",
    currencyName: "Sierra Leonean Leone",
    currencyFlag: "🇸🇱",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  SM: {
    label: "San Marino",
    value: "SM",
    countryCode: "SM",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Regular",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
      ]),
    },
  },
  SV: {
    label: "El Salvador",
    value: "SV",
    countryCode: "SV",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "United States Dollar",
    currencyFlag: "🇺🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  SN: {
    label: "Senegal",
    value: "SN",
    countryCode: "SN",
    currencyCode: "XOF",
    currencySymbol: "CFA",
    currencyName: "CFA Franc BCEAO",
    currencyFlag: "🇸🇳",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  SO: {
    label: "Somalia",
    value: "SO",
    countryCode: "SO",
    currencyCode: "SOS",
    currencySymbol: "Sh",
    currencyName: "Somali Shilling",
    currencyFlag: "🇸🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  SR: {
    label: "Suriname",
    value: "SR",
    countryCode: "SR",
    currencyCode: "SRD",
    currencySymbol: "$",
    currencyName: "Surinamese Dollar",
    currencyFlag: "🇸🇷",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  ST: {
    label: "Sao Tome and Principe",
    value: "ST",
    countryCode: "ST",
    currencyCode: "STD",
    currencySymbol: "Db",
    currencyName: "Dobra",
    currencyFlag: "🇸🇹",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  SX: {
    label: "Sint Maarten (Dutch part)",
    value: "SX",
    countryCode: "SX",
    currencyCode: "ANG",
    currencySymbol: "ƒ",
    currencyName: "Netherlands Antillean Guilder",
    currencyFlag: "🇸🇽",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TC: {
    label: "Turks and Caicos Islands",
    value: "TC",
    countryCode: "TC",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇹🇨",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TF: {
    label: "French Southern Territories",
    value: "TF",
    countryCode: "TF",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  SZ: {
    label: "Eswatini",
    value: "SZ",
    countryCode: "SZ",
    currencyCode: "SZL",
    currencySymbol: "E",
    currencyName: "Swazi Lilangeni",
    currencyFlag: "🇸🇿",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TG: {
    label: "Togo",
    value: "TG",
    countryCode: "TG",
    currencyCode: "XOF",
    currencySymbol: "CFA",
    currencyName: "CFA Franc BCEAO",
    currencyFlag: "🇹🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TH: {
    label: "Thailand",
    value: "TH",
    countryCode: "TH",
    currencyCode: "THB",
    currencySymbol: "฿",
    currencyName: "Baht",
    currencyFlag: "🇹🇭",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^\\d{10,}$"),
    },
  },
  TD: {
    label: "Chad",
    value: "TD",
    countryCode: "TD",
    currencyCode: "XAF",
    currencySymbol: "FCFA",
    currencyName: "Central African CFA Franc",
    currencyFlag: "🇹🇩",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TJ: {
    label: "Tajikistan",
    value: "TJ",
    countryCode: "TJ",
    currencyCode: "TJS",
    currencySymbol: "SM",
    currencyName: "Somoni",
    currencyFlag: "🇹🇯",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TL: {
    label: "Timor-Leste",
    value: "TL",
    countryCode: "TL",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇺🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TM: {
    label: "Turkmenistan",
    value: "TM",
    countryCode: "TM",
    currencyCode: "TMT",
    currencySymbol: "m",
    currencyName: "Turkmenistan New Manat",
    currencyFlag: "🇹🇲",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TN: {
    label: "Tunisia",
    value: "TN",
    countryCode: "TN",
    currencyCode: "TND",
    currencySymbol: "د.ت",
    currencyName: "Tunisian Dinar",
    currencyFlag: "🇹🇳",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TO: {
    label: "Tonga",
    value: "TO",
    countryCode: "TO",
    currencyCode: "TOP",
    currencySymbol: "T$",
    currencyName: "Pa’anga",
    currencyFlag: "🇹🇴",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TR: {
    label: "Turkey",
    value: "TR",
    countryCode: "TR",
    currencyCode: "TRY",
    currencySymbol: "₺",
    currencyName: "Turkish Lira",
    currencyFlag: "🇹🇷",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  TT: {
    label: "Trinidad and Tobago",
    value: "TT",
    countryCode: "TT",
    currencyCode: "TTD",
    currencySymbol: "$",
    currencyName: "Trinidad and Tobago Dollar",
    currencyFlag: "🇹🇹",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TV: {
    label: "Tuvalu",
    value: "TV",
    countryCode: "TV",
    currencyCode: "AUD",
    currencySymbol: "$",
    currencyName: "Australian Dollar",
    currencyFlag: "🇹🇻",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  TW: {
    label: "Taiwan",
    value: "TW",
    countryCode: "TW",
    currencyCode: "TWD",
    currencySymbol: "$",
    currencyName: "New Taiwan Dollar",
    currencyFlag: "🇹🇼",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  TZ: {
    label: "Tanzania, United Republic of",
    value: "TZ",
    countryCode: "TZ",
    currencyCode: "TZS",
    currencySymbol: "TSh",
    currencyName: "Tanzanian Shilling",
    currencyFlag: "🇹🇿",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  UA: {
    label: "Ukraine",
    value: "UA",
    countryCode: "UA",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.IBAN,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  UG: {
    label: "Uganda",
    value: "UG",
    countryCode: "UG",
    currencyCode: "UGX",
    currencySymbol: "USh",
    currencyName: "Ugandan Shilling",
    currencyFlag: "🇺🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
  },
  UY: {
    label: "Uruguay",
    value: "UY",
    countryCode: "UY",
    currencyCode: "UYU",
    currencySymbol: "$",
    currencyName: "Uruguayan Peso",
    currencyFlag: "🇺🇾",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  UZ: {
    label: "Uzbekistan",
    value: "UZ",
    countryCode: "UZ",
    currencyCode: "UZS",
    currencySymbol: "лв",
    currencyName: "Uzbekistan Som",
    currencyFlag: "🇺🇿",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  VC: {
    label: "Saint Vincent and the Grenadines",
    value: "VC",
    countryCode: "VC",
    currencyCode: "XCD",
    currencySymbol: "$",
    currencyName: "East Caribbean Dollar",
    currencyFlag: "🇪🇨",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  VE: {
    label: "Venezuela, Bolivarian Republic of",
    value: "VE",
    countryCode: "VE",
    currencyCode: "VEF",
    currencySymbol: "Bs",
    currencyName: "Venezuelan Bolívar",
    currencyFlag: "🇻🇪",
    usd: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  VG: {
    label: "Virgin Islands, British",
    value: "VG",
    countryCode: "VG",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇻🇬",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  VI: {
    label: "Virgin Islands, U.S.",
    value: "VI",
    countryCode: "VI",
    currencyCode: "USD",
    currencySymbol: "$",
    currencyName: "US Dollar",
    currencyFlag: "🇻🇮",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  VN: {
    label: "Vietnam",
    value: "VN",
    countryCode: "VN",
    currencyCode: "VND",
    currencySymbol: "₫",
    currencyName: "Vietnamese đồng",
    currencyFlag: "🇻🇳",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  VU: {
    label: "Vanuatu",
    value: "VU",
    countryCode: "VU",
    currencyCode: "VUV",
    currencySymbol: "VT",
    currencyName: "Vanuatu Vatu",
    currencyFlag: "🇻🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  WF: {
    label: "Wallis and Futuna",
    value: "WF",
    countryCode: "WF",
    currencyCode: "XPF",
    currencySymbol: "₣",
    currencyName: "CFP Franc",
    currencyFlag: "🇼🇫",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  WS: {
    label: "Samoa",
    value: "WS",
    countryCode: "WS",
    currencyCode: "WST",
    currencySymbol: "T",
    currencyName: "Samoan Tala",
    currencyFlag: "🇼🇸",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  YE: {
    label: "Yemen",
    value: "YE",
    countryCode: "YE",
    currencyCode: "YER",
    currencySymbol: "﷼",
    currencyName: "Yemeni Rial",
    currencyFlag: "🇾🇪",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  YT: {
    label: "Mayotte",
    value: "YT",
    countryCode: "YT",
    currencyCode: "EUR",
    currencySymbol: "€",
    currencyName: "Euro",
    currencyFlag: "🇪🇺",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  ZA: {
    label: "South Africa",
    value: "ZA",
    countryCode: "ZA",
    currencyCode: "ZAR",
    currencySymbol: "R",
    currencyName: "South African Rand",
    currencyFlag: "🇿🇦",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    overrideInputValidation: {
      [INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER]: new RegExp("^[A-Z0-9]{1,16}$"),
    },
  },
  ZM: {
    label: "Zambia",
    value: "ZM",
    countryCode: "ZM",
    currencyCode: "ZMW",
    currencySymbol: "ZK",
    currencyName: "Zambian Kwacha",
    currencyFlag: "🇿🇲",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
  ZW: {
    label: "Zimbabwe",
    value: "ZW",
    countryCode: "ZW",
    currencyCode: "ZWL",
    currencySymbol: "Z$",
    currencyName: "Zimbabwean Dollar",
    currencyFlag: "🇿🇼",
    usd: {
      supported: true,
      deliveryMethod: "Priority",
      requiredFields: new Set([
        INTERNATIONAL_WIRE_INPUTS.CITY,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS,
        INTERNATIONAL_WIRE_INPUTS.ADDRESS_2,
        INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      ]),
    },
    local: {
      supported: false,
      deliveryMethod: "Priority",
      requiredFields: new Set([]),
    },
  },
};

// Please update NO_DECIMAL_CURRENCIES on the backend if you update this list
const disabledDecimalsCurrencies = new Set(["JPY"]);

export function shouldDisableDecimals(currencyCode: string): boolean {
  return disabledDecimalsCurrencies.has(currencyCode);
}

export const supportedInternationalWireCountriesOptions: InternationalWireOption[] = Object.values(
  supportedInternationalWireCountries
).sort((a, b) => (a.label as string).localeCompare(b.label as string));

export function filterSupportedInternationalWireCountriesOptions(
  currencyCode: string
): InternationalWireOption[] {
  return supportedInternationalWireCountriesOptions.filter(
    (c) => c.local.supported && c.currencyCode === currencyCode
  );
}

export type CurrencyOption = {
  currencySymbol: string;
} & Option;

export const usdCurrencyOption: CurrencyOption = {
  label: "🇺🇸 USD",
  value: "USD",
  currencySymbol: "$",
};

const preFormattedInternationalWireCurrencies: CurrencyOption[] = [
  ...Object.values(supportedInternationalWireCountries)
    .filter((supportedCountry) => supportedCountry.local.supported)
    .map((supportsRegularCountry) => ({
      label: `${supportsRegularCountry.currencyFlag} ${supportsRegularCountry.currencyCode}`,
      value: supportsRegularCountry.currencyCode,
      currencySymbol: supportsRegularCountry.currencySymbol ?? "",
    })),
  usdCurrencyOption,
];

export const getCurrencyOption = (currencyCode: string) =>
  Object.values(preFormattedInternationalWireCurrencies).find((c) => c.value === currencyCode);

// Removes all duplicate currencies
export const supportedInternationalWireCurrencies = preFormattedInternationalWireCurrencies
  .filter((v, i, a) => a.findIndex((v2) => v2.value === v.value) === i)
  .sort((a, b) => a.value.localeCompare(b.value));

export const getInternationalWireCountry = (countryCode: string) =>
  supportedInternationalWireCountries[countryCode];

export const getValidator = (
  countryCode: string,
  input: INTERNATIONAL_WIRE_INPUTS
): ((value: string) => Promise<OnTextInputChange>) => {
  const hasOverridenValidation =
    supportedInternationalWireCountries[countryCode].overrideInputValidation;
  const validation =
    hasOverridenValidation && hasOverridenValidation[input]
      ? hasOverridenValidation[input]
      : INTERNATIONAL_WIRE_INPUT_DETAILS[input].defaultValidation;

  return validator(
    (value) => validation.test(value),
    INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT].errorMessage
  );
};

type InternationalWirePrefillInfo = {
  entity: Entity;
  address: Address;
  bankingInfo: InternationalBankingInfo;
  currency: CurrencyOption;
};

export const getInternationalWirePrefillInfo = (
  info: PayeeRep.InternationalWireTransferMethod
): InternationalWirePrefillInfo => {
  const addressCountry = getInternationalWireCountry(info.address.country);
  const state = getStateOptionForCountry(addressCountry, info.address.state);
  const entity = {
    entityType: info.entity.entityType,
    firstName: info.entity.firstName,
    lastName: info.entity.lastName,
    companyName: info.entity.companyName,
    companyBankHolderName: info.entity.companyBankHolderName,
  };
  const address = {
    addressLine1: {
      value: info.address?.addressLine1,
      label: info.address?.addressLine1,
    },
    addressLine2: info.address.addressLine2 ?? "",
    city: info.address.city,
    state: state,
    zipCode: info.address.zipCode,
    country: addressCountry,
  };
  const bankingInfo = {
    accountNumber: {
      inputName: INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
      value: info?.accountNumber,
      isValid: true,
    },
    bankCode: {
      inputName: INTERNATIONAL_WIRE_INPUTS.BANK_CODE,
      value: info?.bankCode,
      isValid: true,
    },
    branchCode: {
      inputName: INTERNATIONAL_WIRE_INPUTS.BRANCH_CODE,
      value: info?.branchCode,
      isValid: true,
    },
    bsbCode: {
      inputName: INTERNATIONAL_WIRE_INPUTS.BSB_CODE,
      value: info?.bsbCode,
      isValid: true,
    },
    clabe: {
      inputName: INTERNATIONAL_WIRE_INPUTS.CLABE,
      value: info?.clabe,
      isValid: true,
    },
    cnaps: {
      inputName: INTERNATIONAL_WIRE_INPUTS.CNAPS,
      value: info?.cnaps,
      isValid: true,
    },
    iban: {
      inputName: INTERNATIONAL_WIRE_INPUTS.IBAN,
      value: info?.iban,
      isValid: true,
    },
    ifsc: {
      inputName: INTERNATIONAL_WIRE_INPUTS.IFSC,
      value: info?.ifsc,
      isValid: true,
    },
    sortCode: {
      inputName: INTERNATIONAL_WIRE_INPUTS.SORT_CODE,
      value: info?.sortCode,
      isValid: true,
    },
    swift: {
      inputName: INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
      value: info?.swift,
      isValid: true,
    },
  };
  const currency = getCurrencyOption(info.currency ?? "USD") ?? usdCurrencyOption;

  return {
    entity,
    address,
    bankingInfo,
    currency,
  };
};
