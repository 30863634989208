import classNames from "classnames";
import { FC, ReactNode } from "react";
import Shimmer from "ui/feedback/Shimmer";

import styles from "./HighlightItem.module.scss";

/**
 * DEPRECATED: Please do not use this, will delete soon.
 */

export type HighlightItemSize = "sm" | "lg";
export type HighlightItemSideRuleColor = "default" | "success" | "warning" | "none";

export const HighlightItemLabelShimmer = () => (
  <Shimmer additionalShimmerClassName={[styles.labelShimmer]} />
);
export const HighlightItemValueShimmer = () => (
  <Shimmer additionalShimmerClassName={[styles.valueShimmer]} />
);
export const HighlightItemSubtextShimmer = () => (
  <Shimmer additionalShimmerClassName={[styles.subtextShimmer]} />
);

type Props = {
  size?: HighlightItemSize;
  sideRuleColor?: HighlightItemSideRuleColor;
  label: ReactNode;
  value: ReactNode;
  subtext?: ReactNode;
};

const HighlightItem: FC<Props> = ({
  size = "lg",
  sideRuleColor = "default",
  label,
  value,
  subtext,
}) => {
  return (
    <div>
      <div className={styles.highlightContainer}>
        <div className={classNames(styles.leftHandRule, styles[sideRuleColor])} />

        <div className={classNames(styles.label, styles[`size-${size}`])}>{label}</div>

        <div className={styles.value}>{value}</div>
      </div>

      {subtext && <div className={styles.subtext}>{subtext}</div>}
    </div>
  );
};

export default Object.assign(HighlightItem, {
  LabelShimmer: HighlightItemLabelShimmer,
  ValueShimmer: HighlightItemValueShimmer,
  SubtextShimmer: HighlightItemSubtextShimmer,
});
