import HighbeamBaseApi from "api/HighbeamBaseApi";
import ChatMessageRequest from "reps/chat/ChatMessageRequest";
import ChatMessageResponse from "reps/chat/ChatMessageResponse";

export default class ChatMessageApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(request: ChatMessageRequest): Promise<ChatMessageResponse> {
    const url = "/chat/chat-messages";
    return (await this.api.post<ChatMessageResponse>(url, request))!;
  }
}
