import { ShieldWarning } from "@phosphor-icons/react";
import { FC } from "react";
import Banner from "ui/data-display/Banner";
import ButtonLink from "ui/inputs/ButtonLink";

type Props = {
  className?: string;
};

const InactiveConnectionWarningBanner: FC<Props> = ({ className }) => {
  return (
    <Banner
      color="grey"
      padding="lg"
      className={className}
      icon={<ShieldWarning className="size-6 text-grey-800" />}
      title="Reconnection required"
      paragraph={
        <>
          One or more of your connected bank accounts or cards need to be reconnected. Please
          reconnect these accounts as having disconnected accounts for a long period of time can
          result in a limit decrease.
        </>
      }
      button={
        <ButtonLink
          variant="tertiary"
          to="/settings/banks-and-cards"
          className="w-full tablet:w-auto"
        >
          Reconnect
        </ButtonLink>
      }
    />
  );
};

export default InactiveConnectionWarningBanner;
