import { useRecoilValue } from "recoil";
import bankAccountByUnitCoDepositAccountIdQueryHooks from "resources/bank-accounts/queries/bankAccountByUnitCoDepositAccountIdQueryHooks";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import FlexpaneHeader from "ui/overlay/Flexpane/FlexpaneHeader";
import useIsAllowedToCancelPayment from "utils/permissions/useIsAllowedToCancelPayment";
import { HighbeamOriginatedAchTransaction } from "utils/types/transactionsTypes";

import { useTransactionInfoFlexpaneContext } from "../context/TransactionInfoFlexpaneProvider";

import CancelPaymentModal from "./CancelPaymentModal";
import cancelPaymentModalState, {
  useCancelPaymentModal,
} from "./CancelPaymentModal/state/cancelPaymentModalState";

type Props = {
  transaction: HighbeamOriginatedAchTransaction;
};

const OriginatedAchTransactionFlexpaneHeader: React.FC<Props> = ({ transaction }) => {
  const { refreshTransactions, onClose } = useTransactionInfoFlexpaneContext();

  const isAllowedToCancelPayment = useIsAllowedToCancelPayment();
  const { open: openCancelPaymentModal } = useCancelPaymentModal();
  const bankAccount = bankAccountByUnitCoDepositAccountIdQueryHooks.useDataRequired({
    unitCoDepositAccountId: transaction.accountId,
  });

  const canCancelPayment =
    transaction.paymentStatus === "Pending" || transaction.paymentStatus === "PendingReview";

  const { isOpen: isCancelPaymentModalOpen } = useRecoilValue(cancelPaymentModalState);

  return (
    <>
      {isCancelPaymentModalOpen && (
        <CancelPaymentModal
          onSuccess={() => {
            refreshTransactions && refreshTransactions();
            onClose();
          }}
          paymentDirection={"Outgoing"}
        />
      )}

      <FlexpaneHeader
        onClose={onClose}
        right={
          canCancelPayment && transaction.paymentGuid !== undefined ? (
            <ButtonWithTooltip
              variant="danger"
              onClick={() => {
                openCancelPaymentModal({
                  account: bankAccount,
                  counterpartyName: transaction.counterpartyName,
                  paymentAmountInCents: transaction.amountInCents,
                  paymentGuid: transaction.paymentGuid!,
                });
              }}
              disabled={!isAllowedToCancelPayment}
              tooltip={!isAllowedToCancelPayment && "You don’t have permission to cancel payments."}
            >
              Cancel transaction
            </ButtonWithTooltip>
          ) : null
        }
      >
        Transaction info
      </FlexpaneHeader>
    </>
  );
};

export default OriginatedAchTransactionFlexpaneHeader;
