import { RecurringPayment } from "@highbeam/unit-node-sdk";

const isRecurring = (recurringPayment: RecurringPayment) => {
  const totalNumPayments = recurringPayment.attributes.schedule.totalNumberOfPayments;
  const endTime = recurringPayment.attributes.schedule.endTime;
  if (totalNumPayments) {
    return totalNumPayments > 1;
  } else {
    // One time payments used to be made by setting endTime and not totalNumberOfPayments = 1.
    // Checking that endTime is null ensures that this is an infinite payment.
    return !endTime;
  }
};

export default isRecurring;
