import { useSuspenseQuery } from "@tanstack/react-query";
import HighbeamInsightsPage from "pages/HighbeamInsightsPage";
import OnboardingConnectStoresPage from "pages/Onboarding/OnboardingConnectStoresPage";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useConnectedStoresQueryOptions from "resources/connected-stores/queries/useConnectedStoresQueryOptions";

/**
 * Highbeam supports an "Insights App" for Shopify, that only includes insights features.
 * See {@link useInsightsApp} for more.
 */
const InsightsAppRouter: React.FC = () => {
  const { data: connectedStores = [] } = useSuspenseQuery(useConnectedStoresQueryOptions());
  const numberOfConnections = connectedStores.length;
  const hasConnections = numberOfConnections > 0;

  return (
    <Routes>
      {hasConnections && <Route path="/insights" element={<HighbeamInsightsPage />} />}
      <Route path="/onboarding/connect-stores" element={<OnboardingConnectStoresPage />} />
      <Route
        path="*"
        element={
          <Navigate replace to={hasConnections ? "/insights" : "/onboarding/connect-stores"} />
        }
      />
    </Routes>
  );
};

export default InsightsAppRouter;
