import MultiStep from "layouts/MultiStep";
import { FC, FormEventHandler, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import isHighCreditUtilization from "resources/line-of-credit/utils/isHighCreditUtilization";
import colors from "styles/colors";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import BarChart from "ui/data-visualization/BarChart";
import CurrencyInput from "ui/inputs/CurrencyInputV2";
import Text from "ui/typography/Text";

import { useDrawdownAmountFormContext, useDrawdownContext } from "../context/DrawdownProvider";

import DrawdownFromCapitalAccountDropdown from "./DrawdownFromCapitalAccountDropdown";
import DrawdownToBankAccountDropdown from "./DrawdownToBankAccountDropdown";

const DRAW_DOWN_AMOUNT_FORM_ID = "draw-down-amount-form";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const DrawdownAmountView: FC<Props> = ({ capitalAccountSummary }) => {
  const capitalAccountAvailableBalance = capitalAccountSummary.available;
  const limit = capitalAccountSummary.details.limit;

  const { nextPathname } = useDrawdownContext();

  const { control, watch } = useDrawdownAmountFormContext();
  const amountInputValue = watch("amount");

  const navigate = useNavigate();

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  const availableAfterDrawdown = capitalAccountAvailableBalance - (amountInputValue || 0);
  const availableAfterDrawdownPercentage = (availableAfterDrawdown / limit) * 100;
  const isDrawdownLargerThanCreditAccountLimit = availableAfterDrawdown < 0;
  const willDrawdownCauseHighCreditUtilization =
    (amountInputValue || 0) > 0 &&
    isHighCreditUtilization(
      0 - capitalAccountSummary.runningBalance + (amountInputValue || 0),
      capitalAccountSummary.details.limit
    );

  const errorMessage = useMemo(() => {
    if (isDrawdownLargerThanCreditAccountLimit) {
      return "Your drawdown amount is larger than your line of credit.";
    }
    if (willDrawdownCauseHighCreditUtilization) {
      return "Continuous high credit utilization can result in a decrease of your line of credit limit.";
    }
  }, [isDrawdownLargerThanCreditAccountLimit, willDrawdownCauseHighCreditUtilization]);

  return (
    <MultiStep.Form id={DRAW_DOWN_AMOUNT_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            How much do you want to draw down?
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <MultiStep.Section className="mt-6">
          <DrawdownFromCapitalAccountDropdown />
        </MultiStep.Section>

        <Controller
          control={control}
          name="amount"
          render={({ field }) => {
            return (
              <CurrencyInput
                prefixValue="$"
                label="Amount"
                hasError={Boolean(errorMessage)}
                errorVariant={isDrawdownLargerThanCreditAccountLimit ? "error" : "warning"}
                errorMessage={errorMessage}
                {...field}
              />
            );
          }}
        />
      </MultiStep.Section>

      <MultiStep.Section className="mt-6">
        <DrawdownToBankAccountDropdown />
      </MultiStep.Section>

      <MultiStep.Section className="py-6">
        <Text color={colors.grey[900]} size={14} weight="medium">
          Available after drawdown
        </Text>

        <BarChart height={8} className="mt-4">
          <BarChart.Bar
            color={availableAfterDrawdownPercentage > 0 ? "green" : "yellow"}
            widthPercentage={availableAfterDrawdownPercentage}
          />
        </BarChart>

        <MoneyAmountFraction
          numeratorInCents={availableAfterDrawdown}
          denominatorInCents={limit}
          className="mt-3"
        />
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.NextButton
          form={DRAW_DOWN_AMOUNT_FORM_ID}
          disabled={!amountInputValue || isDrawdownLargerThanCreditAccountLimit}
        >
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default DrawdownAmountView;
