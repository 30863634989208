import env from "env";

import useApEmailAliases from "./useApEmailAliases";

const useCurrentApEmailAddress = () => {
  const apEmailAliases = useApEmailAliases();
  const currentApEmailAlias = apEmailAliases.length ? apEmailAliases[0] : null;
  return currentApEmailAlias
    ? `${currentApEmailAlias.username}@${env.AP_EMAIL_ALIAS_DOMAIN}`
    : null;
};

export default useCurrentApEmailAddress;
