// Experimental(alex): Use this error if we are expecting a certain shape of data but receive an invalid one.

export default class IncorrectDataReceivedError extends Error {
  constructor(
    public data: unknown,
    public message: string = "Incorrect data received"
  ) {
    super(message);
    this.name = "IncorrectDataReceivedError";
    Object.setPrototypeOf(this, IncorrectDataReceivedError.prototype);
  }
}
