import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import checkIcon from "assets/check-icon-green-circle.svg";
import useDisconnectRutterConnectionMutation from "pages/capital/CreditApplicationPage/hooks/useDisconnectRutterConnectionMutation";
import { FC } from "react";
import RutterConnectionRep from "reps/RutterConnectionRep";
import useAccountingPlatformConnectionsQueryOptions from "resources/accounting-platforms/queries/useAccountingPlatformConnectionsQueryOptions";
import { useIsSuperusering } from "state/auth/isSuperusering";
import { Paragraph } from "ui/typography";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";

import AccountingSoftwareCard from "./AccountingSoftwareCard";

type AccountingConnectProps = {
  type: RutterConnectionRep.RutterAccountingPlatform;
  openModal: () => void;
};

const AccountingPlatformConnectionCard: FC<AccountingConnectProps> = ({ openModal, type }) => {
  const { data: accountingPlatformConnections = [] } = useQuery(
    useAccountingPlatformConnectionsQueryOptions()
  );
  const { mutate } = useDisconnectRutterConnectionMutation();
  const isSuperusering = useIsSuperusering();

  const connectionForType = accountingPlatformConnections.find(
    (connection) => connection.platformName === type
  );
  const isAccountingPlatformConnected = Boolean(connectionForType);
  const handleDisconnect = () => {
    if (!connectionForType) {
      captureException(new Error("No Quickbooks connection found"));
      return;
    }
    mutate(connectionForType.guid);
  };

  return (
    <AccountingSoftwareCard
      type={type}
      description={
        isAccountingPlatformConnected ? (
          <div className="flex gap-x-2">
            <img src={checkIcon} alt="check-icon"></img>
            <Paragraph className="text-sm text-grey-600">Connected</Paragraph>
          </div>
        ) : (
          <Paragraph className="text-sm text-grey-600">
            Allow Highbeam to view your {getAccountingSoftwareName(type)} financials.
          </Paragraph>
        )
      }
      addText={
        isAccountingPlatformConnected
          ? isAccountingPlatformConnected && isSuperusering
            ? "[SUPERUSER] Disconnect"
            : "Disconnect"
          : "Connect"
      }
      onButtonClick={isAccountingPlatformConnected ? handleDisconnect : openModal}
      isAccountingPlatformConnected={isAccountingPlatformConnected}
    />
  );
};

export default AccountingPlatformConnectionCard;
