import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery, { UseRefreshQueryOptions } from "utils/react-query/useRefreshQuery";

import { BILLS_QUERY_KEY } from "./useRefreshBillsQueries";

export const useBillQueryOptions = (billId: string) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [BILLS_QUERY_KEY, { billId }],
    queryFn: () => {
      return highbeamApi.billSummary.get(billId);
    },
  });
};

export const useRefreshBillQuery = (billId: string, options: UseRefreshQueryOptions = {}) => {
  return useRefreshQuery([BILLS_QUERY_KEY, { billId }], options);
};

export const useBillQuery = (billId: string) => {
  return useQuery(useBillQueryOptions(billId));
};

const useBill = <TRequired extends boolean>(billId: string, params?: RequiredParam<TRequired>) => {
  const { data } = useSuspenseQuery(useBillQueryOptions(billId));

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useBill;
