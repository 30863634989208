import { Icon, IconProps, Spinner as SpinnerIcon } from "@phosphor-icons/react";
import classNames from "classnames";

import styles from "./AnimatedSpinner.module.scss";

type Props = {
  icon?: Icon;
} & IconProps;

const AnimatedSpinner: React.FC<Props> = ({ icon: Tag = SpinnerIcon, className, ...rest }) => (
  <Tag className={classNames(className, styles.spin)} size={14} {...rest} />
);

export default AnimatedSpinner;
