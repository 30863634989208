import { ComponentProps, FC, ReactNode, Suspense } from "react";
import Shimmer from "ui/feedback/ShimmerV2";
import { Heading3, Heading4 } from "ui/typography";
import { TextProps } from "ui/typography/Text";
import cn from "utils/tailwind/cn";

export const FlexpaneSectionShimmer = () => (
  <div className="flex flex-col gap-y-6">
    <Shimmer />
    <Shimmer />
  </div>
);

export const FlexpaneSectionHeading: FC<ComponentProps<typeof Heading3>> = ({
  className,
  children,
  ...headingProps
}) => {
  return (
    <Heading3 className={cn("mb-8", className)} {...headingProps}>
      {children}
    </Heading3>
  );
};

export const FlexpaneSectionSubheading: FC<TextProps> = ({ className, children, ...textProps }) => {
  return (
    <Heading4 className={cn("mb-6 text-sm text-grey-600", className)} {...textProps}>
      {children}
    </Heading4>
  );
};

type Props = {
  suspenseFallback?: ReactNode;
  children?: ReactNode;
  className?: string;
  heading?: ReactNode;
  subheading?: ReactNode;
};

const FlexpaneSection: FC<Props> = ({
  suspenseFallback = <FlexpaneSectionShimmer />,
  className,
  children,
  heading,
  subheading,
}) => {
  if (!children) {
    return null;
  }

  return (
    <div
      className={cn(
        "flex flex-col border-b border-grey-100 px-6 py-8 last-of-type:border-b-0 large-desktop:p-8",
        className
      )}
    >
      <Suspense fallback={suspenseFallback}>
        {typeof heading === "string" ? (
          <FlexpaneSectionHeading>{heading}</FlexpaneSectionHeading>
        ) : (
          heading
        )}

        {typeof subheading === "string" ? (
          <FlexpaneSectionSubheading>{subheading}</FlexpaneSectionSubheading>
        ) : (
          subheading
        )}

        {children}
      </Suspense>
    </div>
  );
};

export default FlexpaneSection;
