import InternationalWirePaymentRep from "reps/InternationalWirePaymentRep";
import InternationalWireQuoteRep from "reps/InternationalWireQuote";
import PaymentRep from "reps/PaymentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class PaymentApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getInternationalWireQuote(
    creation: InternationalWireQuoteRep.Creation
  ): Promise<InternationalWireQuoteRep.Complete> {
    const url = `/payment/international-wire/quote`;
    return (await this.api.post<InternationalWireQuoteRep.Complete>(url, creation))!;
  }

  async createInternationalWire(
    creation: InternationalWirePaymentRep.Creation
  ): Promise<InternationalWirePaymentRep.Complete> {
    const url = `/payment/international-wire`;
    return (await this.api.post<InternationalWirePaymentRep.Complete>(url, creation))!;
  }

  async create(creation: PaymentRep.Creation): Promise<PaymentRep.Complete> {
    const url = `/payment`;
    return (await this.api.post<PaymentRep.Complete>(url, creation))!;
  }
}
