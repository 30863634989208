import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { PAYMENT_METADATA_QUERY_KEY } from "resources/payment-metadata/queries/usePaymentMetadataQueryOptions";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import styles from "./CardTransactionsTableReceiptTooltip.module.scss";

// `react-pdf` uses pdfjs, which requires a worker to be loaded. https://www.npmjs.com/package/react-pdf#import-worker-recommended
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

type Props = {
  invoiceGuid: string;
  invoiceName: string;
};

const CardTransactionsTableReceiptTooltip: FC<Props> = ({ invoiceGuid, invoiceName }) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const { data: imageUrl, isLoading } = useQuery({
    queryKey: [PAYMENT_METADATA_QUERY_KEY, { businessGuid, invoiceGuid }],
    queryFn: async () => {
      const downloadUrl = await highbeamApi.transaction.getInvoice(businessGuid, invoiceGuid);

      const res = await fetch(downloadUrl.url, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
      const blob = await res.blob();
      return window.URL.createObjectURL(blob);
    },
  });

  const [_numPages, setNumPages] = useState<number>();
  const [pageIndex, _setPageIndex] = useState<number>(0);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <AnimatedSpinner />
      ) : invoiceName.endsWith(".pdf") ? (
        <div>
          <Document
            file={imageUrl}
            loading={<AnimatedSpinner />}
            onLoadSuccess={({ numPages }) => {
              setNumPages(numPages);
            }}
          >
            <Page pageIndex={pageIndex} />
          </Document>

          {/* TODO(alex): We can display `Page {pageIndex + 1} of {numPages}` +  controls for changing the `pageIndex`, but we need a better tooltip library first. */}
        </div>
      ) : (
        <img src={imageUrl} className={styles.invoiceImg} alt="Uploaded invoice" />
      )}
    </div>
  );
};

export default CardTransactionsTableReceiptTooltip;
