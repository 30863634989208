import { ArrowsInSimple, Pencil, Storefront } from "@phosphor-icons/react";
import MergedCounterpartiesModal from "dialogs/insights/MergedCounterpartiesModal";
import UpdateCounterpartyAliasModal from "dialogs/insights/UpdateCounterpartyAliasModal";
import { CashFlowTableDatum } from "pages/CashFlowPage/data/useCashFlowTableData";
import { FC, useState } from "react";
import colors from "styles/colors";
import CounterpartyCell from "ui/table/CounterpartyCell";
import Text from "ui/typography/Text";

import styles from "./CashFlowCounterpartyCell.module.scss";

const CashFlowCounterpartyCell: FC<{ datum: CashFlowTableDatum }> = ({ datum }) => {
  const [showUpdateCounterpartyAliasModal, setShowUpdateCounterpartyAliasModal] = useState(false);
  const [showMergedCounterpartiesModal, setShowMergedCounterpartiesModal] = useState(false);
  const numberOfMergedCounterparties = datum.originalCounterparties.length;

  return (
    <>
      <UpdateCounterpartyAliasModal
        onClose={() => setShowUpdateCounterpartyAliasModal(false)}
        params={
          showUpdateCounterpartyAliasModal
            ? {
                counterparty: datum.counterparty,
                direction:
                  datum.category.groupDirectionValue === "In" ||
                  (datum.category.groupDirectionValue === "Bidirectional" &&
                    Number(datum.amount.amount) > 0)
                    ? "money-in"
                    : "money-out",
                originalCounterparties: datum.originalCounterparties,
              }
            : undefined
        }
      />

      <MergedCounterpartiesModal
        onClose={() => setShowMergedCounterpartiesModal(false)}
        params={
          showMergedCounterpartiesModal
            ? {
                counterparty: datum.counterparty,
                originalCounterparties: datum.originalCounterparties,
              }
            : undefined
        }
      />

      <CounterpartyCell>
        <Storefront size={24} />

        <div>
          <div
            className={styles.counterpartyName}
            onClick={() => setShowUpdateCounterpartyAliasModal(true)}
          >
            {datum.counterparty}

            <Pencil size={16} className={styles.pencilIcon} />
          </div>

          {numberOfMergedCounterparties > 1 && (
            <div
              className={styles.mergedCounterpartiesContainer}
              onClick={() => setShowMergedCounterpartiesModal(true)}
            >
              <ArrowsInSimple size={16} color={colors.grey[600]} />

              <Text color={colors.grey[600]} size={12} underline>
                {numberOfMergedCounterparties} merged
              </Text>
            </div>
          )}
        </div>
      </CounterpartyCell>
    </>
  );
};

export default CashFlowCounterpartyCell;
