import { Warning } from "@phosphor-icons/react";
import IconWithTooltip from "components/common/IconWithTooltip";
import { FC } from "react";
import BankAccountRep from "reps/BankAccountRep";
import BankAccountBar from "resources/bank-accounts/components/BankAccountBar";
import colors from "styles/colors";
import HighlightItem from "ui/data-display/HighlightItem";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import { Span } from "ui/typography";
import Text from "ui/typography/Text";

import styles from "./ChargeCardAutoPayHighlightItem.module.scss";

type Props = {
  balanceDueAmountInCents: number;
  autoPayAccount: BankAccountRep.Complete;
};

const ChargeCardAutoPayHighlightItem: FC<Props> = ({ balanceDueAmountInCents, autoPayAccount }) => {
  const autoPayAccountBalanceInCents = autoPayAccount.availableBalance;

  const isBalanceDueGreaterThanAutoPayAccountBalance =
    balanceDueAmountInCents > autoPayAccountBalanceInCents;

  return (
    <HighlightItem
      sideRuleColor={isBalanceDueGreaterThanAutoPayAccountBalance ? "warning" : "default"}
      label={
        <>
          Auto-pay account
          <IconWithTooltip
            color="dark"
            tooltip={
              <Text size={14}>
                Highbeam will debit from this account on each due date. If the account balance is
                insufficient, the full amount will be paid with your line of credit.
              </Text>
            }
          />
        </>
      }
      value={<BankAccountBar size={20} bankAccount={autoPayAccount} />}
      subtext={
        isBalanceDueGreaterThanAutoPayAccountBalance ? (
          <div className={styles.warningSubtext}>
            <div>
              <Warning size={24} weight="light" color={colors.yellow[600]} />
            </div>
            <Span className="text-yellow-700">
              Your balance of <MoneyAmount cents={autoPayAccountBalanceInCents} /> is too low. The
              full amount will be paid down using your line of credit.
            </Span>
          </div>
        ) : (
          <>
            Balance: <MoneyAmount cents={autoPayAccountBalanceInCents} />
          </>
        )
      }
    />
  );
};

export default ChargeCardAutoPayHighlightItem;
