import { useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const makeQueryKey = (billId: string) => ["billApprovals", { billId }];

const useBillApprovalsQueryOptions = (billId: string) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: makeQueryKey(billId),
    queryFn: () => highbeamApi.billApproval.search(billId),
  });
};

const useBillApprovals = (billId: string) => {
  const { data } = useSuspenseQuery(useBillApprovalsQueryOptions(billId));
  return data;
};

export const useRefreshBillApprovalsQuery = (billId: string) =>
  useRefreshQuery(makeQueryKey(billId));

export default useBillApprovals;
