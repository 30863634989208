import { CreditCard } from "@phosphor-icons/react";
import React from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./NewCardDetailsHeader.module.scss";

const NewCardDetailsHeader: React.FC = () => {
  return (
    <div className={styles.container}>
      <CreditCard size={16} color={colors.grey[900]} />
      <Text as="p" size={14} weight="bold" color={colors.grey[900]}>
        New card details
      </Text>
    </div>
  );
};

export default NewCardDetailsHeader;
