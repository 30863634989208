import { useMutation } from "@tanstack/react-query";
import ActionItemRep from "reps/ActionItemRep";
import getActionItem from "resources/action-items/utils/getActionItem";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

import useMigrateToThreadActionItems from "../queries/useMigrateToThreadActionItems";

import { ActionItemName, MIGRATE_TO_THREAD_MILESTONE_NAME } from "./constants";

type Options = {
  actionItemName: ActionItemName;
  state: ActionItemRep.State;
};

const useSetThreadActionItemMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const { data: threadActionItems, refetch: refetchThreadActionItems } =
    useMigrateToThreadActionItems();

  return useMutation({
    mutationFn: ({ actionItemName, state }: Options) => {
      const actionItem = getActionItem(threadActionItems, actionItemName);
      if (actionItem) {
        return highbeamApi.actionItem.update(businessGuid, actionItem.guid, {
          name: actionItemName,
          state: state,
        });
      } else {
        return highbeamApi.actionItem.set({
          milestoneName: MIGRATE_TO_THREAD_MILESTONE_NAME,
          ownerGuid: businessGuid,
          name: actionItemName,
          state: state,
        });
      }
    },
    onSuccess: () => {
      refetchThreadActionItems();
    },
  });
};

export default useSetThreadActionItemMutation;
