import BillRep from "reps/BillRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(billId: string): Promise<BillRep.Complete | null> {
    const url = `/accounts-payable/bills/${billId}`;
    return await this.api.get<BillRep.Complete>(url);
  }

  async search(businessGuid: string): Promise<BillRep.Complete[]> {
    const qp = new URLSearchParams({ businessGuid: businessGuid });
    const url = `/accounts-payable/bills?${qp}`;
    return (await this.api.get<BillRep.Complete[]>(url))!;
  }

  async create(creator: BillRep.Creator): Promise<BillRep.Complete> {
    const url = "/accounts-payable/bills";
    return (await this.api.post<BillRep.Complete>(url, creator))!;
  }

  async updateBillInfo(billId: string, updater: BillRep.Updater): Promise<BillRep.Complete> {
    const url = `/accounts-payable/bills/${billId}/info`;
    return (await this.api.patch<BillRep.Complete>(url, updater))!;
  }

  async delete(billId: string): Promise<void> {
    const url = `/accounts-payable/bills/${billId}`;
    await this.api.delete(url);
  }
}
