import { selectorFamily } from "recoil";
import InternationalWireQuoteRep from "reps/InternationalWireQuote";
import businessGuidState from "state/auth/businessGuid";
import highbeamApiState from "state/auth/highbeamApi";

import { quoteIdempotencyKeyState } from "./quoteIdempotencyKey";

const quotesState = selectorFamily<InternationalWireQuoteRep.Complete, string>({
  key: "payments/international/quotes",
  get:
    (currency: string) =>
    async ({ get }) => {
      if (currency === "USD") return { rate: 1, inverse: 1 };
      const _ = get(quoteIdempotencyKeyState);
      const highbeamApi = get(highbeamApiState("authenticated"));
      const businessGuid = get(businessGuidState);

      // We refresh the state every second
      //const second = get(secondState); // will re-run every second
      return await highbeamApi.payment.getInternationalWireQuote({
        currency,
        businessGuid,
      });
    },

  // eslint-disable-next-line camelcase
  cachePolicy_UNSTABLE: {
    // Only store the most recent quote
    // A new quote will invalidate the old one
    eviction: "most-recent",
  },
});

export default quotesState;
