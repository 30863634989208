import { ArrowsClockwise, CalendarBlank, CheckCircle } from "@phosphor-icons/react";
import { FC } from "react";
import HighlightItemV2 from "ui/data-display/HighlightItemV2";
import Pill, { PillColor } from "ui/data-display/Pill";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

import { NextCapitalAutoPayAmount } from "../queries/capitalRepaymentAmountsQueryHooks";

type AutoPayStatusTextProps = {
  className?: string;
  autoPayStatus: "on" | "off";
};

const AutoPayStatusText: FC<AutoPayStatusTextProps> = ({ className, autoPayStatus }) => {
  return (
    <div className={cn("flex h-6 items-center gap-x-1 text-xs", className)}>
      <ArrowsClockwise size={16} className="text-grey-600" />
      <Span className="mb-[-1px] text-grey-600">Auto-pay</Span>
      <Pill
        color={variants(autoPayStatus, {
          on: "green-light" as const,
          off: "grey-light" as const,
        } satisfies Record<string, PillColor>)}
        className="px-1 capitalize"
      >
        {autoPayStatus}
      </Pill>
    </div>
  );
};

type Props = {
  nextAutoPayAmount: NextCapitalAutoPayAmount | null;
  className?: string;
};

const NextAutoPaymentHighlightItem: FC<Props> = ({ nextAutoPayAmount, className }) => {
  return (
    <HighlightItemV2 className={className}>
      {nextAutoPayAmount && nextAutoPayAmount.amount > 0 ? (
        <>
          <HighlightItemV2.Heading>
            <AutoPayStatusText autoPayStatus="on" className="text-sm" />
          </HighlightItemV2.Heading>
          <HighlightItemV2.Body>
            <HighlightItemV2.MoneyAmount cents={nextAutoPayAmount.amount} />
          </HighlightItemV2.Body>
          <HighlightItemV2.Footer className="flex items-center gap-x-1">
            <CalendarBlank size={16} className="-mt-0.5 text-grey-600" weight="light" />
            <Span className="flex items-center gap-x-1 text-xs text-grey-600">
              {nextAutoPayAmount.date.format("MMM D, YYYY")}
            </Span>
          </HighlightItemV2.Footer>
        </>
      ) : (
        <>
          <HighlightItemV2.Heading className="text-green-600">
            Nothing due
            <CheckCircle size={16} />
          </HighlightItemV2.Heading>
          <HighlightItemV2.Body>
            <HighlightItemV2.MoneyAmount cents={0} className="text-green-600" />
          </HighlightItemV2.Body>
          <HighlightItemV2.Footer>
            <AutoPayStatusText autoPayStatus="on" />
          </HighlightItemV2.Footer>
        </>
      )}
    </HighlightItemV2>
  );
};

export default NextAutoPaymentHighlightItem;
