import { FC } from "react";
import { IconRenderFunction, IconSizeRenderProps } from "utils/react-helpers/IconRenderFunction";
import { SingleRenderedElement } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

export type PillColor =
  | "blue-light"
  | "green-light"
  | "green-dark"
  | "grey-legacy"
  | "grey-light"
  | "orange-legacy"
  | "orange-red-legacy"
  | "pink-legacy"
  | "pink-dark"
  | "purple-dark"
  | "purple-legacy"
  | "purple-light"
  | "red-legacy"
  | "yellow-dark"
  | "yellow-legacy"
  | "white"
  | "custom";

const getPillColorClasses = (color: PillColor): string => {
  return variants(color, {
    "blue-light": "border-blue bg-blue-light text-blue",
    "green-light": "border-green-600 bg-green-100 text-green-600",
    "green-dark": "border-green-50 bg-green-500 text-green-50",
    "grey-legacy": "border-grey-700 bg-grey-200 text-grey-700",
    "grey-light": "border-grey-500 bg-grey-100 text-grey-500",
    "orange-legacy": "border-orange-700 bg-orange-200 text-orange-700",
    "orange-red-legacy": "border-orange-500 bg-orange-100 text-orange-500",
    "pink-legacy": "border-pink-700 bg-pink-200 text-pink-700",
    "pink-dark": "border-pink-500 bg-pink-100 text-pink-500",
    "purple-dark": "border-purple-50 bg-purple-500 text-purple-50",
    "purple-legacy": "border-purple-700 bg-purple-200 text-purple-700",
    "purple-light": "border-purple-500 bg-purple-100 text-purple-500",
    "red-legacy": "border-red-700 bg-red-200 text-red-700",
    "yellow-dark": "border-yellow-900 bg-yellow-500 text-yellow-900",
    "yellow-legacy": "border-yellow-700 bg-yellow-100 text-yellow-700",
    white: "border-black bg-white text-black",
    custom: "",
  });
};

export type PillSize = "2xs" | "xs";

type Props = {
  // Any singular rendered element is allowed. This ensures people use `iconLeft`/`iconRight` props instead of passing the icon in as `children`.
  children: SingleRenderedElement;
  iconLeft?: IconRenderFunction;
  iconRight?: IconRenderFunction;
  color: PillColor;
  className?: string;
  size?: PillSize;
  bordered?: boolean;
  onClick?: () => void;
};

const Pill: FC<Props> = ({
  children,
  color,
  className,
  size = "xs",
  bordered,
  onClick,
  iconLeft,
  iconRight,
}) => {
  const iconRenderProps: IconSizeRenderProps = variants(size, {
    "2xs": { size: 12, sizeClassName: "size-3" },
    xs: { size: 16, sizeClassName: "size-4" },
  });

  return (
    <span
      className={cn(
        "inline-flex h-min w-min items-center justify-end whitespace-nowrap rounded-full font-medium",
        "gap-x-1 px-2 py-1",
        variants(size, {
          "2xs": "text-2xs",
          xs: "text-xs",
        }),
        bordered ? "border" : "border-0",
        onClick && "cursor-pointer",
        getPillColorClasses(color),
        className
      )}
      onClick={onClick}
    >
      {/* We always want to render the `span`s because we always want the added gap between the children and spans regardless of if an icon is passed in. */}
      <span className="inline-flex">{iconLeft?.(iconRenderProps)}</span>
      <span
        className={variants(size, {
          "2xs": undefined,
          xs: "mb-[-1px]", // This helps a lot with making text look vertically centered next to icons, especially circular ones.
        })}
      >
        {children}
      </span>
      <span className="inline-flex">{iconRight?.(iconRenderProps)}</span>
    </span>
  );
};

export default Pill;
