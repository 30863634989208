import { PlusCircle } from "@phosphor-icons/react";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import IconWithTooltip from "components/common/IconWithTooltip";
import MultiStep from "layouts/MultiStep";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import CreditApplicationRep from "reps/CreditApplicationRep";
import useCreditApplicationDocumentsQueryOptions from "resources/credit-application/queries/useCreditApplicationDocumentsQueryOptions";
import useCreditApplicationQueryOptions from "resources/credit-application/queries/useCreditApplicationQueryOptions";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Button from "ui/inputs/Button";
import CheckboxLabel from "ui/inputs/CheckboxLabel";
import RadioWithLabel from "ui/inputs/RadioWithLabel";
import TextInput, { Filter } from "ui/inputs/TextInput";
import Text from "ui/typography/Text";
import { getDaysFromMs, getMsFromDays } from "utils/date";

import CreditApplicationBackButton from "../../components/CreditApplicationBackButton";
import UploadDocumentsSection from "../../components/UploadDocumentsSection";
import useGetNextPathname from "../../hooks/useGetNextPathname";
import useUpdateCreditApplication from "../../hooks/useUpdateCreditApplication";
import { getCountByDocumentType } from "../UploadFinancialDocumentsView";

import CopackerInformationCard from "./CopackerInformationCard";
import styles from "./InventoryFulfillementView.module.scss";
import {
  Copacker,
  CopackersValidationErrors,
  getCopackersList,
  isValidCopackersInfo,
  isValidLeadTimeInDays,
} from "./inventoryUtils";

const INVENTORY_AND_FULFILLMENT_FORM_ID = "inventory-and-fulfillment-form";

const INITIAL_COPACKER_INFO: Copacker = { businessName: "", address: { country: "" } };

const InventoryAndFulfillmentView = () => {
  const { data: creditApplicationDocumentsData } = useQuery(
    useCreditApplicationDocumentsQueryOptions()
  );
  const { data: creditApplicationData } = useSuspenseQuery(useCreditApplicationQueryOptions());
  const [inventoryLeadTime, setInventoryLeadTime] = useState("");
  const [isShowingInventoryLeadTimeError, setIsShowingInventoryLeadTimeError] = useState(false);
  const [copackers, setCopackers] = useState<Copacker[]>([]);
  const [validationErrors, setValidationErrors] = useState<CopackersValidationErrors[]>([]);
  const [skipInventoryScheduleReason, setSkipInventoryScheduleReason] =
    useState<CreditApplicationRep.SkipInventoryRequirementReasonRep>();
  const [skipInventoryScheduleExplanation, setSkipInventoryScheduleExplanation] =
    useState<string>();
  const [noInventoryScheduleDocs, setNoInventoryScheduleDocs] = useState(false);
  const isSuperusering = useIsSuperusering();
  const navigate = useNavigate();
  const nextPathname = useGetNextPathname();

  useEffect(() => {
    if (creditApplicationData?.userProvidedDetails?.copackers) {
      setCopackers(creditApplicationData.userProvidedDetails.copackers);
    }
    if (creditApplicationData?.userProvidedDetails?.inventoryLeadTime !== undefined) {
      const msToDays = getDaysFromMs(creditApplicationData.userProvidedDetails.inventoryLeadTime);
      setInventoryLeadTime(msToDays ? msToDays.toString() : "");
    }
    if (creditApplicationData?.userProvidedDetails?.skipInventoryRequirementReason) {
      setNoInventoryScheduleDocs(true);
      setSkipInventoryScheduleReason(
        creditApplicationData.userProvidedDetails.skipInventoryRequirementReason.reason
      );
      setSkipInventoryScheduleExplanation(
        creditApplicationData.userProvidedDetails.skipInventoryRequirementReason.explanation
      );
    }
  }, [creditApplicationData]);

  const { mutateAsync: updateCreditApplication, isPending: isUpdateCreditApplicationLoading } =
    useUpdateCreditApplication();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (
      isValidCopackersInfo(copackers, validationErrors) &&
      isValidLeadTimeInDays(inventoryLeadTime)
    ) {
      await updateCreditApplication({
        userProvidedDetails: {
          ...creditApplicationData?.userProvidedDetails,
          ...(noInventoryScheduleDocs && skipInventoryScheduleReason
            ? {
                skipInventoryRequirementReason: {
                  reason: skipInventoryScheduleReason,
                  explanation: skipInventoryScheduleExplanation,
                },
              }
            : { skipInventoryRequirementReason: undefined }), // Reset to undefined if user returning to upload docs
          inventoryLeadTime: getMsFromDays(parseInt(inventoryLeadTime)),
          copackers: getCopackersList(copackers),
        },
      });
    }
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  const onCopackerInfoChange = (updatedCopacker: Copacker, index: number) => {
    const updatedCopackersInfo = copackers.map((copacker, i) => {
      if (i === index) {
        return updatedCopacker;
      }
      return copacker;
    });
    setCopackers(updatedCopackersInfo);
  };

  const onValidationError = (updatedError: CopackersValidationErrors, index: number) => {
    const updatedErrors = validationErrors.map((err, i) => {
      if (i === index) {
        return updatedError;
      }
      return err;
    });
    setValidationErrors(updatedErrors);
  };

  const onCopackerDelete = (idxToDelete: number) => {
    setCopackers(copackers.filter((_, index) => index !== idxToDelete));
  };

  const inventoryScheduleCount = getCountByDocumentType(
    creditApplicationDocumentsData || [],
    CreditApplicationDocumentRep.DocumentType.InventorySchedule
  );

  const isSkipInventoryReasonAndExplanationProvided =
    skipInventoryScheduleReason === "Other"
      ? skipInventoryScheduleExplanation?.length
      : Boolean(skipInventoryScheduleReason);

  const isSubmitDisabled = noInventoryScheduleDocs
    ? !isSkipInventoryReasonAndExplanationProvided
    : copackers.length > 0
      ? !(isValidCopackersInfo(copackers, validationErrors) && inventoryScheduleCount >= 1)
      : inventoryScheduleCount < 1;

  return (
    <MultiStep.Form id={INVENTORY_AND_FULFILLMENT_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section.Header>
        <MultiStep.Section.Header.Heading>
          Inventory and fulfillment
        </MultiStep.Section.Header.Heading>
        <MultiStep.Section.Header.Subheading>
          Help us learn about your inventory and fulfillment process so we can estimate how it will
          impact your cash flow.
        </MultiStep.Section.Header.Subheading>
      </MultiStep.Section.Header>

      <MultiStep.Section.Spacer />

      <TextInput
        value={inventoryLeadTime}
        label="What is your inventory lead time? (in days)"
        hasError={isShowingInventoryLeadTimeError}
        errorMessage="Please provide a valid duration (in days)"
        inputFilter={Filter.DIGITS}
        onBlur={() => {
          const validInventoryLeadTime = isValidLeadTimeInDays(inventoryLeadTime);
          setIsShowingInventoryLeadTimeError(!validInventoryLeadTime);
        }}
        onChange={setInventoryLeadTime}
      />

      <MultiStep.Section.Spacer />
      <MultiStep.Section.Spacer />

      <div className={styles.copackersContainer}>
        <div className={styles.copackersInfoText}>
          <Text size={14}>Do you use copackers?</Text>
          <IconWithTooltip
            color="primary"
            tooltip="A contract packager, or co-packer, is a company that packages products for their clients"
          />
        </div>
        <div className={styles.copackersRadioLabels}>
          <RadioWithLabel
            label="No"
            checked={!copackers.length}
            onClick={() => {
              setCopackers([]);
              setValidationErrors([]);
            }}
          />
          <RadioWithLabel
            label="Yes"
            checked={Boolean(copackers.length)}
            onClick={() => {
              setCopackers([INITIAL_COPACKER_INFO]);
              setValidationErrors([{}]);
            }}
          />
        </div>
      </div>

      <MultiStep.Section.Spacer />
      <MultiStep.Section.Spacer />

      {copackers.length > 0 && (
        <div className={styles.addCopacker}>
          {copackers.map((copacker, index) => (
            <CopackerInformationCard
              key={index}
              copackerIdx={index}
              copacker={copacker}
              onCopackerInfoChange={onCopackerInfoChange}
              onCopackerDelete={onCopackerDelete}
              validationErrors={validationErrors[index]}
              onValidationError={onValidationError}
            />
          ))}
          <Button
            className={styles.addCopackerButton}
            variant="default"
            onClick={() => {
              setCopackers([...copackers, INITIAL_COPACKER_INFO]);
              setValidationErrors([...validationErrors, {}]);
            }}
          >
            <PlusCircle size={24} />
            Add copacker
          </Button>
        </div>
      )}

      <MultiStep.Section.Spacer />
      <MultiStep.Section.Spacer />

      <UploadDocumentsSection
        heading={
          <div className={styles.uploadDocumentsWithExampleButton}>
            <Text className={styles.inventoryScheduleHeader}>
              Inventory schedule
              <IconWithTooltip
                color="primary"
                tooltip="Any document that captures a breakdown of your inventory levels. This often includes SKUs, quantity, price, costs associated such as storage costs, and age (purchase date, delivery date, etc.)"
              />
            </Text>
          </div>
        }
        type={CreditApplicationDocumentRep.DocumentType.InventorySchedule}
      />

      <CheckboxLabel
        label="I do not have an inventory schedule"
        checked={noInventoryScheduleDocs}
        textWeight="regular"
        onChange={() => setNoInventoryScheduleDocs(!noInventoryScheduleDocs)}
      />

      <MultiStep.Section.Spacer />

      {noInventoryScheduleDocs && (
        <div>
          <MultiStep.Section.Header.Heading>Reason:</MultiStep.Section.Header.Heading>
          <div className={styles.skipReasonRadioContainer}>
            <RadioWithLabel
              key="do-not-carry-inventory"
              label="I don’t carry inventory (e.g. drop-shipping, services, etc.)"
              checked={skipInventoryScheduleReason === "DoNotCarryInventory"}
              onClick={() => setSkipInventoryScheduleReason("DoNotCarryInventory")}
            />
            <RadioWithLabel
              key="other"
              label="Other"
              checked={skipInventoryScheduleReason === "Other"}
              onClick={() => setSkipInventoryScheduleReason("Other")}
            />
          </div>
          {skipInventoryScheduleReason === "Other" && (
            <TextInput
              onChange={setSkipInventoryScheduleExplanation}
              value={skipInventoryScheduleExplanation}
              placeholder="I don’t have updated documents at this time."
            />
          )}
        </div>
      )}

      <MultiStep.Controls>
        <CreditApplicationBackButton />

        <MultiStep.Controls.NextButton
          form={INVENTORY_AND_FULFILLMENT_FORM_ID}
          disabled={isSubmitDisabled}
          isLoading={isUpdateCreditApplicationLoading}
        >
          Save and continue
        </MultiStep.Controls.NextButton>

        {(isSuperusering || process.env.NODE_ENV === "development") && nextPathname && (
          <Link to={nextPathname}>
            <MultiStep.Controls.SkipButton
              className={styles.skipButton}
              isSuperuserOnly={isSuperusering}
            />
          </Link>
        )}
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default InventoryAndFulfillmentView;
