import {
  Menu as BaseMenu,
  MenuButton as BaseMenuButton,
  MenuItems as BaseMenuItems,
  ItemsRenderPropArg,
  MenuRenderPropArg,
} from "@headlessui/react";
import classNames from "classnames";
import { CSSProperties, FC, ReactNode } from "react";
import Text from "ui/typography/Text";

import styles from "./Menu.module.scss";
import MenuItem from "./MenuItem";

type Props = {
  placement?: Pick<CSSProperties, "top" | "bottom" | "left" | "right">;
  button: ReactNode | ((menuRenderProps: MenuRenderPropArg) => ReactNode);
  children: ReactNode | ((itemsRenderProps: ItemsRenderPropArg) => ReactNode);
  className?: string;
  prepend?: ReactNode;
  menuItemsClassName?: string;
};

const Menu: FC<Props> = ({
  button,
  children,
  className,
  prepend,
  placement,
  menuItemsClassName,
}) => {
  return (
    <BaseMenu>
      {(menuRenderProps) => (
        <div className={classNames(className, styles.menu)}>
          <BaseMenuButton as="div">
            {typeof button === "function" ? button(menuRenderProps) : button}
          </BaseMenuButton>

          <BaseMenuItems
            as="div"
            className={classNames(menuItemsClassName, styles.items)}
            style={{ right: 0, ...placement }}
          >
            {(itemsRenderProps) => (
              <>
                {prepend && (
                  <div className={styles.prepend}>
                    <Text size={12}>{prepend}</Text>
                  </div>
                )}

                {typeof children === "function" ? children(itemsRenderProps) : children}
              </>
            )}
          </BaseMenuItems>
        </div>
      )}
    </BaseMenu>
  );
};

export default Object.assign(Menu, {
  Item: MenuItem,
});
