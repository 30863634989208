import TransactionsTable from "components/Transactions";
import { useAccountsTransactions } from "pages/accounts/AccountsTransactionsPage/Transactions/utils";
import BankAccountRep from "reps/BankAccountRep";

type Props = {
  account: BankAccountRep.Complete;
};

const HighYieldTransactions: React.FC<Props> = ({ account }) => {
  const {
    transactions,
    isLoading,
    activeTab,
    setActiveTab,
    searchQuery,
    setSearchQuery,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    pagination,
    refreshTransactions,
  } = useAccountsTransactions(account.unitCoDepositAccountId);

  return (
    <TransactionsTable
      isLoading={isLoading}
      transactions={transactions}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      fromDate={fromDate}
      toDate={toDate}
      setFromDate={setFromDate}
      setToDate={setToDate}
      pagination={pagination}
      hasExport
      unitCoDepositAccountId={account.unitCoDepositAccountId}
      tabs={[]}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      refreshTransactions={refreshTransactions}
    />
  );
};

export default HighYieldTransactions;
