import { Info } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import useShopifyBalancesQueryOptions from "resources/connected-stores/queries/useShopifyBalancesQueryOptions";
import Banner from "ui/data-display/Banner";
import Table, { Column } from "ui/table/Table";
import Text from "ui/typography/Text";

import ShopifyBalanceRep from "../../../../reps/ShopifyBalanceRep";
import MoneyCell from "../../../../ui/table/MoneyCell";

const BalancesTable = () => {
  const { data: balances, isPending } = useQuery(useShopifyBalancesQueryOptions());

  const columns = deriveColumns();
  const data = transformData(balances);

  return (
    <>
      <h2 style={{ fontSize: "18px" }}>Balances</h2>
      {data?.length === 0 && (
        <Banner
          icon={<Info size={24} />}
          color="grey"
          title="No Shopify balances; if you recently signed up please try again in a few minutes."
        />
      )}
      <Table
        isLoading={isPending}
        rowKey={(row) => row.connectionGuid}
        data={data}
        columns={columns}
      />
    </>
  );
};

export default BalancesTable;

const deriveColumns = (): Column<ShopifyBalanceRep.Complete>[] => {
  return [
    {
      title: "Store name",
      cellRender: (datum) => <Text size={14}>{datum.storeName}</Text>,
    },
    {
      title: "Amount",
      cellRender: (datum) => <MoneyCell amount={datum.amount.toString()} currencyCode="USD" />,
    },
    {
      title: "Currency",
      cellRender: (datum) => <Text size={14}>{datum.currency}</Text>,
    },
  ];
};

const transformData = (
  balances: ShopifyBalanceRep.Complete[] | undefined
): ShopifyBalanceRep.Complete[] | undefined =>
  balances?.toSorted((a, b) => a.storeName.localeCompare(b.storeName));
