import { DotsThreeVertical } from "@phosphor-icons/react";
import classNames from "classnames";
import { cloneElement, FC, ReactElement, ReactNode } from "react";
import { Document, Page } from "react-pdf";
import Shimmer from "ui/feedback/Shimmer";

import styles from "./FilePreviewWithLabel.module.scss";

type FilePreviewWithLabelLayoutProps = {
  isActive: boolean;
  image: ReactNode;
  fileName: ReactNode;
  rightIcon: ReactNode;
  className?: string;
};

const FilePreviewWithLabelLayout: FC<FilePreviewWithLabelLayoutProps> = ({
  isActive,
  image,
  fileName,
  rightIcon,
  className,
}) => {
  return (
    <div className={classNames(className, styles.container, isActive && styles.active)}>
      <div className={styles.imageContainer}>{image}</div>

      <div className={styles.fileName}>{fileName}</div>

      {rightIcon}
    </div>
  );
};

const FilePreviewWithLabelMenuIcon = DotsThreeVertical;

type FilePreviewWithLabelLoadingProps = {
  isActive?: boolean;
  rightIcon?: ReactElement<{ size?: number }>;
  className?: string;
};

const FilePreviewWithLabelLoading: FC<FilePreviewWithLabelLoadingProps> = ({
  isActive,
  rightIcon,
  className,
}) => {
  return (
    <FilePreviewWithLabelLayout
      className={className}
      isActive={Boolean(isActive)}
      image={null}
      fileName={<Shimmer additionalShimmerClassName={[styles.shimmer]} />}
      rightIcon={
        rightIcon ? (
          cloneElement(rightIcon, { size: rightIcon?.props.size ?? 16 })
        ) : (
          <FilePreviewWithLabelMenuIcon size={16} />
        )
      }
    />
  );
};

type Props = {
  isActive: boolean;
  src: string;
  fileName: string;
  fileType: "pdf" | "image";
  rightIcon?: ReactElement<{ size?: number }>;
  className?: string;
};

const FilePreviewWithLabel: FC<Props> = ({
  isActive,
  src,
  fileName,
  fileType,
  rightIcon,
  className,
}) => {
  return (
    <FilePreviewWithLabelLayout
      className={className}
      isActive={isActive}
      image={
        <>
          {fileType === "image" && (
            <img src={src} alt={fileName} width={24} height={32} style={{ objectFit: "cover" }} />
          )}
          {fileType === "pdf" && (
            <Document file={src} loading={null}>
              <Page
                pageIndex={0}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                height={32}

                // TODO(alex): Look into using `transform: scale()` css property to scale this down. Slack thread with more context: https://highbeamco.slack.com/archives/C046AHJTHS5/p1721775182671039
              />
            </Document>
          )}
        </>
      }
      fileName={fileName}
      rightIcon={rightIcon && cloneElement(rightIcon, { size: rightIcon?.props.size ?? 16 })}
    />
  );
};

export default Object.assign(FilePreviewWithLabel, {
  Loading: FilePreviewWithLabelLoading,
  MenuIcon: FilePreviewWithLabelMenuIcon,
});
