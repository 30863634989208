import {
  AchPayment,
  OriginatedAchTransaction,
  ReceivedAchTransaction,
} from "@highbeam/unit-node-sdk";
import TraceNumberLabel from "components/Transactions/TransactionInfoFlexpane/TransactionInfoFlexpaneDetailsSection/TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";
import { useUnitCoTransaction } from "resources/unit-co-transactions/queries/useUnitCoTransactionQueryOptions";
import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";

type Props = {
  payment: AchPayment;
};

type AchTransactionTypes = OriginatedAchTransaction | ReceivedAchTransaction;

const AchPaymentDetailsSection: React.FC<Props> = ({ payment }) => {
  const transaction = useUnitCoTransaction<AchTransactionTypes>({
    accountId: payment.relationships.account.data.id,
    transactionId: payment.relationships.transaction?.data.id,
  });

  return (
    <Section>
      <Heading3>ACH details</Heading3>
      <MetadataList>
        {payment.attributes.addenda && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{payment.attributes.addenda}</MetadataList.ItemValue>
          </MetadataList.Item>
        )}
        <MetadataList.Item>
          <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {payment.attributes.counterparty.routingNumber}
          </MetadataList.ItemValue>
        </MetadataList.Item>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {payment.attributes.counterparty.accountNumber}
          </MetadataList.ItemValue>
        </MetadataList.Item>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Account type</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {payment.attributes.counterparty.accountType}
          </MetadataList.ItemValue>
        </MetadataList.Item>
        {transaction?.attributes.traceNumber && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>{<TraceNumberLabel />}</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{transaction.attributes.traceNumber}</MetadataList.ItemValue>
          </MetadataList.Item>
        )}
      </MetadataList>
    </Section>
  );
};

export default AchPaymentDetailsSection;
