import classNames from "classnames";
import Text from "ui/typography/Text";

import styles from "./LegalFootnote.module.scss";

type TextComponent = React.FC<React.ComponentProps<typeof Text>>;

export const DashboardPageSectionDividerLine = () => <div className={styles.dividerLine} />;

export const LegalFootnoteTitle: TextComponent = ({ className, ...textProps }) => (
  <Text className={classNames(className, styles.title)} {...textProps} />
);

export const LegalFootnoteParagraph: TextComponent = ({ className, ...textProps }) => (
  <Text className={classNames(className, styles.paragraph)} {...textProps} />
);

const LegalFootnote: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

export default Object.assign(LegalFootnote, {
  DividerLine: DashboardPageSectionDividerLine,
  Title: LegalFootnoteTitle,
  Paragraph: LegalFootnoteParagraph,
});
