import { ComponentProps, FC } from "react";
import cn from "utils/tailwind/cn";

import FieldsetLegend from "./FieldsetLegend";
import FieldsetRow from "./FieldsetRow";

type Props = ComponentProps<"fieldset">;

const Fieldset: FC<Props> = ({ children, className, ...fieldsetProps }) => {
  return (
    <fieldset className={cn("flex flex-col gap-4 @container", className)} {...fieldsetProps}>
      {children}
    </fieldset>
  );
};

export default Object.assign(Fieldset, {
  Legend: FieldsetLegend,
  Row: FieldsetRow,
});
