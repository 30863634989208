import { DialogTitle } from "@headlessui/react";
import closeIcon from "assets/close-icon.svg";
import { FC, ReactNode } from "react";
import { Heading2 } from "ui/typography";

type Props = {
  title?: string;
  children: ReactNode;
  right?: ReactNode;
  onClose: () => void;
};

const FlexpaneHeader: FC<Props> = ({ children, right, onClose }) => (
  <DialogTitle
    as="div"
    className="flex items-center justify-between border-b border-b-grey-200 px-8 py-4"
  >
    <Heading2>{children}</Heading2>

    <div className="flex items-center justify-end gap-x-6">
      {right}

      <button className="size-5" onClick={onClose}>
        <img src={closeIcon} alt="Close" title="Close" />
      </button>
    </div>
  </DialogTitle>
);

export default FlexpaneHeader;
