import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useTransactionSubcategoriesQueryOptions = () => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: ["transaction-subcategories", { businessGuid }],
    queryFn: () => {
      return highbeamApi.subcategories.getByBusiness(businessGuid);
    },
  });
};

export default useTransactionSubcategoriesQueryOptions;
