import InsightsCategoryRep from "reps/InsightsCategoryRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class InsightsCategoryApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async search(): Promise<InsightsCategoryRep.Complete[]> {
    const url = `/insights/insights-categories`;
    return (await this.api.get<InsightsCategoryRep.Complete[]>(url))!;
  }
}
