import Fuse, { IFuseOptions } from "fuse.js";
import { useMemo } from "react";

export const useFuse = <T>(list: T[], options?: IFuseOptions<T>) => {
  return useMemo(() => new Fuse(list, options), [list, options]);
};

export const useFuseSearch = <T>(list: T[], searchQuery: string, options?: IFuseOptions<T>) => {
  const fuse = useFuse(list, options);
  return useMemo(() => {
    // We pretty much always want to disable search if the search query is blank, as opposed to returning an empty list.
    if (searchQuery === "") {
      return list;
    }
    return fuse.search(searchQuery).map((result) => result.item);
  }, [fuse, searchQuery, list]);
};
