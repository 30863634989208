import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  readAgreementConfirmed: z.boolean(),
});

export type ReviewUpdatedLineAgreementFormInputs = z.infer<typeof schema>;

const useReviewUpdatedLineAgreementForm = () => {
  return useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      readAgreementConfirmed: false,
    },
  });
};

export default useReviewUpdatedLineAgreementForm;
