import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import { FC, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import businessGuidState from "state/auth/businessGuid";
import isSuperuseringState from "state/auth/isSuperusering";

type Props = {
  indexRoute: string;
};

const SuperuserRedirect: FC<Props> = ({ indexRoute }) => {
  const [searchParams] = useSearchParams();
  const businessGuid = searchParams.get("businessGuid");
  const canSuperuser = useCanSuperuser();
  const setIsSuperusering = useSetRecoilState(isSuperuseringState);
  const setBusinessGuid = useSetRecoilState(businessGuidState);

  useEffect(() => {
    if (!businessGuid || !canSuperuser) return;
    setIsSuperusering(Boolean(businessGuid));
    setBusinessGuid(businessGuid);
  }, [businessGuid, setIsSuperusering, setBusinessGuid, canSuperuser]);

  return <Navigate replace to={indexRoute} />;
};

export default SuperuserRedirect;
