import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useUserInvitationsQueryOptions = () => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: ["userInvitations", { businessGuid }],
    queryFn: () => {
      return highbeamApi.userInvitation.getByBusiness(businessGuid);
    },
  });
};

export default useUserInvitationsQueryOptions;
