import PayeeRep from "reps/PayeeRep";
import SuggestedPayeeRep from "reps/SuggestedPayeeRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillPayeeApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getSuggestedPayee(billId: string): Promise<SuggestedPayeeRep.Complete | null> {
    const url = `/accounts-payable/bills/${billId}/suggested-payee`;
    return await this.api.get<SuggestedPayeeRep.Complete>(url);
  }

  async updatePayeeForBillPay(
    businessGuid: string,
    payeeGuid: string,
    update: PayeeRep.Update
  ): Promise<PayeeRep.Complete> {
    const url = `/accounts-payable/business/${businessGuid}/payee/${payeeGuid}`;
    return (await this.api.patch<PayeeRep.Complete>(url, update))!;
  }
}
