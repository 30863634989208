import amazonLogo from "assets/amazon-logo.svg";
import paypalLogo from "assets/paypal-logo.svg";
import shopifyLogo from "assets/shopify-logo.svg";
import stripeLogo from "assets/stripe-logo.svg";

import { ConnectedStoreType } from "../types";

type Params = {
  connectedStoreType: ConnectedStoreType;
};

const getConnectedStoreLogoWithName = ({ connectedStoreType }: Params): string => {
  switch (connectedStoreType) {
    case "Shopify":
      return shopifyLogo;
    case "Amazon":
      return amazonLogo;
    case "Stripe":
      return stripeLogo;
    case "PayPal":
      return paypalLogo;
  }
};

export default getConnectedStoreLogoWithName;
