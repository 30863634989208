import dayjs from "dayjs";
import React from "react";
import PaymentSwitcherAutoPaymentRep from "reps/PaymentSwitcherAutoPaymentRep";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmountV2";
import { Column } from "ui/table/Table";
import Text from "ui/typography/Text";

type Props = {
  payment: PaymentSwitcherAutoPaymentRep;
};

const LastPaymentCell: React.FC<Props> = ({ payment }) => {
  const amount = payment.lastPayment.amount;
  const date = dayjs(payment.lastPayment.date);

  return (
    <div className="flex flex-col gap-1">
      <MoneyAmount
        as="p"
        size={14}
        weight="medium"
        color={colors.grey[800]}
        showCurrencySymbol
        showCents={false}
        amount={amount.amount}
        currencyCode={amount.currency}
      />
      <Text as="p" size={12} weight="regular" color={colors.grey[600]}>
        {date.format("MMM D")}
      </Text>
    </div>
  );
};

export default Object.assign(LastPaymentCell, {
  column: {
    title: "Last payment",
    cellRender: (payment) => <LastPaymentCell payment={payment} />,
    headerClassName: "min-w-[174px] whitespace-nowrap",
  } satisfies Column<PaymentSwitcherAutoPaymentRep>,
});
