import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import UserRoleRep from "reps/UserRoleRep";
import { z } from "zod";

const schema = z.object({
  firstName: z.string().min(1, { message: "This field is required." }),
  lastName: z.string().min(1, { message: "This field is required." }),
  emailAddress: z.string().email({ message: "Please enter a valid email address." }),
  userRole: z
    .custom<UserRoleRep.Complete | null>()
    .refine((value): value is UserRoleRep.Complete => Boolean(value), "Please select a role."),
});

export type InviteUserFormInputs = z.input<typeof schema>;
export type InviteUserFormTransformedOutputs = z.output<typeof schema>;

const useInviteUserForm = () => {
  const defaultValues: InviteUserFormInputs = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    userRole: null,
  };

  return useForm<InviteUserFormInputs, object, InviteUserFormTransformedOutputs>({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
  });
};

export default useInviteUserForm;
