import colors from "styles/colors";

type Props = {
  size?: number;
  color?: string;
};

const PaymentIcon: React.FC<Props> = ({ size = 16, color = colors.grey[600] }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      d="M8 5H1.5C1.22386 5 1 5.22386 1 5.5V12.5C1 12.7761 1.22386 13 1.5 13H14.5C14.7761 13 15 12.7761 15 12.5V5.5C15 5.22386 14.7761 5 14.5 5H13.6875"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 11C9.10457 11 10 10.1046 10 9C10 7.89543 9.10457 7 8 7C6.89543 7 6 7.89543 6 9C6 10.1046 6.89543 11 8 11Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11 13L15 9.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 5L1 8.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 13L1 9.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.625 5.625L9.875 6.375L9.125 5.625"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.875 2.625L9.875 6.375"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.375 3.375L12.125 2.625L12.875 3.375"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.125 6.375L12.125 2.625"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PaymentIcon;
