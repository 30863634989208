import { captureException } from "@sentry/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import SubcategoryDropdown, { initialSubcategory } from "components/SubcategoryDropdown";
import { useState } from "react";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useTransactionSubcategoryOptions from "resources/transaction-subcategories/hooks/useTransactionSubcategoryOptions";
import { useDropdown, Option, OnDropdownChange } from "ui/inputs/Dropdown";
import Helper from "ui/inputs/Helper";
import TextLink from "ui/navigation/TextLink";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { HighbeamTransaction } from "utils/types/transactionsTypes";

const SOURCE_BY_TRANSACTION_QUERY_KEY = "source-by-transaction";

type Props = {
  transaction: HighbeamTransaction;
};

const TransactionSubcategoryDropdown: React.FC<Props> = ({ transaction }) => {
  const subcategoryOptions = useTransactionSubcategoryOptions();

  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  // NB(alex): Putting this here inline because we are planning to delete this soon, and I'd like to just refactor this recoil state for now.
  const { data: highbeamTransaction, refetch } = useSuspenseQuery({
    queryKey: [SOURCE_BY_TRANSACTION_QUERY_KEY, transaction.id],
    queryFn: async () => {
      return (
        await highbeamApi.transaction.getBySourceTransactionId(businessGuid, [transaction.id])
      )[0];
    },
  });

  const [isUpdatingCategory, setIsUpdatingCategory] = useState<boolean>(false);
  const initialCategory = initialSubcategory(
    highbeamTransaction?.subcategoryGuid || undefined,
    subcategoryOptions
  );

  const setTransactionCategory = async (
    subcategoryOption: Option | null
  ): Promise<OnDropdownChange> => {
    if (highbeamTransaction === null) return { error: false };
    if (subcategoryOption === null) return { error: false };

    setIsUpdatingCategory(true);

    try {
      await highbeamApi.transaction.setTransactionCategory(
        businessGuid,
        highbeamTransaction!.guid,
        subcategoryOption.value
      );
      await refetch();
      setIsUpdatingCategory(false);

      return { error: false };
    } catch (e) {
      captureException(e);
      setIsUpdatingCategory(false);

      return {
        error: true,
        errorMessage: "Could not update this transaction’s category at this time.",
      };
    }
  };

  const dropdownState = useDropdown({
    initialValue: initialCategory,
    onChange: setTransactionCategory,
    shouldSetTouchedOnChange: true,
  });

  const isPayeeRelatedTransaction = Boolean(transaction.payeeGuid);
  const categorizePayeeInfo = isPayeeRelatedTransaction ? (
    <>
      or{" "}
      <TextLink to={`/payments/payees/${transaction.payeeGuid}/edit`}>
        {initialCategory ? "edit" : "set"} the category for this payee
      </TextLink>{" "}
      to have these transactions automatically categorized.
    </>
  ) : null;

  return (
    <div>
      <SubcategoryDropdown isLoading={isUpdatingCategory} dropdownState={dropdownState} />
      <Helper iconVariant="info">
        {initialCategory ? "Change the" : "Assign a"} category for this transaction only{" "}
        {categorizePayeeInfo}
      </Helper>
    </div>
  );
};

export default TransactionSubcategoryDropdown;
