import {
  CASH_FLOW_DURATION_OPTIONS,
  CashFlowDurationOption,
  useCashFlowContext,
} from "pages/CashFlowPage/providers/CashFlowProvider";
import Dropdown from "ui/inputs/Dropdown";

import styles from "./CashFlowDurationDropdown.module.scss";

const cashFlowDurationDropdownLabels = {
  "last-3-months": "Last 3 months",
  "last-6-months": "Last 6 months",
  "last-12-months": "Last 12 months",
  "year-to-date": "Year-to-date",
} satisfies { [key in CashFlowDurationOption]: string };

const DROPDOWN_OPTIONS = CASH_FLOW_DURATION_OPTIONS.map((value) => ({
  value,
  label: cashFlowDurationDropdownLabels[value],
}));

const CashFlowDurationDropdown = () => {
  const { duration, setDuration } = useCashFlowContext();

  // Hack is necessary for our current `Dropdown` implementation.
  const dropdownValue = DROPDOWN_OPTIONS.find((option) => option.value === duration) ?? null;

  return (
    <Dropdown
      options={DROPDOWN_OPTIONS}
      value={dropdownValue}
      onChange={(option) => setDuration(option.value)}
      className={styles.container}
    />
  );
};

export default CashFlowDurationDropdown;
