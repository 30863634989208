import classNames from "classnames";
import React, { createRef, CSSProperties, ReactNode, useCallback } from "react";
import { useResizeObserver } from "utils/customHooks/useResizeObserver";

import styles from "./Table.module.scss";
import { useTable } from "./TableWrapper";

type TableLayout = "auto" | "fixed";

type Props = {
  /**
   * Defines how column widths should be determined.
   * See https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout.
   */
  layout?: TableLayout;

  /**
   * Enables horizontal scrolling on the table.
   */
  scrollable?: boolean;

  /**
   * Applies additional styling to the table container.
   */
  style?: ({ length }: { length: number | undefined }) => CSSProperties | undefined;

  children: ReactNode;

  className?: string;
};

const Table: React.FC<Props> = ({
  layout = "auto",
  scrollable = false,
  style = undefined,
  children,
  className,
}) => {
  const { data, renderLoader, isLoading, setScroll } = useTable();

  const ref = createRef<HTMLDivElement>();

  const handleScroll = useCallback(
    (e: HTMLDivElement) => {
      if (!scrollable) return;
      const scrollThreshold = 5;
      setScroll({
        isAtStart: e.scrollLeft < scrollThreshold,
        isAtEnd: e.scrollWidth - (e.scrollLeft + e.offsetWidth) < scrollThreshold,
      });
    },
    [scrollable, setScroll]
  );

  useResizeObserver(ref, handleScroll);

  return (
    <div
      ref={ref}
      onScroll={({ currentTarget: e }) => handleScroll(e)}
      className={classNames(
        className,
        styles.container,
        scrollableClassName(scrollable, isLoading)
      )}
      style={style?.({ length: data?.length })}
    >
      <table className={classNames(styles.table, layoutClassName(layout))}>{children}</table>
      {isLoading && renderLoader()}
    </div>
  );
};

export default Table;

const layoutClassName = (layout: TableLayout): string => {
  switch (layout) {
    case "auto":
      return styles.layoutAuto;
    case "fixed":
      return styles.layoutFixed;
  }
};

const scrollableClassName = (scrollable: boolean, isLoading: boolean): string | undefined => {
  if (scrollable && !isLoading) return styles.scrollable;
  return undefined;
};
