import classNames from "classnames";
import { FC, ReactNode } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";

import styles from "./ModalFooter.module.scss";

export const ModalFooterSubmitButton: FC<ButtonProps> = ({ children, ...buttonProps }) => (
  <Button type="submit" variant="primary" {...buttonProps}>
    {children}
  </Button>
);

export const ModalFooterCloseButton: FC<ButtonProps> = ({ children = "Close", ...buttonProps }) => (
  <Button type="button" variant="default" {...buttonProps}>
    {children}
  </Button>
);

type ModalFooterProps = {
  children: ReactNode;
  className?: string;
  onClose?: () => void;
};

const ModalFooter: FC<ModalFooterProps> = ({ children, className, onClose }) => {
  return (
    <div className={classNames(styles.footer, className)}>
      {children}
      {onClose && <ModalFooterCloseButton onClick={onClose} />}
    </div>
  );
};

export default Object.assign(ModalFooter, {
  SubmitButton: ModalFooterSubmitButton,
  CloseButton: ModalFooterCloseButton,
});
