import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";

import { DEMO_BUSINESS_GUID } from "../constants";

const useIsDemoAccount = () => {
  const businessGuid = useBusinessGuid();
  return businessGuid === DEMO_BUSINESS_GUID;
};

export default useIsDemoAccount;
