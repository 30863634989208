import { Authorization } from "@highbeam/unit-node-sdk";
import TransactionsV2 from "components/TransactionsV2";
import { CARD_TRANSACTIONS_PAGE_CARD_TRANSACTIONS_TABLE_PAGE_SIZE } from "config/constants/filters";
import dayjs from "dayjs";
import { CARD_TRANSACTIONS_TABLE_TABS } from "modules/card-transactions-table/constants";
import useDownloadCardTransactionsCsvMutation from "modules/card-transactions-table/mutations/useDownloadCardTransactionsCsvMutation";
import { CardTransactionsTableTransaction } from "modules/card-transactions-table/queries/useCardTransactionsTableData";
import { Suspense } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CardAvatarBarByCardId } from "resources/cards/components/CardAvatarBar";
import cardsQueryHooks from "resources/cards/queries/cardsQueryHooks";
import getCardDropdownOptions from "resources/cards/utils/getCardDropdownOptions";
import CardReversalTransactionRelatedTransactionComponent from "resources/transactions/components/CardReversalTransactionRelatedTransactionComponent";
import colors from "styles/colors";
import Shimmer from "ui/feedback/Shimmer";
import { DISABLE_SCROLL_TO_TOP_STATE } from "ui/navigation/ScrollToTopOnNavigate";
import AmountCell from "ui/table/AmountCell";
import DateTimeCell from "ui/table/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import { maskAccountNumber } from "utils/account";

import useCardTransactionsPageSearchParams from "../state/useCardTransactionsPageSearchParams";

import useCardTransactionsPageCardTransactionsTableData from "./hooks/useCardTransactionsPageCardTransactionsTableData";

// TODO(alex): Consolidate duplicate code from `src/pages/cards/CardDetailsPage/components/CardTransactionsTable/index.tsx`

const columns: Column<CardTransactionsTableTransaction | Authorization>[] = [
  {
    title: "Date",
    cellRender: ({ attributes }) => <DateTimeCell date={attributes.createdAt} />,
  },
  {
    title: "Vendor",
    cellRender: (datum) => {
      switch (datum.type) {
        case "authorization":
        case "cardTransaction":
        case "purchaseTransaction":
          return (
            <Text size={14} weight="medium" color={colors.grey[800]}>
              {datum.attributes.merchant.name}
            </Text>
          );
        case "atmTransaction":
          return (
            <Text size={14} weight="medium" color={colors.grey[800]}>
              {datum.attributes.atmName}
            </Text>
          );
        case "cardReversalTransaction":
          return (
            <CardReversalTransactionRelatedTransactionComponent cardReversalTransaction={datum}>
              {({ relatedTransaction }) => (
                <Text size={14} weight="medium" color={colors.grey[800]}>
                  {relatedTransaction.attributes.merchant.name}
                </Text>
              )}
            </CardReversalTransactionRelatedTransactionComponent>
          );
      }
    },
  },
  {
    title: "Card",
    cellRender: (datum) => {
      switch (datum.type) {
        case "authorization":
        case "cardTransaction":
        case "purchaseTransaction":
        case "atmTransaction":
          return <CardAvatarBarByCardId cardId={datum.relationships.card.data.id} />;
        case "cardReversalTransaction":
          return (
            <CardReversalTransactionRelatedTransactionComponent cardReversalTransaction={datum}>
              {({ relatedTransaction }) => (
                <CardAvatarBarByCardId cardId={relatedTransaction.relationships.card.data.id} />
              )}
            </CardReversalTransactionRelatedTransactionComponent>
          );
      }
    },
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: (datum) => {
      switch (datum.type) {
        case "authorization":
          return <AmountCell cents={datum.attributes.amount} direction="negative" />;
        case "cardTransaction":
        case "purchaseTransaction":
        case "atmTransaction":
        case "cardReversalTransaction":
          return (
            <AmountCell
              cents={datum.attributes.amount}
              direction={datum.attributes.direction === "Credit" ? "positive" : "negative"}
            />
          );
      }
    },
  },
];

const getTransactionFlexpaneUrl = (
  transaction: CardTransactionsTableTransaction | Authorization
): string => {
  switch (transaction.type) {
    case "atmTransaction":
    case "cardReversalTransaction":
    case "cardTransaction":
    case "purchaseTransaction":
      return `/cards/transactions/${transaction.relationships.account.data.id}/${transaction.id}`;
    case "authorization":
      return `/cards/transactions/${transaction.attributes.status.toLowerCase()}/${transaction.id}`;
  }
};

const AllCardsTransactionsTable = () => {
  const { data } = useCardTransactionsPageCardTransactionsTableData();
  const navigate = useNavigate();

  // NB(alex): Prevents the underlying page from crashing if search params have been set. Would be nice to figure out a cleaner solution.
  const [searchParams] = useSearchParams();

  return (
    <TransactionsV2.TransactionsTable
      data={data}
      rowKey={({ id }) => id}
      columns={columns}
      onRowClick={(transaction) => {
        const url = getTransactionFlexpaneUrl(transaction);
        navigate(`${url}?${searchParams}`, { state: DISABLE_SCROLL_TO_TOP_STATE });
      }}
    />
  );
};

const DatePickers = () => {
  const { since, setSince, until, setUntil } = useCardTransactionsPageSearchParams();

  return (
    <TransactionsV2.DateRangePickers
      fromPicker={
        <TransactionsV2.FromDatePicker
          fromDate={since.toDate()}
          toDate={until.toDate()}
          setFromDate={(date) => setSince(dayjs(date))}
        />
      }
      toPicker={
        <TransactionsV2.ToDatePicker
          fromDate={since.toDate()}
          toDate={until.toDate()}
          setToDate={(date) => setUntil(dayjs(date))}
        />
      }
    />
  );
};

const Dropdown = () => {
  const { cardId, setCardId } = useCardTransactionsPageSearchParams();

  const cardOptions = cardsQueryHooks.useData({
    status: "default",
    select: (cards) => {
      return getCardDropdownOptions(cards);
    },
  });

  const currentOption = cardOptions.find((option) => option.value === cardId) ?? cardOptions[0];

  return (
    <TransactionsV2.Dropdown
      onChange={(option) => {
        setCardId(option.value);
      }}
      value={currentOption}
      label="Show transactions from"
      options={cardOptions}
    />
  );
};

const SearchBar = () => {
  // TODO: Debounce the underlying search required
  const { search, setSearch, activeTab } = useCardTransactionsPageSearchParams();
  const shouldDisableSearch = activeTab !== "Completed";

  return (
    <TransactionsV2.SearchBar
      disabled={shouldDisableSearch}
      wide={false}
      value={search}
      onChange={setSearch}
    />
  );
};

const PaginationInfo = () => {
  const { pagination } = useCardTransactionsPageCardTransactionsTableData();

  return (
    <TransactionsV2.PaginationInfo
      count={Math.min(pagination.total, CARD_TRANSACTIONS_PAGE_CARD_TRANSACTIONS_TABLE_PAGE_SIZE)}
      totalCount={pagination.total}
    />
  );
};

const TransactionsPageIndicator = () => {
  const { page, setPage } = useCardTransactionsPageSearchParams();
  const { pagination } = useCardTransactionsPageCardTransactionsTableData();
  const totalPages = Math.ceil(
    pagination.total / CARD_TRANSACTIONS_PAGE_CARD_TRANSACTIONS_TABLE_PAGE_SIZE
  );
  if (totalPages <= 1) return null;

  return (
    <TransactionsV2.PageIndicator
      currentPage={page - 1}
      setCurrentPage={(value) => setPage(value + 1)}
      totalPages={totalPages}
    />
  );
};

const ExportButton = () => {
  const { mutate: download, isPending } = useDownloadCardTransactionsCsvMutation();

  const { cardId, since, until, search, activeTab } = useCardTransactionsPageSearchParams();

  const card = cardsQueryHooks.useData({
    status: "default",
    select: (cards) => {
      return cards.find(({ id }) => id === cardId) ?? null;
    },
  });

  const handleClick = async () => {
    download(
      card
        ? {
            cardId: card.id,
            cardLast4Digits: maskAccountNumber(card.attributes.last4Digits),
            search: search,
            since: since,
            until: until,
          }
        : {
            search: search,
            since: since,
            until: until,
          }
    );
  };

  if (activeTab !== "Completed") {
    return null;
  }

  return <TransactionsV2.ExportButton isLoading={isPending} onClick={handleClick} />;
};

const FilterTabs = () => {
  const { activeTab, setActiveTab } = useCardTransactionsPageSearchParams();

  return (
    <TransactionsV2.FilterTabs
      tabs={CARD_TRANSACTIONS_TABLE_TABS}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};

const Transactions = () => {
  return (
    <TransactionsV2
      title={<TransactionsV2.Title>Transactions</TransactionsV2.Title>}
      table={
        <Suspense fallback={<Table.Loading columns={columns.map(({ title }) => ({ title }))} />}>
          <AllCardsTransactionsTable />
        </Suspense>
      }
      filters={
        <TransactionsV2.Filters
          dropdown={<Dropdown />}
          searchBar={<SearchBar />}
          dateRangePickers={<DatePickers />}
          paginationInfo={
            <Suspense fallback={<Shimmer />}>
              <PaginationInfo />
            </Suspense>
          }
          filterTabs={<FilterTabs />}
          exportButton={<ExportButton />}
        />
      }
      footer={
        <TransactionsV2.Footer
          pageIndicator={
            <Suspense fallback={<Shimmer />}>
              <TransactionsPageIndicator />
            </Suspense>
          }
        />
      }
    />
  );
};

export default Transactions;
