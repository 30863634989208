const BASE_URL = "https://mail.google.com/";

type Params = {
  // Defaults to search this email if user has multiple gmails.
  authUserEmail?: string;
  searchQuery: string;
};

const makeSearchGmailLink = ({ authUserEmail, searchQuery }: Params) => {
  let url = BASE_URL; // eslint-disable-line functional/no-let

  if (authUserEmail) {
    url += `?authuser=${authUserEmail}`;
  }

  url += `#search/${encodeURIComponent(searchQuery).replace(/%20/g, "+")}`;

  return url;
};

export default makeSearchGmailLink;
