import App from "App";
import env from "env";
import React from "react";
import { createRoot } from "react-dom/client";
import initIntercom from "vendors/intercom";
import initSentry from "vendors/sentry";

import "styles/index.css";

if (env.SENTRY_ENABLED) {
  initSentry();
}
initIntercom();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
