import { FC } from "react";
import SegmentControls from "ui/navigation/SegmentControls";
import { TabConfig, useSearchParamTabState } from "utils/tabs/useTabState";

export const cardTabs: TabConfig = {
  All: { label: "All" },
  Highbeam: { label: "Highbeam Cards" },
  Debit: { label: "Debit cards" },
};

export const useCardTabsSearchParamState = () => {
  return useSearchParamTabState("tab", cardTabs);
};

type Props = {
  activeTab: keyof typeof cardTabs;
  setActiveTab: (tab: keyof typeof cardTabs) => void;
  className?: string;
};

const CardTabs: FC<Props> = ({ activeTab, setActiveTab, className }) => {
  return (
    <SegmentControls
      size="md"
      className={className}
      tabs={cardTabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};

export default CardTabs;
