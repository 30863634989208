import React, { MouseEventHandler } from "react";

import TableCell from "./TableCell";
import { useTable } from "./TableWrapper";

import { Column, RenderHeaderOptions, SortDirection } from ".";

type Props = {
  column: Column<unknown>;
};

const HeaderTableCell: React.FC<Props> = ({ column }) => {
  const defaultSortDirection = column.defaultSortDirection ?? "ascending";

  const { sort, setSort } = useTable();

  const sortByThis = sort?.by === column.key;

  const onClick: MouseEventHandler<HTMLButtonElement> = () =>
    setSort({
      by: column.key,
      direction: sort?.by === column.key ? oppositeDirection(sort.direction) : defaultSortDirection,
    });

  const options: RenderHeaderOptions = {
    align: column.align ?? "left",
    onClick,
    sortByThis,
    sortDirection: sortByThis ? sort.direction : defaultSortDirection,
    sortable: column.sortable,
    title: column.title,
  };

  return (
    <TableCell column={column} header>
      {column.renderHeader(options)}
    </TableCell>
  );
};

export default HeaderTableCell;

const oppositeDirection = (direction: SortDirection): SortDirection => {
  switch (direction) {
    case "ascending":
      return "descending";
    case "descending":
      return "ascending";
  }
};
