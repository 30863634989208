import classNames from "classnames";
import { ComponentProps, FC, PropsWithChildren } from "react";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import CashDisplay from "ui/data-display/money/CashDisplay";
import RoundedBox from "ui/data-display/RoundedBox";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./PaymentSummary.module.scss";
import SummaryItem from "./SummaryItem";

const Title: FC<TextProps> = (props) => <Text size={16} {...props} />;

const Amount: FC<ComponentProps<typeof CashDisplay>> = (props) => <CashDisplay {...props} />;

const Header: FC<PropsWithChildren> = ({ children }) => <div>{children}</div>;

const SectionDivider: FC = () => <Divider />;

type SectionProps = JSX.IntrinsicElements["div"];

const Section: FC<SectionProps> = ({ className, children, ...divProps }) => (
  <div className={classNames(className, styles.section)} {...divProps}>
    {children}
  </div>
);

const SectionHeading: FC<TextProps> = ({ className, ...textProps }) => (
  <Text size={14} color={colors.grey[500]} weight="medium" className={className} {...textProps} />
);

const PaymentSummary: FC<PropsWithChildren> = ({ children }) => (
  <RoundedBox padding={24}>{children}</RoundedBox>
);

export default Object.assign(PaymentSummary, {
  Header: Object.assign(Header, {
    Title,
    Amount,
  }),
  SectionDivider,
  Section: Object.assign(Section, {
    Heading: SectionHeading,
    SummaryItem: SummaryItem,
  }),
});
