import { Minus } from "@phosphor-icons/react";

const NotFoundCell = () => {
  return (
    <div>
      <Minus size={12} />
    </div>
  );
};

export default NotFoundCell;
