import {
  getValidator,
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import TextInput, { useTextInput } from "ui/inputs/TextInput";

type Props = {
  selectedCountry: string;
};

const Swift: React.FC<Props> = ({ selectedCountry }) => {
  const [internationalBankingInfo, setInternationalBankingInfo] = useRecoilState(
    internationalBankingInfoState
  );
  const swift = useTextInput({
    initialValue: internationalBankingInfo.swift?.value,
    onChange: getValidator(selectedCountry, INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT),
  });

  useEffect(() => {
    setInternationalBankingInfo({
      ...internationalBankingInfo,
      swift: {
        inputName: INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
        value: swift.value,
        isValid: swift.isValid,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swift.value, swift.isValid]);

  return (
    <TextInput
      type="text"
      value={swift.value}
      onChange={(value) => {
        swift.onChange(value);
        setInternationalBankingInfo({
          ...internationalBankingInfo,
          swift: {
            inputName: INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT,
            value,
            isValid: swift.isValid,
          },
        });
      }}
      label={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.BIC_SWIFT].label}
      onBlur={swift.onBlur}
      hasError={swift.hasError}
      errorMessage={swift.errorMessage}
    />
  );
};

export default Swift;
