import { AirplaneTakeoff, ChartLineUp, CurrencyDollarSimple, Scales } from "@phosphor-icons/react";
import IconWithTooltip from "components/common/IconWithTooltip";
import { Heading3 } from "ui/typography";
import Text from "ui/typography/Text";

import styles from "./ApplicationRequirementsSummary.module.scss";

const ApplicationRequirementsSummary = () => {
  return (
    <>
      <div className={styles.requirementsText}>
        <Heading3>Application requirements</Heading3>
        <Text>
          In order to qualify for Highbeam Capital you must meet these financial requirements.
        </Text>
      </div>
      <div className={styles.summaryContainer}>
        <div className={styles.summaryItem}>
          <CurrencyDollarSimple size={24} className={styles.summaryIcon} />
          <div className={styles.summaryText}>
            <Text>Current ratio must be greater than 1.</Text>
            <IconWithTooltip tooltip="Current assets / current liabilities" />
          </div>
        </div>
        <div className={styles.summaryItem}>
          <ChartLineUp size={24} className={styles.summaryIcon} />
          <div className={styles.summaryText}>
            <Text>Year-over-year sales trend must not be declining more than 15%.</Text>
            <IconWithTooltip tooltip="Trailing 12-month sales / last year's sales over the same period" />
          </div>
        </div>
        <div className={styles.summaryItem}>
          <AirplaneTakeoff size={24} className={styles.summaryIcon} />
          <div className={styles.summaryText}>
            <Text>Cash runway must be greater than 6 months.</Text>
            <IconWithTooltip tooltip="Cash on hand / Monthly cash burn" />
          </div>
        </div>
        <div className={styles.summaryItem}>
          <Scales size={24} className={styles.summaryIcon} />
          <div className={styles.summaryText}>
            <Text>Shareholder’s equity greater than 0.</Text>
            <IconWithTooltip tooltip="Assets - liabilities" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationRequirementsSummary;
