import { ComponentProps, FC } from "react";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

type Props = ComponentProps<typeof MoneyAmount>;

const AmountCell: FC<Props> = (props) => {
  return (
    <MoneyAmount
      as="div" // NB(alex): This ensures the element gets positioned to the right. It may not be necessary once we use a new table component.
      weight="medium"
      {...props}
    />
  );
};

export default AmountCell;
