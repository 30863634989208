import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useRefreshAccountingAccountsQueries from "../queries/useRefreshAccountingAccountsQueries";

type Variables = {
  accountingAccountId: string;
};

const useSetBankAccountForChartOfAccountMutation = (
  bankAccountGuid: string,
  additionalOptions?: MutationAdditionalOptions<void, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshAccountingAccountsQueries = useRefreshAccountingAccountsQueries();

  return useMutationWithDefaults(
    {
      mutationFn: async ({ accountingAccountId }) => {
        await highbeamApi.accountingAccount.setAccount(accountingAccountId, {
          bankAccountGuid,
        });
      },
      onSuccess: async () => {
        await refreshAccountingAccountsQueries();
      },
    },
    additionalOptions || {}
  );
};

export default useSetBankAccountForChartOfAccountMutation;
