import { atom, useRecoilState } from "recoil";

const commandPaletteState = atom<{ isOpen: boolean }>({
  key: "superuser/commandPaletteState",
  default: { isOpen: false },
});

const useCommandPaletteState = () => {
  return useRecoilState(commandPaletteState);
};

export default useCommandPaletteState;
