import CreditApplicationRep from "reps/CreditApplicationRep";

/**
 * Type-guards, useful for filtering by state.
 */

type CreditApplicationWithState<TState extends CreditApplicationRep.State> =
  CreditApplicationRep.Complete & {
    state: TState;
  };

export const getIsNewCreditApplication = (
  creditApplication: CreditApplicationRep.Complete
): creditApplication is CreditApplicationWithState<CreditApplicationRep.NewCreditApplicationState> =>
  CreditApplicationRep.newCreditApplicationStates.includes(
    creditApplication.state as CreditApplicationRep.NewCreditApplicationState
  );

export const getIsInReviewCreditApplication = (
  creditApplication: CreditApplicationRep.Complete
): creditApplication is CreditApplicationWithState<CreditApplicationRep.InReviewState> =>
  CreditApplicationRep.inReviewCreditApplicationStates.includes(
    creditApplication.state as CreditApplicationRep.InReviewState
  );
