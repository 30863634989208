import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

import { useCashFlowSyncSheetModal } from "../components/CashFlowSyncSheetModal/state/cashFlowSyncSheetModalState";

const useCashFlowSyncSheetMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const { close: closeCashFlowSyncSheetModal } = useCashFlowSyncSheetModal();

  return useMutation({
    mutationFn: async () => highbeamApi.insightsSheet.sync(businessGuid),
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
    },
    onSuccess: async () => {
      notify("info", "Syncing to Google sheet - takes up to 2 mins");
      closeCashFlowSyncSheetModal();
    },
  });
};

export default useCashFlowSyncSheetMutation;
