import RutterBalanceRep from "reps/RutterBalanceRep";
import RutterConnectionRep from "reps/RutterConnectionRep";
import ShopifyBalanceRep from "reps/ShopifyBalanceRep";
import ShopifyConnectionRep from "reps/ShopifyConnectionRep";

import { getRutterPlatformName } from "./rutter";

export const formatBalances = (
  shopifyConnections: ShopifyConnectionRep.Complete[],
  shopifyBalances: ShopifyBalanceRep.Complete[],
  rutterConnections: RutterConnectionRep.Complete[],
  rutterBalances: RutterBalanceRep.Complete[]
) => {
  const shopifyStores = shopifyConnections.map((connection) => {
    const balance = shopifyBalances.reduce((total, balance) => {
      if (balance.connectionGuid === connection.guid) total += balance.amount;
      return total;
    }, 0);

    return {
      shopName: connection.shopName,
      hasSynced: connection.hasSyncedBalances,
      balance: balance,
      platformName: "SHOPIFY" as const,
    };
  });

  const rutterStores = rutterConnections.map((connection) => {
    const balance = rutterBalances.reduce((total, balance) => {
      if (balance.connectionGuid === connection.guid) total += balance.amount;
      return total;
    }, 0);
    return {
      shopName: connection.shopName || getRutterPlatformName(connection.platformName),
      hasSynced: connection.hasSyncedBalances,
      balance,
      platformName: connection.platformName,
    };
  });

  return [...shopifyStores, ...rutterStores];
};
