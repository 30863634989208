import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BusinessOwnerApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  setBusinessOwner(businessGuid: string, userGuid: string): Promise<void | null> {
    const url = `/businesses/${businessGuid}/owner/${userGuid}`;
    return this.api.put<void>(url);
  }
}
