import {
  Address as UnitAddress,
  CreateCounterpartyRequest,
  State,
  CreateCounterpartyWithoutTokenRequest,
  CreateWirePaymentRequest,
  CreateInlinePaymentRequest,
  CreateSchedule,
} from "@highbeam/unit-node-sdk";
import { CreateRecurringCreditAchPaymentRequest } from "@highbeam/unit-node-sdk/dist/types/recurringPayment";
import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import InternationalWirePaymentRep from "reps/InternationalWirePaymentRep";
import PayeeRep from "reps/PayeeRep";
import { Option } from "ui/inputs/Dropdown";
import { Address } from "utils/address";
import { bankingDay, dateOnly, startOfBankingDay } from "utils/date";
import { Entity, EntityCompany, EntityIndividual } from "utils/entity";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";
import { HighbeamBankAccountTransferOption, TransferOption } from "utils/transfers";

import { INTERNATIONAL_WIRE_INPUTS } from "./internationalWires";

export enum PaymentMethod {
  ACH = "ach",
  SAME_DAY_ACH = "same_day_ach",
  TRANSFER = "transfer",
  INTERNATIONAL = "international",
  WIRE = "wire",
  CHECK = "check",
}

export enum TransferInfo {
  ACCOUNT_NUMBER = "Account number",
  BANK_CODE = "Bank code",
  BRANCH_CODE = "Branch code",
  BSB_CODE = "Bank state branch code",
  CLABE = "CLABE",
  CNAPS = "CNAPS",
  ACCOUNT_TYPE = "Account type",
  ADDRESS = "Address",
  IBAN = "International banking account number (IBAN)",
  IFSC = "Indian financial system code (IFSC)",
  ROUTING_NUMBER = "Routing number",
  SORT_CODE = "Sort code",
  SWIFT_CODE = "SWIFT code",
}

export const MIN_WIRE_TRANSFER_AMOUNT_IN_CENTS = 100_00;

export type PaymentMethodOption = {
  description: string;
  minAddendaLength: number;
  maxAddendaLength: number;
  minTransferAmountInCents: number;
  info: string;
  feeInCents: number;
  deliveryMessage: string;
  value: PaymentMethod;
  paymentLimitInCents: number;
} & Option;

export const ACH_PAYMENT_OPTION: PaymentMethodOption = {
  value: PaymentMethod.ACH,
  label: "ACH",
  description: "Free • 3 business days",
  minAddendaLength: 0,
  maxAddendaLength: 80,
  minTransferAmountInCents: 0,
  info: "ACH transfers are free and take up to 2 business days.",
  feeInCents: 0,
  deliveryMessage: "ACH transfers can take up to 2 business days",
  // We have a limit of 500k USD ACHs set on our Checking VIP tier
  // We should update these as we add more tiers
  paymentLimitInCents: 50000000,
};

export const SAME_DAY_ACH_PAYMENT_OPTION: PaymentMethodOption = {
  value: PaymentMethod.SAME_DAY_ACH,
  label: "ACH",
  description: "Free • 1 business day or less",
  minAddendaLength: 0,
  maxAddendaLength: 80,
  minTransferAmountInCents: 0,
  info: "Same-day ACH transfers are free and take up to 1 business day.",
  feeInCents: 0,
  deliveryMessage: "Same-day ACH transfers land the same or following business day",
  // We have a limit of 500k USD ACHs set on our Checking VIP tier
  // We should update these as we add more tiers
  paymentLimitInCents: 50000000,
};

export const WIRE_PAYMENT_OPTION: PaymentMethodOption = {
  value: PaymentMethod.WIRE,
  label: "Wire",
  description: "Free • 1 business day",
  maxAddendaLength: 50,
  minAddendaLength: 1,
  minTransferAmountInCents: MIN_WIRE_TRANSFER_AMOUNT_IN_CENTS,
  info: "Wire transfers are free and take up to 1 business day.",
  feeInCents: 0,
  deliveryMessage: "Wire transfers land the same or following business day",
  // Unit limits us to 20M USD Wires
  paymentLimitInCents: 2000000000,
};

export const DISABLED_WIRE_RECURRING_PAYMENT_OPTION: PaymentMethodOption = {
  ...WIRE_PAYMENT_OPTION,
  description: "Unsupported for recurring payments",
  isDisabled: true,
};

export const DISABLED_WIRE_SCHEDULED_PAYMENT_OPTION: PaymentMethodOption = {
  ...WIRE_PAYMENT_OPTION,
  description: "Unsupported for future payments",
  isDisabled: true,
};

export const SWIFT_FEE = 2000;

export const INTERNATIONAL_PAYMENT_OPTION: PaymentMethodOption = {
  value: PaymentMethod.INTERNATIONAL,
  label: "International wire",
  description: "Fee • 3 - 5 business days",
  // Currency cloud only sends 134 characters in the MT103 message even though the API accepts a
  // long string. Anything past 134 will be truncated.
  maxAddendaLength: 134,
  minAddendaLength: 1,
  minTransferAmountInCents: 100,
  info: "International wire USD transfers incur a $20 SWIFT fee and can take up to 3 - 5 business days.",
  feeInCents: SWIFT_FEE,
  deliveryMessage: "International transfers can take up to 3 - 5 business days",
  // Currencycloud limits us to 1M USD incoming funding payments
  paymentLimitInCents: 100000000,
};

export const DISABLED_INTERNATIONAL_OPTION: PaymentMethodOption = {
  ...INTERNATIONAL_PAYMENT_OPTION,
  description: "Please contact support to enable international wires.",
  isDisabled: true,
};

export const DISABLED_INTERNATIONAL_RECURRING_PAYMENT_OPTION: PaymentMethodOption = {
  ...INTERNATIONAL_PAYMENT_OPTION,
  description: "Unsupported for recurring payments",
  isDisabled: true,
};

export const DISABLED_INTERNATIONAL_SCHEDULED_PAYMENT_OPTION: PaymentMethodOption = {
  ...INTERNATIONAL_PAYMENT_OPTION,
  description: "Unsupported for future payments",
  isDisabled: true,
};

export type BankingInfo = {
  routingNumber: string;
  accountNumber: string;
  accountType: Option | null;
};

export type InternationalField = {
  inputName: INTERNATIONAL_WIRE_INPUTS;
  isValid: boolean;
  value?: string;
};

export type InternationalBankingInfo = {
  accountNumber?: InternationalField;
  bankCode?: InternationalField;
  branchCode?: InternationalField;
  bsbCode?: InternationalField;
  clabe?: InternationalField;
  cnaps?: InternationalField;
  iban?: InternationalField;
  ifsc?: InternationalField;
  sortCode?: InternationalField;
  swift?: InternationalField;
};

export type PayeeOption = {
  label: string;
  lastTransferred?: Date;
  lastTransferedBankAcountGuid?: string;
  lastTransferedDescription?: string;
  address?: PayeeRep.Address;
  emailAddress?: string;
  sendEmailNotification?: boolean;
  achTransferMethod?: PayeeRep.AchTransferMethod;
  internationalWireTransferMethod?: PayeeRep.InternationalWireTransferMethod;
  wireTransferMethod?: PayeeRep.WireTransferMethod;
} & Option;

export const payeeToPayeeOption = (payee: PayeeRep.Complete): PayeeOption => ({
  label: payee.name,
  value: payee.guid,
  description: payee.lastTransferAmount
    ? `Last transferred: ${lastTransferDescription(payee)}`
    : "",
  lastTransferred: payee.lastTransferAt ? new Date(payee.lastTransferAt) : undefined,
  lastTransferedBankAcountGuid: payee.lastTransferBankAccountGuid
    ? payee.lastTransferBankAccountGuid
    : undefined,
  lastTransferedDescription: payee.lastTransferDescription
    ? payee.lastTransferDescription
    : undefined,
  emailAddress: payee.emailAddress ?? undefined,
  sendEmailNotification: payee.sendEmailNotification,
  address: payee.address ?? undefined,
  achTransferMethod: payee.achTransferMethod ?? undefined,
  internationalWireTransferMethod: payee.internationalWireTransferMethod ?? undefined,
  wireTransferMethod: payee.wireTransferMethod ?? undefined,
});

export function lastTransferDescription({
  lastTransferAt,
  lastTransferAmount,
}: {
  lastTransferAt: string | null;
  lastTransferAmount: number | null;
}): string | null {
  const result: string[] = [];
  if (lastTransferAmount) {
    result.push(formatAmount(getDollarsFromCents(lastTransferAmount)));
  }
  if (lastTransferAt) {
    result.push(dateOnly(startOfBankingDay(lastTransferAt)).format("MMM D, YYYY"));
  }
  if (result.length === 0) return null;
  return result.join(" on ");
}

const unitCounterpartyName = (name: string) => name.substring(0, 50);

export const achTransferDescription = (description: string) => {
  return description.substring(0, 10) !== "" ? description.substring(0, 10) : "Transfer";
};

export const achTransferAddenda = (addenda: string) => {
  return addenda.substring(0, 80) !== "" ? addenda.substring(0, 80) : undefined;
};

export const defaultTransferAddenda = (payeeName: string) => {
  return `Payment to ${payeeName} via Highbeam`;
};

export const wireTransferDescription = (description: string) => {
  return description.substring(0, 50) !== "" ? description.substring(0, 50) : "Transfer";
};

export const getAchTransferDescription = (
  transferFrom: TransferOption,
  threadDescription: string,
  blueRidgeDescription: string
) => {
  if (transferFrom.isThreadAccount) {
    return threadDescription;
  } else {
    return blueRidgeDescription;
  }
};

export const createAchPaymentRequest = (
  name: string,
  amountInCents: number,
  transferFrom: TransferOption,
  description: string,
  addenda: string,
  bankingInfo: BankingInfo,
  idempotencyKey: string,
  payeeGuid: string,
  isPayeeEmailToggled?: boolean,
  payeeEmail?: string,
  genPaymentMetadatGuid?: string
): CreateInlinePaymentRequest => ({
  type: "achPayment",
  attributes: {
    amount: amountInCents,
    direction: "Credit",
    counterparty: {
      routingNumber: bankingInfo.routingNumber,
      accountNumber: bankingInfo.accountNumber,
      accountType: bankingInfo.accountType!.value,
      name: unitCounterpartyName(name),
    },
    description: achTransferDescription(description),
    addenda: achTransferAddenda(addenda),
    idempotencyKey,
    tags: {
      recipientGuid: payeeGuid,
      ...(isPayeeEmailToggled ? { payeeEmail: payeeEmail } : {}),
      ...(genPaymentMetadatGuid ? { generalPaymentMetadataGuid: genPaymentMetadatGuid } : {}),
    },
  },
  relationships: {
    account: {
      data: {
        type: transferFrom!.type,
        id: transferFrom!.value,
      },
    },
  },
});

export const createWirePaymentRequest = (
  name: string,
  amountInCents: number,
  transferFrom: TransferOption,
  description: string,
  bankingInfo: BankingInfo,
  address: Address,
  idempotencyKey: string,
  payeeGuid: string,
  isPayeeEmailToggled?: boolean,
  payeeEmail?: string,
  genPaymentMetadataGuid?: string
): CreateWirePaymentRequest => {
  const unitAddress: UnitAddress = {
    street: address.addressLine1?.value!,
    street2: address.addressLine2,
    city: address.city,
    state: address.state!.value as State,
    postalCode: address.zipCode,
    country: address.country?.value!,
  };
  return {
    type: "wirePayment",
    attributes: {
      amount: amountInCents,
      counterparty: {
        routingNumber: bankingInfo.routingNumber,
        accountNumber: bankingInfo.accountNumber,
        name: unitCounterpartyName(name),
        address: unitAddress,
      },
      description: wireTransferDescription(description),
      idempotencyKey,
      tags: {
        recipientGuid: payeeGuid,
        ...(isPayeeEmailToggled ? { payeeEmail: payeeEmail } : {}),
        ...(genPaymentMetadataGuid ? { generalPaymentMetadataGuid: genPaymentMetadataGuid } : {}),
      },
    },
    relationships: {
      account: {
        data: {
          type: transferFrom!.type,
          id: transferFrom!.value,
        },
      },
    },
  };
};

export const createInternationalPaymentRequest = ({
  amountInCents,
  businessGuid,
  payeeGuid,
  transferFrom,
  idempotencyKey,
  description,
  addenda,
  isPayeeEmailToggled,
  paymentType,
  receivedCurrency,
  receivedAmount,
  buyRate,
  purposeCode,
  invoiceNumber,
  invoiceDate,
  genPaymentMetadataGuid,
  payeeEmail,
}: {
  amountInCents: number;
  businessGuid: string;
  payeeGuid: string;
  transferFrom: HighbeamBankAccountTransferOption;
  idempotencyKey: string;
  description: string;
  addenda: string;
  isPayeeEmailToggled: boolean;
  paymentType: PayeeRep.InternationalWirePaymentType;
  receivedCurrency: string;
  receivedAmount: number;
  buyRate: number | null;
  purposeCode: string | null;
  invoiceNumber: string | null;
  invoiceDate: string | null;
  genPaymentMetadataGuid?: string;
  payeeEmail?: string;
}): InternationalWirePaymentRep.Creation => ({
  amount: amountInCents,
  businessGuid: businessGuid,
  bankAccountGuid: transferFrom.guid,
  payeeGuid: payeeGuid,
  idempotencyKey: idempotencyKey,
  description: description,
  reason: addenda,
  // We only support SWIFT payments for now so the payment type will always be priority
  paymentType: paymentType,
  payeeEmailToggle: isPayeeEmailToggled,
  payeeEmail: payeeEmail,
  ...(genPaymentMetadataGuid ? { generalPaymentMetadataGuid: genPaymentMetadataGuid } : {}),
  receivedCurrency: receivedCurrency,
  receivedAmount: receivedAmount,
  buyRate: buyRate,
  purposeCode: purposeCode,
  invoiceNumber: invoiceNumber,
  invoiceDate: invoiceDate,
});

// Extracts the day of the month of the scheduled date and formats it to Unit's
// expected format. Docs: https://docs.unit.co/types#create-schedule
export const unitDayOfMonth = (scheduledDate: Dayjs) => {
  const daysInMonth = scheduledDate.daysInMonth();
  const isNonLeapYearFebruary = daysInMonth === 28;
  const day = scheduledDate.date();
  // Handles a special case for the last day of non leap year February which should be -1 not 28, where -1 is the last day of the month.
  if (day === 28 && isNonLeapYearFebruary) return -1;
  if (day <= 28) return day;
  // Unit allows for "dayOfMonth" to be -5 <= x <= 28. Negative values are counted
  // from the end of the month, where -1 is the last day of the month.
  return day - daysInMonth - 1;
};

type RecurringAchPaymentRequestParams = {
  amountInCents: number;
  transferFrom: TransferOption;
  counterpartyId: string;
  scheduledDate: Dayjs;
  description: string;
  payeeGuid: string;
  numberOfPayments?: number;
  isPayeeEmailToggled?: boolean;
  payeeEmail?: string;
  idempotencyKey?: string;
};

export const createRecurringAchPaymentRequest = ({
  amountInCents,
  transferFrom,
  counterpartyId,
  scheduledDate,
  description,
  payeeGuid,
  numberOfPayments,
  isPayeeEmailToggled,
  payeeEmail,
  idempotencyKey,
}: RecurringAchPaymentRequestParams): CreateRecurringCreditAchPaymentRequest => {
  // We want the "recurring payment" to start on the selected date, then stop the following day
  // in order to emulate a scheduled transaction.
  // eg) If today is April 1st, 2022, and we want the transaction to be scheduled for June 1st
  //  then we'd create a recurring transaction with the following:
  //   startTime="6/1/22",
  //   endTime="6/2/22",
  //   dayOfMonth=1
  const startTime = bankingDay(scheduledDate).toISOString().split("T")[0];

  const schedule: CreateSchedule = {
    // This doesn't matter since the "recurring transaction" ends on the following week
    interval: "Monthly",
    dayOfMonth: unitDayOfMonth(scheduledDate),
    startTime,
  };

  // numberOfPayments === undefined is an infinite recurring payment
  if (numberOfPayments) {
    schedule.totalNumberOfPayments = numberOfPayments;
  }

  return {
    type: "recurringCreditAchPayment",
    attributes: {
      amount: amountInCents,
      schedule: schedule,
      description: achTransferDescription(description),
      addenda: achTransferAddenda(description),
      idempotencyKey: idempotencyKey,
      tags: {
        recipientGuid: payeeGuid,
        ...(isPayeeEmailToggled ? { payeeEmail: payeeEmail } : {}),
      },
    },
    relationships: {
      account: {
        data: {
          type: transferFrom!.type,
          id: transferFrom!.value,
        },
      },
      counterparty: {
        data: {
          type: "counterparty",
          id: counterpartyId,
        },
      },
    },
  };
};

/*
  In unit.co when we send same idempotency key, it is not a failure.
  Instead, it returns the counterparty data that was generated on the last call.
  So, In recurring payments we can hit createCounterparty with a deterministic key and
  if counterparty with that key already exists it will just return that.
  Works like a createIfMissing method.
 */
const idempotencyKeyForCreateCounterparty = (customerId: string, bankingInfo: BankingInfo) =>
  [
    customerId,
    bankingInfo.accountNumber,
    bankingInfo.routingNumber,
    bankingInfo.accountType!.value,
  ].join("-");

export const createCounterpartyRequest = (
  name: string,
  customerId: string,
  bankingInfo: BankingInfo,
  payeeGuid: string
): CreateCounterpartyRequest => ({
  type: "achCounterparty",
  attributes: {
    routingNumber: bankingInfo.routingNumber,
    accountNumber: bankingInfo.accountNumber,
    accountType: bankingInfo.accountType!
      .value as CreateCounterpartyWithoutTokenRequest["attributes"]["accountType"], // NB(alex): Had to type-cast this after upgrading the unit sdk version, and our dropdown `Option` type does not support generics.
    type: "Unknown",
    name,
    idempotencyKey: idempotencyKeyForCreateCounterparty(customerId, bankingInfo),
    tags: { recipientGuid: payeeGuid },
  },
  relationships: {
    customer: {
      data: {
        type: "customer",
        id: customerId,
      },
    },
  },
});

export const getErrorMessage = (error?: string) => {
  switch (error) {
    case "InsufficientFunds":
      return "Failed to schedule this transaction because of insufficient funds in your bank account.";
    case "DailyACHCreditLimitExceeded":
      return "Failed to schedule this transaction because of the daily ACH transfer limit has been exceeded.";
    case "MonthlyACHCreditLimitExceeded":
      return "Failed to schedule this transaction because of the monthly ACH transfer limit has been exceeded.";
    case "Not Found":
      return "The specified payee bank account was not found.";
    default:
      return "Failed to schedule this transaction.";
  }
};

export const getSendMoneyDeliveryMessage = (
  paymentMethod: PaymentMethod,
  scheduledPayment: boolean
) => {
  switch (paymentMethod) {
    case PaymentMethod.ACH:
      return (
        ACH_PAYMENT_OPTION.deliveryMessage + (scheduledPayment ? " from the scheduled date" : "")
      );
    case PaymentMethod.SAME_DAY_ACH:
      return SAME_DAY_ACH_PAYMENT_OPTION.deliveryMessage;
    case PaymentMethod.INTERNATIONAL:
      return INTERNATIONAL_PAYMENT_OPTION.deliveryMessage;
    case PaymentMethod.WIRE:
      return WIRE_PAYMENT_OPTION.deliveryMessage;
    default:
      return "Delivery time is unknown for payment method";
  }
};

export const scheduledAfter3pmOrWeekend = (scheduledDate: Dayjs) => {
  switch (scheduledDate.day()) {
    // TODO(justin): Add US federal holiday check
    // Saturday or Sunday
    case 6:
    case 0:
      return true;
    default:
      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.extend(isSameOrAfter);
      const easternTime3pm = scheduledDate.set("hour", 15);
      return scheduledDate.isSameOrAfter(easternTime3pm, "hour");
  }
};

export function createBankHolderNameFromEntity(entity: Entity) {
  if (entity.entityType === EntityIndividual) {
    if (entity.firstName && entity.lastName)
      return entity.firstName.concat(" ", entity.lastName.trim());
    return entity.firstName ?? entity.lastName ?? "";
  } else {
    return entity.companyBankHolderName ?? entity.companyName ?? "";
  }
}

export function extractFirstLastNamesFromPayee(payeeName: string) {
  const names = payeeName.trimStart().split(/\s+/);
  if (names.length <= 1) return [payeeName, ""];
  const lastName = names[names.length - 1];
  const firstName = names.slice(0, names.length - 1).join(" ");

  return [firstName, lastName ? lastName : " "];
}

export function initialEntityFromPayee(payeeName: string) {
  const [firstName, lastName] = extractFirstLastNamesFromPayee(payeeName);
  return {
    entityType: EntityCompany,
    firstName: firstName,
    lastName: lastName,
    companyName: payeeName,
    companyBankHolderName: payeeName,
  };
}
