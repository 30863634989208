import { ArrowRight } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./RightArrowButton.module.scss";

type Props = JSX.IntrinsicElements["button"] & {
  color?: string;
};

const RightArrowButton: FC<Props> = ({
  children,
  className,
  color = colors.purple[500],
  ...buttonProps
}) => {
  return (
    <button {...buttonProps} className={classNames(className, styles.button)}>
      <Text weight="medium" color={color}>
        {children}
      </Text>
      <ArrowRight size={24} weight="light" color={color} />
    </button>
  );
};

export default RightArrowButton;
