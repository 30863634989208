import React, { useState } from "react";
import BankAccountRep from "reps/BankAccountRep";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import colors from "styles/colors";
import SegmentControls from "ui/navigation/SegmentControls";
import Text from "ui/typography/Text";
import useTabState, { TabConfig } from "utils/tabs/useTabState";

import SwitchHelper from "../SwitchHelper";

import AccountDropdown from "./AccountDropdown";
import AccountPreview from "./AccountPreview";
import HorizontalLine from "./HorizontalLine";
import HowToSwitchHeader from "./HowToSwitchHeader";
import RecurringPaymentSteps from "./RecurringPaymentSteps";

const tabs = {
  "auto-debits": { label: "Auto-debits" },
  "recurring-payments": { label: "Recurring payments" },
} satisfies TabConfig;

const HowToSwitch: React.FC = () => {
  const [tab, setTab] = useTabState(tabs);

  return (
    <SwitchHelper>
      <HowToSwitchHeader />
      <SegmentControls size="sm" activeTab={tab} setActiveTab={setTab} tabs={tabs} />
      <InstructionsForTab tab={tab} />
    </SwitchHelper>
  );
};

export default HowToSwitch;

const InstructionsForTab: React.FC<{ tab: keyof typeof tabs }> = ({ tab }) => {
  const accounts = bankAccountsQueryHooks.useData({
    status: "open",
    select: (accounts) => accounts.filter((account) => account.highbeamType.showDetailsToUser),
  });
  const [account, setAccount] = useState<BankAccountRep.Complete | undefined>(
    accounts.length === 1 ? accounts[0] : undefined
  );

  switch (tab) {
    case "auto-debits":
      return (
        <>
          <Text as="p" size={14} weight="regular" color={colors.grey[600]}>
            Update the payment information in the vendor’s site with your new Highbeam account
            details.
          </Text>
          <AccountDropdown accounts={accounts} account={account} setAccount={setAccount} />
          {account && <AccountPreview account={account} />}
        </>
      );
    case "recurring-payments":
      return (
        <>
          <Text as="p" size={14} weight="regular" color={colors.grey[600]}>
            For recurring payments from your original bank account, follow these steps:
          </Text>
          <HorizontalLine />
          <RecurringPaymentSteps />
        </>
      );
  }
};
