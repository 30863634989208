import HighbeamBaseApi from "./HighbeamBaseApi";

export default class AuthorizedUserApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(businessGuid: string, userGuid: string): Promise<void> {
    const url = `/businesses/${businessGuid}/authorized-users/${userGuid}`;
    await this.api.put<void>(url);
  }
}
