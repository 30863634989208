import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { MIGRATE_TO_THREAD_MILESTONE_NAME } from "../migrateToThreadActionItems/constants";

const useMigrateToThreadActionItemsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["accounts/migrateToThreadActionItems"],
    queryFn: () => {
      return highbeamApi.actionItem.getByMilestoneName(
        businessGuid,
        MIGRATE_TO_THREAD_MILESTONE_NAME
      );
    },
  });
};

export default useMigrateToThreadActionItemsQueryOptions;
