import { Dayjs } from "dayjs";
import RutterPayoutRep from "reps/RutterPayoutRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class RutterPayoutApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getRutterTotalPayoutsInDateRange(
    businessGuid: string,
    fromDate: Dayjs,
    toDate?: Dayjs
  ): Promise<RutterPayoutRep[]> {
    const queryParams = new URLSearchParams({
      fromDate: fromDate.format("YYYY-MM-DD"),
      ...(toDate ? { toDate: toDate.format("YYYY-MM-DD") } : {}),
    });
    const url = `/businesses/${businessGuid}/rutter-total-payout?${queryParams.toString()}`;
    return (await this.api.get<RutterPayoutRep[]>(url))!;
  }
}
