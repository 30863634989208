import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import snapshotState from "state/compareCreditOffers/snapshot";

const useIsMatchingSessionAndSnapshot = () => {
  const { pathname } = useLocation();

  // NB(alex): Hack to get the snapshot slug from the pathname which is not available via useParams because the routes are nested below.
  const isResultsPath = pathname.includes("/results");
  const snapshotSlug = isResultsPath ? pathname.split("/").pop() : undefined;

  const snapshot = useRecoilValue(snapshotState);
  const isMatchingSessionAndSnapshot = Boolean(snapshot && snapshot.slug === snapshotSlug);

  return isMatchingSessionAndSnapshot;
};

export default useIsMatchingSessionAndSnapshot;
