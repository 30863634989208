import { FC } from "react";
import ModalV4 from "ui/overlay/ModalV4";

import EditUserRoleForm, { EditUserRoleParams } from "./EditUserRoleForm";
import { EditUserRoleFormOutputs } from "./useEditUserRoleForm";

type Props = EditUserRoleParams & {
  onSuccess: (data: EditUserRoleFormOutputs) => void;
  onClose: () => void;
};

const EditUserRoleModal: FC<Props> = ({ onClose, onSuccess, ...params }) => {
  return (
    <ModalV4 onClose={onClose}>
      <EditUserRoleForm {...params} onSuccess={onSuccess} />
    </ModalV4>
  );
};

export default EditUserRoleModal;
