import ShopifyBalanceRep from "reps/ShopifyBalanceRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ShopifyBalanceApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getCurrentBalances(businessGuid: string): Promise<ShopifyBalanceRep.Complete[]> {
    const url = `/businesses/${businessGuid}/shopify-balances`;
    return (await this.api.get<ShopifyBalanceRep.Complete[]>(url))!;
  }
}
