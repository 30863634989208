import { FC, ReactElement } from "react";
import cn from "utils/tailwind/cn";

import MoneyInputsAmountInput from "./MoneyInputsAmountInput";
import MoneyInputsCurrencyDropdown from "./MoneyInputsCurrencyDropdown";

/**
 * 🚧 Under construction: Please hold off on using this until we replace `supportedInternationalWireCurrencies` with `props.currencyOptions`.
 */

type Props = {
  className?: string;
  amountInput: ReactElement;
  currencyDropdown: ReactElement;
};

const MoneyInputs: FC<Props> = ({ className, amountInput, currencyDropdown }) => {
  return (
    <div className={cn("flex flex-row", className)}>
      {amountInput}
      {currencyDropdown}
    </div>
  );
};

export default Object.assign(MoneyInputs, {
  AmountInput: MoneyInputsAmountInput,
  CurrencyDropdown: MoneyInputsCurrencyDropdown,
});
