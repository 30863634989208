import { PaperPlaneRight } from "@phosphor-icons/react";
import { useRecoilValue } from "recoil";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";

import ClickToCopyInviteLink from "../ClickToCopyInviteLink";
import { useInviteUserModal } from "../InviteUserModal/state/inviteUserModalState";

import styles from "./InvitationSuccessfulModal.module.scss";
import invitationSuccessfulModalState, {
  useCloseInvitationSuccessfulModal,
} from "./state/invitationSuccessfulModalState";

const InvitationSuccessfulModal = () => {
  const state = useRecoilValue(invitationSuccessfulModalState);
  const closeInvitationSuccessfulModal = useCloseInvitationSuccessfulModal();
  const { open: openInviteUserModal } = useInviteUserModal();

  if (!state.isOpen) {
    return null;
  }

  return (
    <ModalV4 onClose={closeInvitationSuccessfulModal}>
      <ModalV4.Form onSubmit={(_, { closeModalWithAnimation }) => closeModalWithAnimation()}>
        <ModalV4.Header>Invite a user</ModalV4.Header>

        <ModalV4.Body>
          <PaperPlaneRight color={colors.green[500]} size={32} weight="light" />

          <div className={styles.container}>
            <div className={styles.textContainer}>
              <Text size={16} weight="bold">
                Invite sent!
              </Text>

              <Text size={14} color={colors.grey[800]}>
                We emailed {state.displayName} a sign up link to join your team.
              </Text>
            </div>
            <div className={styles.textContainer}>
              <Text size={14} color={colors.grey[800]}>
                If they need a nudge, share this link directly:
              </Text>
              <ClickToCopyInviteLink slug={state.slug} />
            </div>
          </div>
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.SubmitButton>Done</ModalV4.SubmitButton>

          <Button
            onClick={() => {
              closeInvitationSuccessfulModal();
              openInviteUserModal();
            }}
            variant="ghost"
          >
            Invite another user
          </Button>
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default InvitationSuccessfulModal;
