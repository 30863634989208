import { RefObject, useEffect } from "react";

/**
 * This hook uses the observer pattern to listen for changes to the browser "client width".
 * It's more performant than using listeners.
 * See https://developer.mozilla.org/en-US/docs/Web/API/Resize_Observer_API.
 *
 * USE THIS SPARINGLY. Most of the time we should prefer CSS.
 */
export const useResizeObserver = <T extends Element>(
  targetRef: RefObject<T>,
  callback: (element: T) => void
) => {
  useEffect(() => {
    const observer = new ResizeObserver((elements) => {
      callback(elements[0].target as T);
    });
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef.current, callback]);
};
