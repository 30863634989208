import { useController } from "react-hook-form";
import Dropdown from "ui/inputs/Dropdown";

import { useUpdateCounterpartyAliasModalContext } from "../../context";
import useCounterpartySuggestions from "../../data/useCounterpartySuggestions";
import { UpdateCounterpartyAliasFormInputs } from "../../forms/useUpdateCounterpartyAliasForm";

const UpdateCounterpartyAliasDropdown = () => {
  const counterpartySuggestions = useCounterpartySuggestions();
  const { counterparty, direction, form } = useUpdateCounterpartyAliasModalContext();

  const defaultValue =
    counterpartySuggestions.find((suggestion) => suggestion === counterparty) ?? counterparty;

  const {
    field: { ref: _ref, ...field },
  } = useController<UpdateCounterpartyAliasFormInputs>({
    name: "counterpartyName",
    control: form.control,
    defaultValue: defaultValue,
  });

  // NB(alex): I'm doing this convoluted stuff because I'm trying to get our `useForm` pattern figured out, and I don't want to compromise its quality for our `<Dropdown>` component.
  // While working on this, I realized we need an `<AutocompleteInput>` component...
  // TODO(alex): Create an `<AutocompleteInput>` component.

  const counterpartySuggestionsAsDropdownOptions = counterpartySuggestions.map((suggestion) => ({
    label: suggestion,
    value: suggestion,
  }));

  const value = counterpartySuggestionsAsDropdownOptions.find(
    (option) => option.value === field.value
  ) ?? { label: field.value, value: field.value };

  return (
    <Dropdown
      label={`${direction === "money-in" ? "Source" : "Vendor"} name`}
      options={counterpartySuggestionsAsDropdownOptions}
      isClearable
      value={value}
      onChange={(val) => field.onChange(val.value)}
      onClear={() => field.onChange("")}
      onCreate={(inputValue) => {
        field.onChange(inputValue);
      }}
      isValidNewOption={(inputValue) => {
        // NB(alex): This fixes a weird bug where the "Create new option" button doesn't show up when there are too many matches. e.g. I would enter "Highbeam", and the option would go away. We always want to show it.
        return !counterpartySuggestions.some((suggestion) => suggestion === inputValue);
      }}
    />
  );
};

export default UpdateCounterpartyAliasDropdown;
