import { captureException } from "@sentry/react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import allStepsState from "state/compareCreditOffers/allSteps";
import feeState from "state/compareCreditOffers/inputs/offerInputs/fee";
import feeInputTypeState from "state/compareCreditOffers/inputs/offerInputs/feeInputType";
import loanAmountState from "state/compareCreditOffers/inputs/offerInputs/loanAmount";
import remittanceRateState from "state/compareCreditOffers/inputs/offerInputs/remittanceRate";
import isOfferFormValidState from "state/compareCreditOffers/isOfferFormValid";
import CurrencyInput from "ui/inputs/CurrencyInput";
import TextInput from "ui/inputs/TextInput";
import { Heading2 } from "ui/typography";
import { isNDecimalPlace } from "utils/numbers";
import Validator from "utils/validator";

import FromControls from "../../components/FromControls";
import getCreditProviderLogo from "../../utils/getCreditProviderLogo";
import getPathFromStepIndex from "../../utils/getPathFromStepIndex";
import getStepIndexFromPathname from "../../utils/getStepIndexFromPathname";
import styles from "../Form.module.scss";

const SHOPIFY = "Shopify" as const;

type Props = {
  offerIndex: number;
};

const ShopifyOfferDetailsForm: React.FC<Props> = ({ offerIndex }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [loanAmount, setLoanAmount] = useRecoilState(loanAmountState(offerIndex));
  const [remittanceRate, setRemittanceRate] = useRecoilState(remittanceRateState(offerIndex));

  const setFeeInputType = useSetRecoilState(feeInputTypeState(offerIndex));
  const [fee, setFee] = useRecoilState(feeState(offerIndex));

  // Calculates the default total replayment amount value if applicable
  const defaultTotalRepaymentAmount = loanAmount && fee ? Number(loanAmount) + Number(fee) : "";
  const [totalRepaymentAmount, setTotalRepaymentAmount] = useState<string>(
    defaultTotalRepaymentAmount.toString()
  );
  const [totalRepaymentAmountError, setTotalRepaymentAmountError] = useState<string | null>(null);

  const isOfferFormValid =
    useRecoilValue(isOfferFormValidState(offerIndex)) &&
    !totalRepaymentAmountError &&
    totalRepaymentAmount !== "";

  const navigate = useNavigate();
  const allSteps = useRecoilValue(allStepsState);
  const { pathname } = useLocation();

  useEffect(() => {
    // NB(alex): Total repayment amount is shopify-specific. We want to pass the fee amount as a dollar value under the hood, so we set those values here under-the-hood.
    const feeAmount = Number(totalRepaymentAmount) - Number(loanAmount);
    if (feeAmount <= 0 && totalRepaymentAmount !== "") {
      setTotalRepaymentAmountError("Must be greater than the loan amount.");
    } else {
      setTotalRepaymentAmountError(null);
    }
    setFee(feeAmount.toString());
    setFeeInputType("DollarValue");
  }, [totalRepaymentAmount, loanAmount, setFee, setFeeInputType]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    // NB(alex): navigation logic is duplicated from `DefaultCreditOfferDetailsForm` -- we should generalize this if we create another custom form.
    setIsLoading(true);
    try {
      const currentStepIndex = getStepIndexFromPathname(allSteps, pathname);
      const to = getPathFromStepIndex(allSteps, currentStepIndex + 1);
      navigate(to);
    } catch (error) {
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled = !isOfferFormValid;

  return (
    <form className={classNames(styles.form, styles.offers)} onSubmit={handleSubmit}>
      <img src={getCreditProviderLogo(SHOPIFY)} alt="counterparty icon" className={styles.icon} />

      <Heading2 className={styles.heading}>Enter {SHOPIFY} offer details</Heading2>

      <CurrencyInput
        allowDecimals={false}
        prefixValue="$"
        label="Loan amount"
        className={styles.textInput}
        value={loanAmount}
        onChange={(val) => {
          setLoanAmount(val);
        }}
        hasError={loanAmount !== "" && Number(loanAmount) === 0}
        errorMessage={"Must be greater than 0"}
        autoFocus
      />

      <TextInput
        label="% of daily sales"
        type="number"
        className={styles.textInput}
        value={remittanceRate}
        onChange={(val) => {
          if (!Validator.positive(val) || !isNDecimalPlace(val, 2)) return;
          setRemittanceRate(val);
        }}
      />

      <div className={styles.inputWrapper}>
        <CurrencyInput
          label="Total repayment amount"
          className={styles.textInput}
          allowDecimals={false}
          prefixValue="$"
          value={totalRepaymentAmount}
          onChange={(val) => {
            if (!Validator.positive(val)) return;
            setTotalRepaymentAmount(val);
          }}
          errorMessage={totalRepaymentAmountError}
          hasError={Boolean(totalRepaymentAmountError)}
        />
      </div>

      <FromControls isLoading={isLoading} isSubmitDisabled={isSubmitDisabled} />
    </form>
  );
};

export default ShopifyOfferDetailsForm;
