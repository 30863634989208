import { Check } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, useState } from "react";
import { SetRequired } from "type-fest";
import Pill from "ui/data-display/Pill";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import TextArea, { TextAreaProps } from "ui/inputs/TextArea";

const pillClasses = "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10";

type Props = SetRequired<TextAreaProps, "value" | "onChange"> & {
  isSaving: boolean;
  isLoading: boolean;
  saveNotes: (value: string) => Promise<void>;
};

const TransactionFlexpaneNotes: FC<Props> = ({
  isSaving,
  isLoading,
  onBlur: onBlurProp,
  saveNotes,
  ...textAreaProps
}) => {
  const [showSaved, setShowSaved] = useState(false);

  const onBlur = async () => {
    await saveNotes(textAreaProps.value);

    // Optional onBlur prop, just in case we want to specific some kind of additional onBlur behavior
    onBlurProp && (await onBlurProp());

    // Triggers the success pill
    setShowSaved(true);
    setTimeout(() => setShowSaved(false), 1500);
  };

  return (
    <div className="relative">
      {isSaving && (
        <Pill
          color="purple-dark"
          className={pillClasses}
          iconLeft={({ sizeClassName }) => <AnimatedSpinner className={sizeClassName} />}
        >
          Saving note
        </Pill>
      )}
      {isLoading && (
        <Pill
          color="purple-dark"
          className={pillClasses}
          iconLeft={({ sizeClassName }) => <AnimatedSpinner className={sizeClassName} />}
        >
          Loading note
        </Pill>
      )}
      {showSaved && (
        <Pill
          color="green-dark"
          className={pillClasses}
          iconLeft={({ sizeClassName }) => <Check className={sizeClassName} />}
        >
          Note saved
        </Pill>
      )}
      <TextArea
        label="Note"
        placeholder="Add a note about this transaction"
        maxLength={10000}
        className={classNames((isLoading || isSaving || showSaved) && "bg-grey-50 opacity-40")}
        onBlur={onBlur}
        {...textAreaProps}
      />
    </div>
  );
};

export default TransactionFlexpaneNotes;
