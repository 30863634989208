import classNames from "classnames";
import React, { ReactNode } from "react";

import styles from "./Emoji.module.scss";

type Props = {
  animation?: "wave" | "none";
  className?: string;
  children: ReactNode;
};

const Emoji: React.FC<Props> = ({ animation = "none", className, children }) => (
  <span className={classNames({ [styles.wave]: animation === "wave" }, className)}>{children}</span>
);

export default Emoji;
