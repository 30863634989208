import { useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, LoginRequiredParam } from "utils/react-query/require-data";

import useJwtQueryOptions from "./useJwtQueryOptions";

/**
 * This should be preferred over `useRecoilState(jwtState)` where possible, since it automatically refreshes.
 */
const useJwt = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const { data } = useSuspenseQuery(useJwtQueryOptions(params));

  return data as DataRequired<typeof data, TRequired>;
};

export default useJwt;
