import { useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useBillEmailQueryOptions = (billEmailId: string) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: ["bill-emails", { billEmailId }],
    queryFn: () => {
      return highbeamApi.billEmail.get(billEmailId);
    },
  });
};

const useBillEmail = <TRequired extends boolean>(
  billEmailId: string,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery(useBillEmailQueryOptions(billEmailId));

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useBillEmail;
