import BillAuditLogRep from "reps/BillAuditLogRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class AuditTrailApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getBillAuditLogs(
    billId: string,
    businessGuid: string
  ): Promise<BillAuditLogRep.Complete[]> {
    const queryParams = new URLSearchParams({ billId, businessGuid });
    const url = `/audit-trail/bill-audit-logs?${queryParams}`;
    return (await this.api.get<BillAuditLogRep.Complete[]>(url))!;
  }
}
