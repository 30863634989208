import {
  AtmTransaction,
  CardReversalTransaction,
  CardTransaction,
  PurchaseTransaction,
} from "@highbeam/unit-node-sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import { UNIT_CO_TRANSACTIONS_QUERY_KEY } from "resources/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";

import { CardTransactionsTab } from "../constants";

// NB(alex): I'm pretty sure these are all the possible card transaction types, but it might actually be cleanest to not use the `type` field at all and just rely on the `cardId` field.
export const CARD_TRANSACTIONS_TABLE_COMPLETED_TRANSACTIONS_TYPES = [
  "Atm",
  "CardTransaction",
  "Purchase",
  "Reversal",
];

// NB(alex): These map to the respective `CARD_TRANSACTIONS_TABLE_COMPLETED_TRANSACTIONS_TYPES` above. Would be ideal if we determined the type through mapping.
export type CardTransactionsTableTransaction =
  | CardTransaction
  | PurchaseTransaction
  | AtmTransaction
  | CardReversalTransaction;

type Params = {
  cardId?: string;
  activeTab: CardTransactionsTab;
  page: number;
  since: string;
  until: string;
  search: string;
  offset: number;
  limit: number;
};

const useCardTransactionsTableData = ({
  cardId,
  activeTab,
  page,
  since,
  until,
  search,
  offset,
  limit,
}: Params) => {
  const unitApi = useUnitApi();

  // NB(alex): We should probably debounce the `search` query param. Still unsure how we want to do this.

  const { data } = useSuspenseQuery({
    queryKey: [UNIT_CO_TRANSACTIONS_QUERY_KEY, { cardId, activeTab, search, since, until, page }],
    queryFn: async () => {
      switch (activeTab) {
        case "Completed":
          const cardsResponse = await unitApi.transactions.list({
            cardId: cardId,
            limit: limit,
            offset: offset,
            type: CARD_TRANSACTIONS_TABLE_COMPLETED_TRANSACTIONS_TYPES,
            query: search,
            since: since,
            until: until,
          });

          return {
            data: cardsResponse.data as CardTransactionsTableTransaction[], // NB(alex): This type-cast should be safe because we filter by `type: CARD_TRANSACTIONS_TABLE_COMPLETED_TRANSACTIONS_TYPES`. Please let me know if this is not the case.
            pagination: cardsResponse.meta.pagination,
          };
        case "Canceled":
        case "Declined":
          // NB(alex): We don't support `search` for authorizations but we can use fuse to filter on the frontend.

          const authorizationsResponse = await unitApi.authorizations.list({
            cardId: cardId,
            status: activeTab,
            limit: limit,
            offset: offset,
            includeNonAuthorized: true,
            since: since,
            until: until,
            sort: "-createdAt",
          });

          return {
            data: authorizationsResponse.data,
            pagination: authorizationsResponse.meta.pagination,
          };
      }
    },
  });

  return data;
};

export default useCardTransactionsTableData;
