import { ComponentPropsWithoutRef, FC } from "react";
import Shimmer from "ui/feedback/ShimmerV2";
import { Heading4 } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import MoneyAmount from "../money/MoneyAmount";

const HighlightItemV2: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

const HighlightItemHeading: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <Heading4
      className={cn(
        "flex h-6 items-center gap-x-2 text-sm font-medium leading-6 text-grey-600",
        className
      )}
    >
      {children}
    </Heading4>
  );
};

const HighlightItemBody: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("flex h-8 items-center", className)}>{children}</div>;
};

const HighlightItemMoneyAmount: FC<ComponentPropsWithoutRef<typeof MoneyAmount>> = ({
  className,
  ...props
}) => {
  return (
    <MoneyAmount
      size={18}
      weight="medium"
      centsTextSize={14}
      className={cn("leading-8", className)}
      {...props}
    />
  );
};

const HighlightItemFooter: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("flex h-6 items-center", className)}>{children}</div>;
};

const HighlightItemLoading: FC<{ className?: string }> = ({ className }) => {
  return (
    <HighlightItemV2 className={className}>
      <HighlightItemHeading>
        <Shimmer className="w-28" />
      </HighlightItemHeading>
      <HighlightItemBody>
        <Shimmer className="w-12" />
      </HighlightItemBody>
      <HighlightItemFooter>
        <Shimmer className="w-24" />
      </HighlightItemFooter>
    </HighlightItemV2>
  );
};

export default Object.assign(HighlightItemV2, {
  Heading: HighlightItemHeading,
  Body: HighlightItemBody,
  MoneyAmount: HighlightItemMoneyAmount,
  Footer: HighlightItemFooter,
});

export { HighlightItemLoading as HighlightItemV2Loading };
