import { FC, ReactNode } from "react";

type Props = {
  dropdown?: ReactNode;
  searchBar?: ReactNode;
  dateRangePickers?: ReactNode;
  paginationInfo: ReactNode;
  filterTabs?: ReactNode;
  exportButton?: ReactNode;
};

const Filters: FC<Props> = ({
  dropdown,
  searchBar,
  dateRangePickers,
  paginationInfo,
  filterTabs,
  exportButton,
}) => (
  <div className="flex flex-col gap-6 pb-6 tablet-landscape:gap-8">
    {(dropdown || searchBar || dateRangePickers) && (
      <div className="grid gap-6 extra-large-desktop:grid-cols-3">
        {dropdown}
        {searchBar}
        {dateRangePickers}
      </div>
    )}
    <div className="flex w-full flex-col items-start justify-center gap-6 tablet:flex-row tablet:items-center tablet:justify-between tablet:gap-x-4">
      <div>{filterTabs}</div>
      <span className="flex items-center gap-4">
        <div className="flex w-full max-w-80 justify-start tablet:justify-end">
          {paginationInfo}
        </div>
        <div>{exportButton}</div>
      </span>
    </div>
  </div>
);

export default Filters;
