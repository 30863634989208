import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const hasAddToHomeScreenBannerBeenDismissedState = atom<boolean>({
  key: "hasAddToHomeScreenBannerBeenDismissedState",
  default: false,
  effects: [persistAtom],
});

export default hasAddToHomeScreenBannerBeenDismissedState;
