import useDebugSettings from "modules/debug/useDebugSettings";
import React from "react";
import Section from "ui/data-display/Section";
import SectionHeader from "ui/data-display/SectionHeader";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";

type Props = {
  error: Error;
};

/**
 * TODO: Re-design this page. It's very barebones/draft.
 */
const NotFoundPage: React.FC<Props> = ({ error }) => {
  const { showDebugMessages } = useDebugSettings();
  const errorStack = showDebugMessages ? error?.stack : undefined;

  return (
    <Section>
      <SectionHeader>
        <Heading2>404 Not Found</Heading2>
      </SectionHeader>
      <Text size={14}>We’re sorry, this page isn’t available.</Text>
      {errorStack && <code style={{ whiteSpace: "pre-line" }}>{errorStack}</code>}
    </Section>
  );
};

export default NotFoundPage;
