import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";
import { HighbeamAdjustmentTransaction } from "utils/types/transactionsTypes";

type Props = {
  transaction: HighbeamAdjustmentTransaction;
};

const AdjustmentTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>{transaction.longMethodName} details</Heading3>
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.description}</MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default AdjustmentTransactionDetailsSection;
