import checkBack from "assets/check-back.svg";
import CaptureCheck from "pages/DepositCheckPage/CaptureCheck";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import checkDepositState from "state/checkDeposit";

import EndorsementVerification from "./endorsementVerification";

type Props = {
  onPrevPress: () => void;
  onNextPress: () => void;
  isEndorsementVerified: boolean;
  setIsEndorsementVerified: Dispatch<SetStateAction<boolean>>;
};

const config = {
  pageTitle: "Back of check",
  stepNumber: 3,
  direction: "back",
  icon: checkBack,
  mitekDocumentType: "CHECK_BACK",
};

const BackOfCheck: React.FC<Props> = ({
  onPrevPress,
  onNextPress,
  isEndorsementVerified,
  setIsEndorsementVerified,
}) => {
  const [deposit, setDeposit] = useRecoilState(checkDepositState);

  const [currentImage, setCurrentImage] = useState(deposit.back.image);
  const [validation, setValidation] = useState(deposit.back.validation);
  const [selectedOption, setSelectedOption] = useState(deposit.back.selectedOption);

  useEffect(() => {
    setDeposit({
      ...deposit,
      back: {
        ...deposit.back,
        image: currentImage,
        validation: validation,
        selectedOption: selectedOption,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage, validation, selectedOption]);
  return (
    <>
      {!isEndorsementVerified && (
        <EndorsementVerification
          config={config}
          onPrevPress={onPrevPress}
          onNextPress={() => {
            setIsEndorsementVerified(true);
          }}
          isThreadAccount={deposit.account?.isThreadAccount || false}
        ></EndorsementVerification>
      )}
      {isEndorsementVerified && (
        <CaptureCheck
          config={config}
          currentImage={currentImage}
          setCurrentImage={setCurrentImage}
          validation={validation}
          setValidation={setValidation}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          onPrevPress={onPrevPress}
          onNextPress={onNextPress}
        ></CaptureCheck>
      )}
    </>
  );
};

export default BackOfCheck;
