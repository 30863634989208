import { useSuspenseQuery } from "@tanstack/react-query";

import usePlaidLinkTokenQueryOptions from "./usePlaidLinkTokenQueryOptions";

const usePlaidLinkToken = (existingConnectionGuid?: string) => {
  const { data: plaidLinkToken } = useSuspenseQuery(
    usePlaidLinkTokenQueryOptions(existingConnectionGuid)
  );

  return plaidLinkToken;
};

export default usePlaidLinkToken;
