import PaymentRep from "reps/payments-v2/PaymentRep";
import SendPaymentCreatorRep from "reps/SendPaymentCreatorRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class PaymentActionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async send(
    paymentGuid: string,
    sender: SendPaymentCreatorRep.Creator
  ): Promise<PaymentRep.Complete> {
    const url = `/v2/payments/${paymentGuid}/send`;
    return (await this.api.post<PaymentRep.Complete>(url, sender))!;
  }

  async reject(paymentGuid: string): Promise<PaymentRep.Complete> {
    const url = `/v2/payments/${paymentGuid}/reject`;
    return (await this.api.post<PaymentRep.Complete>(url))!;
  }

  async cancel(paymentGuid: string): Promise<PaymentRep.Complete> {
    const url = `/v2/payments/${paymentGuid}/cancel`;
    return (await this.api.post<PaymentRep.Complete>(url))!;
  }
}
