import classNames from "classnames";
import { ReactNode } from "react";
import { GroupBase, OptionProps } from "react-select";
import Text from "ui/typography/Text";

import styles from "./DropdownOption.module.scss";

type DropdownOptionProps<
  TOption,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> = OptionProps<TOption, TIsMulti, TGroup> & {
  description?: ReactNode;
};

const DropdownOption = <
  TOption,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
  props: DropdownOptionProps<TOption, TIsMulti, TGroup>
) => {
  const { innerProps, innerRef, children, description, isDisabled, isFocused } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={classNames(
        styles.option,
        !isDisabled && isFocused && styles.focused,
        isDisabled && styles.disabled
      )}
    >
      {typeof children === "string" ? (
        <Text size={14} className={classNames(styles.labelText)}>
          {children}
        </Text>
      ) : (
        children
      )}

      {description && (
        <>
          {typeof description === "string" ? (
            <Text size={12} className={classNames(styles.descriptionText)}>
              {description}
            </Text>
          ) : (
            description
          )}
        </>
      )}
    </div>
  );
};

export default DropdownOption;
