import whiteLogo from "assets/highbeam-logo-white.svg";
import purpleLogo from "assets/highbeam-logo-without-name.svg";

type Props = {
  isPhysicalCard: boolean;
};

const HighbeamCardLogo: React.FC<Props> = ({ isPhysicalCard }) => (
  <img className="size-6" src={isPhysicalCard ? whiteLogo : purpleLogo} alt="Logo" />
);

export default HighbeamCardLogo;
