import { useRecoilValue } from "recoil";
import allStepsState from "state/capital/creditApplication/allSteps";
import StepsV3 from "ui/navigation/StepsV3";

import useCurrentStepIndex from "../hooks/useCurrentStepIndex";

const CreditApplicationSteps = () => {
  const allSteps = useRecoilValue(allStepsState);
  const currentStepIndex = useCurrentStepIndex();

  return (
    <StepsV3>
      {allSteps.map(({ path, title }, index) => (
        <StepsV3.Step
          key={path}
          number={index + 1}
          state={
            currentStepIndex === index ? "active" : currentStepIndex > index ? "done" : "incomplete"
          }
          isLast={index + 1 === allSteps.length}
          to={currentStepIndex <= index ? undefined : path}
        >
          {title}
        </StepsV3.Step>
      ))}
    </StepsV3>
  );
};

export default CreditApplicationSteps;
