import getSymbolFromCurrency from "currency-symbol-map";
import { formatValue } from "react-currency-input-field";
import { Money } from "reps/Money";

import getCurrencyDecimalPlaces from "./money/getCurrencyDecimalPlaces";

export const parseMoneyFloat = (string: string) => {
  return parseFloat(string.replace(",", "."));
};

export const getCentsFromDollars = (dollars: string) => {
  return Math.round(parseMoneyFloat(dollars) * 100);
};

// NB(alex): refactored this file but we should also be consistent with "dollars" being a string or a number. I think it should be a string, but we can deal with that later.
export const getDollarsFromCents = (cents: number) => {
  return cents / 100;
};

type FormatMoneyOptions = {
  showCurrencySymbol?: boolean;
  showTrailingCurrencyCode?: boolean;
  showCents?: boolean;
  dropZeroCents?: boolean;
  showPlusMinusSign?: boolean;
};

export const formatMoney = (money: Money, options: FormatMoneyOptions = {}) => {
  const {
    showCurrencySymbol = false,
    showTrailingCurrencyCode = false,
    showCents = true,
    dropZeroCents = true,
    showPlusMinusSign = false,
  } = options;

  const { amount, currency } = money;
  const currencySymbol = getSymbolFromCurrency(currency);
  const decimalPlaces = getCurrencyDecimalPlaces(currency);
  const amountNumber = Number(amount);
  const isPositive = amountNumber > 0;
  const isNegative = amountNumber < 0;

  const formattedAmount = formatValue({
    value: amount,
    decimalScale: decimalPlaces,
  });
  const [formattedAmountWhole, formattedAmountCents] = formattedAmount.split(".");

  return [
    showPlusMinusSign && (isPositive || isNegative) && (isPositive ? "+" : "−"),
    showCurrencySymbol && currencySymbol,
    isNegative ? formattedAmountWhole.substring(1) : formattedAmountWhole,
    showCents &&
      formattedAmountCents &&
      (!dropZeroCents || parseFloat(formattedAmountCents) > 0) &&
      `.${formattedAmountCents}`,
    showTrailingCurrencyCode && ` ${currency}`,
  ]
    .filter(Boolean)
    .join("");
};
