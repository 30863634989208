import { useSuspenseQuery } from "@tanstack/react-query";

import useUnitCoCustomerTokenQueryOptions from "./useUnitCoCustomerTokenQueryOptions";

const useUnitCoCustomerToken = () => {
  const { data } = useSuspenseQuery(useUnitCoCustomerTokenQueryOptions());
  return data;
};

export default useUnitCoCustomerToken;
