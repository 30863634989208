import { zodResolver } from "@hookform/resolvers/zod";
import { X as XIcon } from "@phosphor-icons/react";
import { debounce } from "debounce";
import deepEquals from "fast-deep-equal";
import { FC, useCallback, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import BillLineItemRep from "reps/BillLineItemRep";
import BillRep from "reps/BillRep";
import BillSummaryRep from "reps/BillSummaryRep";
import BillSyncRep from "reps/BillSyncRep";
import AccountingCategorySelect from "resources/accounting-accounts/components/AccountingCategorySelect";
import useAccountingAccounts from "resources/accounting-accounts/queries/useAccountingAccounts";
import useDeleteBillLineItemMutation from "resources/bill-line-items/mutations/useDeleteBillLineItemMutation";
import useUpdateBillLineItemMutation from "resources/bill-line-items/mutations/useUpdateBillLineItemMutation";
import useBillLineItems from "resources/bill-line-items/queries/useBillLineItems";
import inferBillLineItemCurrencyFromBill from "resources/bill-line-items/utils/inferBillLineItemCurrency";
import useBillSyncQueryIfEnabled from "resources/bill-syncs/hooks/useBillSyncQueryIfEnabled";
import useShouldShowEmployeeView from "resources/bills/hooks/useShouldShowEmployeeView";
import useBill from "resources/bills/queries/useBill";
import MoneyAmount from "ui/data-display/money/MoneyAmountV2";
import Button from "ui/inputs/Button";
import MoneyInputs from "ui/inputs/MoneyInputs";
import TextInputV2 from "ui/inputs/TextInputV2";
import NotFoundCell from "ui/table/NotFoundCell";
import { Span } from "ui/typography";
import { useIsMobile } from "utils/device/useMediaQuery";
import { useEventBusDispatcher } from "utils/event-bus";
import { parseMoneyFloat } from "utils/money";
import cn from "utils/tailwind/cn";
import { z } from "zod";

const AUTOSAVE_DEBOUNCE_DELAY = 1000;

export const BILL_LINE_ITEM_ROW_ACTION_EVENT = "billLineItemRowAction";

type BillLineItemRowActionType = "saving" | "saved" | "save-error";

export type BillLineItemRowActionEvent = CustomEvent<{
  billLineItemId: string;
  billId: string;
  type: BillLineItemRowActionType;
}>;

const makeBillLineItemRowActionEvent = (
  billLineItemId: string,
  billId: string,
  type: BillLineItemRowActionType
): BillLineItemRowActionEvent =>
  new CustomEvent(BILL_LINE_ITEM_ROW_ACTION_EVENT, {
    detail: { billLineItemId, billId, type },
  });

const billLineItemFormSchema = z.object({
  description: z.string(),
  accountingAccountId: z.string().nullable(),
  amountAmount: z.string().refine((value) => {
    const parsedValue = parseMoneyFloat(value);
    if (isNaN(parsedValue)) {
      return false;
    }
    return parsedValue >= 0;
  }, "Please enter an amount greater than or equal to 0."),
});

type BillLineItemFormInputs = z.infer<typeof billLineItemFormSchema>;

type UseBillLineItemFormParams = {
  disabled: boolean;
  defaultValues: BillLineItemFormInputs;
};

const useBillLineItemForm = (params: UseBillLineItemFormParams) =>
  useForm<BillLineItemFormInputs>({
    resolver: zodResolver(billLineItemFormSchema),
    mode: "onChange",
    ...params,
  });

const makeBillLineItemFormDefaultValues = (
  billLineItem: BillLineItemRep.Complete
): BillLineItemFormInputs => ({
  description: billLineItem.description ?? "",
  accountingAccountId: billLineItem.accountingAccountId,
  amountAmount: billLineItem.amount.amount,
});

const makeBillLineItemUpdater = (
  billLineItem: BillLineItemRep.Complete,
  data: BillLineItemFormInputs
): BillLineItemRep.Updater => {
  const { description, accountingAccountId, amountAmount } = data;

  return {
    ...(description !== billLineItem.description && { description }),
    ...(accountingAccountId !== billLineItem.accountingAccountId && {
      accountingAccountId: accountingAccountId || null,
    }),
    ...(amountAmount !== billLineItem.amount.amount && {
      amount: { amount: amountAmount, currency: billLineItem.amount.currency },
    }),
  };
};

const TR_CLASSES = cn(
  "relative flex flex-col border-none [counter-increment:rowNumber] before:mb-1 before:font-medium before:leading-none before:content-['Item_#'_counter(rowNumber)] tablet:static tablet:table-row tablet:before:hidden"
);

const TD_CLASSES = cn(
  "block w-full py-2 tablet:table-cell tablet:w-1/3 tablet:px-1 tablet:first-of-type:pl-4 tablet:last-of-type:pr-4"
);
const TH_CLASSES = cn(TD_CLASSES, "font-regular text-grey-600");

type BodyRowProps = {
  bill: BillSummaryRep.Complete;
  billLineItem: BillLineItemRep.Complete;
  billSync: BillSyncRep.Complete | null;
  isOnlyRow: boolean;
  isMobile: boolean;
};

const BodyRow: FC<BodyRowProps> = ({ bill, billLineItem, billSync, isOnlyRow, isMobile }) => {
  const billLineItemId = billLineItem.id;
  const billId = bill.id;
  const currency = inferBillLineItemCurrencyFromBill(bill);
  const billSyncErrors = billSync?.errors ?? [];
  const hasBillSyncLineItemAccountMissingError = billSyncErrors.some(
    (error) => error.type === "LineItemAccountMissing" && error.billLineItemId === billLineItemId
  );
  const hasBillSyncLineItemsTotalDiscrepancyError = billSyncErrors.some(
    (error) => error.type === "LineItemsTotalDiscrepancy"
  );

  const dispatchBillLineItemRowActionEvent = useEventBusDispatcher<BillLineItemRowActionEvent>();

  const { mutate: updateBillLineItem } = useUpdateBillLineItemMutation(billLineItemId, billId, {
    onMutate: () => {
      dispatchBillLineItemRowActionEvent(
        makeBillLineItemRowActionEvent(billLineItemId, billId, "saving")
      );
    },
    onSuccess: () => {
      dispatchBillLineItemRowActionEvent(
        makeBillLineItemRowActionEvent(billLineItemId, billId, "saved")
      );
    },
    onError: () => {
      dispatchBillLineItemRowActionEvent(
        makeBillLineItemRowActionEvent(billLineItemId, billId, "save-error")
      );
    },
  });

  const { mutate: deleteBillLineItem, isPending: isDeletingBillLineItem } =
    useDeleteBillLineItemMutation(billLineItemId, billId);

  // NB(lev): Since we're rendering line items as rows in a table body, we're not
  // wrapping the inputs in an actual form. Neverthless, we're using the form abstraction
  // to handle input validation, state of the fields, etc.
  const {
    control,
    getValues: getFormValues,
    trigger: triggerValidation,
    reset: resetForm,
  } = useBillLineItemForm({
    disabled: bill.isClosedForAccounting && bill.state !== BillRep.State.Draft,
    defaultValues: makeBillLineItemFormDefaultValues(billLineItem),
  });

  const save = useCallback(async () => {
    // Trigger validation manually to ensure that all invalid fields display their
    // relevant error state if we abandoned the save.
    const isValid = await triggerValidation();
    if (!isValid) {
      return;
    }
    const updater = makeBillLineItemUpdater(billLineItem, getFormValues());
    if (Object.keys(updater).length > 0) {
      updateBillLineItem(updater);
    }
  }, [billLineItem, getFormValues, updateBillLineItem, triggerValidation]);

  const debouncedSave = debounce(save, AUTOSAVE_DEBOUNCE_DELAY);

  // When the backing data for the line item is updated (e.g. because the line items
  // query was refreshed), we want to sync the form state with the new data.
  // NB(lev): We only want to reset the form state if the row is not focused,
  // because we want to preserve the user's cursor position in the inputs.
  const isFocusedRef = useRef(false);
  const prevBillLineItemDataRef = useRef<BillLineItemRep.Complete | null>(null);

  useEffect(() => {
    const isFocused = isFocusedRef.current;
    const prevBillLineItemData = prevBillLineItemDataRef.current;
    if (!isFocused && prevBillLineItemData && !deepEquals(billLineItem, prevBillLineItemData)) {
      resetForm(makeBillLineItemFormDefaultValues(billLineItem));
    }
    prevBillLineItemDataRef.current = billLineItem;
  }, [billLineItem, resetForm]);

  return (
    <tr
      className={cn(TR_CLASSES, isDeletingBillLineItem && "pointer-events-none opacity-50")}
      onFocus={() => (isFocusedRef.current = true)}
      onBlur={() => (isFocusedRef.current = false)}
    >
      <td className={TD_CLASSES}>
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState }) => (
            <TextInputV2
              variant={isMobile ? "default" : "minimal"}
              label="Description"
              showErrorOutline={Boolean(fieldState.error)}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                debouncedSave();
              }}
            />
          )}
        />
      </td>
      <td className={TD_CLASSES}>
        <Controller
          name="accountingAccountId"
          control={control}
          render={({ field }) => (
            <AccountingCategorySelect
              variant={isMobile ? "default" : "minimal"}
              noAccountsDisplayVariant="not-available"
              disabled={field.disabled}
              value={field.value}
              showErrorOutline={hasBillSyncLineItemAccountMissingError}
              onValueChange={(value) => {
                field.onChange(value);
                // Since onValueChange is only triggered when the user explicitly
                // selects an option, we don't need to debounce here.
                save();
              }}
            />
          )}
        />
      </td>
      <td className={TD_CLASSES}>
        <Controller
          name="amountAmount"
          control={control}
          render={({ field, fieldState }) => (
            <MoneyInputs.AmountInput
              variant={isMobile ? "default" : "minimal"}
              isStandalone
              label="Amount"
              currency={currency}
              showErrorOutline={
                hasBillSyncLineItemsTotalDiscrepancyError || Boolean(fieldState.error)
              }
              {...field}
              onChange={(e) => {
                field.onChange(e);
                debouncedSave();
              }}
            />
          )}
        />
      </td>
      <td className={TD_CLASSES}>
        <div className="absolute -top-2.5 right-0 ml-auto w-fit tablet:static tablet:right-auto tablet:top-auto">
          <Button
            aria-label="Remove"
            paddingVariant="square"
            variant="ghost"
            disabled={isOnlyRow}
            isLoading={isDeletingBillLineItem}
            onClick={() => !isOnlyRow && deleteBillLineItem()}
          >
            {!isDeletingBillLineItem && <XIcon size={16} />}
          </Button>
        </div>
      </td>
    </tr>
  );
};

const AccountingAccountDisplay: FC<{ accountingAccountId: string }> = ({ accountingAccountId }) => {
  const accountingAccounts = useAccountingAccounts();
  const accountingAccount = accountingAccounts.find(
    (accountingAccount) => accountingAccount.id === accountingAccountId
  );

  if (!accountingAccount) {
    return <NotFoundCell />;
  }

  return <Span>{accountingAccount.name}</Span>;
};

type BodyRowEmployeeViewProps = {
  bill: BillSummaryRep.Complete;
  billLineItem: BillLineItemRep.Complete;
};

const BodyRowEmployeeView: FC<BodyRowEmployeeViewProps> = ({ bill, billLineItem }) => {
  const currency = inferBillLineItemCurrencyFromBill(bill);
  const { description, amount, accountingAccountId } = billLineItem;
  return (
    <tr className={TR_CLASSES}>
      <td className={TD_CLASSES}>{description}</td>
      <td className={TD_CLASSES}>
        {accountingAccountId ? (
          <AccountingAccountDisplay accountingAccountId={accountingAccountId} />
        ) : (
          <NotFoundCell />
        )}
      </td>
      <td className={TD_CLASSES}>
        {amount ? (
          <MoneyAmount
            amount={amount.amount}
            currencyCode={currency}
            showCurrencySymbol
            showTrailingCurrencyCode
            currencyCodeTextWeight="regular"
          />
        ) : (
          <NotFoundCell />
        )}
      </td>
    </tr>
  );
};

type Props = {
  billId: string;
};

const BillLineItemsTable: FC<Props> = ({ billId }) => {
  const bill = useBill(billId, { required: true });
  const billLineItems = useBillLineItems(billId);
  const numBillLineItems = billLineItems.length;
  const { data: billSync } = useBillSyncQueryIfEnabled(billId);
  const isMobile = useIsMobile();
  const shouldShowEmployeeView = useShouldShowEmployeeView();

  return (
    <table className="block w-full text-sm tablet:table">
      <thead className="hidden border-b border-grey-100 tablet:table-header-group">
        <tr>
          <th className={TH_CLASSES}>Description</th>
          <th className={TH_CLASSES}>Accounting category</th>
          <th className={TH_CLASSES}>Amount</th>
          {!shouldShowEmployeeView && (
            <th className={TH_CLASSES}>
              <span className="sr-only">Remove</span>
            </th>
          )}
        </tr>
      </thead>
      <tbody className="block [counter-reset:rowNumber] tablet:table-row-group">
        {shouldShowEmployeeView
          ? billLineItems.map((billLineItem) => (
              <BodyRowEmployeeView key={billLineItem.id} bill={bill} billLineItem={billLineItem} />
            ))
          : billLineItems.map((billLineItem) => (
              <BodyRow
                key={billLineItem.id}
                bill={bill}
                billLineItem={billLineItem}
                billSync={billSync ?? null}
                isOnlyRow={numBillLineItems === 1}
                isMobile={isMobile}
              />
            ))}
      </tbody>
    </table>
  );
};

export default BillLineItemsTable;
