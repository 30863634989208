import { ArrowLeft } from "@phosphor-icons/react";
import { FC, FormEvent, useState, useMemo } from "react";
import PayeeRep from "reps/PayeeRep";
import { useRefreshBillLineItemsQuery } from "resources/bill-line-items/queries/useBillLineItems";
import billSyncQueryHooks from "resources/bill-syncs/queries/billSyncQueryHooks";
import shouldBillSyncBeDryRun from "resources/bill-syncs/utils/shouldBillSyncBeDryRun";
import AchPaymentMethodFieldset from "resources/payee-payment-methods/components/AchPaymentMethodFieldset";
import DomesticWirePaymentMethodFieldset from "resources/payee-payment-methods/components/DomesticWirePaymentMethodFieldset";
import InternationalWirePaymentMethodRecoilFieldset from "resources/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset";
import useInternationalWirePaymentMethodRecoilHelpers from "resources/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset/useInternationalWirePaymentMethodRecoilHelpers";
import useAchPaymentMethodForm, {
  AchPaymentMethodFormInputs,
} from "resources/payee-payment-methods/forms/useAchPaymentMethodForm";
import useDomesticWirePaymentMethodForm, {
  DomesticWirePaymentMethodFormInputs,
} from "resources/payee-payment-methods/forms/useDomesticWirePaymentMethodForm";
import PayeeDetailsFieldset from "resources/payees/components/PayeeDetailsFieldset";
import usePayeeDetailsForm, {
  PayeeDetailsFormInputs,
} from "resources/payees/forms/usePayeeDetailsForm";
import useUpdatePayeeForBillPayMutation from "resources/payees/mutations/useUpdatePayeeForBillPayMutation";
import DetailsSidebarBody from "ui/data-display/DetailsSidebarBody";
import Divider from "ui/data-display/Divider";
import UserAvatar from "ui/data-display/UserAvatar";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import Helper from "ui/inputs/Helper";
import SegmentControls from "ui/navigation/SegmentControls";
import Text from "ui/typography/Text";
import { INITIAL_US_ADDRESS, states, US_COUNTRY_OPTION } from "utils/address";
import { ApiError } from "utils/ajax";
import getInitials from "utils/string/getInitials";

import useBill from "../../queries/useBill";
import { useRefreshBillPaymentMethodsQueries } from "../../queries/useBillPaymentMethods";
import useBillDetailsPayeePaymentMethodTabs, {
  PayeePaymentMethodTabKey,
} from "../../search-params/useBillDetailsPayeePaymentMethodTabs";

import styles from "./BillUpdatePayeeForm.module.scss";

const getDefaultPaymentMethodTab = (payee: PayeeRep.Complete): PayeePaymentMethodTabKey => {
  if (payee.achTransferMethod) {
    return "ach";
  }
  if (payee.wireTransferMethod) {
    return "domestic-wire";
  }
  if (payee.internationalWireTransferMethod) {
    return "international-wire";
  }
  return "ach";
};

type Props = {
  onBack: () => void;
  payee: PayeeRep.Complete;
  billId: string;
};

const BillUpdatePayeeForm: FC<Props> = ({ onBack, payee, billId }) => {
  const defaultPaymentMethodTab = useMemo(() => getDefaultPaymentMethodTab(payee), [payee]);
  const bill = useBill(billId, { required: true });
  const dryRun = shouldBillSyncBeDryRun(bill);
  const refreshBillSyncQuery = billSyncQueryHooks.useRefreshQuery({ billId, dryRun });
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);
  const refreshBillPaymentMethodsQueries = useRefreshBillPaymentMethodsQueries();

  const { tabs, activeTab, setActiveTab } =
    useBillDetailsPayeePaymentMethodTabs(defaultPaymentMethodTab);

  const payeeDetailsForm = usePayeeDetailsForm({
    defaultValues: {
      payeeName: payee.name,
      payeeEmail: payee.emailAddress ?? "",
      payeePhoneNumber: payee.phone ?? "",
      payeeDefaultChartOfAccountId: payee.defaultChartOfAccountId,
    },
  });

  const achPaymentMethodForm = useAchPaymentMethodForm({
    defaultValues: payee.achTransferMethod
      ? {
          accountNumber: payee.achTransferMethod.accountNumber,
          routingNumber: payee.achTransferMethod.routingNumber,
        }
      : {},
  });

  const domesticWirePaymentMethodForm = useDomesticWirePaymentMethodForm({
    defaultValues: payee.wireTransferMethod
      ? {
          accountNumber: payee.wireTransferMethod.accountNumber,
          routingNumber: payee.wireTransferMethod.routingNumber,
          // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
          address: payee.address
            ? {
                addressLine1: {
                  value: payee.address?.addressLine1,
                  label: payee.address?.addressLine1,
                },
                addressLine2: payee.address.addressLine2 ?? "",
                city: payee.address.city,
                state: { label: states[payee.address.state], value: payee.address.state },
                zipCode: payee.address.zipCode,
                country: US_COUNTRY_OPTION,
              }
            : INITIAL_US_ADDRESS,
        }
      : {},
  });

  const [internationalWireError, setInternationalWireError] = useState<string>();
  const {
    internationalWireTransferMethod,
    isValidInternational,
    hasInputValues: hasInternationalInputValues,
  } = useInternationalWirePaymentMethodRecoilHelpers({
    payeeGuid: payee.guid,
  });

  const { mutate: updatePayeeForBillPay, isPending } = useUpdatePayeeForBillPayMutation({
    onSuccess: async () => {
      // NB(alex): refreshBillPaymentMethodsQueries() also updates bill payment methods. This seems a bit unintuitive to me, so we should probably re-think the query key in `useBillPaymentMethodsQueryOptions`.
      await Promise.all([
        refreshBillPaymentMethodsQueries(),
        refreshBillLineItemsQuery(),
        refreshBillSyncQuery(),
      ]);

      onBack();
      notify("success", "Payee updated");
    },
    onError: (error) => {
      notify(
        "error",
        error instanceof ApiError
          ? error.message
          : "There was an error saving payee details. Please try again."
      );
    },
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payeeDetailsFormData = await new Promise<PayeeDetailsFormInputs>((resolve, reject) => {
      payeeDetailsForm.handleSubmit(resolve, reject)(e);
    });

    const transferMethodBody = await (async () => {
      if (activeTab === "ach") {
        if (achPaymentMethodForm.isBlank()) {
          return;
        }

        const achPaymentMethodFormData = await new Promise<AchPaymentMethodFormInputs>(
          (resolve, reject) => {
            achPaymentMethodForm.handleSubmit(resolve, reject)(e);
          }
        );
        return {
          achTransferMethod: {
            routingNumber: achPaymentMethodFormData.routingNumber,
            accountNumber: achPaymentMethodFormData.accountNumber,
          } satisfies PayeeRep.AchTransferMethod,
        };
      }
      if (activeTab === "domestic-wire") {
        if (domesticWirePaymentMethodForm.isBlank()) {
          return;
        }

        const domesticWirePaymentMethodData =
          await new Promise<DomesticWirePaymentMethodFormInputs>((resolve, reject) => {
            domesticWirePaymentMethodForm.handleSubmit(
              // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
              (data) => resolve(data as DomesticWirePaymentMethodFormInputs),
              reject
            )(e);
          });

        return {
          wireTransferMethod: {
            routingNumber: domesticWirePaymentMethodData.routingNumber,
            accountNumber: domesticWirePaymentMethodData.accountNumber,
          } satisfies PayeeRep.WireTransferMethod,
          // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
          address: {
            addressLine1: domesticWirePaymentMethodData.address.addressLine1?.value ?? "",
            addressLine2: domesticWirePaymentMethodData.address.addressLine2 || undefined,
            city: domesticWirePaymentMethodData.address.city,
            state: domesticWirePaymentMethodData.address.state?.value ?? "",
            zipCode: domesticWirePaymentMethodData.address.zipCode,
            country: domesticWirePaymentMethodData.address.country?.value ?? "",
          } satisfies PayeeRep.Address,
        };
      }
    })();

    // HACK(alex): We're hacking this form to work with recoil state for international wire details, so we have to trigger form validation manually.
    if (
      activeTab === "international-wire" &&
      hasInternationalInputValues &&
      !isValidInternational
    ) {
      return setInternationalWireError("Missing fields.");
    }

    updatePayeeForBillPay({
      payeeGuid: payee.guid,
      name: payeeDetailsFormData.payeeName,
      emailAddress: payeeDetailsFormData.payeeEmail || undefined,
      phone: payeeDetailsFormData.payeePhoneNumber || undefined,
      defaultChartOfAccountId: payeeDetailsFormData.payeeDefaultChartOfAccountId || undefined,
      ...transferMethodBody,
      ...(activeTab === "international-wire" && internationalWireTransferMethod),
    });
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <DetailsSidebarBody
        header={
          <DetailsSidebarBody.Header>
            <Button variant="ghost" paddingVariant="square" onClick={onBack}>
              <ArrowLeft size={24} weight="light" />
            </Button>

            <UserAvatar initials={getInitials(payee.name)} size={44} color="purple-light" />

            <Text weight="medium" size={16} as="h3">
              {payee.name}
            </Text>
          </DetailsSidebarBody.Header>
        }
        main={
          <DetailsSidebarBody.Main>
            <DetailsSidebarBody.Section>
              <PayeeDetailsFieldset form={payeeDetailsForm} />
            </DetailsSidebarBody.Section>

            <Divider className="my-0" />

            <DetailsSidebarBody.Section>
              <Text size={14} weight="bold">
                Payment details
              </Text>

              <SegmentControls
                size="md"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabs={tabs}
                className={styles.segmentControls}
              />

              {activeTab === "ach" && <AchPaymentMethodFieldset form={achPaymentMethodForm} />}
              {activeTab === "domestic-wire" && (
                <DomesticWirePaymentMethodFieldset form={domesticWirePaymentMethodForm} />
              )}
              {activeTab === "international-wire" && (
                <div>
                  {internationalWireError && (
                    <Helper iconVariant="error" className="mb-4">
                      {internationalWireError}
                    </Helper>
                  )}
                  <InternationalWirePaymentMethodRecoilFieldset />
                </div>
              )}
            </DetailsSidebarBody.Section>
          </DetailsSidebarBody.Main>
        }
        footer={
          <DetailsSidebarBody.Footer className={styles.footer}>
            <Button type="submit" variant="primary" isLoading={isPending}>
              Save
            </Button>
            <Button variant="ghost" onClick={onBack}>
              Cancel
            </Button>
          </DetailsSidebarBody.Footer>
        }
      />
    </form>
  );
};

export default BillUpdatePayeeForm;
