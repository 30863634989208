import {
  Menu as BaseMenu,
  MenuButton as BaseMenuButton,
  MenuItems as BaseMenuItems,
} from "@headlessui/react";
import { DownloadSimple, Trash } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, MouseEventHandler } from "react";
import BillDocumentRep from "reps/BillDocumentRep";
import useDeleteBillDocumentMutation from "resources/bill-documents/mutations/useDeleteBillDocumentMutation";
import { useBillDocumentDataQuery } from "resources/bill-documents/queries/useBillDocumentData";
import useShouldShowEmployeeView from "resources/bills/hooks/useShouldShowEmployeeView";
import FilePreviewWithLabel from "ui/data-display/FilePreviewWithLabel";
import { notify } from "ui/feedback/Toast";
import VirtualButton from "ui/inputs/VirtualButton";
import Menu from "ui/overlay/Menu";
import menuStyles from "ui/overlay/Menu/Menu.module.scss";
import { downloadBlob, downloadWith404Retry } from "utils/download";

import styles from "./BillDocumentFilePreviewWithLabel.module.scss";

type Props = {
  billDocument: BillDocumentRep.Complete;
  onClick: MouseEventHandler<HTMLElement>;
  isActive: boolean;
};

const BillDocumentFilePreviewWithLabel: FC<Props> = ({ billDocument, onClick, isActive }) => {
  const shouldShowEmployeeView = useShouldShowEmployeeView();
  const { data } = useBillDocumentDataQuery({ billDocument });

  // NB(alex): Experimental - this is how I want the interface for mutations to look, but I'm still working out the details for the underlying implementation.
  const { mutate: deleteBillDocument } = useDeleteBillDocumentMutation({
    onSuccess: () => {
      notify("success", "Deleted");
    },
  });

  if (data) {
    return (
      <div>
        <BaseMenu>
          <VirtualButton onClick={onClick}>
            <FilePreviewWithLabel
              key={data.billDocumentId}
              className={styles.filePreview}
              isActive={isActive}
              src={data.src}
              fileType={data.fileType === "pdf" ? "pdf" : "image"}
              fileName={data.documentFileName ?? ""}
              rightIcon={
                <BaseMenuButton as="div" className={styles.iconButton}>
                  <FilePreviewWithLabel.MenuIcon role="button" tabIndex={0} size={16} />
                </BaseMenuButton>
              }
            />
          </VirtualButton>

          <BaseMenuItems className={classNames(styles.menuItems, menuStyles.items)}>
            <Menu.Item
              icon={<DownloadSimple />}
              onClick={async () => {
                const blob = await downloadWith404Retry(data.src);
                downloadBlob(data.documentFileName ?? "", window.URL.createObjectURL(blob));
              }}
            >
              Download
            </Menu.Item>

            {!shouldShowEmployeeView && (
              <Menu.Item
                icon={<Trash />}
                variant="danger"
                onClick={() => {
                  if (data.billDocumentId) {
                    deleteBillDocument({ billDocumentId: data.billDocumentId });
                  }
                }}
              >
                Delete
              </Menu.Item>
            )}
          </BaseMenuItems>
        </BaseMenu>
      </div>
    );
  }

  // NB(alex): Handle error case?

  return <FilePreviewWithLabel.Loading isActive={isActive} className={styles.filePreview} />;
};

export default BillDocumentFilePreviewWithLabel;
