import { Unit, UnitConfig } from "@highbeam/unit-node-sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import env from "env";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useUnitCoCustomerToken from "./useUnitCoCustomerToken";

const useUnitApiQueryOptions = (token: string, basePath: string, config?: UnitConfig) => {
  return useQueryOptions({
    queryKey: ["unit-api", token, basePath, config],
    queryFn: () => {
      return new Unit(token, basePath, config);
    },
    staleTime: Infinity,
  });
};

export const DEFAULT_UNIT_CONFIG: UnitConfig = {
  sdkUserAgent: false,
};

const useUnitApi = (sensitiveToken?: string) => {
  const token = useUnitCoCustomerToken();
  const { data } = useSuspenseQuery(
    useUnitApiQueryOptions(sensitiveToken ?? token, env.UNIT_API_ORIGIN, DEFAULT_UNIT_CONFIG)
  );
  return data;
};

export default useUnitApi;
