import { Gear as GearIcon, ChatTeardropDots as ChatTeardropDotsIcon } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import { FC } from "react";
import useCurrentApEmailAddress from "resources/ap-email-aliases/queries/useCurrentApEmailAddress";
import EmptyState from "ui/data-display/EmptyState";
import InlineCopyText from "ui/data-display/InlineCopyText";

type Props = {
  openEmailSettings: () => void;
};

const BillsEmailInboxEmptyState: FC<Props> = ({ openEmailSettings }) => {
  const currentApEmailAddress = useCurrentApEmailAddress();
  const chat = useChatWidget();

  return (
    <EmptyState
      variant="card"
      className="w-full"
      body={
        <>
          <EmptyState.PrimaryText>No bills</EmptyState.PrimaryText>
          {currentApEmailAddress ? (
            <EmptyState.SecondaryText>
              To create a bill, email an invoice to{" "}
              <InlineCopyText
                textToCopy={currentApEmailAddress}
                tooltipContent="Copy email address"
              >
                {currentApEmailAddress}
              </InlineCopyText>
            </EmptyState.SecondaryText>
          ) : (
            <EmptyState.SecondaryText>
              You do not currently have an email address set up to receive invoices. Please contact
              support to set one up.
            </EmptyState.SecondaryText>
          )}
        </>
      }
      footer={
        currentApEmailAddress ? (
          <EmptyState.CTA onClick={() => openEmailSettings()}>
            <GearIcon />
            Email settings
          </EmptyState.CTA>
        ) : (
          <EmptyState.CTA onClick={() => chat.show()}>
            <ChatTeardropDotsIcon />
            Talk to support
          </EmptyState.CTA>
        )
      }
    />
  );
};

export default BillsEmailInboxEmptyState;
