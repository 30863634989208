import TransactionCategorizationMatcherRep from "reps/TransactionCategorizationMatcherRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class TransactionCategorizationMatcherApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async set(
    body: TransactionCategorizationMatcherRep.Creator
  ): Promise<TransactionCategorizationMatcherRep.Complete> {
    const url = "/insights/transaction-categorizers/set";
    return (await this.api.put<TransactionCategorizationMatcherRep.Complete>(url, body))!;
  }
}
