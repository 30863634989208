import { RecurringPayment } from "@highbeam/unit-node-sdk";
import RecurringPaymentStatusPill from "pages/payments/PaymentsOverviewPage/RecurringPayments/RecurringPaymentStatusPill";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./RecurringPaymentDetailBubble.module.scss";

type Props = {
  recurringPayment: RecurringPayment;
};

const RecurringPaymentDetailBubble: React.FC<Props> = ({ recurringPayment }) => (
  <div className={styles.container}>
    <RecurringPaymentStatusPill recurringPayment={recurringPayment} />
    <Text size={14} weight="medium" color={colors.grey[800]}>
      {recurringPayment.type === "recurringCreditAchPayment" ? "ACH" : "Book"}
    </Text>
  </div>
);

export default RecurringPaymentDetailBubble;
