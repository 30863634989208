import { ChatDots as ChatDotsIcon } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import { FC } from "react";
import Button from "ui/inputs/Button";

type Props = {
  message?: string;
  className?: string;
};

const ContactSupportToConnectAccounting: FC<Props> = ({
  className,
  message = "I would like to connect my accounting software for Highbeam Bill Pay",
}) => {
  const chat = useChatWidget();

  return (
    <Button size="sm" className={className} onClick={() => chat.message(message)}>
      <ChatDotsIcon />
      Contact support
    </Button>
  );
};

export default ContactSupportToConnectAccounting;
