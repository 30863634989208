import { CalendarBlank } from "@phosphor-icons/react";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountAutoPayConnectedStoresHighlightItem from "resources/capital-accounts/components/CapitalAccountAutoPayConnectedStoresHighlightItem";
import CapitalAccountInterestDueHighlightItem from "resources/capital-accounts/components/CapitalAccountInterestDueHighlightItem";
import LineOfCreditDrawdownButtonLink from "resources/capital-accounts/components/LineOfCreditDrawdownButtonLink";
import LineOfCreditRepayButtonLink from "resources/capital-accounts/components/LineOfCreditRepayButtonLink";
import getCapitalAccountRepaymentOptionType from "resources/capital-accounts/utils/getCapitalAccountRepaymentOptionType";
import lineOfCreditIcon from "resources/line-of-credit/assets/line-of-credit-icon.svg";
import HighlightCard from "ui/data-display/HighlightCard";

import LineOfCreditLineUsedHighlightItem from "./LineOfCreditLineUsedHighlightItem";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const LineOfCreditHighlightCard: FC<Props> = ({ capitalAccountSummary }) => {
  const isDrawdownEnabled = capitalAccountSummary.controls.drawdownEnabled;
  const hasOutstandingBalance = capitalAccountSummary.runningBalance !== 0;
  const isLastStatementWarning = !isDrawdownEnabled && hasOutstandingBalance;
  const repaymentOptionType = getCapitalAccountRepaymentOptionType(capitalAccountSummary);

  if (!isDrawdownEnabled && !hasOutstandingBalance) {
    return null;
  }

  return (
    <HighlightCard
      sideRuleColor={isLastStatementWarning ? "warning" : "default"}
      header={
        isLastStatementWarning ? (
          <HighlightCard.Header sideRuleColor="warning" icon={<CalendarBlank weight="light" />}>
            Overdue statement
          </HighlightCard.Header>
        ) : (
          <HighlightCard.Header
            icon={
              <img
                src={lineOfCreditIcon}
                // 28px looks better than 24px
                width={28}
                height={28}
                alt="Line of credit"
              />
            }
          >
            Cash access line
          </HighlightCard.Header>
        )
      }
      body={
        <>
          <LineOfCreditLineUsedHighlightItem
            lineUsedAmountInCents={0 - capitalAccountSummary.runningBalance}
            isLastStatementWarning={isLastStatementWarning}
          />
          {capitalAccountSummary.details.netApr > 0 && (
            <CapitalAccountInterestDueHighlightItem
              capitalAccountSummary={capitalAccountSummary}
              infoIconColor="dark"
            />
          )}
          {repaymentOptionType === "PayoutPercentage" && (
            <CapitalAccountAutoPayConnectedStoresHighlightItem
              capitalAccountSummary={capitalAccountSummary}
              infoIconColor="dark"
            />
          )}
        </>
      }
      footer={
        <div className="flex w-full">
          <LineOfCreditRepayButtonLink
            capitalAccountSummary={capitalAccountSummary}
            className="rounded-r-none border-r-0"
          />

          {!isLastStatementWarning && (
            <LineOfCreditDrawdownButtonLink
              className="rounded-l-none"
              capitalAccountSummary={capitalAccountSummary}
            />
          )}
        </div>
      }
    />
  );
};

export default LineOfCreditHighlightCard;
