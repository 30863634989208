import { ArrowsClockwise } from "@phosphor-icons/react";
import useRefreshPlaidBalancesMutation from "resources/plaid-connections/mutations/useRefreshPlaidBalancesMutation";
import Button from "ui/inputs/Button";

type Props = {
  plaidBalancesRefreshing: boolean;
  setPlaidBalancesRefreshing: (refreshing: boolean) => void;
};

const RefreshPlaidBalancesButton = ({
  plaidBalancesRefreshing,
  setPlaidBalancesRefreshing,
}: Props) => {
  const { mutate: refreshBalances } = useRefreshPlaidBalancesMutation({
    onMutate: () => {
      setPlaidBalancesRefreshing(true);
    },
    onSettled: () => {
      setPlaidBalancesRefreshing(false);
    },
  });

  return (
    <Button
      onClick={() => refreshBalances({ forceFetch: true })}
      disabled={plaidBalancesRefreshing}
      variant="ghost"
    >
      {plaidBalancesRefreshing ? (
        "Refreshing..."
      ) : (
        <>
          <ArrowsClockwise />
          Refresh balances
        </>
      )}
    </Button>
  );
};

export default RefreshPlaidBalancesButton;
