import { JwtPermission } from "reps/Jwt/JwtPermission";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useJwt from "resources/jwt/queries/useJwt";
import { useIsSuperusering } from "state/auth/isSuperusering";

const useHasPermission = (permissionsToCheck: JwtPermission[] | JwtPermission) => {
  const jwt = useJwt();
  const businessGuid = useBusinessGuid();
  const isSuperusering = useIsSuperusering();

  if (isSuperusering) {
    // Pretend superusers have every permission.
    // The backend will enforce any operations that superusers cannot override.
    return true;
  }

  const permissions = jwt.acl.get(businessGuid);

  if (!permissions) return false;

  const permissionsArray = Array.isArray(permissionsToCheck)
    ? permissionsToCheck
    : [permissionsToCheck];

  return permissionsArray.some((permission) => permissions.includes(permission));
};

export default useHasPermission;
