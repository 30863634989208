import { Dispatch, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Wraps useSearchParams to specify a single numeric value and preserves other search params on update.
 * It handles converting the numeric value to be a number, from the string in the search param value.
 */
const useSearchParamNumber = (
  key: string,
  defaultValue: number
): [number, Dispatch<SetStateAction<number>>] => {
  const defaultValueAsString = defaultValue?.toString();

  const [searchParams, setSearchParams] = useSearchParams({
    ...(defaultValueAsString && { [key]: defaultValueAsString }),
  });

  const value = Number(searchParams.get(key) || "");

  const setValue = (value: SetStateAction<number>) => {
    // It's important for us to redefine this here to prevent overwriting params if multiple setters get called at once.
    const currentParams = new URLSearchParams(window.location.search);

    if (typeof value === "function") {
      return setSearchParams({
        ...Object.fromEntries(currentParams.entries()),
        [key]: value(Number(currentParams.get(key)) ?? defaultValue).toString(),
      });
    }

    return setSearchParams({
      ...Object.fromEntries(currentParams.entries()),
      [key]: value.toString(),
    });
  };

  return [value, setValue];
};

export default useSearchParamNumber;
