import Fuse from "fuse.js";
import useExecuteCommand from "modules/command/useExecuteCommand";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import Select, { components as defaultComponents, SelectInstance } from "react-select";
import colors from "styles/colors";
import shadows from "styles/shadows";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";

import { useContactSupportCommandPaletteOptions } from "../command-palette-options/contact-support";
import { useNavigationCommandPaletteOptions } from "../command-palette-options/navigation";
import { useSuperuserCommandPaletteOptions } from "../command-palette-options/superuser";
import { CommandPaletteOption } from "../command-palette-options/types";
import CommandPaletteDropdownOption from "../CommandPaletteDropdownOption";

type CommandPaletteDropdownProps = {
  onClose: () => void;
};

const CommandPaletteDropdown: ForwardRefRenderFunction<
  HTMLInputElement,
  CommandPaletteDropdownProps
> = ({ onClose }, ref) => {
  const selectRef = useRef<SelectInstance<CommandPaletteOption, false>>(null);

  useImperativeHandle(ref, () => {
    // NB(alex): non-null assertion fixes ts error. Feel free to modify if there is a better way to handle this without having to specify default values for 350+ input fields.
    return selectRef.current?.inputRef!;
  });

  // Blur when pressing `Escape`. Seems like this should be a default...
  useKeyboardEvent(
    (e) => e.key === "Escape",
    () => onClose(),
    [selectRef]
  );

  const [inputValue, setInputValue] = useState("");

  const navigationOptions = useNavigationCommandPaletteOptions();
  const superuserOptions = useSuperuserCommandPaletteOptions();
  const contactSupportOptions = useContactSupportCommandPaletteOptions({ inputValue });

  const options = [
    ...navigationOptions,
    ...superuserOptions,
    ...contactSupportOptions,
  ] satisfies CommandPaletteOption[];

  const fuse = useMemo(() => {
    return new Fuse(options, {
      threshold: 0.3,
      keys: [
        { name: "label", weight: 1 },
        { name: "value.commandName", weight: 2 },
      ],
      shouldSort: true,
      isCaseSensitive: false,
      getFn: ({ label, value }, path) => {
        // NB(alex): I think this this should cover every option, but it's not type-safe.
        if (Array.isArray(path)) {
          const name = path.join(".");
          if (name === "label") {
            // NB(alex): Hack to make fields filterable by label. Idk what the best way to do this is, but this certainly is not it.
            const tagsList =
              value.commandName === "superuser"
                ? (value.payload.businessGuid ?? "") // Makes superuser searchable by businessGuid.
                : "";

            return [label, label.replace(/[^a-zA-Z0-9]/g, ""), value.commandName + label, tagsList];
          }
          if (name === "value.commandName") {
            return value.commandName;
          }
        }
        return "";
      },
    });
  }, [options]);

  const suggestedOptions = useMemo(() => {
    return options.filter((option) => "isSuggested" in option && option.isSuggested);
  }, [options]);

  const filteredOptions = useMemo(() => {
    if (!inputValue) return suggestedOptions;
    return fuse.search(inputValue).map((result) => result.item);
  }, [fuse, inputValue, suggestedOptions]);

  const renderedOptions = useMemo(() => {
    return filteredOptions.slice(0, 15); // Improves performance dramatically
  }, [filteredOptions]);

  const executeCommand = useExecuteCommand();

  return (
    <Select
      ref={selectRef}
      inputValue={inputValue}
      onInputChange={(newValue, _actionMeta) => {
        return setInputValue(newValue);
      }}
      tabSelectsValue={false}
      classNamePrefix="command-palette-dropdown"
      components={{
        ...defaultComponents,
        Menu: ({ children, ...menuProps }) => (
          <defaultComponents.Menu {...menuProps}>
            <div
              onMouseLeave={() => {
                // NB(alex): Used for unsetting the menu's focus state when the mouse is no longer hovering over the menu.
                selectRef.current?.setState((prev) => ({ ...prev, focusedOption: null }));
              }}
            >
              {children}
            </div>
          </defaultComponents.Menu>
        ),
        Option: (props) => (
          <CommandPaletteDropdownOption
            onCopy={() => {
              if (
                props.data.value.commandName === "superuser" &&
                props.data.value.payload.businessGuid
              ) {
                executeCommand({
                  commandName: "copy-text",
                  payload: {
                    text: props.data.value.payload.businessGuid,
                  },
                });
              }
            }}
            onCmdEnter={() => {
              if (props.data.value.commandName === "superuser") {
                executeCommand(props.data.value);
              }
            }}
            {...props}
          />
        ),
      }}
      onKeyDown={(e) => {
        // Prevents the default behavior of selecting the selected option when cmd+enter is pressed. This lets us to pass in a custom event handler.
        if (e.metaKey && e.key === "Enter") {
          e.preventDefault();
        }
      }}
      filterOption={() => {
        // Using `fuse.js` for filtering options.
        return true;
      }}
      onChange={(newValue, actionMeta) => {
        if (actionMeta.action === "select-option" && newValue) {
          executeCommand(newValue.value);
          onClose();
        }
      }}
      placeholder="Enter a command"
      options={renderedOptions}
      menuIsOpen
      unstyled
      styles={{
        container: (base) => {
          return {
            ...base,
            borderRadius: "6px",
            height: "100%",
          };
        },
        control: (base) => {
          return {
            ...base,
            ":hover": {
              borderColor: "transparent",
            },
            borderColor: "transparent",
            boxShadow: shadows.sm,
            fontSize: "14px",
            height: "52px",
            padding: "0 12px",
            borderRadius: "6px",
            backgroundColor: "white",
          };
        },
        input: (base) => {
          return {
            ...base,
            cursor: "text",
          };
        },
        placeholder: (base) => {
          return {
            ...base,
            color: colors.grey[400],
          };
        },
        menu: (base) => {
          return {
            ...base,
            position: "static",
            paddingTop: 0,
            boxShadow: shadows.sm,
            overflow: "hidden",
            border: `1px solid ${colors.grey[200]}`,
            backgroundColor: "white",
            borderRadius: "6px",
            marginTop: "6px",
          };
        },
        menuList: (base) => {
          return {
            ...base,
            padding: 0,
            maxHeight: "512px", // Should be dynamic based on window height.
          };
        },
        option: (base, { isFocused }) => {
          return {
            ...base,
            padding: "16px 12px",
            fontSize: "14px",
            backgroundColor: isFocused ? colors.grey[100] : "white",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            fontWeight: 400,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden",
          };
        },
        noOptionsMessage: () => {
          return { display: "none" };
        },
        indicatorSeparator: () => {
          return { display: "none" };
        },
        dropdownIndicator: () => {
          return { display: "none" };
        },
      }}
    />
  );
};

export default forwardRef(CommandPaletteDropdown);
