import colors from "styles/colors";
import Text from "ui/typography/Text";

import { useMergedCounterpartiesModalContext } from "../../context";

import styles from "./MergedCounterpartiesListHeader.module.scss";

const MergedCounterpartiesListHeader = () => {
  const { originalCounterparties, counterparty } = useMergedCounterpartiesModalContext();

  return (
    <div className={styles.container}>
      <Text color={colors.grey[600]} size={14}>
        <Text weight="medium" as="strong">
          {counterparty}
        </Text>{" "}
        is made up of {originalCounterparties.length} vendors:
      </Text>
    </div>
  );
};

export default MergedCounterpartiesListHeader;
