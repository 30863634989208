import checkFront from "assets/check-front.svg";
import CaptureCheck from "pages/DepositCheckPage/CaptureCheck";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import checkDepositState from "state/checkDeposit";

type Props = {
  onPrevPress: () => void;
  onNextPress: () => void;
};

const config = {
  pageTitle: "Front of check",
  stepNumber: 2,
  direction: "front",
  icon: checkFront,
  mitekDocumentType: "CHECK_FRONT",
};

const FrontOfCheck: React.FC<Props> = ({ onPrevPress, onNextPress }) => {
  const [deposit, setDeposit] = useRecoilState(checkDepositState);

  const [currentImage, setCurrentImage] = useState(deposit.front.image);
  const [validation, setValidation] = useState(deposit.front.validation);
  const [selectedOption, setSelectedOption] = useState(deposit.front.selectedOption);

  useEffect(() => {
    setDeposit({
      ...deposit,
      front: {
        ...deposit.front,
        image: currentImage,
        validation: validation,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage, validation]);

  // Saves the selected option for the next screen to make flow easier for user.
  useEffect(() => {
    if (deposit.back.image === null) {
      setDeposit({
        ...deposit,
        front: { ...deposit.front, selectedOption: selectedOption, image: currentImage },
        back: { ...deposit.back, selectedOption: selectedOption },
      });
    } else {
      setDeposit({
        ...deposit,
        front: { ...deposit.front, selectedOption: selectedOption, image: currentImage },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <CaptureCheck
      config={config}
      currentImage={currentImage}
      setCurrentImage={setCurrentImage}
      validation={validation}
      setValidation={setValidation}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      onPrevPress={onPrevPress}
      onNextPress={onNextPress}
    ></CaptureCheck>
  );
};

export default FrontOfCheck;
