import blueRidgeEndorsementSvg from "assets/check-deposit-endorsement-blue-ridge.svg";
import threadEndorsementSvg from "assets/check-deposit-endorsement-thread.svg";
import { OptionConfig } from "pages/DepositCheckPage/CaptureCheck";
import { useState } from "react";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import CheckboxLabel from "ui/inputs/CheckboxLabel";
import StepHeader from "ui/navigation/Steps/StepHeader";
import Text from "ui/typography/Text";

import styles from "./BackOfCheck.module.scss";

type Props = {
  config: OptionConfig;
  onPrevPress: () => void;
  onNextPress: () => void;
  isThreadAccount: boolean;
};

const EndorsementVerification: React.FC<Props> = ({
  config,
  onPrevPress,
  onNextPress,
  isThreadAccount,
}) => {
  const [isEndorsementVerified, setIsEndorsementVerified] = useState(false);
  const isNextStepAllowed = isEndorsementVerified;

  return (
    <>
      <StepHeader
        stepNumber={config.stepNumber}
        disableBackButton={false}
        handleBackButtonClick={() => {
          onPrevPress();
        }}
        handleForwardButtonClick={() => {
          onNextPress();
        }}
        disableForwardButton={!isNextStepAllowed}
        title={config.pageTitle}
      />
      <div className={styles.container}>
        <div className={styles.endorsementContainer}>
          <Text size={14} color={colors.purple[600]} weight="bold">
            Write this on the back of the check and sign it.
          </Text>
          <img
            src={isThreadAccount ? threadEndorsementSvg : blueRidgeEndorsementSvg}
            alt="endorsement example"
          />
        </div>

        <div className={styles.checkbox}>
          <CheckboxLabel
            label="I have endorsed and signed the check."
            checked={isEndorsementVerified}
            onChange={() => {
              setIsEndorsementVerified(!isEndorsementVerified);
            }}
          />
        </div>
        <Button
          className={styles.navButtonsContainerButton}
          type="submit"
          variant="primary"
          disabled={!isNextStepAllowed}
          onClick={onNextPress}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default EndorsementVerification;
