import { BillPaymentMethod } from "../queries/useBillPaymentMethods";

const isBillPaymentMethod = (data: unknown): data is BillPaymentMethod => {
  return (
    typeof data === "object" &&
    data !== null &&
    "billPaymentMethodType" in data &&
    "payeePaymentMethod" in data
  );
};

export default isBillPaymentMethod;
