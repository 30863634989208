import { ArrowDownRight, ArrowUpRight, Check, MagnifyingGlass } from "@phosphor-icons/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import classNames from "classnames";
import Fuse from "fuse.js";
import { groupBy } from "lodash-es";
import { CashFlowTab } from "pages/CashFlowPage/providers/CashFlowProvider";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import useUpdateCounterpartyCategoryMutation from "resources/counterparty-report/mutations/useUpdateCounterpartyCategoryMutation";
import useInsightsCategoriesQueryOptions from "resources/counterparty-report/queries/useInsightsCategoriesQueryOptions";
import Menu from "ui/overlay/Menu";
import Text from "ui/typography/Text";

import styles from "./CashFlowCategoryMenu.module.scss";

type Props = {
  value: string;
  counterparty: string;
  initialDirection: CashFlowTab;
  open: boolean;
};

const CashFlowCategoryMenuContent: FC<Props> = ({
  value,
  counterparty,
  initialDirection,
  open,
}) => {
  const [search, setSearch] = useState("");
  const [direction, setDirection] = useState<CashFlowTab>(initialDirection);

  const { data: insightsCategoriesByCategoryEntries } = useSuspenseQuery({
    ...useInsightsCategoriesQueryOptions(),
    select: (categories) => {
      const directionFilteredCategories = categories.filter((category) =>
        direction === "money-in"
          ? category.groupDirectionValue === "In" ||
            category.groupDirectionValue === "Bidirectional"
          : category.groupDirectionValue === "Out" ||
            category.groupDirectionValue === "Bidirectional"
      );

      const fuse = new Fuse(directionFilteredCategories, {
        threshold: 0.3,
        keys: ["subcategoryDisplayName"],
        shouldSort: false,
      });

      const searchFilteredCategories = search
        ? fuse.search(search).map((result) => result.item)
        : directionFilteredCategories;

      const categoriesSortedByCategoryDisplayName = searchFilteredCategories.sort((a, b) => {
        return a.categoryDisplayName.localeCompare(b.categoryDisplayName);
      });
      return Object.entries(
        groupBy(categoriesSortedByCategoryDisplayName, (category) => category.categoryDisplayName)
      );
    },
  });

  const { mutateAsync: updateCounterpartyCategory } = useUpdateCounterpartyCategoryMutation({
    counterparty,
  });

  // Auto-focus input on open
  const searchRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (open && searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef, open]);

  return (
    <>
      <div className={styles.inputContainer}>
        <input
          className={styles.input}
          placeholder="Search categories"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          autoFocus
          ref={searchRef}
        />
        <MagnifyingGlass size={20} />
      </div>

      <div className={styles.tabsContainer}>
        <div
          className={classNames(styles.tab, { [styles.active]: direction === "money-in" })}
          onClick={() => setDirection("money-in")}
        >
          <ArrowDownRight size={20} /> Money in
        </div>
        <div
          className={classNames(styles.tab, { [styles.active]: direction === "money-out" })}
          onClick={() => setDirection("money-out")}
        >
          <ArrowUpRight size={20} /> Money out
        </div>
      </div>

      <div className={styles.list}>
        {insightsCategoriesByCategoryEntries.map(([categoryDisplayName, categories]) => (
          <Fragment key={categoryDisplayName}>
            <div className={styles.categoryTitle} key={categoryDisplayName}>
              <Text size={12}>{categoryDisplayName}</Text>
            </div>

            {categories.map((category) => {
              const isCurrentlySelected = value === category.value;

              return (
                <Menu.Item
                  variant="ghost"
                  key={category.value}
                  className={classNames(styles.item, {
                    [styles.selectedCategory]: isCurrentlySelected,
                  })}
                  icon={isCurrentlySelected ? <Check /> : undefined}
                  onClick={async () => {
                    updateCounterpartyCategory({
                      category: category.value,
                    });
                  }}
                >
                  {category.subcategoryDisplayName}
                </Menu.Item>
              );
            })}
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default CashFlowCategoryMenuContent;
