import { FC } from "react";
import InternationalWiresMetadataListItems from "resources/international-wires/components/InternationalWiresMetadataListItems";
import { usePayeeQuery } from "resources/payees/queries/usePayee";
import usePaymentApproval from "resources/payment-approvals/queries/usePaymentApproval";
import getPaymentApprovalMethodName from "resources/payment-approvals/utils/getPaymentApprovalMethodName";
import MetadataList from "ui/data-display/MetadataList";
import Shimmer from "ui/feedback/Shimmer";
import Flexpane from "ui/overlay/Flexpane";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import Text from "ui/typography/Text";

import { usePaymentApprovalFlexpaneContext } from "../../context/PaymentApprovalFlexpaneProvider";

const LoadingMetadata = () => {
  return (
    <>
      <Shimmer total={2} />
      <Shimmer total={2} />
      <Shimmer total={2} />
    </>
  );
};

type PaymentApprovalInternationalWireMetadataProps = {
  payeeGuid: string;
  description: string;
};

const PaymentApprovalInternationalWireMetadata: FC<
  PaymentApprovalInternationalWireMetadataProps
> = ({ description, payeeGuid }) => {
  const { data: payee, isLoading } = usePayeeQuery(payeeGuid);

  if (isLoading) {
    return <LoadingMetadata />;
  }

  if (payee) {
    return (
      <MetadataList>
        <InternationalWiresMetadataListItems
          description={description}
          bicSwift={payee.internationalWireTransferMethod?.swift}
          iban={payee.internationalWireTransferMethod?.iban}
          accountNumber={payee.internationalWireTransferMethod?.accountNumber}
          bankCode={payee.internationalWireTransferMethod?.bankCode}
          branchCode={payee.internationalWireTransferMethod?.branchCode}
          bsbCode={payee.internationalWireTransferMethod?.bsbCode}
          clabe={payee.internationalWireTransferMethod?.clabe}
          cnaps={payee.internationalWireTransferMethod?.cnaps}
          ifsc={payee.internationalWireTransferMethod?.ifsc}
          sortCode={payee.internationalWireTransferMethod?.sortCode}
          payeeType={payee.internationalWireTransferMethod?.entity.entityType}
          address={payee.internationalWireTransferMethod?.address}
        />
      </MetadataList>
    );
  }

  return <Text size={14}>International wire metadata could not be found.</Text>;
};

type PaymentApprovalUnitPaymentMetadataProps = {
  description: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string | null;
};

const PaymentApprovalUnitPaymentMetadata: FC<PaymentApprovalUnitPaymentMetadataProps> = ({
  description,
  routingNumber,
  accountNumber,
  accountType,
}) => {
  return (
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{description}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <ItemWithCopyTextTooltip textToCopy={routingNumber}>
            {routingNumber}
          </ItemWithCopyTextTooltip>
        </MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <ItemWithCopyTextTooltip textToCopy={accountNumber}>
            {accountNumber}
          </ItemWithCopyTextTooltip>
        </MetadataList.ItemValue>
      </MetadataList.Item>
      {accountType && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Account type</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{accountType}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
    </MetadataList>
  );
};

const PaymentApprovalMetadata = () => {
  const { paymentApprovalGuid } = usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });
  const { description, payeeGuid } = paymentApproval;
  const longMethodName = getPaymentApprovalMethodName(paymentApproval).long;

  const Metadata = () => {
    switch (paymentApproval.type) {
      case "international-wire":
        return (
          <PaymentApprovalInternationalWireMetadata
            description={paymentApproval.paymentReason}
            payeeGuid={payeeGuid}
          />
        );
      case "unit-payment":
        return (
          <PaymentApprovalUnitPaymentMetadata
            description={description}
            routingNumber={paymentApproval.unitPayload.attributes.counterparty.routingNumber}
            accountNumber={paymentApproval.unitPayload.attributes.counterparty.accountNumber}
            accountType={
              paymentApproval.unitPayload.type === "achPayment"
                ? paymentApproval.unitPayload.attributes.counterparty.accountType
                : null
            }
          />
        );
    }
  };

  return (
    <>
      <Flexpane.SectionHeading>{longMethodName} details</Flexpane.SectionHeading>

      <Metadata />
    </>
  );
};

export default PaymentApprovalMetadata;
