import { AccountEndOfDay } from "@highbeam/unit-node-sdk";
import { DetailedAreaChartDatum } from "ui/data-visualization/DetailedAreaChart";
import { startOfBankingDay } from "utils/date";

export const BANK_ACCOUNT_NUM_DAYS_HISTORY = 30;

const getHistoryWithTodayForMultipleAccounts = (
  accountEndOfDay: AccountEndOfDay[][],
  bankAccountsTotalBalance: number
) => {
  const history = accountEndOfDay
    .reduce((result: DetailedAreaChartDatum[], history) => {
      if (result.length > history.length) {
        return result.map((datum, i) => ({
          date: datum.date,
          amount: (history[i]?.attributes.available || 0) + datum.amount,
        }));
      } else {
        return history.map((point, i) => ({
          date: startOfBankingDay(point.attributes.date, { format: "YYYY-MM-DD" }).format("MMMM D"),
          amount: point.attributes.available + (result[i]?.amount || 0),
        }));
      }
    }, [])
    .reverse();
  if (history.length === 0) {
    history.push({
      date: startOfBankingDay().format("MMMM D"),
      amount: 0,
    });
  }
  const numPaddedDays = Math.max(BANK_ACCOUNT_NUM_DAYS_HISTORY - history.length, 0);
  const paddedHistory = [
    ...[...Array(numPaddedDays)].map((_, i) => ({
      date: startOfBankingDay(history[0].date, { format: "MMMM D" })
        .subtract(numPaddedDays - i, "d")
        .format("MMMM D"),
      amount: 0,
    })),
    ...history,
  ];
  const historyWithToday = [
    ...paddedHistory,
    {
      date: startOfBankingDay(history[history.length - 1].date, { format: "MMMM D" })
        .add(1, "d")
        .format("MMMM D"),
      amount: bankAccountsTotalBalance,
    },
  ];
  return historyWithToday;
};

export default getHistoryWithTodayForMultipleAccounts;
