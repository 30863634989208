import {
  getInternationalWireCountry,
  getInternationalWirePrefillInfo,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import PayeeRep from "reps/PayeeRep";
import usePayee from "resources/payees/queries/usePayee";
import { internationalAddressState } from "state/payments/international/address";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import { internationalEntityState } from "state/payments/international/entity";
import { isLocalPaymentState } from "state/payments/international/isLocalPayment";
import { isValidInternationalPaymentState } from "state/payments/international/isValidInternationalPayment";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import { selectedBankCountryOptionState } from "state/payments/international/selectedBankCountry";
import { EntityIndividual } from "utils/entity";

/**
 * NB(alex): ⛔️ Please do not copy the pattern in this file. It is bad code needed for shipping bill pay. I consolidated all of the recoil state & hacks into one place.
 */

type Params = {
  payeeGuid?: string;
};

const useInternationalWirePaymentMethodRecoilHelpers = ({ payeeGuid }: Params) => {
  const payee = usePayee(payeeGuid);

  const [entity, setEntity] = useRecoilState(internationalEntityState);
  const [internationalAddress, setInternationalAddress] = useRecoilState(internationalAddressState);
  const [internationalBankingInfo, setInternationalBankingInfo] = useRecoilState(
    internationalBankingInfoState
  );
  const setSelectedBankCountryOption = useSetRecoilState(selectedBankCountryOptionState);

  const setInternationalQuoteCurrencyOption = useSetRecoilState(quoteCurrencyState);

  const isValidInternationalPayment = useRecoilValue(isValidInternationalPaymentState);

  const isValidEntity = Boolean(
    entity.entityType === EntityIndividual
      ? entity.firstName && entity.lastName
      : entity.companyName
  );

  const isValidInternational = isValidEntity && isValidInternationalPayment;

  const currency = useRecoilValue(quoteCurrencyState);
  const isLocalPayment = useRecoilValue(isLocalPaymentState);
  const country = getInternationalWireCountry(internationalAddress.country?.value ?? ""); // NB(alex): This is not type-safe.

  const internationalWireTransferMethod: {
    internationalWireTransferMethod: PayeeRep.InternationalWireTransferMethod;
  } | null = isValidInternational
    ? {
        internationalWireTransferMethod: {
          type: isLocalPayment ? country.local.deliveryMethod : "Priority",
          entity: {
            entityType: entity.entityType,
            firstName: entity.firstName,
            lastName: entity.lastName,
            companyName: entity.companyName,
            companyBankHolderName: entity.companyBankHolderName
              ? entity.companyBankHolderName
              : undefined,
          },
          address: {
            addressLine1: internationalAddress.addressLine1!.value, // NB(alex): `isValidInternationalPayment` seems to guard against this.
            addressLine2: internationalAddress.addressLine2
              ? internationalAddress.addressLine2
              : undefined,
            city: internationalAddress.city,
            state: internationalAddress.state?.value ?? "",
            zipCode: internationalAddress.zipCode,
            country: internationalAddress.country?.value!,
          },
          accountNumber: internationalBankingInfo.accountNumber?.value,
          bankCode: internationalBankingInfo.bankCode?.value,
          branchCode: internationalBankingInfo.branchCode?.value,
          bsbCode: internationalBankingInfo.bsbCode?.value,
          clabe: internationalBankingInfo.clabe?.value,
          cnaps: internationalBankingInfo.cnaps?.value,
          iban: internationalBankingInfo.iban?.value,
          ifsc: internationalBankingInfo.ifsc?.value,
          sortCode: internationalBankingInfo.sortCode?.value,
          swift: internationalBankingInfo.swift?.value,
          currency: currency.value,
        },
      }
    : null;

  const resetInternationalEntityState = useResetRecoilState(internationalEntityState);
  const resetAddress = useResetRecoilState(internationalAddressState);
  const resetInternationalBankingInfo = useResetRecoilState(internationalBankingInfoState);
  const resetBankCountry = useResetRecoilState(selectedBankCountryOptionState);

  const resetState = () => {
    resetInternationalEntityState();
    resetBankCountry();
    resetAddress();
    resetInternationalBankingInfo();
  };

  const prefillFormWithPayeeInternationalInfo = (payee: PayeeRep.Complete) => {
    const info = payee.internationalWireTransferMethod;
    if (info) {
      const { entity, address, bankingInfo, currency } = getInternationalWirePrefillInfo(info);
      setEntity(entity);
      setInternationalAddress(address);
      setInternationalBankingInfo(bankingInfo);
      setInternationalQuoteCurrencyOption(currency);
      if (info.bankCountry) {
        setSelectedBankCountryOption(getInternationalWireCountry(info.bankCountry));
      }
    } else {
      // If the payee has no international wire transfer method, reset the state.
      resetState();
    }
  };

  useEffect(() => {
    if (payee) {
      prefillFormWithPayeeInternationalInfo(payee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payee]);

  // NB(alex): This is a total hack. We need a way to tell if the form is dirty / has input values so we can determine if we want to block form submission if `!isValidInternational`.
  const hasInputValues = Boolean(
    entity.firstName ||
      entity.lastName ||
      entity.companyName ||
      entity.companyBankHolderName ||
      internationalAddress.addressLine1?.value ||
      internationalBankingInfo.accountNumber?.value ||
      internationalBankingInfo.bankCode?.value ||
      internationalBankingInfo.branchCode?.value ||
      internationalBankingInfo.bsbCode?.value ||
      internationalBankingInfo.clabe?.value ||
      internationalBankingInfo.cnaps?.value ||
      internationalBankingInfo.iban?.value ||
      internationalBankingInfo.ifsc?.value ||
      internationalBankingInfo.sortCode?.value ||
      internationalBankingInfo.swift?.value
  );

  return { internationalWireTransferMethod, isValidInternational, hasInputValues };
};

export default useInternationalWirePaymentMethodRecoilHelpers;
