import { CalendarBlank, CircleHalf, Clock, CurrencyDollar } from "@phosphor-icons/react";
import { FC } from "react";
import BillSummaryRep from "reps/BillSummaryRep";
import Pill from "ui/data-display/Pill";

type Props = {
  bill: BillSummaryRep.Complete;
};

const BillPaymentStatusPill: FC<Props> = ({ bill }) => {
  switch (bill.paymentStatus) {
    case BillSummaryRep.PaymentStatus.Overdue:
      return (
        <Pill
          color="orange-legacy"
          iconLeft={({ sizeClassName }) => <Clock className={sizeClassName} />}
        >
          Overdue
        </Pill>
      );
    case BillSummaryRep.PaymentStatus.Paid:
      return (
        <Pill
          color="green-light"
          iconLeft={({ sizeClassName }) => <CurrencyDollar className={sizeClassName} />}
        >
          Paid
        </Pill>
      );
    case BillSummaryRep.PaymentStatus.PartiallyPaid:
      // TODO(alex): Use % once backend gives percentage.
      return (
        <Pill
          color="grey-light"
          iconLeft={({ sizeClassName }) => <CircleHalf className={sizeClassName} />}
        >
          Partially paid
        </Pill>
      );
    case BillSummaryRep.PaymentStatus.Scheduled:
      return (
        <Pill
          color="purple-light"
          iconLeft={({ sizeClassName }) => <CalendarBlank className={sizeClassName} />}
        >
          Scheduled
        </Pill>
      );
    case BillSummaryRep.PaymentStatus.Pending:
      return (
        <Pill color="white" bordered>
          In progress
        </Pill>
      );
    case BillSummaryRep.PaymentStatus.Unpaid:
      return <Pill color="grey-light">Unpaid</Pill>;
  }
};

export default BillPaymentStatusPill;
