import { ArrowClockwise } from "@phosphor-icons/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import showConfirmResetModalState from "state/capital/creditCalculator/showConfirmResetModal";
import offerInputsState from "state/compareCreditOffers/inputs/offerInputs/offerInputs";
import Button from "ui/inputs/Button";

import filterOfferInputsWithoutProviderName from "../utils/filterOfferInputsWithoutProviderName";

const ResetComparisonButton = () => {
  const offerInputs = useRecoilValue(offerInputsState);
  const offerInputsWithProviderName = filterOfferInputsWithoutProviderName(offerInputs);
  const hasResponses = offerInputsWithProviderName.length > 0;
  const setShowConfirmResetModal = useSetRecoilState(showConfirmResetModalState);

  const handleReset = () => {
    setShowConfirmResetModal(true);
  };

  return (
    <Button variant="tertiary" onClick={handleReset} disabled={!hasResponses}>
      <ArrowClockwise />
      Reset
    </Button>
  );
};

export default ResetComparisonButton;
