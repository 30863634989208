import { FC } from "react";
import { Heading4, Span } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

const SummaryArray: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <div
    className={cn(
      "flex flex-col overflow-hidden rounded-lg border border-grey-200 tablet:flex-row",
      className
    )}
  >
    {children}
  </div>
);

const SummaryArrayItem: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "w-full border-grey-200 px-6 py-6",
        "border-b last-of-type:border-b-0 tablet:border-b-0", // Border overrides on mobile
        "tablet:border-r tablet:last-of-type:border-r-0", // Border overrides on non-mobile
        className
      )}
    >
      {children}
    </div>
  );
};

const SummaryArrayHeading: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <Heading4
      className={cn(
        "text-sm font-regular",
        "flex items-center gap-x-2", // Default spacing styles for info icons, which are common.
        className
      )}
    >
      {children}
    </Heading4>
  );
};

const SummaryArrayValue: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <Span className={cn("text-2xl font-medium", className)}>{children}</Span>;
};

export default Object.assign(SummaryArray, {
  Item: SummaryArrayItem,
  Heading: SummaryArrayHeading,
  Value: SummaryArrayValue,
});
