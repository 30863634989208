import { data as currencyCodeData, CurrencyCodeRecord } from "currency-codes";

/**
 * NB(alex):
 * This is a temporary hack to determine how many decimal places to show for a given currency code.
 * Please do not modify this file without consulting me first!
 */

const currencyCodeDataMap = Object.fromEntries(currencyCodeData.map((d) => [d.code, d]));

const MISSING_CURRENCIES_THAT_ARE_SUPPORTED_BY_INTERNATIONAL_WIRES = {
  MRO: currencyCodeDataMap.MRU,
  STD: currencyCodeDataMap.STN,
  VEF: currencyCodeDataMap.VES,
} satisfies { [key: string]: CurrencyCodeRecord };

const allCurrencies = {
  ...currencyCodeDataMap,
  ...MISSING_CURRENCIES_THAT_ARE_SUPPORTED_BY_INTERNATIONAL_WIRES,
};

const getCurrencyDecimalPlaces = (currencyCode: string): number => {
  // THIS IS NOT TYPE-SAFE! It relies on ./getCurrencyDecimalPlaces.test.ts to ensure all supported currencies are accounted for.
  // We eventually want to use a string union `CurrencyCode`, but this is the solution we must use for now, while we are dependent on `supportedInternationalWireCountries` as a source of truth.
  return allCurrencies[currencyCode as keyof typeof allCurrencies].digits;
};

export default getCurrencyDecimalPlaces;
