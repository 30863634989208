import { FC, ReactNode } from "react";
import Pill from "ui/data-display/Pill";
import Text from "ui/typography/Text";

type Props = {
  fullName: ReactNode;
  userRoleName?: string;
  isOnboarded: boolean;
};

const BusinessMemberDropdownLabel: FC<Props> = ({ fullName, userRoleName, isOnboarded }) => {
  return (
    <Text className="flex items-center gap-x-2 text-sm text-grey-900">
      {fullName}
      {userRoleName && <Pill color="grey-light">{userRoleName}</Pill>}
      {!isOnboarded && <Pill color="grey-light">Onboarding</Pill>}
    </Text>
  );
};

export default BusinessMemberDropdownLabel;
