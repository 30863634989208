import ArchiveBusinessTaskRep from "reps/ArchiveBusinessTaskRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ArchiveBusinessApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async archive(creator: ArchiveBusinessTaskRep.Creator): Promise<void> {
    const url = "/business-management/archive-business";
    return (await this.api.post(url, creator))!;
  }
}
