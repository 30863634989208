import { Info, WarningCircle } from "@phosphor-icons/react";
import { ComponentProps } from "react";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

export type InfoIconColor = "primary" | "dark" | "light" | "warning";
export type IconType = "info" | "warning";

type Props = Omit<ComponentProps<typeof ItemWithTooltip>, "children"> & {
  color?: InfoIconColor;
  icon?: IconType;
  className?: string;
};

const IconWithTooltip: React.FC<Props> = ({
  color = "primary",
  icon = "info",
  className,
  ...itemWithTooltipProps
}) => {
  const Icon = variants(icon, {
    info: Info,
    warning: WarningCircle,
  });

  return (
    <ItemWithTooltip asChild {...itemWithTooltipProps}>
      <Icon
        className={cn(
          "cursor-pointer",
          variants(color, {
            primary: "text-purple-500",
            dark: "text-grey-900",
            warning: "text-yellow-700",
            light: "text-grey-500",
          }),
          className
        )}
        size={16}
      />
    </ItemWithTooltip>
  );
};

export default IconWithTooltip;
