import { CheckCircle } from "@phosphor-icons/react";
import HighbeamApi from "api/HighbeamApi";
import { PaymentMethod } from "pages/SendMoneyPage/utils";
import colors from "styles/colors";
import { OnTextInputChange } from "ui/inputs/TextInput";
import Validator from "utils/validator";

export const onRoutingNumberChange =
  (highbeamApi: HighbeamApi, paymentMethod: PaymentMethod) =>
  async (routingNumber: string): Promise<OnTextInputChange> => {
    if (!routingNumber) {
      return {};
    }

    if (!Validator.routingNumber(routingNumber)) {
      return { errorMessage: "Routing number must be 9 digits." };
    }

    const institution = await highbeamApi.institution.get(routingNumber);
    if (!institution) {
      return { errorMessage: "No institution is associated with this routing number." };
    }

    switch (paymentMethod) {
      case PaymentMethod.ACH:
        if (!institution.isAchSupported) {
          return {
            errorMessage: `This ${institution?.name!} routing number does not support ACH transfers.`,
          };
        }
        break;
      case PaymentMethod.WIRE:
        if (!institution.isWireSupported) {
          return {
            errorMessage: `This ${institution?.name!} routing number does not support wires.`,
          };
        }
        break;
    }

    return {
      suffixValue: institution.name,
      icon: <CheckCircle weight="fill" color={colors.green[400]} size={20} />,
    };
  };
