import { Dayjs } from "dayjs";
import CapitalTransactionRep from "reps/CapitalTransactionRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CapitalTransactionsApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(
    businessGuid: string,
    capitalAccountGuid: string,
    sinceInclusive: Dayjs,
    untilInclusive: Dayjs
  ): Promise<CapitalTransactionRep.Complete[]> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      capitalAccountGuid: capitalAccountGuid,
      sinceInclusive: sinceInclusive.format("YYYY-MM-DD"),
      untilInclusive: untilInclusive.format("YYYY-MM-DD"),
    });
    const url = `/capital-transactions?${qp}`;
    return (await this.api.get<CapitalTransactionRep.Complete[]>(url))!;
  }

  async getCsv(
    businessGuid: string,
    capitalAccountGuid: string,
    sinceInclusive: Dayjs,
    untilInclusive: Dayjs
  ): Promise<string> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      capitalAccountGuid: capitalAccountGuid,
      sinceInclusive: sinceInclusive.format("YYYY-MM-DD"),
      untilInclusive: untilInclusive.format("YYYY-MM-DD"),
    });
    const url = `/capital-transactions?${qp}`;
    return (await this.api.get<string>(url, {
      Accept: "text/csv",
    }))!;
  }
}
