import { capitalize } from "lodash-es";
import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import CurrencyInputV3 from "ui/inputs/CurrencyInputV3";
import DropdownV2, { convertStringValueToDropdownOption } from "ui/inputs/DropdownV2";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import Helper from "ui/inputs/Helper";
import SwitchWithLabel from "ui/inputs/SwitchWithLabel";
import { getCentsFromDollars } from "utils/money";
import { z } from "zod";

const SPEND_LIMIT_PERIOD_OPTIONS = ["daily", "monthly"] as const;

export type SpendLimitPeriod = (typeof SPEND_LIMIT_PERIOD_OPTIONS)[number];

// Exemplar: z.union + transform
export const spendLimitSchema = z.union([
  z.object({
    enabled: z.literal(true),
    period: z.enum(SPEND_LIMIT_PERIOD_OPTIONS),
    amount: z
      .string()
      .transform((data) => getCentsFromDollars(data))
      .refine((data) => data > 0, {
        message: "Please enter an amount.",
      }),
  }),
  z.object({
    enabled: z.literal(false),
  }),
]);

const schema = z.object({
  spendLimit: spendLimitSchema,
});

export type SpendLimitFieldsetForm = UseFormReturn<
  z.input<typeof schema>,
  object,
  z.output<typeof schema>
>;

type Props = {
  className?: string;
  form: SpendLimitFieldsetForm;
};

const SpendLimitFieldset: FC<Props> = ({ form, className }) => {
  const spendLimitEnabled = form.watch("spendLimit.enabled");
  const selectedSpendLimitPeriod = form.watch("spendLimit.period");

  return (
    <FieldsetV2 className={className}>
      <Controller
        control={form.control}
        name="spendLimit.enabled"
        render={({ field }) => {
          return <SwitchWithLabel label="Spend limit" {...field} />;
        }}
      />

      {spendLimitEnabled && (
        <div className="flex gap-3">
          <Controller
            control={form.control}
            name="spendLimit.period"
            defaultValue="monthly"
            render={({ field }) => {
              return (
                <DropdownV2
                  options={SPEND_LIMIT_PERIOD_OPTIONS.map(convertStringValueToDropdownOption)}
                  {...field}
                  className="min-w-28"
                  getOptionLabel={(option) => capitalize(option.label)}
                  value={convertStringValueToDropdownOption(field.value)}
                  onChange={(value) => {
                    if (value) field.onChange(value.value);
                  }}
                />
              );
            }}
          />
          <Controller
            control={form.control}
            name="spendLimit.amount"
            defaultValue=""
            render={({ field, fieldState: { error } }) => {
              return (
                <div className="flex-1">
                  <CurrencyInputV3
                    label={`${capitalize(selectedSpendLimitPeriod)} spend limit`}
                    startAdornment="$"
                    showErrorOutline={Boolean(error)}
                    {...field}
                  />
                  {error && <Helper iconVariant="error">{error.message}</Helper>}
                </div>
              );
            }}
          />
        </div>
      )}
    </FieldsetV2>
  );
};

export default SpendLimitFieldset;
