import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
import CreditComparisonSnapshotRep from "reps/CreditComparisonSnapshotRep";

import snapshotState from "../../snapshot";

export type FeeInputType = "Percentage" | "DollarValue";

export type OfferInput = {
  providerName: CreditComparisonSnapshotRep.ProviderName | "";
  loanAmount: number | null;
  feeInputType: FeeInputType;
  fee: number | null;
  remittanceRate: number | null;
  weeklyCap: number | null;
};

export const defaultOfferInput: OfferInput = {
  providerName: "",
  loanAmount: null,
  feeInputType: "Percentage",
  fee: null,
  remittanceRate: null,
  weeklyCap: null,
};

const initializeOfferInputs = (prevOfferInputs: OfferInput[]): OfferInput[] =>
  prevOfferInputs.length === 0
    ? Array(3).fill(defaultOfferInput)
    : prevOfferInputs.length < 6
      ? [...prevOfferInputs, defaultOfferInput]
      : prevOfferInputs;

const convertOfferDataToOfferInput = (offer: CreditComparisonSnapshotRep.Offer): OfferInput => {
  const {
    providerName,
    loanAmount,
    fee: { amount },
    repaymentOption,
  } = offer;
  const { remittanceRate, weeklyCap } = repaymentOption;

  // NB(alex): we currently always store the fee as a dollar value, even if the user initially used a percentage.
  const feeInputType = "DollarValue";
  const fee = amount;

  return {
    providerName: providerName,
    loanAmount: loanAmount === null ? null : loanAmount / 100,
    feeInputType: feeInputType,
    fee: fee / 100,
    remittanceRate: remittanceRate,
    weeklyCap: weeklyCap === null ? null : weeklyCap / 100,
  };
};

const { persistAtom } = recoilPersist();

const offerInputsState = atom<OfferInput[]>({
  key: "compareCreditOffers/offerInputsState",
  default: selector<OfferInput[]>({
    key: "compareCreditOffers/offerInputsState/default",
    get: async ({ get }) =>
      get(snapshotState)?.offers.map(convertOfferDataToOfferInput) ?? initializeOfferInputs([]),
  }),
  effects: [persistAtom],
});

export default offerInputsState;
