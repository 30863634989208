import IntercomHashRep from "reps/IntercomHashRep";
import IntercomMetadataRep from "reps/IntercomMetadataRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class IntercomApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getHash(userGuid: string): Promise<IntercomHashRep> {
    const url = `/users/${userGuid}/intercom-hash`;
    return (await this.api.get<IntercomHashRep>(url))!;
  }

  async getMetadata(
    userGuid: string,
    businessGuid: string | undefined
  ): Promise<IntercomMetadataRep> {
    const queryParams = new URLSearchParams();
    if (businessGuid) queryParams.append("businessGuid", businessGuid);
    const url = `/users/${userGuid}/intercom-metadata?${queryParams}`;
    return (await this.api.get<IntercomMetadataRep>(url))!;
  }
}
