import { Children, cloneElement, ComponentProps, FC, ReactElement } from "react";
import PlatformAvatar from "ui/icons/PlatformAvatar";

import styles from "./PlatformAvatarGroup.module.scss";

type Props = {
  children: ReactElement<ComponentProps<typeof PlatformAvatar>>[];
};

const PlatformAvatarGroup: FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      {Children.map(children, (child, index) =>
        cloneElement(child, {
          className: styles.avatar,
          style: { zIndex: children.length - index }, // overlay ltr
        })
      )}
    </div>
  );
};

export default PlatformAvatarGroup;
