import { FC, ComponentPropsWithoutRef, useCallback } from "react";
import AccountingAccountRep from "reps/AccountingAccountRep";
import BankAccountRep from "reps/BankAccountRep";
import { BANK_ACCOUNT_TYPE } from "resources/accounting-accounts/utils/config";
import Combobox from "ui/inputs/Combobox";

import AccountingCategorySelect, { ItemRenderProps } from "../AccountingCategorySelect";

type Props = Omit<ComponentPropsWithoutRef<typeof Combobox>, "clearable"> & {
  bankAccount: BankAccountRep.Complete;
};

const BankAccountChartOfAccountSelect: FC<Props> = ({ bankAccount, ...props }) => {
  const filter = useCallback(
    (accountingAccount: AccountingAccountRep.Complete) =>
      accountingAccount.accountType === BANK_ACCOUNT_TYPE,
    []
  );

  const renderItem = useCallback(
    ({ accountingAccount }: ItemRenderProps) => {
      const { id, name, bankAccountGuid, status } = accountingAccount;
      const isActive = status === "active";
      const isAssociatedWithDifferentBankAccount =
        bankAccountGuid !== null && bankAccountGuid !== bankAccount.guid && isActive;

      return (
        <AccountingCategorySelect.Item
          key={id}
          value={id}
          searchableText={name}
          disabled={isAssociatedWithDifferentBankAccount || !isActive}
        >
          {name}
          {isAssociatedWithDifferentBankAccount && " (associated with another account)"}
        </AccountingCategorySelect.Item>
      );
    },
    [bankAccount]
  );

  return <AccountingCategorySelect filter={filter} renderItem={renderItem} clearable {...props} />;
};

export default BankAccountChartOfAccountSelect;
