import RutterConnectionRep from "reps/RutterConnectionRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class RutterConnectionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(businessGuid: string, publicToken: string): Promise<RutterConnectionRep.Complete> {
    const url = `/businesses/${businessGuid}/rutter-connections`;
    const body: RutterConnectionRep.Creation = { publicToken };
    return (await this.api.post<RutterConnectionRep.Complete>(url, body))!;
  }

  async getByBusiness(businessGuid: string): Promise<RutterConnectionRep.Complete[]> {
    const url = `/businesses/${businessGuid}/rutter-connections`;
    return (await this.api.get<RutterConnectionRep.Complete[]>(url))!;
  }

  async delete(
    businessGuid: string,
    connectionGuid: string
  ): Promise<RutterConnectionRep.Complete | null> {
    const url = `/businesses/${businessGuid}/rutter-connections/${connectionGuid}?softDelete=true`;
    return await this.api.delete<RutterConnectionRep.Complete>(url);
  }
}
