import { FC, ReactNode, createContext, useContext } from "react";

export type ModalSize = "standard" | "small";

export type ModalContextValue = {
  closeModalWithAnimation: () => void;
  size: ModalSize;
  formId: string;
};

const ModalContext = createContext<ModalContextValue>({} as ModalContextValue);

type ModalContextProviderProps = ModalContextValue & {
  children: ReactNode;
};

export const ModalContextProvider: FC<ModalContextProviderProps> = ({
  children,
  closeModalWithAnimation: close,
  size,
  formId,
}) => {
  return (
    <ModalContext.Provider
      value={{
        closeModalWithAnimation: close,
        formId,
        size,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModalContext = () => {
  const modalContext = useContext(ModalContext);
  if (modalContext === undefined) {
    throw new Error("useModalContext must be used within a ModalContextProvider");
  }
  return modalContext;
};

export default useModalContext;
