import classNames from "classnames";
import { ReactNode, useState } from "react";
import colors from "styles/colors";
import Radio from "ui/inputs/Radio";
import Text from "ui/typography/Text";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";

import styles from "./RadioCardSimple.module.scss";

// These props are overwritten so we need to omit them from the base input prop types
type OverlappingInputProps = "value" | "onChange" | "checked" | "ref";
type RadioInputPropsWithoutOverlapping = Omit<
  JSX.IntrinsicElements["input"],
  OverlappingInputProps
>;

type RadioCardSimpleProps<TValue extends string | number> = RadioInputPropsWithoutOverlapping & {
  value: TValue;
  label: ReactNode;
  disabled?: boolean;
  onChange: (value: TValue) => void;
  checked: boolean;
  helper?: ReactNode;
  inputRef?: React.Ref<HTMLInputElement>; // Do not use `forwardRef` or else we cannot use a generic directly. https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
};

const RadioCardSimple = <TValue extends string | number>({
  value,
  onChange,
  label,
  checked,
  inputRef,
  disabled,
  helper,
  ...inputProps
}: RadioCardSimpleProps<TValue>) => {
  const [isFocused, setIsFocused] = useState(false);

  // TODO(alex): we might have to pass in an id prefix as a namespace in case of conflicts?
  const inputId = `${value}`;

  // Selects the focused radio card when the space key is pressed
  useKeyboardEvent(
    (e) => e.code === "Space" || e.code === "Enter", // NB(alex): I actually want this to submit the form if a user presses `Enter` but I can't get it to work :/
    () => !disabled && isFocused && onChange(value),
    [disabled, isFocused, value, onChange]
  );

  return (
    <label
      htmlFor={inputId}
      className={classNames(styles.container, {
        [styles.checked]: checked,
        // TODO(alex): Do we need this style? Removed while adding typescript types.
        // [styles.focused]: !checked && isFocused,
        [styles.containerDisabled]: disabled,
      })}
      tabIndex={disabled ? -1 : 0}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <Radio
        ref={inputRef}
        tabIndex={-1} // Prevents focusing the radio button, since we want the containing label to show focus instead.
        {...inputProps}
        id={inputId}
        checked={checked}
        disabled={disabled}
        value={value}
        onChange={() => onChange(value)}
      />

      <div className={styles.labelContainer}>
        <Text color={colors.grey[900]} size={14} weight="medium" className={styles.label}>
          {label}
        </Text>
      </div>

      {helper !== undefined && (
        <div>
          <Text size={14} color={colors.grey[500]}>
            {helper}
          </Text>
        </div>
      )}
    </label>
  );
};

export default RadioCardSimple;
