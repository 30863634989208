import loadingAnimation from "assets/loading-highbeam.gif";
import FullPageLayout from "layouts/FullPageLayout";
import useDebugSettings from "modules/debug/useDebugSettings";
import HighbeamLogo from "ui/icons/HighbeamLogo";
import Text from "ui/typography/Text";

/**
 * This full-page loading page should only be used when the entire app is loading. If an individual
 * component is loading, a local loading state is more appropriate.
 */

type Props = {
  location: string;
  text?: string;
  showLoadingAnimation?: boolean;
};

const LoadingPage: React.FC<Props> = ({
  location,
  text = "Loading...",
  showLoadingAnimation = true,
}) => {
  const { showDebugMessages } = useDebugSettings();

  return (
    <FullPageLayout backgroundImage="none" header={<HighbeamLogo withText className="mt-8" />}>
      <div className="flex flex-1 flex-col items-center justify-center pb-12">
        {showLoadingAnimation && <img className="mb-2 h-24" src={loadingAnimation} alt="Loading" />}
        <Text size={14} align="center" weight="medium">
          {text}
        </Text>
        {showDebugMessages && (
          <Text size={14} align="center" weight="medium">
            [Dev only] Loading from {location}
          </Text>
        )}
      </div>
    </FullPageLayout>
  );
};

export default LoadingPage;
