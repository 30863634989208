import { ArrowElbowDownRight } from "@phosphor-icons/react";
import { FC } from "react";
import SubcategoryRep from "reps/Insights/SubcategoryRep";
import Text from "ui/typography/Text";

import styles from "./TransactionSubcategoryDropdownLabel.module.scss";

type Props = {
  subcategory: SubcategoryRep.Complete;
};

const TransactionSubcategoryDropdownLabel: FC<Props> = ({ subcategory }) => {
  return (
    <div>
      <div className={styles.category}>
        <Text size={14}>{subcategory.category.displayName}</Text>
      </div>
      <div className={styles.subcategory}>
        <ArrowElbowDownRight size={12} />
        <Text className={styles.subcategory} size={12}>
          {subcategory.displayName}
        </Text>
      </div>
    </div>
  );
};

export default TransactionSubcategoryDropdownLabel;
