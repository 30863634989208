import colors from "styles/colors";

type Props = {
  size?: number;
  color?: string;
};

const BillPayIcon: React.FC<Props> = ({ size = 16, color = colors.grey[600] }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
    <path
      d="M12.5 2.5V6.80769H16.8077"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.125 5V3.125H12.2411L16.1827 7.06658V16.6442H14V17.8942H16.1923C16.5213 17.8942 16.8368 17.7635 17.0694 17.5309C17.302 17.2983 17.4327 16.9828 17.4327 16.6538V6.80769C17.4327 6.64193 17.3668 6.48296 17.2496 6.36575L12.9419 2.05806C12.8247 1.94085 12.6658 1.875 12.5 1.875H5.11538C4.78641 1.875 4.47092 2.00568 4.2383 2.2383C4.00568 2.47092 3.875 2.78641 3.875 3.11538V5H5.125Z"
      fill={color}
    />
    <path
      d="M6.5 10.082V10.9154"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 15.082V15.9154"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 18C9.26142 18 11.5 15.7614 11.5 13C11.5 10.2386 9.26142 8 6.5 8C3.73858 8 1.5 10.2386 1.5 13C1.5 15.7614 3.73858 18 6.5 18Z"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25065 15.0846H7.12565C7.40192 15.0846 7.66687 14.9749 7.86222 14.7795C8.05757 14.5842 8.16732 14.3192 8.16732 14.043C8.16732 13.7667 8.05757 13.5017 7.86222 13.3064C7.66687 13.111 7.40192 13.0013 7.12565 13.0013H5.87565C5.59938 13.0013 5.33443 12.8916 5.13908 12.6962C4.94373 12.5009 4.83398 12.2359 4.83398 11.9596C4.83398 11.6834 4.94373 11.4184 5.13908 11.2231C5.33443 11.0277 5.59938 10.918 5.87565 10.918H7.75065"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="4.49414" cy="5.02344" r="0.625" fill={color} />
    <circle cx="14" cy="17.2734" r="0.625" fill={color} />
  </svg>
);

export default BillPayIcon;
