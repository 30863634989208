import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import BillDocumentRep from "reps/BillDocumentRep";
import getFileTypeFromBlob from "utils/blob/getFileTypeFromBlob";
import { downloadWith404Retry } from "utils/download";
import useQueryOptions from "utils/react-query/useQueryOptions";

const BILL_DOCUMENT_DATA_QUERY_KEY = "bill-document-data";

type Params = {
  billDocument: BillDocumentRep.Complete;
};

const useBillDocumentDataQueryOptions = ({ billDocument }: Params) => {
  return useQueryOptions({
    queryKey: [BILL_DOCUMENT_DATA_QUERY_KEY, billDocument.signedDocumentUrl],
    queryFn: async () => {
      const blob = await downloadWith404Retry(billDocument.signedDocumentUrl);
      const fileType = await getFileTypeFromBlob(blob);

      return {
        documentFileName: billDocument.originalFileName,
        fileType: fileType,
        src: window.URL.createObjectURL(blob),
        alt: billDocument.type,
        billDocumentId: billDocument.id,
      };
    },
    staleTime: Infinity,
  });
};

export const useBillDocumentDataQuery = (params: Params) => {
  return useQuery(useBillDocumentDataQueryOptions(params));
};

const useBillDocumentData = (params: Params) => {
  const { data } = useSuspenseQuery(useBillDocumentDataQueryOptions(params));
  return data;
};

export default useBillDocumentData;
