import classNames from "classnames";
import getSymbolFromCurrency from "currency-symbol-map";
import { FC } from "react";
import { formatValue } from "react-currency-input-field";
import Text, { TextProps } from "ui/typography/Text";
import { TextSize, TextWeight } from "ui/typography/Text/TextTypes";
import getCurrencyDecimalPlaces from "utils/money/getCurrencyDecimalPlaces";

import styles from "./MoneyAmount.module.scss";

type Props = TextProps & {
  amount: string;
  currencyCode: string; // NB(alex): Planning to update this to `CurrencyCode` eventually.
  showPlusMinusSign?: boolean;
  showCurrencySymbol?: boolean;
  showCents?: boolean;
  showTrailingCurrencyCode?: boolean; // NB(alex): Generally recommended to only enable this if `showCurrencySymbol` is `false`.
  centsTextSize?: TextSize;
  centsTextWeight?: TextWeight;
  currencyCodeTextSize?: TextSize;
  currencyCodeTextWeight?: TextWeight;
  currencyCodeTextColor?: string;
};

/**
 * Component for displaying an amount depending on its currency.
 */

const MoneyAmount: FC<Props> = ({
  amount,
  currencyCode,
  showPlusMinusSign,
  showCurrencySymbol,
  showTrailingCurrencyCode = false,
  showCents = true,
  size = 14,
  as = "span",
  centsTextSize = size,
  centsTextWeight,
  currencyCodeTextSize = centsTextSize,
  currencyCodeTextWeight = "bold",
  currencyCodeTextColor,
  ...textProps
}) => {
  const decimalPlaces = getCurrencyDecimalPlaces(currencyCode);

  // NB(alex): We don't internationalize comma / separators yet, but lmk if we need to!
  const formattedAmount = formatValue({
    value: amount,
    decimalScale: decimalPlaces,
  });

  const [dollars, cents] = formattedAmount.split(".");

  const isPositive = Number(amount) > 0;
  const isNegative = Number(amount) < 0;

  return (
    <Text numeric as={as} size={size} {...textProps}>
      {showPlusMinusSign && (isPositive || isNegative) && (
        <span
          className={classNames(styles.direction, {
            [styles.positive]: isPositive,
            [styles.negative]: isNegative,
          })}
        >
          {isPositive ? "+" : "−"}
        </span>
      )}

      {/* Show regular minus sign before symbol if `showPlusMinusSign` is `false` */}
      {!showPlusMinusSign && isNegative && "-"}

      {showCurrencySymbol && getSymbolFromCurrency(currencyCode)}

      {isNegative ? dollars.substring(1) : dollars}

      {cents && showCents && (
        <Text numeric as="span" weight={centsTextWeight} size={centsTextSize}>
          .{cents}
        </Text>
      )}

      {showTrailingCurrencyCode && (
        <Text
          as="span"
          className={styles.currencyCode}
          size={currencyCodeTextSize}
          weight={currencyCodeTextWeight}
          color={currencyCodeTextColor}
        >
          {currencyCode}
        </Text>
      )}
    </Text>
  );
};

export default MoneyAmount;
