import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { Navigate, useParams } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { RESULTS_STEP_BASE_PATH, resultsStepPathState } from "state/compareCreditOffers/allSteps";
import calculationsState from "state/compareCreditOffers/calculations";
import sessionGuidState from "state/compareCreditOffers/sessionGuid";
import snapshotState from "state/compareCreditOffers/snapshot";
import useMountEffect from "utils/customHooks/useMountEffect";
import { SEGMENT_EVENTS, useTrackPageMount } from "utils/customHooks/useSegment";

import CreditComparisonColumn from "./CreditComparisonColumn";
import styles from "./CreditComparisonTable.module.scss";
import CreditComparisonTitlesColumn from "./CreditComparisonTitlesColumn";
import HighbeamCreditComparisonColumn from "./HighbeamCreditComparisonColumn";

const CreditComparisonTable = () => {
  const { snapshotSlug } = useParams();
  const refetchSnapshot = useResetRecoilState(snapshotState);

  const sessionGuid = useRecoilValue(sessionGuidState);

  useTrackPageMount(SEGMENT_EVENTS.CAPITAL_CREDIT_COMPARISON_COMPARED, {
    sessionGuid,
    snapshotSlug,
  });

  // NB(alex): Decided casting snapshotSlug! is safe because if it is undefined it will redirect regardless.
  const calculations = useRecoilValue(calculationsState(snapshotSlug!));

  const setResultsStepPath = useSetRecoilState(resultsStepPathState);

  useMountEffect(() => {
    setResultsStepPath(`${RESULTS_STEP_BASE_PATH}/${snapshotSlug}`);
    refetchSnapshot();
  });

  if (!calculations) {
    return <Navigate to={CREDIT_COMPARISON_PATH} />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <CreditComparisonTitlesColumn />

        {calculations.map((calculation, index) => (
          <CreditComparisonColumn key={index} {...calculation} />
        ))}

        <HighbeamCreditComparisonColumn />
      </div>
    </div>
  );
};

export default CreditComparisonTable;
