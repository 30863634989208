import { CardStatus } from "@highbeam/unit-node-sdk";
import variants from "utils/ts/variants";

const getCardStatusCopy = (status: CardStatus): string => {
  return variants(status, {
    Active: "Active",
    Inactive: "Inactive",
    Stolen: "Stolen",
    Lost: "Lost",
    Frozen: "Locked",
    ClosedByCustomer: "Archived",
    SuspectedFraud: "Suspected fraud",
  });
};

export default getCardStatusCopy;
