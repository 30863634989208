import { Card } from "@highbeam/unit-node-sdk";
import classNames from "classnames";
import useCard from "resources/cards/queries/useCard";
import { getCardholderFirstName, getCardholderLastName } from "resources/cards/utils";
import {
  checkIsPhysicalCard,
  checkIsPhysicalCreditCard,
  checkIsPhysicalDebitCard,
  checkIsVirtualChargeCard,
} from "resources/cards/utils/typeguards";
import Text from "ui/typography/Text";
import getInitials from "utils/string/getInitials";

import styles from "./CardholderInitialsAndFullName.module.scss";

type CardholderInitialsAndFullNameProps = {
  cardId: string;
  useDefaultColor?: boolean;
};

const getCardInitialsClass = (card: Card, useDefaultColor: boolean) => {
  if (useDefaultColor) return styles.cardInitials;

  if (checkIsVirtualChargeCard(card)) {
    return styles.virtualChargeCardInitials;
  } else if (checkIsPhysicalCreditCard(card)) {
    return styles.physicalCreditCardInitials;
  } else if (checkIsPhysicalDebitCard(card)) {
    return styles.physicalDebitCardInitials;
  }
};

const CardholderInitialsAndFullName = ({
  cardId,
  useDefaultColor = false,
}: CardholderInitialsAndFullNameProps) => {
  const card = useCard({ cardId });

  if (!card) return null;

  const firstName = getCardholderFirstName(card);
  const lastName = getCardholderLastName(card);

  if (!firstName || !lastName) return null;

  const textColorClass =
    !useDefaultColor && checkIsPhysicalCard(card)
      ? styles.textColorPhysical
      : styles.textColorVirtual;

  return (
    <div className={styles.row}>
      <Text
        size={12}
        weight="medium"
        className={classNames(styles.cardInitials, getCardInitialsClass(card, useDefaultColor))}
      >
        {getInitials(`${firstName} ${lastName}`)}
      </Text>
      <Text size={12} weight="medium" className={textColorClass}>
        {firstName} {lastName}
      </Text>
    </div>
  );
};

export default CardholderInitialsAndFullName;
