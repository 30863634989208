import { ErrorBoundary } from "@sentry/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import ErrorText from "modules/error/components/ErrorText";
import PlaidConnectionsList from "modules/plaid/components/PlaidConnectionsList";
import { Navigate } from "react-router-dom";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToViewBankConnections from "utils/permissions/navigation/useIsAllowedToViewBankConnections";

const SettingsBanksAndCardsPageContent = () => {
  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to="/settings" />
        <DashboardPage.Header.Title>Banks and cards</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <ErrorBoundary
        fallback={({ error }) => (
          <DashboardPage.Section>
            <ErrorText error={error} />
          </DashboardPage.Section>
        )}
      >
        <DashboardPage.Section>
          <DashboardPage.Section.Body>
            <PlaidConnectionsList />
          </DashboardPage.Section.Body>
        </DashboardPage.Section>
      </ErrorBoundary>
    </>
  );
};

const SettingsBanksAndCardsPage = () => {
  const isAllowedToViewBankConnections = useIsAllowedToViewBankConnections();

  if (!isAllowedToViewBankConnections) {
    return <Navigate to="/settings" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/settings">Settings</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Banks and cards</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <SettingsBanksAndCardsPageContent />
      </DashboardPage>
    </>
  );
};

export default SettingsBanksAndCardsPage;
