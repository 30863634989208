import { FC } from "react";
import BankAccountRep from "reps/BankAccountRep";
import { HighbeamHighYieldIcon, HighbeamIcon, PlatformIconProps } from "ui/icons/platform-icons";
import variants from "utils/ts/variants";

export type HighbeamAccountTypeName = BankAccountRep.HighbeamAccountType["name"];

type Props = PlatformIconProps & {
  highbeamTypeName: HighbeamAccountTypeName;
};

const HighbeamAccountIcon: FC<Props> = ({ highbeamTypeName, ...platformIconProps }) => {
  const PlatformIcon = variants(highbeamTypeName, {
    DepositAccount: HighbeamIcon,
    HighYield: HighbeamHighYieldIcon,
  });

  return <PlatformIcon {...platformIconProps} />;
};

export default HighbeamAccountIcon;
