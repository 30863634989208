import { ArrowLineUpRight, Info } from "@phosphor-icons/react";
import RecurringPaymentDetailsCard from "components/RecurringPaymentDetailsCard";
import { Dayjs } from "dayjs";
import PayeeInfo, { Recipient } from "pages/SendMoneyPage/SendMoneySteps/PayeeInfo";
import {
  BankingInfo,
  PaymentMethod,
  getSendMoneyDeliveryMessage,
  InternationalBankingInfo,
  PaymentMethodOption,
} from "pages/SendMoneyPage/utils";
import Banner from "ui/data-display/Banner";
import Button from "ui/inputs/Button";
import StepHeader from "ui/navigation/Steps/StepHeader";
import { Paragraph } from "ui/typography";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";
import { TransferOption } from "utils/transfers";

type SendMoneyConfirmation = {
  from: TransferOption;
  amountInCents: number;
  to: Recipient;
  scheduledDate: Dayjs;
  bankingInfo?: BankingInfo;
  internationalBankingInfo?: InternationalBankingInfo;
  paymentMethodOption: PaymentMethodOption;
  invoiceName?: string;
};

type Props = {
  sendMoneyConfirmation: SendMoneyConfirmation;
  onNextPress: () => void;
  onPrevPress: () => void;
  isTransferLoading: boolean;
  numberOfPayments?: string;
};

const RecurringPaymentConfirmDetails: React.FC<Props> = ({
  sendMoneyConfirmation,
  onNextPress,
  onPrevPress,
  isTransferLoading,
  numberOfPayments,
}) => {
  const onTransferButtonClick = () => {
    onNextPress();
  };

  const getTotalRecurringPaymentAmount = () => {
    const totalAmount = getDollarsFromCents(
      Number(numberOfPayments) * sendMoneyConfirmation.amountInCents
    );
    const formattedTotalAmount = formatAmount(totalAmount);
    return formattedTotalAmount.split(".");
  };
  const [dollars, cents] = getTotalRecurringPaymentAmount();

  return (
    <>
      <StepHeader
        handleBackButtonClick={onPrevPress}
        disableBackButton={isTransferLoading}
        stepNumber={3}
        disableForwardButton
        title="Confirm details"
      />
      <RecurringPaymentDetailsCard
        iconBesideAmount={<ArrowLineUpRight size={16} />}
        amountInCents={sendMoneyConfirmation.amountInCents}
        from={sendMoneyConfirmation.from.label}
        to={
          <PayeeInfo
            to={sendMoneyConfirmation.to}
            infoText="The recipient will receive a confirmation email for this payment."
          />
        }
        scheduledDate={sendMoneyConfirmation.scheduledDate}
        routingNumber={sendMoneyConfirmation.bankingInfo?.routingNumber}
        accountNumber={
          sendMoneyConfirmation.paymentMethodOption?.value === PaymentMethod.INTERNATIONAL
            ? sendMoneyConfirmation.bankingInfo?.accountNumber
            : sendMoneyConfirmation.internationalBankingInfo?.accountNumber?.value
        }
        deliveryMessage={getSendMoneyDeliveryMessage(
          sendMoneyConfirmation.paymentMethodOption?.value as PaymentMethod,
          true
        )}
      />

      <Paragraph className="my-6 text-sm text-grey-600">
        By clicking Schedule payments, I authorize the above amount to be debited from the bank
        account for every monthly recurring payment starting on the specified date.
      </Paragraph>

      <Banner
        color="grey"
        padding="compact"
        className="border-none font-medium"
        paragraph={
          <>
            <span className="font-medium">{numberOfPayments} payments totaling</span>{" "}
            <span className="font-bold">
              {dollars}.{cents}.
            </span>
          </>
        }
      />

      <div className="mb-4 mt-5 flex flex-col-reverse gap-4 tablet:flex-row tablet:items-center">
        <Button onClick={onPrevPress} variant="tertiary" disabled={isTransferLoading}>
          Back
        </Button>
        <Button
          variant="primary"
          onClick={onTransferButtonClick}
          isLoading={isTransferLoading}
          autoFocus
        >
          {"Schedule payments"}
        </Button>
      </div>
      <div className="mt-8">
        <Banner
          icon={<Info />}
          color="blue"
          paragraph="Future payments that fall on weekends or bank holidays will be processed the following business day."
        />
      </div>
    </>
  );
};
export default RecurringPaymentConfirmDetails;
