import { Question } from "@phosphor-icons/react";
import React from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./HowToSwitchHeader.module.scss";

const HowToSwitchHeader: React.FC = () => {
  return (
    <div className={styles.container}>
      <Question size={16} color={colors.grey[900]} />
      <Text as="p" size={14} weight="bold" color={colors.grey[900]}>
        How to switch
      </Text>
    </div>
  );
};

export default HowToSwitchHeader;
