export const nth = (n: number) => {
  if (n > 3 && n < 21) return "th";
  switch (n % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const isNDecimalPlace = (val: string, decimalPlaces: number) => {
  const number = val.split(".");
  if (number.length < 2 || number[1].length <= decimalPlaces) return true;

  return false;
};

export const formatNumberCompact = (num: number): string => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(num);
};
