import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useUnitCoRecurringPaymentsQueryOptions from "resources/unit-co-recurring-payments/queries/useUnitCoRecurringPaymentsQueryOptions";

type Params = {
  payeeGuid: string;
};

const usePayeeRecurringPaymentsQueryOptions = ({ payeeGuid }: Params) => {
  return useUnitCoRecurringPaymentsQueryOptions({ recipientGuid: payeeGuid });
};

export const usePayeeRecurringPaymentsQuery = ({ payeeGuid }: Params) => {
  return useQuery(usePayeeRecurringPaymentsQueryOptions({ payeeGuid }));
};

const usePayeeRecurringPayments = ({ payeeGuid }: Params) => {
  const { data } = useSuspenseQuery(usePayeeRecurringPaymentsQueryOptions({ payeeGuid }));
  return data?.recurringPayments ?? [];
};

export default usePayeeRecurringPayments;
