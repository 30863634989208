// Necessary hack for getting around an annoying zod `.superRefine` issue.
// If a value has an upstream validation error, there is no way to abort the remaining validation chain.
// Weirdly, instead of just giving you the incorrect value, zod sets the value to `{ "status": "aborted" }`.
// This util loops through the values provided by the first argument of the `.superRefine((values, ctx))` callback,
// and returns `true` if any of these "aborted" statuses are found.
// Slack thread https://highbeamco.slack.com/archives/C02M3GGQPHC/p1726003536991899
const shouldExitSuperRefineIfValuesHaveAbortedStatus = (
  values: Record<string, unknown>
): boolean => {
  for (const key in values) {
    const value = values[key];
    if (value && typeof value === "object" && "status" in value && value.status === "aborted") {
      return true;
    }
  }
  return false;
};

export default shouldExitSuperRefineIfValuesHaveAbortedStatus;
