import MultiStep from "layouts/MultiStep";

import useGetPreviousPathname from "../hooks/useGetPreviousPathname";

const CreditApplicationBackButton = () => {
  const prevStepPath = useGetPreviousPathname();

  if (!prevStepPath) {
    return null;
  }

  return <MultiStep.Controls.BackButtonLink disabled={!Boolean(prevStepPath)} to={prevStepPath} />;
};

export default CreditApplicationBackButton;
