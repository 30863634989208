import { ReactNode } from "react";
import createCtx from "utils/createContext";

const [open, SidebarMenuContextProvider] = createCtx(false);
export const SidebarMenuContext = open;

type Props = {
  children: ReactNode;
};
export const SidebarMenuContextWrapper = ({ children }: Props) => (
  <SidebarMenuContextProvider>{children}</SidebarMenuContextProvider>
);
