// TODO(alex): The milestone `const` should be defined next to the other actionItem milestones.

export const MIGRATE_TO_THREAD_MILESTONE_NAME = "MigrateToThread";

export const migrateToThreadActionItemConstants = {
  activateThreadView: {
    milestoneName: MIGRATE_TO_THREAD_MILESTONE_NAME,
    actionItemName: "ActivateThreadView",
  },
  migrateDebitCardsBanner: {
    milestoneName: MIGRATE_TO_THREAD_MILESTONE_NAME,
    actionItemName: "MigrateDebitCardsBanner",
  },
} as const;

export type ActionItemName =
  (typeof migrateToThreadActionItemConstants)[keyof typeof migrateToThreadActionItemConstants]["actionItemName"];
