import { captureException } from "@sentry/react";
import ChatMessageRequest from "reps/chat/ChatMessageRequest";
import ChatMessageResponse from "reps/chat/ChatMessageResponse";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useCreateChatMessageMutation = (
  additionalOptions: MutationAdditionalOptions<
    ChatMessageResponse,
    Omit<ChatMessageRequest, "businessGuid" | "userGuid">
  >
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const userGuid = useUserGuid();

  return useMutationWithDefaults(
    {
      mutationFn: (request) =>
        highbeamApi.chatMessage.create({ ...request, businessGuid, userGuid }),
      onError: (error) => {
        captureException(error);
      },
    },
    additionalOptions
  );
};

export default useCreateChatMessageMutation;
