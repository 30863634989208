import { Clock } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { FC, Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import bankAccountByUnitCoDepositAccountIdQueryHooks from "resources/bank-accounts/queries/bankAccountByUnitCoDepositAccountIdQueryHooks";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import mapTransferOption from "resources/bank-accounts/utils/mapTransferOption";
import useUnitCoAccountLimitQueryOptions from "resources/unit-co-account-limits/queries/useUnitCoAccountLimitQueryOptions";
import checkDepositState from "state/checkDeposit";
import Banner from "ui/data-display/Banner";
import Shimmer from "ui/feedback/Shimmer";
import Button from "ui/inputs/Button";
import CurrencyInput from "ui/inputs/CurrencyInput";
import Dropdown from "ui/inputs/Dropdown";
import TextInput from "ui/inputs/TextInput";
import StepHeader from "ui/navigation/Steps/StepHeader";
import { getCentsFromDollars, getDollarsFromCents } from "utils/money";

import styles from "./CreateDeposit.module.scss";
import DepositLimitInfo from "./DepositLimitInfo";

const MAX_COUNTERPARTY_NAME_LENGTH = 20;

type Props = {
  onNextPress: () => void;
};

type ClearingInfoTextProps = {
  accountId: string;
};

const ClearingInfoText: FC<ClearingInfoTextProps> = ({ accountId }) => {
  const bankAccount = bankAccountByUnitCoDepositAccountIdQueryHooks.useData({
    unitCoDepositAccountId: accountId,
  });

  return (
    <Banner
      icon={<Clock />}
      color="grey"
      padding="compact"
      title={
        <>
          Checks take {bankAccount?.depositProduct.checkClearingDays ?? 5} business days to clear.
        </>
      }
    />
  );
};

const CreateDeposit: React.FC<Props> = ({ onNextPress }) => {
  const [deposit, setDeposit] = useRecoilState(checkDepositState);
  const { data: accountLimits } = useQuery(
    useUnitCoAccountLimitQueryOptions(deposit.account?.value)
  );

  const isAmountUnderLimits = (value: number) => {
    if (!accountLimits) return true;

    const dailyRemaining =
      accountLimits.attributes.checkDeposit.limits.daily -
      accountLimits.attributes.checkDeposit.totalsDaily;
    const monthlyRemaining =
      accountLimits.attributes.checkDeposit.limits.monthly -
      accountLimits.attributes.checkDeposit.totalsMonthly;

    return value <= dailyRemaining && value <= monthlyRemaining;
  };

  const isNextStepAllowed =
    deposit?.amountInCents > 0 &&
    deposit.account !== null &&
    deposit.counterpartyName !== "" &&
    isAmountUnderLimits(deposit.amountInCents);

  const { data: allTransferOptions = [], isLoading: isTransferOptionsLoading } =
    bankAccountsQueryHooks.useQuery({
      status: "open",
      select: (bankAccounts) => bankAccounts.map(mapTransferOption), // DEPRECATED
    });

  const [amount, setAmount] = useState(
    deposit.amountInCents !== 0 ? getDollarsFromCents(deposit.amountInCents).toString() : ""
  );

  const [counterpartyName, setCounterpartyName] = useState(deposit.counterpartyName);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNextPress();
  };

  useEffect(() => {
    setDeposit({
      ...deposit,
      counterpartyName: counterpartyName,
      amountInCents: amount ? getCentsFromDollars(amount) : 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterpartyName, amount]);

  // Set the default selected account. Get the value from location state for when the user navigates via the accounts page.
  const toAccountGuid = useLocation().state?.toAccountGuid;
  useEffect(() => {
    if (deposit.account === null && allTransferOptions.length > 0) {
      if (toAccountGuid) {
        const toAccountTransferOption = allTransferOptions.find(({ guid }) => {
          return guid === toAccountGuid;
        });
        if (toAccountTransferOption) {
          setDeposit({
            ...deposit,
            account: toAccountTransferOption,
          });
        }
      } else {
        setDeposit({
          ...deposit,
          account: allTransferOptions[0],
        });
      }
    }

    // NB(alex): This hack is needed for now because `allTransferOptions` loads asynchronously, and we have to set the default value once that value loads.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTransferOptions]);

  return (
    <>
      <StepHeader
        stepNumber={1}
        disableBackButton
        disableForwardButton={!isNextStepAllowed}
        handleForwardButtonClick={onNextPress}
        title="Create deposit"
      />

      <form id="create-deposit" className={styles.form} onSubmit={handleSubmit}>
        <TextInput
          type="text"
          value={counterpartyName}
          onChange={setCounterpartyName}
          label="From"
          maxLength={MAX_COUNTERPARTY_NAME_LENGTH}
          autoFocus
        />

        <CurrencyInput
          value={amount}
          onChange={(value) => {
            setAmount(value);
          }}
          label="Amount"
          prefixValue="$"
          hasError={!isAmountUnderLimits(deposit.amountInCents)}
          errorMessage="The amount you are trying to deposit is greater than your remaining deposit limit."
        />

        <Dropdown
          isLoading={isTransferOptionsLoading}
          disabled={isTransferOptionsLoading}
          value={deposit.account}
          onChange={(account) => {
            setDeposit({
              ...deposit,
              account: account,
            });
          }}
          id="Deposit to"
          label="Deposit to"
          options={allTransferOptions}
          isSearchable={false}
        />

        <div className={styles.buttonsContainer}>
          <Button
            className={styles.buttonsContainerButton}
            type="submit"
            form="create-deposit"
            variant="primary"
            disabled={!isNextStepAllowed}
          >
            Next
          </Button>
        </div>
        <Suspense
          fallback={<Shimmer additionalShimmerWrapperClassName={styles.depositInfoShimmer} />}
        >
          {deposit.account?.value && <ClearingInfoText accountId={deposit.account?.value} />}
        </Suspense>
        <Suspense
          fallback={<Shimmer additionalShimmerWrapperClassName={styles.depositInfoShimmer} />}
        >
          {deposit.account?.value && <DepositLimitInfo accountId={deposit.account.value} />}
        </Suspense>
      </form>
    </>
  );
};

export default CreateDeposit;
