import classNames from "classnames";
import { ComponentPropsWithoutRef, forwardRef, ForwardRefRenderFunction } from "react";

import styles from "./Radio.module.scss";

export type RadioProps = Omit<ComponentPropsWithoutRef<"input">, "type">;

const Radio: ForwardRefRenderFunction<HTMLInputElement, RadioProps> = (
  { className, ...inputProps },
  ref
) => {
  return (
    <input type="radio" className={classNames(className, styles.radio)} ref={ref} {...inputProps} />
  );
};

export default forwardRef(Radio);
