import IconWithTooltip from "components/common/IconWithTooltip";
import { Dayjs } from "dayjs";
import ExchangeRateInfoIcon from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/ExchangeRateInfoIcon";
import { SWIFT_FEE, PaymentMethod } from "pages/SendMoneyPage/utils";
import { ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { SwiftFeeTooltipContent } from "resources/international-wires/constants";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import { invoiceDateState } from "state/payments/international/invoiceDate";
import { invoiceNumberState } from "state/payments/international/invoiceNumber";
import { isLocalPaymentState } from "state/payments/international/isLocalPayment";
import { purposeCodeState } from "state/payments/international/purposeCode";
import { quoteCalculatedLocalAmountInCentsState } from "state/payments/international/quoteCalculatedLocalAmountInCents";
import { quoteCalculatedUsdAmountInCentsState } from "state/payments/international/quoteCalculatedUsdAmountInCents";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import quotesState from "state/payments/international/quotes";
import CashDisplay from "ui/data-display/money/CashDisplay";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Pill from "ui/data-display/Pill";
import FileIcon from "ui/icons/FileIcon";
import Text from "ui/typography/Text";
import { dateOnly } from "utils/date";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import { getFileExtension } from "utils/url";

import styles from "./PaymentDetailsCard.module.scss";

type Props = {
  paymentMethod: PaymentMethod;
  iconBesideAmount: ReactNode;
  amountInCents: number;
  from?: ReactNode;
  to: ReactNode;
  scheduledDate?: Dayjs;
  deliveryMessage?: string;
  descriptionMessage?: string;
  routingNumber?: string;
  accountNumber?: string;
  iban?: string;
  swift?: string;
  footer?: ReactNode;
  toLabel?: string;
  invoiceName?: string;
};

const UsdAmount = () => {
  const calculatedUsdAmountInCents = useRecoilValue(quoteCalculatedUsdAmountInCentsState);

  return (
    <>
      <div className={styles.payeeInfo}>
        <Text size={14} className={styles.payeeInfoTitle}>
          Payee receives
        </Text>
        <MoneyAmount
          cents={calculatedUsdAmountInCents}
          currency={"USD"}
          weight="medium"
          size={18}
          trailingCurrencyCode
        />
      </div>

      <div className={styles.cardHeader}>
        <div className={styles.payeeInfo}>
          <Text size={14} className={styles.payeeInfoTitle}>
            SWIFT fee <IconWithTooltip color="light" tooltip={<SwiftFeeTooltipContent />} />
          </Text>
          <MoneyAmount
            cents={SWIFT_FEE}
            currency={"USD"}
            weight="medium"
            size={14}
            trailingCurrencyCode
          />
        </div>

        <div className={styles.payeeInfo}>
          <Text size={14} className={styles.payeeInfoTitle}>
            You pay
          </Text>
          <MoneyAmount
            weight="medium"
            size={20}
            cents={calculatedUsdAmountInCents + SWIFT_FEE}
            currency={"USD"}
            trailingCurrencyCode
          />
        </div>
      </div>
    </>
  );
};

const LocalAmount = () => {
  const internationalQuoteCurrencyOption = useRecoilValue(quoteCurrencyState);
  const quote = useRecoilValue(quotesState(internationalQuoteCurrencyOption.value));
  const calculatedUsdAmountInCents = useRecoilValue(quoteCalculatedUsdAmountInCentsState);
  const calculatedLocalAmountInCents = useRecoilValue(quoteCalculatedLocalAmountInCentsState);

  return (
    <>
      <div className={styles.payeeInfo}>
        <Text size={14} className={styles.payeeInfoTitle}>
          You pay
        </Text>
        <MoneyAmount
          currency={"USD"}
          size={20}
          weight="medium"
          cents={calculatedUsdAmountInCents}
          trailingCurrencyCode
        />
      </div>

      <div className={styles.cardHeader}>
        <div className={styles.payeeInfo}>
          <Text size={14} className={styles.payeeInfoTitle}>
            Rate <ExchangeRateInfoIcon color="light" />
          </Text>
          <div className={styles.payeeInfoContent}>
            1 USD = {quote.rate} {internationalQuoteCurrencyOption.value}
          </div>
        </div>

        <div className={styles.payeeInfo}>
          <Text size={14} className={styles.payeeInfoTitle}>
            Payee receives
          </Text>
          <MoneyAmount
            cents={calculatedLocalAmountInCents}
            currency={internationalQuoteCurrencyOption.value}
            size={18}
            weight="medium"
            trailingCurrencyCode
          />
        </div>
      </div>
    </>
  );
};

const PaymentAmountIntl = () => {
  const isLocalPayment = useRecoilValue(isLocalPaymentState);
  return isLocalPayment ? <LocalAmount /> : <UsdAmount />;
};

const PaymentDetailsCard: React.FC<Props> = ({
  paymentMethod,
  iconBesideAmount,
  amountInCents,
  from,
  to,
  scheduledDate,
  deliveryMessage,
  descriptionMessage,
  routingNumber,
  accountNumber,
  iban,
  swift,
  footer,
  toLabel = "To",
  invoiceName,
}) => {
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const internationalBankingInfo = useRecoilValue(internationalBankingInfoState);
  const purposeCode = useRecoilValue(purposeCodeState);
  const invoiceNumber = useRecoilValue(invoiceNumberState);
  const invoiceDate = useRecoilValue(invoiceDateState);

  // International FX specific logic
  const isIntlPayment = paymentMethod === PaymentMethod.INTERNATIONAL;

  return (
    <div className={styles.card}>
      {isIntlPayment ? (
        <PaymentAmountIntl />
      ) : (
        <div className={styles.cardHeader}>
          <div className={styles.titleWithIcon}>
            {iconBesideAmount}
            <Text size={14} className={styles.text}>
              Amount
            </Text>
          </div>

          <CashDisplay cents={amountInCents} withCents />
        </div>
      )}
      <div className={styles.cardBody}>
        {from && (
          <div className={styles.payeeInfo}>
            <Text size={14} className={styles.payeeInfoTitle}>
              From
            </Text>
            <div className={styles.payeeInfoContent}>{from}</div>
          </div>
        )}
        <div className={styles.payeeInfo}>
          <Text size={14} className={styles.payeeInfoTitle}>
            {toLabel}
          </Text>
          <div className={styles.payeeInfoContent}>{to}</div>
        </div>

        {isIntlPayment ? (
          <>
            {purposeCode && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  Purpose code
                </Text>
                <div className={styles.payeeInfoContent}>{purposeCode.label}</div>
              </div>
            )}
            {invoiceNumber && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  Invoice number
                </Text>
                <div className={styles.payeeInfoContent}>{invoiceNumber}</div>
              </div>
            )}
            {invoiceDate && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  Invoice date
                </Text>
                <div className={styles.payeeInfoContent}>{invoiceDate.format("MMM D, YYYY")}</div>
              </div>
            )}
            {internationalBankingInfo.iban?.value && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  IBAN #
                </Text>
                <div className={styles.payeeInfoContent}>{internationalBankingInfo.iban.value}</div>
              </div>
            )}
            {internationalBankingInfo.swift?.value && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  SWIFT code
                </Text>
                <div className={styles.payeeInfoContent}>
                  {internationalBankingInfo.swift.value}
                </div>
              </div>
            )}
            {internationalBankingInfo.bankCode?.value && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  Bank code
                </Text>
                <div className={styles.payeeInfoContent}>
                  {internationalBankingInfo.bankCode.value}
                </div>
              </div>
            )}
            {internationalBankingInfo.branchCode?.value && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  Branch code
                </Text>
                <div className={styles.payeeInfoContent}>
                  {internationalBankingInfo.branchCode.value}
                </div>
              </div>
            )}
            {internationalBankingInfo.bsbCode?.value && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  Bank state branch code
                </Text>
                <div className={styles.payeeInfoContent}>
                  {internationalBankingInfo.bsbCode.value}
                </div>
              </div>
            )}
            {internationalBankingInfo.clabe?.value && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  CLABE
                </Text>
                <div className={styles.payeeInfoContent}>
                  {internationalBankingInfo.clabe.value}
                </div>
              </div>
            )}
            {internationalBankingInfo.cnaps?.value && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  CNAPS
                </Text>
                <div className={styles.payeeInfoContent}>
                  {internationalBankingInfo.cnaps.value}
                </div>
              </div>
            )}
            {internationalBankingInfo.accountNumber?.value && (
              <>
                <div className={styles.payeeInfo}>
                  <Text size={14} className={styles.payeeInfoTitle}>
                    Account #
                  </Text>
                  <div className={styles.payeeInfoContent}>
                    {internationalBankingInfo.accountNumber.value}
                  </div>
                </div>
              </>
            )}
            {internationalBankingInfo.ifsc?.value && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  IFSC
                </Text>
                <div className={styles.payeeInfoContent}>{internationalBankingInfo.ifsc.value}</div>
              </div>
            )}
            {internationalBankingInfo.sortCode?.value && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  Sort code
                </Text>
                <div className={styles.payeeInfoContent}>
                  {internationalBankingInfo.sortCode.value}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {iban && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  IBAN #
                </Text>
                <div className={styles.payeeInfoContent}>{iban}</div>
              </div>
            )}
            {swift && (
              <div className={styles.payeeInfo}>
                <Text size={14} className={styles.payeeInfoTitle}>
                  SWIFT code
                </Text>
                <div className={styles.payeeInfoContent}>{swift}</div>
              </div>
            )}
          </>
        )}

        {routingNumber && (
          <div className={styles.payeeInfo}>
            <Text size={14} className={styles.payeeInfoTitle}>
              Routing #
            </Text>
            <div className={styles.payeeInfoContent}>{routingNumber}</div>
          </div>
        )}

        {!isIntlPayment && accountNumber && (
          <div className={styles.payeeInfo}>
            <Text size={14} className={styles.payeeInfoTitle}>
              Account #
            </Text>
            <div className={styles.payeeInfoContent}>{accountNumber}</div>
          </div>
        )}

        {isAllowedToApprovePayments && scheduledDate && (
          <div className={styles.payeeInfo}>
            <Text size={14} className={styles.payeeInfoTitle}>
              Initiate on
            </Text>
            <div className={styles.payeeInfoContent}>
              {dateOnly(scheduledDate).format("MM/DD/YYYY")}
            </div>
          </div>
        )}
        {deliveryMessage && (
          <div className={styles.payeeInfo}>
            <Text size={14} className={styles.payeeInfoTitle}>
              Delivery
            </Text>
            <div className={styles.payeeInfoContent}>
              <Text size={14}>{deliveryMessage}</Text>
            </div>
          </div>
        )}
        {descriptionMessage && (
          <div className={styles.payeeInfo}>
            <Text size={14} className={styles.payeeInfoTitle}>
              Description
            </Text>
            <div className={styles.payeeInfoContent}>
              <Text size={14}>{descriptionMessage}</Text>
            </div>
          </div>
        )}
        {invoiceName && (
          <div className={styles.payeeInfo}>
            <Text size={14} className={styles.payeeInfoTitle}>
              Invoice
            </Text>
            <div className={styles.payeeInfoContent}>
              <Pill
                color="grey-light"
                iconLeft={({ sizeClassName }) => (
                  <FileIcon
                    fileExtension={getFileExtension(invoiceName)}
                    className={sizeClassName}
                  />
                )}
              >
                {invoiceName}
              </Pill>
            </div>
          </div>
        )}
      </div>
      {footer && <div className={styles.cardFooter}>{footer}</div>}
    </div>
  );
};

export default PaymentDetailsCard;
