import logo from "assets/highbeam-logo.svg";
import backgroundLogo from "assets/onboarding-background.svg";
import env from "env";
import { useParams } from "react-router-dom";
import InvalidUserInvitation from "resources/user-invitations/components/InvalidUserInvitation";
import useReferral from "resources/user-referrals/queries/useReferral";
import Card from "ui/data-display/Card";

import Referral from "./Referral";
import styles from "./ReferralPage.module.scss";

const ReferralPage = () => {
  const { referralSlug } = useParams();
  const referral = useReferral({ referralSlug });
  const marketingSiteUrl = env.HIGHBEAM_MARKETING_SITE_URL;

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${backgroundLogo})` }}>
      <a href={marketingSiteUrl} target="_blank" rel="noreferrer">
        <img src={logo} className={styles.logo} alt="logo" />
      </a>
      {referral ? (
        <div className={styles.content}>
          <Referral referral={referral} />
        </div>
      ) : (
        <Card className="px-8 pb-12 pt-8">
          <InvalidUserInvitation itemName="referral" />
        </Card>
      )}
    </div>
  );
};

export default ReferralPage;
