import dayjs from "dayjs";
import UserReferralRep from "reps/ReferralLinkRep";
import UserInvitationAlreadyAcceptedCard from "resources/user-invitations/components/UserInvitationAlreadyAcceptedCard";
import DefaultReferralCard from "resources/user-referrals/components/DefaultReferralCard";
import ExpiredReferralCard from "resources/user-referrals/components/ExpiredReferralCard";
import { SEGMENT_EVENTS, useTrackPageMount } from "utils/customHooks/useSegment";

import billDAAvatar from "./assets/billDA.jpeg";
import nikSharmaAvatar from "./assets/nikSharma.png";
import ReferralApy from "./ReferralApy/ReferralApy";
import ReferralBfcm from "./ReferralBfcm/ReferralBfcm";

type Props = {
  referral: UserReferralRep;
};

// To add new apy referral, use referral's slug as a key and avatar as value
// Use null as value for default icon image
const APY_REFERRALS_SLUG_TO_AVATAR_MAP: Record<string, string | null> = {
  bill: billDAAvatar,
  "bill-april-2024": billDAAvatar,
  TrueOps: null,
};

// To add new bfcm referral, use referral's slug as a key and avatar as value
// Use null as value for default icon image
const BFCM_REFERRALS_SLUG_TO_AVATAR_MAP: Record<string, string | null> = {
  "sharma-april-2024": nikSharmaAvatar,
  "sharma-bfcm-2024": nikSharmaAvatar,
};

const Referral: React.FC<Props> = ({ referral }) => {
  useTrackPageMount(SEGMENT_EVENTS.REFERRAL_LINK_VISITED, { referralSlug: referral.slug });

  const referralExpiresAt = referral.expiresAt
    ? dayjs(referral.expiresAt).format("MM/DD/YY")
    : null;
  const hasExpired = referral.expiresAt ? dayjs(referral.expiresAt).isBefore(dayjs()) : false;

  if (referralExpiresAt && hasExpired) {
    return <ExpiredReferralCard referral={referral} />;
  }

  if (referral.manuallyDisabled) {
    return <UserInvitationAlreadyAcceptedCard />;
  }

  if (referral.slug in APY_REFERRALS_SLUG_TO_AVATAR_MAP) {
    return (
      <ReferralApy
        referral={referral}
        referrerAvatar={APY_REFERRALS_SLUG_TO_AVATAR_MAP[referral.slug]}
      />
    );
  }

  if (referral.slug in BFCM_REFERRALS_SLUG_TO_AVATAR_MAP) {
    return (
      <ReferralBfcm
        referral={referral}
        referrerAvatar={BFCM_REFERRALS_SLUG_TO_AVATAR_MAP[referral.slug]}
      />
    );
  }

  return <DefaultReferralCard referral={referral} />;
};

export default Referral;
