import { FC, PropsWithChildren, ReactNode } from "react";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import Text from "ui/typography/Text";

import styles from "./BankingInfo.module.scss";

const BankingInfoKey: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text size={14} className={styles.key}>
      {children}
    </Text>
  );
};

type BankingInfoValueProps = {
  textToCopy?: string;
  children?: ReactNode;
};

const BankingInfoValue: FC<BankingInfoValueProps> = ({ children, textToCopy }) => {
  const text = (
    <Text size={14} weight="medium" className={styles.value}>
      {children}
    </Text>
  );

  if (textToCopy) {
    return <ItemWithCopyTextTooltip textToCopy={textToCopy}>{text}</ItemWithCopyTextTooltip>;
  } else {
    return text;
  }
};

const BankingInfo: FC<PropsWithChildren> = ({ children }) => {
  return <div className={styles.bankInfo}>{children}</div>;
};

export default Object.assign(BankingInfo, {
  Key: BankingInfoKey,
  Value: BankingInfoValue,
});
