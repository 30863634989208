import classNames from "classnames";
import React, { ReactNode } from "react";
import Text from "ui/typography/Text";

import styles from "./BusinessSwitcherResultCell.module.scss";

type Props = {
  allowWrap?: boolean;
  header?: boolean;
  children: ReactNode;
};

const BusinessSwitcherResultCell: React.FC<Props> = ({
  allowWrap = false,
  header = false,
  children,
}) => {
  const X = header ? "th" : "td";

  return (
    <X className={classNames(styles.cell, allowWrapClassName(allowWrap))}>
      <Text size={14}>{children}</Text>
    </X>
  );
};

export default BusinessSwitcherResultCell;

const allowWrapClassName = (allowWrap: boolean): string | undefined => {
  if (!allowWrap) return styles.noWrap;
  return undefined;
};
