import DocumentRep from "reps/DocumentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

// NB(alex): This is called `DocumentApi` in backend-v2 but adding "V2" to reduce ambiguity with backend-v1's `DocumentsApi`.
export default class DocumentV2Api {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(documentId: string): Promise<DocumentRep.Complete | null> {
    const url = `/document/documents/${documentId}`;
    return await this.api.get<DocumentRep.Complete>(url);
  }

  async getDocumentsById(documentIds: string[]): Promise<DocumentRep.Complete[]> {
    const qp = new URLSearchParams({ documentIds: documentIds.join(",") });
    const url = `/document/documents?${qp}`;
    return (await this.api.get<DocumentRep.Complete[]>(url))!;
  }

  async search(businessGuid: string): Promise<DocumentRep.Complete[]> {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/document/documents?${qp}`;
    return (await this.api.get<DocumentRep.Complete[]>(url))!;
  }

  async create(body: DocumentRep.Creator): Promise<DocumentRep.Complete> {
    const url = "/document/documents";
    return (await this.api.post<DocumentRep.Complete>(url, body))!;
  }

  async delete(documentId: string): Promise<DocumentRep.Complete> {
    const url = `/document/documents/${documentId}`;
    return (await this.api.delete<DocumentRep.Complete>(url))!;
  }
}
