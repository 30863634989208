import UserRoleRep from "reps/UserRoleRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class UserRoleApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByBusiness(businessGuid: string): Promise<UserRoleRep.Complete[]> {
    const url = `/businesses/${businessGuid}/user-roles`;
    const userRoles = await this.api.get<UserRoleRep.Complete[]>(url);
    return userRoles ? userRoles : [];
  }
}
