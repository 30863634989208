import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import CreditApplicationRep from "reps/CreditApplicationRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CreditApplicationApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(businessGuid: string): Promise<CreditApplicationRep.Complete | null> {
    const path = `/businesses/${businessGuid}/credit-applications`;
    return await this.api.post<CreditApplicationRep.Complete>(path);
  }

  async update(
    businessGuid: string,
    creditApplicationGuid: string,
    body: CreditApplicationRep.Updater
  ): Promise<CreditApplicationRep.Complete | null> {
    const path = `/businesses/${businessGuid}/credit-applications/${creditApplicationGuid}`;
    return await this.api.patch<CreditApplicationRep.Complete>(path, body);
  }

  async get(
    businessGuid: string,
    creditApplicationGuid: string
  ): Promise<CreditApplicationRep.Complete | null> {
    const path = `/businesses/${businessGuid}/credit-applications/${creditApplicationGuid}`;
    return await this.api.get<CreditApplicationRep.Complete>(path);
  }

  async getByBusiness(businessGuid: string): Promise<CreditApplicationRep.Complete[] | null> {
    const path = `/businesses/${businessGuid}/credit-applications`;
    return await this.api.get<CreditApplicationRep.Complete[]>(path);
  }

  async createDocument(
    businessGuid: string,
    creditApplicationGuid: string,
    rep: CreditApplicationDocumentRep.Creator
  ): Promise<CreditApplicationDocumentRep.Complete | null> {
    const path = `/businesses/${businessGuid}/credit-applications/${creditApplicationGuid}/credit-application-documents`;
    return await this.api.post<CreditApplicationDocumentRep.Complete>(path, rep);
  }

  async getDocuments(
    businessGuid: string,
    creditApplicationGuid: string
  ): Promise<CreditApplicationDocumentRep.Complete[] | null> {
    const path = `/businesses/${businessGuid}/credit-applications/${creditApplicationGuid}/credit-application-documents`;
    return await this.api.get<CreditApplicationDocumentRep.Complete[]>(path);
  }

  async deleteDocument(
    businessGuid: string,
    creditApplicationGuid: string,
    creditApplicationDocumentGuid: string
  ): Promise<CreditApplicationDocumentRep.Complete | null> {
    const path = `/businesses/${businessGuid}/credit-applications/${creditApplicationGuid}/credit-application-documents/${creditApplicationDocumentGuid}`;
    return await this.api.delete<CreditApplicationDocumentRep.Complete | null>(path);
  }

  async submit(
    businessGuid: string,
    creditApplicationGuid: string,
    body: CreditApplicationRep.Updater
  ): Promise<CreditApplicationRep.Complete | null> {
    const path = `/businesses/${businessGuid}/credit-applications/${creditApplicationGuid}/submit`;
    return await this.api.patch<CreditApplicationRep.Complete>(path, body);
  }
}
