import BusinessDetailsRep from "reps/BusinessDetailsRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BusinessDetailsApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(businessGuid: string): Promise<BusinessDetailsRep | null> {
    const url = `/businesses/${businessGuid}/details`;
    return await this.api.get<BusinessDetailsRep>(url);
  }

  async update(
    businessGuid: string,
    updater: BusinessDetailsRep.Updater
  ): Promise<BusinessDetailsRep> {
    const url = `/businesses/${businessGuid}/details`;
    return (await this.api.patch<BusinessDetailsRep>(url, updater))!;
  }
}
