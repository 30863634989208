import { ArrowRight } from "@phosphor-icons/react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import BankAccountRep from "reps/BankAccountRep";
import BankAccountBar from "resources/bank-accounts/components/BankAccountBar";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import ShopifyPayoutsStatusIcon from "resources/connected-stores/components/ShopifyPayoutsStatusIcon";
import AmountCell from "ui/table/AmountCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

const createColumns = (): Column<BankAccountRep.Complete>[] => [
  {
    title: "Account",
    cellRender: (account) => (
      <BankAccountBar
        bankAccount={account}
        endAdornment={account.isPrimary && <ShopifyPayoutsStatusIcon />}
      />
    ),
  },
  {
    title: "Available",
    align: TableColumnAlignment.RIGHT,
    cellRender: (account) => <AmountCell cents={account.availableBalance} />,
  },
  {
    title: "",
    align: TableColumnAlignment.RIGHT,
    width: 160,
    cellRender: () => (
      <div>
        <ArrowRight size={16} />
      </div>
    ),
  },
];

const BankAccountsTable = () => {
  const navigate = useNavigate();
  const bankAccounts = bankAccountsQueryHooks.useData({ status: "open" });
  const columns = useMemo(() => createColumns(), []);

  return (
    <Table
      columns={columns}
      rowKey={(account) => account.guid}
      data={bankAccounts}
      onRowClick={(account) => navigate(`/accounts/${account.guid}`)}
    />
  );
};

export default BankAccountsTable;
