import PayeeRep from "reps/PayeeRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class PayeeApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(businessGuid: string, creation: PayeeRep.Creation): Promise<PayeeRep.Complete> {
    const url = `/businesses/${businessGuid}/payees`;
    return (await this.api.post<PayeeRep.Complete>(url, creation))!;
  }

  async get(businessGuid: string, payeeGuid: string): Promise<PayeeRep.Complete | null> {
    const url = `/businesses/${businessGuid}/payees/${payeeGuid}`;
    return await this.api.get<PayeeRep.Complete>(url);
  }

  async getByBusiness(businessGuid: string, status: PayeeRep.Status): Promise<PayeeRep.Complete[]> {
    const queryParams = new URLSearchParams({
      status,
    });
    const url = `/businesses/${businessGuid}/payees?${queryParams.toString()}`;
    return (await this.api.get<PayeeRep.Complete[]>(url))!;
  }

  async update(
    businessGuid: string,
    payeeGuid: string,
    update: PayeeRep.Update
  ): Promise<PayeeRep.Complete> {
    const url = `/businesses/${businessGuid}/payees/${payeeGuid}`;
    return (await this.api.patch<PayeeRep.Complete>(url, update))!;
  }

  async delete(businessGuid: string, payeeGuid: string): Promise<PayeeRep.Complete | null> {
    const url = `/businesses/${businessGuid}/payees/${payeeGuid}`;
    return await this.api.delete<PayeeRep.Complete>(url);
  }
}
