import { selector } from "recoil";

import revenueProjectionInputsState from "./inputs/revenueProjectionInputs";

const isRevenueProjectionsFormValidState = selector<boolean>({
  key: "isRevenueProjectionsFormValidState",
  get: ({ get }) => get(revenueProjectionInputsState).some((amount) => Number(amount) > 0),
});

export default isRevenueProjectionsFormValidState;
