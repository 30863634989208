import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import isSidebarOpenState from "state/sidebar";

/**
 * Ensures that the sidebar gets closed when the user navigates to a new page.
 *
 * NB(alex): We can delete this if/when we modify `SidebarMenu` to work with `<Link>`.
 */
const useCloseSidebarOnNavigateEffect = () => {
  const setIsSidebarOpen = useSetRecoilState(isSidebarOpenState);
  const { pathname } = useLocation();
  useEffect(() => {
    setIsSidebarOpen(false);
  }, [pathname, setIsSidebarOpen]);
};

export default useCloseSidebarOnNavigateEffect;
