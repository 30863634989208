import { PaymentMethod, TransferInfo } from "pages/SendMoneyPage/utils";
import { Suspense, useState } from "react";
import { useParams } from "react-router-dom";
import usePayee from "resources/payees/queries/usePayee";
import Address from "ui/data-display/Address";
import Shimmer from "ui/feedback/Shimmer";
import Tabs, { Tab } from "ui/navigation/Tabs";
import CopyIconWithTooltip from "ui/overlay/CopyIconWithTooltip";
import Text from "ui/typography/Text";

import styles from "./PayeeDetailsInfo.module.scss";
import { PayeeDetailsTable } from "./PayeeDetailsTable";

// Order of display and should not be alphabetical
const TABS: Tab[] = [
  {
    id: PaymentMethod.ACH,
    label: "ACH",
  },
  {
    id: PaymentMethod.WIRE,
    label: "Wire",
  },
  {
    id: PaymentMethod.INTERNATIONAL,
    label: "Intl. wire",
  },
];

type RowData = {
  name: string;
  copyValue?: string;
  data?: React.ReactNode;
};

type DisplayTableProps = {
  data: RowData[];
};

const NoValueText = () => (
  <Text size={14} weight="medium" className={styles["table-row__value"]}>
    -
  </Text>
);

const PayeeDetailsInfo = () => {
  const { payeeGuid } = useParams();
  const payee = usePayee(payeeGuid!, { required: true });

  const [selectedTab, setSelectedTab] = useState<string>(PaymentMethod.ACH);

  const getTableText = (text?: string, isNumeric = false) => {
    if (!text) {
      return <NoValueText />;
    }

    return (
      <Text size={14} weight="medium" numeric={isNumeric} className={styles["table-row__value"]}>
        {text}
      </Text>
    );
  };

  const PayeeDetailsTableFallback = (numRowsLoading: number) => (
    <table className={styles.table}>
      <tbody>
        {Array.from(Array(numRowsLoading).keys()).map((row) => (
          <tr className={styles["table-row"]} key={row}>
            <td>
              <Text size={14} className={styles["table-row__name"]}>
                <Shimmer />
              </Text>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const DisplayTable = ({ data }: DisplayTableProps) => (
    <table className={styles.table}>
      <tbody>
        {data.map((row: RowData) => (
          <tr className={styles["table-row"]} key={row.name}>
            <td>
              <Text size={14} className={styles["table-row__name"]}>
                {row.name}
              </Text>
            </td>
            <td>{row.data}</td>
            <td>
              {row.copyValue && (
                <CopyIconWithTooltip textToCopy={row.copyValue}></CopyIconWithTooltip>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const achAccountDetails: RowData[] = [
    {
      name: TransferInfo.ROUTING_NUMBER,
      data: getTableText(payee.achTransferMethod?.routingNumber, true),
      copyValue: payee.achTransferMethod?.routingNumber,
    },
    {
      name: TransferInfo.ACCOUNT_NUMBER,
      data: getTableText(payee.achTransferMethod?.accountNumber, true),
      copyValue: payee.achTransferMethod?.accountNumber,
    },
    { name: TransferInfo.ACCOUNT_TYPE, data: getTableText("Business checking") },
  ];

  const internationalAccountDetails: RowData[] = [
    {
      name: TransferInfo.SWIFT_CODE,
      data: getTableText(payee.internationalWireTransferMethod?.swift, true),
      copyValue: payee.internationalWireTransferMethod?.swift,
    },
  ];
  if (payee.internationalWireTransferMethod?.accountNumber) {
    internationalAccountDetails.push({
      name: TransferInfo.ACCOUNT_NUMBER,
      data: getTableText(payee.internationalWireTransferMethod?.accountNumber, true),
      copyValue: payee.internationalWireTransferMethod?.accountNumber,
    });
  }
  if (payee.internationalWireTransferMethod?.bankCode) {
    internationalAccountDetails.push({
      name: TransferInfo.BANK_CODE,
      data: getTableText(payee.internationalWireTransferMethod?.bankCode, true),
      copyValue: payee.internationalWireTransferMethod?.bankCode,
    });
  }
  if (payee.internationalWireTransferMethod?.branchCode) {
    internationalAccountDetails.push({
      name: TransferInfo.BRANCH_CODE,
      data: getTableText(payee.internationalWireTransferMethod?.branchCode, true),
      copyValue: payee.internationalWireTransferMethod?.branchCode,
    });
  }
  if (payee.internationalWireTransferMethod?.bsbCode) {
    internationalAccountDetails.push({
      name: TransferInfo.BSB_CODE,
      data: getTableText(payee.internationalWireTransferMethod?.bsbCode, true),
      copyValue: payee.internationalWireTransferMethod?.bsbCode,
    });
  }
  if (payee.internationalWireTransferMethod?.clabe) {
    internationalAccountDetails.push({
      name: TransferInfo.CLABE,
      data: getTableText(payee.internationalWireTransferMethod?.clabe, true),
      copyValue: payee.internationalWireTransferMethod?.clabe,
    });
  }
  if (payee.internationalWireTransferMethod?.cnaps) {
    internationalAccountDetails.push({
      name: TransferInfo.CNAPS,
      data: getTableText(payee.internationalWireTransferMethod?.cnaps, true),
      copyValue: payee.internationalWireTransferMethod?.cnaps,
    });
  }
  if (payee.internationalWireTransferMethod?.iban) {
    internationalAccountDetails.push({
      name: TransferInfo.IBAN,
      data: getTableText(payee.internationalWireTransferMethod?.iban, true),
      copyValue: payee.internationalWireTransferMethod?.iban,
    });
  }
  if (payee.internationalWireTransferMethod?.ifsc) {
    internationalAccountDetails.push({
      name: TransferInfo.IFSC,
      data: getTableText(payee.internationalWireTransferMethod?.ifsc, true),
      copyValue: payee.internationalWireTransferMethod?.ifsc,
    });
  }
  if (payee.internationalWireTransferMethod?.sortCode) {
    internationalAccountDetails.push({
      name: TransferInfo.SORT_CODE,
      data: getTableText(payee.internationalWireTransferMethod?.sortCode, true),
      copyValue: payee.internationalWireTransferMethod?.sortCode,
    });
  }
  internationalAccountDetails.push({
    name: TransferInfo.ADDRESS,
    data: payee?.internationalWireTransferMethod?.address ? (
      <Address
        address={payee.internationalWireTransferMethod.address}
        size={14}
        weight="medium"
        className={styles["table-row__value"]}
      />
    ) : (
      <NoValueText />
    ),
  });

  const wireAccountDetails: RowData[] = [
    {
      name: TransferInfo.ROUTING_NUMBER,
      data: getTableText(payee.wireTransferMethod?.routingNumber, true),
      copyValue: payee.wireTransferMethod?.routingNumber,
    },
    {
      name: TransferInfo.ACCOUNT_NUMBER,
      data: getTableText(payee.wireTransferMethod?.accountNumber, true),
      copyValue: payee.wireTransferMethod?.accountNumber,
    },
    {
      name: TransferInfo.ADDRESS,
      data: payee?.address ? (
        <Address
          address={payee.address}
          size={14}
          weight="medium"
          className={styles["table-row__value"]}
        />
      ) : (
        <NoValueText />
      ),
    },
    { name: TransferInfo.ACCOUNT_TYPE, data: getTableText("Business checking") },
  ];

  return (
    <div className={styles["details-container"]}>
      <div className={styles["sub-container"]}>
        <div className={styles["details-container__title"]}>
          <h2>Payee info</h2>
        </div>
        <Suspense fallback={PayeeDetailsTableFallback(3)}>
          <PayeeDetailsTable payee={payee} />
        </Suspense>
      </div>
      <div className={styles["sub-container"]}>
        <div className={styles["details-container__title"]}>
          <h2>Transfer info</h2>
        </div>
        <Tabs
          tabs={TABS}
          activeTab={selectedTab}
          setActiveTab={setSelectedTab}
          variant="rounded"
          tabsWrapperAdditionalClassName={styles.tabs}
        />
        {selectedTab === PaymentMethod.ACH && <DisplayTable data={achAccountDetails} />}
        {selectedTab === PaymentMethod.WIRE && <DisplayTable data={wireAccountDetails} />}
        {selectedTab === PaymentMethod.INTERNATIONAL && (
          <DisplayTable data={internationalAccountDetails} />
        )}
      </div>
    </div>
  );
};

export default PayeeDetailsInfo;
