import colors from "styles/colors";
import Text from "ui/typography/Text";

import { useReceivedPaymentInfoFlexpaneContext } from "../../../context/ReceivedPaymentInfoFlexpaneProvider";
import ReceivedPaymentStatusPill from "../../ReceivedPaymentStatusPill";

import styles from "./ReceivedPaymentDetailBubble.module.scss";

const ReceivedPaymentDetailBubble = () => {
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();

  return (
    <div className={styles.container}>
      <ReceivedPaymentStatusPill receivedPayment={receivedPayment} />
      <Text size={14} weight="medium" color={colors.grey[800]}>
        ACH
      </Text>
    </div>
  );
};

export default ReceivedPaymentDetailBubble;
