import classNames from "classnames";
import { Children, ReactNode } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { FileType, fileTypeExtensions } from "utils/dropzone/file-types";

import styles from "./FileDropzone.module.scss";
import FileDropzoneHeader from "./FileDropzoneHeader";
import SelectedFile from "./SelectedFile";
import ActionButtons, {
  DeleteFileButton,
  DownloadFileButton,
  PreviewFileButton,
} from "./SelectedFile/ActionButtons";

type Props = Omit<DropzoneOptions, "accept"> & {
  text?: ReactNode;
  subtext?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  fileTypes: FileType[];
};

const FileDropzone: React.FC<Props> = ({
  text,
  subtext,
  children,
  fileTypes,
  ...dropzoneOptions
}) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    ...dropzoneOptions,
    accept: Object.assign({}, ...fileTypes.map((fileType) => fileTypeExtensions[fileType])),
  });

  return (
    <div className={classNames(styles.root, isDragActive && styles.dragActive)} {...getRootProps()}>
      <FileDropzoneHeader
        isDragActive={isDragActive}
        text={text}
        subtext={subtext}
        onClickBrowse={open}
      />
      {<input {...getInputProps()} />}

      {Children.map(children, (child, index) => (
        <>
          {index > 0 && index < Children.count(children) && (
            <div className={classNames(styles.separator, isDragActive && styles.dragActive)} />
          )}
          {child}
        </>
      ))}
    </div>
  );
};

export default Object.assign(FileDropzone, {
  SelectedFile: Object.assign(SelectedFile, {
    ActionButtons: Object.assign(ActionButtons, {
      PreviewFileButton: PreviewFileButton,
      DownloadFileButton: DownloadFileButton,
      DeleteFileButton: DeleteFileButton,
    }),
  }),
});
