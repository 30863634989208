import { Payment } from "@highbeam/unit-node-sdk";

import getIsInternationalWireUnitCoPayment from "./getIsInternationalWireUnitCoPayment";

type PaymentMethodName = {
  shortMethodName: string;
  longMethodName: string;
};

const getPaymentMethodName = (payment: Payment): PaymentMethodName => {
  if (getIsInternationalWireUnitCoPayment(payment)) {
    return {
      shortMethodName: "Intl. wire",
      longMethodName: "International wire",
    };
  }
  switch (payment.type) {
    case "achPayment":
      return {
        shortMethodName: "ACH",
        longMethodName: "ACH transaction",
      };
    case "bookPayment":
      return {
        shortMethodName: "Book",
        longMethodName: "Book transaction",
      };
    case "wirePayment":
      return {
        shortMethodName: "Wire",
        longMethodName: "Wire transaction",
      };
  }
};

export default getPaymentMethodName;
