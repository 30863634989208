import React from "react";
import PaymentSwitcherAutoPaymentRep from "reps/PaymentSwitcherAutoPaymentRep";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmountV2";
import { Column } from "ui/table/Table";

type Props = {
  payment: PaymentSwitcherAutoPaymentRep;
};

const Last3MonthsCell: React.FC<Props> = ({ payment }) => {
  const amount = payment.last3Months.amount;

  return (
    <div className="flex flex-col gap-1">
      <MoneyAmount
        as="p"
        size={14}
        weight="medium"
        color={colors.grey[800]}
        showCurrencySymbol
        showCents={false}
        amount={amount.amount}
        currencyCode={amount.currency}
      />
    </div>
  );
};

export default Object.assign(Last3MonthsCell, {
  column: {
    title: "Last 3 months",
    cellRender: (payment) => <Last3MonthsCell payment={payment} />,
    headerClassName: "min-w-[174px] whitespace-nowrap",
  } satisfies Column<PaymentSwitcherAutoPaymentRep>,
});
