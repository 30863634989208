import RutterConnectionRep from "reps/RutterConnectionRep";

const getAccountingSoftwareName = (
  integration: RutterConnectionRep.RutterAccountingPlatform
): string => {
  switch (integration) {
    case "QUICKBOOKS":
      return "Quickbooks";
    case "XERO":
      return "Xero";
    case "NETSUITE":
      return "Netsuite";
  }
};

export default getAccountingSoftwareName;
