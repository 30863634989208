import { X } from "@phosphor-icons/react";
import { ClearIndicatorProps, GroupBase, components } from "react-select";

import styles from "./ClearIndicator.module.scss";

type Props<TOption> = ClearIndicatorProps<TOption, boolean, GroupBase<TOption>>;

const ClearIndicator = <TOption,>({ ...props }: Props<TOption>) => {
  return (
    <components.ClearIndicator {...props} className={styles.clear}>
      <X size={16} />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
