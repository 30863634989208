/* eslint sort-keys: ["error", "asc", { "natural": true }] */

type CountryRecord = {
  alpha2: string;
  alpha3: string;
  flag: string;
  name: string;
  number: string;
};

/**
 * A list of all ISO 3166 countries and their respective flags.
 *
 * Sources:
 * - Countires: https://www.iban.com/country-codes
 * - Flags: https://github.com/opencollective/country-currency-emoji-flags/blob/main/country-data.json
 */
const countries = {
  AD: {
    alpha2: "AD",
    alpha3: "AND",
    flag: "🇦🇩",
    name: "Andorra",
    number: "020",
  },
  AE: {
    alpha2: "AE",
    alpha3: "ARE",
    flag: "🇦🇪",
    name: "United Arab Emirates (the)",
    number: "784",
  },
  AF: {
    alpha2: "AF",
    alpha3: "AFG",
    flag: "🇦🇫",
    name: "Afghanistan",
    number: "004",
  },
  AG: {
    alpha2: "AG",
    alpha3: "ATG",
    flag: "🇦🇬",
    name: "Antigua and Barbuda",
    number: "028",
  },
  AI: {
    alpha2: "AI",
    alpha3: "AIA",
    flag: "🇦🇮",
    name: "Anguilla",
    number: "660",
  },
  AL: {
    alpha2: "AL",
    alpha3: "ALB",
    flag: "🇦🇱",
    name: "Albania",
    number: "008",
  },
  AM: {
    alpha2: "AM",
    alpha3: "ARM",
    flag: "🇦🇲",
    name: "Armenia",
    number: "051",
  },
  AO: {
    alpha2: "AO",
    alpha3: "AGO",
    flag: "🇦🇴",
    name: "Angola",
    number: "024",
  },
  AQ: {
    alpha2: "AQ",
    alpha3: "ATA",
    flag: "🇦🇶",
    name: "Antarctica",
    number: "010",
  },
  AR: {
    alpha2: "AR",
    alpha3: "ARG",
    flag: "🇦🇷",
    name: "Argentina",
    number: "032",
  },
  AS: {
    alpha2: "AS",
    alpha3: "ASM",
    flag: "🇦🇸",
    name: "American Samoa",
    number: "016",
  },
  AT: {
    alpha2: "AT",
    alpha3: "AUT",
    flag: "🇦🇹",
    name: "Austria",
    number: "040",
  },
  AU: {
    alpha2: "AU",
    alpha3: "AUS",
    flag: "🇦🇺",
    name: "Australia",
    number: "036",
  },
  AW: {
    alpha2: "AW",
    alpha3: "ABW",
    flag: "🇦🇼",
    name: "Aruba",
    number: "533",
  },
  AX: {
    alpha2: "AX",
    alpha3: "ALA",
    flag: "🇦🇽",
    name: "Åland Islands",
    number: "248",
  },
  AZ: {
    alpha2: "AZ",
    alpha3: "AZE",
    flag: "🇦🇿",
    name: "Azerbaijan",
    number: "031",
  },
  BA: {
    alpha2: "BA",
    alpha3: "BIH",
    flag: "🇧🇦",
    name: "Bosnia and Herzegovina",
    number: "070",
  },
  BB: {
    alpha2: "BB",
    alpha3: "BRB",
    flag: "🇧🇧",
    name: "Barbados",
    number: "052",
  },
  BD: {
    alpha2: "BD",
    alpha3: "BGD",
    flag: "🇧🇩",
    name: "Bangladesh",
    number: "050",
  },
  BE: {
    alpha2: "BE",
    alpha3: "BEL",
    flag: "🇧🇪",
    name: "Belgium",
    number: "056",
  },
  BF: {
    alpha2: "BF",
    alpha3: "BFA",
    flag: "🇧🇫",
    name: "Burkina Faso",
    number: "854",
  },
  BG: {
    alpha2: "BG",
    alpha3: "BGR",
    flag: "🇧🇬",
    name: "Bulgaria",
    number: "100",
  },
  BH: {
    alpha2: "BH",
    alpha3: "BHR",
    flag: "🇧🇭",
    name: "Bahrain",
    number: "048",
  },
  BI: {
    alpha2: "BI",
    alpha3: "BDI",
    flag: "🇧🇮",
    name: "Burundi",
    number: "108",
  },
  BJ: {
    alpha2: "BJ",
    alpha3: "BEN",
    flag: "🇧🇯",
    name: "Benin",
    number: "204",
  },
  BL: {
    alpha2: "BL",
    alpha3: "BLM",
    flag: "🇧🇱",
    name: "Saint Barthélemy",
    number: "652",
  },
  BM: {
    alpha2: "BM",
    alpha3: "BMU",
    flag: "🇧🇲",
    name: "Bermuda",
    number: "060",
  },
  BN: {
    alpha2: "BN",
    alpha3: "BRN",
    flag: "🇧🇳",
    name: "Brunei Darussalam",
    number: "096",
  },
  BO: {
    alpha2: "BO",
    alpha3: "BOL",
    flag: "🇧🇴",
    name: "Bolivia (Plurinational State of)",
    number: "068",
  },
  BQ: {
    alpha2: "BQ",
    alpha3: "BES",
    flag: "🇧🇶",
    name: "Bonaire, Sint Eustatius and Saba",
    number: "535",
  },
  BR: {
    alpha2: "BR",
    alpha3: "BRA",
    flag: "🇧🇷",
    name: "Brazil",
    number: "076",
  },
  BS: {
    alpha2: "BS",
    alpha3: "BHS",
    flag: "🇧🇸",
    name: "Bahamas (the)",
    number: "044",
  },
  BT: {
    alpha2: "BT",
    alpha3: "BTN",
    flag: "🇧🇹",
    name: "Bhutan",
    number: "064",
  },
  BV: {
    alpha2: "BV",
    alpha3: "BVT",
    flag: "🇧🇻",
    name: "Bouvet Island",
    number: "074",
  },
  BW: {
    alpha2: "BW",
    alpha3: "BWA",
    flag: "🇧🇼",
    name: "Botswana",
    number: "072",
  },
  BY: {
    alpha2: "BY",
    alpha3: "BLR",
    flag: "🇧🇾",
    name: "Belarus",
    number: "112",
  },
  BZ: {
    alpha2: "BZ",
    alpha3: "BLZ",
    flag: "🇧🇿",
    name: "Belize",
    number: "084",
  },
  CA: {
    alpha2: "CA",
    alpha3: "CAN",
    flag: "🇨🇦",
    name: "Canada",
    number: "124",
  },
  CC: {
    alpha2: "CC",
    alpha3: "CCK",
    flag: "🇨🇨",
    name: "Cocos (Keeling) Islands (the)",
    number: "166",
  },
  CD: {
    alpha2: "CD",
    alpha3: "COD",
    flag: "🇨🇩",
    name: "Congo (the Democratic Republic of the)",
    number: "180",
  },
  CF: {
    alpha2: "CF",
    alpha3: "CAF",
    flag: "🇨🇫",
    name: "Central African Republic (the)",
    number: "140",
  },
  CG: {
    alpha2: "CG",
    alpha3: "COG",
    flag: "🇨🇬",
    name: "Congo (the)",
    number: "178",
  },
  CH: {
    alpha2: "CH",
    alpha3: "CHE",
    flag: "🇨🇭",
    name: "Switzerland",
    number: "756",
  },
  CI: {
    alpha2: "CI",
    alpha3: "CIV",
    flag: "🇨🇮",
    name: "Côte d'Ivoire",
    number: "384",
  },
  CK: {
    alpha2: "CK",
    alpha3: "COK",
    flag: "🇨🇰",
    name: "Cook Islands (the)",
    number: "184",
  },
  CL: {
    alpha2: "CL",
    alpha3: "CHL",
    flag: "🇨🇱",
    name: "Chile",
    number: "152",
  },
  CM: {
    alpha2: "CM",
    alpha3: "CMR",
    flag: "🇨🇲",
    name: "Cameroon",
    number: "120",
  },
  CN: {
    alpha2: "CN",
    alpha3: "CHN",
    flag: "🇨🇳",
    name: "China",
    number: "156",
  },
  CO: {
    alpha2: "CO",
    alpha3: "COL",
    flag: "🇨🇴",
    name: "Colombia",
    number: "170",
  },
  CR: {
    alpha2: "CR",
    alpha3: "CRI",
    flag: "🇨🇷",
    name: "Costa Rica",
    number: "188",
  },
  CU: {
    alpha2: "CU",
    alpha3: "CUB",
    flag: "🇨🇺",
    name: "Cuba",
    number: "192",
  },
  CV: {
    alpha2: "CV",
    alpha3: "CPV",
    flag: "🇨🇻",
    name: "Cabo Verde",
    number: "132",
  },
  CW: {
    alpha2: "CW",
    alpha3: "CUW",
    flag: "🇨🇼",
    name: "Curaçao",
    number: "531",
  },
  CX: {
    alpha2: "CX",
    alpha3: "CXR",
    flag: "🇨🇽",
    name: "Christmas Island",
    number: "162",
  },
  CY: {
    alpha2: "CY",
    alpha3: "CYP",
    flag: "🇨🇾",
    name: "Cyprus",
    number: "196",
  },
  CZ: {
    alpha2: "CZ",
    alpha3: "CZE",
    flag: "🇨🇿",
    name: "Czechia",
    number: "203",
  },
  DE: {
    alpha2: "DE",
    alpha3: "DEU",
    flag: "🇩🇪",
    name: "Germany",
    number: "276",
  },
  DJ: {
    alpha2: "DJ",
    alpha3: "DJI",
    flag: "🇩🇯",
    name: "Djibouti",
    number: "262",
  },
  DK: {
    alpha2: "DK",
    alpha3: "DNK",
    flag: "🇩🇰",
    name: "Denmark",
    number: "208",
  },
  DM: {
    alpha2: "DM",
    alpha3: "DMA",
    flag: "🇩🇲",
    name: "Dominica",
    number: "212",
  },
  DO: {
    alpha2: "DO",
    alpha3: "DOM",
    flag: "🇩🇴",
    name: "Dominican Republic (the)",
    number: "214",
  },
  DZ: {
    alpha2: "DZ",
    alpha3: "DZA",
    flag: "🇩🇿",
    name: "Algeria",
    number: "012",
  },
  EC: {
    alpha2: "EC",
    alpha3: "ECU",
    flag: "🇪🇨",
    name: "Ecuador",
    number: "218",
  },
  EE: {
    alpha2: "EE",
    alpha3: "EST",
    flag: "🇪🇪",
    name: "Estonia",
    number: "233",
  },
  EG: {
    alpha2: "EG",
    alpha3: "EGY",
    flag: "🇪🇬",
    name: "Egypt",
    number: "818",
  },
  EH: {
    alpha2: "EH",
    alpha3: "ESH",
    flag: "🇪🇭",
    name: "Western Sahara",
    number: "732",
  },
  ER: {
    alpha2: "ER",
    alpha3: "ERI",
    flag: "🇪🇷",
    name: "Eritrea",
    number: "232",
  },
  ES: {
    alpha2: "ES",
    alpha3: "ESP",
    flag: "🇪🇸",
    name: "Spain",
    number: "724",
  },
  ET: {
    alpha2: "ET",
    alpha3: "ETH",
    flag: "🇪🇹",
    name: "Ethiopia",
    number: "231",
  },
  FI: {
    alpha2: "FI",
    alpha3: "FIN",
    flag: "🇫🇮",
    name: "Finland",
    number: "246",
  },
  FJ: {
    alpha2: "FJ",
    alpha3: "FJI",
    flag: "🇫🇯",
    name: "Fiji",
    number: "242",
  },
  FK: {
    alpha2: "FK",
    alpha3: "FLK",
    flag: "🇫🇰",
    name: "Falkland Islands (the) [Malvinas]",
    number: "238",
  },
  FM: {
    alpha2: "FM",
    alpha3: "FSM",
    flag: "🇫🇲",
    name: "Micronesia (Federated States of)",
    number: "583",
  },
  FO: {
    alpha2: "FO",
    alpha3: "FRO",
    flag: "🇫🇴",
    name: "Faroe Islands (the)",
    number: "234",
  },
  FR: {
    alpha2: "FR",
    alpha3: "FRA",
    flag: "🇫🇷",
    name: "France",
    number: "250",
  },
  GA: {
    alpha2: "GA",
    alpha3: "GAB",
    flag: "🇬🇦",
    name: "Gabon",
    number: "266",
  },
  GB: {
    alpha2: "GB",
    alpha3: "GBR",
    flag: "🇬🇧",
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    number: "826",
  },
  GD: {
    alpha2: "GD",
    alpha3: "GRD",
    flag: "🇬🇩",
    name: "Grenada",
    number: "308",
  },
  GE: {
    alpha2: "GE",
    alpha3: "GEO",
    flag: "🇬🇪",
    name: "Georgia",
    number: "268",
  },
  GF: {
    alpha2: "GF",
    alpha3: "GUF",
    flag: "🇬🇫",
    name: "French Guiana",
    number: "254",
  },
  GG: {
    alpha2: "GG",
    alpha3: "GGY",
    flag: "🇬🇬",
    name: "Guernsey",
    number: "831",
  },
  GH: {
    alpha2: "GH",
    alpha3: "GHA",
    flag: "🇬🇭",
    name: "Ghana",
    number: "288",
  },
  GI: {
    alpha2: "GI",
    alpha3: "GIB",
    flag: "🇬🇮",
    name: "Gibraltar",
    number: "292",
  },
  GL: {
    alpha2: "GL",
    alpha3: "GRL",
    flag: "🇬🇱",
    name: "Greenland",
    number: "304",
  },
  GM: {
    alpha2: "GM",
    alpha3: "GMB",
    flag: "🇬🇲",
    name: "Gambia (the)",
    number: "270",
  },
  GN: {
    alpha2: "GN",
    alpha3: "GIN",
    flag: "🇬🇳",
    name: "Guinea",
    number: "324",
  },
  GP: {
    alpha2: "GP",
    alpha3: "GLP",
    flag: "🇬🇵",
    name: "Guadeloupe",
    number: "312",
  },
  GQ: {
    alpha2: "GQ",
    alpha3: "GNQ",
    flag: "🇬🇶",
    name: "Equatorial Guinea",
    number: "226",
  },
  GR: {
    alpha2: "GR",
    alpha3: "GRC",
    flag: "🇬🇷",
    name: "Greece",
    number: "300",
  },
  GS: {
    alpha2: "GS",
    alpha3: "SGS",
    flag: "🇬🇸",
    name: "South Georgia and the South Sandwich Islands",
    number: "239",
  },
  GT: {
    alpha2: "GT",
    alpha3: "GTM",
    flag: "🇬🇹",
    name: "Guatemala",
    number: "320",
  },
  GU: {
    alpha2: "GU",
    alpha3: "GUM",
    flag: "🇬🇺",
    name: "Guam",
    number: "316",
  },
  GW: {
    alpha2: "GW",
    alpha3: "GNB",
    flag: "🇬🇼",
    name: "Guinea-Bissau",
    number: "624",
  },
  GY: {
    alpha2: "GY",
    alpha3: "GUY",
    flag: "🇬🇾",
    name: "Guyana",
    number: "328",
  },
  HK: {
    alpha2: "HK",
    alpha3: "HKG",
    flag: "🇭🇰",
    name: "Hong Kong",
    number: "344",
  },
  HM: {
    alpha2: "HM",
    alpha3: "HMD",
    flag: "🇭🇲",
    name: "Heard Island and McDonald Islands",
    number: "334",
  },
  HN: {
    alpha2: "HN",
    alpha3: "HND",
    flag: "🇭🇳",
    name: "Honduras",
    number: "340",
  },
  HR: {
    alpha2: "HR",
    alpha3: "HRV",
    flag: "🇭🇷",
    name: "Croatia",
    number: "191",
  },
  HT: {
    alpha2: "HT",
    alpha3: "HTI",
    flag: "🇭🇹",
    name: "Haiti",
    number: "332",
  },
  HU: {
    alpha2: "HU",
    alpha3: "HUN",
    flag: "🇭🇺",
    name: "Hungary",
    number: "348",
  },
  ID: {
    alpha2: "ID",
    alpha3: "IDN",
    flag: "🇮🇩",
    name: "Indonesia",
    number: "360",
  },
  IE: {
    alpha2: "IE",
    alpha3: "IRL",
    flag: "🇮🇪",
    name: "Ireland",
    number: "372",
  },
  IL: {
    alpha2: "IL",
    alpha3: "ISR",
    flag: "🇮🇱",
    name: "Israel",
    number: "376",
  },
  IM: {
    alpha2: "IM",
    alpha3: "IMN",
    flag: "🇮🇲",
    name: "Isle of Man",
    number: "833",
  },
  IN: {
    alpha2: "IN",
    alpha3: "IND",
    flag: "🇮🇳",
    name: "India",
    number: "356",
  },
  IO: {
    alpha2: "IO",
    alpha3: "IOT",
    flag: "🇮🇴",
    name: "British Indian Ocean Territory (the)",
    number: "086",
  },
  IQ: {
    alpha2: "IQ",
    alpha3: "IRQ",
    flag: "🇮🇶",
    name: "Iraq",
    number: "368",
  },
  IR: {
    alpha2: "IR",
    alpha3: "IRN",
    flag: "🇮🇷",
    name: "Iran (Islamic Republic of)",
    number: "364",
  },
  IS: {
    alpha2: "IS",
    alpha3: "ISL",
    flag: "🇮🇸",
    name: "Iceland",
    number: "352",
  },
  IT: {
    alpha2: "IT",
    alpha3: "ITA",
    flag: "🇮🇹",
    name: "Italy",
    number: "380",
  },
  JE: {
    alpha2: "JE",
    alpha3: "JEY",
    flag: "🇯🇪",
    name: "Jersey",
    number: "832",
  },
  JM: {
    alpha2: "JM",
    alpha3: "JAM",
    flag: "🇯🇲",
    name: "Jamaica",
    number: "388",
  },
  JO: {
    alpha2: "JO",
    alpha3: "JOR",
    flag: "🇯🇴",
    name: "Jordan",
    number: "400",
  },
  JP: {
    alpha2: "JP",
    alpha3: "JPN",
    flag: "🇯🇵",
    name: "Japan",
    number: "392",
  },
  KE: {
    alpha2: "KE",
    alpha3: "KEN",
    flag: "🇰🇪",
    name: "Kenya",
    number: "404",
  },
  KG: {
    alpha2: "KG",
    alpha3: "KGZ",
    flag: "🇰🇬",
    name: "Kyrgyzstan",
    number: "417",
  },
  KH: {
    alpha2: "KH",
    alpha3: "KHM",
    flag: "🇰🇭",
    name: "Cambodia",
    number: "116",
  },
  KI: {
    alpha2: "KI",
    alpha3: "KIR",
    flag: "🇰🇮",
    name: "Kiribati",
    number: "296",
  },
  KM: {
    alpha2: "KM",
    alpha3: "COM",
    flag: "🇰🇲",
    name: "Comoros (the)",
    number: "174",
  },
  KN: {
    alpha2: "KN",
    alpha3: "KNA",
    flag: "🇰🇳",
    name: "Saint Kitts and Nevis",
    number: "659",
  },
  KP: {
    alpha2: "KP",
    alpha3: "PRK",
    flag: "🇰🇵",
    name: "Korea (the Democratic People's Republic of)",
    number: "408",
  },
  KR: {
    alpha2: "KR",
    alpha3: "KOR",
    flag: "🇰🇷",
    name: "Korea (the Republic of)",
    number: "410",
  },
  KW: {
    alpha2: "KW",
    alpha3: "KWT",
    flag: "🇰🇼",
    name: "Kuwait",
    number: "414",
  },
  KY: {
    alpha2: "KY",
    alpha3: "CYM",
    flag: "🇰🇾",
    name: "Cayman Islands (the)",
    number: "136",
  },
  KZ: {
    alpha2: "KZ",
    alpha3: "KAZ",
    flag: "🇰🇿",
    name: "Kazakhstan",
    number: "398",
  },
  LA: {
    alpha2: "LA",
    alpha3: "LAO",
    flag: "🇱🇦",
    name: "Lao People's Democratic Republic (the)",
    number: "418",
  },
  LB: {
    alpha2: "LB",
    alpha3: "LBN",
    flag: "🇱🇧",
    name: "Lebanon",
    number: "422",
  },
  LC: {
    alpha2: "LC",
    alpha3: "LCA",
    flag: "🇱🇨",
    name: "Saint Lucia",
    number: "662",
  },
  LI: {
    alpha2: "LI",
    alpha3: "LIE",
    flag: "🇱🇮",
    name: "Liechtenstein",
    number: "438",
  },
  LK: {
    alpha2: "LK",
    alpha3: "LKA",
    flag: "🇱🇰",
    name: "Sri Lanka",
    number: "144",
  },
  LR: {
    alpha2: "LR",
    alpha3: "LBR",
    flag: "🇱🇷",
    name: "Liberia",
    number: "430",
  },
  LS: {
    alpha2: "LS",
    alpha3: "LSO",
    flag: "🇱🇸",
    name: "Lesotho",
    number: "426",
  },
  LT: {
    alpha2: "LT",
    alpha3: "LTU",
    flag: "🇱🇹",
    name: "Lithuania",
    number: "440",
  },
  LU: {
    alpha2: "LU",
    alpha3: "LUX",
    flag: "🇱🇺",
    name: "Luxembourg",
    number: "442",
  },
  LV: {
    alpha2: "LV",
    alpha3: "LVA",
    flag: "🇱🇻",
    name: "Latvia",
    number: "428",
  },
  LY: {
    alpha2: "LY",
    alpha3: "LBY",
    flag: "🇱🇾",
    name: "Libya",
    number: "434",
  },
  MA: {
    alpha2: "MA",
    alpha3: "MAR",
    flag: "🇲🇦",
    name: "Morocco",
    number: "504",
  },
  MC: {
    alpha2: "MC",
    alpha3: "MCO",
    flag: "🇲🇨",
    name: "Monaco",
    number: "492",
  },
  MD: {
    alpha2: "MD",
    alpha3: "MDA",
    flag: "🇲🇩",
    name: "Moldova (the Republic of)",
    number: "498",
  },
  ME: {
    alpha2: "ME",
    alpha3: "MNE",
    flag: "🇲🇪",
    name: "Montenegro",
    number: "499",
  },
  MF: {
    alpha2: "MF",
    alpha3: "MAF",
    flag: "🇲🇫",
    name: "Saint Martin (French part)",
    number: "663",
  },
  MG: {
    alpha2: "MG",
    alpha3: "MDG",
    flag: "🇲🇬",
    name: "Madagascar",
    number: "450",
  },
  MH: {
    alpha2: "MH",
    alpha3: "MHL",
    flag: "🇲🇭",
    name: "Marshall Islands (the)",
    number: "584",
  },
  MK: {
    alpha2: "MK",
    alpha3: "MKD",
    flag: "🇲🇰",
    name: "Republic of North Macedonia",
    number: "807",
  },
  ML: {
    alpha2: "ML",
    alpha3: "MLI",
    flag: "🇲🇱",
    name: "Mali",
    number: "466",
  },
  MM: {
    alpha2: "MM",
    alpha3: "MMR",
    flag: "🇲🇲",
    name: "Myanmar",
    number: "104",
  },
  MN: {
    alpha2: "MN",
    alpha3: "MNG",
    flag: "🇲🇳",
    name: "Mongolia",
    number: "496",
  },
  MO: {
    alpha2: "MO",
    alpha3: "MAC",
    flag: "🇲🇴",
    name: "Macao",
    number: "446",
  },
  MP: {
    alpha2: "MP",
    alpha3: "MNP",
    flag: "🇲🇵",
    name: "Northern Mariana Islands (the)",
    number: "580",
  },
  MQ: {
    alpha2: "MQ",
    alpha3: "MTQ",
    flag: "🇲🇶",
    name: "Martinique",
    number: "474",
  },
  MR: {
    alpha2: "MR",
    alpha3: "MRT",
    flag: "🇲🇷",
    name: "Mauritania",
    number: "478",
  },
  MS: {
    alpha2: "MS",
    alpha3: "MSR",
    flag: "🇲🇸",
    name: "Montserrat",
    number: "500",
  },
  MT: {
    alpha2: "MT",
    alpha3: "MLT",
    flag: "🇲🇹",
    name: "Malta",
    number: "470",
  },
  MU: {
    alpha2: "MU",
    alpha3: "MUS",
    flag: "🇲🇺",
    name: "Mauritius",
    number: "480",
  },
  MV: {
    alpha2: "MV",
    alpha3: "MDV",
    flag: "🇲🇻",
    name: "Maldives",
    number: "462",
  },
  MW: {
    alpha2: "MW",
    alpha3: "MWI",
    flag: "🇲🇼",
    name: "Malawi",
    number: "454",
  },
  MX: {
    alpha2: "MX",
    alpha3: "MEX",
    flag: "🇲🇽",
    name: "Mexico",
    number: "484",
  },
  MY: {
    alpha2: "MY",
    alpha3: "MYS",
    flag: "🇲🇾",
    name: "Malaysia",
    number: "458",
  },
  MZ: {
    alpha2: "MZ",
    alpha3: "MOZ",
    flag: "🇲🇿",
    name: "Mozambique",
    number: "508",
  },
  NA: {
    alpha2: "NA",
    alpha3: "NAM",
    flag: "🇳🇦",
    name: "Namibia",
    number: "516",
  },
  NC: {
    alpha2: "NC",
    alpha3: "NCL",
    flag: "🇳🇨",
    name: "New Caledonia",
    number: "540",
  },
  NE: {
    alpha2: "NE",
    alpha3: "NER",
    flag: "🇳🇪",
    name: "Niger (the)",
    number: "562",
  },
  NF: {
    alpha2: "NF",
    alpha3: "NFK",
    flag: "🇳🇫",
    name: "Norfolk Island",
    number: "574",
  },
  NG: {
    alpha2: "NG",
    alpha3: "NGA",
    flag: "🇳🇬",
    name: "Nigeria",
    number: "566",
  },
  NI: {
    alpha2: "NI",
    alpha3: "NIC",
    flag: "🇳🇮",
    name: "Nicaragua",
    number: "558",
  },
  NL: {
    alpha2: "NL",
    alpha3: "NLD",
    flag: "🇳🇱",
    name: "Netherlands (the)",
    number: "528",
  },
  NO: {
    alpha2: "NO",
    alpha3: "NOR",
    flag: "🇳🇴",
    name: "Norway",
    number: "578",
  },
  NP: {
    alpha2: "NP",
    alpha3: "NPL",
    flag: "🇳🇵",
    name: "Nepal",
    number: "524",
  },
  NR: {
    alpha2: "NR",
    alpha3: "NRU",
    flag: "🇳🇷",
    name: "Nauru",
    number: "520",
  },
  NU: {
    alpha2: "NU",
    alpha3: "NIU",
    flag: "🇳🇺",
    name: "Niue",
    number: "570",
  },
  NZ: {
    alpha2: "NZ",
    alpha3: "NZL",
    flag: "🇳🇿",
    name: "New Zealand",
    number: "554",
  },
  OM: {
    alpha2: "OM",
    alpha3: "OMN",
    flag: "🇴🇲",
    name: "Oman",
    number: "512",
  },
  PA: {
    alpha2: "PA",
    alpha3: "PAN",
    flag: "🇵🇦",
    name: "Panama",
    number: "591",
  },
  PE: {
    alpha2: "PE",
    alpha3: "PER",
    flag: "🇵🇪",
    name: "Peru",
    number: "604",
  },
  PF: {
    alpha2: "PF",
    alpha3: "PYF",
    flag: "🇵🇫",
    name: "French Polynesia",
    number: "258",
  },
  PG: {
    alpha2: "PG",
    alpha3: "PNG",
    flag: "🇵🇬",
    name: "Papua New Guinea",
    number: "598",
  },
  PH: {
    alpha2: "PH",
    alpha3: "PHL",
    flag: "🇵🇭",
    name: "Philippines (the)",
    number: "608",
  },
  PK: {
    alpha2: "PK",
    alpha3: "PAK",
    flag: "🇵🇰",
    name: "Pakistan",
    number: "586",
  },
  PL: {
    alpha2: "PL",
    alpha3: "POL",
    flag: "🇵🇱",
    name: "Poland",
    number: "616",
  },
  PM: {
    alpha2: "PM",
    alpha3: "SPM",
    flag: "🇵🇲",
    name: "Saint Pierre and Miquelon",
    number: "666",
  },
  PN: {
    alpha2: "PN",
    alpha3: "PCN",
    flag: "🇵🇳",
    name: "Pitcairn",
    number: "612",
  },
  PR: {
    alpha2: "PR",
    alpha3: "PRI",
    flag: "🇵🇷",
    name: "Puerto Rico",
    number: "630",
  },
  PS: {
    alpha2: "PS",
    alpha3: "PSE",
    flag: "🇵🇸",
    name: "Palestine, State of",
    number: "275",
  },
  PT: {
    alpha2: "PT",
    alpha3: "PRT",
    flag: "🇵🇹",
    name: "Portugal",
    number: "620",
  },
  PW: {
    alpha2: "PW",
    alpha3: "PLW",
    flag: "🇵🇼",
    name: "Palau",
    number: "585",
  },
  PY: {
    alpha2: "PY",
    alpha3: "PRY",
    flag: "🇵🇾",
    name: "Paraguay",
    number: "600",
  },
  QA: {
    alpha2: "QA",
    alpha3: "QAT",
    flag: "🇶🇦",
    name: "Qatar",
    number: "634",
  },
  RE: {
    alpha2: "RE",
    alpha3: "REU",
    flag: "🇷🇪",
    name: "Réunion",
    number: "638",
  },
  RO: {
    alpha2: "RO",
    alpha3: "ROU",
    flag: "🇷🇴",
    name: "Romania",
    number: "642",
  },
  RS: {
    alpha2: "RS",
    alpha3: "SRB",
    flag: "🇷🇸",
    name: "Serbia",
    number: "688",
  },
  RU: {
    alpha2: "RU",
    alpha3: "RUS",
    flag: "🇷🇺",
    name: "Russian Federation (the)",
    number: "643",
  },
  RW: {
    alpha2: "RW",
    alpha3: "RWA",
    flag: "🇷🇼",
    name: "Rwanda",
    number: "646",
  },
  SA: {
    alpha2: "SA",
    alpha3: "SAU",
    flag: "🇸🇦",
    name: "Saudi Arabia",
    number: "682",
  },
  SB: {
    alpha2: "SB",
    alpha3: "SLB",
    flag: "🇸🇧",
    name: "Solomon Islands",
    number: "090",
  },
  SC: {
    alpha2: "SC",
    alpha3: "SYC",
    flag: "🇸🇨",
    name: "Seychelles",
    number: "690",
  },
  SD: {
    alpha2: "SD",
    alpha3: "SDN",
    flag: "🇸🇩",
    name: "Sudan (the)",
    number: "729",
  },
  SE: {
    alpha2: "SE",
    alpha3: "SWE",
    flag: "🇸🇪",
    name: "Sweden",
    number: "752",
  },
  SG: {
    alpha2: "SG",
    alpha3: "SGP",
    flag: "🇸🇬",
    name: "Singapore",
    number: "702",
  },
  SH: {
    alpha2: "SH",
    alpha3: "SHN",
    flag: "🇸🇭",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    number: "654",
  },
  SI: {
    alpha2: "SI",
    alpha3: "SVN",
    flag: "🇸🇮",
    name: "Slovenia",
    number: "705",
  },
  SJ: {
    alpha2: "SJ",
    alpha3: "SJM",
    flag: "🇸🇯",
    name: "Svalbard and Jan Mayen",
    number: "744",
  },
  SK: {
    alpha2: "SK",
    alpha3: "SVK",
    flag: "🇸🇰",
    name: "Slovakia",
    number: "703",
  },
  SL: {
    alpha2: "SL",
    alpha3: "SLE",
    flag: "🇸🇱",
    name: "Sierra Leone",
    number: "694",
  },
  SM: {
    alpha2: "SM",
    alpha3: "SMR",
    flag: "🇸🇲",
    name: "San Marino",
    number: "674",
  },
  SN: {
    alpha2: "SN",
    alpha3: "SEN",
    flag: "🇸🇳",
    name: "Senegal",
    number: "686",
  },
  SO: {
    alpha2: "SO",
    alpha3: "SOM",
    flag: "🇸🇴",
    name: "Somalia",
    number: "706",
  },
  SR: {
    alpha2: "SR",
    alpha3: "SUR",
    flag: "🇸🇷",
    name: "Suriname",
    number: "740",
  },
  SS: {
    alpha2: "SS",
    alpha3: "SSD",
    flag: "🇸🇸",
    name: "South Sudan",
    number: "728",
  },
  ST: {
    alpha2: "ST",
    alpha3: "STP",
    flag: "🇸🇹",
    name: "Sao Tome and Principe",
    number: "678",
  },
  SV: {
    alpha2: "SV",
    alpha3: "SLV",
    flag: "🇸🇻",
    name: "El Salvador",
    number: "222",
  },
  SX: {
    alpha2: "SX",
    alpha3: "SXM",
    flag: "🇸🇽",
    name: "Sint Maarten (Dutch part)",
    number: "534",
  },
  SY: {
    alpha2: "SY",
    alpha3: "SYR",
    flag: "🇸🇾",
    name: "Syrian Arab Republic",
    number: "760",
  },
  SZ: {
    alpha2: "SZ",
    alpha3: "SWZ",
    flag: "🇸🇿",
    name: "Eswatini",
    number: "748",
  },
  TC: {
    alpha2: "TC",
    alpha3: "TCA",
    flag: "🇹🇨",
    name: "Turks and Caicos Islands (the)",
    number: "796",
  },
  TD: {
    alpha2: "TD",
    alpha3: "TCD",
    flag: "🇹🇩",
    name: "Chad",
    number: "148",
  },
  TF: {
    alpha2: "TF",
    alpha3: "ATF",
    flag: "🇹🇫",
    name: "French Southern Territories (the)",
    number: "260",
  },
  TG: {
    alpha2: "TG",
    alpha3: "TGO",
    flag: "🇹🇬",
    name: "Togo",
    number: "768",
  },
  TH: {
    alpha2: "TH",
    alpha3: "THA",
    flag: "🇹🇭",
    name: "Thailand",
    number: "764",
  },
  TJ: {
    alpha2: "TJ",
    alpha3: "TJK",
    flag: "🇹🇯",
    name: "Tajikistan",
    number: "762",
  },
  TK: {
    alpha2: "TK",
    alpha3: "TKL",
    flag: "🇹🇰",
    name: "Tokelau",
    number: "772",
  },
  TL: {
    alpha2: "TL",
    alpha3: "TLS",
    flag: "🇹🇱",
    name: "Timor-Leste",
    number: "626",
  },
  TM: {
    alpha2: "TM",
    alpha3: "TKM",
    flag: "🇹🇲",
    name: "Turkmenistan",
    number: "795",
  },
  TN: {
    alpha2: "TN",
    alpha3: "TUN",
    flag: "🇹🇳",
    name: "Tunisia",
    number: "788",
  },
  TO: {
    alpha2: "TO",
    alpha3: "TON",
    flag: "🇹🇴",
    name: "Tonga",
    number: "776",
  },
  TR: {
    alpha2: "TR",
    alpha3: "TUR",
    flag: "🇹🇷",
    name: "Turkey",
    number: "792",
  },
  TT: {
    alpha2: "TT",
    alpha3: "TTO",
    flag: "🇹🇹",
    name: "Trinidad and Tobago",
    number: "780",
  },
  TV: {
    alpha2: "TV",
    alpha3: "TUV",
    flag: "🇹🇻",
    name: "Tuvalu",
    number: "798",
  },
  TW: {
    alpha2: "TW",
    alpha3: "TWN",
    flag: "🇹🇼",
    name: "Taiwan (Province of China)",
    number: "158",
  },
  TZ: {
    alpha2: "TZ",
    alpha3: "TZA",
    flag: "🇹🇿",
    name: "Tanzania, United Republic of",
    number: "834",
  },
  UA: {
    alpha2: "UA",
    alpha3: "UKR",
    flag: "🇺🇦",
    name: "Ukraine",
    number: "804",
  },
  UG: {
    alpha2: "UG",
    alpha3: "UGA",
    flag: "🇺🇬",
    name: "Uganda",
    number: "800",
  },
  UM: {
    alpha2: "UM",
    alpha3: "UMI",
    flag: "🇺🇲",
    name: "United States Minor Outlying Islands (the)",
    number: "581",
  },
  US: {
    alpha2: "US",
    alpha3: "USA",
    flag: "🇺🇸",
    name: "United States of America (the)",
    number: "840",
  },
  UY: {
    alpha2: "UY",
    alpha3: "URY",
    flag: "🇺🇾",
    name: "Uruguay",
    number: "858",
  },
  UZ: {
    alpha2: "UZ",
    alpha3: "UZB",
    flag: "🇺🇿",
    name: "Uzbekistan",
    number: "860",
  },
  VA: {
    alpha2: "VA",
    alpha3: "VAT",
    flag: "🇻🇦",
    name: "Holy See (the)",
    number: "336",
  },
  VC: {
    alpha2: "VC",
    alpha3: "VCT",
    flag: "🇻🇨",
    name: "Saint Vincent and the Grenadines",
    number: "670",
  },
  VE: {
    alpha2: "VE",
    alpha3: "VEN",
    flag: "🇻🇪",
    name: "Venezuela (Bolivarian Republic of)",
    number: "862",
  },
  VG: {
    alpha2: "VG",
    alpha3: "VGB",
    flag: "🇻🇬",
    name: "Virgin Islands (British)",
    number: "092",
  },
  VI: {
    alpha2: "VI",
    alpha3: "VIR",
    flag: "🇻🇮",
    name: "Virgin Islands (U.S.)",
    number: "850",
  },
  VN: {
    alpha2: "VN",
    alpha3: "VNM",
    flag: "🇻🇳",
    name: "Viet Nam",
    number: "704",
  },
  VU: {
    alpha2: "VU",
    alpha3: "VUT",
    flag: "🇻🇺",
    name: "Vanuatu",
    number: "548",
  },
  WF: {
    alpha2: "WF",
    alpha3: "WLF",
    flag: "🇼🇫",
    name: "Wallis and Futuna",
    number: "876",
  },
  WS: {
    alpha2: "WS",
    alpha3: "WSM",
    flag: "🇼🇸",
    name: "Samoa",
    number: "882",
  },
  YE: {
    alpha2: "YE",
    alpha3: "YEM",
    flag: "🇾🇪",
    name: "Yemen",
    number: "887",
  },
  YT: {
    alpha2: "YT",
    alpha3: "MYT",
    flag: "🇾🇹",
    name: "Mayotte",
    number: "175",
  },
  ZA: {
    alpha2: "ZA",
    alpha3: "ZAF",
    flag: "🇿🇦",
    name: "South Africa",
    number: "710",
  },
  ZM: {
    alpha2: "ZM",
    alpha3: "ZMB",
    flag: "🇿🇲",
    name: "Zambia",
    number: "894",
  },
  ZW: {
    alpha2: "ZW",
    alpha3: "ZWE",
    flag: "🇿🇼",
    name: "Zimbabwe",
    number: "716",
  },
} as const satisfies Record<string, CountryRecord>;

export default countries;

export type CountryCode = keyof typeof countries;
