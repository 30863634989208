import { FC, ReactNode } from "react";
import SectionHeader from "ui/data-display/SectionHeader";
import IconTile from "ui/icons/IconTile";
import BackCaret from "ui/navigation/BackCaret";
import { Heading1 } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

type HeaderContainerProps = {
  actions?: ReactNode;
  children: ReactNode;
  className?: string;
};

export const HeaderContainer: FC<HeaderContainerProps> = ({ actions, children, className }) => {
  return (
    <div className={cn("my-4 max-w-[var(--hb-content-max-width)] tablet:my-6", className)}>
      <SectionHeader actions={actions}>{children}</SectionHeader>
    </div>
  );
};

export const HeaderTitle: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <Heading1 className={cn("font-bold", className)}>{children}</Heading1>;
};

const Header = Object.assign(HeaderContainer, {
  BackCaret: BackCaret,
  IconTile: IconTile,
  Title: HeaderTitle,
});

export default Header;
