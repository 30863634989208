import { ArrowLeft, PlusCircle, UserPlus, PencilSimpleLine } from "@phosphor-icons/react";
import { FC, FormEvent, useState, useCallback } from "react";
import PayeeRep from "reps/PayeeRep";
import { useRefreshBillLineItemsQuery } from "resources/bill-line-items/queries/useBillLineItems";
import billSyncQueryHooks from "resources/bill-syncs/queries/billSyncQueryHooks";
import shouldBillSyncBeDryRun from "resources/bill-syncs/utils/shouldBillSyncBeDryRun";
import AchPaymentMethodFieldset from "resources/payee-payment-methods/components/AchPaymentMethodFieldset";
import DomesticWirePaymentMethodFieldset from "resources/payee-payment-methods/components/DomesticWirePaymentMethodFieldset";
import InternationalWirePaymentMethodRecoilFieldset from "resources/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset";
import useInternationalWirePaymentMethodRecoilHelpers from "resources/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset/useInternationalWirePaymentMethodRecoilHelpers";
import useAchPaymentMethodForm, {
  AchPaymentMethodFormInputs,
} from "resources/payee-payment-methods/forms/useAchPaymentMethodForm";
import useDomesticWirePaymentMethodForm, {
  DomesticWirePaymentMethodFormInputs,
} from "resources/payee-payment-methods/forms/useDomesticWirePaymentMethodForm";
import PayeeDetailsFieldset from "resources/payees/components/PayeeDetailsFieldset";
import usePayeeDetailsForm, {
  PayeeDetailsFormInputs,
} from "resources/payees/forms/usePayeeDetailsForm";
import useCreatePayeeMutation from "resources/payees/mutations/useCreatePayeeMutation";
import Banner from "ui/data-display/Banner";
import DetailsSidebarBody from "ui/data-display/DetailsSidebarBody";
import Divider from "ui/data-display/Divider";
import UserAvatar from "ui/data-display/UserAvatar";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import Helper from "ui/inputs/Helper";
import SegmentControls from "ui/navigation/SegmentControls";
import Text from "ui/typography/Text";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import { TabConfigTab } from "utils/tabs/useTabState";

import useUpdateBillMutation from "../../mutations/useUpdateBillMutation";
import useBill from "../../queries/useBill";
import { useRefreshBillPaymentMethodsQueries } from "../../queries/useBillPaymentMethods";
import useBillSuggestedPayee from "../../queries/useBillSuggestedPayee";
import useBillCreatePayeeFormDefaultPayeeName from "../../search-params/useBillCreatePayeeFormDefaultPayeeName";
import useBillDetailsPayeePaymentMethodTabs from "../../search-params/useBillDetailsPayeePaymentMethodTabs";

import styles from "./BillCreatePayeeForm.module.scss";

type Props = {
  billId: string;
  onBack: () => void;
  onSuccess: (createdPayee: PayeeRep.Complete) => void;
};

const BillCreatePayeeForm: FC<Props> = ({ onBack, onSuccess, billId }) => {
  const bill = useBill(billId, { required: true });
  const billInvoiceNumber = bill.invoiceNumber;
  const dryRun = shouldBillSyncBeDryRun(bill);
  const refreshBillSyncQuery = billSyncQueryHooks.useRefreshQuery({ billId, dryRun });
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);
  const refreshBillPaymentMethodsQueries = useRefreshBillPaymentMethodsQueries();
  const suggestedPayee = useBillSuggestedPayee(billId);
  const achSuggestions = suggestedPayee?.paymentMethods.find(
    (paymentMethod) => paymentMethod.paymentMethod === "Ach"
  );

  const { tabs, activeTab, setActiveTab } = useBillDetailsPayeePaymentMethodTabs();

  if (achSuggestions) {
    (tabs.ach as TabConfigTab).displayAttentionBadge = true;
  }

  const [defaultPayeeName] = useBillCreatePayeeFormDefaultPayeeName();

  const payeeDetailsForm = usePayeeDetailsForm({
    defaultValues: {
      payeeName: defaultPayeeName || suggestedPayee?.name || "",
      payeeEmail: suggestedPayee?.emailAddress || "",
      payeePhoneNumber: suggestedPayee?.phoneNumber || "",
    },
  });

  const achPaymentMethodForm = useAchPaymentMethodForm({
    suggestedPaymentMethod: achSuggestions ?? undefined,
  });

  const domesticWirePaymentMethodForm = useDomesticWirePaymentMethodForm();

  const [internationalWireError, setInternationalWireError] = useState<string>();
  const {
    internationalWireTransferMethod,
    isValidInternational,
    hasInputValues: hasInternationalInputValues,
  } = useInternationalWirePaymentMethodRecoilHelpers({
    payeeGuid: undefined,
  });

  const { segmentTrack } = useSegment();

  const { mutateAsync: updateBill, isPending: isUpdatingBill } = useUpdateBillMutation(billId, {
    onError: () => {
      notify("error", "Something went wrong while updating the bill. Please try again.");
    },
  });

  const { mutateAsync: createPayee, isPending } = useCreatePayeeMutation({
    onSuccess: () => {
      segmentTrack(SEGMENT_EVENTS.BILL_PAY_PAYEE_CREATED);
    },
    onError: () => {
      notify("error", "Something went wrong while creating the payee. Please try again.");
    },
  });

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const payeeDetailsFormData = await new Promise<PayeeDetailsFormInputs>((resolve, reject) => {
        payeeDetailsForm.handleSubmit(resolve, reject)(e);
      });

      const transferMethodBody = await (async () => {
        if (activeTab === "ach") {
          if (achPaymentMethodForm.isBlank()) {
            return;
          }

          const achPaymentMethodFormData = await new Promise<AchPaymentMethodFormInputs>(
            (resolve, reject) => {
              achPaymentMethodForm.handleSubmit(resolve, reject)(e);
            }
          );
          return {
            achTransferMethod: {
              routingNumber: achPaymentMethodFormData.routingNumber,
              accountNumber: achPaymentMethodFormData.accountNumber,
            } satisfies PayeeRep.AchTransferMethod,
          };
        }
        if (activeTab === "domestic-wire") {
          if (domesticWirePaymentMethodForm.isBlank()) {
            return;
          }

          const domesticWirePaymentMethodData =
            await new Promise<DomesticWirePaymentMethodFormInputs>((resolve, reject) => {
              domesticWirePaymentMethodForm.handleSubmit(
                // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
                (data) => resolve(data as DomesticWirePaymentMethodFormInputs),
                reject
              )(e);
            });

          return {
            wireTransferMethod: {
              routingNumber: domesticWirePaymentMethodData.routingNumber,
              accountNumber: domesticWirePaymentMethodData.accountNumber,
            } satisfies PayeeRep.WireTransferMethod,
            // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
            address: {
              addressLine1: domesticWirePaymentMethodData.address.addressLine1?.value ?? "",
              addressLine2: domesticWirePaymentMethodData.address.addressLine2 || undefined,
              city: domesticWirePaymentMethodData.address.city,
              state: domesticWirePaymentMethodData.address.state?.value ?? "",
              zipCode: domesticWirePaymentMethodData.address.zipCode,
              country: domesticWirePaymentMethodData.address.country?.value ?? "",
            } satisfies PayeeRep.Address,
          };
        }
      })();

      // HACK(alex): We're hacking this form to work with recoil state for international wire details, so we have to trigger form validation manually.
      if (
        activeTab === "international-wire" &&
        hasInternationalInputValues &&
        !isValidInternational
      ) {
        return setInternationalWireError("Missing fields.");
      }

      const createdPayee = await createPayee({
        name: payeeDetailsFormData.payeeName,
        emailAddress: payeeDetailsFormData.payeeEmail || undefined,
        phone: payeeDetailsFormData.payeePhoneNumber || undefined,
        defaultChartOfAccountId: payeeDetailsFormData.payeeDefaultChartOfAccountId || undefined,
        ...transferMethodBody,
        ...(activeTab === "international-wire" && internationalWireTransferMethod),
      });

      await updateBill({
        payeeGuid: createdPayee.guid,
      });

      refreshBillPaymentMethodsQueries();
      refreshBillLineItemsQuery();
      refreshBillSyncQuery();

      onSuccess(createdPayee);
      notify("success", "Payee created");
    },
    [
      activeTab,
      hasInternationalInputValues,
      isValidInternational,
      createPayee,
      internationalWireTransferMethod,
      updateBill,
      refreshBillPaymentMethodsQueries,
      refreshBillLineItemsQuery,
      refreshBillSyncQuery,
      onSuccess,
      payeeDetailsForm,
      achPaymentMethodForm,
      domesticWirePaymentMethodForm,
    ]
  );

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <DetailsSidebarBody
        header={
          <DetailsSidebarBody.Header>
            <Button variant="ghost" paddingVariant="square" onClick={onBack}>
              <ArrowLeft size={24} weight="light" />
            </Button>

            <UserAvatar icon={<UserPlus />} size={44} color="grey" />

            <Text weight="medium" size={16} as="h3">
              Create payee
            </Text>
          </DetailsSidebarBody.Header>
        }
        main={
          <DetailsSidebarBody.Main>
            <DetailsSidebarBody.Section>
              {suggestedPayee && (
                <Banner
                  color="purple"
                  icon={<PencilSimpleLine size={24} />}
                  paragraph={
                    <>
                      We’ve pre-filled some of the payee info from{" "}
                      {billInvoiceNumber ? (
                        <>
                          invoice <Text as="strong">{billInvoiceNumber}</Text>
                        </>
                      ) : (
                        <>the invoice</>
                      )}
                      .
                    </>
                  }
                  className={styles.suggestedPayeeBanner}
                />
              )}

              <PayeeDetailsFieldset form={payeeDetailsForm} />
            </DetailsSidebarBody.Section>

            <Divider className="my-0" />

            <DetailsSidebarBody.Section>
              <Text size={14} weight="bold">
                Payment details
              </Text>

              <SegmentControls
                size="md"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabs={tabs}
                className={styles.segmentControls}
              />

              {activeTab === "ach" && <AchPaymentMethodFieldset form={achPaymentMethodForm} />}
              {activeTab === "domestic-wire" && (
                <DomesticWirePaymentMethodFieldset form={domesticWirePaymentMethodForm} />
              )}
              {activeTab === "international-wire" && (
                <div>
                  {internationalWireError && (
                    <Helper iconVariant="error" className="mb-4">
                      {internationalWireError}
                    </Helper>
                  )}
                  <InternationalWirePaymentMethodRecoilFieldset />
                </div>
              )}
            </DetailsSidebarBody.Section>
          </DetailsSidebarBody.Main>
        }
        footer={
          <DetailsSidebarBody.Footer className={styles.footer}>
            <Button type="submit" variant="primary" isLoading={isPending || isUpdatingBill}>
              <PlusCircle size={20} /> Create payee
            </Button>

            <Button variant="ghost" onClick={onBack}>
              Cancel
            </Button>
          </DetailsSidebarBody.Footer>
        }
      />
    </form>
  );
};

export default BillCreatePayeeForm;
