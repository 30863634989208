import Flexpane from "ui/overlay/Flexpane";

import { useReceivedPaymentInfoFlexpaneContext } from "../../context/ReceivedPaymentInfoFlexpaneProvider";

const ReceivedAchPaymentFlexpaneHeader = () => {
  const { onClose } = useReceivedPaymentInfoFlexpaneContext();

  return <Flexpane.Header onClose={onClose}>Transaction info</Flexpane.Header>;
};

export default ReceivedAchPaymentFlexpaneHeader;
