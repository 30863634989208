import { RecurringPayment } from "@highbeam/unit-node-sdk";

export const getCounterpartyIdFromRecurringPayment = (recurringPayment: RecurringPayment) => {
  if (recurringPayment.type === "recurringCreditAchPayment") {
    return recurringPayment.relationships.counterparty.data.id;
  } else if (recurringPayment.type === "recurringDebitAchPayment") {
    return recurringPayment.relationships.counterparty.data.id;
  } else {
    return recurringPayment.relationships.counterpartyAccount.data.id;
  }
};
