import { CaretLeft } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import colors from "styles/colors";

type Props = {
  to: string;
  className?: string;
};

const BackCaret: React.FC<Props> = ({ to, className }) => (
  <Link to={to} className={className}>
    <CaretLeft size={16} color={colors.grey[400]} />
  </Link>
);

export default BackCaret;
