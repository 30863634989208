import { FC, ReactNode } from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import cn from "utils/tailwind/cn";

type Props = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

const BusinessDisambiguatorButton: FC<Props> = ({ children, className, onClick }) => (
  <VirtualButton
    className={cn(
      "flex h-24 items-center gap-x-4 px-6",
      onClick ? "hover:bg-grey-50" : "",
      "border-b border-b-grey-100 last-of-type:border-b-0",
      className
    )}
    onClick={onClick}
  >
    {children}
  </VirtualButton>
);

export default BusinessDisambiguatorButton;
