import usePaymentApproval from "resources/payment-approvals/queries/usePaymentApproval";
import TextInput from "ui/inputs/TextInput";
import Flexpane from "ui/overlay/Flexpane";

import { usePaymentApprovalFlexpaneContext } from "../../context/PaymentApprovalFlexpaneProvider";

const PaymentApprovalPayeeNotificationSection = () => {
  const { paymentApprovalGuid } = usePaymentApprovalFlexpaneContext();
  const { notificationEmailAddress } = usePaymentApproval(paymentApprovalGuid, { required: true });

  if (!notificationEmailAddress) {
    return null;
  }

  return (
    <Flexpane.Section
      heading="Notification"
      subheading="The recipient will receive a confirmation email for this payment."
    >
      <TextInput label="Email address" value={notificationEmailAddress} disabled />
    </Flexpane.Section>
  );
};

export default PaymentApprovalPayeeNotificationSection;
