import { useCallback } from "react";
import { useSetRecoilState } from "recoil";

import confirmationModalState, {
  ConfirmationModalParams,
} from "./ConfirmationModal/confirmationModalState";

const useConfirm = () => {
  const setModalState = useSetRecoilState(confirmationModalState);

  const confirm = useCallback(
    (params: ConfirmationModalParams) => {
      return new Promise<boolean>((resolve) => {
        setModalState({ ...params, isOpen: true, resolvePromise: resolve });
      });
    },
    [setModalState]
  );

  return confirm;
};

export default useConfirm;
