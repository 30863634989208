import plaidAccountsQueryHooks from "./plaidAccountsQueryHooks";

const useHasInactivePlaidDepositoryAccounts = (): boolean => {
  const { data: plaidDepositoryAccounts } = plaidAccountsQueryHooks.useSuspenseQuery({});
  return (
    plaidDepositoryAccounts.filter(
      (account) =>
        account.accountType === "DEPOSITORY" && (!account.isActive || !account.isConnectionActive)
    ).length !== 0
  );
};

export default useHasInactivePlaidDepositoryAccounts;
