import HighbeamApi from "api/HighbeamApi";
import BillDocumentRep from "reps/BillDocumentRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";
import uploadFile from "utils/uploadFile";

import { useRefreshBillDocumentsQueries } from "../queries/useBillDocuments";

type ResultData = {
  associatedBillDocuments: BillDocumentRep.Complete[];
};

type Variables = {
  files: File[];
  billId: string;
  type: BillDocumentRep.DocumentType;
};

type UploadBillDocumentsParams = Variables & {
  highbeamApi: HighbeamApi;
  businessGuid: string;
};

// We export useUploadBillDocumentsMutation's underlying mutation function
// as a standalone function to facilitate its use in other mutations (see useUploadBillInvoiceDocumentMutation),
// without having to nest useMutation hooks.
export const uploadBillDocuments = async (
  params: UploadBillDocumentsParams
): Promise<ResultData> => {
  const { highbeamApi, businessGuid, files, billId, type } = params;

  const associatedBillDocuments = await Promise.all(
    files.map(async (file) => {
      const billDocument = await highbeamApi.billDocument.create({
        businessGuid: businessGuid,
        billId: billId,
        type: type,
        fileName: file.name,
      });

      await uploadFile(file, billDocument.signedDocumentUrl);

      return billDocument;
    })
  );

  return {
    associatedBillDocuments,
  };
};

const useUploadBillDocumentsMutation = (
  additionalOptions: MutationAdditionalOptions<ResultData, Variables> = {}
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const refreshBillDocumentsQueries = useRefreshBillDocumentsQueries();

  return useMutationWithDefaults(
    {
      mutationFn: ({ files, billId, type }) => {
        return uploadBillDocuments({
          highbeamApi,
          businessGuid,
          files,
          billId,
          type,
        });
      },
      onSuccess: async () => {
        await refreshBillDocumentsQueries();
      },
    },
    additionalOptions
  );
};

export default useUploadBillDocumentsMutation;
