import { atom } from "recoil";

export enum Step {
  PAYEE_SCHEDULE,
  PAYMENT_INFO,
  CONFIRM_DETAILS,
  TRANSFER_SCHEDULED,
}

// NB(alex): experimenting with co-locating recoil state near their usage. Do not follow this pattern!
const currentStepState = atom<Step>({
  key: "sendMoney/currentStepState",
  default: Step.PAYEE_SCHEDULE,
});

export default currentStepState;
