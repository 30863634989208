import { FC } from "react";
import { Controller } from "react-hook-form";
import AccountingCategorySelect from "resources/accounting-accounts/components/AccountingCategorySelect";
import PayeeDefaultChartOfAccountDescription from "resources/payees/components/PayeeDefaultChartOfAccountDescription";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import Helper from "ui/inputs/Helper";
import TextInputV2 from "ui/inputs/TextInputV2";

import usePayeeDetailsForm from "../../forms/usePayeeDetailsForm";

type Props = {
  form: ReturnType<typeof usePayeeDetailsForm>;
};

const PayeeDetailsFieldset: FC<Props> = ({ form }) => {
  const { control } = form;

  return (
    <FieldsetV2>
      <FieldsetV2.Legend>Payee info</FieldsetV2.Legend>

      <Controller
        control={control}
        name="payeeName"
        render={({ field, fieldState: { error } }) => {
          return (
            <div>
              <TextInputV2 label="Payee’s legal name" {...field} />
              {error && <Helper iconVariant="error">{error.message}</Helper>}
            </div>
          );
        }}
      />

      <FieldsetV2.Row columns={2}>
        <Controller
          control={control}
          name="payeeEmail"
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <TextInputV2 label="Email (optional)" {...field} />
                {error && <Helper iconVariant="error">{error.message}</Helper>}
              </div>
            );
          }}
        />

        <Controller
          control={control}
          name="payeePhoneNumber"
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <TextInputV2 label="Phone number (optional)" {...field} />
                {error && <Helper iconVariant="error">{error.message}</Helper>}
              </div>
            );
          }}
        />
      </FieldsetV2.Row>

      <div className="flex flex-col gap-2">
        <Controller
          name="payeeDefaultChartOfAccountId"
          control={control}
          render={({ field }) => (
            <AccountingCategorySelect
              value={field.value}
              onValueChange={field.onChange}
              labelText="Accounting category (optional)"
              clearable
            />
          )}
        />
        <PayeeDefaultChartOfAccountDescription />
      </div>
    </FieldsetV2>
  );
};

export default PayeeDetailsFieldset;
