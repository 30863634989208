import { selector } from "recoil";

import { isLocalPaymentState } from "./isLocalPayment";
import { quoteCalculatedLocalAmountInCentsState } from "./quoteCalculatedLocalAmountInCents";
import { selectedCountryOptionState } from "./selectedCountry";

export const isMoreThanAllowedLocalPaymentAmountState = selector<boolean>({
  key: "payments/international/isMoreThanAllowedLocalPaymentAmount",
  get: ({ get }) => {
    const isLocalPayment = get(isLocalPaymentState);
    if (!isLocalPayment) return false;
    const country = get(selectedCountryOptionState);
    const quoteCalculatedLocalAmountInCents = get(quoteCalculatedLocalAmountInCentsState);
    const localLimit = country?.local.paymentLimitinCents;
    return localLimit ? quoteCalculatedLocalAmountInCents > localLimit : false;
  },
});
