import { Check } from "@phosphor-icons/react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Text from "ui/typography/Text";

import styles from "./Steps.module.scss";

export type StepTypeV2 = {
  title: string;
  path: string;
  isNavigable?: boolean;
};

type StepProps = StepTypeV2 & {
  isActive: boolean;
  isDone: boolean;
  isIncomplete: boolean;
  isLast: boolean;
  number: number;
};

const Step: React.FC<StepProps> = ({
  isActive,
  isDone,
  isIncomplete,
  isLast,
  number,
  title,
  path,
  isNavigable: isNavigableProp,
}) => {
  const defaultIsNavigable = isDone || isActive;
  // Use default value if prop is not defined
  const __isNavigable = isNavigableProp === undefined ? defaultIsNavigable : isNavigableProp;
  const LinkComponent = __isNavigable ? Link : "div";

  return (
    <LinkComponent
      className={classNames({
        [styles.step]: true,
        [styles.active]: isActive,
        [styles.incomplete]: isIncomplete,
        [styles.done]: isDone,
      })}
      to={__isNavigable ? path : ""}
    >
      {!isLast && <div className={styles.line} />}
      <div className={styles.number}>{isDone ? <Check weight="bold" /> : number}</div>
      <Text className={styles.title} size={14}>
        {title}
      </Text>
    </LinkComponent>
  );
};

type Props = {
  steps: StepTypeV2[];
  currentStepIndex: number;
};

const StepsV2: React.FC<Props> = ({ steps, currentStepIndex }) => (
  <div className={classNames("hide-scrollbars", styles.container)}>
    {steps.map((step, index) => (
      <Step
        key={index}
        title={step.title}
        path={step.path}
        number={index + 1}
        isActive={currentStepIndex === index}
        isDone={currentStepIndex > index}
        isIncomplete={currentStepIndex < index}
        isLast={index === steps.length - 1}
        isNavigable={step.isNavigable}
      />
    ))}
  </div>
);

export default StepsV2;
