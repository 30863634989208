import { ReactNode } from "react";
import Button from "ui/inputs/Button";
import VirtualButton from "ui/inputs/VirtualButton";
import { Heading4, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type ConnectionItemBodyProps = {
  iconSrc: string;
  providerName: string;
  subtext?: string;
  className?: string;
  onClick?: () => void;
  endAdornment?: ReactNode;
};

const ConnectionItemBody: React.FC<ConnectionItemBodyProps> = ({
  iconSrc,
  providerName,
  subtext,
  className,
  onClick,
  endAdornment,
}) => (
  <VirtualButton
    onClick={onClick}
    className={cn(
      "flex items-center gap-x-4 rounded-lg border border-grey-200 px-6 py-4 shadow-xs",
      onClick && "active:shadow-inset",
      className
    )}
  >
    <div className="flex size-8 items-center justify-center rounded-lg bg-grey-100">
      <img src={iconSrc} alt="Quickbooks logo" className="size-4" />
    </div>

    <div className="flex-1">
      <Heading4 className="text-sm font-medium">{providerName}</Heading4>
      <Paragraph className="text-xs font-regular text-grey-400">{subtext}</Paragraph>
    </div>

    {endAdornment}
  </VirtualButton>
);

type Props = {
  isConnected: boolean;
  onClickConnect?: () => void;
  onClickDisconnect?: () => void;
  iconSrc: string;
  providerName: string;
  className?: string;
};

const ConnectionItem: React.FC<Props> = ({
  isConnected,
  onClickConnect,
  onClickDisconnect,
  ...connectionItemBodyProps
}) => {
  if (isConnected) {
    return (
      <ConnectionItemBody
        subtext="Connected"
        {...connectionItemBodyProps}
        endAdornment={
          <Button onClick={onClickDisconnect} variant="danger" size="sm">
            Disconnect
          </Button>
        }
      />
    );
  } else {
    return (
      <ConnectionItemBody
        onClick={onClickConnect}
        subtext="Click to connect"
        className="active:shadow-inset"
        {...connectionItemBodyProps}
      />
    );
  }
};

export default ConnectionItem;
