import { ReactNode } from "react";
import ActionItemRep from "reps/ActionItemRep";

export enum SignupStep {
  AuthorizeWithUnit = "AuthorizeWithUnit",
}

export enum GetStartedSetupGuideStep {
  ConnectStore = "ConnectStore",
  CreateVirtualCard = "CreateVirtualCard",
  ConnectBank = "ConnectBank",
  FundHighbeamAccount = "FundHighbeamAccount",
  ReceiveStorePayouts = "ReceiveStorePayouts",
}

export enum FundYourAccountStep {
  FundYourAccount = "FundYourAccount",
}

export type ActionItemSteps = SignupStep | GetStartedSetupGuideStep | FundYourAccountStep;

export enum Milestone {
  Signup = "Signup",
  GetStarted = "GetStarted",
  FundYourAccount = "FundYourAccount",
}

export type ActionItem = {
  order: number;
  name: ActionItemSteps;
  milestone: Milestone;
  title: string;
  description?: string;
  dynamicDescription?: () => ReactNode;
  primaryActionText: string;
  primaryActionPath: string;
  finishedActionItemRep?: ActionItemRep.Complete;
  locationState?: object;
  hideAction?: boolean;
};
