import { atom } from "recoil";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

type RetryAchReceivedPaymentModalStateParams = {
  receivedPaymentId: string;
};

type RetryAchReceivedPaymentModalState = DialogState<RetryAchReceivedPaymentModalStateParams>;

const retryAchReceivedPaymentModalState = atom<RetryAchReceivedPaymentModalState>({
  key: "retryAchReceivedPaymentModalState",
  default: { isOpen: false },
});

export default retryAchReceivedPaymentModalState;

export const useRetryAchReceivedPaymentModal = () => {
  return useModal<RetryAchReceivedPaymentModalStateParams>(retryAchReceivedPaymentModalState);
};
