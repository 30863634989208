import classNames from "classnames";
import React from "react";

import DotsLoader from "../DotsLoader";

import styles from "./DotsLoaderPage.module.scss";

type Props = {
  variant: "page" | "section";
};

const DotsPageLoader: React.FC<Props> = ({ variant }) => (
  <div className={classNames(styles.container, variant === "page" && styles.page)}>
    <DotsLoader dots={variant === "page" ? 5 : 3} />
  </div>
);

export default DotsPageLoader;
