import classNames from "classnames";

import styles from "./Shimmer.module.scss";

type Props = {
  total?: number;
  additionalShimmerClassName?: string[];
  additionalShimmerWrapperClassName?: string;
  noAnimation?: boolean;
  variant?: "light" | "dark" | "transparent";
};

const Shimmer: React.FC<Props> = ({
  total = 1,
  additionalShimmerWrapperClassName,
  additionalShimmerClassName,
  noAnimation,
  variant = "light",
}) => (
  <div className={classNames(styles.container, additionalShimmerWrapperClassName)}>
    {Array.from({ length: total }).map((_, index) => (
      <div
        key={index}
        className={classNames(
          styles.loader,
          {
            [styles["loader--has-margin-right"]]: index !== total - 1,
            [styles["loader--dark"]]: variant === "dark",
            [styles["loader--with-transparency"]]: variant === "transparent",
            [styles["loader--has-animation"]]: !noAnimation,
          },
          additionalShimmerClassName ? additionalShimmerClassName[index] : ""
        )}
      />
    ))}
  </div>
);

export default Shimmer;
