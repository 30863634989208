import external from "assets/external.svg";
import BankingInfo from "components/BankingInfo";
import BankAccountRep from "reps/BankAccountRep";
import Text from "ui/typography/Text";
import { getAccountType } from "utils/account";

import styles from "../PayoutInstructionModal.module.scss";

type Props = {
  accountToDisplay?: BankAccountRep.Complete;
};

const ShopifyInstructions: React.FC<Props> = ({ accountToDisplay }) => {
  return (
    <>
      <div className={styles.step}>
        <div className={styles.stepNumber}>1</div>
        <Text size={14}>
          From your <span className={styles.highlight}>Shopify admin</span>, go to{" "}
          <a
            href="https://www.shopify.com/admin/settings/payments"
            target="_blank"
            rel="noreferrer"
            className={styles.highlightLink}
          >
            Settings &gt; Payments
            <img src={external} alt="Shopify login" />
          </a>
          .
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>2</div>
        <Text size={14}>
          In the <span className={styles.highlight}>Shopify Payments</span> section, click{" "}
          <span className={styles.highlight}>Manage</span>.
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>3</div>
        <Text size={14}>
          In the <span className={styles.highlight}>Payout account</span> box, click{" "}
          <span className={styles.highlight}>Change payout account</span>.
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>4</div>
        <Text size={14}>Enter your Highbeam bank account information.</Text>
      </div>

      {accountToDisplay && (
        <BankingInfo>
          <BankingInfo.Key>Routing number</BankingInfo.Key>
          <BankingInfo.Value textToCopy={accountToDisplay.routingNumber}>
            {accountToDisplay.routingNumber}
          </BankingInfo.Value>

          <BankingInfo.Key>Account number</BankingInfo.Key>
          <BankingInfo.Value textToCopy={accountToDisplay.accountNumber}>
            {accountToDisplay.accountNumber}
          </BankingInfo.Value>

          <BankingInfo.Key>Account type</BankingInfo.Key>
          <BankingInfo.Value>{getAccountType(accountToDisplay)}</BankingInfo.Value>
        </BankingInfo>
      )}

      <div className={styles.step}>
        <div className={styles.stepNumber}>5</div>
        <Text size={14}>
          Click <span className={styles.highlight}>Save</span>.
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>6</div>
        <Text size={14}>Wait a few days until the first payout arrives in Highbeam.</Text>
      </div>
    </>
  );
};

export default ShopifyInstructions;
