import { FC } from "react";
import Text, { TextProps } from "ui/typography/Text";
import cn from "utils/tailwind/cn";

// TODO(alex): Make this reusable outside of the context of the card creation modal.

export const SectionHeading: FC<Partial<TextProps>> = ({ children, className, ...props }) => {
  return (
    <Text as="h4" className={cn("text-md font-bold text-grey-900", className)} {...props}>
      {children}
    </Text>
  );
};

export const SectionSubheading: FC<Partial<TextProps>> = ({ children, className, ...props }) => {
  return (
    <Text
      as="h5"
      className={cn("mb-6 mt-1 text-sm font-regular text-grey-700", className)}
      {...props}
    >
      {children}
    </Text>
  );
};
