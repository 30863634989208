export enum IntervalType {
  Monthly = "Monthly",
}

export interface RevenueProjectionRep {
  readonly start: string;
  readonly end: string;
  readonly amount: number;
}

interface RevenueForecastRep {
  readonly revenueProjections: RevenueProjectionRep[];
}

export default RevenueForecastRep;
