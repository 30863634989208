import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const ROOT_BUSINESSES_QUERY_KEY = "businesses-root";

export const useRefreshAllBusinessesQueries = () => {
  return useRefreshQuery([ROOT_BUSINESSES_QUERY_KEY]);
};

const businessesQueryHooks = makeQueryHooks({
  rootName: ROOT_BUSINESSES_QUERY_KEY,
  name: "businesses",
  useQueryVariables: () => {
    return {};
  },
  useQueryFnMaker: () => {
    const highbeamApi = useHighbeamApi();
    return () => {
      return highbeamApi.business.getAll();
    };
  },
});

export default businessesQueryHooks;
