import { atom } from "recoil";
import BankAccountRep from "reps/BankAccountRep";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

type EditBankAccountNameModalParams = {
  bankAccount: BankAccountRep.Complete;
};

const editBankAccountNameModalState = atom<DialogState<EditBankAccountNameModalParams>>({
  key: "bankAccounts/editBankAccountNameModalState",
  default: { isOpen: false },
});

export default editBankAccountNameModalState;

export const useEditAccountNameModal = () => {
  return useModal<EditBankAccountNameModalParams>(editBankAccountNameModalState);
};
