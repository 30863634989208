import visaLogoBlue from "assets/visa-logo-blue.svg";
import visaLogoWhite from "assets/visa-logo-white.svg";

type Props = {
  isPhysicalCard: boolean;
};

const VisaLogo: React.FC<Props> = ({ isPhysicalCard }) => (
  <img className="w-10" src={isPhysicalCard ? visaLogoWhite : visaLogoBlue} alt="Visa logo" />
);

export default VisaLogo;
