import { useApSettings } from "resources/ap-settings/queries/apSettingsQueryHooks";
import useShouldShowEmployeeView from "resources/bills/hooks/useShouldShowEmployeeView";
import useBill from "resources/bills/queries/useBill";

import billSyncQueryHooks from "../queries/billSyncQueryHooks";
import shouldBillSyncBeDryRun from "../utils/shouldBillSyncBeDryRun";

const useBillSyncQueryIfEnabled = (billId: string) => {
  const shouldShowEmployeeView = useShouldShowEmployeeView();
  const { accountingSyncEnabled } = useApSettings();
  const bill = useBill(billId, { required: true });
  const dryRun = shouldBillSyncBeDryRun(bill);

  return billSyncQueryHooks.useQuery({
    billId,
    dryRun,
    enabled: accountingSyncEnabled && !shouldShowEmployeeView,
  });
};

export default useBillSyncQueryIfEnabled;
