import React from "react";
import Text from "ui/typography/Text";

import styles from "./FeatureStatusIndicator.module.scss";

export type FeatureStatus = "New" | "Beta";

type Props = {
  featureStatus: FeatureStatus;
};

const FeatureStatusIndicator: React.FC<Props> = ({ featureStatus }) => {
  switch (featureStatus) {
    case "New":
      return (
        <Text size={12} weight={"medium"} className={styles.indicator}>
          New
        </Text>
      );
    case "Beta":
      return (
        <Text size={12} weight={"medium"} className={styles.indicator}>
          Beta
        </Text>
      );
  }
};

export default FeatureStatusIndicator;
