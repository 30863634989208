import Text from "ui/typography/Text";

export const SwiftFeeTooltipContent = () => (
  <Text size={14}>
    This fee is charged by the SWIFT network. Consider sending money in the payee’s local currency
    to avoid this fee.
  </Text>
);

export const ExchangeRateTooltipContent = () => (
  <Text size={14}>This rate represents the exchange rate provided by Highbeam.</Text>
);
