import { useSuspenseQuery } from "@tanstack/react-query";

import usePlaidConnectionsQueryOptions from "./usePlaidConnectionsQueryOptions";

const usePlaidConnections = () => {
  const { data: plaidConnections } = useSuspenseQuery(usePlaidConnectionsQueryOptions());

  return plaidConnections;
};

export default usePlaidConnections;
