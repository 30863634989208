import { FC, ReactNode } from "react";
import cn from "utils/tailwind/cn";

type Props = {
  columns: 2 | 3;
  children: ReactNode;
  className?: string;
};

const FieldsetRow: FC<Props> = ({ children, className, columns }) => {
  return (
    <div
      className={cn(
        "grid gap-4",
        columns === 2 && "@md:grid-cols-2",
        columns === 3 && "@2xl:grid-cols-3",
        className
      )}
    >
      {children}
    </div>
  );
};

export default FieldsetRow;
