import { useRecoilValue } from "recoil";
import ModalV3 from "ui/overlay/ModalV3";

import useCashFlowSyncSheetMutation from "../../hooks/useCashFlowSyncSheetMutation";

import cashFlowSyncSheetModalState, {
  useCashFlowSyncSheetModal,
} from "./state/cashFlowSyncSheetModalState";

const CASH_FLOW_SYNC_SHEET_FORM = "cash-flow-sync-sheet-form";

const CashFlowSyncSheetModal = () => {
  const state = useRecoilValue(cashFlowSyncSheetModalState);
  const { isOpen, close: onClose } = useCashFlowSyncSheetModal();
  const { mutate: syncSheet, isPending } = useCashFlowSyncSheetMutation();

  return (
    <ModalV3 isOpen={isOpen} onClose={onClose}>
      {state.isOpen && (
        <form
          id={CASH_FLOW_SYNC_SHEET_FORM}
          onSubmit={(e) => {
            e.preventDefault();
            syncSheet();
          }}
        >
          <ModalV3.Header onClose={onClose}>Sync to Google sheet</ModalV3.Header>

          <ModalV3.Body>
            <ModalV3.Body.Text>
              Click <b>Start sync</b> to update your google sheet with up-to-date cash flow data.
              The sync can take up to <b>2 minutes</b>.
            </ModalV3.Body.Text>
          </ModalV3.Body>

          <ModalV3.Footer>
            <ModalV3.Footer.SubmitButton
              autoFocus
              form={CASH_FLOW_SYNC_SHEET_FORM}
              isLoading={isPending}
            >
              Start sync
            </ModalV3.Footer.SubmitButton>
            <ModalV3.Footer.CloseButton onClick={onClose}>Cancel</ModalV3.Footer.CloseButton>
          </ModalV3.Footer>
        </form>
      )}
    </ModalV3>
  );
};

export default CashFlowSyncSheetModal;
