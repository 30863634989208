import { FC } from "react";
import HighlightItem from "ui/data-display/HighlightItem";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

type Props = {
  cardBalanceSettledInCents: number;
  cardBalancePendingInCents: number;
};

const ChargeCardCurrentBalanceHighlightItem: FC<Props> = ({
  cardBalanceSettledInCents,
  cardBalancePendingInCents,
}) => {
  const cardBalanceTotalInCents = cardBalanceSettledInCents + cardBalancePendingInCents;

  return (
    <HighlightItem
      label={<>Current balance</>}
      value={
        <MoneyAmount
          size={20}
          weight="medium"
          centsTextSize={16}
          centsTextWeight="regular"
          cents={cardBalanceTotalInCents}
        />
      }
    />
  );
};

export default ChargeCardCurrentBalanceHighlightItem;
