import ChargeCardOfferRep from "reps/ChargeCardOfferRep";

export type LegacyCreditTerm =
  | ChargeCardOfferRep.ChargeCardCreditTerm.Production
  | ChargeCardOfferRep.ChargeCardCreditTerm.Sandbox;

export const checkIsLegacyCreditTerm = (
  creditTerm: ChargeCardOfferRep.ChargeCardCreditTerm
): creditTerm is LegacyCreditTerm => {
  return (
    creditTerm === ChargeCardOfferRep.ChargeCardCreditTerm.Production ||
    creditTerm === ChargeCardOfferRep.ChargeCardCreditTerm.Sandbox
  );
};

export type CashCreditTerm =
  | ChargeCardOfferRep.ChargeCardCreditTerm.ProductionCash
  | ChargeCardOfferRep.ChargeCardCreditTerm.SandboxCash;

export const checkIsCashCreditTerm = (
  creditTerm: ChargeCardOfferRep.ChargeCardCreditTerm
): creditTerm is CashCreditTerm => {
  return (
    creditTerm === ChargeCardOfferRep.ChargeCardCreditTerm.ProductionCash ||
    creditTerm === ChargeCardOfferRep.ChargeCardCreditTerm.SandboxCash
  );
};

export type ExtendCreditTerm =
  | ChargeCardOfferRep.ChargeCardCreditTerm.ProductionExtend
  | ChargeCardOfferRep.ChargeCardCreditTerm.SandboxExtend;

export const checkIsExtendCreditTerm = (
  creditTerm: ChargeCardOfferRep.ChargeCardCreditTerm
): creditTerm is ExtendCreditTerm => {
  return (
    creditTerm === ChargeCardOfferRep.ChargeCardCreditTerm.ProductionExtend ||
    creditTerm === ChargeCardOfferRep.ChargeCardCreditTerm.SandboxExtend
  );
};

export type FlexCreditTerm =
  | ChargeCardOfferRep.ChargeCardCreditTerm.ProductionFlex
  | ChargeCardOfferRep.ChargeCardCreditTerm.SandboxFlex;

export const checkIsFlexCreditTerm = (
  creditTerm: ChargeCardOfferRep.ChargeCardCreditTerm
): creditTerm is FlexCreditTerm => {
  return (
    creditTerm === ChargeCardOfferRep.ChargeCardCreditTerm.ProductionFlex ||
    creditTerm === ChargeCardOfferRep.ChargeCardCreditTerm.SandboxFlex
  );
};
