/* eslint sort-keys: ["error", "asc", { "natural": true }] */

// NB(alex): Use this as the source of truth for all counterparty vendor names. Just be sure to use `[key in CounterpartyVendorName]` when defining mappings.
export const counterpartyVendorNames = {
  adobe: "Adobe",
  affirm: "Affirm",
  amazon: "Amazon",
  amazonPayout: "Amazon Payout",
  americanExpress: "Amex",
  americanExpressPayment: "Amex Payment",
  easypost: "Easypost",
  facebook: "Facebook",
  google: "Google",
  klaviyo: "Klaviyo",
  microsoft: "Microsoft",
  paypal: "PayPal",
  paypalPayout: "PayPal Payout",
  shopify: "Shopify",
  shopifyCapital: "Shopify Capital",
  shopifyPayout: "Shopify Payout",
  stripe: "Stripe",
  tiktok: "Tiktok",
  typeform: "Typeform",
  uber: "Uber",
  walmart: "Walmart",
  wayflyer: "Wayflyer",
  wholeFoods: "Whole Foods",
};

export type CounterpartyVendorKey = keyof typeof counterpartyVendorNames;
