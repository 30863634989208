import { InternationalWireLocalPurposeOption } from "pages/SendMoneyPage/internationalWires";
import { selector } from "recoil";

import { selectedBankCountryInternationalWireOptionState } from "./selectedBankCountry";

export const requiredPurposeCodeOptionsState = selector<InternationalWireLocalPurposeOption[]>({
  key: "payments/international/requiredPurposeCodes",
  get: ({ get }) => {
    const selectedBankCountryInternationalWireOption = get(
      selectedBankCountryInternationalWireOptionState
    );
    const purposeCodes = selectedBankCountryInternationalWireOption?.purposeCodes || [];

    return purposeCodes;
  },
});
