import { ComponentProps, ElementRef, forwardRef } from "react";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = ComponentProps<typeof Span> & {
  overflowEllipsis?: boolean;
  darken?: boolean;
};

const TextCell = forwardRef<ElementRef<typeof Span>, Props>(
  ({ className, overflowEllipsis, darken, children, ...spanProps }, ref) => (
    <Span
      ref={ref}
      className={cn(
        "inline-flex items-center gap-x-2 text-sm text-grey-600",
        "w-full", // Ensure we fill the width of the column (for right-aligned text to work).
        darken && "text-grey-900",
        overflowEllipsis && "block overflow-hidden overflow-ellipsis whitespace-nowrap",
        className
      )}
      {...spanProps}
    >
      {children}
    </Span>
  )
);

export default TextCell;
