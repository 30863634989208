import { useNavigate } from "react-router-dom";
import Button, { ButtonProps } from "ui/inputs/Button";

import { SIGN_OUT, SIGN_OUT_ROUTE } from "./constants";

export const useSignOut = () => {
  const navigate = useNavigate();
  return () => navigate(SIGN_OUT_ROUTE);
};

type Props = Omit<ButtonProps, "children">;

const SignOutButton = ({ ...props }: Props) => {
  const signOut = useSignOut();

  return (
    <Button onClick={signOut} variant="ghost" paddingVariant="bare" {...props}>
      {SIGN_OUT}
    </Button>
  );
};

export default SignOutButton;
