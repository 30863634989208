import { Unit } from "@highbeam/unit-node-sdk";
import { captureException } from "@sentry/react";
import lockIcon from "assets/lock.svg";
import env from "env";
import { useCallback, useState } from "react";
import CardAvatarBar from "resources/cards/components/CardAvatarBar";
import useCard, { useRefreshCardQuery } from "resources/cards/queries/useCard";
import { isCardLocked } from "resources/cards/utils";
import useGetUnitCoSensitiveTokenWithMfa from "resources/unit-co-customer-token/hooks/useGetUnitCoSensitiveTokenWithMfa";
import { DEFAULT_UNIT_CONFIG } from "resources/unit-co-customer-token/queries/useUnitApi";
import Divider from "ui/data-display/Divider";
import { notify } from "ui/feedback/Toast";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";

import styles from "./LockCardModal.module.scss";

type Props = {
  onClose: () => void;
  cardId: string;
};

const LockCardModal: React.FC<Props> = ({ onClose, cardId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const getUnitCoSensitiveTokenWithMfa = useGetUnitCoSensitiveTokenWithMfa({
    scopes: ["CARDS_WRITE"],
  });

  const card = useCard({ cardId, required: true });
  const refreshCardQuery = useRefreshCardQuery({ cardId });
  const isLocked = isCardLocked(card);

  const toggleLockCard = useCallback(
    async (newLockStatus: boolean) => {
      const sensitiveToken = await getUnitCoSensitiveTokenWithMfa();

      const unitApi = new Unit(sensitiveToken, env.UNIT_API_ORIGIN, DEFAULT_UNIT_CONFIG);
      try {
        if (newLockStatus) {
          await unitApi.cards.freeze(cardId);
        } else {
          await unitApi.cards.unfreeze(cardId);
        }
        refreshCardQuery();
        onClose();
      } catch (e) {
        notify("error", "Something went wrong! Please try again.");
        captureException(e);
      }
      setIsLoading(false);
    },
    [cardId, onClose, getUnitCoSensitiveTokenWithMfa, refreshCardQuery]
  );

  const text = isLocked
    ? "Unlocking allows transactions with this card. You can always lock it again later."
    : "Locking prevents transactions with this card. You can always unlock it again later.";
  return (
    <Modal
      icon={<img alt="Highbeam account" src={lockIcon} />}
      title={isLocked ? "Unlock this card?" : "Lock this card? "}
      showCancel
      buttonText={isLocked ? "Unlock card" : "Lock card"}
      isLoading={isLoading}
      isPrimaryButtonDisabled={isLoading}
      isCancelButtonDisabled={isLoading}
      onClick={() => toggleLockCard(!isLocked)}
      onClose={onClose}
    >
      <CardAvatarBar card={card} />

      <Divider />

      <Text className={styles.text} size={14}>
        {text}
      </Text>
    </Modal>
  );
};

export default LockCardModal;
