import { ErrorBoundary } from "@sentry/react";
import IntercomChat from "components/ChatWidget/IntercomChat";
import { useMemo } from "react";
import { useEventBusDispatcher } from "utils/event-bus";

export const CHAT_WIDGET_SHOWN_EVENT = "chatWidgetShown";

const makeChatWidgetShownEvent = () => new CustomEvent(CHAT_WIDGET_SHOWN_EVENT);

const ChatWidget = () => {
  return (
    <ErrorBoundary fallback={() => <></>}>
      <IntercomChat />
    </ErrorBoundary>
  );
};

export default ChatWidget;

type ChatWidgetType = {
  show: () => void;
  hide: () => void;
  refresh: () => void;
  message: (message: string) => void;
  shutDown: () => void;
};

export const useChatWidget = (): ChatWidgetType => {
  const dispatchEvent = useEventBusDispatcher();

  return useMemo(
    () => ({
      show: () => {
        Intercom("show");
        dispatchEvent(makeChatWidgetShownEvent());
      },
      hide: () => {
        Intercom("hide");
      },
      refresh: () => {
        Intercom("update", {
          last_request_at: Math.floor(new Date().getTime() / 1000), // eslint-disable-line camelcase
        });
      },
      message: (message: string) => {
        Intercom("showNewMessage", message);
        dispatchEvent(makeChatWidgetShownEvent());
      },
      shutDown: () => {
        Intercom("shutdown");
      },
    }),
    [dispatchEvent]
  );
};
