import { Payment } from "@highbeam/unit-node-sdk";

import { InternationalWireUnitCoPayment } from "../types";

const getIsInternationalWireUnitCoPayment = (
  payment: Payment
): payment is InternationalWireUnitCoPayment => {
  return Boolean(
    (payment.type === "achPayment" || payment.type === "wirePayment") &&
      payment.attributes.tags &&
      "paymentType" in payment.attributes.tags &&
      "paymentMetadataGuid" in payment.attributes.tags &&
      payment.attributes.tags.paymentType === "internationalWire" &&
      payment.attributes.tags.paymentMetadataGuid
  );
};

export default getIsInternationalWireUnitCoPayment;
