import external from "assets/external.svg";
import { ReactNode } from "react";

import styles from "./ExternalLink.module.scss";

type Props = {
  children: ReactNode;
  href: string;
};

const ExternalLink: React.FC<Props> = ({ href, children }) => (
  <a href={href} target="_blank" rel="noreferrer" className={styles.highlightLink}>
    {children}
    <img src={external} alt="Link" />
  </a>
);

export default ExternalLink;
