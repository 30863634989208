import { Copy } from "@phosphor-icons/react";
import { ReactNode, useEffect, useState } from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph } from "ui/typography";
import sleep from "utils/async/sleep";
import { copyToClipboard } from "utils/string";
import cn from "utils/tailwind/cn";

type Props = {
  accented?: boolean;
  textToCopy: string;
  className?: string;
  children: ReactNode;
  tooltipContent?: ReactNode;
};

const ItemWithCopyTextTooltip: React.FC<Props> = ({
  accented = false,
  textToCopy,
  children,
  className,
  tooltipContent = "Click to copy",
}) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;

    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copied, setCopied]);

  const handleClick = async () => {
    if (textToCopy) copyToClipboard(textToCopy);
    if (copied) {
      // Minor UI nicety that quickly re-shows the uncopied state for feedback if one clicks multiple times before the state resets.
      setCopied(false);
      await sleep(150);
    }
    setCopied(true);
  };

  return (
    <ItemWithTooltip
      tooltip={<Paragraph>{!copied ? <>{tooltipContent}</> : "Copied"}</Paragraph>}
      asChild
    >
      <VirtualButton
        className={cn(
          "inline-flex w-fit cursor-pointer items-center justify-between gap-x-2.5",
          accented && "rounded-md bg-purple-100 p-2.5 text-purple-500",
          className
        )}
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}
        onPointerDown={(e) => {
          // Prevents the tooltip from closing when the icon is clicked.
          e.stopPropagation();
        }}
      >
        {children}
        <Copy size={16} />
      </VirtualButton>
    </ItemWithTooltip>
  );
};

export default ItemWithCopyTextTooltip;
