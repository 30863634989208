import DocumentRepBackendV1 from "reps/DocumentRepBackendV1";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class DocumentsApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getDocuments(businessGuid: string): Promise<DocumentRepBackendV1.Complete[]> {
    const url = `/businesses/${businessGuid}/documents`;
    return (await this.api.get<DocumentRepBackendV1.Complete[]>(url))!;
  }

  async getDocumentUrl(businessGuid: string, documentGuid: string): Promise<string> {
    const url = `/businesses/${businessGuid}/document/${documentGuid}/url`;
    return (await this.api.get<string>(url))!;
  }
}
