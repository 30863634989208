import { atom } from "recoil";
import { stringLocalStorageEffect } from "utils/recoil";

/**
 * https://plaid.com/docs/link/oauth/#introduction-to-oauth
 *
 * We need to use the same link token when reinitializing link
 * during an oauth flow
 */
export const localStorageLinkTokenState = atom<string>({
  key: "localStorage/linkToken",
  default: "",
  effects: [stringLocalStorageEffect("linkToken")],
});
