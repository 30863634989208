import CheckDepositFlexpane from "dialogs/CheckDepositFlexpane";
import PaymentInfoFlexpane from "dialogs/PaymentInfoFlexpane";
import { useState } from "react";
import { BankAccountBarByUnitCoDepositAccountId } from "resources/bank-accounts/components/BankAccountBar";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import DateTimeCell from "ui/table/DateTimeCell";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import { isNotNull } from "utils/array";
import { HighbeamTransaction } from "utils/types/transactionsTypes";

import Counterparty from "./Counterparty";
import TransactionInfoFlexpane from "./TransactionInfoFlexpane";
import styles from "./TransactionsTable.module.scss";
import TransactionStatusPill from "./TransactionStatusPill";

export type TransactionsTableProps = {
  hideBalanceColumn?: boolean; // HACK(alex): Fixing this https://github.com/highbeamco/highbeam/pull/12724#pullrequestreview-2243655213
  isLoading: boolean;
  transactions?: HighbeamTransaction[];
  refreshTransactions?: () => void;
};

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  hideBalanceColumn,
  isLoading,
  transactions,
  refreshTransactions,
}) => {
  const [selectedTransaction, setSelectedTransaction] = useState<HighbeamTransaction>();

  const columns: Column<HighbeamTransaction>[] = [
    {
      title: "Date",
      key: "formattedCreatedAt",
      cellRender: (transaction: HighbeamTransaction) => (
        <DateTimeCell date={transaction.createdAt} />
      ),
      width: 120 as const,
    },
    {
      title: "To / From",
      cellRender: (transaction: HighbeamTransaction) => (
        <div className={styles.toFromStatus}>
          <Counterparty transaction={transaction} />
          <TransactionStatusPill
            transaction={transaction}
            showCompleted={false}
            className={styles.pill}
          />
        </div>
      ),
    },
    {
      title: "Account",
      key: "accountName",
      width: 320 as const,
      cellRender: (transaction: HighbeamTransaction) => (
        <BankAccountBarByUnitCoDepositAccountId
          unitCoDepositAccountId={transaction.accountId}
          shortMethodName={transaction.shortMethodName}
          className="w-full overflow-hidden text-ellipsis"
        />
      ),
    },
    {
      title: "Amount",
      align: TableColumnAlignment.RIGHT,
      cellRender: (transaction: HighbeamTransaction) => (
        <MoneyAmount
          as="div"
          cents={transaction.amountInCents}
          direction={transaction.direction === "Credit" ? "positive" : "negative"}
          weight="medium"
          color={colors.grey[800]}
        />
      ),
    },
    hideBalanceColumn
      ? null
      : {
          title: "Balance",
          align: TableColumnAlignment.RIGHT,
          cellRender: (transaction: HighbeamTransaction) => {
            // HACK(alex): This value should always exist if not the failed payments tab. https://github.com/highbeamco/highbeam/pull/12724#pullrequestreview-2243655213
            if (!transaction.balanceInCents) {
              return <NotFoundCell />;
            }
            return (
              <MoneyAmount
                as="div"
                cents={transaction.balanceInCents}
                weight="medium"
                color={colors.grey[800]}
              />
            );
          },
        },
  ].filter(isNotNull);

  return (
    <>
      <TransactionInfoFlexpane
        params={
          selectedTransaction && selectedTransaction.type !== "failedPaymentTransaction"
            ? {
                unitCoDepositAccountId: selectedTransaction.accountId,
                transactionId: selectedTransaction.id,
              }
            : undefined
        }
        onClose={() => setSelectedTransaction(undefined)}
        refreshTransactions={refreshTransactions}
      />

      {/* HACK(alex): This hack for distinguishing "failedPaymentTransaction" is necessary until we refactor `HighbeamTransaction` to distinguish between failed checks & failed payments */}

      <CheckDepositFlexpane
        checkDepositId={
          // NB(alex): Hacky fix for failed payments. We should delete this entire component once we get rid of the `HighbeamTransaction` type.
          selectedTransaction &&
          selectedTransaction.type === "failedPaymentTransaction" &&
          selectedTransaction.shortMethodName === "Check"
            ? selectedTransaction.id
            : undefined
        }
        onClose={() => setSelectedTransaction(undefined)}
      />

      <PaymentInfoFlexpane
        paymentId={
          // NB(alex): Hacky fix for failed payments. We should delete this entire component once we get rid of the `HighbeamTransaction` type.
          selectedTransaction &&
          selectedTransaction.type === "failedPaymentTransaction" &&
          selectedTransaction.shortMethodName !== "Check"
            ? selectedTransaction.id
            : undefined
        }
        onClose={() => setSelectedTransaction(undefined)}
      />

      <Table
        columns={columns}
        isLoading={isLoading}
        isLoadingRows={10}
        data={transactions}
        rowKey={(transaction) => `${transaction.id}-${transaction.accountId}`}
        cellClassName={styles.cell}
        onRowClick={(transaction) => setSelectedTransaction(transaction)}
      />
    </>
  );
};

export default TransactionsTable;
