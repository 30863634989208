import { ChatTeardropDots, MagnifyingGlass } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import useUser from "resources/user/queries/useUser";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./ApplicationEvaluation.module.scss";

const ApplicationEvaluation = () => {
  const chat = useChatWidget();
  const user = useUser();

  return (
    <div className={styles.container}>
      <Text size={20} weight="bold">
        We are manually reviewing your business{" "}
      </Text>
      <div className={styles.reasonsContainer}>
        <Text className={styles.reasonsTitle} size={14} weight="medium" color={colors.grey[600]}>
          <MagnifyingGlass />
          Reasons why we review a business:
        </Text>

        <Text size={14}>
          <ul className={styles.reasons}>
            <li>The business has no online stores (e.g. Shopify, Amazon)</li>
            <li>The business is pre-revenue</li>
            <li>Fraud prevention</li>
          </ul>
        </Text>
      </div>
      <div>
        <Text className={styles.helperText} size={14} color={colors.grey[600]}>
          When our review is complete in{" "}
          <Text className={styles.helperText} weight="medium">
            1 - 3 business days
          </Text>
          , we will email you with the results at{" "}
          <Text className={styles.helperText} weight="medium">
            {user.emailAddress}
          </Text>
          . Thank you for your patience.
        </Text>
      </div>
      <div className={styles.supportContainer}>
        <Text size={14} color={colors.grey[600]}>
          Have questions?
        </Text>
        <div className={styles.support} onClick={() => chat.show()}>
          <ChatTeardropDots size={14} color={colors.purple[500]} />
          <Text size={12} color={colors.purple[500]}>
            Contact support
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ApplicationEvaluation;
