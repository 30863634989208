import { PaymentListParams } from "@highbeam/unit-node-sdk";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const INCOMING_PAYMENT_PARAMS: PaymentListParams = {
  limit: 50,
  offset: 0,
  direction: ["Debit"], // satisfies Direction[]
  status: ["Pending", "Clearing", "PendingReview"], // satisfies PaymentStatus[]
};

export const UNIT_PAYMENTS_QUERY_KEY = "unitPayments";

const useUnitCoPaymentsQueryOptions = (params?: PaymentListParams) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: [UNIT_PAYMENTS_QUERY_KEY, params],
    queryFn: async () => {
      const { data } = await unitApi.payments.list(params);
      return data;
    },
  });
};

export default useUnitCoPaymentsQueryOptions;
