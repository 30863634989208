import { TransferOption } from "utils/transfers";

export type Transfer = {
  amountInCents: number;
  from: TransferOption | null;
  to: TransferOption | null;
};

export function filterTransferOptions(
  supportedTransferOptions: TransferOption[],
  counterpartyOption: TransferOption | null
) {
  if (counterpartyOption && !counterpartyOption.supportsTransferMoneyExternally) {
    return supportedTransferOptions.filter(
      (option: TransferOption) => option.supportsTransferMoneyExternally
    );
  }
  return supportedTransferOptions;
}
