import { CheckDepositListParams } from "@highbeam/unit-node-sdk";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const INCOMING_CHECK_DEPOSITS_PARAMS: CheckDepositListParams = {
  status: ["Pending", "Clearing", "PendingReview"], // satisfies CheckDepositStatus[]
};

export const UNIT_CO_CHECK_DEPOSITS_QUERY_KEY = "unitCoCheckDeposits";

const useUnitCoCheckDepostsQueryOptions = (params?: CheckDepositListParams) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: [UNIT_CO_CHECK_DEPOSITS_QUERY_KEY, params],
    queryFn: async () => {
      const { data } = await unitApi.checkDeposits.list(params);
      return data;
    },
  });
};

export default useUnitCoCheckDepostsQueryOptions;
