import { Copy } from "@phosphor-icons/react";
import { FC, useEffect, useState } from "react";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph } from "ui/typography";
import sleep from "utils/async/sleep";
import { copyToClipboard } from "utils/string";

type Props = {
  textToCopy?: string;
};

const CopyIconWithTooltip: FC<Props> = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;

    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [copied, setCopied]);

  const handleClick = async () => {
    if (textToCopy) copyToClipboard(textToCopy);
    if (copied) {
      // Minor UI nicety that quickly re-shows the uncopied state for feedback if one clicks multiple times before the state resets.
      setCopied(false);
      await sleep(150);
    }
    setCopied(true);
  };

  return (
    <ItemWithTooltip
      tooltip={<Paragraph>{!copied ? "Click to copy" : "Copied"}</Paragraph>}
      asChild
    >
      <span
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}
        onPointerDown={(e) => {
          // Prevents the tooltip from closing when the icon is clicked.
          e.stopPropagation();
        }}
        className="inline-flex cursor-pointer"
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          // NB(alex): Had to wrap with a `span` because svgs don't support `onKeyDown`.
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            e.currentTarget.click();
          }
        }}
      >
        <Copy size={16} />
      </span>
    </ItemWithTooltip>
  );
};

export default CopyIconWithTooltip;
