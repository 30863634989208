import { useSuspenseQuery } from "@tanstack/react-query";
import getCounterpartyReportQueryParamsBySelectedDuration from "pages/CashFlowPage/utils/getCounterpartyReportQueryParamsBySelectedDuration";
import useCounterpartyReportQueryOptions from "resources/counterparty-report/queries/useCounterpartyReportQueryOptions";

const useCounterpartySuggestions = () => {
  const { data: counterpartySuggestions } = useSuspenseQuery({
    ...useCounterpartyReportQueryOptions(
      getCounterpartyReportQueryParamsBySelectedDuration("last-12-months")
    ),
    select: (counterpartyReport) => {
      return counterpartyReport.rows.map((row) => {
        return row.counterparty;
      });
    },
  });

  return counterpartySuggestions;
};

export default useCounterpartySuggestions;
