import { InternationalWireLocalPurposeOption } from "pages/SendMoneyPage/internationalWires";
import { useRecoilState, useRecoilValue } from "recoil";
import { purposeCodeState } from "state/payments/international/purposeCode";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import { requiredPurposeCodeOptionsState } from "state/payments/international/requiredPurposeCodeOptions";
import { selectedBankCountryOptionState } from "state/payments/international/selectedBankCountry";
import Dropdown from "ui/inputs/Dropdown";
import Helper from "ui/inputs/Helper";

import styles from "./InternationalPurpose.module.scss";

const InternationalPurpose = () => {
  const [purposeCode, setPurposeCode] = useRecoilState(purposeCodeState);
  const purposeCodeOptions = useRecoilValue(requiredPurposeCodeOptionsState);
  const selectedBankCountry = useRecoilValue(selectedBankCountryOptionState);
  const currencyOption = useRecoilValue(quoteCurrencyState);
  const infoText = `${currencyOption.value} payments to ${selectedBankCountry?.label} require a purpose to be included in the transaction.`;

  if (purposeCodeOptions.length === 0) return null;

  return (
    <div className={styles.purposeCode}>
      <Dropdown
        value={purposeCode}
        onChange={(code) => setPurposeCode(code as InternationalWireLocalPurposeOption)}
        label="Purpose"
        options={purposeCodeOptions}
        isSearchable
      />
      <Helper>{infoText}</Helper>
    </div>
  );
};
export default InternationalPurpose;
