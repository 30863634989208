import { DefaultValue, selectorFamily } from "recoil";

import offerInputsState, { FeeInputType, defaultOfferInput } from "./offerInputs";

const feeInputTypeState = selectorFamily<FeeInputType, number>({
  key: "compareCreditOffers/feeInputTypeState",
  get:
    (offerIndex) =>
    ({ get }) => {
      const offerInputs = get(offerInputsState);
      return offerInputs[offerIndex].feeInputType;
    },
  set:
    (offerIndex) =>
    ({ set }, newValue) => {
      set(offerInputsState, (prev) => {
        const feeInputType =
          newValue instanceof DefaultValue
            ? defaultOfferInput.feeInputType
            : (newValue as FeeInputType);

        return [
          ...prev.slice(0, offerIndex),
          {
            ...prev[offerIndex],
            feeInputType,
          },
          ...prev.slice(offerIndex + 1),
        ];
      });
    },
});

export default feeInputTypeState;
