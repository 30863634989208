import classNames from "classnames";
import { ReactNode, RefObject } from "react";
import { GroupBase, MenuProps, SelectInstance } from "react-select";

import styles from "./DropdownMenu.module.scss";

type DropdownMenuProps<
  TOption,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> = MenuProps<TOption, TIsMulti, TGroup> & {
  selectRef: RefObject<SelectInstance<TOption, TIsMulti, TGroup>>;
  appendElement?: ReactNode;
};

const DropdownMenu = <
  TOption,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
  props: DropdownMenuProps<TOption, TIsMulti, TGroup>
) => {
  const { innerRef, innerProps, children, className, placement, selectRef, appendElement } = props;

  // NB(alex): This seems to be the only way to determine if there are any children visible in the dropdown menu if there are no filtered results visible.
  const hasChildren = Boolean(appendElement || selectRef.current?.hasOptions());

  return (
    <div
      className={classNames(
        className,
        styles.menu,
        styles[`placement--${placement}`],
        hasChildren && styles.hasChildren
      )}
      ref={innerRef}
      onMouseLeave={() => {
        // Used for unsetting the menu's focus state when the mouse is no longer hovering over the menu.
        selectRef.current?.setState((prev) => ({ ...prev, focusedOption: null }));
      }}
      {...innerProps}
    >
      {children}

      {appendElement && (
        <div
          className={styles.appendedElementContainer}
          onMouseEnter={() => {
            // Fixes a minor visual issue where the selected menu item doesn't get unselected when hovering over the appended element.
            selectRef.current?.setState((prev) => ({ ...prev, focusedOption: null }));
          }}
        >
          {appendElement}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
