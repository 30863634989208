import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountAutoPayBankAccountHighlightItem from "resources/capital-accounts/components/CapitalAccountAutoPayBankAccountHighlightItem";
import CapitalAccountAutoPayConnectedStoresHighlightItem from "resources/capital-accounts/components/CapitalAccountAutoPayConnectedStoresHighlightItem";
import CapitalAccountInterestDueHighlightItem from "resources/capital-accounts/components/CapitalAccountInterestDueHighlightItem";
import getCapitalAccountRepaymentOptionType from "resources/capital-accounts/utils/getCapitalAccountRepaymentOptionType";
import Divider from "ui/data-display/Divider";
import { useIsMobile } from "utils/device/useMediaQuery";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAvailableHighlightItems: FC<Props> = ({ capitalAccountSummary }) => {
  const isMobile = useIsMobile();

  return (
    <div className="mt-10 flex gap-x-4 border border-grey-100 py-4">
      <div className="w-40 flex-1">
        {getCapitalAccountRepaymentOptionType(capitalAccountSummary) === "DailyInstallments" ? (
          <CapitalAccountAutoPayBankAccountHighlightItem
            capitalAccountSummary={capitalAccountSummary}
            size="sm"
            sideRuleColor="none"
          />
        ) : (
          <CapitalAccountAutoPayConnectedStoresHighlightItem
            capitalAccountSummary={capitalAccountSummary}
            infoIconColor="primary"
            size="sm"
            sideRuleColor="none"
          />
        )}
      </div>

      {isMobile ? (
        <Divider orientation="horizontal" className="my-6" />
      ) : (
        <Divider orientation="vertical" className="mx-6" />
      )}

      {capitalAccountSummary.details.netApr > 0 && (
        <div className="w-40 flex-1">
          <CapitalAccountInterestDueHighlightItem
            capitalAccountSummary={capitalAccountSummary}
            infoIconColor="primary"
            size="sm"
            sideRuleColor="none"
          />
        </div>
      )}
    </div>
  );
};

export default CapitalAvailableHighlightItems;
