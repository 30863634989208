import useSearchParamOption from "utils/search-params/useSearchParamOption";
import { TabConfig } from "utils/tabs/useTabState";
import getObjectKeys from "utils/ts/getObjectKeys";

export const BILL_DETAILS_PAYEE_PAYMENT_METHOD_TAB_KEY = "payment-details";

export const billDetailsPayeePaymentMethodTabs = {
  ach: { label: "ACH" },
  "domestic-wire": { label: "Wire" },
  "international-wire": { label: "International wire" },
} satisfies TabConfig;

export type PayeePaymentMethodTabKey = keyof typeof billDetailsPayeePaymentMethodTabs;

const useBillDetailsPayeePaymentMethodTabs = (defaultValue: PayeePaymentMethodTabKey = "ach") => {
  const [activeTab, setActiveTab] = useSearchParamOption(
    "payment-details",
    getObjectKeys(billDetailsPayeePaymentMethodTabs),
    defaultValue
  );

  return {
    tabs: billDetailsPayeePaymentMethodTabs,
    activeTab: activeTab,
    setActiveTab: setActiveTab,
  };
};

export default useBillDetailsPayeePaymentMethodTabs;
