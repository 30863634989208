import classNames from "classnames";
import React, { RefObject, useEffect, useState } from "react";
import CurrencyInputField from "react-currency-input-field";
import { InfoIconVariant } from "ui/icons/InfoIcon";

import InputWrapper, { shouldShowLabelAsFocused, CommonInputProps } from "../InputWrapper";

import styles from "./CurrencyInput.module.scss";

type Props = {
  placeholder?: string;
  icon?: React.ReactElement;
  onIconClick?: () => void;
  onClick?: () => void;
  onChange: (value: number | null | undefined) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  value: number | null | undefined;
  prefixValue?: string;
  maxLength?: number;
  autoFocus?: boolean;
  allowDecimals?: boolean;
  errorVariant?: InfoIconVariant;
  inputRef?: RefObject<HTMLInputElement>;
} & CommonInputProps;

/**
 * NB(alex): I don't want to mark this component as `DEPRECATED` yet because it might still be useful, but now that `backend-v2` is using the `Money` object, which expects `amount: string`, the string-based `CurrencyInputV3` is likely the better option.
 *
 * This component is exactly like the original CurrencyInput except that its `value` prop is an amount in cents.
 */
const CurrencyInput: React.FC<Props> = ({
  id,
  label,
  placeholder,
  icon,
  onIconClick,
  onClick,
  onChange,
  onFocus,
  onBlur,
  value: valueInCents,
  prefixValue,
  autoFocus,
  hasError,
  errorMessage,
  errorVariant,
  disabled,
  append,
  className,
  maxLength = 16,
  allowDecimals = true,
  inputRef,
}: Props) => {
  const [focusOutline, setFocusOutline] = useState(false);

  const valueInDollars =
    valueInCents === undefined || valueInCents === null ? undefined : valueInCents / 100;

  const showLabelAsFocused = shouldShowLabelAsFocused(
    label,
    valueInDollars === undefined ? undefined : `${valueInDollars}`,
    placeholder,
    focusOutline
  );

  const [internalStringValue, setInternalStringValue] = useState<string | undefined>(
    valueInDollars === undefined ? undefined : `${valueInDollars}`
  );

  useEffect(() => {
    setInternalStringValue(valueInDollars === undefined ? undefined : `${valueInDollars}`);
  }, [valueInDollars]);

  return (
    <InputWrapper
      append={append}
      id={id}
      disabled={disabled}
      focusOutline={focusOutline}
      showLabelAsFocused={showLabelAsFocused}
      className={classNames(
        className,
        styles.container,
        showLabelAsFocused && styles["container--focused"]
      )}
      label={label}
      icon={icon}
      onIconClick={onIconClick}
      onClick={onClick}
      hasValue={valueInCents !== undefined}
      prefixValue={prefixValue}
      hasError={hasError}
      errorMessage={errorMessage}
      errorVariant={errorVariant}
    >
      <CurrencyInputField
        ref={inputRef}
        allowDecimals={allowDecimals}
        disabled={disabled}
        id={id}
        aria-describedby={placeholder}
        placeholder={placeholder}
        decimalsLimit={allowDecimals ? 2 : 0}
        decimalScale={allowDecimals ? 2 : 0}
        value={internalStringValue}
        allowNegativeValue={false}
        onValueChange={(value, _name, values) => {
          setInternalStringValue(value);
          onChange(!values || values?.float === null ? null : values.float * 100);
        }}
        onFocus={(e) => {
          setFocusOutline(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocusOutline(false);
          onBlur?.(e);
        }}
        maxLength={maxLength}
        autoFocus={autoFocus}
      />
    </InputWrapper>
  );
};

export default CurrencyInput;
