import classNames from "classnames";
import { FC, ReactNode } from "react";

import styles from "./WithLabel.module.scss";

export type SharedWithLabelProps = {
  label: ReactNode;
  disabled?: boolean;
  className?: string;
};

type Props = SharedWithLabelProps & {
  htmlFor: string;
  children: ReactNode;
};

const WithLabel: FC<Props> = ({ htmlFor, children, label, className, disabled }) => {
  return (
    <div className={classNames(className, styles.withLabelContainer, disabled && styles.disabled)}>
      {children}
      <label htmlFor={htmlFor}>{label}</label>
    </div>
  );
};

export default WithLabel;
