import { LineOfCreditAgreementMetadataRep } from "reps/LineOfCreditAgreementMetadataRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class LineOfCreditAgreementApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getMetadata(
    businessGuid: string,
    lineOfCreditGuid: string
  ): Promise<LineOfCreditAgreementMetadataRep | null> {
    const url = `/businesses/${businessGuid}/lines-of-credit/${lineOfCreditGuid}/line-of-credit-agreements/metadata`;
    return this.api.get<LineOfCreditAgreementMetadataRep | null>(url);
  }
}
