import CapitalAccountDetailsRep from "reps/CapitalAccountDetailsRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

type RepaymentOptionType = CapitalAccountDetailsRep.CapitalRepaymentOption["type"];

const getCapitalAccountRepaymentOptionType = (
  capitalAccountSummary: CapitalAccountSummaryRep.Complete
): RepaymentOptionType => {
  return capitalAccountSummary.details.repayment.option.type;
};

export default getCapitalAccountRepaymentOptionType;
