import InvoiceUpload from "components/InvoiceUpload";
import { Suspense, useState } from "react";
import Section from "ui/data-display/Section";
import Dropdown from "ui/inputs/Dropdown";
import { Heading3 } from "ui/typography";
import { HighbeamTransaction, isCardTransaction } from "utils/types/transactionsTypes";

import { useTransactionInfoFlexpaneContext } from "../context/TransactionInfoFlexpaneProvider";

import TransactionNotes from "./TransactionNotes";
import TransactionSubcategoryDropdown from "./TransactionSubcategoryDropdown";

type Props = {
  transaction: HighbeamTransaction;
};

const TransactionInfoFlexpaneAdditionalInfoSection: React.FC<Props> = ({ transaction }) => {
  const { refreshTransactions } = useTransactionInfoFlexpaneContext();

  const [paymentMetadataGuid, setPaymentMetadataGuid] = useState<string>(
    transaction.generalPaymentMetadataGuid ?? ""
  );

  const loadingDropdown = (
    <Dropdown label="Category" placeholder="Category" value={null} options={[]} isLoading />
  );

  return (
    <Section>
      <Heading3>Additional info</Heading3>
      <Suspense fallback={loadingDropdown}>
        <TransactionSubcategoryDropdown transaction={transaction} />
      </Suspense>
      <InvoiceUpload
        paymentMetadataGuid={paymentMetadataGuid}
        setPaymentMetadataGuid={setPaymentMetadataGuid}
        attachmentType={isCardTransaction(transaction) ? "receipt" : "invoice"}
        unitCoDepositAccountId={transaction.accountId}
        transactionId={transaction.id}
        refreshTransactions={refreshTransactions}
      />
      <TransactionNotes
        paymentMetadataGuid={paymentMetadataGuid}
        setPaymentMetadataGuid={setPaymentMetadataGuid}
        transaction={transaction}
        refreshTransactions={refreshTransactions}
      />
    </Section>
  );
};

export default TransactionInfoFlexpaneAdditionalInfoSection;
