import BillSyncRep from "reps/BillSyncRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillSyncApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async sync(billId: string, dryRun: boolean): Promise<BillSyncRep.Complete> {
    const url = `/accounting/bill-syncs/sync/${billId}?dryRun=${dryRun}`;
    return (await this.api.post<BillSyncRep.Complete>(url))!;
  }
}
