import { HighbeamApiError } from "utils/ajax";

const isGuidNotFoundError = (error: unknown): error is HighbeamApiError => {
  return (
    error instanceof HighbeamApiError &&
    (error.statusCode === 400 || // Malformed guid
      error.statusCode === 404) // Guid not found
  );
};

export default isGuidNotFoundError;
