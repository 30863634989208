import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import UserRoleOptionDescription from "resources/user-roles/components/UserRoleOptionDescription";
import UserRoleOptionIcon from "resources/user-roles/components/UserRoleOptionIcon";
import useUserRolesQueryOptions from "resources/user-roles/queries/useUserRolesQueryOptions";
import sortUserRoles from "resources/user-roles/utils/sortUserRoles";
import RadioCard from "ui/inputs/RadioCard";
import RadioCardSelect from "ui/inputs/RadioCardSelect";

import { InviteUserFormInputs } from "./hooks/useInviteUserForm";

const UserRoleSelect = () => {
  const { control } = useFormContext<InviteUserFormInputs>();
  const { data: userRoles } = useSuspenseQuery(useUserRolesQueryOptions());
  const sortedUserRoles = useMemo(() => sortUserRoles(userRoles), [userRoles]);

  return (
    <Controller
      name="userRole"
      control={control}
      render={({
        field: {
          ref: _ref, // `RadioCardSelect` does not support `ref` yet so we need to omit it.
          ...field
        },
      }) => (
        <RadioCardSelect options={sortedUserRoles} value={field.value} onChange={field.onChange}>
          {({ option: role, isSelected, onSelect }) => (
            <RadioCard
              key={role.guid}
              icon={<UserRoleOptionIcon type={role.type} />}
              checked={isSelected}
              value={role.guid}
              label={role.name}
              description={<UserRoleOptionDescription type={role.type} />}
              onChange={() => onSelect(role)}
            />
          )}
        </RadioCardSelect>
      )}
    />
  );
};

export default UserRoleSelect;
