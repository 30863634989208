import BankAccountRep from "reps/BankAccountRep";

// TODO: We currently only allow 1 primary account. But we need to support one on
// each of our partner banks.
function isPseudoPrimary(account: BankAccountRep.Complete): boolean {
  return account.isPrimary || /primary/i.test(account.name);
}

const sortBankAccounts = (a: BankAccountRep.Complete, b: BankAccountRep.Complete) => {
  // Order by Thread accounts and then Blue Ridge accounts
  if (a.blueRidgeAccount && !b.blueRidgeAccount) return 1;
  if (!a.blueRidgeAccount && b.blueRidgeAccount) return -1;

  // Force primary to the top
  if (isPseudoPrimary(a) && !isPseudoPrimary(b)) return -1;
  if (!isPseudoPrimary(a) && isPseudoPrimary(b)) return 1;

  // Force High Yield account to the bottom
  if (a.highbeamType.name === "HighYield" && b.highbeamType.name !== "HighYield") return 1;
  if (a.highbeamType.name !== "HighYield" && b.highbeamType.name === "HighYield") return -1;

  return a.name.localeCompare(b.name);
};

export default sortBankAccounts;
