import CounterpartyAliasManagementRep from "reps/CounterpartyAliasManagementRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CounterpartyAliasManagementApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async renameCounterparty(body: CounterpartyAliasManagementRep.RenameCounterparty): Promise<void> {
    const url = "/insights/counterparty-aliases/rename-counterparty";
    return (await this.api.put<void>(url, body))!;
  }

  async deleteByOriginalCounterparty(
    body: CounterpartyAliasManagementRep.DeleteByOriginalCounterparty
  ): Promise<void> {
    const url = "/insights/counterparty-aliases/delete-by-original-counterparty";
    return (await this.api.delete<void>(url, body))!;
  }
}
