import { FC } from "react";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import cn from "utils/tailwind/cn";

import { SharedButtonWithTooltipProps } from "../Button/ButtonWithTooltip";

import ButtonLink, { ButtonLinkProps } from ".";

type Props = ButtonLinkProps & SharedButtonWithTooltipProps;

const ButtonLinkWithTooltip: FC<Props> = ({
  tooltip,
  disabled,
  fullWidth,
  className,
  ...props
}) => {
  const buttonLink = (
    <ButtonLink
      disabled={disabled}
      className={cn(fullWidth === true ? "w-full" : fullWidth, className)}
      {...props}
    />
  );

  return (
    <ItemWithTooltip tooltip={tooltip}>
      {disabled ? (
        <span className={cn("inline-block", fullWidth && "w-full")} tabIndex={0}>
          {buttonLink}
        </span>
      ) : (
        buttonLink
      )}
    </ItemWithTooltip>
  );
};

export default ButtonLinkWithTooltip;
