import BankAccountRep from "reps/BankAccountRep";
import { ConnectedStoreType } from "resources/connected-stores/types";

import AmazonInstructions from "./AmazonInstructions";
import PayPalInstructions from "./PayPalInstructions";
import ShopifyInstructions from "./ShopifyInstructions";
import StripeInstructions from "./StripeInstructions";

type Props = {
  accountToDisplay?: BankAccountRep.Complete;
  activeTab: ConnectedStoreType;
};

const PayoutInstructions: React.FC<Props> = ({ accountToDisplay, activeTab }) => {
  switch (activeTab) {
    case "Shopify":
      return <ShopifyInstructions accountToDisplay={accountToDisplay} />;
    case "Amazon":
      return <AmazonInstructions accountToDisplay={accountToDisplay} />;
    case "PayPal":
      return <PayPalInstructions accountToDisplay={accountToDisplay} />;
    case "Stripe":
      return <StripeInstructions accountToDisplay={accountToDisplay} />;
  }
};

export default PayoutInstructions;
