import { ArrowFatLinesUp } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import MultiStep from "layouts/MultiStep";
import { FC } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountBreadcrumbItem from "resources/capital-accounts/components/CapitalAccountBreadcrumbItem";
import capitalAccountSummaryQueryHooks from "resources/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import checkIsCapitalAccountSummaryWithChargeCard from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToRepayCapital from "utils/permissions/useIsAllowedtoRepayCapital";

import RepayHighbeamCardsSteps from "./components/RepayHighbeamCardsSteps";
import RepayLineOfCreditAmountView from "./views/RepayHighbeamCardsAmountView";
import RepayHighbeamCardsConfirmationView from "./views/RepayHighbeamCardsConfirmationView";

type CapitalRepayHighbeamCardsPageContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalRepayHighbeamCardsPageContent: FC<CapitalRepayHighbeamCardsPageContentProps> = ({
  capitalAccountSummary,
}) => {
  const isAllowedToRepayCapital = useIsAllowedToRepayCapital();

  if (
    !isAllowedToRepayCapital ||
    !checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary)
  ) {
    return <Navigate to={`/capital/${capitalAccountSummary.guid}`} />;
  }

  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to={`/capital/${capitalAccountSummary.guid}`} />
        <DashboardPage.Header.IconTile icon={<ArrowFatLinesUp size={20} weight="light" />} />
        <DashboardPage.Header.Title>Repay Highbeam Cards</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <MultiStep
          steps={
            <RepayHighbeamCardsSteps capitalAccountSummaryWithChargeCard={capitalAccountSummary} />
          }
        >
          <Routes>
            <Route
              index
              element={
                <RepayLineOfCreditAmountView
                  capitalAccountSummaryWithChargeCard={capitalAccountSummary}
                />
              }
            />
            <Route
              path="/confirm"
              element={
                <RepayHighbeamCardsConfirmationView
                  capitalAccountSummaryWithChargeCard={capitalAccountSummary}
                />
              }
            />
          </Routes>
        </MultiStep>
      </DashboardPage.Section>
    </>
  );
};

const CapitalRepayHighbeamCardsPage = () => {
  const { capitalAccountGuid } = useParams();
  const { data: capitalAccountSummary } = capitalAccountSummaryQueryHooks.useQuery({
    capitalAccountGuid: capitalAccountGuid ?? null,
  });

  if (
    capitalAccountSummary === null ||
    (capitalAccountSummary && !checkIsCapitalAccountSummaryWithChargeCard(capitalAccountSummary))
  ) {
    return <Navigate to="/capital" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>
          <CapitalAccountBreadcrumbItem
            capitalAccountSummary={capitalAccountSummary}
            isCurrentItem={false}
          />
          <Breadcrumbs.CurrentItem>Repay Highbeam Cards</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        {capitalAccountSummary ? (
          <CapitalRepayHighbeamCardsPageContent capitalAccountSummary={capitalAccountSummary} />
        ) : (
          <DashboardPage.DotsLoader />
        )}
      </DashboardPage>
    </>
  );
};

export default CapitalRepayHighbeamCardsPage;
