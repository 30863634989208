import TransactionsV2 from "components/TransactionsV2";
import Empty from "components/TransactionsV2/Empty";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, FC, SetStateAction, Suspense } from "react";
import CapitalTransactionRep from "reps/CapitalTransactionRep";
import useDownloadCapitalAccountTransactionsCsvMutation from "resources/capital-account-transactions/mutations/useDownloadCapitalAccountTransactionsCsvMutation";
import { useCapitalAccountTransactionsQuery } from "resources/capital-account-transactions/queries/useCapitalAccountTransactions";
import getCapitalTransactionType from "resources/capital-account-transactions/utils/getCapitalTransactionType";
import Shimmer from "ui/feedback/Shimmer";
import AmountCell from "ui/table/AmountCell";
import DateTimeCell, { DATE_TIME_CELL_DATE_FORMAT } from "ui/table/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import { useFuseSearch } from "utils/fuse";

import CapitalAccountTransactionTransactionTypeCell from "../CapitalAccountTransactionTransactionTypeCell";

const columns: Column<CapitalTransactionRep.Complete>[] = [
  {
    title: "Date",
    cellRender: ({ date }) => {
      return <DateTimeCell date={date} />;
    },
  },
  {
    title: "Transaction type",
    cellRender: (capitalTransaction) => {
      return (
        <CapitalAccountTransactionTransactionTypeCell capitalTransaction={capitalTransaction} />
      );
    },
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ amount }) => (
      <AmountCell cents={amount} direction={amount < 0 ? "negative" : "positive"} />
    ),
  },
];

// NB(alex): Experimental pattern, please ask before copying!
type Control<T> = [T, Dispatch<SetStateAction<T>>];

type CapitalAccountTransactionsTableProps = {
  capitalAccountGuid: string;
  sinceControl: Control<Dayjs>;
  untilControl: Control<Dayjs>;
  searchControl: Control<string>;
};

const CapitalAccountTransactionsTable: FC<CapitalAccountTransactionsTableProps> = ({
  capitalAccountGuid,
  sinceControl: [since, setSince],
  untilControl: [until, setUntil],
  searchControl: [search, setSearch],
}) => {
  const { data = [], isPending: isLoadingTransactions } = useCapitalAccountTransactionsQuery({
    capitalAccountGuid,
    since,
    until,
  });

  const { mutate: downloadCapitalAccountTransactions, isPending: isDownloadingTransactions } =
    useDownloadCapitalAccountTransactionsCsvMutation();

  const transactions = useFuseSearch(data, search, {
    shouldSort: true,
    threshold: 0.3,
    keys: [
      {
        name: "date",
        getFn: (transaction) => dayjs(transaction.date).format(DATE_TIME_CELL_DATE_FORMAT),
      },
      "amount",
      "summary",
      {
        name: "transactionType",
        getFn: (transaction) => getCapitalTransactionType(transaction),
      },
    ],
  });

  return (
    <TransactionsV2
      filters={
        <TransactionsV2.Filters
          searchBar={<TransactionsV2.SearchBar value={search} onChange={setSearch} />}
          dateRangePickers={
            <TransactionsV2.DateRangePickers
              fromPicker={
                <TransactionsV2.FromDatePicker
                  fromDate={since.toDate()}
                  toDate={until.toDate()}
                  setFromDate={(date) => setSince(dayjs(date))}
                />
              }
              toPicker={
                <TransactionsV2.ToDatePicker
                  fromDate={since.toDate()}
                  toDate={until.toDate()}
                  setToDate={(date) => setUntil(dayjs(date))}
                />
              }
            />
          }
          paginationInfo={
            <Suspense fallback={<Shimmer />}>
              <TransactionsV2.PaginationInfo
                count={transactions.length}
                totalCount={transactions.length}
              />
            </Suspense>
          }
          exportButton={
            <TransactionsV2.ExportButton
              isLoading={isDownloadingTransactions}
              onClick={() =>
                downloadCapitalAccountTransactions({
                  capitalAccountGuid: capitalAccountGuid,
                  sinceInclusive: since,
                  untilInclusive: until,
                })
              }
            />
          }
        />
      }
      table={
        !isLoadingTransactions && transactions.length === 0 ? (
          <Empty />
        ) : (
          <Table
            data={transactions}
            columns={columns}
            rowKey={({ unitCoId }) => unitCoId}
            isLoading={isLoadingTransactions}
          />
        )
      }
    />
  );
};

export default CapitalAccountTransactionsTable;
