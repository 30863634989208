import {
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useSetRecoilState } from "recoil";
import { invoiceNumberState } from "state/payments/international/invoiceNumber";
import TextInput, { useTextInput, validator } from "ui/inputs/TextInput";

import styles from "./InternationalInvoiceNumber.module.scss";

const InternationalInvoiceNumber = () => {
  const input = INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.INVOICE_NUMBER];
  const setInvoiceNumber = useSetRecoilState(invoiceNumberState);
  const invoiceNumberText = useTextInput({
    initialValue: undefined,
    onChange: validator((value: string) => input.defaultValidation.test(value), input.errorMessage),
  });

  return (
    <div className={styles.invoiceNumber}>
      <TextInput
        type="text"
        value={invoiceNumberText.value}
        onChange={(value: string) => {
          invoiceNumberText.onChange(value);
          setInvoiceNumber(value);
        }}
        label={input.label}
        onBlur={invoiceNumberText.onBlur}
        hasError={invoiceNumberText.hasError}
        errorMessage={input.errorMessage}
      />
    </div>
  );
};
export default InternationalInvoiceNumber;
