import IconWithTooltip from "components/common/IconWithTooltip";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useBusinessDetailsFormContext } from "resources/business-details/hooks/useBusinessDetailsForm";
import Fieldset from "ui/inputs/Fieldset";
import TextInput from "ui/inputs/TextInput";
import Text from "ui/typography/Text";
import useIsAllowedToUpdateBusinessDetails from "utils/permissions/useIsAllowedToUpdateBusinessDetails";

const BusinessDetailsFormFields: FC = () => {
  const { control } = useBusinessDetailsFormContext();
  const hasWritePermission = useIsAllowedToUpdateBusinessDetails();

  return (
    <Fieldset width="wide">
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextInput
            label="Legal business name"
            disabled
            {...field}
            append={
              <IconWithTooltip
                color="dark"
                tooltip={
                  <Text size={12}>
                    Please reach out to our support team if you would like to edit your legal
                    business name.
                  </Text>
                }
              />
            }
          />
        )}
      />
      <Controller
        name="dba"
        control={control}
        render={({ field, formState }) => (
          <TextInput
            label="Doing business as"
            hasError={Boolean(formState.errors.dba)}
            errorMessage={formState.errors.dba?.message}
            disabled={!hasWritePermission}
            {...field}
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field, formState }) => (
          <TextInput
            label="Business phone number"
            hasError={Boolean(formState.errors.phoneNumber)}
            errorMessage={formState.errors.phoneNumber?.message}
            disabled={!hasWritePermission}
            {...field}
          />
        )}
      />
    </Fieldset>
  );
};

export default BusinessDetailsFormFields;
