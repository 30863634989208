import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";
import { HighbeamReturnedReceivedAchTransaction } from "utils/types/transactionsTypes";

type Props = {
  transaction: HighbeamReturnedReceivedAchTransaction;
};

const ReturnedReceivedAchTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>Returned ACH details</Heading3>
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Reason</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.reason}</MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default ReturnedReceivedAchTransactionDetailsSection;
