import { Card } from "@highbeam/unit-node-sdk";
import React from "react";

import HighbeamCardSensitiveField from "./HighbeamCardSensitiveField";

type Props = {
  isLoading: boolean;
  isRevealed: boolean;
  isAvailable: boolean;
  card: Card;
  textColor?: "white" | "black";
};

const HighbeamCardNumber: React.FC<Props> = (props) => {
  const placeholder = `•••• •••• •••• ${props.card.attributes.last4Digits}`;

  return (
    <HighbeamCardSensitiveField
      card={props.card}
      placeholder={placeholder}
      isLoading={props.isLoading}
      isRevealed={props.isRevealed}
      isAvailable={props.isAvailable}
      vgs={{ cardId: props.card.id, name: "pan", field: "pan" }}
      textColor={props.textColor}
    />
  );
};

export default HighbeamCardNumber;
