import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import Jwt from "reps/Jwt";
import { getJwtFromAccessToken } from "utils/auth";

import auth0ClientState from "./auth0Client";

const jwtVersionState = atom<number>({
  key: "auth/jwtVersion",
  default: 0,
});

export const useRefreshJwt = () => {
  const setJwtVersion = useSetRecoilState(jwtVersionState);
  const auth0Client = useRecoilValue(auth0ClientState);
  return async () => {
    // Skip cache and force refetch
    await auth0Client.getTokenSilently({ cacheMode: "off" });
    // NOTE: If you got here after seeing an "Error: Login required” and 2FA not completing,
    // you're 1) on localhost, and 2) using a browser with Intelligent Tracking Protection (ITP).
    // This is a non-issue on staging and prod as we use the custom domains feature on Auth0. See:
    // https://auth0.com/docs/troubleshoot/authentication-issues/renew-tokens-when-using-safari#workarounds

    setJwtVersion((currVal) => currVal + 1);
  };
};

/**
 * DEPRECATED: The `useJwt()` custom hook should be preferred over this where possible,
 * since the custom hook automatically refreshes.
 */
const jwtState = selector<Jwt>({
  key: "auth/jwt",
  get: async ({ get }) => {
    get(jwtVersionState);
    const auth0Client = get(auth0ClientState);
    const jwtString = await auth0Client.getTokenSilently();
    return getJwtFromAccessToken(jwtString);
  },
  // The Auth0 client has its own cache.
  cachePolicy_UNSTABLE: { eviction: "most-recent" }, // eslint-disable-line camelcase
});

export default jwtState;
