import dayjs from "dayjs";
import { useState } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import useCapitalAccountTransactions from "resources/capital-account-transactions/queries/useCapitalAccountTransactions";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import useCapitalDrawdownApprovalLargeAmountInCentsFlagValue from "./useCapitalDrawdownApprovalLargeAmountInCentsFlagValue";

// NB(alex): This is a somewhat hacky placeholder hook for determining if a user can immediately draw down or if they need to get an approval. We intend to move this logic to the backend and delete this hook at some point.

export type CapitalDrawdownType = "instant-drawdown" | "large-drawdown" | "requires-approval";

type Params = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  amountInCents: number;
};

const useCapitalDrawdownType = ({
  capitalAccountSummary,
  amountInCents,
}: Params): CapitalDrawdownType => {
  const isDrawdownApprovalFeatureFlagEnabled = useFeatureFlag("DRAW_DOWN_WITH_APPROVAL_REQUIRED");
  const isDrawdownApprovalRequired =
    capitalAccountSummary.controls.drawdownRequiresApproval && isDrawdownApprovalFeatureFlagEnabled;

  // NB(alex): I'm extremely confused but using `useCapitalAccountTransactions` (with suspense) causes an infinite loop / causes the dates to update. Very strange / shouldn't happen.
  const [now] = useState(dayjs());

  const lineOfCreditTransactions = useCapitalAccountTransactions({
    capitalAccountGuid: capitalAccountSummary.guid,
    since: now.startOf("day").subtract(48, "hours"),
    until: now,
  });

  const sumOfTransactions = lineOfCreditTransactions.reduce((acc, transaction) => {
    return acc + -1 * transaction.amount;
  }, 0);

  const largeAmountInCentsThreshold = useCapitalDrawdownApprovalLargeAmountInCentsFlagValue();

  const allowedToDrawdownInCents = largeAmountInCentsThreshold - sumOfTransactions;

  const isLargeAmount = amountInCents > allowedToDrawdownInCents;

  if (isDrawdownApprovalRequired) {
    return "requires-approval";
  }

  if (isLargeAmount) {
    return "large-drawdown";
  }

  return "instant-drawdown";
};

export default useCapitalDrawdownType;
