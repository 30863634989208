import { FC } from "react";
import Flexpane from "ui/overlay/Flexpane";
import { HighbeamTransaction, isOriginatedAchTransaction } from "utils/types/transactionsTypes";

import { useTransactionInfoFlexpaneContext } from "../context/TransactionInfoFlexpaneProvider";

import OriginatedAchTransactionFlexpaneHeader from "./OriginatedAchTransactionFlexpaneHeader";

type Props = {
  transaction: HighbeamTransaction;
};

const TransactionInfoFlexpaneHeader: FC<Props> = ({ transaction }) => {
  const { onClose } = useTransactionInfoFlexpaneContext();

  if (isOriginatedAchTransaction(transaction)) {
    return <OriginatedAchTransactionFlexpaneHeader transaction={transaction} />;
  } else {
    return <Flexpane.Header onClose={onClose}>Transaction info</Flexpane.Header>;
  }
};

export default TransactionInfoFlexpaneHeader;
