import { CaretDown } from "@phosphor-icons/react";
import { useIsMutating } from "@tanstack/react-query";
import { CashFlowTableDatum } from "pages/CashFlowPage/data/useCashFlowTableData";
import { useCashFlowContext } from "pages/CashFlowPage/providers/CashFlowProvider";
import { FC } from "react";
import { UPDATE_COUNTERPARTY_CATEGORY_MUTATION_KEY } from "resources/counterparty-report/mutations/useUpdateCounterpartyCategoryMutation";
import colors from "styles/colors";
import Menu from "ui/overlay/Menu";
import NotFoundCell from "ui/table/NotFoundCell";
import UpdatingIndicatorCell from "ui/table/UpdatingIndicatorCell";
import Text from "ui/typography/Text";

import CashFlowCategoryMenuContent from "../CashFlowCategoryMenuContent";

import styles from "./CashFlowCategoryCell.module.scss";

const CashFlowCategoryCell: FC<{ datum: CashFlowTableDatum }> = ({ datum }) => {
  const { activeTab } = useCashFlowContext();
  const insightsCategory = datum.category;

  // Exemplar: You can globally fetch a mutation's loading state by its `mutationKey`. We should only specify a `mutationKey` on a mutation if we need to be able to access it via the query cache like this.
  const isPending = useIsMutating({
    mutationKey: [UPDATE_COUNTERPARTY_CATEGORY_MUTATION_KEY, datum.counterparty],
  });

  if (!insightsCategory) {
    return <NotFoundCell />;
  }

  return (
    <Menu
      button={
        isPending ? (
          <UpdatingIndicatorCell />
        ) : (
          <div className={styles.container}>
            <Text
              color={colors.grey[800]}
              weight="medium"
              size={14}
              className={styles.categoryText}
            >
              {insightsCategory.subcategoryDisplayName} <CaretDown />
            </Text>
            <Text color={colors.grey[600]} weight="regular" size={12}>
              {insightsCategory.categoryDisplayName}
            </Text>
          </div>
        )
      }
    >
      {({ open }) => (
        <CashFlowCategoryMenuContent
          value={insightsCategory.value}
          initialDirection={activeTab}
          counterparty={datum.counterparty}
          open={open}
        />
      )}
    </Menu>
  );
};

export default CashFlowCategoryCell;
