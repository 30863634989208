import React from "react";
import { Route, Routes } from "react-router-dom";
import { useIsCurrentBusinessMemberOnboarded } from "resources/business-members/queries/businessMemberQueryHooks";

import SettingsAccountingSoftwarePage from "./SettingsAccountingSoftwarePage";
import SettingsBanksAndCardsPage from "./SettingsBanksAndCardsPage";
import SettingsBusinessPage from "./SettingsBusinessPage";
import SettingsPage from "./SettingsPage";
import SettingsSecurityPage from "./SettingsSecurityPage";
import SettingsStoresPage from "./SettingsStoresPage";
import SettingsUsersPage from "./SettingsUsersPage";

const SettingsRouter: React.FC = () => {
  const isOnboarded = useIsCurrentBusinessMemberOnboarded();

  return (
    <Routes>
      <Route path="/" element={<SettingsPage />} />
      <Route path="/stores" element={<SettingsStoresPage />} />
      <Route path="/banks-and-cards" element={<SettingsBanksAndCardsPage />} />
      {isOnboarded && <Route path="/business" element={<SettingsBusinessPage />} />}
      <Route path="/accounting-software" element={<SettingsAccountingSoftwarePage />} />
      <Route path="/users" element={<SettingsUsersPage />} />
      <Route path="/security" element={<SettingsSecurityPage />} />
    </Routes>
  );
};

export default SettingsRouter;
