import { useCardQuery } from "resources/cards/queries/useCard";
import { getCardholderName, getCardName } from "resources/cards/utils";
import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import Shimmer from "ui/feedback/Shimmer";
import ButtonLink from "ui/inputs/ButtonLink";
import { Heading3 } from "ui/typography";
import {
  HighbeamCardTransaction,
  HighbeamPurchaseTransaction,
} from "utils/types/transactionsTypes";

type Props = {
  transaction: HighbeamCardTransaction | HighbeamPurchaseTransaction;
};

const CardTransactionDetailsSection: React.FC<Props> = ({ transaction }) => {
  const { data: card } = useCardQuery({ cardId: transaction.cardId });

  return (
    <Section>
      <Heading3>{transaction.longMethodName} details</Heading3>
      <MetadataList>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Card</MetadataList.ItemLabel>
          {card ? (
            <MetadataList.ItemValue>{getCardName(card)}</MetadataList.ItemValue>
          ) : (
            <Shimmer />
          )}
        </MetadataList.Item>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Cardholder’s name</MetadataList.ItemLabel>
          {card ? (
            <MetadataList.ItemValue>{getCardholderName(card)}</MetadataList.ItemValue>
          ) : (
            <Shimmer />
          )}
        </MetadataList.Item>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{transaction.summary}</MetadataList.ItemValue>
        </MetadataList.Item>
        {transaction.merchantCategory && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>Merchant type</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{transaction.merchantCategory}</MetadataList.ItemValue>
          </MetadataList.Item>
        )}
      </MetadataList>

      <ButtonLink variant="tertiary" size="sm" to={`/cards/${transaction.cardId}`}>
        View full card details
      </ButtonLink>
    </Section>
  );
};

export default CardTransactionDetailsSection;
