import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFormContext } from "react-hook-form";
import BankAccountRep from "reps/BankAccountRep";
import { z } from "zod";

const schema = z.object({
  amount: z.number().positive(),
  repaymentBankAccount: z.custom<BankAccountRep.Complete>(
    (value) => Boolean(value),
    "Please select a bank account."
  ),
});

export type RepayLineOfCreditFormInputs = z.infer<typeof schema>;

type Params = {
  defaultValues: RepayLineOfCreditFormInputs;
};

const useRepayLineOfCreditForm = ({ defaultValues }: Params) => {
  return useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });
};

export default useRepayLineOfCreditForm;

export const useRepayLineOfCreditFormContext = () => {
  return useFormContext<RepayLineOfCreditFormInputs>();
};
