import { BookOpen, Calculator, FadersHorizontal, User, UserGear } from "@phosphor-icons/react";
import { FC } from "react";
import UserRoleRep from "reps/UserRoleRep";
import RadioCard from "ui/inputs/RadioCard";

const UserRoleOptionIcon: FC<{ type: UserRoleRep.UserRoleType }> = ({ type }) => {
  switch (type) {
    case "Admin":
      return <RadioCard.Icon Icon={UserGear} />;
    case "AccountsPayable":
      return <RadioCard.Icon Icon={Calculator} />;
    case "Bookkeeper":
      return <RadioCard.Icon Icon={BookOpen} />;
    case "Employee":
      return <RadioCard.Icon Icon={User} />;
    case "Custom":
      return <RadioCard.Icon Icon={FadersHorizontal} />;
  }
};

export default UserRoleOptionIcon;
