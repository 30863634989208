import { Info } from "@phosphor-icons/react";
import BankingInfo from "components/BankingInfo";
import BankAccountRep from "reps/BankAccountRep";
import Banner from "ui/data-display/Banner";
import ExternalLink from "ui/navigation/ExternalLink";
import Text from "ui/typography/Text";
import { getAccountType } from "utils/account";

import styles from "../PayoutInstructionModal.module.scss";

const AMAZON_SUPPORT_LINK = "https://pay.amazon.com/help/202161910";

type Props = {
  accountToDisplay?: BankAccountRep.Complete;
};

const AmazonInstructions: React.FC<Props> = ({ accountToDisplay }) => {
  return (
    <>
      <div className={styles.step}>
        <div className={styles.stepNumber}>1</div>
        <Text size={14}>
          Important: Please notify{" "}
          <ExternalLink href={AMAZON_SUPPORT_LINK}>Amazon support</ExternalLink> that you’re
          switching your bank account for payouts. Some customers also find it helpful to call
          Amazon Support while setting up their payouts. Once confirmed, follow these steps:
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>2</div>
        <Text size={14}>
          Go to <span className={styles.highlight}>Settings</span> and click{" "}
          <span className={styles.highlight}>Account Info</span>.
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>3</div>
        <Text size={14}>
          Under <span className={styles.highlight}>Payment Information</span>, click{" "}
          <ExternalLink href="https://sellercentral.amazon.com/sw/AccountInfo/DepositMethodView/step/DepositMethodView">
            Deposit Methods
          </ExternalLink>
          .
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>4</div>
        <Text size={14}>
          Click <span className={styles.highlight}>Add new deposit method</span>.
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>5</div>
        <Text size={14}>
          If a marketplace selection modal shows up, select the Amazon.com marketplace. You can
          repeat the process for other marketplaces as well.
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>6</div>
        <Text size={14}>Enter your Highbeam bank account information.</Text>
      </div>
      {accountToDisplay && (
        <BankingInfo>
          <BankingInfo.Key>Routing number</BankingInfo.Key>
          <BankingInfo.Value textToCopy={accountToDisplay.routingNumber}>
            {accountToDisplay.routingNumber}
          </BankingInfo.Value>

          <BankingInfo.Key>Account number</BankingInfo.Key>
          <BankingInfo.Value textToCopy={accountToDisplay.accountNumber}>
            {accountToDisplay.accountNumber}
          </BankingInfo.Value>

          <BankingInfo.Key>Account type</BankingInfo.Key>
          <BankingInfo.Value>{getAccountType(accountToDisplay)}</BankingInfo.Value>
        </BankingInfo>
      )}
      <div className={styles.step}>
        <div className={styles.stepNumber}>7</div>
        <Text size={14}>
          Click <span className={styles.highlight}>Set Deposit Method</span>.
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>8</div>
        <Text size={14}>
          If <span className={styles.highlight}>Express Payout</span> is turned on for your new
          Deposit Method, please switch it to{" "}
          <span className={styles.highlight}>Standard Payout</span> so Highbeam can process your
          payouts.
        </Text>
      </div>

      <Banner
        icon={<Info />}
        color="blue"
        paragraph="After setting your new deposit method, please wait 3 days for Amazon’s automated deposits to clear and be accepted into your Highbeam account."
      />
    </>
  );
};

export default AmazonInstructions;
