import {
  InternationalWireOption,
  getInternationalWireCountry,
  supportedInternationalWireCountriesOptions,
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { internationalAddressState } from "state/payments/international/address";
import { selectedBankCountryInternationalWireOptionState } from "state/payments/international/selectedBankCountry";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import TextInput, { Filter } from "ui/inputs/TextInput";
import { INITIAL_ADDRESS, mexicoStateOptions, provinceOptions } from "utils/address";
import { useAddressAutocomplete } from "utils/customHooks/useAddressAutocomplete";

import styles from "./InternationalPayeeLegalAddress.module.scss";

const InternationalPayeeLegalAddress = () => {
  const [address, setAddress] = useRecoilState(internationalAddressState);

  const [selectedCountryOption, setSelectedCountryOption] = useState<InternationalWireOption>();
  const selectedBankCountryInternationalWireOption = useRecoilValue(
    selectedBankCountryInternationalWireOptionState
  );
  const {
    streetAddressInput,
    autoCompleteHandleChange,
    autoCompleteHandleInputChange,
    placeId,
    addressSuggestions,
    isPlacePredictionsLoading,
    resetAddressSuggestions,
  } = useAddressAutocomplete(address, setAddress);
  const requiredFields = selectedCountryOption?.usd.requiredFields;

  const onAddressCountryChange = (country: Option) => {
    setAddress({ ...INITIAL_ADDRESS, country });
    resetAddressSuggestions();
  };

  const options = supportedInternationalWireCountriesOptions;

  useEffect(() => {
    if (!address.country) {
      setAddress({ ...INITIAL_ADDRESS, country: selectedBankCountryInternationalWireOption });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (address.country && address.country !== selectedCountryOption) {
      const country = getInternationalWireCountry(address.country.value);
      setSelectedCountryOption(country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.country]);

  return (
    <fieldset>
      <div className={styles.container}>
        <legend className={styles.heading}>Payee’s legal address</legend>
        <div className={styles.body}>
          <div className={styles.row}>
            <Dropdown
              onChange={onAddressCountryChange}
              value={address.country}
              label="Country"
              options={options}
              hideCaret
              hideCursor={address.country !== null}
            />
          </div>
          {address.country && (
            <div className={styles.row}>
              <Dropdown
                value={address.addressLine1}
                inputValue={streetAddressInput}
                onChange={autoCompleteHandleChange}
                onInputChange={autoCompleteHandleInputChange}
                onBlur={() => {
                  if (placeId || !streetAddressInput) {
                    return;
                  }
                  setAddress({
                    ...address,
                    addressLine1: { label: streetAddressInput, value: streetAddressInput },
                  });
                }}
                onClear={() => {
                  setAddress({
                    ...address,
                    addressLine1: null,
                  });
                }}
                isClearable
                options={addressSuggestions}
                filterOption={() => true} // always show all options that autocomplete provides
                label="Street address"
                isLoading={isPlacePredictionsLoading}
                hideCaret
                hideCursor={address.addressLine1 !== null}
              />
            </div>
          )}
          {requiredFields?.has(INTERNATIONAL_WIRE_INPUTS.ADDRESS_2) && (
            <div className={styles.row}>
              <TextInput
                type="text"
                value={address.addressLine2}
                onChange={(addressLine2) => setAddress({ ...address, addressLine2 })}
                label="Address line 2"
              />
            </div>
          )}
          {requiredFields?.has(INTERNATIONAL_WIRE_INPUTS.CITY) && (
            <div className={styles.row}>
              <TextInput
                type="text"
                value={address.city}
                onChange={(city) => setAddress({ ...address, city })}
                label="City"
              />
            </div>
          )}
          {requiredFields?.has(INTERNATIONAL_WIRE_INPUTS.REGION) && (
            <div className={styles.row}>
              <TextInput
                type="text"
                value={address.region || ""}
                onChange={(region) => setAddress({ ...address, region })}
                label="Region"
              />
            </div>
          )}
          {requiredFields?.has(INTERNATIONAL_WIRE_INPUTS.PROVINCE) && (
            <div className={styles.row}>
              <Dropdown
                onChange={(state) => setAddress({ ...address, state })}
                value={address.state}
                label="Province"
                // Only Canada requires province right now, so we can get away with hardcoding the options
                options={provinceOptions}
              />
            </div>
          )}

          {requiredFields?.has(INTERNATIONAL_WIRE_INPUTS.STATE) && (
            <div className={styles.row}>
              <Dropdown
                onChange={(state) => setAddress({ ...address, state })}
                value={address.state}
                label="State"
                // Only Mexico requires state right now, so we can get away with hardcoding the options
                options={mexicoStateOptions}
              />
            </div>
          )}
          {requiredFields?.has(INTERNATIONAL_WIRE_INPUTS.ZIP_CODE) && (
            <div className={styles.row}>
              <TextInput
                type="text"
                value={address.zipCode}
                onChange={(zipCode) => setAddress({ ...address, zipCode })}
                label={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.ZIP_CODE].label}
                maxLength={9}
                inputFilter={Filter.DIGITS}
              />
            </div>
          )}
          {requiredFields?.has(INTERNATIONAL_WIRE_INPUTS.POSTAL_CODE) && (
            <div className={styles.row}>
              <TextInput
                type="text"
                value={address.zipCode}
                onChange={(zipCode) => setAddress({ ...address, zipCode })}
                label={
                  INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.POSTAL_CODE].label
                }
              />
            </div>
          )}
        </div>
      </div>
    </fieldset>
  );
};

export default InternationalPayeeLegalAddress;
