import FullPageLayout, { FullPageLayoutHeaderHighbeamLogoOnly } from "layouts/FullPageLayout";
import LoadingPage from "modules/loading/pages/LoadingPage";
import { useParams } from "react-router-dom";
import InvalidUserInvitation from "resources/user-invitations/components/InvalidUserInvitation";
import UserInvitationCard from "resources/user-invitations/components/UserInvitationCard";
import invitationBySlugQueryHooks from "resources/user-invitations/queries/invitationBySlugQueryHooks";

const InvitationPage = () => {
  const { invitationSlug } = useParams();

  const { isLoading, error, data } = invitationBySlugQueryHooks.useQuery({
    slug: invitationSlug ?? null,
  });

  if (isLoading) return <LoadingPage location={InvitationPage.name} />;

  return (
    <FullPageLayout header={<FullPageLayoutHeaderHighbeamLogoOnly />}>
      <div className="mt-16 px-2 tablet:mt-32">
        {!error && data ? (
          <UserInvitationCard invitation={data.invitation} userExists={data.userExists} />
        ) : (
          <InvalidUserInvitation itemName="invitation" />
        )}
      </div>
    </FullPageLayout>
  );
};

export default InvitationPage;
