import {
  ArrowsClockwise as ArrowsClockwiseIocn,
  CheckCircle as CheckCircleIcon,
} from "@phosphor-icons/react";
import classNames from "classnames";
import { FC } from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Text from "ui/typography/Text";

import styles from "./AutosaveIndicator.module.scss";

export type SaveStatus = "idle" | "saving" | "saved";

type Props = {
  saveStatus: SaveStatus;
};

const AutosaveIndicator: FC<Props> = ({ saveStatus }) => {
  return (
    <div
      className={classNames(
        styles.container,
        saveStatus === "idle" && styles["saveStatus-idle"],
        saveStatus === "saved" && styles["saveStatus-saved"]
      )}
    >
      {saveStatus === "saving" && (
        <>
          <AnimatedSpinner icon={ArrowsClockwiseIocn} />
          <Text as="span">Saving&hellip;</Text>
        </>
      )}

      {saveStatus === "saved" && (
        <>
          <CheckCircleIcon size={14} />
          <Text as="span">Saved</Text>
        </>
      )}
    </div>
  );
};

export default AutosaveIndicator;
