import IconWithTooltip from "components/common/IconWithTooltip";
import { FC, Suspense } from "react";
import BankAccountRep from "reps/BankAccountRep";
import useBankAccountInterestYieldYtd from "resources/bank-accounts/queries/useBankAccountInterestYieldYtd";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import SummaryArray from "ui/data-display/SummaryArray";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import ApyTooltipContent from "../ApyTooltipContent";
import YieldInfoTooltipContent from "../YieldEarnedTooltipContent";

type Props = {
  account: BankAccountRep.Complete;
};

const ApySummary: FC<Props> = ({ account }) => {
  const interestYtd = useBankAccountInterestYieldYtd(account.guid);
  const isInterestOverrideEnabled = useFeatureFlag("SPECIAL_INTEREST_CUSTOMER");

  return (
    <SummaryArray className="py-5">
      <div className="ml-6 flex-1 border-r border-grey-200 pr-2">
        <SummaryArray.Heading className="mb-1">
          Current APY
          {!isInterestOverrideEnabled && (
            <IconWithTooltip color="dark" tooltip={<ApyTooltipContent account={account} />} />
          )}
        </SummaryArray.Heading>
        <Text size={18} weight="medium">
          {(account.depositProduct.interestBps / 100).toFixed(1)}%
        </Text>
      </div>

      <div className="ml-6 flex-1 basis-6">
        <SummaryArray.Heading className="mb-1">
          Yield (YTD)
          <IconWithTooltip color="dark" tooltip={<YieldInfoTooltipContent />} />
        </SummaryArray.Heading>
        <Suspense fallback={<AnimatedSpinner size={18} />}>
          <MoneyAmount
            color={interestYtd > 0 ? "green" : undefined}
            size={18}
            centsTextSize={12}
            cents={interestYtd}
            weight="medium"
          />
        </Suspense>
      </div>
    </SummaryArray>
  );
};

export default ApySummary;
