import { ReactNode } from "react";
import Card from "ui/data-display/Card";
import { Heading1, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  header?: ReactNode;
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  footer?: ReactNode;
  className?: string;
};

const OnboardingCardLayout: React.FC<Props> = ({
  header,
  title,
  subtitle,
  children,
  footer,
  className,
}) => (
  <Card className={cn("mx-auto mb-16 w-full max-w-2xl p-8", className)}>
    {header}

    {title && <Heading1 className="mb-1">{title}</Heading1>}

    {subtitle && (
      <Paragraph className="border-b border-b-grey-100 pb-6 text-sm text-grey-500">
        {subtitle}
      </Paragraph>
    )}

    <div className="mb-8 mt-6">{children}</div>

    {footer && <div className="flex justify-end border-t border-t-grey-200 pt-6">{footer}</div>}
  </Card>
);

export default OnboardingCardLayout;
