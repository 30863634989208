import { useCallback } from "react";
import { useRecoilState } from "recoil";
import isBusinessSwitcherOpenState from "state/superuser/isBusinessSwitcherOpen";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";

import BusinessSwitcherDialog from "./BusinessSwitcherDialog";

const BusinessSwitcher = () => {
  const [isOpen, setIsOpen] = useRecoilState(isBusinessSwitcherOpenState);

  const filter = useCallback(
    (event: KeyboardEvent) => event.key === "k" && event.metaKey && event.shiftKey,
    []
  );

  useKeyboardEvent(
    filter,
    (event: KeyboardEvent) => {
      event.preventDefault();
      setIsOpen((isOpen) => !isOpen);
    },
    []
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  return <BusinessSwitcherDialog isOpen={isOpen} onClose={handleClose} />;
};

export default BusinessSwitcher;
