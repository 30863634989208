import classNames from "classnames";
import { ComponentProps, FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import Shimmer from "ui/feedback/ShimmerV2";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

import styles from "./Breadcrumbs.module.scss";

const BreadcrumbsShimmer = () => {
  return <Shimmer className={cn("w-16", styles.item)} />;
};

const BreadcrumbsItemShimmer = () => {
  return (
    <>
      <BreadcrumbsShimmer />
      <span className={styles.slash}>/</span>
    </>
  );
};

const BreadcrumbsCurrentItemShimmer = () => {
  return <BreadcrumbsShimmer />;
};

type BreadcrumbsItemProps = {
  children: ReactNode;
  to: ComponentProps<typeof Link>["to"];
};

const BreadcrumbsItem: FC<BreadcrumbsItemProps> = ({ children, to }) => {
  return (
    <>
      <Text as="span" className={styles.item}>
        <Link to={to}>{children}</Link>
      </Text>
      <span className={styles.slash}>/</span>
    </>
  );
};

type BreadcrumbsCurrentItemProps = {
  children: ReactNode;
};

const BreadcrumbsCurrentItem: FC<BreadcrumbsCurrentItemProps> = ({ children }) => {
  return (
    <Text as="span" className={classNames(styles.item, styles.currentItem)}>
      {children}
    </Text>
  );
};

type BreadcrumbsProps = {
  children: ReactNode;
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default Object.assign(Breadcrumbs, {
  Item: BreadcrumbsItem,
  CurrentItem: BreadcrumbsCurrentItem,
  ItemShimmer: BreadcrumbsItemShimmer,
  CurrentItemShimmer: BreadcrumbsCurrentItemShimmer,
});
