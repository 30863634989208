import { Unit } from "@highbeam/unit-node-sdk";
import { Buffer } from "buffer";
import env from "env";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { MfaCanceledError } from "modules/mfa/useMfa";
import { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import useGetUnitCoSensitiveTokenWithMfa from "resources/unit-co-customer-token/hooks/useGetUnitCoSensitiveTokenWithMfa";
import { DEFAULT_UNIT_CONFIG } from "resources/unit-co-customer-token/queries/useUnitApi";
import checkDepositState from "state/checkDeposit";
import { notify } from "ui/feedback/Toast";
import ChequeIcon from "ui/icons/ChequeIcon";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Steps, { StepType } from "ui/navigation/Steps";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import styles from "./DepositCheckPage.module.scss";
import AdditionalDetails from "./DepositCheckSteps/AdditionalDetails";
import BackOfCheck from "./DepositCheckSteps/BackOfCheck";
import CheckDepositProcessed from "./DepositCheckSteps/CheckDepositProcessed";
import ConfirmDetails from "./DepositCheckSteps/ConfirmDetails";
import CreateDeposit from "./DepositCheckSteps/CreateDeposit";
import FrontOfCheck from "./DepositCheckSteps/FrontOfCheck";

enum DepositStep {
  CREATE_DEPOSIT,
  FRONT_OF_CHECK,
  BACK_OF_CHECK,
  ADDITIONAL_DETAILS,
  CONFIRM_DETAILS,
  CHECK_SUBMITTED,
}

const steps: StepType[] = [
  {
    id: DepositStep.CREATE_DEPOSIT,
    number: 1,
    title: "Create deposit",
  },
  {
    id: DepositStep.FRONT_OF_CHECK,
    number: 2,
    title: "Front of check",
  },
  {
    id: DepositStep.BACK_OF_CHECK,
    number: 3,
    title: "Back of check",
  },
  {
    id: DepositStep.CONFIRM_DETAILS,
    number: 4,
    title: "Confirm details",
  },
];

const stepsV2: StepType[] = [
  {
    id: DepositStep.CREATE_DEPOSIT,
    number: 1,
    title: "Create deposit",
  },
  {
    id: DepositStep.FRONT_OF_CHECK,
    number: 2,
    title: "Front of check",
  },
  {
    id: DepositStep.BACK_OF_CHECK,
    number: 3,
    title: "Back of check",
  },
  {
    id: DepositStep.ADDITIONAL_DETAILS,
    number: 4,
    title: "Additional details",
  },
  {
    id: DepositStep.CONFIRM_DETAILS,
    number: 5,
    title: "Confirm details",
  },
];

const DepositCheckContent = () => {
  const deposit = useRecoilValue(checkDepositState);
  const resetCheckDeposit = useResetRecoilState(checkDepositState);
  const [currentStep, setCurrentStep] = useState<DepositStep>(DepositStep.CREATE_DEPOSIT);
  const [isCheckDepositProcessing, setIsCheckDepositProcessing] = useState(false);
  const [paymentMetadataGuid, setPaymentMetadataGuid] = useState<string>("");
  const skipEndorsementVerification = Boolean(useFeatureFlag("CHECKS_SKIP_ENDORSEMENT_STEP"));
  const allowInvoiceUpload = Boolean(useFeatureFlag("CHECKS_ALLOW_INVOICE_UPLOAD"));
  const [isEndorsementVerified, setIsEndorsementVerified] = useState(skipEndorsementVerification);

  const getUnitCoSensitiveTokenWithMfa = useGetUnitCoSensitiveTokenWithMfa({
    scopes: ["ACCOUNTS_READ", "CHECK_DEPOSITS_WRITE"],
  });

  const { segmentTrack } = useSegment();

  const submitCheckDeposit = async () => {
    setIsCheckDepositProcessing(true);

    try {
      const sensitiveToken = await getUnitCoSensitiveTokenWithMfa().catch((err) => {
        if (!(err instanceof MfaCanceledError)) {
          notify("warning", "There was an authentication issue. Please try again.");
        }
        throw err;
      });

      const unitApi = new Unit(sensitiveToken, env.UNIT_API_ORIGIN, DEFAULT_UNIT_CONFIG);
      const tags: Record<string, string> = {};
      tags["userProvidedCounterpartyName"] = deposit.counterpartyName;
      if (paymentMetadataGuid && paymentMetadataGuid !== "") {
        tags["generalPaymentMetadataGuid"] = paymentMetadataGuid;
      }

      const resp = await unitApi.checkDeposits.create({
        type: "checkDeposit",

        attributes: {
          /**
           * The check amount (in cents) to deposit.
           */
          amount: deposit.amountInCents,
          description: deposit.description,
          tags: tags,
        },
        relationships: {
          /**
           * The account receiving the check deposit.
           */
          account: {
            data: {
              type: deposit.account!.type,
              id: deposit.account!.value,
            },
          },
        },
      });

      const frontImage = deposit.front.image!.replace(/^data:image\/[a-z]+;base64,/, "");
      const backImage = deposit.back.image!.replace(/^data:image\/[a-z]+;base64,/, "");

      const front = Buffer.from(frontImage, "base64");
      const back = Buffer.from(backImage, "base64");

      // TODO: implement retry and error state
      await unitApi.checkDeposits.upload({
        checkDepositId: resp.data.id,
        isBackSide: false,
        file: front,
      });
      await unitApi.checkDeposits.upload({
        checkDepositId: resp.data.id,
        isBackSide: true,
        file: back,
      });
      setPaymentMetadataGuid(""); // Resets associated payment guid. Fixes https://linear.app/highbeam/issue/HB-5887/previous-checks-additional-attachment-appears-when-depositing-new
      setCurrentStep(DepositStep.CHECK_SUBMITTED);
      segmentTrack(SEGMENT_EVENTS.CHECK_DEPOSIT_COMPLETED);
    } finally {
      setIsCheckDepositProcessing(false);
    }
  };

  // Clear state when the user navigates away from the page
  useEffect(() => {
    return () => {
      resetCheckDeposit();
    };
  }, [resetCheckDeposit]);

  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.IconTile icon={<ChequeIcon />} />
        <DashboardPage.Header.Title>Deposit check</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        {currentStep === DepositStep.CHECK_SUBMITTED ? (
          <CheckDepositProcessed
            amount={deposit.amountInCents}
            to={deposit.account?.label}
            description={deposit.description}
            makeAnotherDeposit={() => {
              resetCheckDeposit();
              setCurrentStep(DepositStep.CREATE_DEPOSIT);
            }}
          />
        ) : (
          <main className={styles.content}>
            {<Steps steps={allowInvoiceUpload ? stepsV2 : steps} currentStep={currentStep} />}
            <section className={styles.content__step}>
              {currentStep === DepositStep.CREATE_DEPOSIT && (
                <CreateDeposit
                  onNextPress={() => {
                    setCurrentStep(DepositStep.FRONT_OF_CHECK);
                    segmentTrack(SEGMENT_EVENTS.CHECK_DEPOSIT_STARTED);
                  }}
                />
              )}
              {currentStep === DepositStep.FRONT_OF_CHECK && (
                <FrontOfCheck
                  onPrevPress={() => setCurrentStep(DepositStep.CREATE_DEPOSIT)}
                  onNextPress={() => setCurrentStep(DepositStep.BACK_OF_CHECK)}
                />
              )}
              {currentStep === DepositStep.BACK_OF_CHECK && !isEndorsementVerified && (
                <BackOfCheck
                  onPrevPress={() => setCurrentStep(DepositStep.FRONT_OF_CHECK)}
                  onNextPress={() => {}}
                  isEndorsementVerified={isEndorsementVerified}
                  setIsEndorsementVerified={setIsEndorsementVerified}
                />
              )}
              {currentStep === DepositStep.BACK_OF_CHECK && isEndorsementVerified && (
                <BackOfCheck
                  onPrevPress={() => setCurrentStep(DepositStep.FRONT_OF_CHECK)}
                  onNextPress={() =>
                    setCurrentStep(
                      allowInvoiceUpload
                        ? DepositStep.ADDITIONAL_DETAILS
                        : DepositStep.CONFIRM_DETAILS
                    )
                  }
                  isEndorsementVerified={isEndorsementVerified}
                  setIsEndorsementVerified={setIsEndorsementVerified}
                />
              )}
              {currentStep === DepositStep.ADDITIONAL_DETAILS && (
                <AdditionalDetails
                  onPrevPress={() => setCurrentStep(DepositStep.BACK_OF_CHECK)}
                  onNextPress={() => setCurrentStep(DepositStep.CONFIRM_DETAILS)}
                  paymentMetadataGuid={paymentMetadataGuid}
                  setPaymentMetadataGuid={setPaymentMetadataGuid}
                />
              )}
              {currentStep === DepositStep.CONFIRM_DETAILS && (
                <ConfirmDetails
                  isLoading={isCheckDepositProcessing}
                  paymentMetadataGuid={paymentMetadataGuid}
                  setPaymentMetadataGuid={setPaymentMetadataGuid}
                  onChangeFrontImage={() => {
                    setCurrentStep(DepositStep.FRONT_OF_CHECK);
                  }}
                  onChangeBackImage={() => {
                    setCurrentStep(DepositStep.BACK_OF_CHECK);
                  }}
                  onPrevPress={() =>
                    setCurrentStep(
                      allowInvoiceUpload
                        ? DepositStep.ADDITIONAL_DETAILS
                        : DepositStep.BACK_OF_CHECK
                    )
                  }
                  onSubmit={() => {
                    submitCheckDeposit();
                  }}
                />
              )}
            </section>
          </main>
        )}
      </DashboardPage.Section>
    </>
  );
};

const DepositCheckPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Deposit check</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DepositCheckContent />
      </DashboardPage>
    </>
  );
};

export default DepositCheckPage;
