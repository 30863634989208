import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import classNames from "classnames";
import { ComponentProps, ReactElement } from "react";
import Pill from "ui/data-display/Pill";

import styles from "./PillList.module.scss";

type Props = {
  children: ReactElement<ComponentProps<typeof Pill>>[];
  className?: string;
  variant?: "wrap" | "noWrap";
};

const PillList: React.FC<Props> = ({ children, className, variant = "noWrap" }) => (
  <ScrollingCarousel className={classNames(styles.wrapper, styles[variant], className)}>
    {children}
  </ScrollingCarousel>
);

export default PillList;
