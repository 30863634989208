import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { makeQueryKey } from "./useBillComments";

const useRefreshBillCommentsQuery = (billId: string) => {
  const businessGuid = useBusinessGuid();

  return useRefreshQuery(makeQueryKey(billId, businessGuid));
};

export default useRefreshBillCommentsQuery;
