import { useSuspenseQuery } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { Controller, FormProvider } from "react-hook-form";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import UserRoleOptionDescription from "resources/user-roles/components/UserRoleOptionDescription";
import UserRoleOptionIcon from "resources/user-roles/components/UserRoleOptionIcon";
import useEditUserRoleMutation from "resources/user-roles/mutations/useEditUserRoleMutation";
import useUserRolesQueryOptions from "resources/user-roles/queries/useUserRolesQueryOptions";
import sortUserRoles from "resources/user-roles/utils/sortUserRoles";
import Divider from "ui/data-display/Divider";
import UserAvatarBar from "ui/data-display/UserAvatarBar";
import RadioCard from "ui/inputs/RadioCard";
import RadioCardSelect from "ui/inputs/RadioCardSelect";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/useModalContext";

import useEditUserRoleForm, { EditUserRoleFormOutputs } from "./useEditUserRoleForm";

export type EditUserRoleParams = {
  userGuid: string;
  initials: string;
  fullName: string;
  userRoleName: string;
  userRoleGuid: string;
};

type Props = EditUserRoleParams & {
  onSuccess: (data: EditUserRoleFormOutputs) => void;
};

const EditUserRoleForm: FC<Props> = ({
  userGuid,
  initials,
  fullName,
  userRoleName,
  userRoleGuid,
  onSuccess,
}) => {
  const currentUserGuid = useUserGuid();
  const { closeModalWithAnimation } = useModalContext();

  const form = useEditUserRoleForm({
    defaultValues: {
      userRoleGuid: userRoleGuid,
    },
  });

  const { mutateAsync: editUserRole, isPending } = useEditUserRoleMutation({
    onSuccess: () => {
      onSuccess(form.getValues());
    },
  });

  const { data: userRoles } = useSuspenseQuery(useUserRolesQueryOptions());
  const sortedUserRoles = useMemo(() => sortUserRoles(userRoles), [userRoles]);

  const onSubmit = form.handleSubmit(async (data) => {
    await editUserRole({
      userRoleGuid: data.userRole.guid,
      userGuid: userGuid,
    });

    /**
     * If the user changes their own role, we refresh the page.
     * It would be better to invalidate the JWT state, but we currently (as of October 2024)
     * have multiple sources of truth (both react-query and Recoil) for the JWT, so this is impractical.
     */
    if (userGuid === currentUserGuid) {
      window.location.reload(); // Intended to force a refresh.
      return;
    }

    closeModalWithAnimation();
    onSuccess(data);
  });

  return (
    <FormProvider {...form}>
      <ModalV4.Form onSubmit={onSubmit}>
        <ModalV4.Header>Change user role</ModalV4.Header>

        <ModalV4.Body>
          <div className="px-4">
            <UserAvatarBar fullName={fullName} initials={initials} userRoleName={userRoleName} />
          </div>

          <Divider className="mb-4" />

          <Controller
            name="userRole"
            control={form.control}
            render={({
              field: {
                ref: _ref, // `RadioCardSelect` does not support `ref` yet so we need to omit it.
                ...field
              },
            }) => (
              <RadioCardSelect
                options={sortedUserRoles}
                value={field.value}
                onChange={field.onChange}
              >
                {({ option: role, isSelected, onSelect }) => {
                  const isCurrentRole = role.guid === userRoleGuid;

                  return (
                    <RadioCard
                      key={role.guid}
                      icon={<UserRoleOptionIcon type={role.type} />}
                      checked={isSelected}
                      value={role.guid}
                      label={isCurrentRole ? `${role.name} (Current role)` : role.name}
                      disabled={isCurrentRole}
                      description={<UserRoleOptionDescription type={role.type} />}
                      onChange={() => onSelect(role)}
                    />
                  );
                }}
              </RadioCardSelect>
            )}
          />
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.SubmitButton
            isLoading={isPending}
            disabled={!form.formState.isValid || !form.formState.isDirty}
          >
            Change user role
          </ModalV4.SubmitButton>

          <ModalV4.CloseButton />
        </ModalV4.Footer>
      </ModalV4.Form>
    </FormProvider>
  );
};

export default EditUserRoleForm;
