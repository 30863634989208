import { useState } from "react";

// Borrowed from https://usehooks.com/useprevious
const usePrevious = <T>(value: T): T | null => {
  const [current, setCurrent] = useState(value);
  const [previous, setPrevious] = useState<T | null>(null);

  if (value !== current) {
    setPrevious(current);
    setCurrent(value);
  }

  return previous;
};

export default usePrevious;
