import { selector } from "recoil";

import { quoteCurrencyState } from "./quoteCurrency";
import { quoteFixedSideState } from "./quoteFixedSideState";
import { quoteLocalAmountInCentsState } from "./quoteLocalAmountInCents";
import quotesState from "./quotes";
import { quoteUsdAmountInCentsState } from "./quoteUsdAmountInCents";

export const quoteCalculatedLocalAmountInCentsState = selector<number>({
  key: "payments/international/quoteCalculatedLocalAmountInCents",
  get: async ({ get }) => {
    const shouldCalculate = get(quoteFixedSideState) === "Send";
    const localAmount = get(quoteLocalAmountInCentsState);
    if (!shouldCalculate) return localAmount;

    const usdAmount = get(quoteUsdAmountInCentsState);
    const currency = get(quoteCurrencyState).value;
    const quote = get(quotesState(currency));

    return Math.round(quote.rate * usdAmount);
  },
});
