import { FC } from "react";
import cn from "utils/tailwind/cn";

export type DividerOrientation = "horizontal" | "vertical";

type Props = {
  className?: string;
  orientation?: DividerOrientation;
};

const Divider: FC<Props> = ({ className, orientation = "horizontal" }) => {
  return (
    <div
      className={cn(
        "bg-grey-100",
        orientation === "horizontal" && "my-6 h-[1px]",
        orientation === "vertical" && "w-[1px]",
        className
      )}
    />
  );
};

export default Divider;
