import classNames from "classnames";
import { CSSProperties, ReactElement, ReactNode, cloneElement } from "react";
import CountBadge from "ui/data-display/CountBadge";

import styles from "./TabsTab.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
  count?: number;
  icon?: ReactElement<{ size?: number }>;
  isActive?: boolean;
  style?: CSSProperties;
};

const TabsTab = ({ children, className, count, icon, isActive, style }: Props) => {
  return (
    <div className={classNames(className, styles.tab)} style={style}>
      {icon && (
        <span className={styles.iconContainer}>
          {cloneElement(icon, { size: icon.props.size ?? 16 })}
        </span>
      )}

      <div
        className={classNames(
          styles.label,
          icon && styles.labelWithIcon,
          isActive && styles.active
        )}
      >
        {children}
      </div>

      {count !== undefined && (
        <CountBadge count={count} backgroundColor={isActive ? "grey-700" : "grey-500"} />
      )}
    </div>
  );
};

export default TabsTab;
