import { captureException } from "@sentry/react";
import { isError } from "@sentry/utils";
import { notify } from "ui/feedback/Toast";

const isTimeoutError = (err: unknown) => isError(err) && err.message === "Timeout";
const isPopupClosedError = (err: unknown) => isError(err) && err.message === "Popup closed";

const handleMfaError = (err: unknown) => {
  if (isTimeoutError(err)) {
    notify("info", "Session timed out. Please try again.");
  } else if (isPopupClosedError(err)) {
    notify("info", "Verification window closed. Please try again.");
    return; // Do not report error to sentry when popup is closed
  } else {
    notify("error", "Something went wrong! Please try again.");
  }

  captureException(err);
};

export default handleMfaError;
