import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { ComponentPropsWithoutRef, FC } from "react";
import useTopLayerPortalContainer from "ui/hooks/useTopLayerPortalContainer";
import cn from "utils/tailwind/cn";

type TooltipProviderProps = ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider>;

const TooltipProvider: FC<TooltipProviderProps> = ({ ...props }) => {
  return <TooltipPrimitive.Provider delayDuration={0} {...props} />;
};

type TooltipProps = ComponentPropsWithoutRef<typeof TooltipPrimitive.Root> & {
  enabled?: boolean;
};

const Tooltip: FC<TooltipProps> = ({ enabled, open, ...props }) => {
  return <TooltipPrimitive.Root open={enabled === false ? false : open} {...props} />;
};

type TooltipTriggerProps = ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>;

const TooltipTrigger: FC<TooltipTriggerProps> = ({ ...props }) => {
  return <TooltipPrimitive.Trigger {...props} />;
};

export type TooltipContentProps = ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
  stopPropagation?: boolean;
};

const TooltipContent: FC<TooltipContentProps> = ({
  className,
  sideOffset = 12,
  children,
  onClick,
  stopPropagation = true,
  ...props
}) => {
  const portalContainer = useTopLayerPortalContainer();

  return (
    <TooltipPrimitive.Portal container={portalContainer}>
      <TooltipPrimitive.Content
        sideOffset={sideOffset}
        side="top"
        className={cn(
          "relative max-w-96 rounded-md border border-grey-200 bg-white px-4 py-3 text-sm shadow-xs",
          className
        )}
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }
          onClick?.(e);
        }}
        {...props}
      >
        {children}

        <TooltipPrimitive.Arrow asChild>
          <span className="absolute bottom-[-1px] h-3 w-3 -translate-x-1.5 translate-y-1/2 -rotate-45 rounded-sm border-2 border-r-0 border-t-0 border-grey-200 bg-white" />
        </TooltipPrimitive.Arrow>
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  );
};

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export default Object.assign(Tooltip, {
  Trigger: TooltipTrigger,
  Content: TooltipContent,
});

export { TooltipProvider };
