import { DEMO_STORE_BALANCES } from "modules/demo-account/constants";
import useIsDemoAccount from "modules/demo-account/queries/useIsDemoAccount";
import RutterConnectionRep from "reps/RutterConnectionRep";
import Table, { TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import { useIsMobile } from "utils/device/useMediaQuery";
import { getDollarsFromCents } from "utils/money";
import { getRutterIcon } from "utils/rutter";
import { formatAmount } from "utils/string";

import styles from "./StoreAccountsTable.module.scss";

type Datum = {
  shopName: string;
  hasSynced: boolean;
  balance: number;
  platformName: RutterConnectionRep.RutterPlatform;
};

type Props = {
  data: Datum[];
};

const StoreAccountsTable: React.FC<Props> = ({ data }) => {
  const isMobile = useIsMobile();
  const isDemoAccount = useIsDemoAccount();
  const columns = createColumns(isMobile, isDemoAccount);

  return <Table columns={columns} data={data} rowKey={(it, index) => `${index}-${it.shopName}`} />;
};

export default StoreAccountsTable;

function createColumns(isMobile: boolean, isDemoAccount: boolean) {
  const storeColumn = {
    title: "Store",
    cellRender: (datum: Datum) => (
      <div className={styles["account-container"]}>
        <img
          className={styles.logo}
          src={getRutterIcon(datum.platformName)}
          alt={datum.platformName}
        />
        <Text size={14} weight="medium">
          {datum.shopName}
        </Text>
      </div>
    ),
  };

  const balanceColumn = {
    title: "Balance",
    align: TableColumnAlignment.RIGHT,
    cellRender: (datum: Datum) => {
      const isNegativeStripeBalance = datum.platformName === "STRIPE" && datum.balance < 0;
      const text =
        datum.hasSynced && !isNegativeStripeBalance
          ? formatAmount(
              getDollarsFromCents(
                isDemoAccount
                  ? datum.balance === 0
                    ? DEMO_STORE_BALANCES[datum.platformName]
                    : datum.balance
                  : datum.balance
              )
            )
          : "Available soon";
      return (
        <Text className={styles["store-balance"]} numeric size={14} weight="medium">
          {text}
        </Text>
      );
    },
  };

  return isMobile ? [storeColumn, balanceColumn] : [storeColumn, balanceColumn];
}
