import { useQuery } from "@tanstack/react-query";
import TransactionFlexpaneNotes from "components/common/transaction-flexpane/TransactionFlexpaneNotes";
import { useEffect, useState } from "react";
import useUpdateOrCreatePaymentMetadataMutation from "resources/payment-metadata/mutations/useUpdateOrCreatePaymentMetadataMutation";
import usePaymentMetadataQueryOptions, {
  PAYMENT_METADATA_QUERY_KEY,
} from "resources/payment-metadata/queries/usePaymentMetadataQueryOptions";
import { UNIT_CO_TRANSACTIONS_QUERY_KEY } from "resources/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";
import { HighbeamTransaction } from "utils/types/transactionsTypes";

type Props = {
  paymentMetadataGuid: string;
  setPaymentMetadataGuid: (paymentMetadataGuid: string) => void;
  transaction: HighbeamTransaction;
  refreshTransactions?: () => void;
};

const TransactionNotes: React.FC<Props> = ({
  paymentMetadataGuid,
  setPaymentMetadataGuid,
  transaction,
  refreshTransactions,
}) => {
  const refreshPaymentMetadataQuery = useRefreshQuery([PAYMENT_METADATA_QUERY_KEY]);
  const refresTransactionsQuery = useRefreshQuery([UNIT_CO_TRANSACTIONS_QUERY_KEY]);

  const [notes, setNotes] = useState<string>("");

  const { data: paymentMetadata, isLoading } = useQuery(
    usePaymentMetadataQueryOptions({ paymentMetadataGuid })
  );

  // Sets the default input value if applicable
  useEffect(() => {
    if (paymentMetadata) {
      setNotes(paymentMetadata.notes ?? "");
    }
  }, [paymentMetadata]);

  const { mutateAsync: updateOrCreatePaymentMetadata, isPending: isSaving } =
    useUpdateOrCreatePaymentMetadataMutation();

  const saveNotes = async () => {
    if (paymentMetadataGuid === "" && notes === "") return;

    const res = await updateOrCreatePaymentMetadata(
      paymentMetadataGuid
        ? {
            paymentMetadataGuid: paymentMetadataGuid,
            notes: notes,
          }
        : {
            notes: notes,
            unitCoDepositAccountId: transaction?.accountId,
            transactionId: transaction?.id,
          }
    );

    setPaymentMetadataGuid(res.guid);

    // NB(alex): We can delete this once the flexpane is standalone. (doesn't use `HighbeamTransaction`)
    if (paymentMetadataGuid === "") {
      refreshTransactions?.();
      refresTransactionsQuery();
      refreshPaymentMetadataQuery();
    }
  };

  return (
    <TransactionFlexpaneNotes
      isLoading={isLoading}
      isSaving={isSaving}
      value={notes}
      onChange={setNotes}
      saveNotes={saveNotes}
    />
  );
};

export default TransactionNotes;
