import ShopifyConnectionRep from "reps/ShopifyConnectionRep";
import ShopifyRedirectUrlRep from "reps/ShopifyRedirectUrlRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ShopifyConnectionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async createRedirectUrl(
    businessGuid: string | null,
    callbackUrl: string,
    storeName: string
  ): Promise<ShopifyRedirectUrlRep.Complete> {
    const url = `/shopify-redirect-urls`;
    const body: ShopifyRedirectUrlRep.Creation = { businessGuid, callbackUrl, storeName };
    return (await this.api.post<ShopifyRedirectUrlRep.Complete>(url, body))!;
  }

  async create(
    accessCode: string,
    businessGuid: string | null,
    hmac: string,
    hmacMessage: string,
    shopHost: string,
    state: string
  ): Promise<ShopifyConnectionRep.Complete> {
    const url = `/shopify-connections`;
    const body: ShopifyConnectionRep.Creation = {
      accessCode,
      businessGuid,
      hmac,
      hmacMessage,
      shopHost,
      state,
    };
    return (await this.api.post<ShopifyConnectionRep.Complete>(url, body))!;
  }

  async getByBusiness(businessGuid: string): Promise<ShopifyConnectionRep.Complete[]> {
    const url = `/businesses/${businessGuid}/shopify-connections`;
    return (await this.api.get<ShopifyConnectionRep.Complete[]>(url))!;
  }

  async disableBannersByBusiness(businessGuid: string): Promise<ShopifyConnectionRep.Complete[]> {
    const url = `/businesses/${businessGuid}/disable-shopify-connection-banners`;
    return (await this.api.post<ShopifyConnectionRep.Complete[]>(url))!;
  }

  async delete(
    businessGuid: string,
    connectionGuid: string
  ): Promise<ShopifyConnectionRep.Complete | null> {
    const url = `/businesses/${businessGuid}/shopify-connections/${connectionGuid}?softDelete=true`;
    return await this.api.delete<ShopifyConnectionRep.Complete>(url);
  }
}
