import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./EmptyCardsList.module.scss";

type Props = {
  className?: string;
} & React.PropsWithChildren;

const EmptyCardsList: React.FC<Props> = ({ children }) => (
  <div className={styles.emptyState}>{children}</div>
);

const EmptyCardsListText: React.FC<React.ComponentProps<typeof Text>> = (props) => (
  <Text size={14} color={colors.grey[600]} {...props} />
);

export default Object.assign(EmptyCardsList, {
  Text: EmptyCardsListText,
});
