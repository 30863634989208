import { ComponentProps, FC } from "react";
import Dropdown from "ui/inputs/Dropdown";
import cn from "utils/tailwind/cn";

type Props = ComponentProps<typeof Dropdown> & { wide?: boolean };

const DropdownFilter: FC<Props> = ({ wide = false, ...dropdownProps }) => {
  return (
    <Dropdown
      label="Show transactions from"
      className={cn("extra-large-desktop:col-span-1", wide && "extra-large-desktop:col-span-2")}
      isSearchable={false}
      {...dropdownProps}
    />
  );
};

export default DropdownFilter;
