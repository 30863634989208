import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import sessionGuidState from "state/compareCreditOffers/sessionGuid";
import snapshotState from "state/compareCreditOffers/snapshot";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

/**
 * Saves the email param to the session if a snapshot doesn't exist.
 *
 * @note this does not check to see if a session already has an email address, so it will be overwritten if a new param is passed in.
 */
const useSaveEmailParamToSession = () => {
  const highbeamApi = useHighbeamApi({ authMode: "maybeAuthenticated" });
  const sessionGuid = useRecoilValue(sessionGuidState);
  const snapshot = useRecoilValue(snapshotState);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const email = searchParams.get("email");
    if (!snapshot && email) {
      highbeamApi.creditComparison.updateCreditComparisonSession(sessionGuid, {
        emailAddress: email,
      });
      searchParams.delete("email");
      navigate({ search: searchParams.toString() });
    }
  }, [sessionGuid, highbeamApi.creditComparison, searchParams, snapshot, navigate]);
};

export default useSaveEmailParamToSession;
