import { PlusCircle } from "@phosphor-icons/react";
import { FC } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";

const CreateBillButton: FC<ButtonProps> = ({ ...buttonProps }) => {
  return (
    <Button variant="secondary" {...buttonProps}>
      <PlusCircle size={20} weight="light" /> Create bill
    </Button>
  );
};

export default CreateBillButton;
