import classNames from "classnames";
import React, { PropsWithChildren, ReactNode } from "react";

import styles from "./SectionHeader.module.scss";

type Props = {
  actions?: ReactNode;
} & PropsWithChildren;

const SectionHeader: React.FC<Props> = ({ children, actions }) => (
  <div className={styles.outer}>
    <div className={styles.inner}>{children}</div>
    {actions && <div className={classNames(styles.inner, styles.actions)}>{actions}</div>}
  </div>
);

export default SectionHeader;
