import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { COUNTERPARTY_REPORT_QUERY_KEY } from "../queries/useCounterpartyReportQueryOptions";

type Variables = {
  counterparty: string;
  direction: "money-in" | "money-out";
  originalCounterparties: string[];
};

const useUpdateCounterpartyAliasMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshCounterpartyReportQuery = useRefreshQuery([COUNTERPARTY_REPORT_QUERY_KEY]);

  return useMutation({
    mutationFn: ({ counterparty, originalCounterparties }: Variables) => {
      return highbeamApi.counterpartyAliasManagement.renameCounterparty({
        businessGuid: businessGuid,
        originalCounterparties: originalCounterparties,
        counterparty: counterparty,
      });
    },
    onError: () => {
      notify("error", "There was an issue updating the name. Please try again or contact support.");
    },
    onSuccess: async (_, { direction }) => {
      await refreshCounterpartyReportQuery();
      notify("success", `${direction === "money-in" ? "Source" : "Vendor"} name updated`);
    },
  });
};

export default useUpdateCounterpartyAliasMutation;
