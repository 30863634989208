import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

const useShouldShowActivateThreadBanner = () => {
  const bankAccounts = bankAccountsQueryHooks.useData({ status: "open" });
  const hasThreadAccount = bankAccounts.some((account) => account.threadAccount);
  const isMigrateToThreadEnabled = useFeatureFlag("MIGRATE_TO_THREAD");

  return Boolean(isMigrateToThreadEnabled && !hasThreadAccount);
};

export default useShouldShowActivateThreadBanner;
