import Flexpane from "ui/overlay/Flexpane";

import PaymentInfoFlexpaneAmountSection from "./components/PaymentInfoFlexpaneAmountSection";
import PaymentInfoFlexpaneDetailsSection from "./components/PaymentInfoFlexpaneDetailsSection";
import PaymentInfoFlexpaneHeaderSection from "./components/PaymentInfoFlexpaneHeaderSection";
import PaymentInfoFlexpaneToFromSection from "./components/PaymentInfoFlexpaneToFromSection";
import PaymentInfoFlexpaneProvider from "./context/PaymentInfoFlexpaneProvider";

type Props = {
  paymentId?: string;
  onClose: () => void;
  onPaymentCanceled?: () => void;
};

const PaymentInfoFlexpane: React.FC<Props> = ({ paymentId, onClose, onPaymentCanceled }) => {
  return (
    <Flexpane isOpen={Boolean(paymentId)} onClose={onClose} disableInitialFocus>
      {paymentId && (
        <PaymentInfoFlexpaneProvider
          onClose={onClose}
          paymentId={paymentId}
          paymentDirection="Outgoing"
          onPaymentCanceled={onPaymentCanceled}
        >
          <PaymentInfoFlexpaneHeaderSection />
          <PaymentInfoFlexpaneToFromSection />
          <PaymentInfoFlexpaneAmountSection />
          <PaymentInfoFlexpaneDetailsSection />
        </PaymentInfoFlexpaneProvider>
      )}
    </Flexpane>
  );
};

export default PaymentInfoFlexpane;
