import { FC } from "react";
import ButtonLink, { ButtonLinkProps } from "ui/inputs/ButtonLink";

const BackButtonLink: FC<ButtonLinkProps> = ({ children = "Back", ...buttonV2Props }) => (
  <ButtonLink variant="tertiary" type="button" {...buttonV2Props}>
    {children}
  </ButtonLink>
);

export default BackButtonLink;
