import { useContext } from "react";
import AccountingCategorySelect from "resources/accounting-accounts/components/AccountingCategorySelect";
import PayeeDefaultChartOfAccountDescription from "resources/payees/components/PayeeDefaultChartOfAccountDescription";
import Fieldset from "ui/inputs/Fieldset";
import TextInput from "ui/inputs/TextInput";

import { CreatePayeeFormContext } from "./CreatePayeeForm";
import style from "./CreatePayeePage.module.scss";

const PayeeInfoSection = () => {
  const form = useContext(CreatePayeeFormContext);
  const { defaultChartOfAccountId } = form.payee;

  return (
    <Fieldset width="narrow">
      <TextInput label="Name" className={style.span2} maxLength={60} {...form.payee.name} />
      <TextInput label="Email (optional)" className={style.span2} {...form.payee.emailAddress} />
      <TextInput
        label="Phone number (optional)"
        className={style.span2}
        {...form.payee.phoneNumber}
      />
      <div className="col-span-2 mt-2 flex flex-col gap-2">
        <AccountingCategorySelect
          value={defaultChartOfAccountId.value}
          onValueChange={defaultChartOfAccountId.setValue}
          labelText="Accounting category (optional)"
          clearable
        />
        <PayeeDefaultChartOfAccountDescription />
      </div>
    </Fieldset>
  );
};

export default PayeeInfoSection;
