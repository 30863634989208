import { useChatWidget } from "components/ChatWidget";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";

import styles from "./CannotDeleteStoreModal.module.scss";

type Props = {
  onClose: () => void;
};

const CannotDeleteStoreModal: React.FC<Props> = ({ onClose }) => {
  const chat = useChatWidget();

  return (
    <Modal
      title={"Cannot disconnect this store"}
      buttonText={"Got it"}
      focusPrimaryButton
      onClose={onClose}
      onClick={onClose}
    >
      <Text size={14}>
        You have an active line of credit connected to this store. If you believe that this is a
        mistake, please{" "}
        <button
          onClick={() => {
            onClose();
            chat.show();
          }}
          className={styles.contactUs}
        >
          contact support
        </button>
        .
      </Text>
    </Modal>
  );
};

export default CannotDeleteStoreModal;
