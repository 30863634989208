import colors from "styles/colors";
import Text from "ui/typography/Text";
import { HighbeamTransaction, isCheckDepositTransaction } from "utils/types/transactionsTypes";

import Counterparty from "../../Counterparty";

type Props = {
  transaction: HighbeamTransaction;
};

const CounterpartyDetail: React.FC<Props> = ({ transaction }) => {
  const dateLabel = getDateLabel(transaction);

  return (
    <>
      <Counterparty transaction={transaction} hideCounterpartyIcon />
      <Text size={14} color={colors.grey[500]}>
        {dateLabel}
      </Text>
    </>
  );
};

function getDateLabel(transaction: HighbeamTransaction): string {
  const positive = transaction.direction === "Credit";
  // CounterpartyDetail appears under "From" (left side)
  if (positive) {
    if (isCheckDepositTransaction(transaction)) {
      return "Initiated " + transaction.formattedCreatedAt;
    }
    return transaction.formattedCreatedAt;
  }

  // CounterpartyDetail appears under "To" (right side)
  if (
    transaction.paymentEstimatedDate &&
    (transaction.paymentStatus === "Pending" || transaction.paymentStatus === "Clearing")
  ) {
    return "Estimated " + transaction.formattedPaymentEstimatedDate;
  }

  return "";
}

export default CounterpartyDetail;
