import CheckDepositFlexpane from "dialogs/CheckDepositFlexpane";
import PaymentInfoFlexpane from "dialogs/PaymentInfoFlexpane";
import ReceivedPaymentInfoFlexpane from "dialogs/ReceivedPaymentInfoFlexpane";
import useIncomingPayments from "modules/incoming-payments/queries/useIncomingPayments";
import { IncomingPayment } from "modules/incoming-payments/types";
import getIncomingPaymentEstDate from "modules/incoming-payments/utils/getIncomingPaymentEstDate";
import { FC, useState } from "react";
import { BankAccountBarByUnitCoDepositAccountId } from "resources/bank-accounts/components/BankAccountBar";
import { getBankAccountsByUnitCoDepositAccountId } from "resources/bank-accounts/queries/bankAccountByUnitCoDepositAccountIdQueryHooks";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import getIncomingPaymentMethodName from "resources/unit-co-received-payments/utils/getPaymentMethodName";
import PageIndicator from "ui/navigation/PageIndicator";
import AmountCell from "ui/table/AmountCell";
import CounterpartyCell from "ui/table/CounterpartyCell";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import TextCell from "ui/table/TextCell";
import { formatBankingDate } from "utils/date";
import { getPaymentCounterparty } from "utils/payments";
import {
  isCheckDepositPayment,
  isReceivedAchPayment,
  isReceivedPayment,
} from "utils/types/incomingPaymentTypes";

import IncomingPaymentStatusPill from "../IncomingPaymentStatusPill";

import styles from "./IncomingPaymentsTable.module.scss";

const PaymentsTableCounterpartyCell = ({ payment }: { payment: IncomingPayment }) => {
  const bankAccountsByUnitCoDepositAccountId = bankAccountsQueryHooks.useData({
    status: "all",
    select: getBankAccountsByUnitCoDepositAccountId,
  });
  const counterparty = getPaymentCounterparty(payment, bankAccountsByUnitCoDepositAccountId);

  return (
    <div className={styles.toFromStatus}>
      <CounterpartyCell>{counterparty.formattedName ?? counterparty.name}</CounterpartyCell>
      <IncomingPaymentStatusPill payment={payment} className={styles.pill} />
    </div>
  );
};

const columns: Column<IncomingPayment>[] = [
  {
    title: "Est. date",
    cellRender: (payment) => {
      const estDate = getIncomingPaymentEstDate(payment);
      if (estDate) {
        return <TextCell>{formatBankingDate(estDate)}</TextCell>;
      } else {
        return <NotFoundCell />;
      }
    },
    width: 120,
  },
  {
    title: "From",
    cellRender: (payment) => {
      return <PaymentsTableCounterpartyCell payment={payment} />;
    },
  },
  {
    title: "Account",
    key: "accountName",
    cellRender: (payment) => {
      return (
        <BankAccountBarByUnitCoDepositAccountId
          unitCoDepositAccountId={payment.relationships.account.data.id}
          shortMethodName={getIncomingPaymentMethodName(payment).shortMethodName}
        />
      );
    },
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: (payment) => {
      if (isCheckDepositPayment(payment)) {
        return <AmountCell cents={payment.attributes.amount} direction="positive" />;
      } else if (isReceivedAchPayment(payment)) {
        return (
          <AmountCell
            cents={payment.attributes.amount}
            direction={payment.attributes.direction === "Debit" ? "negative" : "positive"}
          />
        );
      } else {
        return (
          <AmountCell
            cents={payment.attributes.amount}
            direction={payment.attributes.direction === "Debit" ? "positive" : "negative"}
          />
        );
      }
    },
  },
];

type Props = {
  accountId?: string;
};

const PAGE_SIZE = 5;

const IncomingPaymentsTable: FC<Props> = ({ accountId }) => {
  const incomingPayments = useIncomingPayments({ accountId });
  const [selectedPayment, setSelectedPayment] = useState<IncomingPayment>();

  const totalPages = Math.ceil(incomingPayments.length / PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);

  const paginatedIncomingPayments = incomingPayments.slice(
    currentPage * PAGE_SIZE,
    (currentPage + 1) * PAGE_SIZE
  );

  return (
    <>
      <CheckDepositFlexpane
        onClose={() => setSelectedPayment(undefined)}
        checkDepositId={
          selectedPayment && isCheckDepositPayment(selectedPayment) ? selectedPayment.id : undefined
        }
      />
      <ReceivedPaymentInfoFlexpane
        receivedPaymentId={
          selectedPayment && isReceivedAchPayment(selectedPayment) ? selectedPayment.id : undefined
        }
        onClose={() => setSelectedPayment(undefined)}
      />
      <PaymentInfoFlexpane
        paymentId={
          selectedPayment && isReceivedPayment(selectedPayment) ? selectedPayment.id : undefined
        }
        onClose={() => setSelectedPayment(undefined)}
      />

      <Table
        columns={columns}
        data={paginatedIncomingPayments}
        rowKey={(payment) => payment.id}
        cellClassName={styles.cell}
        onRowClick={(payment) => {
          setSelectedPayment(payment);
        }}
      />

      {totalPages > 1 && (
        <PageIndicator
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          className="mt-8"
        />
      )}
    </>
  );
};

export default IncomingPaymentsTable;
