import BillEmailRep from "reps/BillEmailRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillEmailApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(billEmailId: string): Promise<BillEmailRep.Complete | null> {
    const url = `/accounts-payable/bill-emails/${billEmailId}`;
    return await this.api.get<BillEmailRep.Complete>(url);
  }
}
