import { CalendarCheck } from "@phosphor-icons/react";
import { FC } from "react";
import ChargeCardOfferRep from "reps/ChargeCardOfferRep";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./ChargeCardExampleStatementPeriod.module.scss";

const ExtendTermsDueDateTimeline = () => (
  <div className={styles.timelineContainer}>
    <div className={styles.timelineItem}>
      <div className={styles.timelineBarGradient} />
      <Text as="p" size={12} className={styles.timePeriod} color={colors.purple[600]}>
        Apr 1 - Apr 30
      </Text>
    </div>
    <div className={styles.timelineItem}>
      <div className={styles.timelineBar} />
      <Text as="p" size={12}>
        May
      </Text>
      <div className={styles.dueIndicatorExtendTerm}>
        <CalendarCheck size={14} weight="bold" />
        <Text as="p" size={12} weight="bold">
          Due May 30
        </Text>
      </div>
    </div>
    <div className={styles.timelineItem}>
      <div className={styles.timelineBar} />
      <Text as="p" size={12}>
        Jun
      </Text>
    </div>
  </div>
);

const FlexTermsDueDateTimeline = () => (
  <div className={styles.timelineContainer}>
    <div className={styles.timelineItem}>
      <div className={styles.timelineBarGradient} />
      <Text as="p" size={12} className={styles.timePeriod} color={colors.purple[600]}>
        Apr 1 - Apr 30
      </Text>
    </div>
    <div className={styles.timelineItem}>
      <div className={styles.timelineBar} />
      <Text as="p" size={12}>
        May
      </Text>
      <div className={styles.dueIndicatorFlexTerm}>
        <CalendarCheck size={14} weight="bold" />
        <Text as="p" size={12} weight="bold">
          Due May 1
        </Text>
      </div>
    </div>
  </div>
);

const CashTermsDueDateTimeline = () => (
  <div className={styles.timelineContainer}>
    <div className={styles.timelineItem}>
      <div className={styles.timelineBarGradient} />
      <Text as="p" size={12} className={styles.timePeriod} color={colors.purple[600]}>
        Today
      </Text>
    </div>
    <div className={styles.timelineItem}>
      <div className={styles.timelineBar} />
      <Text as="p" size={12}>
        Tomorrow
      </Text>
      <div className={styles.dueIndicatorCashTerm}>
        <CalendarCheck size={14} weight="bold" />
        <Text as="p" size={12} weight="bold">
          Due 10am ET
        </Text>
      </div>
    </div>
  </div>
);

type ChargeCardExampleStatementPeriodProps = {
  chargeCardCreditTerm: ChargeCardOfferRep.ChargeCardCreditTerm;
};

const ChargeCardExampleStatementPeriod: FC<ChargeCardExampleStatementPeriodProps> = ({
  chargeCardCreditTerm,
}) => {
  switch (chargeCardCreditTerm) {
    case ChargeCardOfferRep.ChargeCardCreditTerm.Production:
    case ChargeCardOfferRep.ChargeCardCreditTerm.Sandbox:
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionFlex:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxFlex:
      return <FlexTermsDueDateTimeline />;
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionExtend:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxExtend:
      return <ExtendTermsDueDateTimeline />;
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionCash:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxCash:
      return <CashTermsDueDateTimeline />;
  }
};

export default ChargeCardExampleStatementPeriod;
