import { FC, ReactNode } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./ActionItemBanner.module.scss";

type Props = {
  icon: ReactNode;
  iconContainerBackgroundColor?: string;
  text: ReactNode;
  subtext: ReactNode;
  actionButton: ReactNode;
};

const ActionItemBanner: FC<Props> = ({
  icon,
  iconContainerBackgroundColor = colors.purple[100],
  text,
  subtext,
  actionButton,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.iconContainer}
        style={{ backgroundColor: iconContainerBackgroundColor }}
      >
        {icon}
      </div>

      <div className={styles.textContainer}>
        <Text size={16} weight="bold" color={colors.grey[800]}>
          {text}
        </Text>
        <Text size={16} weight="regular" color={colors.grey[600]}>
          {subtext}
        </Text>
      </div>

      <div>{actionButton}</div>
    </div>
  );
};

export default ActionItemBanner;
