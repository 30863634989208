import { Trash, Pencil, ArrowLineUpRight } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import { useState } from "react";
import { useParams } from "react-router-dom";
import usePayee from "resources/payees/queries/usePayee";
import usePayeeRecurringPayments from "resources/payees/queries/usePayeeRecurringPayments";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import ButtonLink from "ui/inputs/ButtonLink";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useIsAllowedToDeletePayees from "utils/permissions/useIsAllowedToDeletePayees";
import useIsAllowedToEditPayees from "utils/permissions/useIsAllowedToEditPayees";
import useIsAllowedToViewSendMoney from "utils/permissions/useIsAllowedToViewSendMoney";

import RemovePayeeModal from "./RemovePayeeModal";

const PayeeDetailsHeader = () => {
  const { payeeGuid } = useParams();
  const payee = usePayee(payeeGuid!, { required: true });

  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isAllowedToViewSendMoney = useIsAllowedToViewSendMoney();
  const isAllowedToEditPayees = useIsAllowedToEditPayees();
  const isAllowedToDeletePayees = useIsAllowedToDeletePayees();

  const payeeRecurringPayments = usePayeeRecurringPayments({ payeeGuid: payee.guid });
  const hasPendingScheduledPaymentsForPayee = payeeRecurringPayments.length > 0;
  const [removePayeeModalIsOpen, setRemovePayeeModalIsOpen] = useState(false);

  return (
    <DashboardPage.Header
      actions={
        <>
          {removePayeeModalIsOpen && (
            <RemovePayeeModal payee={payee} onClose={() => setRemovePayeeModalIsOpen(false)} />
          )}
          {isAllowedToViewSendMoney && (
            <ButtonLink variant="secondary" to="/send-money" state={{ payeeGuid: payee.guid }}>
              <ArrowLineUpRight />
              {isAllowedToApprovePayments ? "Send money" : "Draft a payment"}
            </ButtonLink>
          )}

          <ButtonLinkWithTooltip
            tooltip={!isAllowedToEditPayees && "You don’t have permission to edit payees."}
            to={`/payments/payees/${payee.guid}/edit`}
            state={{ payeeGuid: payee.guid }}
            variant="tertiary"
            disabled={!isAllowedToEditPayees}
          >
            <Pencil />
            Edit
          </ButtonLinkWithTooltip>

          <ButtonWithTooltip
            disabled={!isAllowedToDeletePayees || hasPendingScheduledPaymentsForPayee}
            variant="danger"
            onClick={() => setRemovePayeeModalIsOpen(true)}
            tooltip={
              (!isAllowedToDeletePayees && "You don’t have permission to delete payees.") ||
              (hasPendingScheduledPaymentsForPayee &&
                "You can't delete a payee with pending scheduled payments.")
            }
          >
            <Trash />
            Remove
          </ButtonWithTooltip>
        </>
      }
    >
      <DashboardPage.Header.BackCaret to="/payments/payees" />
      <DashboardPage.Header.Title>{payee.name}</DashboardPage.Header.Title>
    </DashboardPage.Header>
  );
};

export default PayeeDetailsHeader;
