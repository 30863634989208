import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import BankAccountBar from "resources/bank-accounts/components/BankAccountBar";
import useCapitalAccountAutoPayBankAccount from "resources/capital-accounts/queries/useCapitalAccountAutoPayBankAccount";
import HighlightItem, {
  HighlightItemSideRuleColor,
  HighlightItemSize,
} from "ui/data-display/HighlightItem";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  size?: HighlightItemSize;
  sideRuleColor?: HighlightItemSideRuleColor;
};

const CapitalAccountAutoPayBankAccountHighlightItem: FC<Props> = ({
  capitalAccountSummary,
  size,
  sideRuleColor,
}) => {
  const capitalAccountAutoPayBankAccount =
    useCapitalAccountAutoPayBankAccount(capitalAccountSummary);

  return (
    <HighlightItem
      size={size}
      sideRuleColor={sideRuleColor}
      label="Auto-pay account"
      value={<BankAccountBar bankAccount={capitalAccountAutoPayBankAccount} />}
      subtext={
        <>
          Balance: <MoneyAmount cents={capitalAccountAutoPayBankAccount.availableBalance} />
        </>
      }
    />
  );
};

export default CapitalAccountAutoPayBankAccountHighlightItem;
