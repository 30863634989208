import ChatResponseLineChart from "pages/accounts/ChatPage/ChatConversationSection/ChatResponseLineChart";
import React from "react";
import ChatMessageResponse from "reps/chat/ChatMessageResponse";
import cn from "utils/tailwind/cn";

import ChatResponseMarkdown from "./ChatResponseMarkdown";
import ChatResponseTable from "./ChatResponseTable";

type Props = {
  className?: string;
  response: ChatMessageResponse;
};

const ChatResponse: React.FC<Props> = ({ className, response }) => {
  return (
    <div className={cn("flex flex-col gap-4", className)}>
      {response.content.map((section, i) => {
        switch (section.type) {
          case "line_chart":
            return <ChatResponseLineChart key={i} section={section} />;
          case "markdown":
            return <ChatResponseMarkdown key={i} section={section} />;
          case "table":
            return <ChatResponseTable key={i} section={section} />;
          default:
            return null;
        }
      })}
    </div>
  );
};

export default ChatResponse;
