import {
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryKey,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback } from "react";
import { Simplify } from "type-fest";

export type UseRefreshQueryOptions = Simplify<
  InvalidateOptions & Omit<InvalidateQueryFilters, "queryKey">
>;

const useRefreshQuery = <TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  options: UseRefreshQueryOptions = {}
) => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    // We pass in all options together but split them into the groups. See the type definition for `invalidateQueries` below for more information.
    const { cancelRefetch, throwOnError, ...filter } = options;

    return queryClient.invalidateQueries({ queryKey, ...filter }, { cancelRefetch, throwOnError });
  }, [queryKey, options, queryClient]);
};

export default useRefreshQuery;
