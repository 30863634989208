import useUserGuid from "resources/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useSignupSourceQueryOptions = () => {
  const userGuid = useUserGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: ["signupSource", { userGuid }],
    queryFn: async () => {
      const lead = await highbeamApi.lead.getByUser(userGuid);
      return lead?.source ?? null;
    },
  });
};

export default useSignupSourceQueryOptions;
