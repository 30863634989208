import { FC } from "react";
import { Heading2 } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

const Title: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <Heading2 className={cn("pb-6", className)}>{children}</Heading2>;
};

export default Title;
