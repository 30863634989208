import classNames from "classnames";
import MultiStep from "layouts/MultiStep";
import { FC, useRef, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import BarChart from "ui/data-visualization/BarChart";
import CurrencyInput from "ui/inputs/CurrencyInputV2";
import Helper from "ui/inputs/Helper";
import RadioCardSelect from "ui/inputs/RadioCardSelect";
import RadioCardSimple from "ui/inputs/RadioCardSimple";
import Text from "ui/typography/Text";
import { formatAmount } from "utils/string";

import RepaymentBankAccountDropdown from "../components/RepaymentBankAccountDropdown";
import useRepayLineOfCreditForm, {
  useRepayLineOfCreditFormContext,
} from "../hooks/useRepayLineOfCreditForm";

const repaymentOptions = [1, 0.5, 0.25, "custom"] as const;

type RepaymentOption = (typeof repaymentOptions)[number];

const REPAY_LINE_OF_CREDIT_FORM_ID = "repay-line-of-credit-form";

type RepayLineOfCreditAmountFormProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const RepayLineOfCreditAmountForm: FC<RepayLineOfCreditAmountFormProps> = ({
  capitalAccountSummary,
}) => {
  const runningBalance = capitalAccountSummary.runningBalance;
  const limit = capitalAccountSummary.details.limit;

  const amountOwed = 0 - runningBalance;

  const { control, handleSubmit, watch } = useRepayLineOfCreditFormContext();
  const amountInputValue = watch("amount");
  const selectedRepaymentBankAccount = watch("repaymentBankAccount");

  const repaymentBankAccountAvailableBalance = selectedRepaymentBankAccount.availableBalance;
  const repaymentBankAccountBalanceAfterRepayment =
    repaymentBankAccountAvailableBalance - amountInputValue;
  const insufficientFunds = repaymentBankAccountBalanceAfterRepayment < 0;

  const isOverpaying = amountInputValue > amountOwed;

  // TODO(alex): be sure to set default to updated value if they go back
  const defaultRepaymentSelectValue = repaymentOptions[0];
  const [repaymentOptionValue, setRepaymentOptionValue] = useState<RepaymentOption>(
    defaultRepaymentSelectValue
  );

  const navigate = useNavigate();

  const amountInputRef = useRef<HTMLInputElement>(null);

  const onSubmit = handleSubmit((data) => {
    navigate(`/capital/${capitalAccountSummary.guid}/credit/repay/confirm`, {
      state: {
        amount: data.amount,
        repaymentBankAccountGuid: data.repaymentBankAccount.guid,
      },
    });
  });

  const availableAfterRepayment =
    capitalAccountSummary.available + Math.min(amountOwed, amountInputValue);
  const availableAfterRepaymentPercentage = (availableAfterRepayment / limit) * 100;

  return (
    <MultiStep.Form id={REPAY_LINE_OF_CREDIT_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            How much do you want to repay?
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <Controller
          control={control}
          name="amount"
          render={({ field }) => {
            return (
              <RadioCardSelect
                options={repaymentOptions}
                value={repaymentOptionValue}
                onChange={setRepaymentOptionValue}
                rowGap={8}
              >
                {({ option, isSelected, onSelect }) => (
                  <RadioCardSimple
                    key={option}
                    value={option}
                    checked={isSelected}
                    onChange={(value) => {
                      onSelect(value);

                      if (value !== "custom") {
                        field.onChange(amountOwed * value);
                      } else {
                        // Focus & select the amount input when the "custom" option is selected
                        // NB(alex): I wasn't able to figure out how to unset the field so I opted to select & highlight the field instead
                        amountInputRef.current?.focus();
                        setTimeout(() => amountInputRef.current?.select(), 0);
                      }
                    }}
                    label={
                      typeof option === "number"
                        ? formatAmount((amountOwed * option) / 100, { withCents: true })
                        : "Custom amount"
                    }
                    helper={typeof option === "number" ? `${option * 100}%` : undefined}
                  />
                )}
              </RadioCardSelect>
            );
          }}
        />

        <Controller
          control={control}
          name="amount"
          render={({ field }) => {
            return (
              <CurrencyInput
                inputRef={amountInputRef}
                prefixValue="$"
                label="Amount"
                className={classNames(repaymentOptionValue !== "custom" && "hidden", "mt-2")}
                {...field}
              />
            );
          }}
        />

        {insufficientFunds && (
          <Helper iconVariant="error">
            The amount you entered is greater than your account balance of{" "}
            {formatAmount(selectedRepaymentBankAccount.availableBalance, {
              withCents: true,
            })}
            .
          </Helper>
        )}

        {isOverpaying && (
          <Helper iconVariant="error">
            The amount you entered is greater than the amount you owe.
          </Helper>
        )}
      </MultiStep.Section>

      <MultiStep.Section className="mt-8">
        <RepaymentBankAccountDropdown control={control} />
      </MultiStep.Section>

      <Divider className="my-8" />

      <MultiStep.Section>
        <Text color={colors.grey[900]} size={14} weight="medium">
          Capital available after repayment
        </Text>

        <BarChart height={8} className="my-3">
          <BarChart.Bar color="green" widthPercentage={availableAfterRepaymentPercentage} />
        </BarChart>

        <MoneyAmountFraction
          numeratorInCents={availableAfterRepayment}
          denominatorInCents={limit}
        />
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.NextButton
          form={REPAY_LINE_OF_CREDIT_FORM_ID}
          disabled={!amountInputValue || insufficientFunds || isOverpaying}
        >
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const RepayLineOfCreditAmountView: FC<Props> = ({ capitalAccountSummary }) => {
  const openBankAccounts = bankAccountsQueryHooks.useData({ status: "open" });

  const defaultRepaymentBankAccount =
    openBankAccounts.find(
      (bankAccount) => bankAccount.guid === capitalAccountSummary.details.repayment.bankAccountGuid
    ) ?? openBankAccounts[0];

  const form = useRepayLineOfCreditForm({
    defaultValues: {
      amount: 0 - capitalAccountSummary.runningBalance,
      repaymentBankAccount: defaultRepaymentBankAccount,
    },
  });

  return (
    <FormProvider {...form}>
      <RepayLineOfCreditAmountForm capitalAccountSummary={capitalAccountSummary} />
    </FormProvider>
  );
};

export default RepayLineOfCreditAmountView;
