import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const CREATE_CREDIT_APPLICATIONS_QUERY_KEY = "credit-applications";

const useCreditApplicationsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [CREATE_CREDIT_APPLICATIONS_QUERY_KEY, businessGuid],
    queryFn: async () => {
      const creditApplications = await highbeamApi.creditApplication.getByBusiness(businessGuid);

      if (!creditApplications) {
        return [];
      }

      return creditApplications;
    },
  });
};

export default useCreditApplicationsQueryOptions;
