import OnboardingLayout from "layouts/OnboardingLayout";
import OnboardingStartBankApplicationCard from "modules/onboarding/components/OnboardingStartBankApplicationCard";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import BankApplicationRep from "reps/BankApplicationRep";
import { onboardingProgressState } from "state/onboarding/progress";

type Props = {
  bankApplication: BankApplicationRep.Complete;
};

type LocationState = {
  fromConnectStore: boolean;
};

const BankApplicationCreated: FC<Props> = ({ bankApplication }) => {
  const locationState = useLocation().state as LocationState | undefined;
  const setProgress = useSetRecoilState(onboardingProgressState);

  useEffect(() => {
    setProgress(30);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardingLayout>
      <OnboardingStartBankApplicationCard
        bankApplication={bankApplication}
        fromConnectStore={Boolean(locationState?.fromConnectStore)}
      />
    </OnboardingLayout>
  );
};

export default BankApplicationCreated;
