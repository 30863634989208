import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  counterpartyName: z.string().nonempty("Please enter a name."),
});

export type UpdateCounterpartyAliasFormInputs = z.infer<typeof schema>;

const useUpdateCounterpartyAliasForm = () => {
  return useForm<UpdateCounterpartyAliasFormInputs>();
};

export default useUpdateCounterpartyAliasForm;
