import { CounterpartyVendorKey } from "./counterpartyVendorNames";

/* eslint sort-keys: ["error", "asc", { "natural": true }] */

const counterpartyVendorLogos = {
  adobe: "/assets/merchants/adobe.svg",
  affirm: "/assets/merchants/affirm.svg",
  amazon: "/assets/merchants/amazon.svg",
  amazonPayout: "/assets/merchants/amazon.svg",
  americanExpress: "/assets/merchants/american-express.svg",
  americanExpressPayment: "/assets/merchants/american-express.svg",
  easypost: "/assets/merchants/easypost.svg",
  facebook: "/assets/merchants/facebook.svg",
  google: "/assets/merchants/google.svg",
  klaviyo: "/assets/merchants/klaviyo.svg",
  microsoft: "/assets/merchants/microsoft.svg",
  paypal: "/assets/merchants/paypal.svg",
  paypalPayout: "/assets/merchants/paypal.svg",
  shopify: "/assets/merchants/shopify.svg",
  shopifyCapital: "/assets/merchants/shopify.svg",
  shopifyPayout: "/assets/merchants/shopify.svg",
  stripe: "/assets/merchants/stripe.svg",
  tiktok: "/assets/merchants/tiktok.svg",
  typeform: "/assets/merchants/typeform.svg",
  uber: "/assets/merchants/uber.svg",
  walmart: "/assets/merchants/walmart.svg",
  wayflyer: "/assets/merchants/wayflyer.svg",
  wholeFoods: "/assets/merchants/whole-foods.svg",
} satisfies { [key in CounterpartyVendorKey]: string };

export default counterpartyVendorLogos;
