import { Warning } from "@phosphor-icons/react";
import env from "env";
import { FC } from "react";
import { Heading3, Link, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
  itemName: "referral" | "invitation";
};

const InvalidUserInvitation: FC<Props> = ({ className, itemName: variant }) => (
  <div className={cn("flex w-full max-w-md flex-col items-center gap-4 text-center", className)}>
    <Warning weight="fill" className="size-10 text-red-600" />

    <div className="flex flex-col gap-y-1">
      <Heading3 className="text-md text-grey-600">We couldn’t find that {variant}.</Heading3>
      <Paragraph className="text-md">Please contact the person who sent it to you.</Paragraph>
      <Paragraph className="text-md">
        If you think this is a mistake or if the problem persists, contact{" "}
        <Link
          href={`mailto:${env.HIGHBEAM_SUPPORT_EMAIL_ADDRESS}?subject=Invalid+user+invitation`}
          rel="noreferrer"
        >
          {env.HIGHBEAM_SUPPORT_EMAIL_ADDRESS}
        </Link>
        .
      </Paragraph>
    </div>
  </div>
);

export default InvalidUserInvitation;
