import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import currentStepIndexState from "state/capital/creditApplication/currentStepIndex";

/**
 * Helper hook to get the current step index with the current pathname
 */
const useCurrentStepIndex = () => {
  const { pathname } = useLocation();
  const currentStepIndex = useRecoilValue(currentStepIndexState(pathname));
  return currentStepIndex;
};

export default useCurrentStepIndex;
