import { Card } from "@highbeam/unit-node-sdk";
import successIcon from "assets/success.svg";
import HighbeamCard from "components/HighbeamCard";
import { FC } from "react";
import ModalV4 from "ui/overlay/ModalV4";
import { Heading3, Paragraph } from "ui/typography";

type SuccessProps = {
  card: Card;
};

const Success: FC<SuccessProps> = ({ card }) => {
  return (
    <ModalV4.Form
      onSubmit={(_e, { closeModalWithAnimation }) => {
        closeModalWithAnimation();
      }}
    >
      <ModalV4.Header icon={<img alt="Success" src={successIcon} className="size-5" />}>
        Card activated
      </ModalV4.Header>

      <ModalV4.Body>
        <Heading3 className="mb-1">You’re all set!</Heading3>
        <Paragraph className="mb-6 text-sm">
          You can now use this card for physical and online purchases.
        </Paragraph>
        <HighbeamCard card={card} clickable={false} />
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton>Close</ModalV4.SubmitButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

export default Success;
