import { FEATURE_VALUES } from "flags";
import { atom } from "recoil";

const featureValuesState = atom<Record<string, string>>({
  key: "featureValues",
  default: Object.values(FEATURE_VALUES).reduce(
    (flagObj, flag) => ({ ...flagObj, [flag]: "" }),
    {}
  ),
});

export default featureValuesState;
