import classNames from "classnames";
import React, { useState, ForwardRefRenderFunction, forwardRef } from "react";

import InputWrapper, { shouldShowLabelAsFocused, CommonInputProps } from "../InputWrapper";

import styles from "./TextArea.module.scss";

export enum Filter {
  DIGITS,
  ALPHABETS,
}

export type TextAreaProps = {
  placeholder?: string;
  icon?: React.ReactElement;
  onIconClick?: () => void;
  onClick?: () => void;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  value?: string;
  maxLength?: number;
  inputFilter?: Filter;
  autoFocus?: boolean;
  textArea?: boolean;
} & CommonInputProps;

const TextArea: ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaProps> = (
  {
    id,
    label,
    placeholder,
    icon,
    onIconClick,
    onClick,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    value,
    prefixValue,
    append,
    hasError,
    errorMessage,
    maxLength,
    inputFilter,
    disabled,
    className,
    autoFocus = false,
    textArea = true,
  },
  ref
) => {
  const [focusOutline, setFocusOutline] = useState(false);
  const showLabelAsFocused = shouldShowLabelAsFocused(label, value, placeholder, focusOutline);
  return (
    <InputWrapper
      id={id}
      disabled={disabled}
      focusOutline={focusOutline}
      showLabelAsFocused={showLabelAsFocused}
      className={classNames(
        className,
        styles.container,
        showLabelAsFocused && styles["container--focused"],
        label && styles["container--labelled"]
      )}
      label={label}
      icon={icon}
      onIconClick={onIconClick}
      onClick={onClick}
      hasValue={Boolean(value)}
      prefixValue={prefixValue}
      append={append}
      hasError={hasError}
      errorMessage={errorMessage}
      textArea={textArea}
    >
      <textarea
        ref={ref}
        id={id}
        placeholder={placeholder}
        onClick={onClick}
        onFocus={() => {
          setFocusOutline(true);
          onFocus?.();
        }}
        onBlur={() => {
          setFocusOutline(false);
          onBlur?.();
        }}
        onKeyDown={onKeyDown}
        disabled={disabled}
        onChange={(e) => {
          const getFilteredValue = (val: string) => {
            switch (inputFilter) {
              case Filter.ALPHABETS:
                return val.replace(/[^a-z]/gi, "");
              case Filter.DIGITS:
                return val.replace(/[^0-9]/gi, "");
              default:
                return val;
            }
          };

          const filteredValue = getFilteredValue(e.currentTarget.value);

          if (!maxLength) {
            return onChange?.(filteredValue);
          }
          return onChange?.(filteredValue.slice(0, maxLength));
        }}
        value={value}
        autoFocus={autoFocus}
      />
    </InputWrapper>
  );
};

TextArea.displayName = "TextArea";

export default forwardRef(TextArea);
