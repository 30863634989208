import { Plus, X } from "@phosphor-icons/react";
import { useOpenAddToHomeScreenInstructionsSheet } from "modules/add-to-home-screen/AddToHomeScreenInstructionsSheet/state/addToHomeScreenInstructionsSheetState";
import { useSetRecoilState } from "recoil";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./AddToHomeScreenBanner.module.scss";
import highbeamAppIcon from "./assets/highbeam-app-icon.png";
import hasAddToHomeScreenBannerBeenDismissedState from "./state/hasAddToHomeScreenBannerBeenDismissedState";

const AddToHomeScreenBanner = () => {
  const openAddToHomeScreenInstructionsSheet = useOpenAddToHomeScreenInstructionsSheet();
  const setHasAddToHomeScreenBannerBeenDismissedState = useSetRecoilState(
    hasAddToHomeScreenBannerBeenDismissedState
  );

  return (
    <div
      className={styles.container}
      onClick={() => {
        openAddToHomeScreenInstructionsSheet();
      }}
    >
      <div className={styles.appIconContainer}>
        <img src={highbeamAppIcon} alt="Highbeam app icon" width={36} height={36} />

        <div className={styles.plusIconContainer}>
          <Plus color={colors.purple[400]} size={12} />
        </div>
      </div>

      <Text color={colors.purple[500]} size={14} weight="bold" className={styles.text}>
        Add Highbeam to home screen
      </Text>

      <X
        onClick={(e) => {
          e.stopPropagation(); // Prevents wrapping onClickBanner from being called
          setHasAddToHomeScreenBannerBeenDismissedState(true);
        }}
        size={24}
        className={styles.x}
        color={colors.grey[800]}
        weight="thin"
      />
    </div>
  );
};

export default AddToHomeScreenBanner;
