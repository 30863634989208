import IconWithTooltip from "components/common/IconWithTooltip";
import { FC } from "react";
import chargeCardProgramQueryHooks from "resources/capital-account-charge-card-program/queries/chargeCardProgramQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import ChargeCardOfferRepaymentPeriodContent from "resources/charge-cards/components/ChargeCardOfferRepaymentPeriodContent";
import StatementCycleExplanation from "resources/charge-cards/components/StatementCycleExplanation";
import Divider from "ui/data-display/Divider";
import OfferDetails from "ui/data-display/OfferDetails";

import ChargeCardCashBackInfoIconWithTooltip from "../ChargeCardCashBackInfoIconWithTooltip";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const CapitalAccountCardChargeCardOfferDetailsContent: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const chargeCardProgram = chargeCardProgramQueryHooks.useDataRequired({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
    state: capitalAccountSummaryWithChargeCard.state,
  });

  return (
    <>
      {chargeCardProgram.maxCashback > 0 && (
        <>
          <OfferDetails.Item
            label={
              <>
                Cash back
                <ChargeCardCashBackInfoIconWithTooltip chargeCardProgram={chargeCardProgram} />
              </>
            }
            value={`Up to ${chargeCardProgram.maxCashback}%`}
          />

          <Divider orientation="vertical" />
        </>
      )}

      <OfferDetails.Item
        label={
          <>
            Repayment period
            <IconWithTooltip
              color="dark"
              tooltip={<StatementCycleExplanation creditTerms={chargeCardProgram.creditTerms} />}
            />
          </>
        }
        value={
          <ChargeCardOfferRepaymentPeriodContent
            capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
          />
        }
      />
    </>
  );
};

export default CapitalAccountCardChargeCardOfferDetailsContent;
