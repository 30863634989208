import { Authorization } from "@highbeam/unit-node-sdk";
import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import TransactionFlexpaneStatus from "components/common/transaction-flexpane/TransactionFlexpaneStatus";
import { FC } from "react";
import { CardAvatarBarByCardId } from "resources/cards/components/CardAvatarBar";
import { Span } from "ui/typography";
import variants from "utils/ts/variants";

type Props = {
  authorization: Authorization;
};

const CardAuthorizationFlexpaneParties: FC<Props> = ({ authorization }) => {
  return (
    <TransactionFlexpaneParties
      from={
        <CardAvatarBarByCardId
          cardId={authorization.relationships.card.data.id}
          showTypeLabel={false}
          showIcon={false}
        />
      }
      status={
        <TransactionFlexpaneStatus
          pillColor={variants(authorization.attributes.status, {
            Authorized: "grey-light" as const,
            Completed: "green-light" as const,
            Canceled: "grey-light" as const,
            Declined: "red-legacy" as const,
          })}
          status={variants(authorization.attributes.status, {
            Authorized: "Pending" as const,
            Completed: "Completed" as const,
            Canceled: "Canceled" as const,
            Declined: "Declined" as const,
          })}
          methodName="Card transaction"
        />
      }
      to={<Span className="text-sm font-medium">{authorization.attributes.merchant.name}</Span>}
    />
  );
};

export default CardAuthorizationFlexpaneParties;
