import { ArrowFatLinesDown, ArrowFatLinesUp } from "@phosphor-icons/react";
import { capitalize } from "lodash-es";
import React from "react";
import CapitalTransactionRep from "reps/CapitalTransactionRep";
import getCapitalTransactionType from "resources/capital-account-transactions/utils/getCapitalTransactionType";
import colors from "styles/colors";
import TextCell from "ui/table/TextCell";

type Props = {
  capitalTransaction: CapitalTransactionRep.Complete;
};

const CapitalAccountTransactionTransactionTypeCell: React.FC<Props> = ({ capitalTransaction }) => {
  const transactionType = getCapitalTransactionType(capitalTransaction);

  return (
    <TextCell>
      {transactionType === "repayment" ? (
        <ArrowFatLinesUp size={14} color={colors.purple[500]} />
      ) : (
        <ArrowFatLinesDown size={14} color={colors.purple[500]} />
      )}
      {capitalize(transactionType)}
    </TextCell>
  );
};

export default CapitalAccountTransactionTransactionTypeCell;
