import isGuidNotFoundError from "modules/error/utils/isGuidNotFoundError";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import { ROOT_CAPITAL_ACCOUNTS_QUERY_KEY } from "./capitalAccountsQueryHooks";

type Params = {
  capitalAccountGuid: string | null;
};

const capitalAccountSummaryQueryHooks = makeQueryHooks({
  rootName: ROOT_CAPITAL_ACCOUNTS_QUERY_KEY,
  name: "capitalAccountSummary",
  useQueryVariables: ({ capitalAccountGuid }: Params) => {
    const businessGuid = useBusinessGuid();
    return { capitalAccountGuid, businessGuid };
  },
  useQueryFnMaker: ({ capitalAccountGuid, businessGuid }) => {
    const highbeamApi = useHighbeamApi();

    return async () => {
      if (!capitalAccountGuid) {
        return null;
      }
      try {
        return await highbeamApi.capitalAccount.getSummary(businessGuid, capitalAccountGuid);
      } catch (error) {
        if (isGuidNotFoundError(error)) {
          return null;
        }
        throw error;
      }
    };
  },
});

export default capitalAccountSummaryQueryHooks;
