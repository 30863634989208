import Text from "ui/typography/Text";

const formatPaginatedTransactionsRange = (offset: number, count: number, totalCount: number) =>
  `Showing ${offset + 1}-${offset + count} of ${totalCount} transactions`;

const formatTransactionsRange = (count: number, totalCount: number) =>
  `Showing ${count} of ${totalCount} transactions`;

type Props = {
  offset?: number;
  count: number;
  totalCount: number;
};

const PaginationInfo: React.FC<Props> = ({ offset, count, totalCount }) => (
  <Text size={14}>
    {offset
      ? formatPaginatedTransactionsRange(offset, count, totalCount)
      : formatTransactionsRange(count, totalCount)}
  </Text>
);

export default PaginationInfo;
