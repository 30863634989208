import classNames from "classnames";
import { SetRequired } from "type-fest";

import styles from "./MultiStepForm.module.scss";

type Props = SetRequired<JSX.IntrinsicElements["form"], "id" | "onSubmit">;

const MultiStepForm: React.FC<Props> = ({ className, ...formProps }) => (
  <form className={classNames(className, styles.container)} {...formProps} />
);

export default MultiStepForm;
