import { FC } from "react";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

type TeaserColor = "purple";

type Props = PropsWithChildrenAndClassName & {
  color: TeaserColor;
};

const TeaserV2: FC<Props> = ({ children, className, color }) => {
  return (
    <div
      className={cn(
        "rounded-2xl border p-6 tablet:p-12",
        variants(color, {
          purple: "border-purple-100 bg-purple-50",
        }),
        className
      )}
    >
      {children}
    </div>
  );
};

export default TeaserV2;
