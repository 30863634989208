import {
  getValidator,
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import TextInput, { useTextInput } from "ui/inputs/TextInput";

type Props = {
  selectedCountry: string;
};

const AccountNumber: React.FC<Props> = ({ selectedCountry }) => {
  const [internationalBankingInfo, setInternationalBankingInfo] = useRecoilState(
    internationalBankingInfoState
  );
  const accountNumber = useTextInput({
    initialValue: internationalBankingInfo.accountNumber?.value,
    onChange: getValidator(selectedCountry, INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER),
  });

  useEffect(() => {
    setInternationalBankingInfo({
      ...internationalBankingInfo,
      accountNumber: {
        inputName: INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER,
        value: accountNumber.value,
        isValid: accountNumber.isValid,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber.value, accountNumber.isValid]);

  return (
    <TextInput
      type="text"
      value={accountNumber.value}
      onChange={(value) => {
        accountNumber.onChange(value);
      }}
      label={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER].label}
      onBlur={accountNumber.onBlur}
      hasError={accountNumber.hasError}
      errorMessage={
        INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.ACCOUNT_NUMBER].errorMessage
      }
    />
  );
};

export default AccountNumber;
