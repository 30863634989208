import React from "react";
import BankAccountRep from "reps/BankAccountRep";
import colors from "styles/colors";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import Text from "ui/typography/Text";

import styles from "./AccountPreview.module.scss";
import HorizontalLine from "./HorizontalLine";

type Props = {
  account: BankAccountRep.Complete;
};

const AccountPreview: React.FC<Props> = ({ account }) => {
  return (
    <div className={styles.container}>
      <Info label="Routing number">{account.routingNumber}</Info>
      <HorizontalLine />
      <Info label="Account number">{account.accountNumber}</Info>
    </div>
  );
};

export default AccountPreview;

const Info: React.FC<{ label: string; children: string }> = ({ label, children }) => {
  return (
    <div className={styles.info}>
      <Text as="span" size={14} weight="regular" color={colors.grey[800]}>
        {label}
      </Text>
      <ItemWithCopyTextTooltip textToCopy={children}>
        <Text as="span" size={14} weight="medium" color={colors.grey[800]}>
          {children}
        </Text>
      </ItemWithCopyTextTooltip>
    </div>
  );
};
