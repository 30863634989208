import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Pagination } from "ui/navigation/PageIndicator";

import { Column, ColumnProps, FetchTableData, Scroll, Sort } from ".";

export type HasKey = {
  key: React.Key;
};

type TableContext<T> = {
  columns: Column<T>[];
  data?: (T & HasKey)[];

  isLoading: boolean;

  pagination: Pagination | undefined;

  renderLoader: () => ReactNode;

  scroll: Scroll;
  setScroll: Dispatch<SetStateAction<Scroll>>;

  sort: Sort | undefined;
  setSort: Dispatch<SetStateAction<Sort | undefined>>;
};

const Context = React.createContext<TableContext<unknown>>(
  undefined as unknown as TableContext<unknown>
);

type Props<T> = {
  columns: ColumnProps<T>[];
  fetchData: FetchTableData<T & HasKey>;
  initialSort?: Sort;
  pagination?: Pagination;
  renderLoader?: () => ReactNode;

  children: ReactNode;
};

const TableWrapper = <T extends unknown>({
  columns: columnProps,
  fetchData,
  initialSort = undefined,
  pagination = undefined,
  renderLoader,
  children,
}: Props<T>) => {
  const [data, setData] = useState<(T & HasKey)[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [scroll, setScroll] = useState<Scroll>({ isAtStart: true, isAtEnd: true });
  const [sort, setSort] = useState<Sort | undefined>(initialSort);

  const columns = useMemo(() => computeColumns(columnProps as Column<unknown>[]), [columnProps]);

  /**
   * Reset pagination when the table fundamentals change.
   */
  useEffect(() => {
    pagination?.setCurrentPage(0);
    pagination?.setTotalCount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, fetchData]);

  useEffect(() => {
    setData(undefined);
    setIsLoading(true);
    let set = true; // eslint-disable-line functional/no-let
    fetchData({ offset: pagination?.offset, pageSize: pagination?.pageSize, sort }).then(
      ({ data, totalCount }) => {
        if (!set) return;
        setData(data);
        setIsLoading(false);
        pagination?.setTotalCount(totalCount ?? data.length);
      }
    );
    return () => void (set = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, pagination?.currentPage, sort]);

  const value: TableContext<unknown> = {
    columns: columns,
    data,
    isLoading,
    pagination,
    renderLoader: renderLoader ?? (() => null),
    scroll,
    setScroll,
    sort,
    setSort,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default TableWrapper;

const computeColumns = (columns: ColumnProps<unknown>[]): Column<unknown>[] => {
  const result: Column<unknown>[] = columns.map((column) => new Column<unknown>(column));

  // Compute left.
  let left = 0; // eslint-disable-line functional/no-let
  // eslint-disable-next-line functional/no-let
  for (let i = 0; i < result.length; i++) {
    const column = result[i];
    if (column.sticky !== "left") break;
    column.left = left;
    left += column.width ?? 0;
  }

  // Compute right.
  let right = 0; // eslint-disable-line functional/no-let
  // eslint-disable-next-line functional/no-let
  for (let i = result.length - 1; i >= 0; i--) {
    const column = result[i];
    if (column.sticky !== "right") break;
    column.right = right;
    right += column.width ?? 0;
  }

  return result;
};

export const useTable = (): TableContext<unknown> => useContext(Context);
