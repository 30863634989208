import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ActiveCapitalAccountWithChargeCard } from "resources/capital-accounts/hooks/useActiveCapitalAccountsWithChargeCard";
import { z } from "zod";

const schema = z.object({
  capitalAccount: z.custom<ActiveCapitalAccountWithChargeCard>(),
});

export type CreateCardModalSelectCapitalAccountFormInputs = z.input<typeof schema>;
export type CreateCardModalSelectCapitalAccountFormOutputs = z.output<typeof schema>;

type Params = {
  defaultValues: CreateCardModalSelectCapitalAccountFormInputs;
};

const useCreateCardModalSelectCapitalAccountForm = ({ defaultValues }: Params) => {
  return useForm<
    CreateCardModalSelectCapitalAccountFormInputs,
    object,
    CreateCardModalSelectCapitalAccountFormOutputs
  >({
    resolver: zodResolver(schema),
    defaultValues,
  });
};

export default useCreateCardModalSelectCapitalAccountForm;
