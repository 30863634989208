import { AchReceivedPayment } from "@highbeam/unit-node-sdk";
import { FC } from "react";
import Pill, { PillColor } from "ui/data-display/Pill";

type StatusLabel = {
  text: string;
  pillColor: PillColor;
};

// TODO: Design and add all the received statuses
const failed: StatusLabel = {
  text: "Failed",
  pillColor: "pink-dark",
};

const getLabel = (receivedPayment: AchReceivedPayment): StatusLabel | undefined => {
  switch (receivedPayment.attributes.status) {
    case "MarkedForReturn":
      return failed;
    default:
      return undefined;
  }
};

type Props = {
  receivedPayment: AchReceivedPayment;
};

const ReceivedPaymentStatusPill: FC<Props> = ({ receivedPayment }) => {
  const label = getLabel(receivedPayment);

  if (!label) return null;

  return (
    <Pill bordered color={label.pillColor}>
      {label.text}
    </Pill>
  );
};

export default ReceivedPaymentStatusPill;
