import { useIsSuperusering } from "state/auth/isSuperusering";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

const useChatEnabled = (): boolean => {
  const featureFlag = useFeatureFlag("CHAT_UI") as boolean;
  const isSuperusering = useIsSuperusering();
  return featureFlag || isSuperusering;
};

export default useChatEnabled;
