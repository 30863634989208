import {
  getValidator,
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import TextInput, { useTextInput } from "ui/inputs/TextInput";

type Props = {
  selectedCountry: string;
};

const BankCode: React.FC<Props> = ({ selectedCountry }) => {
  const [internationalBankingInfo, setInternationalBankingInfo] = useRecoilState(
    internationalBankingInfoState
  );
  const BankCode = useTextInput({
    initialValue: internationalBankingInfo.bankCode?.value,
    onChange: getValidator(selectedCountry, INTERNATIONAL_WIRE_INPUTS.BANK_CODE),
  });

  useEffect(() => {
    setInternationalBankingInfo({
      ...internationalBankingInfo,
      bankCode: {
        inputName: INTERNATIONAL_WIRE_INPUTS.BANK_CODE,
        value: BankCode.value,
        isValid: BankCode.isValid,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BankCode.value, BankCode.isValid]);

  return (
    <TextInput
      type="text"
      value={BankCode.value}
      onChange={(value) => {
        BankCode.onChange(value);
      }}
      label={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.BANK_CODE].label}
      onBlur={BankCode.onBlur}
      hasError={BankCode.hasError}
      errorMessage={
        INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.BANK_CODE].errorMessage
      }
    />
  );
};

export default BankCode;
