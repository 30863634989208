import { ComponentProps, FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import BankAccountRep from "reps/BankAccountRep";
import bankAccountByUnitCoDepositAccountIdQueryHooks from "resources/bank-accounts/queries/bankAccountByUnitCoDepositAccountIdQueryHooks";
import bankAccountQueryHooks from "resources/bank-accounts/queries/bankAccountQueryHooks";
import AccountBar from "ui/data-display/AccountBar";
import DetailsPopover from "ui/data-display/DetailsPopover";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

import HighbeamAccountIcon from "../HighbeamAccountIcon";

type Props = Omit<ComponentProps<typeof AccountBar>, "accountName"> & {
  bankAccount: BankAccountRep.Complete;
  showStatus?: boolean;
  withPopover?: boolean;
  withLink?: boolean;
};

const BankAccountBar: FC<Props> = ({
  bankAccount,
  className,
  showStatus = true,
  withPopover,
  withLink,
  ...props
}) => {
  // eslint-disable-next-line functional/no-let
  let bankAccountBar = (
    <AccountBar
      icon={<HighbeamAccountIcon highbeamTypeName={bankAccount.highbeamType.name} />}
      accountName={bankAccount.name}
      accountNumber={
        bankAccount.highbeamType.name === "HighYield" ? undefined : bankAccount.accountNumber
      }
      status={
        showStatus
          ? variants(bankAccount.status, {
              [BankAccountRep.Status.OPEN]: undefined,
              [BankAccountRep.Status.CLOSED]: "closed" as const,
              [BankAccountRep.Status.FROZEN]: "frozen" as const,
            })
          : undefined
      }
      className={cn(withLink && "hover:underline", className)}
      {...props}
    />
  );

  if (withLink) {
    bankAccountBar = (
      <Link
        to={`/accounts/${bankAccount.guid}`}
        onClick={(e) => e.stopPropagation()} // We assume we want to cancel any other click handlers if `withLink` is specified.
      >
        {bankAccountBar}
      </Link>
    );
  }

  if (!withPopover) {
    return bankAccountBar;
  }

  return (
    <DetailsPopover
      triggerAsChild={withLink}
      popoverContent={
        <>
          <DetailsPopover.ContentHeader>
            {/* Recursive use of this component is intentional! */}
            <BankAccountBar withLink bankAccount={bankAccount} className="gap-x-3" />
          </DetailsPopover.ContentHeader>
          <DetailsPopover.ContentBody>
            <DetailsPopover.ContentItem>
              <>Available</>
              <MoneyAmount cents={bankAccount.availableBalance} />
            </DetailsPopover.ContentItem>
          </DetailsPopover.ContentBody>
        </>
      }
    >
      {bankAccountBar}
    </DetailsPopover>
  );
};

export default BankAccountBar;

// Wrappers

type BankAccountBarByUnitCoDepositAccountIdProps = Omit<Props, "bankAccount"> & {
  unitCoDepositAccountId: string;
  notFoundFallback?: ReactNode;
};

export const BankAccountBarByUnitCoDepositAccountId: FC<
  BankAccountBarByUnitCoDepositAccountIdProps
> = ({ unitCoDepositAccountId, notFoundFallback = null, ...props }) => {
  const { data: bankAccount, isLoading } = bankAccountByUnitCoDepositAccountIdQueryHooks.useQuery({
    unitCoDepositAccountId: unitCoDepositAccountId,
  });

  if (isLoading) {
    return <AccountBar.Loading {...props} />;
  }

  if (!bankAccount) {
    return <>{notFoundFallback}</>;
  }

  return <BankAccountBar bankAccount={bankAccount} {...props} />;
};

type BankAccountBarByGuidProps = Omit<Props, "bankAccount"> & {
  bankAccountGuid: string;
  notFoundFallback?: ReactNode;
};

export const BankAccountBarByGuid: FC<BankAccountBarByGuidProps> = ({
  bankAccountGuid,
  notFoundFallback = null,
  ...props
}) => {
  const { data: bankAccount, isLoading } = bankAccountQueryHooks.useQuery({
    bankAccountGuid: bankAccountGuid,
    status: "all",
  });

  if (isLoading) {
    return <AccountBar.Loading {...props} />;
  }

  if (!bankAccount) {
    return <>{notFoundFallback}</>;
  }

  return <BankAccountBar bankAccount={bankAccount} {...props} />;
};
