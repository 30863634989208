import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";
import { removeScheme } from "utils/url";

type Props = {
  link: string;
  text?: string;
  tooltipContent?: React.ReactNode;
  className?: string;
};

const CopyLink: React.FC<Props> = ({
  link,
  text = removeScheme(link),
  tooltipContent,
  className,
}) => (
  <ItemWithCopyTextTooltip
    className={cn(
      "w-full items-center gap-x-2 whitespace-nowrap rounded-lg bg-grey-50 p-4 shadow-xs hover:shadow-sm hover:active:shadow-xs",
      className
    )}
    textToCopy={link}
    tooltipContent={tooltipContent}
  >
    <Span className="select-none text-sm text-grey-500">{text}</Span>
  </ItemWithCopyTextTooltip>
);

export default CopyLink;
