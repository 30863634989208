// The Embedded Drawer component is a simple panel that is absolutely positioned along
// the bottom of its parent container (or first non-static positioned ancestor). The
// component is composed of a visible header/bar and a body, which is expanded/collapsed
// by clicking the header.
// The Embedded Drawer is *not* a modal-based component, like a conventional drawer or
// our dialog modals or Flexpane. When the drawer is open, it does *not* trap focus or
// otherwise prevent interaction with the rest of the page.

import { CaretDown as CaretDownIcon, CaretUp as CaretUpIcon } from "@phosphor-icons/react";
import classNames from "classnames";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useId,
  useCallback,
  useRef,
  useEffect,
} from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import { transitionDuration } from "utils/transitions";

import styles from "./EmbeddedDrawer.module.scss";

type EmbeddedDrawerContextValue = {
  baseId: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const EmbeddedDrawerContext = createContext<EmbeddedDrawerContextValue>({
  baseId: "",
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
});

const useEmbeddedDrawerContext = () => {
  const context = useContext(EmbeddedDrawerContext);
  if (!context) {
    throw new Error("useEmbeddedDrawerContext must be used within an EmbeddedDrawer");
  }
  return context;
};

const makeHeaderId = (baseId: string) => `${baseId}-header`;
const makeBodyId = (baseId: string) => `${baseId}-body`;

const EmbeddedDrawerHeaderCaret: FC = () => {
  const { isOpen } = useEmbeddedDrawerContext();

  return (
    <div className={styles.headerCaretContainer}>
      {isOpen ? <CaretDownIcon size={16} /> : <CaretUpIcon size={16} />}
    </div>
  );
};

type EmbeddedDrawerHeaderProps = PropsWithChildren;

const EmbeddedDrawerHeader: FC<EmbeddedDrawerHeaderProps> = ({ children }) => {
  const { baseId, isOpen, onOpen, onClose } = useEmbeddedDrawerContext();
  const headerId = makeHeaderId(baseId);
  const bodyId = makeBodyId(baseId);

  const handleClick = useCallback(() => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  }, [isOpen, onClose, onOpen]);

  return (
    <VirtualButton
      id={headerId}
      aria-controls={bodyId}
      className={styles.headerContainer}
      onClick={handleClick}
    >
      {children}
    </VirtualButton>
  );
};

type EmbeddedDrawerBodyProps = PropsWithChildren;

const EmbeddedDrawerBody: FC<EmbeddedDrawerBodyProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { baseId, isOpen } = useEmbeddedDrawerContext();
  const headerId = makeHeaderId(baseId);
  const bodyId = makeBodyId(baseId);

  useEffect(() => {
    const container = containerRef.current!;
    container.style.overflow = "hidden";

    if (isOpen) {
      container.style.display = "block";
      container.style.maxHeight = `${container.scrollHeight}px`;

      const timer = setTimeout(() => {
        container.style.overflow = null!;
        container.style.maxHeight = null!;
      }, transitionDuration);

      return () => clearTimeout(timer);
    } else {
      container.style.maxHeight = "0px";

      const timer = setTimeout(() => {
        container.style.display = "none";
      }, transitionDuration);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <div
      id={bodyId}
      aria-labelledby={headerId}
      aria-expanded={isOpen}
      className={styles.bodyContainer}
      ref={containerRef}
    >
      {children}
    </div>
  );
};

type Props = PropsWithChildren<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  className?: string;
}>;

const EmbeddedDrawer: FC<Props> = ({ isOpen, onOpen, onClose, className, children }) => {
  const baseId = useId();

  // Decorate the provided onClose handler to handle special logic for animating
  // the drawer body when it's closed.
  const shimmedOnClose = useCallback(() => {
    const bodyContainer = document.getElementById(makeBodyId(baseId));
    // If we have a body element, we need to set its max-height to its scrollHeight
    // before closing to ensure a smooth animation.
    if (bodyContainer) {
      bodyContainer.style.maxHeight = `${bodyContainer.scrollHeight}px`;
      // Schedule the onClose via a setTimeout to allow the max-height
      // of the body container to be set before transitioning.
      setTimeout(onClose, 0);
    } else {
      onClose();
    }
  }, [baseId, onClose]);

  return (
    <div
      className={classNames(styles.container, className, { [styles["container-isOpen"]]: isOpen })}
    >
      <EmbeddedDrawerContext.Provider value={{ baseId, isOpen, onOpen, onClose: shimmedOnClose }}>
        {children}
      </EmbeddedDrawerContext.Provider>
    </div>
  );
};

export default Object.assign(EmbeddedDrawer, {
  Header: EmbeddedDrawerHeader,
  HeaderCaret: EmbeddedDrawerHeaderCaret,
  Body: EmbeddedDrawerBody,
});
