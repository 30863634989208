import { captureException } from "@sentry/react";
import useMfa from "modules/mfa/useMfa";
import { useIsCurrentBusinessMemberOnboarded } from "resources/business-members/queries/businessMemberQueryHooks";
import { useRefreshJwt } from "state/auth/jwt";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import useMarkAuthorizedWithUnit from "./useMarkAuthorizedWithUnit";

type GetStarted = {
  getStarted: () => Promise<void>;
  isPending: boolean;
};

const useGetStarted = ({ onSuccess }: { onSuccess: () => void }): GetStarted => {
  const shouldRefreshJwt = useFeatureFlag("REFRESH_JWT_BEFORE_INITIAL_AUTHORIZATION") as boolean;

  const isOnboarded = useIsCurrentBusinessMemberOnboarded();

  const refreshJwt = useRefreshJwt();
  const { segmentTrack } = useSegment();

  const { mfa } = useMfa();

  const { mutate: markAuthorizedWithUnit, isPending } = useMarkAuthorizedWithUnit({ onSuccess });

  return {
    getStarted: async (): Promise<void> => {
      if (isOnboarded) {
        onSuccess();
        return;
      }

      // We require the user to MFA first in order to ensure they have a phone number configured with Auth0.
      // This phone number will be passed through to Unit.
      if (shouldRefreshJwt) {
        try {
          await refreshJwt();
        } catch (e) {
          captureException(e);
        }
      }
      await mfa();
      segmentTrack(SEGMENT_EVENTS.ONBOARDING_COMPLETE);
      markAuthorizedWithUnit();
    },
    isPending,
  };
};

export default useGetStarted;
