import { atom } from "recoil";
import { stringLocalStorageEffect } from "utils/recoil";

/**
 * Where to redirect users after fully completing a Plaid Link OAuth flow.
 *
 * This is different from the Plaid OAuth redirect URL; the flow goes something like this:
 *
 *   Original Plaid Link location (e.g. /settings/banks-and-cards) ->
 *   Bank website (e.g. chase.com) ->
 *   Plaid Redirect Url (e.g. /plaid-oauth) ->
 *   postPlaidOauthRedirectPath (e.g. /settings/banks-and-cards)
 *
 * See https://plaid.com/docs/link/oauth/
 */
export const localStoragePostPlaidOauthRedirectPath = atom<string>({
  key: "localStorage/postPlaidOauthRedirectPath",
  default: "",
  effects: [stringLocalStorageEffect("postPlaidOauthRedirectPath")],
});
