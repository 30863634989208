import { FEATURE_FLAGS } from "flags";
import { atom } from "recoil";

export type FeatureFlags = Record<string, boolean | number>;

const featureFlagsState = atom<FeatureFlags>({
  key: "featureFlags",
  default: Object.values(FEATURE_FLAGS).reduce(
    (flagObj, flag) => ({ ...flagObj, [flag]: false }),
    {}
  ),
});

export default featureFlagsState;
