import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import React, { ReactNode, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import businessSummariesByMemberUserQueryHooks, {
  filterActiveAndPendingBusinessSummaries,
} from "resources/businesses/queries/businessSummariesByMemberUserQueryHooks";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useUserInvitationsByEmailAddress from "resources/user-invitations/queries/useUserInvitationsByEmailAddress";
import businessGuidState from "state/auth/businessGuid";
import isSuperuseringState from "state/auth/isSuperusering";

import BusinessDisambiguator from "./BusinessDisambiguator";

export const BUSINESS_DISAMBIGUATOR_PATH = "/businesses";

type Props = {
  children: ReactNode;
};

/**
 * This component wraps most of the app, adding support for multi-business.
 * It uses some approaches that are atypical in React,
 * such as returning null to avoid flickers and using window.location.href.
 * Avoid copying these patterns.
 */
const BusinessDisambiguatorRedirector: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const [businessGuid, setBusinessGuid] = useRecoilState(businessGuidState);

  const canSuperuser = useCanSuperuser();
  const [isSuperusering, setIsSuperusering] = useRecoilState(isSuperuseringState);

  const userGuid = useUserGuid();
  const businessSummaries = businessSummariesByMemberUserQueryHooks.useData({
    userGuid,
    select: filterActiveAndPendingBusinessSummaries,
  });

  const userInvitations = useUserInvitationsByEmailAddress();

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    if (isSuperusering && !canSuperuser) {
      // The user is superusering, but they're not allowed to.
      setIsSuperusering(false);
      return;
    }
    if (
      businessGuid &&
      !businessSummaries.map(({ businessGuid }) => businessGuid).includes(businessGuid) &&
      !isSuperusering
    ) {
      // The user is trying to access a business they don't have access to.
      setBusinessGuid("");
      return;
    }
    if (
      !businessGuid &&
      businessSummaries.length === 1 &&
      userInvitations.length === 0 &&
      !isSuperusering
    ) {
      // Automatically select the business if the user only has 1.
      setBusinessGuid(businessSummaries[0].businessGuid);
      return;
    }
    setIsLoading(false);
  }, [
    businessGuid,
    businessSummaries,
    canSuperuser,
    isSuperusering,
    setBusinessGuid,
    setIsSuperusering,
    userInvitations.length,
  ]);

  if (isLoading) {
    return null;
  }

  if (businessGuid) {
    return <>{children}</>;
  }

  // Handles the edge case where a user navigates to the business disambiguator but they don't have a selected `businessGuid`.
  if (location.pathname === BUSINESS_DISAMBIGUATOR_PATH) {
    return <BusinessDisambiguator />;
  }

  const qp = new URLSearchParams();

  if (location.pathname !== "/" && location.pathname !== BUSINESS_DISAMBIGUATOR_PATH) {
    qp.set("redirectTo", location.pathname);
  }

  return <Navigate to={`${BUSINESS_DISAMBIGUATOR_PATH}?${qp}`} replace />;
};

export default BusinessDisambiguatorRedirector;
