import { useSuspenseQuery } from "@tanstack/react-query";
import HighbeamApi from "api/HighbeamApi";
import { useMemo } from "react";
import AccountingAccountRep from "reps/AccountingAccountRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery, { UseRefreshQueryOptions } from "utils/react-query/useRefreshQuery";

import { makeAccountingAccountsGroups } from "../utils/accountingAccountsGroups";

const makeQueryKey = (businessGuid: string) => ["accounting-accounts", { businessGuid }];

const makeQueryFn = (highbeamApi: HighbeamApi, businessGuid: string) => () =>
  highbeamApi.accountingAccount.search(businessGuid);

type AccountingAccountFilterFn = (accountingAccount: AccountingAccountRep.Complete) => boolean;

const useAccountingAccountsQueryOptions = (filter?: AccountingAccountFilterFn) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: makeQueryKey(businessGuid),
    queryFn: makeQueryFn(highbeamApi, businessGuid),
    select: filter ? (accountingAccounts) => accountingAccounts.filter(filter) : undefined,
  });
};

const useAccountingAccounts = (filter?: AccountingAccountFilterFn) => {
  const { data } = useSuspenseQuery(useAccountingAccountsQueryOptions(filter));
  return data;
};

export const useRefreshAccountingAccountsQuery = (options: UseRefreshQueryOptions = {}) => {
  const businessGuid = useBusinessGuid();
  return useRefreshQuery(makeQueryKey(businessGuid), options);
};

// The useAccountingGroupsQuery is derived from the same query as useAccountingAccounts,
// but rather than returning the list of accounting accounts, it transforms the raw list
// into a list of "accounting accounts groups", each containing a list of accounting accounts
// belonging to the same category. Groups are sorted by category, with "expense" category
// always being first. Accounting accounts within each group are based on their hierarchy
// (e.g. a parent account will always come before its child account), with siblings
// sorted alphabetically. The accounting accounts within each group are also annotated
// with their depth in the hierarchy, so that they can be rendered with the appropriate
// indentation level.
// See makeAccountingAccountsGroups for more details.
export const useAccountingAccountsGroups = (filter?: AccountingAccountFilterFn) => {
  const accountingAccounts = useAccountingAccounts(filter);
  return useMemo(() => makeAccountingAccountsGroups(accountingAccounts), [accountingAccounts]);
};

export default useAccountingAccounts;
