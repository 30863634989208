import { useMutation } from "@tanstack/react-query";
import BusinessMemberRep from "reps/BusinessMemberRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

import businessMemberQueryHooks from "../queries/businessMemberQueryHooks";

type Variables = BusinessMemberRep.Updater & {
  readonly memberGuid: string;
};

const useUpdateBusinessMemberMutation = () => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const userGuid = useUserGuid();
  const refreshBusinessMemberQuery = businessMemberQueryHooks.useRefreshQuery({ userGuid });

  return useMutation({
    mutationFn: ({ memberGuid, ...variables }: Variables) => {
      return highbeamApi.businessMember.update(businessGuid, memberGuid, variables);
    },
    onSuccess: async () => {
      await refreshBusinessMemberQuery();
    },
  });
};

export default useUpdateBusinessMemberMutation;
