import { zodResolver } from "@hookform/resolvers/zod";
import dayjs, { Dayjs } from "dayjs";
import { FC, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { notify } from "ui/feedback/Toast";
import DatePicker from "ui/inputs/DatePicker";
import Helper from "ui/inputs/Helper";
import ModalV4 from "ui/overlay/ModalV4";
import { formatDate, dateOnly } from "utils/date";
import { z } from "zod";

import useUpdateApSettingsMutation from "../../mutations/useUpdateApSettingsMutation";
import { useApSettings } from "../../queries/apSettingsQueryHooks";

const updateBooksClosedDateFormSchema = z.object({
  closeBooksDate: z.custom<Dayjs | null>(),
});

type UpdateBooksClosedDateFormInputs = z.infer<typeof updateBooksClosedDateFormSchema>;

type UpdateBooksClosedDateModalContentProps = {
  onClose: () => void;
  onSuccess: () => void;
};

const UpdateBooksClosedDateModalContent: FC<UpdateBooksClosedDateModalContentProps> = ({
  onSuccess,
}) => {
  const { id: apSettingsId, closeBooksDate } = useApSettings();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateBooksClosedDateFormInputs>({
    resolver: zodResolver(updateBooksClosedDateFormSchema),
    mode: "onChange",
    defaultValues: {
      closeBooksDate: closeBooksDate ? dayjs(closeBooksDate) : null,
    },
  });

  const { mutate: updateApSettings, isPending: isUpdatingApSettings } = useUpdateApSettingsMutation(
    apSettingsId,
    {
      onSuccess: () => {
        notify("success", "Accounting settings updated");
        onSuccess();
      },
      onError: (error) => {
        notify("error", error.message ?? "Failed to update books closed date");
      },
    }
  );

  const onSubmit = useCallback(
    (data: UpdateBooksClosedDateFormInputs) => {
      updateApSettings({ closeBooksDate: data.closeBooksDate?.format("YYYY-MM-DD") ?? null });
    },
    [updateApSettings]
  );

  return (
    <ModalV4.Form onSubmit={handleSubmit(onSubmit)}>
      <ModalV4.Header>Books closed date</ModalV4.Header>
      <ModalV4.Body>
        <div>
          <Controller
            name="closeBooksDate"
            control={control}
            render={({ field, fieldState }) => {
              const { value } = field;
              return (
                <>
                  <DatePicker
                    label="Books closed date"
                    isClearable
                    disabled={field.disabled}
                    maxDate={dateOnly().toDate()}
                    value={value ? value.toDate() : null}
                    onChange={(selected) => {
                      field.onChange(selected ? dayjs(selected) : selected);
                    }}
                    className="leading-4" // NB(lev): DatePicker label/input is optically misaligned without this leading set.
                    variant="no-date"
                    onCalendarClose={() => {
                      field.onBlur();
                    }}
                  />
                  {closeBooksDate && value && dayjs(closeBooksDate).isAfter(value) && (
                    <Helper iconVariant="warning">
                      If you set the books closed date to {formatDate(value)}, it will unlock
                      editing and syncing for any bills with invoice dates between{" "}
                      {formatDate(value, "MMM D")} and {formatDate(closeBooksDate, "MMM D")}.
                    </Helper>
                  )}
                  {closeBooksDate && !value && fieldState.isDirty && (
                    <Helper iconVariant="warning">
                      If you clear the books closed date, editing and syncing will be unlocked for
                      all bills.
                    </Helper>
                  )}
                </>
              );
            }}
          />
        </div>
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.SubmitButton disabled={!isValid} isLoading={isUpdatingApSettings}>
          Save changes
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton>Close</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = UpdateBooksClosedDateModalContentProps;

const UpdateBooksClosedDateModal: FC<Props> = (props) => (
  <ModalV4 onClose={props.onClose}>
    <UpdateBooksClosedDateModalContent {...props} />
  </ModalV4>
);

export default UpdateBooksClosedDateModal;
