import { LockLaminated } from "@phosphor-icons/react";
import { Span } from "ui/typography";

const HighbeamCardFraud = () => (
  <div className="flex items-center gap-1 rounded-xl border border-grey-200 bg-white px-2 py-1.5 shadow-xs">
    <LockLaminated size={12} weight="bold" className="text-red-500" />
    <Span className="text-xs font-bold text-red-500">Locked: unusual activity</Span>
  </div>
);

export default HighbeamCardFraud;
