import { useRecoilValue } from "recoil";
import auth0ClientState from "state/auth/auth0Client";

const useMfaWithRedirect = () => {
  const auth0Client = useRecoilValue(auth0ClientState);

  return () => {
    return auth0Client.loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}${window.location.hash}`,
      },
      authorizationParams: {
        acr_values: "mfa_required", // eslint-disable-line camelcase
      },
    });
  };
};

export default useMfaWithRedirect;
