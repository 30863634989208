import { FC, ReactElement } from "react";
import { RequireExactlyOne } from "type-fest";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

export type UserAvatarColor = "purple-dark" | "purple-light" | "grey";

export type UserAvatarSize = 32 | 44;

type Props = {
  color: UserAvatarColor;
  size: UserAvatarSize;
  className?: string;
} & RequireExactlyOne<{
  initials: string;
  icon: ReactElement;
}>;

const UserAvatar: FC<Props> = ({ initials, icon, className, color, size }) => {
  return (
    <span
      className={cn(
        "flex select-none items-center justify-center overflow-hidden rounded-full text-center font-bold",
        variants(color, {
          "purple-dark": "bg-purple-500 text-white",
          "purple-light": "border border-purple-200 bg-purple-100 text-purple-500",
          grey: "border border-grey-200 bg-grey-100 text-grey-500",
        }),
        variants(size, {
          32: "size-8 text-xs [&>svg]:size-4",
          44: "size-11 text-md [&>svg]:size-5",
        }),
        className
      )}
    >
      {icon}
      {initials}
    </span>
  );
};

export default UserAvatar;
