import ActionsSection from "pages/payments/PayeesPage/CreatePayeePage/ActionsSection";
import { onRoutingNumberChange } from "pages/payments/PayeesPage/CreatePayeePage/validation";
import { accountTypeOptions } from "pages/SendMoneyPage";
import { PaymentMethod } from "pages/SendMoneyPage/utils";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useResetRecoilState } from "recoil";
import { internationalEntityState } from "state/payments/international/entity";
import Section from "ui/data-display/Section";
import { DropdownState, useDropdown } from "ui/inputs/Dropdown";
import { TextInputState, useTextInput, validator } from "ui/inputs/TextInput";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import Validator from "utils/validator";

import PayeeInfoSection from "./PayeeInfoSection";
import PaymentInfoSection from "./PaymentInfoSection";

type CreatePayeeFormData = {
  payee: {
    name: TextInputState;
    emailAddress: TextInputState;
    phoneNumber: TextInputState;
    defaultChartOfAccountId: {
      value: string | null;
      setValue: Dispatch<SetStateAction<string | null>>;
    };
  };
  paymentInfo: {
    ach: {
      hasError: [boolean, (value: boolean) => void];
      routingNumber: TextInputState;
      accountNumber: TextInputState;
      accountType: DropdownState;
    };
    wire: {
      hasError: [boolean, (value: boolean) => void];
      routingNumber: TextInputState;
      accountNumber: TextInputState;
    };
  };
};

// NB(lev): defaultChartOfAccountId is not included here right now (it's currently not
// a TextInputState nor a DropdownState. Since this function is only used for validation
// right now, we don't technically need it here because it's optional and cannot be placed
// into an invalid state).
export const createPayeeFormFields = (form: CreatePayeeFormData) => [
  form.payee.name,
  form.payee.emailAddress,
  form.payee.phoneNumber,
  form.paymentInfo.ach.routingNumber,
  form.paymentInfo.ach.accountNumber,
  form.paymentInfo.ach.accountType,
  form.paymentInfo.wire.routingNumber,
  form.paymentInfo.wire.accountNumber,
];

export const CreatePayeeFormContext = React.createContext<CreatePayeeFormData>(
  null as unknown as CreatePayeeFormData
);

const CreatePayeeForm = () => {
  const highbeamApi = useHighbeamApi();
  const resetEntityState = useResetRecoilState(internationalEntityState);
  const [defaultChartOfAccountId, setDefaultChartOfAccountId] = useState<string | null>(null);

  useEffect(() => {
    resetEntityState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value: CreatePayeeFormData = {
    payee: {
      name: useTextInput({ onChange: validator(Validator.humanName, "Payee name is required") }),
      emailAddress: useTextInput({
        onChange: validator(Validator.emailAddress, "Invalid email address", false),
      }),
      phoneNumber: useTextInput({
        onChange: validator(Validator.phoneNumber, "Invalid phone number", false),
      }),
      defaultChartOfAccountId: {
        value: defaultChartOfAccountId,
        setValue: setDefaultChartOfAccountId,
      },
    },
    paymentInfo: {
      ach: {
        hasError: useState(false),
        routingNumber: useTextInput({
          onChange: onRoutingNumberChange(highbeamApi, PaymentMethod.ACH),
        }),
        accountNumber: useTextInput({
          onChange: validator(Validator.accountNumber, "Invalid account number", false),
        }),
        accountType: useDropdown({ initialValue: accountTypeOptions[0] }),
      },
      wire: {
        hasError: useState(false),
        routingNumber: useTextInput({
          onChange: onRoutingNumberChange(highbeamApi, PaymentMethod.WIRE),
        }),
        accountNumber: useTextInput({
          onChange: validator(Validator.accountNumber, "Invalid account number", false),
        }),
      },
    },
  };

  return (
    <Section>
      <CreatePayeeFormContext.Provider value={value}>
        <PayeeInfoSection />
        <PaymentInfoSection />
        <ActionsSection />
      </CreatePayeeFormContext.Provider>
    </Section>
  );
};

export default CreatePayeeForm;
