import { Copy } from "@phosphor-icons/react";
import React, { ReactNode, RefObject, useEffect, useState } from "react";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph } from "ui/typography";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

type Props = {
  label?: string;
  children: ReactNode;
  numeric?: boolean;
  isCopyable?: boolean;
  copy: {
    buttonRef?: RefObject<HTMLDivElement>; // Allows for externally-managed copy/paste.
    onClick?: () => void;
    state?: [boolean, (copied: boolean) => void]; // Optionally manage copied state externally.
  };
  textColor?: "white" | "black";
};

const HighbeamCardField: React.FC<Props> = ({
  label,
  children,
  numeric,
  copy,
  isCopyable,
  textColor = "black",
}) => {
  // We assume that [copy.state]'s existence is stable.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [copied, setCopied] = copy.state ?? useState(false);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setCopied(true);
    copy.onClick?.();
  };

  useEffect(() => {
    if (!copied) return;
    const timer = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(timer);
  }, [copied, setCopied]);

  return (
    <div className="flex items-center gap-x-3 text-white">
      {label ? (
        <Text size={14} weight="medium" color={textColor}>
          {label}
        </Text>
      ) : null}
      {/* Does not use CopyItemTooltip because we need to expose an interface that can work with the
          VGS iframe, which differs from that component's interface and is not a pattern used
          elsewhere in the app. */}
      {isCopyable && (
        <ItemWithTooltip
          tooltip={<Paragraph>{!copied ? "Click to copy" : "Copied"}</Paragraph>}
          asChild
        >
          <div className="flex cursor-pointer items-center gap-x-2">
            <div
              className={cn(
                "relative flex items-center font-bold",
                numeric && "font-monospace font-medium"
              )}
              onClick={handleClick}
            >
              {children}
            </div>
            <div className="relative flex w-4 cursor-pointer">
              <Copy size={16} color={textColor} />
              <div
                ref={copy.buttonRef}
                className="absolute inset-0 h-full w-full [&>iframe]:h-full [&>iframe]:w-full"
                onClick={handleClick}
              />
            </div>
          </div>
        </ItemWithTooltip>
      )}
      {!isCopyable && (
        <div className="flex items-center gap-x-2">
          <div
            className={cn(
              "relative flex items-center font-bold",
              numeric && "font-monospace font-medium"
            )}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default HighbeamCardField;
