import { ConnectedStoreType } from "../types";

type Params = {
  connectedStoreType: ConnectedStoreType;
  pluralize?: boolean;
};

const getConnectedStoreAccountOrStoreLabel = ({
  connectedStoreType,
  pluralize,
}: Params): string => {
  switch (connectedStoreType) {
    case "Amazon":
    case "Shopify":
      return pluralize ? "stores" : "store";
    case "PayPal":
    case "Stripe":
      return pluralize ? "accounts" : "account";
  }
};

export default getConnectedStoreAccountOrStoreLabel;
