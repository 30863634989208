import env from "env";
import { ApiError, request, RequestMethod } from "utils/ajax";

export default class Auth0Api {
  async passwordReset(userEmail: string): Promise<any> {
    const url = `https://${env.AUTH0_TENANT_DOMAIN}/dbconnections/change_password`;
    const body = {
      client_id: env.AUTH0_CLIENT_ID, // eslint-disable-line camelcase
      email: userEmail,
      connection: env.AUTH0_CONNECTION_NAME,
    };
    const headers = { "content-type": "application/json" };

    return await this.request("POST", url, JSON.stringify(body), headers);
  }

  private request<T>(
    method: RequestMethod,
    url: string,
    body?: BodyInit,
    headers?: any
  ): Promise<T | null> {
    return new Promise<T | null>(async (resolve, reject) => {
      try {
        const response = await request<T>(method, url, body, headers);
        resolve(response);
      } catch (e: unknown) {
        if (e instanceof ApiError) {
          if (e.statusCode === 403 || e.statusCode === 404) {
            // TODO: Maybe we want to handle these differently
            resolve(null);
            return;
          }
        }
        reject(e);
      }
    });
  }
}
