import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

const isAbleToDrawDown = (capitalAccountSummary: CapitalAccountSummaryRep.Complete) => {
  return (
    capitalAccountSummary.controls.drawdownEnabled &&
    capitalAccountSummary.available > 0 &&
    capitalAccountSummary.state === CapitalAccountRep.State.Active
  );
};

export default isAbleToDrawDown;
