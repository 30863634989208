import { useMutation } from "@tanstack/react-query";
import useMfa, { MfaCanceledError } from "modules/mfa/useMfa";
import SendPaymentCreatorRep from "reps/SendPaymentCreatorRep";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import usePaymentApprovalsQueryOptions from "../queries/usePaymentApprovalsQueryOptions";

type Variables = SendPaymentCreatorRep.Creator & {
  paymentGuid: string;
};

const useApprovePaymentMutation = () => {
  const highbeamApi = useHighbeamApi();
  const { mfa } = useMfa();
  const refreshPaymentApprovalsQuery = useRefreshQuery(
    usePaymentApprovalsQueryOptions("Open").queryKey
  );

  return useMutation({
    mutationFn: async ({ paymentGuid, ...creator }: Variables) => {
      await mfa();
      return highbeamApi.paymentAction.send(paymentGuid, creator);
    },
    onError: (err) => {
      if (err instanceof MfaCanceledError) return;
      notify("error", "There was an error approving the payment. Please try again.");
    },
    onSuccess: (payment) => {
      if (payment.status === "Open" && payment.reason) {
        notify("error", `Payment rejected: ${payment.reason}`);
      } else {
        notify("success", "Payment approved and sent");
      }
      refreshPaymentApprovalsQuery();
    },
  });
};

export default useApprovePaymentMutation;
