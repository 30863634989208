import TransactionStatusPill from "components/Transactions/TransactionStatusPill";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { HighbeamTransaction } from "utils/types/transactionsTypes";

import styles from "./TransactionDetailBubble.module.scss";

type Props = {
  transaction: HighbeamTransaction;
};

const TransactionDetailBubble: React.FC<Props> = ({ transaction }) => (
  <div className={styles.container}>
    <TransactionStatusPill transaction={transaction} showCompleted />
    <Text size={14} weight="medium" color={colors.grey[800]}>
      {transaction.longMethodName}
    </Text>
  </div>
);

export default TransactionDetailBubble;
