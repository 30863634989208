import { PayeeInternationalWirePaymentMethod } from "../queries/usePayeePaymentMethods";

/**
 * Use this if you need to display some kind of account number for an international wire payment method.
 */
const getDisplayAccountNumberForInternationalPaymentMethod = (
  internationalWirePaymentMethod: PayeeInternationalWirePaymentMethod
) => {
  // NB(alex): Feel free to modify it if needed, I haven't tested every case.
  return (
    internationalWirePaymentMethod.swift ||
    internationalWirePaymentMethod.iban ||
    internationalWirePaymentMethod.ifsc || // INR
    internationalWirePaymentMethod.cnaps ||
    internationalWirePaymentMethod.accountNumber ||
    ""
  );
};

export default getDisplayAccountNumberForInternationalPaymentMethod;
