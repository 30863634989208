import { useMutation } from "@tanstack/react-query";
import env from "env";
import BankAccountRep from "reps/BankAccountRep";
import useUnitCoCustomerToken from "resources/unit-co-customer-token/queries/useUnitCoCustomerToken";
import { notify } from "ui/feedback/Toast";
import { downloadBlob } from "utils/download";
import useIsAllowedToViewAccountNumbers from "utils/permissions/useIsAllowedToViewAccountNumbers";

const useDownloadBankVerificationLetterMutation = () => {
  const unitCoCustomerToken = useUnitCoCustomerToken();
  const isAllowedToViewAccountNumbers = useIsAllowedToViewAccountNumbers();

  return useMutation({
    mutationFn: async (account: BankAccountRep.Complete) => {
      // NB(alex): context https://highbeamco.slack.com/archives/C059DBA38M8/p1692722675751319
      if (!isAllowedToViewAccountNumbers) {
        return notify(
          "error",
          "You don’t have permission to download the bank verification letter."
        );
      }

      const res = await fetch(
        `${env.UNIT_API_ORIGIN}/statements/${account.unitCoDepositAccountId}/bank/pdf`,
        {
          headers: {
            Authorization: `Bearer ${unitCoCustomerToken}`,
          },
        }
      );
      const blob = await res.blob();
      downloadBlob("account_verification_letter.pdf", window.URL.createObjectURL(blob));
    },
  });
};

export default useDownloadBankVerificationLetterMutation;
