import {
  DotsThreeVertical as DotsThreeVerticalIcon,
  EnvelopeSimple as EnvelopeSimpleIcon,
  Gear as GearIcon,
} from "@phosphor-icons/react";
import FeatureStatusIndicator from "components/FeatureStatusIndicator";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { FC, useState, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useApEmailAliases from "resources/ap-email-aliases/queries/useApEmailAliases";
import AllBillsView from "resources/bills/components/AllBillsView";
import AssignedGrantedBillsView from "resources/bills/components/AssignedGrantedBillsView";
import AssignedRequestedBillsView from "resources/bills/components/AssignedRequestedBillsView";
import BillsEmailInboxView from "resources/bills/components/BillsEmailInboxView";
import CreateBillButton from "resources/bills/components/CreateBillButton";
import useShouldShowEmployeeView from "resources/bills/hooks/useShouldShowEmployeeView";
import useCreateBillMutation from "resources/bills/mutations/useCreateBillMutation";
import { useAssignedRequestedBills } from "resources/bills/queries/assignedBillsQueryHooks";
import { useDraftBillsQuery } from "resources/bills/queries/useBills";
import { notify } from "ui/feedback/Toast";
import BillPayIcon from "ui/icons/BillPayIcon";
import Button from "ui/inputs/Button";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import SegmentControls from "ui/navigation/SegmentControls";
import TabsV2 from "ui/navigation/TabsV2";
import Menu from "ui/overlay/Menu";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useHasPermission from "utils/permissions/useHasPermission";
import useSearchParamOption from "utils/search-params/useSearchParamOption";
import { TabConfig } from "utils/tabs/useTabState";
import getObjectKeys from "utils/ts/getObjectKeys";

import BillDetailsFullPageModal from "../BillDetailsFullPageModal";
import BillPaymentFullPageModal from "../BillPaymentFullPageModal";
import BillPayOnboardingModal from "../BillPayOnboardingModal";
import EmailSettingsModal from "../EmailSettingsModal";
import LaunchHeroBanner from "../LaunchHeroBanner";
import LaunchHowItWorks from "../LaunchHowItWorks";

import styles from "./BillsPage.module.scss";

const BillsPageBillDetailsFullPageModal: FC = () => {
  const { billId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const backToPath = "/bills";

  // Should never happen, just here for type-safety.
  if (!billId) {
    return <Navigate to={`${backToPath}?${searchParams}`} />;
  }

  return (
    <BillDetailsFullPageModal
      billId={billId}
      onClose={() => {
        // Do not preserve tabs specific to the bill info details and documents views.
        searchParams.delete("detail-tab");
        searchParams.delete("bill-info-form-tab");
        searchParams.delete("payment-details");
        searchParams.delete("document");
        searchParams.delete("payment"); // Payment info flexpane (for bill related payments).
        navigate(`${backToPath}?${searchParams}`);
      }}
    />
  );
};

const BillsPageBillPaymentFullPageModal: FC = () => {
  const [searchParams] = useSearchParams();
  const billIds = searchParams.getAll("bills");
  const navigate = useNavigate();

  return (
    <BillPaymentFullPageModal
      billIds={billIds}
      onClose={() => {
        searchParams.delete("bills");
        navigate(`/bills?${searchParams}`);
      }}
      onCancel={() => navigate(-1)}
    />
  );
};

const BillsDashboardBreadcrumbs: FC = () => (
  <Breadcrumbs>
    <Breadcrumbs.CurrentItem>Bill Pay</Breadcrumbs.CurrentItem>
  </Breadcrumbs>
);

const BillsDashboardHeaderContent: FC = () => (
  <>
    <DashboardPage.Header.IconTile icon={<BillPayIcon />} />
    <DashboardPage.Header.Title>Bill Pay</DashboardPage.Header.Title>
    <FeatureStatusIndicator featureStatus="Beta" />
  </>
);

type UseApprovalsTabsOptions = {
  showNeedApprovalCount?: boolean;
};

const useApprovalsTabs = (options?: UseApprovalsTabsOptions) => {
  const { showNeedApprovalCount = true } = options ?? {};
  const assignedRequestedBills = useAssignedRequestedBills();

  const tabConfig = useMemo(
    () =>
      ({
        "need-approval": {
          label: "Need approval",
          count: showNeedApprovalCount ? assignedRequestedBills.length : undefined,
        },
        approved: { label: "Approved" },
      }) satisfies TabConfig,
    [assignedRequestedBills, showNeedApprovalCount]
  );

  const [activeTab, setActiveTab] = useSearchParamOption(
    "approvals-tab",
    getObjectKeys(tabConfig),
    "need-approval"
  );

  return {
    tabConfig,
    activeTab,
    setActiveTab,
  } as const;
};

type ApprovalsSegmentControlsProps = ReturnType<typeof useApprovalsTabs>;

const ApprovalsSegmentControls: FC<ApprovalsSegmentControlsProps> = ({
  tabConfig,
  activeTab,
  setActiveTab,
}) => (
  <SegmentControls
    tabs={tabConfig}
    activeTab={activeTab}
    setActiveTab={setActiveTab}
    size="md"
    className="tablet:w-fit"
  />
);

const YourApprovalsView: FC = () => {
  const { tabConfig, activeTab, setActiveTab } = useApprovalsTabs({ showNeedApprovalCount: false });

  return (
    <>
      <ApprovalsSegmentControls
        tabConfig={tabConfig}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <div className="mt-8">
        {activeTab === "need-approval" && <AssignedRequestedBillsView />}
        {activeTab === "approved" && <AssignedGrantedBillsView />}
      </div>
    </>
  );
};

const ActivatedFullView: FC = () => {
  const isManualBillCreationEnabled = useFeatureFlag("BILL_PAY_MANUAL_BILL_CREATION");
  const isBillPaySettingsEnabled = useFeatureFlag("BILL_PAY_SETTINGS_UI");
  const isAllowedToNavigateToBillPaySettings = useHasPermission("accountsPayableSettings:read");

  const navigate = useNavigate();
  const { search } = useLocation();

  const { data: draftBills } = useDraftBillsQuery();
  const emailInboxCount = draftBills?.length;

  const assignedRequestedBills = useAssignedRequestedBills();

  const billsPageTabs = {
    "all-bills": { label: "All bills" },
    "email-inbox": { label: "Email inbox", count: emailInboxCount || undefined },
    approvals: { label: "Your approvals", count: assignedRequestedBills.length },
  } satisfies TabConfig;

  const [activeTab, setActiveTab] = useSearchParamOption(
    "tab",
    getObjectKeys(billsPageTabs),
    "all-bills"
  );

  const { mutate: createBill, isPending } = useCreateBillMutation({
    onSuccess: (bill) => {
      navigate(`/bills/${bill.id}${search}`);
    },
  });

  const makeCreateBillVariables = () => ({
    amount: null,
    invoiceDate: null,
    invoiceDueDate: null,
    invoiceNumber: null,
    payeeGuid: null,
    memo: null,
    paymentTerms: null,
    purchaseOrderNumber: null,
  });

  const [isEmailSettingsOpen, setIsEmailSettingsOpen] = useState(false);

  return (
    <>
      <Routes>
        <Route path="/:billId" element={<BillsPageBillDetailsFullPageModal />} />
        <Route path="/payment" element={<BillsPageBillPaymentFullPageModal />} />
      </Routes>

      <DashboardHeader>
        <BillsDashboardBreadcrumbs />
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header
          actions={
            <div className={styles.headerActionsCtaContainer}>
              {isManualBillCreationEnabled && (
                <CreateBillButton
                  isLoading={isPending}
                  onClick={() => createBill(makeCreateBillVariables())}
                />
              )}
              <Menu
                button={
                  <Button variant="tertiary" paddingVariant="square">
                    <DotsThreeVerticalIcon size={20} />
                  </Button>
                }
                placement={{ top: "calc(100% - 4px)" }}
                className="ml-auto"
              >
                <Menu.Item
                  icon={<EnvelopeSimpleIcon />}
                  onClick={() => setIsEmailSettingsOpen(true)}
                >
                  Email settings
                </Menu.Item>
                {/*
                NB(lev): The initial version of the Bill pay settings page is limited to accounting settings.
                Eventually, we will present this as just "Bill pay settings" and "Email settings" will be
                folded into the Bill pay settings page as well.
                */}
                {isBillPaySettingsEnabled && isAllowedToNavigateToBillPaySettings && (
                  <Menu.Item icon={<GearIcon />} linkTo="/bills/settings">
                    Accounting settings
                  </Menu.Item>
                )}
              </Menu>
            </div>
          }
        >
          <BillsDashboardHeaderContent />
        </DashboardPage.Header>

        <TabsV2 activeTab={activeTab} setActiveTab={setActiveTab} tabs={billsPageTabs} />

        <DashboardPage.Section>
          {activeTab === "all-bills" && (
            <AllBillsView
              createBill={() => createBill(makeCreateBillVariables())}
              isCreatingBill={isPending}
              openEmailSettings={() => setIsEmailSettingsOpen(true)}
            />
          )}
          {activeTab === "email-inbox" && (
            <BillsEmailInboxView openEmailSettings={() => setIsEmailSettingsOpen(true)} />
          )}
          {activeTab === "approvals" && <YourApprovalsView />}
        </DashboardPage.Section>
      </DashboardPage>

      {isEmailSettingsOpen && <EmailSettingsModal onClose={() => setIsEmailSettingsOpen(false)} />}
    </>
  );
};

const LaunchFullView: FC = () => {
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <DashboardHeader>
        <BillsDashboardBreadcrumbs />
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <BillsDashboardHeaderContent />
        </DashboardPage.Header>

        <DashboardPage.Section>
          <LaunchHeroBanner onGetStarted={() => setIsOnboardingModalOpen(true)} />
        </DashboardPage.Section>

        <DashboardPage.Section>
          <LaunchHowItWorks />
        </DashboardPage.Section>
      </DashboardPage>

      {isOnboardingModalOpen && (
        <BillPayOnboardingModal
          onClose={() => setIsOnboardingModalOpen(false)}
          onComplete={() => {
            notify("success", "Welcome to Highbeam Bill Pay!");
            setIsOnboardingModalOpen(false);
            navigate("/bills?tab=email-inbox");
          }}
        />
      )}
    </>
  );
};

const FullView: FC = () => {
  const apEmailAliases = useApEmailAliases();

  return apEmailAliases.length > 0 ? <ActivatedFullView /> : <LaunchFullView />;
};

const EmployeeView: FC = () => {
  const { tabConfig, activeTab, setActiveTab } = useApprovalsTabs();

  return (
    <>
      <Routes>
        <Route path="/:billId" element={<BillsPageBillDetailsFullPageModal />} />
      </Routes>

      <DashboardHeader>
        <BillsDashboardBreadcrumbs />
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.Title>Bill approvals</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <ApprovalsSegmentControls
          tabConfig={tabConfig}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        <DashboardPage.Section>
          {activeTab === "need-approval" && <AssignedRequestedBillsView />}
          {activeTab === "approved" && <AssignedGrantedBillsView />}
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

const BillsPage: FC = () => {
  const shouldShowEmployeeView = useShouldShowEmployeeView();

  return shouldShowEmployeeView ? <EmployeeView /> : <FullView />;
};

export default BillsPage;
