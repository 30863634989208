import { FC, ReactNode } from "react";
import PageIndicator from "ui/navigation/PageIndicator";

import DateRangePickers, { FromDatePicker, ToDatePicker } from "./DateRangePickers";
import Dropdown from "./Dropdown";
import ExportButton from "./ExportButton";
import Filters from "./Filters";
import FilterTabs from "./FilterTabs";
import Footer from "./Footer";
import PaginationInfo from "./PaginationInfo";
import SearchBar from "./SearchBar";
import Title from "./Title";
import TransactionsTable from "./TransactionsTable";

type Props = {
  title?: ReactNode;
  filters?: ReactNode;
  table: ReactNode;
  footer?: ReactNode;
  transactionFlexpane?: ReactNode;
};

const TransactionsV2: FC<Props> = ({ title, filters, table, footer, transactionFlexpane }) => (
  <>
    <div>
      {title}
      {filters}
      {table}
      {footer}
    </div>
    {transactionFlexpane}
  </>
);

export default Object.assign(TransactionsV2, {
  Title: Title,
  Filters: Filters,
  Dropdown: Dropdown,
  SearchBar: SearchBar,
  DateRangePickers: DateRangePickers,
  FromDatePicker: FromDatePicker,
  ToDatePicker: ToDatePicker,
  PaginationInfo: PaginationInfo,
  FilterTabs: FilterTabs,
  ExportButton: ExportButton,
  TransactionsTable: TransactionsTable,
  Footer: Footer,
  PageIndicator: PageIndicator,
});
