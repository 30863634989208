import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useInsightsCategoriesQueryOptions = () => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: ["insights-categories"],
    queryFn: () => {
      return highbeamApi.insightsCategory.search();
    },
  });
};

export default useInsightsCategoriesQueryOptions;
