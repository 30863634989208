import { Check, CircleDashed } from "@phosphor-icons/react";
import { FC } from "react";
import BillSummaryRep from "reps/BillSummaryRep";
import Pill from "ui/data-display/Pill";

type Props = {
  bill: BillSummaryRep.Complete;
};

const BillApprovalStatusPill: FC<Props> = ({ bill }) => {
  switch (bill.approvalSummary.status) {
    case BillSummaryRep.ApprovalStatus.Approved:
      return (
        <Pill
          color="green-light"
          iconLeft={({ sizeClassName }) => <Check className={sizeClassName} />}
        >
          Approved
        </Pill>
      );
    case BillSummaryRep.ApprovalStatus.Partial:
      return (
        <Pill color="grey-light">
          {bill.approvalSummary.numberOfApprovals} of{" "}
          {bill.approvalSummary.numberOfApprovalsRequested} approved
        </Pill>
      );
    case BillSummaryRep.ApprovalStatus.Unassigned:
      return (
        <Pill
          color="grey-light"
          iconLeft={({ sizeClassName }) => <CircleDashed className={sizeClassName} />}
        >
          Unassigned
        </Pill>
      );
  }
};

export default BillApprovalStatusPill;
