import { atom } from "recoil";
import { Entity, EntityCompany } from "utils/entity";

const defaultEntity: Entity = {
  entityType: EntityCompany,
};

export const internationalEntityState = atom<Entity>({
  key: "payments/international/internationalEntity",
  default: defaultEntity,
});
