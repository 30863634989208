import classNames from "classnames";
import { useEffect, useState } from "react";
import Dropdown from "ui/inputs/Dropdown";
import TextInput, { Filter } from "ui/inputs/TextInput";
import { Address, stateOptions } from "utils/address";
import { useAddressAutocomplete } from "utils/customHooks/useAddressAutocomplete";

import styles from "./PayeeLegalAddress.module.scss";

type Props = {
  address: Address;
  setAddress: (address: Address) => void;
  setHasLegalAddressError?: (hasLegalAddressError: boolean) => void;
};

const PayeeLegalAddress: React.FC<Props> = ({
  address,
  setAddress,
  setHasLegalAddressError = undefined,
}) => {
  const [isValidZip, setIsValidZip] = useState<boolean>(false);
  const [showZipError, setShowZipError] = useState<boolean>(false);

  const {
    streetAddressInput,
    autoCompleteHandleChange,
    autoCompleteHandleInputChange,
    placeId,
    addressSuggestions,
    isPlacePredictionsLoading,
  } = useAddressAutocomplete(address, setAddress);

  useEffect(() => {
    setHasLegalAddressError?.(!isValidZip);
  }, [isValidZip, setHasLegalAddressError]);

  useEffect(() => {
    setIsValidZip(address.zipCode.length === 5 || address.zipCode.length === 9);
  }, [address.zipCode, setIsValidZip]);

  useEffect(() => () => setHasLegalAddressError?.(false), [setHasLegalAddressError]);

  return (
    <fieldset>
      <div className={styles.container}>
        <legend className={styles.heading}>Payee’s legal address</legend>
        <div className={styles.body}>
          <div className={styles.row}>
            <Dropdown
              value={address.addressLine1}
              inputValue={streetAddressInput}
              onChange={autoCompleteHandleChange}
              onInputChange={autoCompleteHandleInputChange}
              onBlur={() => {
                if (placeId || !streetAddressInput) {
                  return;
                }

                setAddress({
                  ...address,
                  addressLine1: { label: streetAddressInput, value: streetAddressInput },
                });
              }}
              onClear={() => {
                setAddress({
                  ...address,
                  addressLine1: null,
                });
              }}
              isClearable
              options={addressSuggestions}
              filterOption={() => true} // always show all options that autocomplete provides
              label="Street address"
              isLoading={isPlacePredictionsLoading}
              hideCaret
              hideCursor={address.addressLine1 !== null}
            />
          </div>
          <div className={styles.row}>
            <TextInput
              type="text"
              value={address.addressLine2}
              onChange={(addressLine2) => setAddress({ ...address, addressLine2 })}
              label="Address line 2"
            />
          </div>
          <div className={classNames(styles.row, styles.flexRow)}>
            <TextInput
              type="text"
              value={address.city}
              onChange={(city) => setAddress({ ...address, city })}
              className={styles.subrow}
              label="City"
            />
            <div className={styles.subrow}>
              <Dropdown
                onChange={(state) => setAddress({ ...address, state })}
                value={address.state}
                label="State"
                options={stateOptions}
              />
            </div>
            <TextInput
              type="text"
              value={address.zipCode}
              onChange={(zipCode) => setAddress({ ...address, zipCode })}
              onBlur={() => setShowZipError(true)}
              onFocus={() => setShowZipError(false)}
              className={classNames(styles.subrow)}
              label="ZIP"
              hasError={showZipError && !isValidZip}
              maxLength={9}
              inputFilter={Filter.DIGITS}
            />
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default PayeeLegalAddress;
