import { Check, CheckCircle } from "@phosphor-icons/react";
import classNames from "classnames";
import { ComponentProps, ReactNode } from "react";
import { Link } from "react-router-dom";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./Step.module.scss";

type StepState = "active" | "incomplete" | "done" | "success";
type StepVariant = "dashed" | "solid";

type StepProps = {
  children: ReactNode;
  state: StepState;
  number?: number;
  variant?: StepVariant;
  isLast?: boolean;
  to?: ComponentProps<typeof Link>["to"];
};

const Step: React.FC<StepProps> = ({ number, children, state, isLast, to, variant = "dashed" }) => {
  const LinkComponent = to ? Link : "div";
  const stepType = number !== undefined ? styles.number : styles.bullet;

  return (
    <LinkComponent
      to={to!} // `to` is always defined if `LinkComponent` is a `Link`
      className={classNames(styles.step, styles[state], isLast && styles.last)}
    >
      {!isLast && <div className={styles[variant]} />}

      <div className={styles.titleContainer}>
        <div className={stepType}>
          {state === "done" ? (
            <Check weight="bold" />
          ) : state === "success" ? (
            <CheckCircle size={16} weight="bold" color={colors.green[600]} />
          ) : (
            number
          )}
        </div>

        <Text className={styles.title} size={14}>
          {children}
        </Text>
      </div>
    </LinkComponent>
  );
};

export default Step;
