import { useSuspenseQuery } from "@tanstack/react-query";
import bankAccountsQueryHooks, {
  ROOT_BANK_ACCOUNTS_QUERY_KEY,
} from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import getTotalBalanceOfBankAccounts from "resources/bank-accounts/utils/getTotalBalanceOfBankAccounts";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import { startOfBankingDay } from "utils/date";

import getHistoryWithTodayForMultipleAccounts, {
  BANK_ACCOUNT_NUM_DAYS_HISTORY,
} from "../utils/getHistoryWithTodayForMultipleAccounts";

const useBankAccountBalanceHistory = () => {
  const unitApi = useUnitApi();

  const openBankAccounts = bankAccountsQueryHooks.useData({ status: "open" });

  const bankAccountsToIncludeInTotalBalance = openBankAccounts.filter(
    (account) => account.highbeamType.includeInTotalBalance
  );

  const { data } = useSuspenseQuery({
    queryKey: [ROOT_BANK_ACCOUNTS_QUERY_KEY],
    queryFn: async () => {
      const responses = await Promise.all(
        bankAccountsToIncludeInTotalBalance.map((bankAccount) =>
          unitApi.accountsEndOfDay.list({
            since: startOfBankingDay()
              .subtract(BANK_ACCOUNT_NUM_DAYS_HISTORY, "days")
              .format("YYYY-MM-DD"),
            accountId: bankAccount.unitCoDepositAccountId,
          })
        )
      );

      // Due to time zone discrepancies between the local client and Unit.co's backend, this can
      // return more than BANK_ACCOUNT_NUM_DAYS_HISTORY. However, we should only show the last
      // BANK_ACCOUNT_NUM_DAYS_HISTORY.
      const histories = responses.map((unitAccountEndOfDay) =>
        unitAccountEndOfDay.data.slice(0, BANK_ACCOUNT_NUM_DAYS_HISTORY)
      );

      const bankAccountsTotalBalance = getTotalBalanceOfBankAccounts(openBankAccounts);
      const historyWithToday = getHistoryWithTodayForMultipleAccounts(
        histories,
        bankAccountsTotalBalance
      );

      return historyWithToday;
    },
  });

  return data;
};

export default useBankAccountBalanceHistory;
