import { AccountLimitInfo, LimitWarning, AccountLimitWarnings } from "../types";

const LIMIT_APPROACHING_THRESHOLD = 0.9;

const makeLimitWarningOrNull = (
  isExceeeded: boolean,
  isAt: boolean,
  isApproaching: boolean
): LimitWarning | null => {
  if (isExceeeded) return "exceeded";
  if (isAt) return "at";
  if (isApproaching) return "approaching";
  return null;
};

const makeAccountLimitWarnings = (
  accountLimitInfo: AccountLimitInfo,
  amountInCents: number,
  approachingThreshold = LIMIT_APPROACHING_THRESHOLD
): AccountLimitWarnings => {
  const {
    achDailyCreditRemaining,
    achMonthlyCreditRemaining,
    wireDailyTransferRemaining,
    wireMonthlyTransferRemaining,
  } = accountLimitInfo;

  const achDailyCreditLimitExceeded = amountInCents > achDailyCreditRemaining;
  const achAtDailyCreditLimit = amountInCents === achDailyCreditRemaining;
  const achDailyCreditLimitApproaching =
    amountInCents < achDailyCreditRemaining &&
    amountInCents >= achDailyCreditRemaining * approachingThreshold;

  const achMonthlyCreditLimitExceeded = amountInCents > achMonthlyCreditRemaining;
  const achAtMonthlyCreditLimit = amountInCents === achMonthlyCreditRemaining;
  const achMonthlyCreditLimitApproaching =
    amountInCents < achMonthlyCreditRemaining &&
    amountInCents >= achMonthlyCreditRemaining * approachingThreshold;

  const wireDailyTransferLimitExceeded = amountInCents > wireDailyTransferRemaining;
  const wireAtDailyTransferLimit = amountInCents === wireDailyTransferRemaining;
  const wireDailyTransferLimitApproaching =
    amountInCents < wireDailyTransferRemaining &&
    amountInCents >= wireDailyTransferRemaining * approachingThreshold;

  const wireMonthlyTransferLimitExceeded = amountInCents > wireMonthlyTransferRemaining;
  const wireAtMonthlyTransferLimit = amountInCents === wireMonthlyTransferRemaining;
  const wireMonthlyTransferLimitApproaching =
    amountInCents < wireMonthlyTransferRemaining &&
    amountInCents >= wireMonthlyTransferRemaining * approachingThreshold;

  return {
    accountLimitInfo,
    achDailyCreditWarning: makeLimitWarningOrNull(
      achDailyCreditLimitExceeded,
      achAtDailyCreditLimit,
      achDailyCreditLimitApproaching
    ),
    achMonthlyCreditWarning: makeLimitWarningOrNull(
      achMonthlyCreditLimitExceeded,
      achAtMonthlyCreditLimit,
      achMonthlyCreditLimitApproaching
    ),
    wireDailyTransferWarning: makeLimitWarningOrNull(
      wireDailyTransferLimitExceeded,
      wireAtDailyTransferLimit,
      wireDailyTransferLimitApproaching
    ),
    wireMonthlyTransferWarning: makeLimitWarningOrNull(
      wireMonthlyTransferLimitExceeded,
      wireAtMonthlyTransferLimit,
      wireMonthlyTransferLimitApproaching
    ),
  };
};

export default makeAccountLimitWarnings;
