import TransactionFlexpaneCounterparty from "components/common/transaction-flexpane/TransactionFlexpaneCounterparty";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { formatBankingDate } from "utils/date";
import { getReceivedAchPaymentCounterparty } from "utils/receivedPayments";

import { useReceivedPaymentInfoFlexpaneContext } from "../../context/ReceivedPaymentInfoFlexpaneProvider";

const CounterpartyDetail = () => {
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();
  const counterparty = getReceivedAchPaymentCounterparty(receivedPayment);

  return (
    <>
      <TransactionFlexpaneCounterparty>
        {counterparty.formattedName ?? counterparty.name}
      </TransactionFlexpaneCounterparty>

      <Text size={14} color={colors.grey[500]}>
        {formatBankingDate(receivedPayment.attributes.createdAt)}
      </Text>
    </>
  );
};
export default CounterpartyDetail;
