import { atom, selector, useRecoilValue } from "recoil";
import { booleanLocalStorageEffect } from "utils/recoil";

import isAuthenticatedState from "./isAuthenticated";

export const localStorageIsSuperuseringState = atom<boolean>({
  key: "auth/localStorageIsSuperusering",
  default: false,
  effects: [booleanLocalStorageEffect("isSuperusering")],
});

const isSuperuseringState = selector<boolean>({
  key: "auth/isSuperusering",
  get: async ({ get }) => {
    const isAuthenticated = get(isAuthenticatedState);
    const localStorageIsSuperusering = get(localStorageIsSuperuseringState);

    return isAuthenticated && localStorageIsSuperusering;
  },
  set: ({ set }, newValue) => {
    set(localStorageIsSuperuseringState, newValue);
  },
});

export default isSuperuseringState;

export const useIsSuperusering = () => {
  return useRecoilValue(isSuperuseringState);
};
