import BillSummaryRep from "reps/BillSummaryRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillSummaryApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(billId: string): Promise<BillSummaryRep.Complete | null> {
    const url = `/accounts-payable/bills/${billId}/summary`;
    return await this.api.get<BillSummaryRep.Complete>(url);
  }

  async search(businessGuid: string): Promise<BillSummaryRep.Complete[]> {
    const qp = new URLSearchParams({ businessGuid: businessGuid });

    const url = `/accounts-payable/bills/summary?${qp}`;
    return (await this.api.get<BillSummaryRep.Complete[]>(url))!;
  }
}
