import { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useFinancialInstitutionQuery } from "resources/financial-institutions/queries/useFinancialInstitution";
import useAchPaymentMethodForm from "resources/payee-payment-methods/forms/useAchPaymentMethodForm";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import Helper from "ui/inputs/Helper";
import TextInputV2 from "ui/inputs/TextInputV2";
import { ROUTING_NUMBER_LENGTH } from "utils/zod/routingNumberSchema";

import RoutingNumberInput from "../RoutingNumberInput";

type Props = {
  form: ReturnType<typeof useAchPaymentMethodForm>;
};

const AchPaymentMethodFieldset: FC<Props> = ({ form }) => {
  const { control } = form;

  // NB(alex): This is basically duplicate code from `DomesticWirePaymentMethodFieldset`. I'd like to figure out a better pattern, but gotta ship this for now.
  const routingNumber = form.watch("routingNumber");
  const financialInstitutionQueryEnabled = routingNumber.length >= ROUTING_NUMBER_LENGTH;

  const { data: financialInstitution, isFetching: isFetchingFinancialInstitution } =
    useFinancialInstitutionQuery({
      routingNumber: routingNumber,
      enabled: financialInstitutionQueryEnabled,
    });

  // Trigger routing number validation once the query is enabled to see if the institution is valid.
  useEffect(() => {
    if (financialInstitutionQueryEnabled && financialInstitution !== undefined) {
      form.trigger("routingNumber");
    } else {
      form.clearErrors("routingNumber");
    }
  }, [
    form,
    financialInstitutionQueryEnabled,
    financialInstitution, // Ensures form validation is re-triggered if this value changes.
  ]);

  return (
    <FieldsetV2>
      <Controller
        control={control}
        name="routingNumber"
        render={({ field, fieldState: { error } }) => {
          return (
            <div>
              <RoutingNumberInput
                financialInstitution={financialInstitution ?? null}
                isFetchingFinancialInstitution={isFetchingFinancialInstitution}
                {...field}
              />
              {error && <Helper iconVariant="error">{error.message}</Helper>}
            </div>
          );
        }}
      />
      <Controller
        control={control}
        name="accountNumber"
        render={({ field, fieldState: { error } }) => {
          return (
            <div>
              <TextInputV2 label="Account number" inputMode="numeric" {...field} />
              {error && <Helper iconVariant="error">{error.message}</Helper>}
            </div>
          );
        }}
      />
    </FieldsetV2>
  );
};

export default AchPaymentMethodFieldset;
