import dayjs from "dayjs";
import {
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useRecoilState } from "recoil";
import { invoiceDateState } from "state/payments/international/invoiceDate";
import DatePicker from "ui/inputs/DatePicker";

import styles from "./InternationalInvoiceDate.module.scss";

const InternationalInvoiceDate = () => {
  const input = INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.INVOICE_DATE];
  const [invoiceDate, setInvoiceDate] = useRecoilState(invoiceDateState);

  return (
    <div className={styles.invoiceDate}>
      <DatePicker
        value={invoiceDate && invoiceDate.toDate()}
        onChange={(date) => setInvoiceDate(dayjs(date))}
        label={input.label}
        variant="no-date"
      />
    </div>
  );
};
export default InternationalInvoiceDate;
