import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";
import { HighbeamDishonoredAchTransaction } from "utils/types/transactionsTypes";

import TraceNumberLabel from "./TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";

type Props = {
  transaction: HighbeamDishonoredAchTransaction;
};

const DishonoredAchTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>Returned ACH details</Heading3>
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.description}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.counterpartyRoutingNumber}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>
          <TraceNumberLabel />
        </MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.traceNumber}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Reason</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.reason}</MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default DishonoredAchTransactionDetailsSection;
