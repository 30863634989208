import { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import {
  Address,
  AddressSuggestionOption,
  useGooglePlacesAutocomplete,
  US_COUNTRY_OPTION,
} from "utils/address";

export const useAddressAutocomplete = (
  address: Address,
  setAddress: (address: Address) => void
) => {
  const [streetAddressInput, setStreetAddressInput] = useState<string>("");
  const [placeId, setPlaceId] = useState<string>();

  const {
    addressSuggestions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    getPlaceDetails,
    resetAddressSuggestions,
  } = useGooglePlacesAutocomplete();

  useEffect(() => {
    const setDetails = (placeDetails: google.maps.places.PlaceResult) => {
      const autofill: Address = {
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: "",
        state: null,
        zipCode: "",
        country: address.country,
      };
      placeDetails.address_components?.forEach((component) => {
        if (component.types.includes("locality") || component.types.includes("sublocality")) {
          autofill.city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          autofill.state = { label: component.long_name, value: component.short_name };
        }
        if (component.types.includes("postal_code")) {
          autofill.zipCode = component.long_name;
        }
      });
      setAddress(autofill);
    };

    getPlaceDetails(placeId, setDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  const autoCompleteHandleInputChange = (_streetAddressInput: string) => {
    if (placeId) {
      setPlaceId(undefined);
    }
    getPlacePredictions({
      input: _streetAddressInput,
      componentRestrictions: { country: address.country?.value || US_COUNTRY_OPTION.value },
    });
    setStreetAddressInput(_streetAddressInput);
  };

  const autoCompleteHandleChange = (opt: SingleValue<AddressSuggestionOption>) => {
    setAddress({ ...address, addressLine1: { ...opt!, label: opt!.value } });
    setPlaceId(opt!.placeId);
  };

  return {
    streetAddressInput,
    autoCompleteHandleChange,
    autoCompleteHandleInputChange,
    placeId,
    addressSuggestions,
    isPlacePredictionsLoading,
    resetAddressSuggestions,
  };
};
