import { DefaultValue, selectorFamily } from "recoil";

import offerInputsState from "./offerInputs";

const remittanceRateState = selectorFamily<string, number>({
  key: "compareCreditOffers/remittanceRateState",
  get:
    (offerIndex) =>
    ({ get }) => {
      const offerInputs = get(offerInputsState);
      const remittanceRate = offerInputs[offerIndex].remittanceRate;
      return remittanceRate === null ? "" : remittanceRate.toString();
    },
  set:
    (offerIndex) =>
    ({ set }, newValue) => {
      set(offerInputsState, (prev) => [
        ...prev.slice(0, offerIndex),
        {
          ...prev[offerIndex],
          remittanceRate:
            newValue === "" || newValue instanceof DefaultValue ? null : parseInt(newValue),
        },
        ...prev.slice(offerIndex + 1),
      ]);
    },
});

export default remittanceRateState;
