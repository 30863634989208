import UserNotificationSettingsRep from "reps/UserNotificationSettingsRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class UserNotificationSettingsApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(userGuid: string): Promise<UserNotificationSettingsRep.Complete> {
    const url = `/users/${userGuid}/notification-settings`;
    return (await this.api.get<UserNotificationSettingsRep.Complete>(url))!;
  }

  async update(
    userGuid: string,
    update: UserNotificationSettingsRep.Update
  ): Promise<UserNotificationSettingsRep.Complete | null> {
    const url = `/users/${userGuid}/notification-settings`;
    return await this.api.patch<UserNotificationSettingsRep.Complete>(url, update);
  }
}
