import { FC, ReactNode, createContext, useContext } from "react";

type RecurringPaymentFlexpaneContextState = {
  recurringPaymentId: string;
  close: () => void;
};

const RecurringPaymentFlexpaneContext = createContext<RecurringPaymentFlexpaneContextState>(
  {} as RecurringPaymentFlexpaneContextState
);

type Props = {
  children: ReactNode;
  recurringPaymentId: string;
  close: () => void;
};

const RecurringPaymentFlexpaneProvider: FC<Props> = ({ children, recurringPaymentId, close }) => {
  return (
    <RecurringPaymentFlexpaneContext.Provider
      value={{
        recurringPaymentId: recurringPaymentId,
        close: close,
      }}
    >
      {children}
    </RecurringPaymentFlexpaneContext.Provider>
  );
};

export default RecurringPaymentFlexpaneProvider;

export const useRecurringPaymentFlexpaneContext = () => {
  return useContext(RecurringPaymentFlexpaneContext);
};
