/* eslint sort-keys: ["error", "asc", { "natural": true }] */

/**
 * Boolean, on/off flags
 */
export const PERMANENT_FEATURE_FLAGS = {
  ALLOW_STORE_DISCONNECTION_IN_SETTINGS: "allow-store-disconnection-in-settings",
  CLOSE_BANK_ACCOUNT: "close-bank-account",
  CREDIT_ALLOW_SKIP_STORES: "credit-allow-skip-stores",
  REQUIRE_STORE_CONNECTION_IN_ONBOARDING: "require-store-connection-in-onboarding",
  SETTINGS_ALLOW_ACCOUNTING_SOFTWARE_CONNECTION: "settings-allow-accounting-software-connection",
};

export const TEMPORARY_FEATURE_FLAGS = {
  ALLOW_CA_CREDIT_APPLICATIONS: "capital-allow-ca-credit-applications",
  BILL_PAY_LINE_ITEMS_UI: "bill-pay-line-items-ui",
  BILL_PAY_MANUAL_BILL_CREATION: "bill-pay-manual-bill-creation",
  BILL_PAY_SETTINGS_UI: "bill-pay-settings-ui",
  BILL_PAY_UI: "bill-pay-ui",
  CAPITAL_ACCOUNT_CHARGE_CARD_DETAILS_V2: "capital-account-charge-card-details-v2",
  CAPITAL_DRAWDOWN_LARGE_AMOUNT_BANNER_TEXT: "capital-drawdown-large-amount-banner-text",
  CAPITAL_DRAWDOWN_LARGE_AMOUNT_PENDING_IN_CENTS: "capital-drawdown-large-amount-pending-in-cents",
  CAPITAL_OVERVIEW_PAGE: "capital-overview-page",
  CAPITAL_PROMOTIONAL_RATES: "capital-promotional-rates",
  CAPITAL_UNACCEPTED_AGREEMENT_FLOW: "capital-unaccepted-agreement-flow",
  CASH_FLOW_VIEW: "cash-flow-view",
  CHARGE_CARD_MULTIPLE_CAPITAL_ACCOUNTS_CARD_CREATION_MODAL:
    "charge-card-multipe-capital-accounts-card-creation-modal",
  CHAT_UI: "chat-ui",
  CHECKS_ALLOW_INVOICE_UPLOAD: "checks-allow-invoice-upload",
  CHECKS_SKIP_ENDORSEMENT_STEP: "checks-skip-endorsement-step",
  CONNECT_STORES_DURING_ONBOARDING: "connect-stores-during-onboarding",
  CREDIT_APPLICATION_REQUIREMENTS_MODAL: "credit-application-requirements-modal",
  DRAW_DOWN_WITH_APPROVAL_REQUIRED: "drawdown-with-approval-required",
  FAILED_DEBITS_UI: "failed-debits-ui",
  FORCE_MIGRATE_TO_THREAD: "force-migrate-to-thread",
  HIDE_CONNECTED_STORES: "hide-connected-stores",
  INCLUDE_ADJUSTMENT_TRANSACTIONS_AS_INTEREST: "include-adjustment-transactions-as-interest",
  MIGRATE_TO_THREAD: "migrate-to-thread",
  ONBOARDING_PERSONAL_INFORMATION_FORM: "onboarding-personal-information-form",
  PAYMENT_FLOW_ALLOW_BANK_ACCOUNT_UPDATE: "payment-flow-allow-bank-account-update",
  PAYMENT_SUMMARY_V2_IN_EARLY_PAY: "payment-summary-v-2-in-early-pay",
  PREVENT_ALL_DEPOSIT_ACCOUNT_CREATION: "prevent-all-deposit-account-creation",
  PREVENT_DACA_DEPOSIT_ACCOUNT_CREATION: "prevent-daca-deposit-account-creation",
  REFRESH_JWT_BEFORE_INITIAL_AUTHORIZATION: "refresh-jwt-before-initial-authorization",
  SAME_DAY_ACH_VIP: "same-day-ach-domestic-ach",
  SPECIAL_INTEREST_CUSTOMER: "prevent-interest-tier-switching",
};

export const FEATURE_FLAGS = {
  ...PERMANENT_FEATURE_FLAGS,
  ...TEMPORARY_FEATURE_FLAGS,
};

/**
 * String values
 */
export const FEATURE_VALUES = {
  example: "replace-me-this-is-here-for-type-safety",
};
