import { DialogTitle } from "@headlessui/react";
import closeIcon from "assets/close-icon.svg";
import { FC, ReactElement, ReactNode } from "react";
import { Heading2 } from "ui/typography";

import useModalContext, { ModalContextValue } from "./useModalContext";

type Props = {
  icon?: ReactElement<{ size: number }>;
  children: ReactNode | ((renderProps: ModalContextValue) => ReactNode);
};

const ModalHeader: FC<Props> = ({ icon, children }) => {
  const modalContext = useModalContext();

  return (
    <DialogTitle
      as="div"
      className="flex w-full items-center justify-between border-b border-b-grey-200 px-6 py-4"
    >
      <div className="flex flex-grow items-center justify-start gap-x-4">
        {icon}
        <Heading2 className="w-full">
          {typeof children === "function" ? children(modalContext) : children}
        </Heading2>
      </div>
      <div className="flex items-center justify-end gap-x-6 px-2">
        <button
          className="flex size-5"
          onClick={modalContext.closeModalWithAnimation}
          type="button"
        >
          <img src={closeIcon} alt="Close" title="Close" />
        </button>
      </div>
    </DialogTitle>
  );
};

export default ModalHeader;
