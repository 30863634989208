import BillApprovalRep from "reps/BillApprovalRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillApprovalActionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async grant(billApprovalId: string): Promise<BillApprovalRep.Complete> {
    const url = `/accounts-payable/bill-approvals/${billApprovalId}/grant`;
    return (await this.api.patch<BillApprovalRep.Complete>(url))!;
  }

  async sendReminder(billApprovalId: string): Promise<void> {
    const url = `/accounts-payable/bill-approvals/${billApprovalId}/reminder`;
    return (await this.api.post<void>(url))!;
  }
}
