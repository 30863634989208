import { useEffect } from "react";
import colors from "styles/colors";
import Switch from "ui/inputs/Switch";
import TextInput, { useTextInput, validator } from "ui/inputs/TextInput";
import Text from "ui/typography/Text";
import Validator from "utils/validator";

import styles from "./PayeeNotification.module.scss";

type Props = {
  setPayeeEmail: (payeeEmail: string) => void;
  payeeEmail: string;
  isPayeeEmailToggled: boolean;
  setIsPayeeEmailToggled: (toggled: boolean) => void;
  setHasEmailError: (hasError: boolean) => void;
};

const PayeeNotification: React.FC<Props> = ({
  payeeEmail,
  setPayeeEmail,
  isPayeeEmailToggled,
  setIsPayeeEmailToggled,
  setHasEmailError,
}) => {
  const email = useTextInput({
    initialValue: payeeEmail,
    onChange: validator(Validator.emailAddress, "Invalid email address"),
  });

  useEffect(() => {
    setPayeeEmail(email.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email.value, isPayeeEmailToggled]);

  useEffect(() => {
    setHasEmailError(!isValidEmail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email.hasError, isPayeeEmailToggled]);

  const isValidEmail = () => isPayeeEmailToggled && Validator.emailAddress(payeeEmail);

  useEffect(() => {
    setHasEmailError(!isValidEmail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payeeEmail]);

  return (
    <div className={styles.container}>
      <legend className={styles.headingText}>Notification</legend>

      <div className={styles.bodyContainer}>
        <Text size={14} color={colors.grey[800]} className={styles.descriptionText}>
          Send the recipient a confirmation email for this payment
        </Text>
        <Switch value={isPayeeEmailToggled} onChange={setIsPayeeEmailToggled} />
      </div>
      {isPayeeEmailToggled && <TextInput type="email" label="Email address" {...email} />}
    </div>
  );
};

export default PayeeNotification;
