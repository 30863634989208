import React from "react";
import ChatMessageContent from "reps/chat/ChatMessageContent";
import MoneyCell from "ui/table/MoneyCell";
import Table, { Column } from "ui/table/Table";
import TextCell from "ui/table/TextCell";

type Props = {
  section: ChatMessageContent.Table;
};

const ChatResponseTable: React.FC<Props> = ({ section }) => {
  const columns: Column<ChatMessageContent.Table.Value[]>[] = section.columns.map((column, i) => {
    return {
      title: column.header,
      cellRender: (row) => {
        const value = row[i];
        switch (column.type) {
          case "currency":
            return <MoneyCell amount={value.toString()} currencyCode="USD" />;
          default:
            return <TextCell>{value}</TextCell>;
        }
      },
    };
  });

  const data = section.values;

  return (
    <Table
      className="table-auto"
      columns={columns}
      rowKey={(row, i) => i.toString()}
      data={data}
      headerTextWeight="medium"
    />
  );
};

export default ChatResponseTable;
