import {
  forwardRef,
  ForwardRefRenderFunction,
  HTMLAttributes,
  KeyboardEvent,
  useCallback,
  useMemo,
} from "react";
import cn from "utils/tailwind/cn";

import { ButtonPaddingVariant, ButtonSize, ButtonVariant, getButtonClasses } from "../Button";

type Props = HTMLAttributes<HTMLDivElement> & {
  variant?: ButtonVariant;
  paddingVariant?: ButtonPaddingVariant;
  size?: ButtonSize;
  disabled?: boolean;
};

const VirtualButton: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { onClick, variant, paddingVariant, className, size, disabled = false, children, ...props },
  ref
) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (!disabled && (event.key === "Enter" || event.key === " ")) {
        event.preventDefault();
        event.currentTarget.click();
      }
    },
    [disabled]
  );

  // Standard button classes are only applied if at least one of the props
  // variant, paddingVariant, or size are provided. This allows for the caller
  // to use a VirtualButton without any button styles.
  const buttonClasses = useMemo(() => {
    return variant || paddingVariant || size
      ? getButtonClasses({
          variant: variant ?? "default",
          paddingVariant: paddingVariant ?? "regular",
          size: size ?? "md",
        })
      : "";
  }, [variant, paddingVariant, size]);

  return (
    <div
      ref={ref}
      role="button"
      className={cn(
        buttonClasses,
        "cursor-pointer focus-visible:outline-focus",
        !onClick && "cursor-auto",
        disabled && "pointer-events-none",
        className
      )}
      aria-disabled={disabled}
      data-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      {...props}
    >
      {children}
    </div>
  );
};

export default forwardRef(VirtualButton);
