import { Relationship } from "@highbeam/unit-node-sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import TransactionFlexpaneDispute from "components/common/transaction-flexpane/TransactionFlexpaneDispute";
import BankAccountRep from "reps/BankAccountRep";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { UNIT_CO_TRANSACTIONS_QUERY_KEY } from "resources/unit-co-transactions/queries/useUnitCoTransactionsQueryOptions";
import Section from "ui/data-display/Section";
import Flexpane from "ui/overlay/Flexpane";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { convertToHighbeamTransaction, getPaymentMetadataByGuid } from "utils/transactions";
import { isBookTransaction, isFailedPaymentTransaction } from "utils/types/transactionsTypes";

import TransactionInfoFlexpaneProvider from "./context/TransactionInfoFlexpaneProvider";
import useAssociatedPaymentForTransactionInfoFlexpane from "./hooks/useAssociatedPaymentForTransactionInfoFlexpane";
import useTransactionInfoFlexpaneTransaction from "./hooks/useTransactionInfoFlexpaneTransaction";
import TransactionInfoFlexpaneAdditionalInfoSection from "./TransactionInfoFlexpaneAdditionalInfoSection";
import TransactionInfoFlexpaneAmountSection from "./TransactionInfoFlexpaneAmountSection/TransactionInfoFlexpaneAmountSection";
import TransactionInfoFlexpaneDetailsSection from "./TransactionInfoFlexpaneDetailsSection";
import TransactionInfoFlexpaneHeader from "./TransactionInfoFlexpaneHeader";
import TransactionInfoFlexpaneReasonSection from "./TransactionInfoFlexpaneReasonSection";
import TransactionInfoFlexpaneToFromSection from "./TransactionInfoFlexpaneToFromSection";

// NB(alex): Temporary hack. Will delete this once we stop prop-drilling the HighbeamTransaction through the flexpane.

const TransactionInfoFlexpaneContent = () => {
  const unitTransaction = useTransactionInfoFlexpaneTransaction();
  const paymentId = (unitTransaction.relationships.payment as Relationship)?.data.id;

  // NB(alex): Will clean this up once we get rid of `HighbeamTransaction`. Is necessary for now.
  // Ensures international wires are formed property in `HighbeamTransaction`.
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const { data: internationalWireDetails } = useSuspenseQuery({
    queryKey: [UNIT_CO_TRANSACTIONS_QUERY_KEY, unitTransaction.id],
    queryFn: async () => {
      return await getPaymentMetadataByGuid(businessGuid, [unitTransaction], highbeamApi);
    },
  });

  const unitPayment = useAssociatedPaymentForTransactionInfoFlexpane(paymentId);
  const allBankAccounts = bankAccountsQueryHooks.useData({ status: "all" });

  const transaction = convertToHighbeamTransaction(
    unitTransaction,

    // NB(alex): Note for self, we can delete this soon. This is a temporary hack.
    allBankAccounts?.reduce((map, account: BankAccountRep.Complete) => {
      map.set(account.unitCoDepositAccountId, account);
      return map;
    }, new Map<string, BankAccountRep.Complete>()) || new Map<string, BankAccountRep.Complete>(),

    internationalWireDetails[unitTransaction.attributes.tags?.paymentMetadataGuid ?? ""],
    unitPayment ?? undefined
  );

  return (
    <>
      <TransactionInfoFlexpaneHeader transaction={transaction} />
      <TransactionInfoFlexpaneToFromSection transaction={transaction} />
      <TransactionInfoFlexpaneAmountSection transaction={transaction} />
      {isFailedPaymentTransaction(transaction) ? (
        transaction.reason !== "" && (
          <TransactionInfoFlexpaneReasonSection transaction={transaction} />
        )
      ) : (
        <>
          <TransactionInfoFlexpaneDetailsSection transaction={transaction} />
          {!isBookTransaction(transaction) && (
            <TransactionInfoFlexpaneAdditionalInfoSection transaction={transaction} />
          )}
          <Section>
            <TransactionFlexpaneDispute />
          </Section>
        </>
      )}
    </>
  );
};

type Props = {
  params?: {
    transactionId: string;
    unitCoDepositAccountId: string;
  };
  onClose: () => void;
  refreshTransactions?: () => void;
};

const TransactionInfoFlexpane: React.FC<Props> = ({ params, onClose, refreshTransactions }) => {
  return (
    <Flexpane isOpen={Boolean(params)} onClose={onClose} disableInitialFocus>
      {params && (
        <TransactionInfoFlexpaneProvider
          unitCoDepositAccountId={params.unitCoDepositAccountId}
          transactionId={params.transactionId}
          onClose={onClose}
          refreshTransactions={refreshTransactions}
        >
          <TransactionInfoFlexpaneContent />
        </TransactionInfoFlexpaneProvider>
      )}
    </Flexpane>
  );
};

export default TransactionInfoFlexpane;
