import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { useForm, UseFormProps } from "react-hook-form";
import SuggestedPayeeRep from "reps/SuggestedPayeeRep";
import { useGetFinancialInstitutionFromQueryCache } from "resources/financial-institutions/queries/useFinancialInstitution";
import accountNumberSchema from "utils/zod/accountNumberSchema";
import routingNumberSchema, { RoutingNumberSchemaParams } from "utils/zod/routingNumberSchema";
import { z } from "zod";

type AchPaymentMethodFormSchemaParams = RoutingNumberSchemaParams;

const achPaymentMethodFormSchema = ({
  fetchFinancialInstitutionByRoutingNumber,
  paymentMethod,
}: AchPaymentMethodFormSchemaParams) => {
  return z.object({
    routingNumber: routingNumberSchema({
      fetchFinancialInstitutionByRoutingNumber,
      paymentMethod,
    }),
    accountNumber: accountNumberSchema,
  });
};

export type AchPaymentMethodFormInputs = z.infer<ReturnType<typeof achPaymentMethodFormSchema>>;

type UseAchPaymentMethodFormParams = Pick<
  UseFormProps<AchPaymentMethodFormInputs>,
  "defaultValues" | "disabled"
> & {
  suggestedPaymentMethod?: SuggestedPayeeRep.PaymentMethod;
};

const useAchPaymentMethodForm = (params: UseAchPaymentMethodFormParams = {}) => {
  const { suggestedPaymentMethod } = params;

  const getFinancialInstitutionFromQueryCache = useGetFinancialInstitutionFromQueryCache();

  const form = useForm({
    ...params,
    resolver: zodResolver(
      achPaymentMethodFormSchema({
        fetchFinancialInstitutionByRoutingNumber: async (routingNumber) => {
          const financialInstitution = getFinancialInstitutionFromQueryCache(routingNumber);

          if (!financialInstitution) {
            return null;
          }
          return {
            isAchSupported: financialInstitution.isAchSupported,
            isDomesticWireSupported: financialInstitution.isWireSupported,
            name: financialInstitution.name,
          };
        },
        paymentMethod: "ach",
      })
    ),
    defaultValues: {
      routingNumber: suggestedPaymentMethod?.achRoutingNumber ?? "",
      accountNumber: suggestedPaymentMethod?.achAccountNumber ?? "",
      ...params?.defaultValues,
    },
  });

  const isBlank = useCallback(() => {
    const { routingNumber, accountNumber } = form.getValues();
    return !routingNumber && !accountNumber;
  }, [form]);

  return Object.assign(form, { isBlank: isBlank });
};

export default useAchPaymentMethodForm;
