import { CREDIT_OFFER_PARAM_PREFIX } from "pages/capital/CreditComparisonPage/constants";
import filterOfferInputsWithoutProviderName from "pages/capital/CreditComparisonPage/utils/filterOfferInputsWithoutProviderName";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { atom, selector } from "recoil";
import { StepTypeV2 } from "ui/navigation/StepsV2";

import offerInputsState from "./inputs/offerInputs/offerInputs";
import isStepNavigableState from "./isStepNavigable";

export const RESULTS_STEP_BASE_PATH = `${CREDIT_COMPARISON_PATH}/results`;

export const initialSteps = [
  { title: "Select providers", path: `${CREDIT_COMPARISON_PATH}` },
  { title: "Revenue projection", path: `${CREDIT_COMPARISON_PATH}/revenue-projection` },
  { title: "Results", path: RESULTS_STEP_BASE_PATH },
];

export const resultsStepPathState = atom<string>({
  key: "compareCreditOffers/resultsStepPathState",
  default: RESULTS_STEP_BASE_PATH,
});

const allStepsState = selector<StepTypeV2[]>({
  key: "compareCreditOffers/allStepsState",
  get: ({ get }) => {
    const selectProvidersStep: StepTypeV2 = initialSteps[0];

    const offerInputs = get(offerInputsState);
    const offerDetailsSteps: StepTypeV2[] = filterOfferInputsWithoutProviderName(offerInputs).map(
      ({ providerName }, index) => ({
        title: providerName,
        path: `${CREDIT_COMPARISON_PATH}/${CREDIT_OFFER_PARAM_PREFIX}${index + 1}`,
      })
    );

    const revenueProjectionsStep: StepTypeV2 = {
      ...initialSteps[1],
    };

    const resultsStep: StepTypeV2 = {
      ...initialSteps[2],
      path: get(resultsStepPathState),
    };

    const allStepsWithoutIsNavigable = [
      selectProvidersStep,
      ...offerDetailsSteps,
      revenueProjectionsStep,
      resultsStep,
    ];

    return allStepsWithoutIsNavigable.map((step, index) => ({
      ...step,
      isNavigable: get(isStepNavigableState(index)),
    }));
  },
});

export default allStepsState;
