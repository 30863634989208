import { Info as InfoIcon, Warning as WarningIcon } from "@phosphor-icons/react";
import { FC, PropsWithChildren, createContext, useContext } from "react";
import { Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

export type AlertVariant = "info" | "warning";

type AlertContextValue = {
  variant: AlertVariant;
};

const AlertContext = createContext<AlertContextValue>({
  variant: "info",
});

const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlertContext must be used within an Alert");
  }
  return context;
};

type AlertIconProps = {
  className?: string;
};

const AlertIcon: FC<AlertIconProps> = ({ className }) => {
  const { variant } = useAlertContext();
  const classes = cn(
    "h-5 w-5 shrink-0",
    variant === "info" && "text-purple-500",
    variant === "warning" && "text-orange-300",
    className
  );

  if (variant === "info") {
    return <InfoIcon className={classes} />;
  }

  if (variant === "warning") {
    return <WarningIcon className={classes} />;
  }

  throw new Error(`Unsupported alert variant: ${variant}`);
};

type AlertTextProps = PropsWithChildren<{
  className?: string;
}>;

const AlertText: FC<AlertTextProps> = ({ children, className }) => (
  <Paragraph className={cn("text-sm", className)}>{children}</Paragraph>
);

type Props = PropsWithChildren<{
  variant?: AlertVariant;
  className?: string;
}>;

const Alert: FC<Props> = ({ children, className, variant = "info" }) => (
  <div className={cn("flex items-start gap-2 tablet:p-4", className)}>
    <AlertContext.Provider value={{ variant }}>{children}</AlertContext.Provider>
  </div>
);

export default Object.assign(Alert, {
  Icon: AlertIcon,
  Text: AlertText,
});
