import {
  getValidator,
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import TextInput, { useTextInput } from "ui/inputs/TextInput";

type Props = {
  selectedCountry: string;
};

const Clabe: React.FC<Props> = ({ selectedCountry }) => {
  const [internationalBankingInfo, setInternationalBankingInfo] = useRecoilState(
    internationalBankingInfoState
  );
  const clabe = useTextInput({
    initialValue: internationalBankingInfo.clabe?.value,
    onChange: getValidator(selectedCountry, INTERNATIONAL_WIRE_INPUTS.CLABE),
  });

  useEffect(() => {
    setInternationalBankingInfo({
      ...internationalBankingInfo,
      clabe: {
        inputName: INTERNATIONAL_WIRE_INPUTS.CLABE,
        value: clabe.value,
        isValid: clabe.isValid,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clabe.value, clabe.isValid]);

  return (
    <TextInput
      type="text"
      value={clabe.value}
      onChange={(value) => {
        clabe.onChange(value);
      }}
      label={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.CLABE].label}
      onBlur={clabe.onBlur}
      hasError={clabe.hasError}
      errorMessage={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.CLABE].errorMessage}
    />
  );
};

export default Clabe;
