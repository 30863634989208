import colors from "styles/colors";

type Props = {
  size?: number;
  color?: string;
};

const ChequeIcon: React.FC<Props> = ({ size = 16, color = colors.grey[600] }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      d="M7 3.5H2C1.72386 3.5 1.5 3.72386 1.5 4V12C1.5 12.2761 1.72386 12.5 2 12.5H14C14.2761 12.5 14.5 12.2761 14.5 12V4C14.5 3.72386 14.2761 3.5 14 3.5H13.625"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.4995 10.5H12.4995" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 6L8 6" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 8L8 8" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.3325 1.83325V5.49992"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.83252 4L10.3325 5.5L11.8325 4"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChequeIcon;
