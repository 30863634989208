import React from "react";
import ChatMessageRequest from "reps/chat/ChatMessageRequest";
import useUser from "resources/user/queries/useUser";
import colors from "styles/colors";
import UserAvatar from "ui/data-display/UserAvatar";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
  request: Omit<ChatMessageRequest, "businessGuid" | "userGuid">;
};

const ChatRequest: React.FC<Props> = ({ className, request }) => {
  const user = useUser();

  return (
    <div className={cn("flex flex-row gap-4", className)}>
      <UserAvatar
        initials={user?.initials ?? ""}
        color="purple-light"
        size={32}
        className="shrink-0"
      />
      <Text as="p" size={14} color={colors.grey[600]} className="py-[0.375rem]">
        {request.content}
      </Text>
    </div>
  );
};

export default ChatRequest;
