import AccountLabel from "components/Accounts/AccountLabel";
import BankAccountRep from "reps/BankAccountRep";
import { Option } from "ui/inputs/Dropdown";

import getBankAccountDisplayName from "./getBankAccountDisplayName";

// NB(alex): This replaces the old `allBankAccountsOptionsState` recoil state. We should delete this once we have a composable `<Dropdown>` component.

export const ALL_BANK_ACCOUNTS_OPTION: Option = {
  label: "All accounts",
  value: "all",
};

export const getBankAccountDropdownOption = (bankAccount: BankAccountRep.Complete) => {
  return {
    value: bankAccount.unitCoDepositAccountId,
    label: AccountLabel({ bankAccount: bankAccount }),
    description: getBankAccountDisplayName(bankAccount),
  };
};

const getBankAccountDropdownOptions = (bankAccounts: BankAccountRep.Complete[]) => {
  const accountOptions = bankAccounts.map(getBankAccountDropdownOption);
  return [ALL_BANK_ACCOUNTS_OPTION, ...accountOptions];
};

export default getBankAccountDropdownOptions;
