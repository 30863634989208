import { CaretDown, CaretUp, CheckCircle } from "@phosphor-icons/react";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import isGettingStartedSetupGuideOpenState from "resources/action-items/get-started/state/gettingStartedSetupGuide";
import { useMilestoneActionItems } from "resources/action-items/hooks/useMilestoneActionItems";
import { ActionItem, Milestone } from "resources/action-items/types";
import colors from "styles/colors";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./ActionItemRow.module.scss";

type Props = {
  number: number;
  fullWidth?: boolean;
  actionItem: ActionItem;
  active?: boolean;
  setActive: (active: number | undefined) => void;
  hideAction?: boolean;
};

const ActionItemRow: React.FC<Props> = ({
  number,
  fullWidth = false,
  actionItem,
  active = false,
  setActive,
  hideAction,
}) => {
  const navigate = useNavigate();
  const [scrollHeight, setScrollHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (active) {
      const content = contentRef.current;
      if (content) {
        content.style.height = `${scrollHeight}px`;
      }
    } else {
      const content = contentRef.current;
      if (content) {
        setScrollHeight(content.scrollHeight);
        content.style.height = "0";
      }
    }
  }, [active, scrollHeight]);
  const toggleContainer = () => {
    if (active) {
      setActive(undefined);
    } else {
      setActive(actionItem.order);
    }
  };
  const { finishItemByStep } = useMilestoneActionItems(Milestone.GetStarted);

  const skipAction = () => {
    finishItemByStep(actionItem.name, "Dismissed");
  };

  const isSkipped = actionItem.finishedActionItemRep?.state === "Dismissed";
  const isCompleted = actionItem.finishedActionItemRep?.state === "Complete";
  const isSkippable = !isSkipped && !isCompleted;
  const setShowGetStartedSetupGuide = useSetRecoilState(isGettingStartedSetupGuideOpenState);

  const completedIcon = <CheckCircle weight="fill" color={colors.green[400]} size={24} />;

  const actionNumber = (
    <div
      className={classNames(styles.actionItemRowIndex, {
        [styles.actionItemRowIndexExpanded]: active,
      })}
    >
      {number}
    </div>
  );

  const actionDetails = (
    <div className={classNames(styles.actionItemRowDetails, fullWidth && styles.fullWidth)}>
      <div className={styles.actionTitleContainer}>
        <Text size={14} weight={"medium"} color={colors.grey[900]} className={styles.actionTitle}>
          {actionItem.title}
        </Text>
        {isSkipped && <Pill color="orange-red-legacy">Skipped</Pill>}
      </div>
      <div ref={contentRef} className={styles.actionItemContent}>
        <Text size={14} color={colors.grey[600]}>
          {actionItem.description}
        </Text>
        {actionItem.dynamicDescription ? actionItem.dynamicDescription() : null}
        <div className={styles.actionItemRowDetailButtons}>
          {hideAction ? null : (
            <>
              {!isCompleted && (
                <Button
                  variant="tertiary"
                  size="sm"
                  onClick={() => {
                    navigate(actionItem.primaryActionPath, actionItem.locationState);
                    setShowGetStartedSetupGuide(false);
                  }}
                >
                  {actionItem.primaryActionText}
                </Button>
              )}
              {isSkippable && (
                <Button size="sm" onClick={skipAction}>
                  Skip
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );

  const actionToggle = (
    <div onClick={toggleContainer}>
      <button className={styles.caret}>
        {active ? (
          <CaretUp size={16} color={colors.grey[400]} className={styles.caret} />
        ) : (
          <CaretDown size={16} color={colors.grey[400]} className={styles.caret} />
        )}
      </button>
    </div>
  );

  return (
    <div
      className={classNames(styles.actionItemRow, { [styles.actionItemRowExpanded]: active })}
      onClick={toggleContainer}
    >
      {isCompleted ? completedIcon : actionNumber}
      {actionDetails}
      {actionToggle}
    </div>
  );
};

export default ActionItemRow;
