import getIsInternationalWireUnitCoPayment from "resources/unit-co-payments/utils/getIsInternationalWireUnitCoPayment";
import Flexpane from "ui/overlay/Flexpane";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

import OriginatedAchTransactionFlexpaneHeader from "./OriginatedAchTransactionFlexpaneHeader";

const PaymentInfoFlexpaneHeaderSection = () => {
  const { payment, onClose, paymentDirection } = usePaymentInfoFlexpaneContext();
  const isInternationalWirePayment = getIsInternationalWireUnitCoPayment(payment);

  if (payment.type === "achPayment" && !isInternationalWirePayment) {
    return <OriginatedAchTransactionFlexpaneHeader paymentDirection={paymentDirection} />;
  } else {
    return <Flexpane.Header onClose={onClose}>Payment info</Flexpane.Header>;
  }
};

export default PaymentInfoFlexpaneHeaderSection;
