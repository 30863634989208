import PaymentSummary from "components/PaymentSummary";
import MultiStep from "layouts/MultiStep";
import { FC, FormEvent } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import BankAccountBar from "resources/bank-accounts/components/BankAccountBar";
import { useOpenBankAccountRequired } from "resources/bank-accounts/queries/bankAccountQueryHooks";
import CapitalAccountBar from "resources/capital-accounts/components/CapitalAccountBar";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import useRepayChargeCardMutation from "resources/charge-cards/mutations/useRepayChargeCardMutation";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import { notify } from "ui/feedback/Toast";

const REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID = "repay-highbeam-cards-confirmation-form";

type RepayHighbeamCardsConfirmationViewState = {
  amount: number;
  repaymentBankAccountGuid: string;
};

type RepayHighbeamCardsConfirmationFormProps = RepayHighbeamCardsConfirmationViewState & {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const RepayHighbeamCardsConfirmationForm: FC<RepayHighbeamCardsConfirmationFormProps> = ({
  amount,
  repaymentBankAccountGuid,
  capitalAccountSummaryWithChargeCard,
}) => {
  const repaymentBankAccount = useOpenBankAccountRequired(repaymentBankAccountGuid);
  const navigate = useNavigate();

  const availableAfterRepayment = capitalAccountSummaryWithChargeCard.available + amount;

  const { mutate: repayChargeCard, isPending } = useRepayChargeCardMutation({
    onSuccess: () => {
      notify("success", "Repayment successful");
      navigate(`/capital/${capitalAccountSummaryWithChargeCard.guid}`);
    },
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    repayChargeCard({
      capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
      amountInCents: amount,
      bankAccountGuid: repaymentBankAccountGuid,
    });
  };

  return (
    <MultiStep.Form id={REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID} onSubmit={onSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Confirm details</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <PaymentSummary>
          <PaymentSummary.Header>
            <PaymentSummary.Header.Title>Repayment amount</PaymentSummary.Header.Title>
            <PaymentSummary.Header.Amount cents={amount} />
          </PaymentSummary.Header>

          <PaymentSummary.SectionDivider />

          <PaymentSummary.Section>
            <PaymentSummary.Section.SummaryItem
              label="Repaying"
              value={<CapitalAccountBar capitalAccount={capitalAccountSummaryWithChargeCard} />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Pay from"
              value={<BankAccountBar bankAccount={repaymentBankAccount} />}
            />

            <PaymentSummary.Section.SummaryItem
              label="Available (current)"
              value={
                <MoneyAmountFraction
                  numeratorInCents={capitalAccountSummaryWithChargeCard.available}
                  denominatorInCents={capitalAccountSummaryWithChargeCard.details.limit}
                />
              }
            />

            <PaymentSummary.Section.SummaryItem
              label="Available after repaying"
              value={
                <MoneyAmountFraction
                  numeratorInCents={availableAfterRepayment}
                  denominatorInCents={capitalAccountSummaryWithChargeCard.details.limit}
                />
              }
            />
          </PaymentSummary.Section>
        </PaymentSummary>
      </MultiStep.Section>

      <MultiStep.Controls>
        <MultiStep.Controls.BackButtonLink
          to={`/capital/${capitalAccountSummaryWithChargeCard.guid}/cards/repay`}
          disabled={isPending}
        />

        <MultiStep.Controls.NextButton
          form={REPAY_HIGHBEAM_CARDS_CONFIRMATION_FORM_ID}
          autoFocus
          isLoading={isPending}
        >
          Complete repayment
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

const isValidRepayHighbeamCardsConfirmationViewState = (
  state: unknown
): state is RepayHighbeamCardsConfirmationViewState => {
  return (
    typeof state === "object" &&
    state !== null &&
    "amount" in state &&
    typeof state.amount === "number" &&
    "repaymentBankAccountGuid" in state &&
    typeof state.repaymentBankAccountGuid === "string"
  );
};

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const RepayHighbeamCardsConfirmationView: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const { state } = useLocation();

  if (!isValidRepayHighbeamCardsConfirmationViewState(state)) {
    return <Navigate to="/capital" />;
  }

  return (
    <RepayHighbeamCardsConfirmationForm
      amount={state.amount}
      repaymentBankAccountGuid={state.repaymentBankAccountGuid}
      capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
    />
  );
};

export default RepayHighbeamCardsConfirmationView;
