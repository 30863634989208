import classNames from "classnames";
import {
  ChangeEvent,
  ComponentProps,
  forwardRef,
  ForwardRefRenderFunction,
  useState,
  useId,
} from "react";

import InputWrapper, { SharedInputProps } from "../InputWrapperV2";

import styles from "./TextInput.module.scss";

type InputProps = ComponentProps<"input">;

type CustomTextInputProps = {
  value: string;
  onChange: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  hideNumberIncrementArrows?: boolean;
};

type InheritedInputProps = Omit<InputProps, keyof CustomTextInputProps | keyof SharedInputProps>;

type Props = SharedInputProps & CustomTextInputProps & InheritedInputProps;

const TextInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    value,
    onChange,
    placeholder,
    className,
    disabled,
    id,
    label,
    startAdornment,
    endAdornment,
    showErrorOutline,
    variant,
    onFocus,
    onBlur,
    hideNumberIncrementArrows,
    ...inheritedInputProps
  },
  ref
) => {
  const [focused, setFocused] = useState(false);
  // We don't show the label in minimal variant.
  const hasLabel = Boolean(label) && variant !== "minimal";
  const shouldShrinkLabel = hasLabel && Boolean(value || placeholder || focused);
  const autoId = useId();
  const inputId = id ?? autoId;

  return (
    <InputWrapper
      shouldShrinkLabel={shouldShrinkLabel}
      showFocusOutline={focused}
      disabled={disabled}
      className={className}
      inputId={inputId}
      label={label}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      showErrorOutline={showErrorOutline}
      variant={variant}
    >
      <input
        ref={ref}
        onFocus={(e) => {
          setFocused(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          onBlur?.(e);
        }}
        className={classNames(
          styles.input,
          hasLabel && styles.hasLabel,
          hideNumberIncrementArrows && styles.hideNumberIncrementArrows
        )}
        disabled={disabled}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value, e)}
        placeholder={placeholder}
        id={inputId}
        {...inheritedInputProps}
      />
    </InputWrapper>
  );
};

export default forwardRef(TextInput);
