import { useContext, useMemo } from "react";
import InfoIcon from "ui/icons/InfoIcon";
import CollapsibleFieldset from "ui/inputs/CollapsibleFieldset";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import Helper from "ui/inputs/Helper";
import TextInput, { Filter } from "ui/inputs/TextInput";
import { Heading4 } from "ui/typography";

import { CreatePayeeFormContext } from "./CreatePayeeForm";
import styles from "./CreatePayeePage.module.scss";

export const accountTypeOptions: Option[] = [
  {
    value: "Checking",
    label: "Checking",
  },
  {
    value: "Savings",
    label: "Savings",
  },
];

const AchInfoSection = () => {
  const form = useContext(CreatePayeeFormContext);
  const ach = form.paymentInfo.ach;

  const hasError = useMemo(() => ach.hasError[0], [ach.hasError]);

  const heading = (
    <>
      <Heading4>ACH</Heading4>
      {hasError && <InfoIcon size={20} variant="warning" />}
    </>
  );

  return (
    <CollapsibleFieldset heading={heading}>
      {hasError && (
        <Helper iconVariant="warning" className={styles.span2}>
          If you’d like to send ACH payments to this recipient, please complete all ACH fields.
          Otherwise, you can leave them blank.
        </Helper>
      )}
      <TextInput
        label="Routing number"
        className={styles.span2}
        maxLength={9}
        inputFilter={Filter.DIGITS}
        {...ach.routingNumber}
      />
      <TextInput
        label="Account number"
        className={styles.span2}
        maxLength={17}
        inputFilter={Filter.DIGITS}
        {...ach.accountNumber}
      />
      <Dropdown
        label="Account type"
        className={styles.span2}
        options={accountTypeOptions}
        isSearchable={false}
        {...ach.accountType}
      />
    </CollapsibleFieldset>
  );
};

export default AchInfoSection;
