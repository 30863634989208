import { FC, ReactNode } from "react";
import DatePicker from "ui/inputs/DatePicker";

type FromDatePickerProps = {
  fromDate: Date;
  toDate: Date;
  setFromDate: (date: Date) => void;
};

export const FromDatePicker: FC<FromDatePickerProps> = ({ fromDate, toDate, setFromDate }) => (
  <DatePicker
    value={fromDate}
    onChange={(date) => date && setFromDate(date)}
    label="From"
    variant="start-date"
    startDate={fromDate}
    endDate={toDate}
  />
);

type ToDatePickerProps = {
  fromDate: Date;
  toDate: Date;
  setToDate: (date: Date) => void;
};

export const ToDatePicker: FC<ToDatePickerProps> = ({ fromDate, toDate, setToDate }) => (
  <DatePicker
    value={toDate}
    onChange={(date) => date && setToDate(date)}
    label="To"
    variant="end-date"
    startDate={fromDate}
    endDate={toDate}
    minDate={fromDate}
  />
);

type Props = {
  fromPicker: ReactNode;
  toPicker: ReactNode;
};

const DateRangePickers: FC<Props> = ({ fromPicker, toPicker }) => (
  <div className="flex flex-col gap-2 tablet:flex-row tablet:gap-6">
    {fromPicker}
    {toPicker}
  </div>
);

export default DateRangePickers;
