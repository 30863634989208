import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import { useCheckDepositFlexpaneContext } from "dialogs/CheckDepositFlexpane/context/CheckDepositFlexpaneProvider";
import Flexpane from "ui/overlay/Flexpane";

const CheckDepositFlexpaneAmountSection = () => {
  const { checkDeposit } = useCheckDepositFlexpaneContext();

  return (
    <Flexpane.Section>
      <TransactionFlexpaneAmount cents={checkDeposit.attributes.amount} direction="positive" />
    </Flexpane.Section>
  );
};

export default CheckDepositFlexpaneAmountSection;
