import { atom, selector } from "recoil";
import { SaveStatus } from "ui/feedback/AutosaveIndicator";

type BillDetailsAutosaveState = {
  saveStatus: SaveStatus;
};

const billDetailsAutosaveState = atom<BillDetailsAutosaveState>({
  key: "billDetails/autosave",
  default: {
    saveStatus: "idle",
  },
});

export const billDetailsAutosaveStatusState = selector<SaveStatus>({
  key: "billDetails/autosave/status",
  get: ({ get }) => {
    return get(billDetailsAutosaveState).saveStatus;
  },
});

export default billDetailsAutosaveState;
