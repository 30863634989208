import classNames from "classnames";
import React from "react";
import { createFilter } from "react-select";
import SubcategoryRep from "reps/Insights/SubcategoryRep";
import useTransactionSubcategoryOptions from "resources/transaction-subcategories/hooks/useTransactionSubcategoryOptions";
import Dropdown, { DropdownState, Option } from "ui/inputs/Dropdown";

import styles from "./SubcategoryDropdown.module.scss";

type Props = {
  dropdownState: DropdownState;
  className?: string;
  hideLabel?: boolean;
  inline?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  menuPlacement?: "bottom" | "top";
};

const SubcategoryDropdown: React.FC<Props> = ({
  dropdownState,
  className = undefined,
  hideLabel = false,
  inline = false,
  disabled = false,
  isLoading = false,
  menuPlacement = "bottom",
}) => {
  const subcategoryOptions = useTransactionSubcategoryOptions();

  return (
    <Dropdown
      label={hideLabel ? undefined : "Category"}
      placeholder="Select category"
      className={classNames(styles.dropdown, inline ? styles.inline : styles.notInline, className)}
      options={subcategoryOptions}
      isSearchable
      filterOption={createFilter({
        stringify: (option) => {
          const subcategory = (option.data as Option & { subcategory: SubcategoryRep.Complete })
            .subcategory;
          return `${subcategory.category.displayName} ${subcategory.displayName}`;
        },
      })}
      hideCursor
      hideBorders
      disabled={disabled}
      isLoading={isLoading}
      menuPlacement={menuPlacement}
      {...dropdownState}
    />
  );
};

export const initialSubcategory = (
  subcategoryGuid: string | undefined,
  subcategoryOptions: Option[]
): Option | undefined => {
  if (subcategoryGuid === undefined) return undefined;

  return subcategoryOptions.find(
    (subcategoryOption) => subcategoryOption.value === subcategoryGuid
  );
};

export default SubcategoryDropdown;
