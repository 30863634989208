import CapitalAccountRep from "reps/CapitalAccountRep";
import ChargeCardOfferRep from "reps/ChargeCardOfferRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

/**
 * `chargeCardProgram` has the exact same fields as `chargeCardOffer`, but fetches the `chargeCardAccount` endpoint instead and returns its overlapping fields.
 */

export const ROOT_CHARGE_CARD_PROGRAM_QUERY_KEY = "chargeCardProgram-root";

export const useRefreshAllChargeCardProgramQueries = () => {
  return useRefreshQuery([ROOT_CHARGE_CARD_PROGRAM_QUERY_KEY]);
};

export type ChargeCardProgram = ChargeCardOfferRep.Complete;

type Params = {
  capitalAccountGuid: string;
  state: CapitalAccountRep.State;
};

const chargeCardProgramQueryHooks = makeQueryHooks({
  rootName: ROOT_CHARGE_CARD_PROGRAM_QUERY_KEY,
  name: "chargeCardProgram",
  useQueryVariables: ({ capitalAccountGuid, state }: Params) => {
    const businessGuid = useBusinessGuid();
    return { businessGuid, capitalAccountGuid, state };
  },
  useQueryFnMaker: ({ businessGuid, capitalAccountGuid, state }) => {
    const highbeamApi = useHighbeamApi();
    return async (): Promise<ChargeCardProgram | null> => {
      if (
        state === CapitalAccountRep.State.Active ||
        state === CapitalAccountRep.State.Terminated
      ) {
        const chargeCardAccount = await highbeamApi.chargeCardAccount.get(
          businessGuid,
          capitalAccountGuid
        );
        if (!chargeCardAccount) {
          return null;
        }
        return {
          businessGuid: chargeCardAccount.businessGuid,
          maxCreditLimit: chargeCardAccount.maxCreditLimit,
          minCashback: chargeCardAccount.minCashback,
          maxCashback: chargeCardAccount.maxCashback,
          maxRepaymentDays: chargeCardAccount.maxRepaymentDays,
          creditTerms: chargeCardAccount.creditTerms,
          latePaymentFees: chargeCardAccount.latePaymentFees,
        };
      } else {
        return await highbeamApi.chargeCardOffer.get(businessGuid, capitalAccountGuid);
      }
    };
  },
});

export default chargeCardProgramQueryHooks;
