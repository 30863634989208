import { FC, ReactNode, createContext, useContext } from "react";

export type FullPageModalContextValue = {
  onClose: () => void;
};

const FullPageModalContext = createContext<FullPageModalContextValue>(
  {} as FullPageModalContextValue
);

type FullPageModalContextProviderProps = FullPageModalContextValue & {
  children: ReactNode;
};

export const FullPageModalContextProvider: FC<FullPageModalContextProviderProps> = ({
  children,
  onClose,
}) => {
  return (
    <FullPageModalContext.Provider
      value={{
        onClose,
      }}
    >
      {children}
    </FullPageModalContext.Provider>
  );
};

const useFullPageModalContext = () => {
  const modalContext = useContext(FullPageModalContext);
  if (modalContext === undefined) {
    throw new Error("useFullPageModalContext must be used within a FullPageModalContextProvider");
  }
  return modalContext;
};

export default useFullPageModalContext;
