import { ShieldStar } from "@phosphor-icons/react";
import { FC } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";

type Props = ButtonProps & {
  isSuperuserOnly?: boolean;
};

const SkipButton: FC<Props> = ({ children = "Skip", isSuperuserOnly, ...buttonV2Props }) => (
  <Button variant="ghost" type="button" {...buttonV2Props}>
    {isSuperuserOnly && <ShieldStar size={20} />}
    {children}
  </Button>
);

export default SkipButton;
