import BillSummaryRep from "reps/BillSummaryRep";
import { roundEpsilon } from "utils/math";

const getBillPercentPaid = (bill: BillSummaryRep.Complete): number | undefined => {
  const { amount, remainingAmount } = bill;

  if (amount && remainingAmount) {
    return roundEpsilon(100 - (Number(remainingAmount.amount) / Number(amount.amount)) * 100, 0);
  }
};

export default getBillPercentPaid;
