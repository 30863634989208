import BusinessMemberSummaryRep from "reps/BusinessMemberSummaryRep";

const useShouldShowAddNewBusiness = (businessSummaries: BusinessMemberSummaryRep.Complete[]) => {
  const hasAtLeastOneActiveBusiness = businessSummaries.some(
    (businessSummary) => businessSummary.businessStatus === "Active"
  );
  return hasAtLeastOneActiveBusiness;
};

export default useShouldShowAddNewBusiness;
