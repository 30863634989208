import { useCallback, useEffect, useRef, useState } from "react";

const useElementSize = <TElement extends HTMLElement = HTMLDivElement>() => {
  const elementRef = useRef<TElement>(null);
  const [size, setSize] = useState<{ width: number; height: number }>();

  const setElementSize = useCallback(() => {
    if (elementRef.current) {
      setSize({
        width: elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      });
    }
  }, [elementRef]);

  useEffect(() => {
    if (!elementRef.current) return;

    // Set initial size on mount
    setElementSize();

    // Reassignment fixes ts warning: "The ref value 'elementRef.current' will likely have changed [...] use that variable in the cleanup function. react-hooks/exhaustive-deps"
    const element = elementRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries?.length) return;
      setElementSize();
    });

    resizeObserver.observe(element);

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [elementRef, setElementSize]);

  return {
    elementRef,
    width: size?.width,
    height: size?.height,
  };
};

export default useElementSize;
