import { SortAscending, SortDescending } from "@phosphor-icons/react";
import classNames from "classnames";
import React, { MouseEventHandler } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./Header.module.scss";

import { Align, RenderHeaderOptions } from ".";

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const Header: React.FC<RenderHeaderOptions & Props> = ({
  align = "left",
  onClick,
  sortByThis,
  sortDirection,
  sortable,
  title,
}) => {
  const Icon = sortDirection === "ascending" ? SortAscending : SortDescending;

  const color = sortByThis ? colors.purple[500] : undefined;

  const content = (
    <>
      <Text color={colors.grey[600]} size={14} weight="regular">
        {title}
      </Text>
      {sortable ? (
        <div className={styles.icon}>
          <Icon height={20} width={20} color={color} />
        </div>
      ) : null}
    </>
  );

  if (sortable) {
    return (
      <button className={classNames(styles.header, alignClassName(align))} onClick={onClick}>
        {content}
      </button>
    );
  } else {
    return <div className={classNames(styles.header, alignClassName(align))}>{content}</div>;
  }
};

export default Header;

const alignClassName = (align: Align): string => {
  switch (align) {
    case "left":
      return styles.alignLeft;
    case "right":
      return styles.alignRight;
  }
};
