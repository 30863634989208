import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

dayjs.extend(customParseFormat);

/**
 * Use search params to save state that must be a valid option in an array of options.
 * @param key The key to use in the search params.
 * @param defaultValue The default value to use if the key is not present in the search params.
 */
const useSearchParamDayjs = (
  key: string,
  defaultValue: Dayjs
): [Dayjs, Dispatch<SetStateAction<Dayjs>>] => {
  const [searchParams, setSearchParams] = useSearchParams({
    [key]: defaultValue.format("YYYY-MM-DD"),
  });

  const dateStringFromSearchParams = searchParams.get(key) || "";
  const valueFromSearchParams = dayjs(dateStringFromSearchParams, "YYYY-MM-DD", true);
  const isValidValue = valueFromSearchParams.isValid();

  // We have to conditionally set the value to the default value if the initial value is not valid, otherwise requests may throw errors if they used the initial invalid default value.
  const value = isValidValue ? valueFromSearchParams : defaultValue;

  // If the value is not a valid option, set the value to be the default value
  useEffect(() => {
    if (!isValidValue) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        [key]: defaultValue.format("YYYY-MM-DD"),
      });
    }
  }, [isValidValue, defaultValue, key, searchParams, setSearchParams]);

  const setValue = (value: SetStateAction<Dayjs>) => {
    // It's important for us to redefine this here to prevent overwriting params if multiple setters get called at once.
    const currentParams = new URLSearchParams(window.location.search);

    if (typeof value === "function") {
      return setSearchParams({
        ...Object.fromEntries(currentParams.entries()),
        [key]: value(dayjs(currentParams.get(key)) ?? defaultValue).format("YYYY-MM-DD"),
      });
    }

    return setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      [key]: value.format("YYYY-MM-DD"),
    });
  };

  return [value, setValue];
};

export default useSearchParamDayjs;
