import BillLineItemRep from "reps/BillLineItemRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillLineItemApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByBillId(billId: string): Promise<BillLineItemRep.Complete[]> {
    const url = `/accounts-payable/bills/${billId}/bill-line-items`;
    return (await this.api.get<BillLineItemRep.Complete[]>(url))!;
  }

  async create(body: BillLineItemRep.Creator): Promise<BillLineItemRep.Complete> {
    const url = `/accounts-payable/bill-line-items`;
    return (await this.api.post<BillLineItemRep.Complete>(url, body))!;
  }

  async update(
    billLineItemId: string,
    body: BillLineItemRep.Updater
  ): Promise<BillLineItemRep.Complete> {
    const url = `/accounts-payable/bill-line-items/${billLineItemId}`;
    return (await this.api.patch<BillLineItemRep.Complete>(url, body))!;
  }

  async delete(billLineItemId: string): Promise<BillLineItemRep.Complete> {
    const url = `/accounts-payable/bill-line-items/${billLineItemId}`;
    return (await this.api.delete<BillLineItemRep.Complete>(url))!;
  }
}
