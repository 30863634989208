import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import { useCheckDepositFlexpaneContext } from "dialogs/CheckDepositFlexpane/context/CheckDepositFlexpaneProvider";
import getCheckDepositCounterpartyName from "resources/unit-co-check-deposits/utils/getCheckDepositCounterpartyName";
import colors from "styles/colors";
import Flexpane from "ui/overlay/Flexpane";
import Text from "ui/typography/Text";
import { formatBankingDate } from "utils/date";

import CheckDepositFlexpaneBankAccountParty from "../CheckDepositFlexpaneBankAccountParty";
import CheckDepositFlexpaneStatusPill from "../CheckDepositFlexpaneStatusPill";

const CheckDepositFlexpaneToFromSection = () => {
  const { checkDeposit } = useCheckDepositFlexpaneContext();

  return (
    <Flexpane.Section>
      <TransactionFlexpaneParties
        from={
          <>
            <Text color={colors.grey[800]} size={14} weight="medium">
              {getCheckDepositCounterpartyName(checkDeposit)}
            </Text>
            <Text size={14} color={colors.grey[500]}>
              Initiated {formatBankingDate(checkDeposit.attributes.createdAt)}
            </Text>
          </>
        }
        status={<CheckDepositFlexpaneStatusPill />}
        to={<CheckDepositFlexpaneBankAccountParty />}
      />
    </Flexpane.Section>
  );
};

export default CheckDepositFlexpaneToFromSection;
