import useIsAllowedToNavigateToDepositCheckPage from "./useIsAllowedToNavigateToDepositCheckPage";
import useIsAllowedToNavigateToSendMoney from "./useIsAllowedToNavigateToSendMoney";
import useIsAllowedToNavigateToTransferMoney from "./useIsAllowedToNavigateToTransferMoney";

const useIsAllowedToNavigateToMoveMoneyRoutes = () => {
  const isAllowedToNavigateToSendMoney = useIsAllowedToNavigateToSendMoney();
  const isAllowedToNavigateToTransferMoney = useIsAllowedToNavigateToTransferMoney();
  const isAllowedToNavigateToDepositCheckPage = useIsAllowedToNavigateToDepositCheckPage();
  return (
    isAllowedToNavigateToSendMoney ||
    isAllowedToNavigateToTransferMoney ||
    isAllowedToNavigateToDepositCheckPage
  );
};

export default useIsAllowedToNavigateToMoveMoneyRoutes;
