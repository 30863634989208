import {
  CurrencyOption,
  filterSupportedInternationalWireCountriesOptions,
  supportedInternationalWireCurrencies,
} from "pages/SendMoneyPage/internationalWires";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { internationalAddressState } from "state/payments/international/address";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import {
  selectedBankCountryInternationalWireOptionState,
  selectedBankCountryOptionState,
} from "state/payments/international/selectedBankCountry";
import Dropdown from "ui/inputs/Dropdown";
import { INITIAL_ADDRESS } from "utils/address";

import styles from "./PaymentCurrency.module.scss";

type Props = {
  className?: string;
  includeLegend?: boolean;
};
const PaymentCurrency: React.FC<Props> = ({ className, includeLegend = true }) => {
  const [internationalQuoteCurrencyOption, setInternationalQuoteCurrencyOption] =
    useRecoilState(quoteCurrencyState);
  const setSelectedBankCountryOption = useSetRecoilState(selectedBankCountryOptionState);
  const setInternationalAddress = useSetRecoilState(internationalAddressState);
  const selectedBankCountryInternationalWireOption = useRecoilValue(
    selectedBankCountryInternationalWireOptionState
  );

  return (
    <>
      {includeLegend && <legend className={styles.legend}>Payee’s Currency</legend>}

      <Dropdown
        className={className}
        onChange={(option) => {
          // Reset the address if the current selected country is not supported by the new currency
          // We select the country if it is the only one supported by the new currency
          if (
            option.value !== selectedBankCountryInternationalWireOption?.currencyCode &&
            option.value !== "USD"
          ) {
            const countryOptions = filterSupportedInternationalWireCountriesOptions(option.value);
            const country = countryOptions.length === 1 ? countryOptions[0] : null;

            setSelectedBankCountryOption(country);
            setInternationalAddress({ ...INITIAL_ADDRESS, country });
          }
          setInternationalQuoteCurrencyOption(option as CurrencyOption);
        }}
        value={internationalQuoteCurrencyOption}
        label="Currency"
        options={supportedInternationalWireCurrencies}
      />
    </>
  );
};

export default PaymentCurrency;
