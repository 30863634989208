import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import AccountBar from "ui/data-display/AccountBar";
import { Span } from "ui/typography";
import { HighbeamTransaction } from "utils/types/transactionsTypes";

type Props = {
  accountName: string;
  transaction: HighbeamTransaction;
};

const BankAccountDetail: React.FC<Props> = ({ accountName, transaction }) => (
  <>
    <AccountBar
      // TODO(alex): We _should_ be able to use `BankAccountBarByGuid` but `HighbeamTransaction` strips the counterparty info 🙄. Will fix later.
      icon={<HighbeamAccountIcon highbeamTypeName="DepositAccount" />}
      accountName={accountName}
      className="my-0.5 whitespace-nowrap"
    />
    <Span className="text-sm text-grey-500">
      {transaction.direction === "Debit" && transaction.formattedCreatedAt}
    </Span>
  </>
);

export default BankAccountDetail;
