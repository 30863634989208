import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

export type CapitalAccountSummaryWithChargeCard = CapitalAccountSummaryRep.ChargeCardOnlyComplete;

const checkIsCapitalAccountSummaryWithChargeCard = (
  capitalAccountSummary: CapitalAccountSummaryRep.Complete
): capitalAccountSummary is CapitalAccountSummaryWithChargeCard => {
  return capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly;
};

export default checkIsCapitalAccountSummaryWithChargeCard;
