import dayjs from "dayjs";
import { FC } from "react";

const YieldInfoTooltipContent: FC = () => {
  const nextMonth = dayjs().add(1, "month");

  return (
    <>
      Yield accrues daily and is paid out during the first week of every month. This yield total
      will update in the first week of {nextMonth.format("MMMM")}.
    </>
  );
};

export default YieldInfoTooltipContent;
