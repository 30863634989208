import {
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions as ReactQueryUseQueryOptions,
} from "@tanstack/react-query";

/**
 * Custom `UseQueryOptions` generic. Omits a few properties to make `useQueryOptions` extra reusable.
 *
 * Why do we remove certain keys?
 * `enabled` is removed because it is not available in `useSuspenseQuery`.
 * `queryFn` is omitted because we make it required. This allows us to more-easily reuse the `queryFn` if we want to alter its behavior.
 * `select` is omitted because this should be defined at the `useQuery` or `useSuspenseQuery` level.
 */
type UseQueryOptionsOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  ReactQueryUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "queryFn" | "enabled" | "throwOnError" | "placeholderData"
> & {
  // This makes `queryFn` required. It is slightly different from `QueryFunction` because `context` is optional.
  queryFn: (context?: QueryFunctionContext<TQueryKey>) => TQueryFnData | Promise<TQueryFnData>;
};

// NB(alex): Experimental, please hold off from using for now. Utility generic for extending `useQueryOptions` options.
export type UseQueryAdditionalOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<UseQueryOptionsOptions<TQueryFnData, TError, TData, TQueryKey>, "queryFn" | "queryKey">;

const useQueryOptions = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseQueryOptionsOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  return options as UseQueryOptionsOptions<TQueryFnData, TError, TData, TQueryKey>;
};

export default useQueryOptions;
