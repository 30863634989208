import { FEATURE_FLAGS } from "flags";
import { useRecoilValue } from "recoil";
import featureFlagsState from "state/featureFlags";

const useFeatureFlag = (flagKey: keyof typeof FEATURE_FLAGS) => {
  const featureFlags = useRecoilValue(featureFlagsState);
  const flagKeyValue = FEATURE_FLAGS[flagKey];
  return featureFlags[flagKeyValue];
};

export default useFeatureFlag;
