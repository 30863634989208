import { selector } from "recoil";

import jwtState from "./jwt";

/**
 * DEPRECATED: Use `useUserGuid` instead.
 *
 * userGuidState acts as an intermediate layer between userState and
 * jwtState to suppress re-rendering if the `user` value in the jwt state
 * does not change.
 */
const userGuidState = selector<string>({
  key: "auth/userGuid",
  get: async ({ get }) => {
    const jwt = get(jwtState);

    return jwt.user.guid;
  },
});

export default userGuidState;
