import { captureException } from "@sentry/react";
import useMfa from "modules/mfa/useMfa";
import PaymentDetailCreatorRep from "reps/payments-v2/PaymentDetailCreatorRep";
import PaymentRep from "reps/payments-v2/PaymentRep";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Variables =
  | PaymentDetailCreatorRep.AchCreator
  | PaymentDetailCreatorRep.DomesticWireCreator
  | PaymentDetailCreatorRep.InternationalWireCreator;

const useSendBillPaymentMutation = (
  additionalOptions: MutationAdditionalOptions<PaymentRep.Complete, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const { mfa } = useMfa();
  const { segmentTrack } = useSegment();

  return useMutationWithDefaults(
    {
      mutationFn: async (variables: Variables) => {
        await mfa();
        return highbeamApi.paymentV2.create({
          send: true,
          detail: variables,
        });
      },
      onSuccess: async (data) => {
        // NB(lev): For now, we intentionally do not perform any bill query invalidations
        // here and leave that to the caller. The reason for this is to do with the current
        // bill payment page implementation, which raises errors if the bill it's querying
        // is not in a ready for payment state. Invalidating that bill here causes a race
        // condition where the bill payment page can incorrectly raise an error because
        // the bill becomes no longer ready for payment (i.e. since it's now been paid)
        // before the bill payment page unmounts. The bill payment page should still be aware
        // of this and handle invalidation itself, once it's had a chance to prepare its
        // own state so that it doesn't incorrectly raise an error.

        if (data.status === "Failed") {
          notify("error", data.reason);
          captureException(new Error("Bill payment failed"), {
            extra: {
              data: data,
            },
          });
        }

        segmentTrack(SEGMENT_EVENTS.BILL_PAY_PAYMENT_CREATED);
      },
    },
    additionalOptions
  );
};

export default useSendBillPaymentMutation;
