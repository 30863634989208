import CheckSection from "pages/payments/PayeesPage/CreatePayeePage/CheckSection";
import SectionHeader from "ui/data-display/SectionHeader";
import CollapsibleFieldsetSet from "ui/inputs/CollapsibleFieldset/CollapsibleFieldsetSet";
import { Heading3 } from "ui/typography";

import AchInfoFieldset from "./AchInfoSection";
import InternationalWireSection from "./InternationalWireSection";
import WireInfoSection from "./WireInfoSection";

const PayeeInfoSection = () => (
  <>
    <SectionHeader>
      <Heading3>Payment info</Heading3>
    </SectionHeader>
    <CollapsibleFieldsetSet>
      <AchInfoFieldset />
      <WireInfoSection />
      <InternationalWireSection />
      <CheckSection />
    </CollapsibleFieldsetSet>
  </>
);

export default PayeeInfoSection;
