import LoadingPage from "modules/loading/pages/LoadingPage";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { localStorageLinkTokenState } from "state/localStorage/linkToken";
import { localStoragePostPlaidOauthRedirectPath } from "state/localStorage/postPlaidOauthRedirectPath";
import useHighbeamPlaidLink from "utils/customHooks/useHighbeamPlaidLink";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

/**
 * Completes the Plaid OAuth flow.
 *
 * Plaid OAuth redirects are not well-supported. This very well may not work.
 * If it does work, it doesn't show nice loading states, success messages, or
 * error messages.
 */
const PlaidOauthRedirectPage = () => {
  const { segmentTrack } = useSegment();
  const navigate = useNavigate();
  const existingLinkToken = useRecoilValue(localStorageLinkTokenState);
  const postPlaidOauthRedirectPath = useRecoilValue(localStoragePostPlaidOauthRedirectPath);
  const redirectAfterLink = useCallback(() => {
    navigate(postPlaidOauthRedirectPath);
  }, [navigate, postPlaidOauthRedirectPath]);
  const { openPlaid, isPlaidReady } = useHighbeamPlaidLink({
    linkToken: existingLinkToken,
    onSuccess: redirectAfterLink,
    onError: redirectAfterLink,
    onEarlyExit: redirectAfterLink,
  });
  useEffect(() => {
    if (isPlaidReady) {
      segmentTrack(SEGMENT_EVENTS.PLAID_OAUTH_FLOW_USED);
      openPlaid();
    }
  }, [openPlaid, isPlaidReady, segmentTrack]);
  if (isPlaidReady) {
    openPlaid();
  }
  return (
    <LoadingPage
      location={PlaidOauthRedirectPage.name}
      text={"Connecting your bank..."}
      showLoadingAnimation={false}
    />
  );
};

export default PlaidOauthRedirectPage;
