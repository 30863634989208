import { ListDashes } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import useBusinessDetailsForm from "resources/business-details/hooks/useBusinessDetailsForm";
import useUpdateBusinessDetailsMutation from "resources/business-details/hooks/useUpdateBusinessDetailsMutation";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import useIsAllowedToUpdateBusinessDetails from "utils/permissions/useIsAllowedToUpdateBusinessDetails";

import BusinessDetailsFormFields from "./BusinessDetailsFormFields";

const BusinessDetailsSection: FC = () => {
  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading icon={<ListDashes />}>
          Business details
        </DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <BusinessDetailsForm />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};
export default BusinessDetailsSection;

const BusinessDetailsForm: FC = () => {
  const form = useBusinessDetailsForm();
  const { mutate: updateBusinessDetails, isPending } = useUpdateBusinessDetailsMutation({
    onSuccess: (data) => {
      form.reset({
        ...form.getValues(),
        dba: data.dba ?? undefined,
        phoneNumber: data.phoneNumber ?? undefined,
      });
    },
  });
  const hasWritePermission = useIsAllowedToUpdateBusinessDetails();

  const handleSubmit = form.handleSubmit(async (data) => {
    updateBusinessDetails({
      dba: data.dba || undefined,
      phoneNumber: data.phoneNumber || undefined,
    });
  });

  return (
    <FormProvider {...form}>
      <form className="flex flex-col gap-y-8" onSubmit={handleSubmit}>
        <BusinessDetailsFormFields />

        <div>
          <ButtonWithTooltip
            isLoading={isPending}
            type="submit"
            variant="primary"
            disabled={!hasWritePermission || !form.formState.isDirty}
            tooltip={!hasWritePermission && "You don’t have permission to edit this information."}
          >
            Save changes
          </ButtonWithTooltip>
        </div>
      </form>
    </FormProvider>
  );
};
