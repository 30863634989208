import { EffectCallback, useEffect, useRef } from "react";

/**
 * Helper for running `useEffect` only on mount.
 */
const useMountEffect = (effectCallback: EffectCallback) => {
  const ignoreRef = useRef(false);
  const effectCallbackCleanupRef = useRef<ReturnType<EffectCallback>>(void null);

  useEffect(() => {
    // Prevents firing twice in React.StrictMode
    if (!ignoreRef.current) {
      effectCallbackCleanupRef.current = effectCallback();
      ignoreRef.current = true;
    }
    return () => {
      effectCallbackCleanupRef.current && effectCallbackCleanupRef.current();
      effectCallbackCleanupRef.current = void null;
      ignoreRef.current = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useMountEffect;
