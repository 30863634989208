import classNames from "classnames";

import styles from "./RoundedBox.module.scss";

type Props = {
  children: React.ReactNode;
  className?: string;
  padding?: 16 | 24 | 32;
};

const RoundedBox: React.FC<Props> = ({ children, className, padding = 32 }) => (
  <div
    className={classNames(className, styles.roundedBox, {
      [styles.p16]: padding === 16,
      [styles.p24]: padding === 24,
      [styles.p32]: padding === 32,
    })}
  >
    {children}
  </div>
);

export default RoundedBox;
