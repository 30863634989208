import { Calculator } from "@phosphor-icons/react";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import ButtonLink from "ui/inputs/ButtonLink";

const CreateYourOwnButton = () => (
  <ButtonLink variant="tertiary" to={`${CREDIT_COMPARISON_PATH}`}>
    <Calculator size={24} />
    Create your own
  </ButtonLink>
);

export default CreateYourOwnButton;
