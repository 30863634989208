import { Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";
import { captureException } from "@sentry/react";
import env from "env";
import { selector } from "recoil";

const CODE_RE = /[?&]code=[^&]+/u;
const SHOP_RE = /[?&]shop=[^&]+/u;
const STATE_RE = /[?&]state=[^&]+/u;
const ERROR_RE = /[?&]error=[^&]+/u;

type AppState = {
  returnTo?: string;
};

const auth0ClientState = selector<Auth0Client>({
  key: "auth/auth0Client",
  get: async () => {
    const auth0Client = await createClient();
    if (hasAuthParams()) {
      await handleRedirectCallback(auth0Client);
    }
    return auth0Client;
  },
  dangerouslyAllowMutability: true,
});

export default auth0ClientState;

const createClient = async (): Promise<Auth0Client> =>
  await createAuth0Client({
    authorizationParams: {
      audience: `https://${env.AUTH0_TENANT_DOMAIN}/api/v2/`,
      redirect_uri: window.location.origin, // eslint-disable-line camelcase
    },
    domain: env.AUTH0_CUSTOM_DOMAIN,
    clientId: env.AUTH0_CLIENT_ID,
  });

const hasAuthParams = (searchParams = window.location.search): boolean =>
  (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) &&
  STATE_RE.test(searchParams) &&
  // If the shop param is present, it is the Shopify auth0 flow
  !SHOP_RE.test(searchParams);

const isUserIsBlockedError = (error: unknown): boolean => {
  return error instanceof Error && error.message === "user is blocked";
};

/**
 * After redirecting back from Auth0 upon login,
 * this function should be called to restore the [AppState].
 */
const handleRedirectCallback = async (auth0Client: Auth0Client): Promise<void> => {
  let appState: AppState | undefined = undefined; // eslint-disable-line functional/no-let
  try {
    ({ appState } = await auth0Client.handleRedirectCallback<AppState>());
  } catch (e) {
    // No need to report auth exception if user is blocked
    if (!isUserIsBlockedError(e)) {
      captureException(e);
    }
  }
  replaceState(appState?.returnTo);
};

const replaceState = (url?: string): void => {
  window.history.replaceState({}, document.title, url ?? window.location.pathname);
};
