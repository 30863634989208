import { forwardRef, ForwardRefRenderFunction } from "react";
import { Link, LinkProps } from "react-router-dom";
import cn from "utils/tailwind/cn";

import { ButtonPaddingVariant, ButtonSize, ButtonVariant, getButtonClasses } from "../Button";

export type ButtonLinkProps = LinkProps & {
  variant?: ButtonVariant;
  paddingVariant?: ButtonPaddingVariant;
  size?: ButtonSize;
  disabled?: boolean;
};

const ButtonLink: ForwardRefRenderFunction<HTMLAnchorElement, ButtonLinkProps> = (
  {
    variant = "default",
    paddingVariant = "regular",
    className,
    size = "md",
    disabled = false,
    children,
    ...props
  },
  ref
) => {
  return (
    <Link
      ref={ref}
      className={cn(
        getButtonClasses({
          variant,
          paddingVariant,
          size,
        }),
        disabled && "pointer-events-none",
        className
      )}
      aria-disabled={disabled}
      data-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
      {...props}
    >
      {children}
    </Link>
  );
};

export default forwardRef(ButtonLink);
