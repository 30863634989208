import classNames from "classnames";
import { CSSProperties, FC, ReactNode } from "react";
import { SetRequired } from "type-fest";

import styles from "./PlatformAvatar.module.scss";

type PlatformAvatarImgProps = SetRequired<JSX.IntrinsicElements["img"], "src" | "alt">;

export const PlatformAvatarImg: FC<PlatformAvatarImgProps> = ({ alt, src, ...props }) => {
  return <img width="100%" height="100%" alt={alt} src={src} {...props} />;
};

type PlatformAvatarProps = {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
};

const PlatformAvatar: FC<PlatformAvatarProps> = ({ style, className, children }) => {
  return (
    <div className={classNames(className, styles.container)} style={style}>
      {children}
    </div>
  );
};

export default Object.assign(PlatformAvatar, {
  Img: PlatformAvatarImg,
});
