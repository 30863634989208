import IconWithTooltip from "components/common/IconWithTooltip";
import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import { useInternationalWirePaymentMetadata } from "resources/international-wire-payment-metadata/queries/useInternationalWirePaymentMetadata";
import { SwiftFeeTooltipContent } from "resources/international-wires/constants";
import { InternationalWireUnitCoPayment } from "resources/unit-co-payments/types";
import MetadataList from "ui/data-display/MetadataList";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Flexpane from "ui/overlay/Flexpane";
import Text from "ui/typography/Text";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";

type UsdAmountProps = {
  feeInCents: number;
  receivedAmountInCents: number;
};

const UsdAmount: React.FC<UsdAmountProps> = ({ feeInCents, receivedAmountInCents }) => {
  const fee = getDollarsFromCents(feeInCents ?? 0);
  const transferredAmount = getDollarsFromCents(receivedAmountInCents) - fee;
  return (
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Transferred to payee</MetadataList.ItemLabel>
        <Text size={18} weight="bold" numeric align="right">
          {formatAmount(transferredAmount, {
            withCents: true,
            currency: "USD",
            trailingCurrencyCode: true,
          })}
        </Text>
      </MetadataList.Item>
      <MetadataList.Item hideBorder>
        <MetadataList.ItemLabel>
          SWIFT fee <IconWithTooltip color="light" tooltip={<SwiftFeeTooltipContent />} />
        </MetadataList.ItemLabel>
        <MetadataList.ItemValue numeric align="right">
          {formatAmount(fee, { trailingCurrencyCode: true })}
        </MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel weight="bold">Total</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <TransactionFlexpaneAmount
            cents={receivedAmountInCents}
            direction={"negative"}
            currency={"USD"}
            trailingCurrencyCode
          />
        </MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  );
};

type LocalAmountProps = {
  feeInCents: number;
  receivedAmountInCents: number;
  receivedCurrency: string;
  totalAmountInCents: number;
};

const LocalAmount: React.FC<LocalAmountProps> = ({
  feeInCents: fee,
  receivedAmountInCents,
  receivedCurrency,
  totalAmountInCents,
}) => {
  const transferedAmountInCents = receivedAmountInCents - fee;
  const buyRate = (transferedAmountInCents / totalAmountInCents).toFixed(5);
  return (
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>You sent</MetadataList.ItemLabel>
        <MoneyAmount
          weight="medium"
          cents={totalAmountInCents}
          direction={"negative"}
          currency={"USD"}
          trailingCurrencyCode
        />
      </MetadataList.Item>

      <MetadataList.Item hideBorder>
        <MetadataList.ItemLabel>Exchange rate</MetadataList.ItemLabel>
        <Text size={14} weight="medium" numeric align="right">
          1 USD = {buyRate} {receivedCurrency}
        </Text>
      </MetadataList.Item>

      <MetadataList.Item>
        <MetadataList.ItemLabel weight="bold">Transferred to payee</MetadataList.ItemLabel>
        <TransactionFlexpaneAmount
          cents={transferedAmountInCents}
          direction="none"
          currency={receivedCurrency}
          trailingCurrencyCode
        />
      </MetadataList.Item>
    </MetadataList>
  );
};

type InternationalWireProps = {
  payment: InternationalWireUnitCoPayment;
};

const InternationalWirePaymentInfoFlexpaneAmountSection: React.FC<InternationalWireProps> = ({
  payment,
}) => {
  const paymentMetadata = useInternationalWirePaymentMetadata({
    paymentMetadataGuid: payment.attributes.tags.paymentMetadataGuid,
  });
  const isLocalCurrency = paymentMetadata?.paymentCurrency !== "USD";

  if (!paymentMetadata) {
    throw new Error("International wire payment without metadata is not supported");
  }

  return (
    <Flexpane.Section>
      {isLocalCurrency ? (
        <LocalAmount
          feeInCents={paymentMetadata.paymentFee}
          receivedAmountInCents={paymentMetadata.paymentAmount}
          receivedCurrency={paymentMetadata.paymentCurrency}
          totalAmountInCents={payment.attributes.amount}
        />
      ) : (
        <UsdAmount
          feeInCents={paymentMetadata.paymentFee}
          receivedAmountInCents={payment.attributes.amount}
        />
      )}
    </Flexpane.Section>
  );
};

export default InternationalWirePaymentInfoFlexpaneAmountSection;
