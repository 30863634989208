import { Card } from "@highbeam/unit-node-sdk";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { ROOT_CARDS_QUERY_NAME } from "./cardsQueryHooks";

type QueryKeyParams = {
  cardId: string;
};

const makeQueryKey = ({ cardId }: QueryKeyParams) => [ROOT_CARDS_QUERY_NAME, { cardId }];

export const useRefreshCardQuery = (params: QueryKeyParams) => {
  return useRefreshQuery(makeQueryKey(params));
};

type Params<TData = Card> = QueryKeyParams & {
  select?: (data: Card) => TData;
};

export const useCardQueryOptions = <TData = Card>({ cardId, select }: Params<TData>) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: makeQueryKey({ cardId }),
    queryFn: async () => {
      const response = await unitApi.cards.get(cardId);
      return response.data;
    },
    select: select,
  });
};

export const useCardQuery = <TData = Card>(params: Params<TData>) => {
  return useQuery(useCardQueryOptions(params));
};

const useCard = <TRequired extends boolean = false>({
  cardId,
  select,
  required,
}: Params & RequiredParam<TRequired>) => {
  const { data } = useSuspenseQuery(useCardQueryOptions({ cardId, select }));

  if (required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useCard;
