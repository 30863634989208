import { Dayjs } from "dayjs";
import { ReactNode } from "react";
import CashDisplay from "ui/data-display/money/CashDisplay";
import Text from "ui/typography/Text";
import { dateOnly } from "utils/date";

import styles from "./RecurringPaymentDetailsCard.module.scss";

type Props = {
  iconBesideAmount: ReactNode;
  amountInCents: number;
  from: ReactNode;
  to: ReactNode;
  scheduledDate?: Dayjs;
  deliveryMessage?: string;
  descriptionMessage?: string;
  routingNumber?: string;
  accountNumber?: string;
  footer?: ReactNode;
  toLabel?: string;
};

const RecurringPaymentDetailsCard: React.FC<Props> = ({
  iconBesideAmount,
  amountInCents,
  from,
  to,
  scheduledDate,
  deliveryMessage,
  descriptionMessage,
  routingNumber,
  accountNumber,
  footer,
  toLabel = "To",
}) => (
  <div className={styles.card}>
    <div className={styles.cardHeader}>
      <div className={styles.titleWithIcon}>
        {iconBesideAmount}
        <Text size={14} className={styles.text}>
          Amount per payment
        </Text>
      </div>
      <CashDisplay cents={amountInCents} />
    </div>
    <div className={styles.cardBody}>
      <div className={styles.payeeInfo}>
        <Text size={14} className={styles.payeeInfoTitle}>
          From
        </Text>
        <div className={styles.payeeInfoContent}>{from}</div>
      </div>
      <div className={styles.payeeInfo}>
        <Text size={14} className={styles.payeeInfoTitle}>
          {toLabel}
        </Text>
        <div className={styles.payeeInfoContent}>{to}</div>
      </div>
      {routingNumber && accountNumber && (
        <>
          <div className={styles.payeeInfo}>
            <Text size={14} className={styles.payeeInfoTitle}>
              Routing #
            </Text>
            <div className={styles.payeeInfoContent}>{routingNumber}</div>
          </div>
          <div className={styles.payeeInfo}>
            <Text size={14} className={styles.payeeInfoTitle}>
              Account #
            </Text>
            <div className={styles.payeeInfoContent}>{accountNumber}</div>
          </div>
        </>
      )}
      {scheduledDate && (
        <div className={styles.payeeInfo}>
          <Text size={14} className={styles.payeeInfoTitle}>
            Initiate on
          </Text>
          <div className={styles.payeeInfoContent}>
            {dateOnly(scheduledDate).format("MM/DD/YYYY")}
          </div>
        </div>
      )}
      {deliveryMessage && (
        <div className={styles.payeeInfo}>
          <Text size={14} className={styles.payeeInfoTitle}>
            Delivery
          </Text>
          <div className={styles.payeeInfoContent}>
            <Text size={14}>{deliveryMessage}</Text>
          </div>
        </div>
      )}
      {descriptionMessage && (
        <div className={styles.payeeInfo}>
          <Text size={14} className={styles.payeeInfoTitle}>
            Description
          </Text>
          <Text className={styles.payeeInfoContent} size={14}>
            {descriptionMessage}
          </Text>
        </div>
      )}
    </div>
    {footer && <div className={styles.cardFooter}>{footer}</div>}
  </div>
);

export default RecurringPaymentDetailsCard;
