import { selector } from "recoil";

import { quoteCurrencyState } from "./quoteCurrency";
import { quoteFixedSideState } from "./quoteFixedSideState";
import { quoteLocalAmountInCentsState } from "./quoteLocalAmountInCents";
import quotesState from "./quotes";
import { quoteUsdAmountInCentsState } from "./quoteUsdAmountInCents";

export const quoteCalculatedUsdAmountInCentsState = selector<number>({
  key: "payments/international/quoteCalculatedUsdAmountInCents",
  get: async ({ get }) => {
    const shouldCalculate = get(quoteFixedSideState) === "Receive";
    const usdAmount = get(quoteUsdAmountInCentsState);
    if (!shouldCalculate) return usdAmount;

    const localAmount = get(quoteLocalAmountInCentsState);
    const currency = get(quoteCurrencyState).value;
    const quote = get(quotesState(currency));

    return Math.round(quote.inverse * localAmount);
  },
});
