import React from "react";
import PaymentSwitcherAutoPaymentGroupRep from "reps/PaymentSwitcherAutoPaymentGroupRep";
import PaymentSwitcherAutoPaymentRep from "reps/PaymentSwitcherAutoPaymentRep";
import Last3MonthsCell from "resources/payment-switcher/components/AccountPayments/AccountTable/Last3MonthsCell";
import Table, { Column } from "ui/table/Table";

import styles from "./AccountTable.module.scss";
import LastPaymentCell from "./LastPaymentCell";
import SwitchedCell from "./SwitchedCell";
import VendorCell from "./VendorCell";

const columns: Column<PaymentSwitcherAutoPaymentRep>[] = [
  VendorCell.column,
  Last3MonthsCell.column,
  LastPaymentCell.column,
  SwitchedCell.column,
];

type Props = {
  group: PaymentSwitcherAutoPaymentGroupRep;
};

const AccountTable: React.FC<Props> = ({ group }) => {
  return (
    <Table
      className={styles.table}
      headerClassName={styles.header}
      cellClassName={styles.cell}
      rowKey={(payment) => payment.counterparty} // Counterparties are unique.
      data={group.payments}
      columns={columns}
    />
  );
};

export default AccountTable;
