import { ArrowFatLinesUp, Coins } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import LineOfCreditDrawdownButtonLink from "resources/capital-accounts/components/LineOfCreditDrawdownButtonLink";
import LineOfCreditRepayButtonLink from "resources/capital-accounts/components/LineOfCreditRepayButtonLink";
import getCapitalAccountTitle from "resources/capital-accounts/utils/getCapitalAccountTitle";
import useIsCapitalOverviewPageEnabled from "resources/capital/queries/useIsCapitalOverviewPageEnabled";
import ButtonLink from "ui/inputs/ButtonLink";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountHeader: FC<Props> = ({ capitalAccountSummary }) => {
  const isCapitalOverviewPageEnabled = useIsCapitalOverviewPageEnabled();
  const isChargeCardDetailsV2Enabled = useFeatureFlag("CAPITAL_ACCOUNT_CHARGE_CARD_DETAILS_V2");

  return (
    <>
      <DashboardPage.Header
        actions={
          (capitalAccountSummary.state === CapitalAccountRep.State.Active ||
            capitalAccountSummary.state === CapitalAccountRep.State.OfferAccepted) && (
            <div className="flex w-full gap-x-2">
              {capitalAccountSummary.type === CapitalAccountRep.Type.CashAccessOnly && (
                <div className="flex w-full">
                  <LineOfCreditRepayButtonLink
                    capitalAccountSummary={capitalAccountSummary}
                    className="rounded-r-none border-r-0"
                  />
                  <LineOfCreditDrawdownButtonLink
                    className="rounded-l-none"
                    capitalAccountSummary={capitalAccountSummary}
                  />
                </div>
              )}

              {isChargeCardDetailsV2Enabled &&
                capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly && (
                  <ButtonLink
                    variant="secondary"
                    to={`/capital/${capitalAccountSummary.guid}/cards/repay`}
                  >
                    <ArrowFatLinesUp size={20} weight="light" />
                    Repay
                  </ButtonLink>
                )}
            </div>
          )
        }
      >
        {isCapitalOverviewPageEnabled ? (
          <>
            <DashboardPage.Header.BackCaret to="/capital" />
            <DashboardPage.Header.IconTile
              icon={<CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} />}
            />
            <DashboardPage.Header.Title>
              {getCapitalAccountTitle(capitalAccountSummary)}
            </DashboardPage.Header.Title>
          </>
        ) : (
          <>
            <DashboardPage.Header.IconTile icon={<Coins />} />
            <DashboardPage.Header.Title>Capital</DashboardPage.Header.Title>
          </>
        )}
      </DashboardPage.Header>
    </>
  );
};

export default CapitalAccountHeader;
