import { cloneElement, ReactElement } from "react";
import colors from "styles/colors";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

export type IconRoundedness = "rounded-square" | "circle";

export type IconTileColor = "green" | "orange" | "red" | "purple";

type Props = {
  icon: ReactElement<{ size?: number; color?: string }>;
  roundedness?: IconRoundedness;
  color?: IconTileColor;
  className?: string;
};

const IconTile: React.FC<Props> = ({
  icon,
  roundedness: type = "rounded-square",
  color = "purple",
  className,
}) => {
  const defaultIconColor = variants(color, {
    green: colors.green[500],
    orange: colors.orange[500],
    red: colors.red[500],
    purple: colors.purple[500],
  });

  return (
    <div
      className={cn(
        "flex size-11 shrink-0 items-center justify-center",
        variants(type, {
          "rounded-square": "rounded-lg",
          circle: "rounded-full",
        }),
        variants(color, {
          green: "bg-green-100",
          orange: "bg-orange-100",
          red: "bg-red-100",
          purple: "bg-purple-100",
        }),
        className
      )}
    >
      {cloneElement(icon, {
        size: icon.props.size || 24,
        color: icon.props.color || defaultIconColor,
      })}
    </div>
  );
};

export default IconTile;
