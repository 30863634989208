import { useChatWidget } from "components/ChatWidget";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import colors from "styles/colors";
import InfoIcon from "ui/icons/InfoIcon";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import Modal from "ui/overlay/Modal";
import TableV2, { ColumnProps } from "ui/table/TableV2";
import Text from "ui/typography/Text";
import { getAccountType } from "utils/account";

import styles from "./ManualTransferFallbackModal.module.scss";
import manualTransferFallbackModalState, {
  useCloseManualTransferFallbackModal,
} from "./state/manualTransferFallbackModalState";

type ColumnDatum = {
  key: React.Key;
  accountDataType: string;
  info: React.ReactNode;
};

const ManualTransferFallbackModal = () => {
  const closeModal = useCloseManualTransferFallbackModal();
  const chat = useChatWidget();
  const primaryBankAccount = usePrimaryBankAccount({ required: true });

  const columns: ColumnProps<ColumnDatum>[] = [
    {
      key: "accountDataType",
      renderCell: ({ accountDataType }) => <Text size={14}>{accountDataType}</Text>,
      title: "Account Data Type",
    },
    {
      key: "info",
      renderCell: ({ info }) => <Text size={14}>{info}</Text>,
      title: "Info",
    },
  ];

  const AccountNumber = () => (
    <ItemWithCopyTextTooltip textToCopy={primaryBankAccount.accountNumber}>
      <Text size={14} color={colors.grey[900]} weight="medium">
        {primaryBankAccount.accountNumber}
      </Text>
    </ItemWithCopyTextTooltip>
  );
  const AccountName = () => (
    <Text size={14} color={colors.grey[900]} weight="medium">
      {primaryBankAccount.name}
    </Text>
  );

  const RoutingNumber = () => (
    <ItemWithCopyTextTooltip textToCopy={primaryBankAccount.routingNumber}>
      <Text size={14} color={colors.grey[900]} weight="medium">
        {primaryBankAccount.routingNumber}
      </Text>
    </ItemWithCopyTextTooltip>
  );

  const AccountType = () => (
    <Text size={14} color={colors.grey[900]} weight="medium">
      {getAccountType(primaryBankAccount)}
    </Text>
  );

  const data: ColumnDatum[] = [
    { key: 0, accountDataType: "Account name", info: <AccountName /> },
    { key: 1, accountDataType: "Account number", info: <AccountNumber /> },
    { key: 2, accountDataType: "Routing number", info: <RoutingNumber /> },
    { key: 3, accountDataType: "Account type", info: <AccountType /> },
  ];

  return (
    <Modal
      icon={<InfoIcon variant="info" />}
      title="Transferring large amounts"
      onClose={() => closeModal()}
    >
      <div className={styles.container}>
        <Text size={14} color={colors.grey[600]}>
          To fund your Highbeam account, you can ACH or wire funds from your other bank into your
          Highbeam account with no limits:
        </Text>
        <TableV2 columns={columns} fetchData={() => Promise.resolve({ data })}>
          <TableV2.Table>
            <TableV2.Body />
          </TableV2.Table>
        </TableV2>
        <Text size={14} color={colors.grey[600]}>
          For further assistance, please contact Highbeam{" "}
          <button
            onClick={() => {
              closeModal();
              chat.show();
            }}
            className={styles.support}
          >
            support
          </button>
          .
        </Text>
      </div>
    </Modal>
  );
};

const WrappedManualTransferFallbackModal = () => {
  const state = useRecoilValue(manualTransferFallbackModalState);

  if (!state.isOpen) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <ManualTransferFallbackModal />
    </Suspense>
  );
};

export default WrappedManualTransferFallbackModal;
