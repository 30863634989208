import { useReceivedPaymentInfoFlexpaneContext } from "dialogs/ReceivedPaymentInfoFlexpane/context/ReceivedPaymentInfoFlexpaneProvider";
import RetryAchReceivedPaymentModal from "modules/failed-debits/RetryAchReceivedPaymentModal";
import { useRetryAchReceivedPaymentModal } from "modules/failed-debits/RetryAchReceivedPaymentModal/state/RetryAchReceivedPaymentModalState";
import bankAccountByUnitCoDepositAccountIdQueryHooks from "resources/bank-accounts/queries/bankAccountByUnitCoDepositAccountIdQueryHooks";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import Flexpane from "ui/overlay/Flexpane";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";

const ReceivedPaymentInfoFlexpaneRetryButton = () => {
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();

  //We can leverage the existing payment create scope until we get a request to tighten the permissions
  const isAllowedToRetryPayment = useIsAllowedToApprovePayments();
  const { open: openRetryAchReceivedPaymentModal } = useRetryAchReceivedPaymentModal();
  const bankAccount = bankAccountByUnitCoDepositAccountIdQueryHooks.useData({
    unitCoDepositAccountId: receivedPayment.relationships.account.data.id,
  });
  const needsFunding =
    receivedPayment.attributes.returnReason === "InsufficientFunds" &&
    (bankAccount?.availableBalance || 0) < receivedPayment.attributes.amount;

  if (receivedPayment.attributes.status !== "MarkedForReturn") {
    return null;
  }

  return (
    <>
      <RetryAchReceivedPaymentModal />
      <Flexpane.Section>
        <div>
          <ButtonWithTooltip
            variant="primary"
            size="md"
            onClick={() => {
              openRetryAchReceivedPaymentModal({ receivedPaymentId: receivedPayment.id });
            }}
            disabled={!isAllowedToRetryPayment || needsFunding}
            tooltip={
              (!isAllowedToRetryPayment && "You don’t have permission to retry payments.") ||
              (needsFunding && "You don’t have enough funds in the account to retry this payment.")
            }
          >
            Retry debit
          </ButtonWithTooltip>
        </div>
      </Flexpane.Section>
    </>
  );
};

export default ReceivedPaymentInfoFlexpaneRetryButton;
