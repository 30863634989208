import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

type Params = { billId: string; dryRun: boolean };

const billSyncQueryHooks = makeQueryHooks({
  name: "bill-sync",
  useQueryVariables(params: Params) {
    return params;
  },
  useQueryFnMaker({ billId, dryRun }) {
    const highbeamApi = useHighbeamApi();
    return () => highbeamApi.billSync.sync(billId, dryRun);
  },
});

export default billSyncQueryHooks;
