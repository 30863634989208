import BusinessMemberRep from "reps/BusinessMemberRep";

const getBusinessMemberByUserGuid = (
  businessMembers: BusinessMemberRep.Complete[],
  userGuid: string
) => {
  return businessMembers.find((businessMember) => businessMember.userGuid === userGuid);
};

export default getBusinessMemberByUserGuid;
