import { useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useUnitCoRecurringPaymentQueryOptions from "./useUnitCoRecurringPaymentQueryOptions";

const useUnitCoRecurringPayment = <TRequired extends boolean>(
  recurringPaymentGuid: string,
  params?: RequiredParam<TRequired>
) => {
  const { data: recurringPayment } = useSuspenseQuery({
    ...useUnitCoRecurringPaymentQueryOptions(recurringPaymentGuid),
  });

  if (params?.required && !recurringPayment) {
    throw new RequiredButNotFoundError();
  }

  return recurringPayment as DataRequired<typeof recurringPayment, TRequired>;
};

export default useUnitCoRecurringPayment;
