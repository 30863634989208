import { zodResolver } from "@hookform/resolvers/zod";
import { Dayjs } from "dayjs";
import { CurrencyOption } from "pages/SendMoneyPage/internationalWires";
import { useForm } from "react-hook-form";
import PayeeRep from "reps/PayeeRep";
import { getCentsFromDollars } from "utils/money";
import { z } from "zod";

const billDetailsFormSchema = z.object({
  // NB(alex): Not sure if there's a better way to mark that a field is allowed to be `null`, but I also want to be able to validate that the field is non-nullable when trying to submit.
  payee: z.custom<PayeeRep.Complete | null>((value) => Boolean(value), "Please select a payee."),
  amount: z.object({
    amount: z.string().refine((value) => {
      const isGreaterThanZero = getCentsFromDollars(value) > 0;
      return isGreaterThanZero;
    }, "Please enter an amount greater than 0."),
    currency: z
      // NB(alex): We need this to be a `CurrencyOption` until we refactor `supportedInternationalWireCountries`.
      .custom<CurrencyOption | null>()
      .refine((value): value is NonNullable<typeof value> => {
        return Boolean(value);
      }, "Please select a currency."),
  }),
  invoiceDate: z.custom<Dayjs | null>((value) => Boolean(value), "Please select an invoice date."),
  invoiceDueDate: z.custom<Dayjs | null>(
    (value) => Boolean(value),
    "Please select an invoice due date."
  ),
  invoiceNumber: z.string().min(1, "Please enter an invoice number."),
  paymentTerms: z.string().nullable(),
  purchaseOrderNumber: z.string(),
  memo: z.string(),
});

export type BillDetailsFormInputs = z.input<typeof billDetailsFormSchema>;

export type BillDetailsFormTransformedOutputs = z.output<typeof billDetailsFormSchema>;

type UseBillDetailsFormParams = {
  disabled: boolean;
  defaultValues: BillDetailsFormInputs;
};

const useBillDetailsForm = (params: UseBillDetailsFormParams) => {
  return useForm<BillDetailsFormInputs, object, BillDetailsFormTransformedOutputs>({
    resolver: zodResolver(billDetailsFormSchema),
    ...params,
  });
};

export default useBillDetailsForm;
