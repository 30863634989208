import {
  ArrowRight,
  ArrowSquareOut,
  CheckCircle,
  EnvelopeSimple,
  PlusCircle,
  Trash,
} from "@phosphor-icons/react";
import { FC, useState } from "react";
import BusinessMemberSummaryRep from "reps/BusinessMemberSummaryRep";
import UserInvitationRep from "reps/UserInvitationRep";
import BusinessAvatar from "resources/business/components/BusinessAvatar";
import getBusinessSafeDisplayName, {
  NEW_BUSINESS_PLACEHOLDER_NAME,
} from "resources/business/utils/getBusinessSafeDisplayName";
import useArchiveBusinessMutation from "resources/businesses/mutations/useArchiveBusinessMutation";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useResendInvitationEmailMutation from "resources/user-invitations/mutations/useResendInvitationEmailMutation";
import useUserRoleMembershipDisplayName from "resources/user-roles/queries/useUserRoleMembershipDisplayName";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import Pill from "ui/data-display/Pill";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph, Span } from "ui/typography";
import sleep from "utils/async/sleep";
import makeSearchGmailLink from "utils/gmail/makeSearchGmailLink";

import BusinessDisambiguatorButton from "./BusinessDisambiguatorButton";

type UserInvitationProps = {
  userInvitation: UserInvitationRep.Complete;
};

export const BusinessDisambiguatorUserInvitation: FC<UserInvitationProps> = ({
  userInvitation,
}) => {
  const [hasResent, setHasResent] = useState(false);
  const { mutate: resendInvitationEmail, isPending } = useResendInvitationEmailMutation({
    onSuccess: async () => {
      await sleep(1500); // NB(alex): We want to delay the loading state slightly because clicking the `Open in Gmail` action below too early may cause the user to see a stale inbox view.
      setHasResent(true);
      notify("success", "Invite resent");
    },
  });

  return (
    <BusinessDisambiguatorButton>
      <BusinessAvatar variant="grey" businessName={userInvitation.businessDisplayName ?? ""} />
      <div className="flex flex-col gap-y-2 pb-1">
        <Span className="text-md">
          {userInvitation.businessDisplayName || NEW_BUSINESS_PLACEHOLDER_NAME} (invite sent)
        </Span>
      </div>

      <div className="ml-auto">
        {hasResent ? (
          <div className="flex items-center gap-x-2">
            <Span className="flex items-center gap-x-2 text-sm font-medium text-green-600">
              <CheckCircle className="size-4 shrink-0" /> Sent
            </Span>

            <Divider orientation="vertical" className="h-6" />

            <a
              href={makeSearchGmailLink({
                searchQuery: "invited to Highbeam",
                authUserEmail: userInvitation.emailAddress,
              })}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-x-1 text-sm"
            >
              Open in Gmail
              <ArrowSquareOut size={16} />
            </a>
          </div>
        ) : (
          <Button
            paddingVariant="bare"
            isLoading={isPending}
            onClick={() => resendInvitationEmail({ userInvitationGuid: userInvitation.guid })}
          >
            <EnvelopeSimple size={16} />
            Resend email invite
          </Button>
        )}
      </div>
    </BusinessDisambiguatorButton>
  );
};

type BusinessProps = {
  businessSummary: BusinessMemberSummaryRep.Complete;
  onClick: () => void;
};

export const BusinessDisambiguatorBusiness: FC<BusinessProps> = ({ businessSummary, onClick }) => {
  const userRoleMembershipDisplayName = useUserRoleMembershipDisplayName(
    businessSummary.businessGuid,
    useUserGuid()
  );

  const isPendingReview = businessSummary.businessStatus === "PendingReview";

  const { mutate: archiveBusinessApplication, isPending: isArchivingBusinessApplication } =
    useArchiveBusinessMutation();

  return (
    <BusinessDisambiguatorButton onClick={onClick}>
      <BusinessAvatar
        variant={isPendingReview ? "grey" : "purple"}
        businessName={businessSummary.businessDisplayName}
      />
      <div className="flex flex-col gap-y-2 pb-1">
        <Span className="text-md">
          {getBusinessSafeDisplayName({
            displayName: businessSummary.businessDisplayName,
            status: businessSummary.businessStatus,
          })}
        </Span>
        {userRoleMembershipDisplayName && (
          <Pill size="2xs" color="grey-light">
            {userRoleMembershipDisplayName}
          </Pill>
        )}
      </div>

      <div className="ml-auto flex items-center gap-x-4">
        {isPendingReview && (
          <ItemWithTooltip asChild tooltip={<Paragraph>Cancel application</Paragraph>}>
            <Button
              paddingVariant="square"
              variant="ghost"
              onClick={() => {
                archiveBusinessApplication({
                  businessGuid: businessSummary.businessGuid,
                });
              }}
              isLoading={isArchivingBusinessApplication}
            >
              <Trash size={24} className="text-red-600" />
            </Button>
          </ItemWithTooltip>
        )}

        {isPendingReview || businessSummary.userIsOnboarded ? (
          <ArrowRight weight="light" size={24} color={colors.purple[500]} />
        ) : (
          <Span className="text-sm font-medium text-purple-500">Finish onboarding</Span>
        )}
      </div>
    </BusinessDisambiguatorButton>
  );
};

type AddNewBusinessProps = {
  onClick: () => void;
};

export const BusinessDisambiguatorAddNewBusiness: FC<AddNewBusinessProps> = ({ onClick }) => {
  return (
    <BusinessDisambiguatorButton onClick={onClick}>
      <PlusCircle className="size-6 text-purple-500" />
      <Span className="text-sm text-purple-500">Add new business</Span>
    </BusinessDisambiguatorButton>
  );
};
