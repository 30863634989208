import { useMutation } from "@tanstack/react-query";
import useMfa from "modules/mfa/useMfa";
import { useState } from "react";
import BankAccountRep from "reps/BankAccountRep";
import useRefreshCapitalAccountTransactions from "resources/capital-account-transactions/queries/useRefreshCapitalAccountTransactions";
import { useRefreshAllCapitalAccountsQueries } from "resources/capital-accounts/queries/capitalAccountsQueryHooks";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";
import { v4 as uuidv4 } from "uuid";

import { LINE_OF_CREDIT_INTEREST_FEE_QUERY_KEY } from "../queries/useLineOfCreditInterestFee";

type Data = {
  lineOfCreditGuid: string;
  amountInCents: number;
  transferTo: BankAccountRep.Complete;
};

const useCreateDrawdownMutation = () => {
  const { mfa } = useMfa();
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  const refreshCapitalAccountsQueries = useRefreshAllCapitalAccountsQueries();
  const refreshCapitalAccountTransactions = useRefreshCapitalAccountTransactions();

  const refreshLineOfCreditInterestFeeQuery = useRefreshQuery([
    LINE_OF_CREDIT_INTEREST_FEE_QUERY_KEY,
  ]);

  // NB(alex): We should move this to `react-hook-form` once we get around to it. Context: https://highbeamco.slack.com/archives/C02M3GGQPHC/p1690914198052609?thread_ts=1690912209.739389&cid=C02M3GGQPHC
  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());

  return useMutation({
    mutationFn: async (data: Data) => {
      await mfa();
      return highbeamApi.lineOfCreditTransactions.createDrawdown(
        businessGuid,
        data.lineOfCreditGuid,
        data.amountInCents,
        idempotencyKey,
        data.transferTo.guid
      );
    },
    onError: () => {
      notify("warning", "There was an issue completing the transfer. Please try again.");
    },
    onSuccess: async () => {
      // Exemplar: `await` multiple queries to ensure the queries refreshed before we navigate.
      await Promise.all([
        refreshCapitalAccountsQueries(),
        refreshLineOfCreditInterestFeeQuery(),
        refreshCapitalAccountTransactions(),
      ]);
      notify("success", "Drawdown successful");
      setIdempotencyKey(uuidv4());
    },
  });
};

export default useCreateDrawdownMutation;
