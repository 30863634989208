import { FC } from "react";
import ModalV3 from "ui/overlay/ModalV3";

import MergedCounterpartiesCounterpartyRow from "./components/MergedCounterpartiesCounterpartyRow";
import MergedCounterpartiesListHeader from "./components/MergedCounterpartiesListHeader";
import {
  MergedCounterpartiesModalParams,
  MergedCounterpartiesModalProvider,
  useMergedCounterpartiesModalContext,
} from "./context";

const MergedCounterpartiesModalContent = () => {
  const { onClose, originalCounterparties } = useMergedCounterpartiesModalContext();

  return (
    <>
      <ModalV3.Header onClose={onClose}>Merged vendors</ModalV3.Header>

      <ModalV3.Body>
        <MergedCounterpartiesListHeader />

        {originalCounterparties.map((originalCounterparty, index) => (
          <MergedCounterpartiesCounterpartyRow
            key={originalCounterparty}
            originalCounterparty={originalCounterparty}
            isLastRow={index === originalCounterparties.length - 1}
          />
        ))}
      </ModalV3.Body>

      <ModalV3.Footer>
        <ModalV3.Footer.CloseButton onClick={onClose}>Cancel</ModalV3.Footer.CloseButton>
      </ModalV3.Footer>
    </>
  );
};

type Props = {
  params?: MergedCounterpartiesModalParams;
  onClose: () => void;
};

const MergedCounterpartiesModal: FC<Props> = ({ params, onClose }) => {
  const isOpen = Boolean(params);

  return (
    <ModalV3 isOpen={isOpen} onClose={onClose} dropdownOverflowHack>
      {params && (
        <MergedCounterpartiesModalProvider params={params} onClose={onClose}>
          <MergedCounterpartiesModalContent />
        </MergedCounterpartiesModalProvider>
      )}
    </ModalV3>
  );
};

export default MergedCounterpartiesModal;
