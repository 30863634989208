import { PauseCircle } from "@phosphor-icons/react";
import IconWithTooltip, { InfoIconColor } from "components/common/IconWithTooltip";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import useCapitalAccountAutoPayConnectedStoreTypes from "resources/capital-accounts/queries/useCapitalAccountAutoPayConnectedStoreTypes";
import getRemittanceRate from "resources/capital-accounts/utils/getRemittanceRate";
import ConnectedStoresAvatarGroup from "resources/connected-stores/components/ConnectedStoresAvatarGroup";
import HighlightItem, {
  HighlightItemSideRuleColor,
  HighlightItemSize,
} from "ui/data-display/HighlightItem";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  size?: HighlightItemSize;
  sideRuleColor?: HighlightItemSideRuleColor;
  infoIconColor?: InfoIconColor;
};

const CapitalAccountAutoPayConnectedStoresHighlightItem: FC<Props> = ({
  capitalAccountSummary,
  size,
  sideRuleColor,
  infoIconColor,
}) => {
  const remittanceRate = getRemittanceRate(capitalAccountSummary) ?? 0;
  const remittancePercent = remittanceRate * 100;

  const autoPayConnectedStoreTypes = useCapitalAccountAutoPayConnectedStoreTypes(
    capitalAccountSummary.guid
  );

  const onClickSetUpAutoPay = () => {
    Intercom("showNewMessage", "Hi, I would like to set up auto-pay for my line of credit.");
  };

  return (
    <HighlightItem
      size={size}
      sideRuleColor={sideRuleColor}
      label={
        <>
          Auto-pay
          {remittancePercent === 0 ? (
            <Pill color="grey-light">Off</Pill>
          ) : (
            <>
              {" from "}
              <IconWithTooltip
                color={infoIconColor}
                tooltip={
                  <Text size={14}>
                    {remittancePercent}% of every payout will be used to automatically pay back the
                    outstanding balance on your line of credit.
                  </Text>
                }
              />
            </>
          )}
        </>
      }
      value={
        autoPayConnectedStoreTypes.length >= 1 ? (
          <ConnectedStoresAvatarGroup
            connectedStoreTypes={autoPayConnectedStoreTypes}
            isGrey={remittancePercent === 0}
            size="sm"
          />
        ) : (
          <Button variant="tertiary" size="sm" onClick={onClickSetUpAutoPay}>
            Set up auto-pay
          </Button>
        )
      }
      subtext={
        remittancePercent === 0 ? (
          <>
            <PauseCircle /> Paused
          </>
        ) : (
          <>{remittancePercent}% of every payout</>
        )
      }
    />
  );
};

export default CapitalAccountAutoPayConnectedStoresHighlightItem;
