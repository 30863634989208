export const getScheme = (url: string) => {
  const scheme = url.split("://")[0];
  return scheme;
};

export const removeScheme = (url: string) => {
  const scheme = getScheme(url);
  return url.replace(`${scheme}://`, "");
};

export const getFileExtension = (fileUrl: string) => {
  const parts = fileUrl.split(".");
  if (parts.length < 2) return "";
  return parts.slice(-1)[0].toLowerCase();
};
