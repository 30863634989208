import { ComponentProps, FC, ReactNode } from "react";
import { SetRequired } from "type-fest";
import Button, { ButtonProps } from "ui/inputs/Button";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import BackButtonLink from "ui/navigation/BackButton";
import SkipButton from "ui/navigation/SkipButton";

import styles from "./MultiStepControls.module.scss";

const MultiStepControlsButton: FC<ButtonProps> = ({ ...buttonProps }) => {
  return <Button type="button" variant="tertiary" {...buttonProps} />;
};

type MultiStepControlsNextButtonProps = SetRequired<
  ComponentProps<typeof ButtonWithTooltip>,
  "form"
>;

const MultiStepControlsNextButton: FC<MultiStepControlsNextButtonProps> = (props) => {
  return <ButtonWithTooltip variant="primary" type="submit" {...props} />;
};

type MultiStepControlsProps = {
  children: ReactNode;
};

const MultiStepControls: FC<MultiStepControlsProps> = ({ children }) => {
  return <div className={styles.controlSection}>{children}</div>;
};

export default Object.assign(MultiStepControls, {
  Button: MultiStepControlsButton,
  NextButton: MultiStepControlsNextButton,
  BackButtonLink: BackButtonLink,
  SkipButton: SkipButton,
});
