import { DataRequired, LoginRequiredParam } from "utils/react-query/require-data";

import useJwt from "./useJwt";

const useUserGuid = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const jwt = useJwt(params);

  return (jwt?.user.guid ?? null) as DataRequired<string, TRequired>;
};

export default useUserGuid;
