import useMfa from "modules/mfa/useMfa";
import { useCallback } from "react";
import useModalState from "utils/dialog/useModalState";

import { CreateCardModalDefaultValues } from "../dialogs/CreateCardModal";

const useCreateCardModalState = () => {
  const {
    state: createCardModalState,
    open,
    close: closeCreateCardModal,
  } = useModalState<{ defaultValues?: CreateCardModalDefaultValues }>();
  const { mfa } = useMfa();

  const openCreateCardModal = useCallback(
    async (params?: Parameters<typeof open>[0]) => {
      await mfa();
      open(params ?? {});
    },
    [mfa, open]
  );

  return {
    createCardModalState,
    openCreateCardModal,
    closeCreateCardModal,
  };
};

export default useCreateCardModalState;
