import dayjs from "dayjs";
import { FC } from "react";
import { Paragraph } from "ui/typography";
import { getUsersLocalTimezoneAbbreviation } from "utils/date";

import DateCell from "../DateCell";

export const DATE_TIME_CELL_DATE_FORMAT = "MMM D, YYYY [at] h:mm:ss A";

type Props = {
  date: string;
};

const DateTimeCell: FC<Props> = ({ date }) => {
  return (
    <DateCell
      date={date}
      tooltip={
        <Paragraph>
          {dayjs(date).format(DATE_TIME_CELL_DATE_FORMAT)}{" "}
          {getUsersLocalTimezoneAbbreviation(new Date(date))}
        </Paragraph>
      }
    />
  );
};

export default DateTimeCell;
