import DocumentRep from "reps/DocumentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

// NB(alex): This is called `DocumentActionApi` in backend-v2 but adding "V2" to reduce ambiguity with backend-v1's `DocumentsApi`.
export default class DocumentV2ActionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async uploadUrl(documentId: string): Promise<DocumentRep.Complete> {
    const url = `/document/documents/${documentId}/upload-url`;
    return (await this.api.put<DocumentRep.Complete>(url))!;
  }
}
