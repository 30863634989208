import { CardCreditOrDebit } from "../types";

type Params = {
  cardholderFirstName: string;
  creditOrDebit: CardCreditOrDebit;
  capitalAccountName?: string;
};

const getDefaultCardName = ({
  cardholderFirstName,
  creditOrDebit,
  capitalAccountName,
}: Params): string => {
  // eslint-disable-next-line functional/no-let
  let cardName = `${cardholderFirstName}’s`;

  if (creditOrDebit === "credit") {
    if (capitalAccountName) {
      cardName += ` ${capitalAccountName}`;
    } else {
      cardName += " card";
    }
  } else {
    cardName += " debit card";
  }

  return cardName;
};

export default getDefaultCardName;
