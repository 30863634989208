import Shimmer from "ui/feedback/Shimmer";

const HighbeamCardFieldShimmer = () => (
  <Shimmer
    variant="transparent"
    additionalShimmerWrapperClassName="absolute inset-0 w-full h-full"
  />
);

export default HighbeamCardFieldShimmer;
