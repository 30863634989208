import { ArrowUpRight } from "@phosphor-icons/react";
import paymentsIcon from "assets/payments-icon.svg";
import successGif from "assets/success.gif";
import RecurringPaymentDetailsCard from "components/RecurringPaymentDetailsCard";
import { Dayjs } from "dayjs";
import {
  BankingInfo,
  getSendMoneyDeliveryMessage,
  PaymentMethodOption,
} from "pages/SendMoneyPage/utils";
import { useNavigate } from "react-router-dom";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import { TransferOption } from "utils/transfers";

import PayeeInfo, { Recipient } from "../PayeeInfo";

import styles from "./RecurringPaymentScheduled.module.scss";

type Props = {
  from: TransferOption;
  amountInCents: number;
  to: Recipient;
  scheduledDate: Dayjs;
  bankingInfo: BankingInfo;
  resetSendMoney: () => void;
  paymentMethodOption: PaymentMethodOption;
};

const RecurringPaymentScheduled: React.FC<Props> = ({
  from,
  amountInCents,
  to,
  scheduledDate,
  bankingInfo,
  resetSendMoney,
  paymentMethodOption,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <img className={styles.successGif} src={`${successGif}?a=${Math.random()}`} alt="success" />
      <div className={styles.heading}>
        <h1 className={styles.text}>Recurring payment scheduled</h1>
        <Text size={14} color={colors.grey[600]}>
          You can track the status of the payment under{" "}
          <img src={paymentsIcon} alt="payments icon" className={styles.icon} /> Payments /
          Upcoming.
        </Text>
      </div>
      <RecurringPaymentDetailsCard
        iconBesideAmount={<ArrowUpRight size={16} />}
        amountInCents={amountInCents}
        from={from.label}
        to={<PayeeInfo to={to} infoText="Recipient was sent a confirmation email." />}
        scheduledDate={scheduledDate}
        routingNumber={bankingInfo?.routingNumber}
        accountNumber={bankingInfo?.accountNumber}
        deliveryMessage={getSendMoneyDeliveryMessage(paymentMethodOption.value, true)}
      />
      <div className={styles.buttons}>
        <Button className={styles.button} variant="tertiary" onClick={resetSendMoney}>
          Make another payment
        </Button>
        <Button
          className={styles.button}
          variant="primary"
          onClick={() => navigate("/payments")}
          autoFocus
        >
          Track status
        </Button>
      </div>
    </>
  );
};

export default RecurringPaymentScheduled;
