import { Clock as ClockIcon } from "@phosphor-icons/react";
import { FC } from "react";
import HeroBanner from "ui/data-display/HeroBanner";
import BillPayIcon from "ui/icons/BillPayIcon";
import Button from "ui/inputs/Button";
import { Paragraph } from "ui/typography";

import billPayPreviewDesktopImg from "./assets/bill-pay-preview-desktop.webp";
import billPayPreviewPhoneImg from "./assets/bill-pay-preview-phone.webp";

const BillPayPreviewPhone: FC = () => (
  <div className="absolute left-0 top-0 flex w-[11.25rem] flex-col rounded-b-[1.95rem] shadow-sm shadow-focus">
    <div className="relative h-72 w-full border-x-[5.5px] border-black bg-white">
      <div className="absolute inset-0 overflow-hidden border-b border-b-grey-200">
        <img
          className="absolute inset-0 size-full object-cover object-center"
          src={billPayPreviewPhoneImg}
          alt="Bill pay preview (phone)"
          loading="lazy"
          decoding="async"
        />
      </div>
    </div>
    <div
      aria-hidden="true"
      className="flex h-6 w-full rounded-b-[1.95rem] border-[5.5px] border-t-0 border-x-black bg-white"
    >
      <div className="m-auto h-0.5 w-14 rounded-full bg-grey-600"></div>
    </div>
  </div>
);

const BillPayPreviewDesktop: FC = () => (
  <div className="absolute bottom-0 left-[13.25rem] right-0 top-10 overflow-hidden rounded-tl-lg border-l border-t border-purple-100 bg-grey-50 shadow-sm">
    <img
      className="absolute inset-0 size-full object-cover object-left-top"
      src={billPayPreviewDesktopImg}
      alt="Bill pay preview (desktop)"
      loading="lazy"
      decoding="async"
    />
  </div>
);

type Props = {
  onGetStarted: () => void;
};

const LaunchHeroBanner: FC<Props> = ({ onGetStarted }) => (
  <HeroBanner>
    <HeroBanner.Main>
      <HeroBanner.IconTile icon={<BillPayIcon />} />
      <HeroBanner.Heading>
        Automate your <abbr title="Accounts Payable">AP</abbr> with Highbeam Bill Pay
      </HeroBanner.Heading>
      <HeroBanner.Paragraph>Get your free preview of Highbeam Bill Pay.</HeroBanner.Paragraph>
      <HeroBanner.Actions>
        <Button variant="primary" onClick={() => onGetStarted()}>
          Get started
        </Button>
        <Paragraph className="flex items-center gap-1 text-inherit">
          <ClockIcon weight="light" className="h-6 w-6 text-grey-600" />
          Takes 1 minute
        </Paragraph>
      </HeroBanner.Actions>
    </HeroBanner.Main>
    <HeroBanner.Showcase>
      <div className="absolute inset-0">
        <BillPayPreviewPhone />
        <BillPayPreviewDesktop />
      </div>
    </HeroBanner.Showcase>
  </HeroBanner>
);

export default LaunchHeroBanner;
