import { DefaultValue, atom, selector } from "recoil";
import CreditComparisonSnapshotRep from "reps/CreditComparisonSnapshotRep";
import highbeamApiState from "state/auth/highbeamApi";

import sessionGuidState from "./sessionGuid";

const __resetterState = atom({
  key: "compareCreditOffers/snapshotResetter",
  default: 0,
});

const snapshotState = selector<CreditComparisonSnapshotRep.Complete | null>({
  key: "compareCreditOffers/snapshotState",
  get: async ({ get }) => {
    // Trigger a reset if the resetter state changes
    get(__resetterState);

    const highbeamApi = get(highbeamApiState("maybeAuthenticated"));
    const sessionGuid = get(sessionGuidState);
    return await highbeamApi.creditComparison.getLatestCreditComparisonSnapshot(sessionGuid);
  },
  set: ({ set }, newValue) => {
    // Refetch
    if (newValue instanceof DefaultValue) {
      set(__resetterState, (oldValue) => oldValue + 1);
    }
  },
});

export default snapshotState;
