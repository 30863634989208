import { Authorization, Transaction } from "@highbeam/unit-node-sdk";
import cashBackIcon from "assets/cashBack.svg";
import interestIcon from "assets/interest.svg";
import { getTransactionHighbeamType } from "utils/transactions";

type Result = {
  icon?: string;
  counterpartyFormattedName?: string;
};

const adobeMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    (transaction.attributes.merchant.name?.startsWith("ADOBE") ||
      transaction.attributes.merchant.name?.startsWith("PP*ADOBE"))
  ) {
    return { icon: "/assets/merchants/adobe.svg", counterpartyFormattedName: "Adobe" };
  }
};

const affirmMatcher = (transaction: Transaction) => {
  if (
    transaction.type === "receivedAchTransaction" &&
    transaction.attributes.companyName === "SHOPPAYINST AFRM"
  ) {
    return { icon: "/assets/merchants/affirm.svg", counterpartyFormattedName: "Affirm payout" };
  }
};

const amazonMatcher = (transaction: Transaction | Authorization) => {
  if (
    transaction.type === "receivedAchTransaction" &&
    transaction.attributes.companyName.startsWith("AMAZON.C")
  ) {
    return { icon: "/assets/merchants/amazon.svg", counterpartyFormattedName: "Amazon Payout" };
  } else if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    (transaction.attributes.merchant.name?.startsWith("AMAZON.COM") ||
      transaction.attributes.merchant.name?.startsWith("AMZN Mktp"))
  ) {
    return { icon: "/assets/merchants/amazon.svg", counterpartyFormattedName: "Amazon" };
  }
};

const americanExpressMatcher = (transaction: Transaction) => {
  if (
    transaction.type === "receivedAchTransaction" &&
    transaction.attributes.companyName.startsWith("AMEX")
  ) {
    const counterpartyFormattedName =
      transaction.attributes.summary === "AMEX EPAYMENT  |  ACH PMT" ? "Amex payment" : "Amex";

    return { icon: "/assets/merchants/american-express.svg", counterpartyFormattedName };
  }
};

const cashbackMatcher = (transaction: Transaction) => {
  const highbeamType = getTransactionHighbeamType(transaction);
  if (highbeamType === "cashback") return { icon: cashBackIcon };
};

const currencyCloudMatcher = (transaction: Transaction) => {
  if (
    transaction.type === "receivedAchTransaction" &&
    transaction.attributes.companyName === "Currency Cloud"
  ) {
    // The default currency cloud description is "Sent from"
    // Any collections we (Highbeam) create from Currency Cloud will have a description but
    // payments sent by Currency Cloud that we were not involved in will not have a description
    // This aims to provide a better description for those payments
    if (transaction.attributes.description === "Sent from") {
      return { counterpartyFormattedName: "International payment (Currency Cloud)" };
    }
    // Currency cloud description will be the payment reference entered on Currency cloud
    return { counterpartyFormattedName: transaction.attributes.description };
  }
};

const easypostMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name?.startsWith("EASYPOST")
  ) {
    return { icon: "/assets/merchants/easypost.svg", counterpartyFormattedName: "Easypost" };
  }
};

const facebookMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name?.startsWith("FACEBK")
  ) {
    return { icon: "/assets/merchants/facebook.svg", counterpartyFormattedName: "Facebook" };
  }
};

const googleMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name?.toLowerCase()?.startsWith("google")
  ) {
    return { icon: "/assets/merchants/google.svg", counterpartyFormattedName: "Google" };
  }
};

const interestMatcher = (transaction: Transaction) => {
  if (transaction.type === "interestTransaction") {
    return { icon: interestIcon, counterpartyFormattedName: "High yield interest" };
  }
};

const klaviyoMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name === "KLAVIYO INC. SOFTWARE"
  ) {
    return { icon: "/assets/merchants/klaviyo.svg", counterpartyFormattedName: "Klaviyo" };
  }
};

const microsoftMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name?.startsWith("Microsoft")
  ) {
    return { icon: "/assets/merchants/microsoft.svg", counterpartyFormattedName: "Microsoft" };
  }
};

const paypalPayoutMatcher = (transaction: Transaction) => {
  if (
    transaction.type === "receivedAchTransaction" &&
    transaction.attributes.companyName === "PAYPAL" &&
    transaction.attributes.description === "TRANSFER"
  ) {
    return { icon: "/assets/merchants/paypal.svg", counterpartyFormattedName: "PayPal payout" };
  }
};

const paypalMatcher = (transaction: Transaction) => {
  if (
    transaction.type === "receivedAchTransaction" &&
    transaction.attributes.companyName === "PAYPAL"
  ) {
    return { icon: "/assets/merchants/paypal.svg", counterpartyFormattedName: "PayPal" };
  }
};

const stripeMatcher = (transaction: Transaction) => {
  if (
    transaction.type === "receivedAchTransaction" &&
    transaction.attributes.companyName.toLowerCase().startsWith("stripe") &&
    transaction.attributes.summary.endsWith("|  TRANSFER")
  ) {
    return { icon: "/assets/merchants/stripe.svg", counterpartyFormattedName: "Stripe payout" };
  }
};

const shopifyMatcher = (transaction: Transaction | Authorization) => {
  if (
    transaction.type === "receivedAchTransaction" &&
    transaction.attributes.companyName.toLowerCase().startsWith("shopify capital")
  ) {
    return { icon: "/assets/merchants/shopify.svg", counterpartyFormattedName: "Shopify Capital" };
  } else if (
    transaction.type === "receivedAchTransaction" &&
    transaction.attributes.summary.toLowerCase().startsWith("shopify") &&
    transaction.attributes.summary.endsWith("|  TRANSFER")
  ) {
    return {
      icon: "/assets/merchants/shopify.svg",
      counterpartyFormattedName: "Shopify payout",
    };
  } else if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name?.toLowerCase().startsWith("shopify")
  ) {
    return { icon: "/assets/merchants/shopify.svg", counterpartyFormattedName: "Shopify" };
  }
};

const tiktokMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name?.startsWith("TIKTOK")
  ) {
    return { icon: "/assets/merchants/tiktok.svg", counterpartyFormattedName: "Tiktok" };
  }
};

const typeformMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name === "TYPEFORM, S.L."
  ) {
    return { icon: "/assets/merchants/typeform.svg", counterpartyFormattedName: "Typeform" };
  }
};

const uberMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name?.startsWith("UBER")
  ) {
    return { icon: "/assets/merchants/uber.svg", counterpartyFormattedName: "Uber" };
  }
};

const walmartMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name?.startsWith("Walmart.com")
  ) {
    return { icon: "/assets/merchants/walmart.svg", counterpartyFormattedName: "Walmart" };
  }
};

const wayflyerMatcher = (transaction: Transaction) => {
  if (
    transaction.type === "receivedAchTransaction" &&
    transaction.attributes.companyName === "Wayflyer"
  ) {
    return { icon: "/assets/merchants/wayflyer.svg", counterpartyFormattedName: "Wayflyer" };
  }
};

const wholeFoodsMatcher = (transaction: Transaction | Authorization) => {
  if (
    (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
    transaction.attributes.merchant.name?.startsWith("WHOLEFDS")
  ) {
    return { icon: "/assets/merchants/whole-foods.svg", counterpartyFormattedName: "Whole Foods" };
  }
};

const TRANSACTION_MATCHERS = [
  adobeMatcher,
  affirmMatcher,
  amazonMatcher,
  americanExpressMatcher,
  cashbackMatcher,
  currencyCloudMatcher,
  easypostMatcher,
  facebookMatcher,
  googleMatcher,
  interestMatcher,
  klaviyoMatcher,
  microsoftMatcher,
  paypalPayoutMatcher,
  paypalMatcher,
  // It is important that the stripe matcher is placed before the Shopify matcher
  // Shopify uses stripe as the underlying processor, so they look very similar
  // The Shopify matcher will match on all Stripe transactions as well
  stripeMatcher,
  shopifyMatcher,
  tiktokMatcher,
  typeformMatcher,
  uberMatcher,
  walmartMatcher,
  wayflyerMatcher,
  wholeFoodsMatcher,
];

export function getTransactionCounterpartyMetadata(transaction: Transaction): Result {
  const matcher = TRANSACTION_MATCHERS.find((matcher) => matcher(transaction));
  if (!matcher) return {};
  return matcher(transaction)!;
}
