import { DefaultValue, selectorFamily } from "recoil";

import offerInputsState from "./offerInputs";

const loanAmountState = selectorFamily<string, number>({
  key: "compareCreditOffers/loanAmountState",
  get:
    (offerIndex) =>
    ({ get }) => {
      const offerInputs = get(offerInputsState);
      const loanAmount = offerInputs[offerIndex].loanAmount;
      return loanAmount === null ? "" : loanAmount.toString();
    },
  set:
    (offerIndex) =>
    ({ set }, newValue) => {
      set(offerInputsState, (prev) => {
        const loanAmount =
          newValue === "" || newValue instanceof DefaultValue ? null : parseInt(newValue);
        return [
          ...prev.slice(0, offerIndex),
          {
            ...prev[offerIndex],
            loanAmount,
          },
          ...prev.slice(offerIndex + 1),
        ];
      });
    },
});

export default loanAmountState;
