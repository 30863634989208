import { useState } from "react";

type ModalState<TState extends object> = ({ isOpen: true } & TState) | { isOpen: false };

/**
 * Used for managing modal state.
 * When open, `isOpen` is true and additional state is included.
 * When closed, `isOpen` is false and no additional state is included.
 */
const useModalState = <TState extends object>(defaultState?: TState) => {
  const [modalState, setModalState] = useState<ModalState<TState>>(
    defaultState ? { ...defaultState, isOpen: true } : { isOpen: false }
  );

  const open = (state: TState) => {
    setModalState({ ...state, isOpen: true });
  };

  const close = () => {
    // Clear state.
    setModalState({ isOpen: false });
  };

  return { state: modalState, open, close };
};

export default useModalState;
