// Business guid for the demo account (Java Junction)
export const DEMO_BUSINESS_GUID = "2c01309b-0b2c-4058-9e1b-ed54d3634412";

type DemoStoreBalances = {
  [key: string]: number;
};

// Some static values for store balances
export const DEMO_STORE_BALANCES: DemoStoreBalances = {
  SHOPIFY: 543541,
  AMAZON: 143224,
  STRIPE: 654234,
  PAYPAL: 98102,
};
