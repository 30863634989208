import { ArrowRight } from "@phosphor-icons/react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import allStepsState from "state/compareCreditOffers/allSteps";
import Button from "ui/inputs/Button";
import BackButtonLink from "ui/navigation/BackButton";

import useIsMatchingSessionAndSnapshot from "../hooks/useIsMatchingSessionAndSnapshot";
import getPathFromStepIndex from "../utils/getPathFromStepIndex";
import getStepIndexFromPathname from "../utils/getStepIndexFromPathname";

import styles from "./FormControls.module.scss";
import ResetComparisonButton from "./ResetComparisonButton";

type Props = {
  isLoading: boolean;
  isSubmitDisabled: boolean;
  submitText?: string;
};

const FromControls: React.FC<Props> = ({ isLoading, isSubmitDisabled, submitText = "Next" }) => {
  const allSteps = useRecoilValue(allStepsState);
  const { pathname } = useLocation();

  const isResultsPath = pathname.includes("/results");
  const isMatchingSessionAndSnapshot = useIsMatchingSessionAndSnapshot();

  const currentStepIndex = getStepIndexFromPathname(allSteps, pathname);

  return (
    <div className={styles.controlSection}>
      {isResultsPath && !isMatchingSessionAndSnapshot ? null : currentStepIndex > 0 ? (
        <BackButtonLink to={getPathFromStepIndex(allSteps, currentStepIndex - 1)} />
      ) : (
        <ResetComparisonButton />
      )}
      {currentStepIndex < allSteps.length - 1 && (
        <Button variant="primary" type="submit" isLoading={isLoading} disabled={isSubmitDisabled}>
          {submitText}
          <ArrowRight />
        </Button>
      )}
    </div>
  );
};

export default FromControls;
