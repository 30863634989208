import { useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

type Params = {
  billId: string;
};

const BASE_BILL_PAYMENTS_QUERY_KEY = "billPayments";

const useBillPaymentsQueryOptions = ({ billId }: Params) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [BASE_BILL_PAYMENTS_QUERY_KEY, billId],
    queryFn: async () => {
      return await highbeamApi.billPayment.search(billId);
    },
  });
};

const useBillPayments = (params: Params) => {
  const { data } = useSuspenseQuery(useBillPaymentsQueryOptions(params));
  return data;
};

export default useBillPayments;
