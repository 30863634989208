import React, { ReactNode } from "react";

import HowToSwitch from "./HowToSwitch";
import NewCardDetails from "./NewCardDetails";
import styles from "./SwitchHelper.module.scss";

type Props = {
  children: ReactNode;
};

const SwitchHelper: React.FC<Props> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default Object.assign(SwitchHelper, { HowToSwitch, NewCardDetails });
