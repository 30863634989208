import emptyImg from "assets/empty-state.svg";
import classNames from "classnames";
import { FAILED } from "pages/accounts/AccountsTransactionsPage/Transactions/utils";
import { useEffect } from "react";
import PageIndicator from "ui/navigation/PageIndicator";
import EmptyState from "ui/table/EmptyState";

import styles from "./Transactions.module.scss";
import TransactionsFilters, { TransactionsFiltersProps } from "./TransactionsFilters";
import TransactionsTable, { TransactionsTableProps } from "./TransactionsTable";

type BaseProps = TransactionsTableProps & TransactionsFiltersProps;
type Props = {
  className?: string;
  refreshTransactions?: () => void;
} & BaseProps;

const Transactions: React.FC<Props> = ({
  transactions,
  searchQuery,
  setSearchQuery,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  pagination,
  tabs,
  activeTab,
  setActiveTab,
  hasExport,
  isLoading,
  className,
  accountOptions,
  selectedAccount,
  setSelectedAccount,
  unitCoDepositAccountId,
  refreshTransactions,
  searchDisabledOnNonCompletedTabs,
  hideBalanceColumn,
}) => {
  useEffect(() => {
    if (fromDate && toDate && toDate < fromDate) {
      setToDate?.(fromDate);
    }
  }, [fromDate, setToDate, toDate]);

  return (
    <div className={classNames(styles.container, className)}>
      <TransactionsFilters
        isLoading={isLoading}
        transactions={transactions}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        pagination={pagination}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        hasExport={hasExport}
        accountOptions={accountOptions}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
        unitCoDepositAccountId={unitCoDepositAccountId}
        searchDisabledOnNonCompletedTabs={searchDisabledOnNonCompletedTabs}
      />
      {isLoading || transactions.length > 0 ? (
        <>
          <TransactionsTable
            hideBalanceColumn={activeTab === FAILED || hideBalanceColumn}
            isLoading={isLoading}
            transactions={transactions}
            refreshTransactions={refreshTransactions}
          />
          {pagination && pagination.totalPages > 1 && <PageIndicator {...pagination} />}
        </>
      ) : (
        <EmptyState
          image={emptyImg}
          secondaryText="Once you have transactions, they will be shown here."
          primaryText="No transactions available"
        />
      )}
    </div>
  );
};

export default Transactions;
