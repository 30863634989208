import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";
import { HighbeamOriginatedAchTransaction } from "utils/types/transactionsTypes";

import TraceNumberLabel from "./TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";

type Props = {
  transaction: HighbeamOriginatedAchTransaction;
};

const OriginatedAchTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>ACH transaction details</Heading3>
    <MetadataList>
      {transaction.addenda && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{transaction.addenda}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      <MetadataList.Item>
        <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.counterpartyRoutingNumber}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.counterpartyAccountNumber}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Account type</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.counterpartyAccountType}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>
          <TraceNumberLabel />
        </MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          {transaction.traceNumber ? transaction.traceNumber : "Available after payment sends"}
        </MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default OriginatedAchTransactionDetailsSection;
