import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { bankingDay } from "utils/date";

type Variables = {
  capitalAccountGuid: string;
};

const useUpdateLineOfCreditUserActionsMetadataMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutation({
    mutationFn: ({ capitalAccountGuid }: Variables) => {
      return highbeamApi.lineOfCredit.updateLineOfCreditUserActionsMetadata(
        businessGuid,
        capitalAccountGuid,
        {
          unsignedAgreementOpenedAt: bankingDay().toISOString(),
        }
      );
    },
    onError: () => {
      notify("error", "Failed to update user actions. Please try again.");
    },
  });
};

export default useUpdateLineOfCreditUserActionsMetadataMutation;
