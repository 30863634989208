import classNames from "classnames";
import React, {
  ChangeEvent,
  FocusEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";

import InputWrapper, { shouldShowLabelAsFocused, CommonInputProps } from "../InputWrapper";

import styles from "./TextInput.module.scss";

export type OnTextInputChange = {
  suffixValue?: string;
  icon?: ReactElement;
  errorMessage?: string;
};

type TextInputOptions = {
  initialValue?: string;
  onChange?: (value: string) => Promise<OnTextInputChange>;
};

export type TextInputState = {
  value: string;
  onChange: (value: string) => void;
  onBlur: FocusEventHandler<HTMLInputElement>;
  setTouched: () => void;
  isLoading: boolean;
  suffixValue?: string;
  icon?: ReactElement;
  isValid: boolean;
  hasError: boolean;
  errorMessage: string | undefined;
};

export const validator =
  (
    validator: (value: string) => boolean,
    errorMessage: string,
    required?: boolean
  ): ((value: string) => Promise<OnTextInputChange>) =>
  (value) => {
    const isValid = (required === false && !value) || validator(value);
    return Promise.resolve({ errorMessage: isValid ? undefined : errorMessage });
  };

export const useTextInput = (options: TextInputOptions = {}): TextInputState => {
  const [touched, setTouched] = useState(false);
  const [previousValue, setPreviousValue] = useState("");
  const [value, setValue] = useState(options.initialValue ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const [tic, setTic] = useState<OnTextInputChange>({});
  const onBlur = useCallback(() => {
    if (value !== previousValue) setTouched(true);
    setPreviousValue(value);
  }, [previousValue, value]);
  useEffect(() => {
    (async () => {
      if (!options.onChange) return;
      setTic({ icon: <AnimatedSpinner size={20} /> });
      setIsLoading(true);
      setTic(await options.onChange(value));
      setIsLoading(false);
    })();
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    value,
    onChange: setValue,
    onBlur,
    setTouched: () => setTouched(true),
    isLoading,
    suffixValue: tic.suffixValue,
    icon: tic.icon,
    isValid: !tic.errorMessage,
    hasError: touched && Boolean(tic.errorMessage),
    errorMessage: tic.errorMessage,
  };
};

export enum Filter {
  DIGITS,
  ALPHABETS,
}

export type TextInputProps = {
  placeholder?: string;
  icon?: React.ReactElement;
  onIconClick?: () => void;
  onClick?: () => void;
  onChange?: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  value?: string;
  type?: string;
  maxLength?: number;
  inputFilter?: Filter;
  autoFocus?: boolean;
  autoComplete?: "on" | "off";
} & CommonInputProps;

const TextInput: ForwardRefRenderFunction<HTMLInputElement, TextInputProps> = (
  {
    id,
    label,
    placeholder,
    icon,
    onIconClick,
    onClick,
    type,
    onChange,
    onBlur,
    onFocus,
    value,
    prefixValue,
    suffixValue,
    append,
    hasError,
    errorMessage,
    maxLength,
    inputFilter,
    disabled,
    className,
    autoFocus = false,
    autoComplete,
    onKeyDown,
    onKeyUp,
  },
  ref
) => {
  const [focusOutline, setFocusOutline] = useState(false);
  const showLabelAsFocused = shouldShowLabelAsFocused(label, value, placeholder, focusOutline);

  return (
    <InputWrapper
      id={id}
      disabled={disabled}
      focusOutline={focusOutline}
      showLabelAsFocused={showLabelAsFocused}
      className={classNames(
        className,
        styles.container,
        showLabelAsFocused && styles["container--focused"],
        label && styles["container--labelled"]
      )}
      label={label}
      icon={icon}
      onIconClick={onIconClick}
      onClick={onClick}
      hasValue={Boolean(value)}
      prefixValue={prefixValue}
      suffixValue={suffixValue}
      append={append}
      hasError={hasError}
      errorMessage={errorMessage}
    >
      <input
        ref={ref}
        type={type || "text"}
        id={id}
        aria-describedby={placeholder}
        placeholder={placeholder}
        onClick={onClick}
        autoComplete={autoComplete}
        onFocus={(e) => {
          setFocusOutline(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocusOutline(false);
          onBlur?.(e);
        }}
        disabled={disabled}
        onChange={(e) => {
          const getFilteredValue = (val: string) => {
            switch (inputFilter) {
              case Filter.ALPHABETS:
                return val.replace(/[^a-z]/gi, "");
              case Filter.DIGITS:
                return val.replace(/[^0-9]/gi, "");
              default:
                return val;
            }
          };

          const filteredValue = getFilteredValue(e.currentTarget.value);

          if (!maxLength) {
            return onChange?.(filteredValue, e);
          }
          return onChange?.(filteredValue.slice(0, maxLength), e);
        }}
        value={value}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
    </InputWrapper>
  );
};

export default forwardRef(TextInput);
