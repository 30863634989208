import useHighYieldInterestYtd from "modules/high-yield/queries/useHighYieldInterestYtd";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import { TextSize } from "ui/typography/Text/TextTypes";

type Props = {
  accountGuid: string;
  dollarsTextSize?: TextSize;
  centsTextSize?: TextSize;
};

const HighYieldInterestYtd: React.FC<Props> = ({
  accountGuid,
  dollarsTextSize = 28,
  centsTextSize = 16,
}) => {
  const interest = useHighYieldInterestYtd({ highYieldAccountGuid: accountGuid });

  return (
    <MoneyAmount
      color={interest > 0 ? "green" : undefined}
      size={dollarsTextSize}
      centsTextSize={centsTextSize}
      cents={interest}
      weight="medium"
    />
  );
};

export default HighYieldInterestYtd;
