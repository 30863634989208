import { ChartLineUp, Clock } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, ReactNode } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import UpsellCapitalTeaserStatusBadge from "../UpsellCapitalTeaserStatusBadge";

import styles from "./StartCreditApplicationTeaser.module.scss";

type StartCreditApplicationTeaserProps = {
  showInProgressBadge: boolean;
  startApplicationButton: ReactNode;
};

const StartCreditApplicationTeaser: FC<StartCreditApplicationTeaserProps> = ({
  showInProgressBadge,
  startApplicationButton,
}) => {
  return (
    <div className={classNames(styles.container, styles.startApplication)}>
      <div className={styles.left}>
        <div className={styles.contentContainer}>
          <div className={styles.copyContainer}>
            {showInProgressBadge ? (
              <UpsellCapitalTeaserStatusBadge status="in-progress" />
            ) : (
              <div className={styles.chartIconOutline}>
                <ChartLineUp size={32} weight="light" />
              </div>
            )}

            <Text as="h3" color={colors.grey[900]} size={20} lineHeight={32} weight="bold">
              Grow with Highbeam Capital
            </Text>

            <Text as="p" color={colors.grey[600]} size={16} lineHeight={24} weight="regular">
              Flexible, reliable, and transparent capital paired with expert advisory services to
              fuel your brand’s growth.{" "}
            </Text>
          </div>

          <div className={styles.actionsContainer}>
            {startApplicationButton}

            <div className={styles.timeEstimateContainer}>
              <Clock size={20} weight="light" />
              <Text as="p" color={colors.grey[600]} size={14} lineHeight={20} weight="regular">
                Takes 5 minutes
              </Text>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.right} />
    </div>
  );
};

export default StartCreditApplicationTeaser;
