import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import cn from "utils/tailwind/cn";

type Props = {
  slug: string;
  className?: string;
};

const ClickToCopyInviteLink: React.FC<Props> = ({ slug, className }) => {
  const inviteLink = `${window.location.origin}/invitation/${slug}`;
  return (
    <ItemWithCopyTextTooltip
      textToCopy={inviteLink}
      accented
      className={cn("hide-scrollbars w-full overflow-x-auto text-nowrap text-sm", className)}
      tooltipContent="Copy invite link"
    >
      {inviteLink}
    </ItemWithCopyTextTooltip>
  );
};

export default ClickToCopyInviteLink;
