import { DependencyList, useCallback, useEffect } from "react";

/**
 * Listens for keyboard events that match the given {@param filter}.
 * The {@param handler} will be called when an event is found.
 * {@param deps} is a React dependency list, just like you'd pass to {@see useCallback} or {@see useEffect}.
 */
const useKeyboardEvent = (
  filter: (event: KeyboardEvent) => boolean,
  handler: (event: KeyboardEvent) => void,
  deps: DependencyList
) => {
  const handleEvent = useCallback(
    (event: KeyboardEvent) => {
      if (!(event instanceof KeyboardEvent)) {
        // This should never happen.
        return;
      }
      if (!filter(event)) {
        return;
      }
      handler(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEvent);
    return () => document.removeEventListener("keydown", handleEvent);
  }, [handleEvent]);
};

export default useKeyboardEvent;
