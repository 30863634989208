import { FeeInputType } from "state/compareCreditOffers/inputs/offerInputs/offerInputs";
import { roundEpsilon } from "utils/math";

const getFeeAmountByInputType = (
  loanAmount: number,
  fee: number,
  feeInputType: FeeInputType
): number =>
  feeInputType === "Percentage"
    ? roundEpsilon((fee / 100) * (loanAmount || 0), 2) * 100
    : fee * 100;

export default getFeeAmountByInputType;
