import { PlaidPendingConnection } from "modules/plaid/components/connection-cards/PlaidPendingConnectionCardContents";
import PlaidConnectionRep from "reps/PlaidConnectionRep";

const compareConnections = (
  a: PlaidConnectionRep.Complete | PlaidPendingConnection,
  b: PlaidConnectionRep.Complete | PlaidPendingConnection
): number => {
  const institutionNameA =
    "institutionName" in a ? a.institutionName : a.linkSuccessMetadata.institution!.name;
  const institutionNameB =
    "institutionName" in b ? b.institutionName : b.linkSuccessMetadata.institution!.name;

  // First, sort by institution name (alphabetical)
  if (institutionNameA < institutionNameB) {
    return -1;
  }
  if (institutionNameA > institutionNameB) {
    return 1;
  }

  // If institution names are the same, check for connection GUID (null for pending connections)
  const guidA = "guid" in a ? a.guid : null;
  const guidB = "guid" in b ? b.guid : null;

  // Sort pending connections (with no guid) after those with a guid
  if (guidA === null && guidB !== null) {
    return 1;
  }
  if (guidA !== null && guidB === null) {
    return -1;
  }

  // If both have a GUID (or both are pending), sort by GUID
  if (guidA && guidB) {
    if (guidA < guidB) {
      return -1;
    }
    if (guidA > guidB) {
      return 1;
    }
  }

  // If everything else is equal, return 0 (they're equal)
  return 0;
};

export default compareConnections;
