import { Export, SpinnerGap } from "@phosphor-icons/react";
import Button from "ui/inputs/Button";

type Props = React.ComponentProps<typeof Button> & {
  isLoading: boolean;
};

const ExportButton: React.FC<Props> = ({ isLoading, ...buttonProps }) => (
  <Button variant="tertiary" size="xs" disabled={isLoading} {...buttonProps}>
    {isLoading ? <SpinnerGap /> : <Export />}
    {isLoading ? "Exporting..." : "Export"}
  </Button>
);

export default ExportButton;
