import { Unit, DepositAccountLimits } from "@highbeam/unit-node-sdk";
import { captureMessage } from "@sentry/react";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { UNIT_ACCOUNT_LIMITS_QUERY_KEY } from "./useUnitCoAccountLimitsQueryOptions";

const UNLIMITED_LIMIT: DepositAccountLimits = {
  id: "", // NB(alex): Fixing ts error after upgrading the unit sdk. I think this works but not 100% sure.
  type: "limits",
  attributes: {
    ach: {
      limits: {
        dailyDebit: Number.MAX_SAFE_INTEGER,
        dailyCredit: Number.MAX_SAFE_INTEGER,
        monthlyDebit: Number.MAX_SAFE_INTEGER,
        monthlyCredit: Number.MAX_SAFE_INTEGER,
      },
      totalsDaily: {
        debits: 0,
        credits: 0,
      },
      totalsMonthly: {
        debits: 0,
        credits: 0,
      },
    },
    card: {
      limits: {
        dailyWithdrawal: Number.MAX_SAFE_INTEGER,
        dailyDeposit: Number.MAX_SAFE_INTEGER,
        dailyPurchase: Number.MAX_SAFE_INTEGER,
        dailyCardTransaction: Number.MAX_SAFE_INTEGER,
      },
      totalsDaily: {
        withdrawals: 0,
        deposits: 0,
        purchases: 0,
        cardTransactions: 0,
      },
    },
    checkDeposit: {
      limits: {
        daily: Number.MAX_SAFE_INTEGER,
        monthly: Number.MAX_SAFE_INTEGER,
        dailySoft: Number.MAX_SAFE_INTEGER,
        monthlySoft: Number.MAX_SAFE_INTEGER,
      },
      totalsDaily: 0,
      totalsMonthly: 0,
    },
    wire: {
      limits: {
        dailyTransfer: Number.MAX_SAFE_INTEGER,
        monthlyTransfer: Number.MAX_SAFE_INTEGER,
        dailyTransferSoft: Number.MAX_SAFE_INTEGER,
        monthlyTransferSoft: Number.MAX_SAFE_INTEGER,
      },
      totalsDaily: { transfers: 0 },
      totalsMonthly: { transfers: 0 },
    },
    checkPayment: {
      limits: {
        dailySent: Number.MAX_SAFE_INTEGER,
        monthlySent: Number.MAX_SAFE_INTEGER,
        dailySentSoft: Number.MAX_SAFE_INTEGER,
        monthlySentSoft: Number.MAX_SAFE_INTEGER,
      },
      totalsDaily: {
        sent: Number.MAX_SAFE_INTEGER,
      },
      totalsMonthly: {
        sent: Number.MAX_SAFE_INTEGER,
      },
    },
  },
};

export const makeQueryKey = (accountId?: string) => [UNIT_ACCOUNT_LIMITS_QUERY_KEY, accountId];

export const makeQueryFn = (unitApi: Unit, accountId?: string) => async () => {
  if (!accountId) return UNLIMITED_LIMIT;

  const { data } = await unitApi.accounts.limits(accountId);

  if (data.type === "creditLimits") {
    captureMessage(
      `Alert(alex): fetched account limits for "creditLimits" for accountId ${accountId}, but this type is not supported.`
    );
  }

  return data as DepositAccountLimits;
};

const useUnitCoAccountLimitQueryOptions = (accountId?: string) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: makeQueryKey(accountId),
    queryFn: makeQueryFn(unitApi, accountId),
  });
};

export default useUnitCoAccountLimitQueryOptions;
