import BillDocumentRep from "reps/BillDocumentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillDocumentApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async searchByBillId(billId: string): Promise<BillDocumentRep.Complete[]> {
    const qp = new URLSearchParams({ billId });
    const url = `/accounts-payable/documents?${qp}`;
    return (await this.api.get<BillDocumentRep.Complete[]>(url))!;
  }

  async create(body: BillDocumentRep.Creator): Promise<BillDocumentRep.Complete> {
    const url = "/accounts-payable/documents";
    return (await this.api.post<BillDocumentRep.Complete>(url, body))!;
  }

  async delete(billDocumentId: string): Promise<BillDocumentRep.Complete> {
    const url = `/accounts-payable/documents/${billDocumentId}`;
    return (await this.api.delete<BillDocumentRep.Complete>(url))!;
  }
}
