// This component implements a newer variant of an EmptyState component
// than the one found in ui/table/EmptyState. While this variant may also
// be used in tandem with table-related components, it is not specific to
// tables and may be used in other contexts as well. In contrast to the
// table-specific EmptyState, this variant does not include an image, but
// *does* include a call-to-action button.

import { FC, ReactNode, PropsWithChildren, ComponentProps } from "react";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import { Heading4, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type EmptyStateVariant = "card" | "inset-card";

type Props = {
  body?: ReactNode;
  footer?: ReactNode;
  variant?: EmptyStateVariant;
  className?: string;
};

const EmptyState: FC<Props> = ({ body, footer, variant, className }) => (
  <div
    className={cn(
      "m-auto flex flex-col items-center gap-4",
      (variant === "card" || variant === "inset-card") &&
        "rounded-lg border border-dashed border-grey-200 px-6 py-10",
      variant === "inset-card" && "bg-grey-50",
      className
    )}
  >
    {body && (
      <div className="flex flex-col items-center gap-2 text-center text-sm text-grey-600">
        {body}
      </div>
    )}
    {footer && <div>{footer}</div>}
  </div>
);

const PrimaryText: FC<PropsWithChildren> = ({ children }) => (
  <Heading4 className="font-medium text-inherit">{children}</Heading4>
);

const SecondaryText: FC<PropsWithChildren> = ({ children }) => (
  <Paragraph className="text-inherit">{children}</Paragraph>
);

type CTAProps = Omit<ComponentProps<typeof ButtonWithTooltip>, "size">;

const CTA: FC<CTAProps> = ({ className, children, ...restProps }) => (
  <ButtonWithTooltip size="sm" className={cn("[&>svg]:size-4", className)} {...restProps}>
    {children}
  </ButtonWithTooltip>
);

export default Object.assign(EmptyState, {
  PrimaryText: PrimaryText,
  SecondaryText: SecondaryText,
  CTA: CTA,
});
