import { AuthorizationParams } from "@auth0/auth0-spa-js";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import auth0ClientState from "state/auth/auth0Client";

const useSignUpWithRedirect = () => {
  const auth0 = useRecoilValue(auth0ClientState);

  return useCallback(
    (authorizationOptions: AuthorizationParams) => {
      auth0.loginWithRedirect({
        authorizationParams: {
          /* eslint-disable camelcase */
          // Prompts the user to sign up, rather than log in (the default).
          screen_hint: "signup",
          // Prevents existing sessions from being used (essentially logs the user out).
          max_age: 0,
          /* eslint-enable camelcase */
          ...authorizationOptions,
        },
      });
    },
    [auth0]
  );
};

export default useSignUpWithRedirect;
