import { FC, ReactNode, createContext, useContext } from "react";

type TransactionInfoFlexpaneContextState = {
  unitCoDepositAccountId: string;
  transactionId: string;
  onClose: () => void;

  // DEPRECATED: We can delete this once all transaction tables use react-query, and then we should refresh transactions at the mutation level via `useRefreshQuery`.
  refreshTransactions: () => void;
};

const TransactionInfoFlexpaneContext = createContext<TransactionInfoFlexpaneContextState>(
  {} as TransactionInfoFlexpaneContextState
);

type Props = {
  children: ReactNode;
  transactionId: string;
  unitCoDepositAccountId: string;
  onClose: () => void;

  // DEPRECATED: We can delete this once all transaction tables use react-query, and then we should refresh transactions at the mutation level via `useRefreshQuery`.
  refreshTransactions?: () => void;
};

const TransactionInfoFlexpaneProvider: FC<Props> = ({
  children,
  unitCoDepositAccountId,
  transactionId,
  onClose,
  refreshTransactions,
}) => {
  return (
    <TransactionInfoFlexpaneContext.Provider
      value={{
        unitCoDepositAccountId: unitCoDepositAccountId,
        transactionId: transactionId,
        onClose: onClose,
        refreshTransactions: refreshTransactions ? refreshTransactions : () => {},
      }}
    >
      {children}
    </TransactionInfoFlexpaneContext.Provider>
  );
};

export default TransactionInfoFlexpaneProvider;

export const useTransactionInfoFlexpaneContext = () => {
  return useContext(TransactionInfoFlexpaneContext);
};
