import classNames from "classnames";
import React, { useState } from "react";
import CurrencyInputField from "react-currency-input-field";
import { InfoIconVariant } from "ui/icons/InfoIcon";

import InputWrapper, { shouldShowLabelAsFocused, CommonInputProps } from "../InputWrapper";

import styles from "./CurrencyInput.module.scss";

type Props = {
  placeholder?: string;
  icon?: React.ReactElement;
  onIconClick?: () => void;
  onClick?: () => void;
  onChange?: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  value?: string;
  prefixValue?: string;
  maxLength?: number;
  autoFocus?: boolean;
  allowDecimals?: boolean;
  errorVariant?: InfoIconVariant;
} & CommonInputProps;

/**
 * DEPRECATED: Use CurrencyInputV3 instead.
 */
const CurrencyInput: React.FC<Props> = ({
  id,
  label,
  placeholder,
  icon,
  onIconClick,
  onClick,
  onChange,
  onFocus,
  onBlur,
  value,
  prefixValue,
  autoFocus,
  hasError,
  errorMessage,
  errorVariant,
  disabled,
  append,
  className,
  maxLength = 16,
  allowDecimals = true,
}: Props) => {
  const [focusOutline, setFocusOutline] = useState(false);
  const showLabelAsFocused = shouldShowLabelAsFocused(label, value, placeholder, focusOutline);
  return (
    <InputWrapper
      append={append}
      id={id}
      disabled={disabled}
      focusOutline={focusOutline}
      showLabelAsFocused={showLabelAsFocused}
      className={classNames(
        className,
        styles.container,
        showLabelAsFocused && styles["container--focused"]
      )}
      label={label}
      icon={icon}
      onIconClick={onIconClick}
      onClick={onClick}
      hasValue={Boolean(value)}
      prefixValue={prefixValue}
      hasError={hasError}
      errorMessage={errorMessage}
      errorVariant={errorVariant}
    >
      <CurrencyInputField
        allowDecimals={allowDecimals}
        disabled={disabled}
        id={id}
        aria-describedby={placeholder}
        placeholder={placeholder}
        decimalsLimit={allowDecimals ? 2 : 0}
        decimalScale={allowDecimals ? 2 : 0}
        value={value}
        allowNegativeValue={false}
        onValueChange={(value) => {
          onChange?.(value ?? "");
        }}
        onFocus={(e) => {
          setFocusOutline(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocusOutline(false);
          onBlur?.(e);
        }}
        maxLength={maxLength}
        autoFocus={autoFocus}
      />
    </InputWrapper>
  );
};

export default CurrencyInput;
