import { ArrowSquareOut } from "@phosphor-icons/react";
import React from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./RecurringPaymentSteps.module.scss";

const RecurringPaymentSteps: React.FC = () => {
  return (
    <div className={styles.container}>
      <Item number={1} link="/payments/payees/new">
        Add a new payee for the vendor
      </Item>
      <Item number={2} link="/send-money">
        Schedule the recurring payment to the payee in Highbeam
      </Item>
      <Item number={3}>
        Cancel the old recurring payment in your original bank or bill pay software.
      </Item>
    </div>
  );
};

export default RecurringPaymentSteps;

const Item: React.FC<{ number: number; children: string; link?: string }> = ({
  number,
  children,
  link,
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.number}>
        <Text as="span" size={12} weight="medium" color={colors.grey[900]}>
          {number}
        </Text>
      </div>
      <Text as="p" size={14} weight="regular" color={colors.grey[800]}>
        {children}
      </Text>
      {link && (
        <a href={link} target="_blank" rel="noreferrer" className={styles.link}>
          <ArrowSquareOut size={16} color={colors.purple[500]} />
        </a>
      )}
    </div>
  );
};
