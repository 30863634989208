import { Dialog, DialogBackdrop } from "@headlessui/react";
import { FC, Suspense, useRef } from "react";

import CommandPaletteDropdown from "../CommandPaletteDropdown";

import styles from "./CommandPaletteDialog.module.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CommandPaletteDialog: FC<Props> = ({ isOpen, onClose }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Suspense fallback={null}>
      <Dialog initialFocus={inputRef} open={isOpen} onClose={onClose}>
        <DialogBackdrop className={styles.backdrop} onClick={onClose} />
        <div className={styles.container}>
          <div className={styles.content}>
            <CommandPaletteDropdown ref={inputRef} onClose={onClose} />
          </div>
        </div>
      </Dialog>
    </Suspense>
  );
};

export default CommandPaletteDialog;
