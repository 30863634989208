import { useEffect, useState } from "react";
import Validator from "utils/validator";

export const useValidName = (name: string | undefined) => {
  const [isNameValid, setIsNameValid] = useState(true);
  const [isShowingNameError, setIsShowingNameError] = useState(false);

  useEffect(() => {
    if (name !== undefined) {
      setIsNameValid(Validator.humanName(name));
    }
  }, [name]);

  return {
    isNameValid,
    isShowingNameError,
    setIsShowingNameError,
  };
};

export const useValidEmail = (email: string | undefined) => {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isShowingEmailError, setIsShowingEmailError] = useState(false);

  useEffect(() => {
    if (email) {
      setIsEmailValid(Validator.emailAddress(email));
    } else {
      setIsEmailValid(true);
    }
  }, [email]);

  return {
    isEmailValid,
    isShowingEmailError,
    setIsShowingEmailError,
  };
};

export const useValidPhone = (phone: string | undefined) => {
  const [isPhonelValid, setIsPhonelValid] = useState(true);
  const [isShowingPhoneError, setIsShowingPhoneError] = useState(false);

  useEffect(() => {
    if (phone) {
      setIsPhonelValid(Validator.phoneNumber(phone));
    } else {
      setIsPhonelValid(true);
    }
  }, [phone]);

  return {
    isPhonelValid,
    isShowingPhoneError,
    setIsShowingPhoneError,
  };
};
