import ChargeCardOfferRep from "reps/ChargeCardOfferRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ChargeCardOfferApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(
    businessGuid: string,
    capitalAccountGuid: string
  ): Promise<ChargeCardOfferRep.Complete | null> {
    const qp = new URLSearchParams({ businessGuid, capitalAccountGuid });
    const url = `/charge-card-offers?${qp}`;
    return await this.api.get<ChargeCardOfferRep.Complete>(url);
  }
}
