import classNames from "classnames";
import { FC, ReactNode, PropsWithChildren } from "react";
import colors from "styles/colors";
import Shimmer from "ui/feedback/Shimmer";
import Text from "ui/typography/Text";

import styles from "./LogList.module.scss";

type Props = PropsWithChildren & {
  className?: string;
};

const LogList: FC<Props> = ({ children, className }) => {
  return <ol className={classNames(className, styles.container)}>{children}</ol>;
};

type ItemProps = PropsWithChildren & {
  icon?: ReactNode;
  isLoading?: boolean;
};

const Item: FC<ItemProps> = ({ children, icon, isLoading = false }) => {
  return (
    <li
      className={classNames(styles.itemContainer, {
        [styles["itemContainer--loading"]]: isLoading,
      })}
    >
      {isLoading ? (
        <>
          <div aria-hidden="true" className={styles.itemMarker}></div>
          <div className={styles.itemContent}>
            <Shimmer />
            <Shimmer additionalShimmerWrapperClassName={styles.subtextShimmer} />
          </div>
        </>
      ) : (
        <>
          <div aria-hidden="true" className={styles.itemMarker}>
            {icon && <>{icon}</>}
          </div>
          <div className={styles.itemContent}>{children}</div>
        </>
      )}
    </li>
  );
};

const ItemText: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text size={14} color={colors.grey[800]} as="p">
      {children}
    </Text>
  );
};

const ItemTextBold: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text weight="medium" as="strong">
      {children}
    </Text>
  );
};

const ItemTextItalic: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text italic as="em">
      {children}
    </Text>
  );
};

const ItemSubtext: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text size={12} color={colors.grey[600]} as="p">
      {children}
    </Text>
  );
};

type LoadingProps = {
  placeholderItemCount?: number;
  className?: string;
};

const Loading: FC<LoadingProps> = ({ placeholderItemCount = 8, className }) => {
  return (
    <LogList className={className}>
      {Array.from({ length: placeholderItemCount }).map((_, index) => (
        // Using index as key is ok here because the placeholder items are static.
        <Item key={index} isLoading />
      ))}
    </LogList>
  );
};

export default Object.assign(LogList, {
  Item,
  ItemText,
  ItemTextBold,
  ItemTextItalic,
  ItemSubtext,
  Loading,
});
