import { Suspense, ComponentType, SuspenseProps } from "react";

/**
 * Wraps a component with Suspense.
 */
const withSuspense = <P extends object>(
  WrappedComponent: ComponentType<P>,
  suspenseProps: SuspenseProps
) => {
  return (props: P) => (
    <Suspense {...suspenseProps}>
      <WrappedComponent {...props} />
    </Suspense>
  );
};

export default withSuspense;
