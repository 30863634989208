import { zodResolver } from "@hookform/resolvers/zod";
import pencilIcon from "assets/pencil.svg";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import SpendLimitFieldset, {
  SpendLimitFieldsetForm,
  spendLimitSchema,
} from "resources/card-spend-limit/components/SpendLimitFieldset";
import cardSpendLimitQueryHooks from "resources/card-spend-limit/queries/cardSpendLimitQueryHooks";
import CardAvatarBar from "resources/cards/components/CardAvatarBar";
import useEditCardLimitMutation from "resources/cards/mutations/useEditCardLimitMutation";
import useCard from "resources/cards/queries/useCard";
import { getCardName } from "resources/cards/utils";
import Divider from "ui/data-display/Divider";
import Alert from "ui/feedback/Alert";
import { notify } from "ui/feedback/Toast";
import Helper from "ui/inputs/Helper";
import TextInputV2 from "ui/inputs/TextInputV2";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/useModalContext";
import { getDollarsFromCents } from "utils/money";
import { z } from "zod";

import getCardTypeByCard from "../utils/getCardTypeByCard";

const schema = z
  .object({
    cardName: z.string().min(1, "Please enter a card name."),
    spendLimit: spendLimitSchema,
    purchaseTotals: z.object({
      dailyTotalPurchases: z.number(),
      monthlyTotalPurchases: z.number(),
    }),
  })
  .superRefine((data, ctx) => {
    if (data.spendLimit.enabled) {
      if (
        data.spendLimit.period === "daily" &&
        data.purchaseTotals.dailyTotalPurchases > data.spendLimit.amount
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Daily total purchases cannot exceed the spend limit.",
          path: ["spendLimit"],
        });
      }
      if (
        data.spendLimit.period === "monthly" &&
        data.purchaseTotals.monthlyTotalPurchases > data.spendLimit.amount
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Monthly total purchases cannot exceed the spend limit.",
          path: ["spendLimit"],
        });
      }
    }
  });

type EditCardModalContentProps = {
  cardId: string;
};

const EditCardModalContent: FC<EditCardModalContentProps> = ({ cardId }) => {
  const card = useCard({ cardId, required: true });
  const cardSpendLimit = cardSpendLimitQueryHooks.useData({ cardId });
  const { closeModalWithAnimation } = useModalContext();

  const form = useForm<z.input<typeof schema>, object, z.output<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      cardName: getCardName(card),
      spendLimit: cardSpendLimit
        ? {
            enabled: true,
            period: cardSpendLimit.period,
            amount: getDollarsFromCents(cardSpendLimit.limitInCents).toString(),
          }
        : {
            enabled: false,
          },
      purchaseTotals: {
        dailyTotalPurchases: cardSpendLimit?.purchaseTotals.dailyTotalPurchases ?? 0,
        monthlyTotalPurchases: cardSpendLimit?.purchaseTotals.monthlyTotalPurchases ?? 0,
      },
    },
  });

  const { mutate: editCard, isPending } = useEditCardLimitMutation(card.id, {
    onSuccess: () => {
      closeModalWithAnimation();
    },
    onError: (error) => {
      notify("error", error.message ?? "Something went wrong! Please try again.");
    },
  });

  const onValid = (data: z.output<typeof schema>) => {
    editCard({
      cardType: getCardTypeByCard(card),
      cardName: data.cardName,
      cardSpendLimit: data.spendLimit.enabled
        ? {
            period: data.spendLimit.period,
            limitInCents: data.spendLimit.amount,
          }
        : null,
    });
  };

  const selectedPeriod = form.watch("spendLimit.period");
  const selectedSpendLimit = form.watch("spendLimit.amount");
  const purchaseAmount =
    selectedPeriod === "daily"
      ? cardSpendLimit?.purchaseTotals.dailyTotalPurchases
      : cardSpendLimit?.purchaseTotals.monthlyTotalPurchases;

  return (
    <ModalV4.Form onSubmit={form.handleSubmit(onValid)}>
      <ModalV4.Header icon={<img alt="Edit icon" src={pencilIcon} />}>
        Edit card details
      </ModalV4.Header>

      <ModalV4.Body>
        <CardAvatarBar card={card} />

        <Divider />

        <Controller
          control={form.control}
          name="cardName"
          render={({ field, fieldState: { error } }) => (
            <div className="mb-8">
              <TextInputV2 label="Card name" {...field} />
              {error && <Helper iconVariant="error">{error.message}</Helper>}
            </div>
          )}
        />

        <SpendLimitFieldset form={form as unknown as SpendLimitFieldsetForm} />

        {
          // Hack: We check for `spendLimit?.message` to determine if we should render the alert. Important not to just check for `spendLimit` because we don't want to render the banner for other spend limit errors.
          form.formState.errors.spendLimit?.message && (
            <Alert variant="warning" className="tablet:px-0">
              <Alert.Icon />
              <Alert.Text>
                The current {selectedPeriod} spend on this card is ${purchaseAmount}. To set a spend
                budget of ${selectedSpendLimit}, please wait until the end of the{" "}
                {selectedPeriod === "daily" ? "day" : "month"}.
              </Alert.Text>
            </Alert>
          )
        }
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton isLoading={isPending}>Save changes</ModalV4.SubmitButton>
        <ModalV4.CloseButton disabled={isPending} />
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

type Props = {
  cardId: string;
  onClose: () => void;
};

const EditCardModal: FC<Props> = ({ cardId, onClose }) => {
  return (
    <ModalV4 onClose={onClose}>
      <EditCardModalContent cardId={cardId} />
    </ModalV4>
  );
};

export default EditCardModal;
