import classNames from "classnames";
import { ReactNode } from "react";
import CountBadge from "ui/data-display/CountBadge";
import Button from "ui/inputs/Button";

import styles from "./Tabs.module.scss";

export type Tab<T extends string = string> = {
  id: T;
  label: ReactNode;
  count?: number;
};

type Props<TTab extends string = string> = {
  tabs: readonly Tab<TTab>[];
  activeTab: string;
  variant?: "default" | "rounded";
  tabsWrapperAdditionalClassName?: string;
  tabAdditionalClassName?: string;
  noBorder?: boolean;
  setActiveTab: (tabId: TTab) => void;
  className?: string;
};

const Tabs = <TTab extends string = string>({
  tabs,
  setActiveTab,
  activeTab,
  variant = "default",
  tabsWrapperAdditionalClassName,
  tabAdditionalClassName,
  noBorder = false,
  className,
}: Props<TTab>) => (
  <div
    role="tablist"
    aria-orientation="horizontal"
    className={classNames(
      styles.container,
      noBorder && styles["container--no-border"],
      variant === "rounded" && styles["container--rounded"],
      "hide-scrollbars",
      className
    )}
  >
    <div
      className={classNames(
        styles.tabs,
        variant === "rounded" && styles["tabs--rounded"],
        tabsWrapperAdditionalClassName
      )}
    >
      {tabs.map((tab) => {
        const isActive = tab.id === activeTab;
        return (
          <Button
            variant="plain"
            role="tab"
            type="button"
            aria-selected={isActive}
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={classNames(
              styles.tab,
              styles[`tab--${variant}`],
              // TODO(alex) HB-5920: Fix this, temporary hack to fix https://highbeamco.slack.com/archives/C07HGFN06PR/p1730382386368709?thread_ts=1730382234.577419&cid=C07HGFN06PR
              variant === "rounded" && "rounded-full px-2 py-1",
              isActive && styles.active,
              tabAdditionalClassName
            )}
          >
            {tab.label}

            {tab?.count !== undefined && (
              <CountBadge count={tab.count} backgroundColor={isActive ? "grey-700" : "grey-500"} />
            )}
          </Button>
        );
      })}
    </div>
  </div>
);

export default Tabs;
