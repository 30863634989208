import { CheckCircle } from "@phosphor-icons/react";
import { ComponentProps, forwardRef, ForwardRefRenderFunction } from "react";
import InstitutionRep from "reps/InstitutionRep";
import colors from "styles/colors";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import TextInputV2 from "ui/inputs/TextInputV2";

import styles from "./RoutingNumberInput.module.scss";

type Props = ComponentProps<typeof TextInputV2> & {
  isFetchingFinancialInstitution: boolean;
  financialInstitution: InstitutionRep.Complete | null;
};

const RoutingNumberInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { isFetchingFinancialInstitution, financialInstitution, value: routingNumber, ...inputProps },
  ref
) => {
  return (
    <TextInputV2
      ref={ref}
      label="Routing number"
      inputMode="numeric"
      value={routingNumber}
      endAdornment={
        isFetchingFinancialInstitution ? (
          <AnimatedSpinner />
        ) : (
          <>
            {financialInstitution && (
              <div className={styles.routingNumberFinancialInstitutionSuffix}>
                {financialInstitution.name}
                <CheckCircle weight="fill" color={colors.green[400]} size={20} />
              </div>
            )}
          </>
        )
      }
      {...inputProps}
    />
  );
};

export default forwardRef(RoutingNumberInput);
