import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { HighbeamApiError } from "utils/ajax";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const INTERNATIONAL_BANK_ACCOUNTS_QUERY_KEY = "internationalBankAccounts";

const useInternationalAccountQuery = () => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [INTERNATIONAL_BANK_ACCOUNTS_QUERY_KEY, { businessGuid }],
    queryFn: async () => {
      try {
        return await highbeamApi.internationalBankAccount.getByBusiness(businessGuid);
      } catch (e) {
        if (e instanceof HighbeamApiError && e.statusCode === 403) {
          return null;
        }
        throw e;
      }
    },
  });
};

export default useInternationalAccountQuery;
