import useCashFlowTableData, {
  CashFlowTableDatum,
} from "pages/CashFlowPage/data/useCashFlowTableData";
import { useCashFlowContext } from "pages/CashFlowPage/providers/CashFlowProvider";
import TableV2 from "ui/table/TableV2";

import CashFlowCategoryCell from "../CashFlowCategoryCell";
import CashFlowCounterpartyCell from "../CashFlowCounterpartyCell";

import styles from "./CashFlowTable.module.scss";

const CashFlowTable = () => {
  const { activeTab } = useCashFlowContext();
  const data = useCashFlowTableData();

  return (
    <TableV2
      fetchData={() => {
        return new Promise<{ data: CashFlowTableDatum[] }>((resolve) => {
          resolve({
            data: data,
          });
        });
      }}
      columns={[
        {
          key: "counterparty",
          title: activeTab === "money-in" ? "Source" : "Vendor",
          renderCell: (datum) => <CashFlowCounterpartyCell datum={datum} />,
        },
        {
          key: "category",
          title: "Category",
          renderCell: (datum) => <CashFlowCategoryCell datum={datum} />,
        },
      ]}
    >
      <TableV2.Table className={styles.table}>
        <TableV2.Head />
        <TableV2.Body />
      </TableV2.Table>
      <TableV2.PageIndicator />
    </TableV2>
  );
};

export default CashFlowTable;
