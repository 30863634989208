import { INTERNATIONAL_WIRE_INPUTS } from "pages/SendMoneyPage/internationalWires";
import { PaymentMethod, PaymentMethodOption } from "pages/SendMoneyPage/utils";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { isLocalPaymentState } from "state/payments/international/isLocalPayment";
import requiredFieldsState from "state/payments/international/requiredFields";
import Dropdown from "ui/inputs/Dropdown";
import Helper from "ui/inputs/Helper";
import TextInput from "ui/inputs/TextInput";
import { getDollarsFromCents } from "utils/money";

import InternationalInvoiceDate from "../InternationalMisc/InternationalInvoiceDate";
import InternationalInvoiceNumber from "../InternationalMisc/InternationalInvoiceNumber";
import InternationalPurpose from "../InternationalMisc/InternationalPurpose";

import styles from "./PaymentAndDescription.module.scss";

type Props = {
  setPaymentMethod: (paymentMethod: PaymentMethodOption) => void;
  paymentMethod: PaymentMethodOption | null;
  options: PaymentMethodOption[];
  addenda: string;
  setAddenda: (addenda: string) => void;
  minDescriptionLength: number;
  maxDescriptionLength: number;
  isAmountLessThanMinimumAmount: boolean;
};

const PaymentAndDescription: React.FC<Props> = ({
  addenda,
  setAddenda,
  minDescriptionLength,
  maxDescriptionLength,
  options,
  setPaymentMethod,
  paymentMethod,
  isAmountLessThanMinimumAmount,
}) => {
  const [showDescriptionError, setShowDescriptionError] = useState(false);
  const shouldShowDescriptionError = () => minDescriptionLength > 0 && addenda.length === 0;
  const isInternationalPayment = paymentMethod?.value === PaymentMethod.INTERNATIONAL;
  const isLocalPayment = useRecoilValue(isLocalPaymentState);
  const requiredFields = useRecoilValue(requiredFieldsState);

  // If the error is shown and payment method gets changed, check if we need to still show it
  useEffect(() => {
    if (showDescriptionError) {
      setShowDescriptionError(shouldShowDescriptionError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  return (
    <fieldset>
      <div className={styles.container}>
        <legend className={styles.heading}>Method & description</legend>
        <Dropdown
          value={paymentMethod}
          onChange={(paymentMethod) => setPaymentMethod(paymentMethod as PaymentMethodOption)}
          id="select-payment-method"
          label="Select payment method"
          options={options}
          isSearchable={false}
          hasError={isAmountLessThanMinimumAmount}
          errorMessage={`${
            paymentMethod?.label
          } transfers require a minimum of $${getDollarsFromCents(
            paymentMethod?.minTransferAmountInCents!
          )} to be transferred.`}
        />
        {isInternationalPayment && !isAmountLessThanMinimumAmount && (
          <>
            {isLocalPayment ? (
              <Helper>
                International wire transfers are free and can take up to 3 - 5 business days.
              </Helper>
            ) : (
              <Helper>{paymentMethod.info}</Helper>
            )}
          </>
        )}
        <TextInput
          type="text"
          value={addenda}
          onChange={setAddenda}
          label="Description"
          maxLength={maxDescriptionLength}
          className={styles.description}
          onBlur={() => setShowDescriptionError(shouldShowDescriptionError())}
          onFocus={() => setShowDescriptionError(false)}
          hasError={showDescriptionError}
          errorMessage="A description is required."
        />
        <Helper>Will show on your bank and the recipient’s bank statements.</Helper>
        {isInternationalPayment && requiredFields && (
          <>
            {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.PURPOSE_CODE) && <InternationalPurpose />}
            {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.INVOICE_NUMBER) && (
              <InternationalInvoiceNumber />
            )}
            {requiredFields.has(INTERNATIONAL_WIRE_INPUTS.INVOICE_DATE) && (
              <InternationalInvoiceDate />
            )}
          </>
        )}
      </div>
    </fieldset>
  );
};

export default PaymentAndDescription;
