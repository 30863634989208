import dayjs from "dayjs";
import { FC, ReactNode } from "react";
import ChargeCardRepaymentInfoRep from "reps/ChargeCardRepaymentInfoRep";
import chargeCardAccountQueryHooks from "resources/charge-cards/queries/chargeCardAccountQueryHooks";
import { useChargeCardRepaymentInfoQuery } from "resources/charge-cards/queries/useChargeCardRepaymentInfo";
import Shimmer from "ui/feedback/ShimmerV2";
import variants from "utils/ts/variants";

type Props = {
  chargeCardRepaymentInfo: ChargeCardRepaymentInfoRep.Complete;
  statementPeriod: "current" | "previous";
};

const ChargeCardRepaymentPeriodDateRange: FC<Props> = ({
  chargeCardRepaymentInfo,
  statementPeriod,
}) => {
  const start = variants(statementPeriod, {
    current: chargeCardRepaymentInfo.currentStatementPeriodStart,
    previous: chargeCardRepaymentInfo.previousStatementPeriodStart,
  });

  const end = variants(statementPeriod, {
    current: chargeCardRepaymentInfo.currentStatementPeriodEnd,
    previous: chargeCardRepaymentInfo.previousStatementPeriodEnd,
  });

  return (
    <>
      {dayjs(start).format("MMM D")} - {dayjs(end).format("MMM D")}
    </>
  );
};

export default ChargeCardRepaymentPeriodDateRange;

// Wrappers

type ChargeCardRepaymentPeriodDateRangeByCapitalAccountGuidProps = Omit<
  Props,
  "chargeCardRepaymentInfo"
> & {
  capitalAccountGuid: string;
  loadingFallback?: ReactNode;
  notFoundFallback?: ReactNode;
};

export const ChargeCardRepaymentPeriodDateRangeByCapitalAccountGuid: FC<
  ChargeCardRepaymentPeriodDateRangeByCapitalAccountGuidProps
> = ({ capitalAccountGuid, loadingFallback = <Shimmer />, notFoundFallback = null, ...props }) => {
  const { data: chargeCardAccount, isLoading: isChargeCardAccountLoading } =
    chargeCardAccountQueryHooks.useQuery({
      capitalAccountGuid: capitalAccountGuid,
    });

  const { data: chargeCardRepaymentInfo, isLoading: isChargeCardRepaymentInfoLoading } =
    useChargeCardRepaymentInfoQuery(chargeCardAccount?.guid);

  const isLoading = isChargeCardAccountLoading || isChargeCardRepaymentInfoLoading;

  if (isLoading) {
    return <>{loadingFallback}</>;
  }

  if (!chargeCardRepaymentInfo) {
    return <>{notFoundFallback}</>;
  }

  return (
    <ChargeCardRepaymentPeriodDateRange
      chargeCardRepaymentInfo={chargeCardRepaymentInfo}
      {...props}
    />
  );
};
