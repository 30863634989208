import { Dayjs } from "dayjs";
import DailyPlReportRep from "reps/DailyPlReportRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class DailyPlReportApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async search(
    businessGuid: string,
    dateRangeStart: Dayjs,
    dateRangeEndInclusive: Dayjs
  ): Promise<DailyPlReportRep> {
    const qp = new URLSearchParams({
      businessGuid,
      dateRangeStart: dateRangeStart.format("YYYY-MM-DD"),
      dateRangeEndInclusive: dateRangeEndInclusive.format("YYYY-MM-DD"),
    });
    const url = `/insights/daily-pl-reports?${qp}`;
    return (await this.api.get<DailyPlReportRep>(url))!;
  }
}
