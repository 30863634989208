import {
  HighbeamTransaction,
  isAdjustmentTransaction,
  isAtmTransaction,
  isCardTransaction,
  isCheckDepositTransaction,
  isDishonoredAchTransaction,
  isInternationalWireTransaction,
  isOriginatedAchTransaction,
  isPurchaseTransaction,
  isReceivedAchTransaction,
  isReturnedAchTransaction,
  isReturnedReceivedAchTransaction,
  isWireTransaction,
} from "utils/types/transactionsTypes";

import AdjustmentTransactionDetailsSection from "./AdjustmentTransactionDetailsSection";
import AtmTransactionDetailsSection from "./AtmTransactionDetailsSection";
import BaseTransactionDetailsSection from "./BaseTransactionDetailsSection";
import CardTransactionDetailsSection from "./CardTransactionDetailsSection";
import CheckDepositTransactionDetailsSection from "./CheckDepositTransactionDetailsSection";
import DishonoredAchTransactionDetailsSection from "./DishonoredAchTransactionDetailsSection";
import InternationalWireTransactionDetailsSection from "./InternationalWireTransactionDetailsSection";
import OriginatedAchTransactionDetailsSection from "./OriginatedAchTransactionDetailsSection";
import ReceivedAchTransactionDetailsSection from "./ReceivedAchTransactionDetailsSection";
import ReturnedAchTransactionDetailsSection from "./ReturnedAchTransactionDetailsSection";
import ReturnedReceivedAchTransactionDetailsSection from "./ReturnedReceivedAchTransactionDetailsSection";
import WireTransactionDetailsSection from "./WireTransactionDetailsSection";

type Props = {
  transaction: HighbeamTransaction;
};

const TransactionInfoFlexpaneDetailsSection: React.FC<Props> = ({ transaction }) => {
  // TODO: Replace with switch statement and get rid of "isXTransaction" methods once all are
  //  covered.
  if (isAdjustmentTransaction(transaction)) {
    return <AdjustmentTransactionDetailsSection transaction={transaction} />;
  } else if (isAtmTransaction(transaction)) {
    return <AtmTransactionDetailsSection transaction={transaction} />;
  } else if (isCardTransaction(transaction) || isPurchaseTransaction(transaction)) {
    return <CardTransactionDetailsSection transaction={transaction} />;
  } else if (isCheckDepositTransaction(transaction)) {
    return <CheckDepositTransactionDetailsSection transaction={transaction} />;
  } else if (isDishonoredAchTransaction(transaction)) {
    return <DishonoredAchTransactionDetailsSection transaction={transaction} />;
  } else if (isInternationalWireTransaction(transaction)) {
    return (
      <InternationalWireTransactionDetailsSection
        addenda={transaction.addenda}
        internationalPaymentMetadataGuid={transaction.paymentMetadataGuid}
      />
    );
  } else if (isOriginatedAchTransaction(transaction)) {
    return <OriginatedAchTransactionDetailsSection transaction={transaction} />;
  } else if (isReceivedAchTransaction(transaction)) {
    return <ReceivedAchTransactionDetailsSection transaction={transaction} />;
  } else if (isReturnedAchTransaction(transaction)) {
    return <ReturnedAchTransactionDetailsSection transaction={transaction} />;
  } else if (isReturnedReceivedAchTransaction(transaction)) {
    return <ReturnedReceivedAchTransactionDetailsSection transaction={transaction} />;
  } else if (isWireTransaction(transaction)) {
    return <WireTransactionDetailsSection transaction={transaction} />;
  } else {
    return <BaseTransactionDetailsSection transaction={transaction} />;
  }
};

export default TransactionInfoFlexpaneDetailsSection;
