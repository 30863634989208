import { ListDashes as ListDashesIcon, Info as InfoIcon } from "@phosphor-icons/react";
import { FC } from "react";
import { useApSettings } from "resources/ap-settings/queries/apSettingsQueryHooks";
import BillSyncStatus from "resources/bill-syncs/components/BillSyncStatus";
import useBillSyncQueryIfEnabled from "resources/bill-syncs/hooks/useBillSyncQueryIfEnabled";
import shouldBillSyncBeDryRun from "resources/bill-syncs/utils/shouldBillSyncBeDryRun";
import useBill from "resources/bills/queries/useBill";
import { Span, Heading3 } from "ui/typography";
import cn from "utils/tailwind/cn";

import BillLineItemsCountBadge from "../BillLineItemsCountBadge";

type Props = {
  billId: string;
  as?: "h3" | "span";
  className?: string;
};

const BillLineItemsHeading: FC<Props> = ({ billId, as = "h3", className }) => {
  const { accountingSyncEnabled } = useApSettings();
  const bill = useBill(billId, { required: true });
  const dryRun = shouldBillSyncBeDryRun(bill);
  const { data: billSync, error: billSyncEndpointError } = useBillSyncQueryIfEnabled(billId);
  const billSyncErrors = billSync?.errors ?? [];
  const hasBillSyncErrors = Boolean(billSyncEndpointError) || billSyncErrors.length > 0;

  const content = (
    <>
      <span className="flex items-center gap-2.5">
        {hasBillSyncErrors ? (
          <InfoIcon className="h-4 w-4 shrink-0 text-yellow-600" />
        ) : (
          <ListDashesIcon className="h-4 w-4 shrink-0" />
        )}
        <Span
          className={cn("text-grey-800", {
            "text-yellow-700": hasBillSyncErrors,
          })}
        >
          Line items
        </Span>
        <BillLineItemsCountBadge billId={billId} />
      </span>
      {accountingSyncEnabled && !dryRun && (
        <BillSyncStatus billId={billId} className="ml-auto tablet:ml-8" />
      )}
    </>
  );

  const classes = cn(
    "flex w-full items-center gap-2.5 text-sm tablet:w-auto tablet:text-md",
    className
  );

  return as === "h3" ? (
    <Heading3 className={classes}>{content}</Heading3>
  ) : (
    <span className={classes}>{content}</span>
  );
};

export default BillLineItemsHeading;
