import { captureMessage } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { notify } from "ui/feedback/Toast";
import { downloadBlob, downloadWith404Retry } from "utils/download";

type DownloadInvoiceVariables = {
  invoiceUrl: string;
};

const useDownloadInvoiceMutation = () => {
  return useMutation({
    mutationFn: async ({ invoiceUrl }: DownloadInvoiceVariables) => {
      // Extract the file name from the attachment url
      const invoiceName = invoiceUrl.split("/").pop()?.split("?")[0] || "";
      const blob = await downloadWith404Retry(invoiceUrl);
      downloadBlob(invoiceName, window.URL.createObjectURL(blob));
    },
    onError: (error) => {
      notify("error", "Something went wrong downloading the invoice! Please try again.");
      captureMessage(error.message);
    },
  });
};

export default useDownloadInvoiceMutation;
