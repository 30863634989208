import { useQueryClient } from "@tanstack/react-query";
import PaymentSwitcherAutoPaymentGroupRep from "reps/PaymentSwitcherAutoPaymentGroupRep";
import PaymentSwitcherAutoPaymentRep from "reps/PaymentSwitcherAutoPaymentRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { PAYMENT_SWITCHER_AUTO_PAYMENTS_QUERY_KEY } from "resources/payment-switcher/queries/usePaymentSwitcherAutoPayments";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Options = {
  payment: PaymentSwitcherAutoPaymentRep;
};

const useSetPaymentSwitcherAutoPayment = (
  additionalOptions?: MutationAdditionalOptions<void, Options>
) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  const queryClient = useQueryClient();
  const queryKey = [PAYMENT_SWITCHER_AUTO_PAYMENTS_QUERY_KEY, businessGuid];

  return useMutationWithDefaults(
    {
      mutationFn: ({ payment }: Options) => {
        return highbeamApi.paymentSwitcherAutoPayment.set({
          businessGuid,
          counterparty: payment.counterparty,
          switched: !payment.switched,
        });
      },
      onMutate: async ({ payment }: Options) => {
        // Handle optimistic update.
        await queryClient.cancelQueries({ queryKey }); // Cancel any outgoing refetches.
        queryClient.setQueryData<PaymentSwitcherAutoPaymentGroupRep[]>(queryKey, (old) => {
          if (!old) return old;
          return old.map((group) => {
            return {
              ...group,
              payments: group.payments.map((it) => {
                if (it.counterparty !== payment.counterparty) return it;
                return { ...it, switched: !it.switched };
              }),
            };
          });
        });
      },
      onError: () => {
        notify("error", "Something went wrong! Please try again.");
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    },
    additionalOptions || {}
  );
};

export default useSetPaymentSwitcherAutoPayment;
