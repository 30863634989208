import InternationalPayeeBankingInfo from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/InternationalPayeeBankingInfo";
import InternationalPayeeEntity from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/InternationalPayeeEntity";
import InternationalPayeeLegalAddress from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/InternationalPayeeLegalAddress";
import PaymentCurrency from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/PaymentCurrency";
import { useEffect } from "react";
import { useResetRecoilState } from "recoil";
import { internationalAddressState } from "state/payments/international/address";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import { internationalEntityState } from "state/payments/international/entity";
import { selectedBankCountryOptionState } from "state/payments/international/selectedBankCountry";

// HACK(alex): Took elements from `InternationalWireSection` and `UpdatePayee` to make this work for bill pay. I hope we get around to rewriting international wires someday.
const InternationalWirePaymentMethodRecoilFieldset = () => {
  const resetInternationalEntityState = useResetRecoilState(internationalEntityState);
  const resetAddress = useResetRecoilState(internationalAddressState);
  const resetInternationalBankingInfo = useResetRecoilState(internationalBankingInfoState);
  const resetBankCountry = useResetRecoilState(selectedBankCountryOptionState);

  const resetInternationalWireInfo = () => {
    resetInternationalEntityState();
    resetBankCountry();
    resetAddress();
    resetInternationalBankingInfo();
  };

  // Cleanup if user navigates away from page
  useEffect(
    () => () => {
      resetInternationalWireInfo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <PaymentCurrency />
      <InternationalPayeeEntity />
      <InternationalPayeeLegalAddress />
      <InternationalPayeeBankingInfo />
    </div>
  );
};

export default InternationalWirePaymentMethodRecoilFieldset;
