import PaymentRep from "reps/payments-v2/PaymentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class PaymentApiV2 {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async list(businessGuid: string, status: PaymentRep.Status): Promise<PaymentRep.Complete[]> {
    const qp = new URLSearchParams({ businessGuid, status });
    const url = `/v2/payments?${qp}`;
    return (await this.api.get<PaymentRep.Complete[]>(url)) || [];
  }

  async create(creator: PaymentRep.Creator): Promise<PaymentRep.Complete> {
    const url = `/v2/payments`;
    return (await this.api.post<PaymentRep.Complete>(url, creator))!;
  }
}
