import { captureMessage } from "@sentry/react";
import BankAccountRep from "reps/BankAccountRep";

const getPrimaryBankAccount = (
  bankAccounts: BankAccountRep.Complete[]
): BankAccountRep.Complete | null => {
  const primaryBankAccounts = bankAccounts.filter((account) => account.isPrimary);

  if (primaryBankAccounts.length > 1) {
    captureMessage("Multiple primary bank accounts found");
  } else if (primaryBankAccounts.length === 0) {
    captureMessage("No primary bank account found");
  }

  return primaryBankAccounts[0] ?? null;
};

export default getPrimaryBankAccount;
