import { ArrowsLeftRight, Star } from "@phosphor-icons/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import BankAccountRep from "reps/BankAccountRep";
import BankAccountBar from "resources/bank-accounts/components/BankAccountBar";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import ModalV3 from "ui/overlay/ModalV3";
import Text from "ui/typography/Text";

import styles from "./CloseBankAccountModal.module.scss";
import useCloseBankAccountMutation from "./hooks/useCloseBankAccountMutation";
import closeBankAccountModalState, {
  useCloseBankAccountModal,
} from "./state/closeBankAccountModalState";

const CLOSE_ACCOUNT_FORM = "close-bank-account-form";

type Props = {
  bankAccount: BankAccountRep.Complete;
};

const ZeroBalanceContent: FC<Props> = ({ bankAccount }) => {
  const isCheckingAccount = bankAccount.highbeamType.name === "DepositAccount";
  return (
    <div className={styles.stepsContainer}>
      <ModalV3.Body.Text weight="medium">
        Before closing this account, please consider:
      </ModalV3.Body.Text>
      <div className={styles.stepsContainer}>
        <div className={styles.step}>
          <Text size={14} weight="medium" align="center" className={styles.stepNumber}>
            1
          </Text>
          <ModalV3.Body.Text>This account will be closed permanently</ModalV3.Body.Text>
        </div>

        <div className={styles.step}>
          <Text size={14} weight="medium" align="center" className={styles.stepNumber}>
            2
          </Text>
          <ModalV3.Body.Text>
            You’ll still be able to view account details and statements
          </ModalV3.Body.Text>
        </div>
        {isCheckingAccount && (
          <div className={styles.step}>
            <Text size={14} weight="medium" align="center" className={styles.stepNumber}>
              3
            </Text>
            <ModalV3.Body.Text>
              All pending transfers in or out of this account will fail
            </ModalV3.Body.Text>
          </div>
        )}
        {isCheckingAccount && (
          <div className={styles.step}>
            <Text size={14} weight="medium" align="center" className={styles.stepNumber}>
              4
            </Text>
            <ModalV3.Body.Text>
              All associated debit cards will be archived permanently
            </ModalV3.Body.Text>
          </div>
        )}
      </div>
    </div>
  );
};

const NonZeroBalanceContent: React.FC<Props> = ({ bankAccount }) => (
  <ModalV3.Body.Text>
    In order to close an account, the balance must be $0. Please{" "}
    <Link to="/transfer-money" state={{ fromAccountGuid: bankAccount.guid }}>
      <Text as="span" size={16} color={colors.purple[500]} underline>
        transfer money
      </Text>
    </Link>{" "}
    out of this account first before closing it.
  </ModalV3.Body.Text>
);

const modalContent = (bankAccount: BankAccountRep.Complete) => {
  if (bankAccount.availableBalance === 0) {
    return <ZeroBalanceContent bankAccount={bankAccount} />;
  } else {
    return <NonZeroBalanceContent bankAccount={bankAccount} />;
  }
};

type CloseModalProps = {
  onSuccess?: () => void;
};

const CloseNonPrimaryAccountModal = ({ onSuccess = () => {} }: CloseModalProps) => {
  const state = useRecoilValue(closeBankAccountModalState);
  const { isOpen, close: onClose } = useCloseBankAccountModal();
  const { mutateAsync: closeBankAccount, isPending } = useCloseBankAccountMutation();

  return (
    <ModalV3 isOpen={isOpen} onClose={onClose}>
      {state.isOpen && (
        <form
          id={CLOSE_ACCOUNT_FORM}
          onSubmit={async (e) => {
            e.preventDefault();
            await closeBankAccount(state.bankAccount.guid);
            onSuccess();
          }}
        >
          <ModalV3.Header onClose={onClose}>Close account?</ModalV3.Header>

          <ModalV3.Body className={styles.body}>
            <div className={styles.bankAccountCard}>
              <BankAccountBar bankAccount={state.bankAccount} />
              <div className={styles.balanceContainer}>
                <Text size={14}>
                  Balance:{" "}
                  <MoneyAmount
                    weight="medium"
                    cents={state.bankAccount.availableBalance}
                    centsTextSize={12}
                  />
                </Text>
                {state.bankAccount.availableBalance !== 0 && (
                  <Link to="/transfer-money" state={{ fromAccountGuid: state.bankAccount.guid }}>
                    <ArrowsLeftRight weight="bold" color={colors.purple[500]} size={14} />
                  </Link>
                )}
              </div>
            </div>
            {modalContent(state.bankAccount)}
          </ModalV3.Body>

          <ModalV3.Footer>
            <ModalV3.Footer.SubmitButton
              variant="danger"
              autoFocus
              form={CLOSE_ACCOUNT_FORM}
              isLoading={isPending}
              disabled={state.bankAccount.availableBalance !== 0}
            >
              Close account
            </ModalV3.Footer.SubmitButton>

            <ModalV3.Footer.CloseButton onClick={onClose}>Cancel</ModalV3.Footer.CloseButton>
          </ModalV3.Footer>
        </form>
      )}
    </ModalV3>
  );
};

const ClosePrimaryAccountModal: FC = () => {
  const state = useRecoilValue(closeBankAccountModalState);
  const { isOpen, close: onClose } = useCloseBankAccountModal();

  return (
    <ModalV3 isOpen={isOpen} onClose={onClose}>
      {state.isOpen && (
        <>
          <ModalV3.Header onClose={onClose}>Cannot close account</ModalV3.Header>

          <ModalV3.Body className={styles.body}>
            <div className={styles.bankAccountCard}>
              <BankAccountBar bankAccount={state.bankAccount} />
              <div className={styles.primaryStarContainer}>
                <Star size={16} />
                <Text size={14}>Current primary</Text>
              </div>
            </div>
            <ModalV3.Body.Text>
              This account is your primary account and cannot be closed. To close this account,
              first set another account to your primary account.
            </ModalV3.Body.Text>
          </ModalV3.Body>

          <ModalV3.Footer>
            <ModalV3.Footer.CloseButton onClick={onClose}>Cancel</ModalV3.Footer.CloseButton>
          </ModalV3.Footer>
        </>
      )}
    </ModalV3>
  );
};

const CloseBankAccountModal: React.FC<CloseModalProps> = ({ onSuccess = () => {} }) => {
  const state = useRecoilValue(closeBankAccountModalState);

  return (
    <>
      {state.isOpen && state.bankAccount.isPrimary ? (
        <ClosePrimaryAccountModal />
      ) : (
        <CloseNonPrimaryAccountModal onSuccess={onSuccess} />
      )}
    </>
  );
};

export default CloseBankAccountModal;
