import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useUserRoleMembershipsQueryOptions = (businessGuid?: string) => {
  const highbeamApi = useHighbeamApi();
  const defaultBusinessGuid = useBusinessGuid();
  const effectiveBusinessGuid = businessGuid ?? defaultBusinessGuid;

  return useQueryOptions({
    queryKey: ["userRoleMemberships", { businessGuid: effectiveBusinessGuid }],
    queryFn: () => highbeamApi.userRoleMembership.getByBusiness(effectiveBusinessGuid),
  });
};

export default useUserRoleMembershipsQueryOptions;
