import { AchReceivedPayment, CheckDeposit, Payment } from "@highbeam/unit-node-sdk";
import { IncomingPayment } from "modules/incoming-payments/types";

export const isCheckDepositPayment = (
  incomingPayment: IncomingPayment
): incomingPayment is CheckDeposit => incomingPayment.type === "checkDeposit";

export const isReceivedAchPayment = (
  incomingPayment: IncomingPayment
): incomingPayment is AchReceivedPayment => incomingPayment.type === "achReceivedPayment";

export const isReceivedPayment = (incomingPayment: IncomingPayment): incomingPayment is Payment =>
  incomingPayment.type === "achPayment" ||
  incomingPayment.type === "bookPayment" ||
  incomingPayment.type === "wirePayment";
