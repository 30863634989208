import { ArrowRight, Info } from "@phosphor-icons/react";
import highbeamLogo from "assets/highbeam-logo.svg";
import netsuiteFullLogo from "assets/rutter/netsuite-full-logo.svg";
import quickbooksFullLogo from "assets/rutter/quickbooks-full-logo.svg";
import xeroLogo from "assets/rutter/xero-logo.svg";
import { ReactElement, ReactNode, useRef, useState } from "react";
import { Link } from "react-router-dom";
import RutterConnectionRep from "reps/RutterConnectionRep";
import colors from "styles/colors";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";

import styles from "./AccountingSoftwareModal.module.scss";

const getAccountingSoftwareFullLogo = (type: RutterConnectionRep.RutterAccountingPlatform) => {
  switch (type) {
    case "QUICKBOOKS":
      return quickbooksFullLogo;
    case "XERO":
      return xeroLogo;
    case "NETSUITE":
      return netsuiteFullLogo;
  }
};

/* eslint-disable react/jsx-key */
const AccountingSoftwareInstructions: {
  [key in RutterConnectionRep.RutterAccountingPlatform]: ReactElement[];
} = {
  QUICKBOOKS: [
    <Text size={14} color={colors.grey[800]}>
      Go to{" "}
      <a
        href={"https://c1.qbo.intuit.com/qbo1/login?pagereq=banking"}
        target="_blank"
        rel="noreferrer"
        className={styles.accountingLink}
      >
        Bank transactions
      </a>{" "}
      page in Quickbooks
    </Text>,
    <Text size={14} color={colors.grey[800]}>
      Search for “Highbeam”
    </Text>,
    <Text size={14} color={colors.grey[800]}>
      Log into Highbeam using your phone number
    </Text>,
    <Text size={14} color={colors.grey[800]}>
      Select the Highbeam bank accounts to add
    </Text>,
  ],
  XERO: [
    <Text size={14} color={colors.grey[800]}>
      {`In Xero, go to Accounting >`}{" "}
      <a
        href={"https://go.xero.com/Bank/BankAccounts.aspx"}
        target="_blank"
        rel="noreferrer"
        className={styles.accountingLink}
      >
        Bank accounts
      </a>
    </Text>,
    <Text size={14} color={colors.grey[800]}>
      Click “Add bank account”
    </Text>,
    <Text size={14} color={colors.grey[800]}>
      Search for “Highbeam”
    </Text>,
    <Text size={14} color={colors.grey[800]}>
      Log into Highbeam using your phone number
    </Text>,
    <Text size={14} color={colors.grey[800]}>
      Select the Highbeam bank accounts to add
    </Text>,
  ],
  NETSUITE: [
    <Text size={14} color={colors.grey[800]}>
      {`Go to Accounts >`}{" "}
      <Link to="/transactions" className={styles.accountingLink}>
        Transactions
      </Link>{" "}
      in Highbeam
    </Text>,
    <Text
      size={14}
      color={colors.grey[800]}
    >{`Click Export > Export as QFX to download your transaction data`}</Text>,
    <Text
      size={14}
      color={colors.grey[800]}
    >{`In Netsuite, go to Transactions > Bank > Banking Import History and click “Upload file”`}</Text>,
    <Text size={14} color={colors.grey[800]}>
      Upload your QFX file to Netsuite
    </Text>,
  ],
};
/* eslint-enable react/jsx-key */

type RenderPropsArgs = {
  openModal: () => void;
};

type Props = {
  children: ReactNode | ((args: RenderPropsArgs) => ReactNode);
  accountingSoftwareType: RutterConnectionRep.RutterAccountingPlatform;
};

const AccountingInstructions: React.FC<Props> = ({ children, accountingSoftwareType }) => {
  const [isInstrucionsModalVisible, setIsInstructionsModalVisible] = useState(false);

  const openInstructionsModal = () => {
    setIsInstructionsModalVisible(true);
  };

  const renderPropsArgs = useRef<RenderPropsArgs>({ openModal: openInstructionsModal }).current;

  return (
    <>
      {isInstrucionsModalVisible && (
        <IntegrationInstructionsModal
          isRedirecting={false}
          accountingSoftwareType={accountingSoftwareType}
          onClose={() => {
            setIsInstructionsModalVisible(false);
          }}
        />
      )}

      {typeof children === "function" && children(renderPropsArgs)}
      {typeof children !== "function" && children}
    </>
  );
};

const IntegrationInstructionsModal: React.FC<{
  isRedirecting: boolean;
  accountingSoftwareType: RutterConnectionRep.RutterAccountingPlatform;
  onClose: () => void;
}> = ({ isRedirecting, onClose, accountingSoftwareType }) => {
  const accountingSoftwareName = getAccountingSoftwareName(accountingSoftwareType);
  const instructionsForType = AccountingSoftwareInstructions[accountingSoftwareType];
  return (
    <Modal
      title={`Send data from Highbeam to ${accountingSoftwareName}`}
      buttonText="Done"
      isLoading={isRedirecting}
      onClose={onClose}
      onClick={onClose}
      showCancel
    >
      <div className={styles.modalBody}>
        <div className={styles.chartWithLogos}>
          <img src={highbeamLogo} alt="highbeam logo" className={styles.logo} />
          <div className={styles.circleArrow}>
            <ArrowRight size={16} weight="light" />
          </div>
          <img
            src={getAccountingSoftwareFullLogo(accountingSoftwareType)}
            alt={`${accountingSoftwareName} logo`}
            className={accountingSoftwareType === "XERO" ? styles.logo : ""}
          />
        </div>
        <div className={styles.instructions}>
          {instructionsForType.map((instruction, idx) => (
            <div key={idx} className={styles.instructionRow}>
              <Text size={14} color={colors.grey[800]} className={styles.instructionRowNumber}>
                {idx + 1}
              </Text>
              {instruction}
            </div>
          ))}
        </div>
        {accountingSoftwareType === "NETSUITE" && (
          <div className={styles.netsuiteInfoMsg}>
            <Info size={32} />
            <Text size={14} color={colors.grey[800]}>
              Highbeam can’t currently sync real-time data with Netsuite so you may need to manually
              re-export your data periodically to keep it up-to-date.
            </Text>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AccountingInstructions;
