import HighbeamBaseApi from "api/HighbeamBaseApi";
import CategorizedResourceRep from "reps/Insights/CategorizedResourceRep";

export default class CategorizedResourceApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async set(
    businessGuid: string,
    creator: CategorizedResourceRep.Creator
  ): Promise<CategorizedResourceRep.Complete> {
    const queryParams = new URLSearchParams({ businessGuid: businessGuid });
    const url = `/categorized-resources?${queryParams.toString()}`;
    return (await this.api.put<CategorizedResourceRep.Complete>(url, creator))!;
  }

  async get(
    businessGuid: string,
    resourceGuid: string
  ): Promise<CategorizedResourceRep.Complete | null> {
    const queryParams = new URLSearchParams({ businessGuid, resourceGuid });
    const url = `/categorized-resources?${queryParams.toString()}`;
    return await this.api.get<CategorizedResourceRep.Complete>(url);
  }
}
