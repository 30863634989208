import { ComponentProps, FC } from "react";
import { SetRequired } from "type-fest";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

type Props = SetRequired<ComponentProps<typeof MoneyAmount>, "direction">;

const TransactionFlexpaneAmount: FC<Props> = ({ cents, direction, ...moneyAmountProps }) => {
  return (
    <MoneyAmount
      size={28}
      weight="medium"
      cents={cents}
      direction={direction}
      {...moneyAmountProps}
    />
  );
};

export default TransactionFlexpaneAmount;
