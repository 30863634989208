import { ChartLine } from "@phosphor-icons/react";
import FeatureStatusIndicator from "components/FeatureStatusIndicator";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import CashFlowDurationDropdown from "./components/CashFlowDurationDropdown";
import CashFlowSearchInput from "./components/CashFlowSearchInput";
import CashFlowSyncSheetButton from "./components/CashFlowSyncSheetButton";
import CashFlowSyncSheetModal from "./components/CashFlowSyncSheetModal";
import CashFlowTable from "./components/CashFlowTable";
import CashFlowTabs from "./components/CashFlowTabs";
import CashFlowProvider from "./providers/CashFlowProvider";

const CashFlowHeader = () => {
  return (
    <DashboardPage.Header actions={<CashFlowSyncSheetButton />}>
      <DashboardPage.Header.IconTile icon={<ChartLine />} />
      <DashboardPage.Header.Title>Cash flow</DashboardPage.Header.Title>
      <FeatureStatusIndicator featureStatus="Beta" />
    </DashboardPage.Header>
  );
};

const CashFlowDurationSection = () => {
  return (
    <DashboardPage.Section>
      <CashFlowDurationDropdown />
    </DashboardPage.Section>
  );
};

const CashFlowPage = () => {
  return (
    <>
      <CashFlowSyncSheetModal />

      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Cash flow</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CashFlowProvider>
          <CashFlowHeader />

          <CashFlowDurationSection />

          <CashFlowTabs />

          <DashboardPage.Section>
            <CashFlowSearchInput />
          </DashboardPage.Section>

          <DashboardPage.Section>
            <CashFlowTable />
          </DashboardPage.Section>
        </CashFlowProvider>
      </DashboardPage>
    </>
  );
};

export default CashFlowPage;
