import { useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import usePaymentApprovalsQueryOptions from "./usePaymentApprovalsQueryOptions";

const usePaymentApproval = <TRequired extends boolean>(
  paymentApprovalGuid: string,
  params?: RequiredParam<TRequired>
) => {
  const { data: paymentApproval } = useSuspenseQuery({
    ...usePaymentApprovalsQueryOptions("Open"),
    select: (data) => data.find((payment) => payment.guid === paymentApprovalGuid),
  });

  if (params?.required && !paymentApproval) {
    throw new RequiredButNotFoundError();
  }

  return paymentApproval as DataRequired<typeof paymentApproval, TRequired>;
};

export default usePaymentApproval;
