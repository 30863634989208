import { ArrowSquareOut, Storefront } from "@phosphor-icons/react";
import React from "react";
import PaymentSwitcherAutoPaymentRep from "reps/PaymentSwitcherAutoPaymentRep";
import colors from "styles/colors";
import { Column } from "ui/table/Table";
import Text from "ui/typography/Text";

type Props = {
  payment: PaymentSwitcherAutoPaymentRep;
};

const VendorCell: React.FC<Props> = ({ payment }) => {
  const { counterparty, logoUrl, switchLink } = payment;

  return (
    <div className="flex flex-row items-center gap-3">
      {logoUrl ? (
        <img src={logoUrl} alt="Logo" className="h-4 w-4 shrink-0" />
      ) : (
        <Storefront size={16} color={colors.grey[800]} className="shrink-0" />
      )}
      <Text as="p" size={14} weight="medium" color={colors.grey[800]} className="truncate">
        {counterparty}
      </Text>
      {switchLink && (
        <>
          <span className="sr-only">Switch</span>
          <a href={switchLink} target="_blank" rel="noreferrer" className="shrink-0 cursor-pointer">
            <ArrowSquareOut size={16} color={colors.purple[500]} />
          </a>
        </>
      )}
    </div>
  );
};

export default Object.assign(VendorCell, {
  column: {
    title: "Vendor",
    cellRender: (payment) => <VendorCell payment={payment} />,
  } satisfies Column<PaymentSwitcherAutoPaymentRep>,
});
