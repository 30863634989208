import { atom } from "recoil";
import { HighbeamBankAccountTransferOption } from "utils/transfers";

export enum CaptureOption {
  CAMERA,
  UPLOAD,
}

export type CheckDepositValidation = {
  isValidated: boolean;
  errorMessage: string | null;
};

type CheckDeposit = {
  amountInCents: number;
  account: HighbeamBankAccountTransferOption | null;
  counterpartyName: string;
  description: string;
  front: {
    image: string | null;
    selectedOption: CaptureOption;
    validation: CheckDepositValidation;
  };
  back: {
    image: string | null;
    selectedOption: CaptureOption;
    validation: CheckDepositValidation;
  };
};

const checkDepositState = atom<CheckDeposit>({
  key: "checkDeposit",
  default: {
    amountInCents: 0,
    account: null,
    counterpartyName: "",
    description: "Check deposit",
    front: {
      image: null,
      selectedOption: CaptureOption.CAMERA,
      validation: {
        isValidated: false,
        errorMessage: null,
      },
    },
    back: {
      image: null,
      selectedOption: CaptureOption.CAMERA,
      validation: {
        isValidated: false,
        errorMessage: null,
      },
    },
  },
});

export default checkDepositState;
