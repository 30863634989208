import FileDropzone from "ui/inputs/FileDropzone";
import { DeleteFileButton } from "ui/inputs/FileDropzone/SelectedFile/ActionButtons";

import useDeleteCreditApplicationDocument from "../hooks/useDeleteCreditApplicationDocument";

type Props = {
  guid: string;
  fileName: string;
};

const UploadedFile: React.FC<Props> = ({ guid, fileName }) => {
  const { mutate: deleteCreditApplicationDocument, isPending } =
    useDeleteCreditApplicationDocument();

  return (
    <FileDropzone.SelectedFile
      fileName={fileName}
      actions={
        <DeleteFileButton
          onClick={() => deleteCreditApplicationDocument(guid)}
          isLoading={isPending}
        />
      }
    />
  );
};

export default UploadedFile;
