import { Card } from "@highbeam/unit-node-sdk";
import activateCardIcon from "assets/activate-card-icon.svg";
import { FC } from "react";
import { checkIsDebitCard } from "resources/cards/utils/typeguards";
import Helper from "ui/inputs/Helper";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

import styles from "./ActivateCard.module.scss";
import backOfChargeCard from "./back-of-charge-card.svg";
import backOfDebitCard from "./back-of-debit-card.svg";

type Props = {
  card: Card;
  hasError: boolean;
  onSubmit: () => void;
  isLoading: boolean;
};

const ActivateCard: FC<Props> = ({ card, hasError, onSubmit, isLoading }) => {
  return (
    <ModalV4.Form onSubmit={onSubmit}>
      <ModalV4.Header icon={<img alt="Highbeam account" src={activateCardIcon} />}>
        Activate your card
      </ModalV4.Header>

      <ModalV4.Body className={styles.container}>
        <Text size={14}>Enter the expiry date and CVV number for this card.</Text>
        <form className="mt-4 flex flex-col gap-y-4">
          <div className="flex">
            <div
              id="expiryDate"
              className={cn(
                "h-14 w-1/2 rounded-l-md border border-r-0 border-grey-200 p-4",
                hasError && "border-yellow-400"
              )}
            />
            <div
              id="cvv"
              className={cn(
                "h-14 w-1/2 rounded-r-md border border-grey-200 p-4",
                hasError && "border-yellow-400"
              )}
            />
          </div>
        </form>

        {hasError && (
          <Helper iconVariant="error">Please verify the details are correct and try again.</Helper>
        )}

        <div className="mt-8">
          {checkIsDebitCard(card) ? (
            <img src={backOfDebitCard} alt="Back of Highbeam debit card" />
          ) : (
            <img src={backOfChargeCard} alt="Back of Highbeam Card" />
          )}
        </div>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton isLoading={isLoading}>Activate card</ModalV4.SubmitButton>
        <ModalV4.CloseButton variant="ghost" disabled={isLoading}>
          Cancel
        </ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

export default ActivateCard;
