import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

const ROOT_NAME = "invitations-root";

const invitationBySlugQueryHooks = makeQueryHooks({
  rootName: ROOT_NAME,
  name: "invitationBySlug",
  useQueryVariables: (params: { slug: string | null }) => params,
  useQueryFnMaker: ({ slug }) => {
    const highbeamApi = useHighbeamApi({ authMode: "unauthenticated" });

    return async () => {
      if (!slug) return null;

      const invitation = await highbeamApi.userInvitation.getBySlug(slug);

      if (!invitation) return null;

      const { exists } = await highbeamApi.userExistence.getByEmailAddress(invitation.emailAddress);

      return {
        invitation: invitation,
        userExists: exists,
      };
    };
  },
});

export default invitationBySlugQueryHooks;
