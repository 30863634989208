import { capitalize } from "lodash-es";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

import getCardOrLineCopy from "./getCardOrLineCopy";

const getCapitalAccountTitle = (capitalAccountSummary: CapitalAccountSummaryRep.Complete) => {
  return capitalAccountSummary.state === CapitalAccountRep.State.Offered
    ? `${capitalize(getCardOrLineCopy(capitalAccountSummary))} offer details`
    : capitalAccountSummary.name;
};

export default getCapitalAccountTitle;
