import { ArrowFatLinesUp } from "@phosphor-icons/react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./ChargeCardRepaymentType.module.scss";

const ChargeCardRepaymentType = () => (
  <div className={styles.container}>
    <ArrowFatLinesUp size={14} color={colors.purple[500]} />
    <Text size={14} weight="medium" color={colors.grey[800]} className={styles.text}>
      Repayment
    </Text>
  </div>
);

export default ChargeCardRepaymentType;
