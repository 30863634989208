import { autoCaptureHints } from "pages/DepositCheckPage/CaptureCheck/CaptureHints";
import { useEffect, useState } from "react";
import { CheckDepositValidation } from "state/checkDeposit";

export type mitekDocumentType = "CHECK_FRONT" | "CHECK_BACK";

export const useMitekAutoCapture = (
  containerId: string,
  documentType: mitekDocumentType,
  setValidationState: (state: CheckDepositValidation) => void
) => {
  const [isLoadingCamera, setIsLoadingCamera] = useState(false);
  const [startAutoCapture, setStartAutoCapture] = useState<boolean>(false);
  const [autoCapturedImage, setAutoCapturedImage] = useState<string | null>(null);

  //Error message
  // Have to manually close SDK's camera
  useEffect(() => {
    if (!startAutoCapture) {
      window.mitekScienceSDK.cmd("SDK_STOP");
    } else {
      setIsLoadingCamera(true);

      // When the camera starts
      window.mitekScienceSDK.on("CAMERA_DISPLAY_STARTED", () => {
        setIsLoadingCamera(false);
        window.mitekScienceSDK.cmd("SHOW_HINT", autoCaptureHints["MITEK_FIRST_MESSAGE"]);
      });

      // During capture
      window.mitekScienceSDK.on("FRAME_PROCESSING_FEEDBACK", (result: any) => {
        const recentHint = result.key as string;
        if (recentHint !== null) {
          if (autoCaptureHints[recentHint] === undefined) {
            window.mitekScienceSDK.cmd("SHOW_HINT", autoCaptureHints["MITEK_ERROR_FOUR_CORNER"]);
          } else {
            window.mitekScienceSDK.cmd("SHOW_HINT", autoCaptureHints[recentHint]);
          }
        }
      });

      // Post capture
      window.mitekScienceSDK.on("FRAME_CAPTURE_RESULT", (result: any) => {
        setAutoCapturedImage(result.response.imageData as string);
        setValidationState({ isValidated: true, errorMessage: null });
        window.mitekScienceSDK.cmd("SDK_STOP");
      });

      setValidationState({ isValidated: false, errorMessage: null });

      window.mitekScienceSDK.cmd("CAPTURE_AND_PROCESS_FRAME", {
        mode: "AUTO_CAPTURE",
        documentType: documentType,
        mitekSDKPath: "vendors/mi-snap-sdk/mitekSDK/",
        options: {
          qualityPercent: 80, // value between 50 to 100 recommended
          hintFrequencyMS: 1200, // how long the hint message displays (in MS) / note: 2400 recommended for screen readers
          hintAriaLive: 2, //screen reader setting for hint updates value (0=off / 1=polite / 2=assertive)
          guidePaddingLevel: 1, // size of the overlay guide  (1=large, 2=medium, 3=small) / note: 1 is recommended
          hintMessageSize: 3, // set the size of the hint message (1=small / 2=medium / 3=large)
          disableSmileDetection: true, // disable smile detection
          videoContainerId: containerId, // (optionally) embed the video in a custom HTML container
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAutoCapture]);

  return {
    isLoadingCamera,
    startAutoCapture,
    setStartAutoCapture,
    autoCapturedImage,
  };
};
