import PayeeRep from "reps/PayeeRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import { useRefreshPayeesQueries } from "../queries/usePayees";

type Variables = PayeeRep.Update & { payeeGuid: string };

/**
 * This mutation updates payees just like the main payee update method, but uses
 * a dedicated API for mutations in the bill pay context, which performs additional
 * business logic on the backend.
 */
const useUpdatePayeeForBillPayMutation = (
  additionalOptions: MutationAdditionalOptions<PayeeRep.Complete, Variables> = {}
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshPayeesQueries = useRefreshPayeesQueries();

  return useMutationWithDefaults(
    {
      mutationFn: ({ payeeGuid, ...update }) => {
        return highbeamApi.billPayee.updatePayeeForBillPay(businessGuid, payeeGuid, update);
      },
      onSuccess: () => {
        refreshPayeesQueries();
      },
    },
    additionalOptions
  );
};

export default useUpdatePayeeForBillPayMutation;
