import { MagnifyingGlass } from "@phosphor-icons/react";
import { useCashFlowContext } from "pages/CashFlowPage/providers/CashFlowProvider";
import colors from "styles/colors";
import TextInput from "ui/inputs/TextInput";

const CashFlowSearchInput = () => {
  const { search, setSearch } = useCashFlowContext();

  return (
    <TextInput
      placeholder="Search for vendors by name"
      value={search}
      onChange={setSearch}
      append={<MagnifyingGlass size={20} color={colors.grey[900]} weight="light" />}
    />
  );
};

export default CashFlowSearchInput;
