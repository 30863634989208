import { AccountEndOfDay } from "@highbeam/unit-node-sdk";
import { startOfBankingDay } from "utils/date";

export const BANK_ACCOUNT_NUM_DAYS_HISTORY = 30;

const getHistoryWithTodayForSingleAccount = (
  accountEndOfDay: AccountEndOfDay[],
  bankAccountTotalBalance: number
) => {
  const history = accountEndOfDay
    .map((point) => ({
      date: startOfBankingDay(point.attributes.date, { format: "YYYY-MM-DD" }).format("MMMM D"),
      amount: point.attributes.available,
    }))
    .reverse();

  if (history.length === 0) {
    history.push({
      date: startOfBankingDay().format("MMMM D"),
      amount: 0,
    });
  }
  const numPaddedDays = Math.max(BANK_ACCOUNT_NUM_DAYS_HISTORY - history.length, 0);
  const paddedHistory = [
    ...[...Array(numPaddedDays)].map((_, i) => ({
      date: startOfBankingDay(history[0].date, { format: "MMMM D" })
        .subtract(numPaddedDays - i, "d")
        .format("MMMM D"),
      amount: 0,
    })),
    ...history,
  ];
  const historyWithToday = [
    ...paddedHistory,
    {
      date: startOfBankingDay(history[history.length - 1].date, { format: "MMMM D" })
        .add(1, "d")
        .format("MMMM D"),
      amount: bankAccountTotalBalance,
    },
  ];
  return historyWithToday;
};

export default getHistoryWithTodayForSingleAccount;
