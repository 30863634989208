import classNames from "classnames";
import React, { PropsWithChildren } from "react";

import styles from "./Section.module.scss";

type Props = {
  className?: string;
} & PropsWithChildren;

const Section: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(className, styles.container)}>{children}</div>
);

export default Section;
