import env from "env";

function initIntercom() {
  // Setting `app_id` fixes an intercom console warning, but it differs from their SPA docs.
  // Either:
  // a) Itercom's warning logic is wrong
  // b) Intercom's docs are wrong
  // Consider deleting this in the future if the warning goes away, or delete this comment if the docs get updated.
  // Full slack thread is here: https://highbeamco.slack.com/archives/C02M3GGQPHC/p1706045073049469?thread_ts=1706039154.340839&cid=C02M3GGQPHC
  window.intercomSettings = {
    app_id: env.INTERCOM_APP_ID, // eslint-disable-line camelcase
  };

  const w = window;
  const ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    const d = document;
    const i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${env.INTERCOM_APP_ID}`;
      const x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
}

export default initIntercom;
