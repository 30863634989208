import { TabConfig } from "utils/tabs/useTabState";
import cn from "utils/tailwind/cn";

import SegmentControlsButton, { SegmentControlsButtonSize } from "./SegmentControlsButton";

type SegmentControlsSize = SegmentControlsButtonSize;

type Props<TTabs extends TabConfig> = {
  className?: string;
  activeTab: keyof TTabs;
  setActiveTab: (tab: keyof TTabs) => void;
  tabs: TTabs;
  size: SegmentControlsSize;
};

const SegmentControls = <TTabs extends TabConfig>({
  className,
  activeTab,
  setActiveTab,
  tabs,
  size,
}: Props<TTabs>) => {
  const tabsArray = Object.entries(tabs);
  return (
    <div className={cn("flex h-fit items-center gap-x-1 rounded-full bg-grey-100 p-1", className)}>
      {tabsArray.map(([tabName, { icon, label, count, displayAttentionBadge }]) => (
        <SegmentControlsButton
          size={size}
          key={tabName}
          icon={icon}
          isActive={tabName === activeTab}
          count={count}
          displayAttentionBadge={displayAttentionBadge}
          onClick={() => setActiveTab(tabName)}
        >
          {label}
        </SegmentControlsButton>
      ))}
    </div>
  );
};

export default Object.assign(SegmentControls, {
  Button: SegmentControlsButton,
});
