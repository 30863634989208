import OnboardingLayout from "layouts/OnboardingLayout";
import React from "react";
import BankApplicationRep from "reps/BankApplicationRep";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import { Heading1, Paragraph } from "ui/typography";

type Props = {
  bankApplication: BankApplicationRep.Complete;
};

const BankApplicationPending: React.FC<Props> = ({ bankApplication }) => {
  const { body, title, cta } = getStatusCardConfig(bankApplication);

  return (
    <OnboardingLayout>
      <Card className="mt- relative p-8">
        <Heading1 className="mb-2 font-bold">{title}</Heading1>
        <Paragraph className="text-sm text-grey-500">{body}</Paragraph>
        {cta && (
          <Button variant="primary" className="mt-6" onClick={cta.action}>
            {cta?.text}
          </Button>
        )}
      </Card>
    </OnboardingLayout>
  );
};

export default BankApplicationPending;

type StatusCardConfig = {
  title: string;
  body: string;
  cta?: { text: string; action: () => void };
};

const getStatusCardConfig = (bankApplication: BankApplicationRep.Complete): StatusCardConfig => {
  if (
    bankApplication.status === BankApplicationRep.Status.Pending ||
    bankApplication.status === BankApplicationRep.Status.PendingReview
  ) {
    return {
      title: "Almost done!",
      body: "We are processing your information and we will contact you once your Highbeam account is ready to use.",
    };
  } else if (bankApplication.status === BankApplicationRep.Status.AwaitingDocuments) {
    return {
      title: "Additional documents required",
      body: "Please upload the additional documentation to complete your application.",
      cta: {
        text: "Upload documents",
        action: () => window.location.replace(bankApplication.url!),
      },
    };
  }
  throw new Error("Bank Application status unhandled.");
};
