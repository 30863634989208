import { useRecoilValue } from "recoil";
import highbeamApiState, { AuthMode } from "state/auth/highbeamApi";

type UseHighbeamApiOptions = {
  authMode?: AuthMode;
};

const useHighbeamApi = ({ authMode = "authenticated" }: UseHighbeamApiOptions = {}) =>
  useRecoilValue(highbeamApiState(authMode));

export default useHighbeamApi;
