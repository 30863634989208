import { useCallback } from "react";
import useRefreshQuery, { UseRefreshQueryOptions } from "utils/react-query/useRefreshQuery";

import { BASE_QUERY_KEY } from "./useAccountingAccount";
import { useRefreshAccountingAccountsQuery } from "./useAccountingAccounts";

const useRefreshAccountingAccountsQueries = (options: UseRefreshQueryOptions = {}) => {
  const refreshAccountingAccountsQuery = useRefreshAccountingAccountsQuery(options);
  const refreshIndividualAccountingAccountsQuery = useRefreshQuery([BASE_QUERY_KEY], options);

  return useCallback(async () => {
    await Promise.all([
      refreshAccountingAccountsQuery(),
      refreshIndividualAccountingAccountsQuery(),
    ]);
  }, [refreshAccountingAccountsQuery, refreshIndividualAccountingAccountsQuery]);
};

export default useRefreshAccountingAccountsQueries;
