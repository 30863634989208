import { Card } from "@highbeam/unit-node-sdk";
import { captureException } from "@sentry/react";
import useMfa from "modules/mfa/useMfa";
import React, { useRef, useState } from "react";
import Text from "ui/typography/Text";
import useIsAllowedToReadCardNumber from "utils/permissions/useIsAllowedToReadCardNumber";

import HighbeamCardField from "./HighbeamCardField";
import HighbeamCardSensitiveData from "./HighbeamCardSensitiveData";

type Props = {
  label?: string;
  placeholder: string;
  isLoading: boolean;
  isRevealed: boolean;
  isAvailable: boolean;
  vgs: { cardId: string; name: string; field: string };
  textColor?: "white" | "black";
  card: Card;
};

const HighbeamCardSensitiveField: React.FC<Props> = (props) => {
  const isAllowedToReadCardNumber = useIsAllowedToReadCardNumber(props.card);

  const { isMfaComplete, mfa } = useMfa();

  const copyButtonRef = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    if (isAllowedToReadCardNumber && !isMfaComplete) {
      try {
        // `isMfaComplete` triggers sensitive data fetching in `HighbeamCardSensitiveData`.
        await mfa();
      } catch (e) {
        captureException(e);
      }
    }
  };

  /**
   * In order to preserve container size, the placeholder is hidden (transparent color) instead of
   * unmounted when the sensitive data is revealed.
   */
  const placeholderStyle = {
    color: props.isLoading || props.isRevealed ? "transparent" : props.textColor,
    marginRight: "4px", // Added to account for browser rounding (HB-1455).
  };

  return (
    <>
      <HighbeamCardField
        label={props.label}
        numeric
        copy={{ buttonRef: copyButtonRef, onClick: handleCopy, state: [copied, setCopied] }}
        textColor={props.textColor}
        isCopyable={isAllowedToReadCardNumber && props.isAvailable}
      >
        <Text size={14} style={placeholderStyle} color={props.textColor}>
          {props.placeholder}
        </Text>
        {isAllowedToReadCardNumber && props.isAvailable && (
          <HighbeamCardSensitiveData
            isRevealed={props.isRevealed}
            isLoading={props.isLoading}
            vgs={props.vgs}
            copy={{ buttonRef: copyButtonRef, onCopy: () => setCopied(true) }}
            textColor={props.textColor}
          />
        )}
      </HighbeamCardField>
    </>
  );
};

export default HighbeamCardSensitiveField;
