import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useHasPermission from "utils/permissions/useHasPermission";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { CONNECTED_STORES_QUERY_KEY } from "./useConnectedStoresQueryOptions";

const useRutterConnectionsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const hasPermission = useHasPermission("storeConnection:read");

  return useQueryOptions({
    queryKey: [CONNECTED_STORES_QUERY_KEY, "rutter", businessGuid],
    queryFn: () => {
      if (!hasPermission) {
        return [];
      }

      return highbeamApi.rutterConnection.getByBusiness(businessGuid);
    },
  });
};

export default useRutterConnectionsQueryOptions;
