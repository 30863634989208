import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const ROOT_PLAID_ACCOUNTS_QUERY_NAME = "plaidAccounts-root";

export const useRefreshAllPlaidAccountsQueries = () => {
  return useRefreshQuery([ROOT_PLAID_ACCOUNTS_QUERY_NAME]);
};

const plaidAccountsQueryHooks = makeQueryHooks({
  rootName: ROOT_PLAID_ACCOUNTS_QUERY_NAME,
  name: "plaidAccounts",
  useQueryVariables: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  useQueryFnMaker: (variables) => {
    const highbeamApi = useHighbeamApi();
    return () => {
      return highbeamApi.plaid.getPlaidBankAccountsByBusiness(variables.businessGuid);
    };
  },
});

export default plaidAccountsQueryHooks;
