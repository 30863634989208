import {
  getValidator,
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import TextInput, { useTextInput } from "ui/inputs/TextInput";

type Props = {
  selectedCountry: string;
};

const BranchCode: React.FC<Props> = ({ selectedCountry }) => {
  const [internationalBankingInfo, setInternationalBankingInfo] = useRecoilState(
    internationalBankingInfoState
  );
  const BranchCode = useTextInput({
    initialValue: internationalBankingInfo.branchCode?.value,
    onChange: getValidator(selectedCountry, INTERNATIONAL_WIRE_INPUTS.BRANCH_CODE),
  });

  useEffect(() => {
    setInternationalBankingInfo({
      ...internationalBankingInfo,
      branchCode: {
        inputName: INTERNATIONAL_WIRE_INPUTS.BRANCH_CODE,
        value: BranchCode.value,
        isValid: BranchCode.isValid,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BranchCode.value, BranchCode.isValid]);

  return (
    <TextInput
      type="text"
      value={BranchCode.value}
      onChange={(value) => {
        BranchCode.onChange(value);
      }}
      label={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.BRANCH_CODE].label}
      onBlur={BranchCode.onBlur}
      hasError={BranchCode.hasError}
      errorMessage={
        INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.BRANCH_CODE].errorMessage
      }
    />
  );
};

export default BranchCode;
