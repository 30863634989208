namespace CreditApplicationDocumentRep {
  export enum DocumentType {
    ApArAgingReport = "ApArAgingReport",
    BalanceSheet = "BalanceSheet",
    OrgStructure = "OrgStructure",
    InventorySchedule = "InventorySchedule",
    Other = "Other",
    ProfitAndLossStatement = "ProfitAndLossStatement",
    Unknown = "Unknown",
  }

  export interface Complete {
    readonly guid: string;
    readonly type: DocumentType;
    readonly businessGuid: string;
    readonly creditApplicationGuid: string;
    readonly signedUploadUrl: string;
    readonly fileName: string;
    readonly notes: string | null;
  }

  export interface Creator {
    readonly type: DocumentType;
    readonly fileName: string;
    readonly notes: string | null;
  }
}

export default CreditApplicationDocumentRep;
