import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import MultiStep from "layouts/MultiStep";
import { useState } from "react";
import { ErrorCode } from "react-dropzone";
import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import useCreditApplicationDocumentsQueryOptions from "resources/credit-application/queries/useCreditApplicationDocumentsQueryOptions";
import FileDropzone from "ui/inputs/FileDropzone";
import { FileType } from "utils/dropzone/file-types";
import stringToReactKey from "utils/react-helpers/stringToReactKey";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import useUploadCreditApplicationDocument from "../hooks/useUploadCreditApplicationDocument";

import UploadedFile from "./UploadedFile";

const ALL_FILE_TYPES: FileType[] = ["image", "pdf", "word", "excel", "csv"];
const EXCEL_CSV_FILE_TYPES: FileType[] = ["excel", "csv"];

type Props = React.PropsWithChildren<{
  heading?: React.ReactNode;
  subheading?: React.ReactNode;
  type: CreditApplicationDocumentRep.DocumentType;
}>;

const UploadDocumentsSection: React.FC<Props> = ({ heading, subheading, type, children }) => {
  const refreshCreditApplicationDocumentsQuery = useRefreshQuery(
    useCreditApplicationDocumentsQueryOptions().queryKey
  );
  const { data: creditApplicationDocuments = [] } = useQuery(
    useCreditApplicationDocumentsQueryOptions()
  );
  const uploadedDocuments = creditApplicationDocuments.filter((d) => d.type === type) || [];
  const [documentsToUpload, setDocumentsToUpload] = useState<File[]>([]);
  const { mutateAsync: uploadCreditApplicationDocument } = useUploadCreditApplicationDocument();

  const isExcelCsvFileType =
    type === CreditApplicationDocumentRep.DocumentType.ApArAgingReport ||
    type === CreditApplicationDocumentRep.DocumentType.BalanceSheet ||
    type === CreditApplicationDocumentRep.DocumentType.ProfitAndLossStatement;

  return (
    <MultiStep.Section>
      {(heading || subheading) && (
        <MultiStep.Section.Header>
          {heading && (
            <MultiStep.Section.Header.Heading>{heading}</MultiStep.Section.Header.Heading>
          )}
          {subheading && (
            <MultiStep.Section.Header.Subheading>{subheading}</MultiStep.Section.Header.Subheading>
          )}
        </MultiStep.Section.Header>
      )}

      <FileDropzone
        fileTypes={isExcelCsvFileType ? EXCEL_CSV_FILE_TYPES : ALL_FILE_TYPES}
        maxSize={52428800} // 50mb
        subtext={isExcelCsvFileType && "Excel or CSV file type"}
        onDropRejected={(fileRejections) => {
          fileRejections.forEach((fileRejection) => {
            fileRejection.errors.forEach(({ code }) => {
              if (code === ErrorCode.FileTooLarge) {
                // NB(alex): Capturing this exception to see if we need to increase the limit
                captureException(new Error(`File too large: ${fileRejection.file.name}`));
              }
            });
          });
        }}
        onDropAccepted={async (documentsToUpload) => {
          setDocumentsToUpload(documentsToUpload);
          for (const file of documentsToUpload) {
            // NB(alex): Ideally we wouldn't `await` each document upload, but we do this to ensure a consistent `createdAt` order.
            //  Otherwise, the documents come back in an inconsistent order when calling `refetch` below.
            //  It would be cleanest to use an ordinal for sorting, but the backend doesn't support this yet.
            // eslint-disable-next-line no-await-in-loop
            await uploadCreditApplicationDocument({ file, notes: null, type: type });
          }
          await refreshCreditApplicationDocumentsQuery();
          setDocumentsToUpload([]);
        }}
      >
        {uploadedDocuments.map(({ guid, fileName }) => (
          <UploadedFile key={guid} guid={guid} fileName={fileName} />
        ))}

        {documentsToUpload.map((file, index) => (
          <FileDropzone.SelectedFile
            key={stringToReactKey(file.name, index)}
            fileName={file.name}
            isLoading
          />
        ))}
      </FileDropzone>

      {children}
    </MultiStep.Section>
  );
};

export default UploadDocumentsSection;
