import BusinessMemberSummaryRep from "reps/BusinessMemberSummaryRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BusinessMemberSummaryApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async listByUser(userGuid: string): Promise<BusinessMemberSummaryRep.Complete[]> {
    const queryParams = new URLSearchParams({ userGuid });
    const url = `/business-member-summaries?${queryParams}`;
    return (await this.api.get<BusinessMemberSummaryRep.Complete[]>(url))!;
  }
}
