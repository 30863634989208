import { useSuspenseQuery } from "@tanstack/react-query";
import useUser from "resources/user/queries/useUser";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const useUserInvitationsByEmailAddressQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const { emailAddress } = useUser();

  return useQueryOptions({
    queryKey: ["userInvitationsByEmailAddress", { emailAddress }],
    queryFn: () => {
      return highbeamApi.userInvitation.getByEmailAddress(emailAddress);
    },
  });
};

const useUserInvitationsByEmailAddress = () => {
  return useSuspenseQuery(useUserInvitationsByEmailAddressQueryOptions()).data;
};

export default useUserInvitationsByEmailAddress;
