import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import { useCapitalDrawdownApprovalsQueryOptions } from "./useCapitalDrawdownApprovals";

type Params = {
  capitalAccountGuid: string;
  pendingDrawdownGuid: string;
};

const useCapitalDrawdownApprovalQueryOptions = ({
  capitalAccountGuid,
  pendingDrawdownGuid,
}: Params) => {
  const { queryKey, queryFn } = useCapitalDrawdownApprovalsQueryOptions({
    capitalAccountGuid,
  });

  return {
    queryKey: [...queryKey, pendingDrawdownGuid],
    queryFn: async () => {
      const capitalDrawdownApprovals = await queryFn();
      return capitalDrawdownApprovals.find(
        (pendingDrawdown) => pendingDrawdown.guid === pendingDrawdownGuid
      );
    },
  };
};

export const useCapitalDrawdownApprovalQuery = (params: Params) => {
  return useQuery(useCapitalDrawdownApprovalQueryOptions(params));
};

const useCapitalDrawdownApproval = <TRequired extends boolean>({
  required,
  ...params
}: RequiredParam<TRequired> & Params) => {
  const { data } = useSuspenseQuery(useCapitalDrawdownApprovalQueryOptions(params));

  if (required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useCapitalDrawdownApproval;
