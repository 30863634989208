import { Check } from "@phosphor-icons/react";
import classNames from "classnames";
import Text from "ui/typography/Text";

import styles from "./Steps.module.scss";

export type StepType = {
  id: number;
  number: number;
  title: string;
};

type StepProps = {
  step: StepType;
  isActive: boolean;
  isDone: boolean;
  isIncomplete: boolean;
  isLast: boolean;
};

const Step: React.FC<StepProps> = ({ step, isActive, isDone, isIncomplete, isLast }) => (
  <div
    className={classNames({
      [styles.step]: true,
      [styles.active]: isActive,
      [styles.incomplete]: isIncomplete,
      [styles.done]: isDone,
    })}
  >
    {!isLast && <div className={styles.line} />}
    <div className={styles.number}>{isDone ? <Check weight="bold" /> : step.number}</div>
    <Text className={styles.title} size={14}>
      {step.title}
    </Text>
  </div>
);

type Props = {
  steps: StepType[];
  currentStep: number;
};

const Steps: React.FC<Props> = ({ steps, currentStep }) => (
  <div className={classNames("hide-scrollbars", styles.container)}>
    {steps.map((step, index) => (
      <Step
        key={step.id}
        step={step}
        isActive={currentStep === step.id}
        isDone={currentStep > step.id}
        isIncomplete={currentStep < step.id}
        isLast={index === steps.length - 1}
      />
    ))}
  </div>
);

export default Steps;
