import UserRoleMembershipRep from "reps/UserRoleMembershipRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserRoleMembershipsQueryOptions from "resources/user-roles/queries/useUserRoleMembershipsQueryOptions";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

type Variables = {
  userRoleGuid: string;
  userGuid: string;
};

const useEditUserRoleMutation = (
  additionalOptions?: MutationAdditionalOptions<UserRoleMembershipRep.Complete[], Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const refreshUserRoleMembershipsQuery = useRefreshQuery(
    useUserRoleMembershipsQueryOptions().queryKey
  );

  return useMutationWithDefaults(
    {
      mutationFn: ({ userGuid, userRoleGuid }: Variables) => {
        return highbeamApi.userRoleMembership.setByUser(businessGuid, userGuid, [
          { userRoleGuid: userRoleGuid },
        ]);
      },
      onSuccess: async () => {
        await refreshUserRoleMembershipsQuery();
      },
    },
    additionalOptions ?? {}
  );
};

export default useEditUserRoleMutation;
