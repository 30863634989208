import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import cn from "utils/tailwind/cn";

const BASE_CLASSES = "text-grey-900";

const Paragraph = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ children, className, ...props }, ref) => (
    <p className={cn(BASE_CLASSES, className)} ref={ref} {...props}>
      {children}
    </p>
  )
);

const Span = forwardRef<ElementRef<"span">, ComponentPropsWithoutRef<"span">>(
  ({ children, className, ...props }, ref) => (
    <span className={cn(BASE_CLASSES, className)} ref={ref} {...props}>
      {children}
    </span>
  )
);

const Strong = forwardRef<ElementRef<"strong">, ComponentPropsWithoutRef<"strong">>(
  ({ children, className, ...props }, ref) => (
    <strong className={cn(BASE_CLASSES, "font-medium", className)} ref={ref} {...props}>
      {children}
    </strong>
  )
);

const Em = forwardRef<ElementRef<"em">, ComponentPropsWithoutRef<"em">>(
  ({ children, className, ...props }, ref) => (
    <em className={cn(BASE_CLASSES, "italic", className)} ref={ref} {...props}>
      {children}
    </em>
  )
);

const makeTextLinkClasses = (className?: string) =>
  cn(BASE_CLASSES, "text-purple-500 underline visited:text-purple-500", className);

type LinkProps = ComponentPropsWithoutRef<"a"> & {
  href: string;
  target?: never;
};

const Link = forwardRef<ElementRef<"a">, LinkProps>(
  ({ children, className, href, ...props }, ref) => {
    const isExternalLink = href.startsWith("http");
    const classes = makeTextLinkClasses(className);

    if (isExternalLink) {
      return (
        <a className={classes} href={href} ref={ref} {...props}>
          {children}
        </a>
      );
    }

    return (
      <RouterLink className={classes} to={href} ref={ref} {...props}>
        {children}
      </RouterLink>
    );
  }
);

type TextButtonProps = ComponentPropsWithoutRef<"button">;

const TextButton = forwardRef<ElementRef<"button">, TextButtonProps>(
  ({ children, className, disabled, ...props }, ref) => (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      className={makeTextLinkClasses(
        cn("inline-flex items-center", disabled && "pointer-events-none opacity-50", className)
      )}
      {...props}
    >
      {children}
    </button>
  )
);

const Heading1 = forwardRef<ElementRef<"h1">, ComponentPropsWithoutRef<"h1">>(
  ({ children, className, ...props }, ref) => (
    <h1 className={cn(BASE_CLASSES, "text-xl font-medium", className)} ref={ref} {...props}>
      {children}
    </h1>
  )
);

const Heading2 = forwardRef<ElementRef<"h2">, ComponentPropsWithoutRef<"h2">>(
  ({ children, className, ...props }, ref) => (
    <h2 className={cn(BASE_CLASSES, "text-lg font-bold", className)} ref={ref} {...props}>
      {children}
    </h2>
  )
);

const Heading3 = forwardRef<ElementRef<"h3">, ComponentPropsWithoutRef<"h3">>(
  ({ children, className, ...props }, ref) => (
    <h3 className={cn(BASE_CLASSES, "text-md font-bold", className)} ref={ref} {...props}>
      {children}
    </h3>
  )
);

const Heading4 = forwardRef<ElementRef<"h4">, ComponentPropsWithoutRef<"h4">>(
  ({ children, className, ...props }, ref) => (
    <h4 className={cn(BASE_CLASSES, "text-sm font-bold", className)} ref={ref} {...props}>
      {children}
    </h4>
  )
);

export { Paragraph, Span, Strong, Em, Link, TextButton, Heading1, Heading2, Heading3, Heading4 };
