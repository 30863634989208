import DashboardPage from "layouts/DashboardPage";
import IncomingPaymentsTable from "modules/incoming-payments/components/IncomingPaymentsTable";
import useIncomingPayments from "modules/incoming-payments/queries/useIncomingPayments";
import { FC } from "react";

type Props = {
  unitCoDepositAccountId: string;
};

const AccountDetailsIncomingPaymentsSection: FC<Props> = ({ unitCoDepositAccountId }) => {
  const incomingPayments = useIncomingPayments({ accountId: unitCoDepositAccountId });

  if (incomingPayments.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>In transit</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <IncomingPaymentsTable accountId={unitCoDepositAccountId} />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default AccountDetailsIncomingPaymentsSection;
