import emptyImg from "assets/empty-state.svg";
import EmptyState from "ui/table/EmptyState";

const Empty = () => (
  <EmptyState
    image={emptyImg}
    secondaryText="Once you have transactions, they will be shown here."
    primaryText="No transactions available"
  />
);

export default Empty;
