import { useRecoilValue } from "recoil";
import useIsStandaloneDisplayMode from "utils/device/useIsStandaloneDisplayMode";
import { useIsTablet } from "utils/device/useMediaQuery";

import hasAddToHomeScreenBannerBeenDismissedState from "../state/hasAddToHomeScreenBannerBeenDismissedState";

const useShouldShowAddToHomeScreenBanner = () => {
  const isTablet = useIsTablet();
  const isStandaloneDisplayMode = useIsStandaloneDisplayMode();
  const hasAddToHomeScreenBannerBeenDismissed = useRecoilValue(
    hasAddToHomeScreenBannerBeenDismissedState
  );

  return isTablet && !isStandaloneDisplayMode && !hasAddToHomeScreenBannerBeenDismissed;
};

export default useShouldShowAddToHomeScreenBanner;
