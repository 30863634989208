import DarbEncoder from "utils/darb/DarbEncoder";

/**
 * The numbers associated with these permissions are defined the backend.
 * IMPORTANT: These numbers MUST match the values specified in the backend.
 */
const permission = {
  "accountsPayableBill:read": 86,
  "accountsPayableBill:readAssigned": 102,
  "accountsPayableBill:write": 87,
  "accountsPayableBill:delete": 88,

  "accountsPayableBillApproval:read": 89,
  "accountsPayableBillApproval:write": 90,
  "accountsPayableBillApproval:delete": 91,

  "accountsPayableBillPayment:read": 92,
  "accountsPayableBillPayment:write": 93,
  "accountsPayableBillPayment:delete": 94,

  "accountsPayableSettings:read": 106,
  "accountsPayableSettings:write": 107,

  "bankAccount:read": 1,
  "bankAccount:readNumber": 2,
  "bankAccount:create": 3,
  "bankAccount:update": 4,
  "bankAccount:delete": 63,

  "bankConnection:read": 7,
  "bankConnection:create": 8,

  "business:update": 10,
  "businessAddress:set": 12,

  "businessMember:readSelf": 65,
  "businessMember:readAny": 66,
  "businessMember:createSelf": 14,
  "businessMember:updateSelf": 67,
  "businessMember:updateAny": 15,
  "businessMember:updateOwner": 16,
  "businessMember:deleteAny": 17,

  "businessOwner:set": 19,

  "capital:drawdown": 25,
  "capital:repay": 26,

  "card:read": 20, // deprecated
  "card:readOwn": 70,
  "card:readAny": 71,

  "card:readNumber": 21, // deprecated
  "card:readNumberOwn": 72,
  "card:readNumberAny": 73,

  "card:create": 22,
  "card:update": 23,
  "card:activate": 24,
  "card:activateOwn": 76,
  "card:activateAny": 77,

  "chat:message": 108,

  "checkDeposit:create": 32,

  "creditApplication:write": 34,

  "insightsAlias:write": 80,
  "insightsAuditReport:read": 82,
  "insightsCategorizer:write": 81,
  "insightsCounterpartyReport:read": 78,
  "insightsTransactionReport:read": 79,

  "payee:read": 38,
  "payee:update": 40,
  "payee:delete": 41,

  "payment:read": 42,
  "payment:create": 43,
  "payment:cancel": 64,
  "payment:createTransfer": 68,

  "paymentApproval:read": 44,
  "paymentApproval:create": 45,

  "storeConnection:read": 48,
  "storeConnection:create": 49,

  "userInvitation:create": 57,
  "userInvitation:delete": 58,

  "userRoleMembership:write": 61,

  "vendorList:read": 62,
} as const;

export type JwtPermission = keyof typeof permission;

const allPermissions: Map<number, JwtPermission> = new Map(
  Object.entries(permission).map(([permission, index]) => [index, permission as JwtPermission])
);

export const decodeAcl = (acl: Record<string, string>): Map<string, JwtPermission[]> =>
  new Map(Object.entries(acl).map(([businessGuid, darb]) => [businessGuid, decodeDarb(darb)]));

const decodeDarb = (darb: string): JwtPermission[] => {
  const booleanList = DarbEncoder.decode(darb);
  return booleanList.flatMap((value, i) => {
    if (!value) return [];
    const permission = allPermissions.get(i);
    if (!permission) return [];
    return [permission];
  });
};
