import { DefaultValue, atom, selector } from "recoil";
import businessGuidState from "state/auth/businessGuid";
import highbeamApiState from "state/auth/highbeamApi";
import isAuthenticatedState from "state/auth/isAuthenticated";

const key = "compareCreditOffers/creditComparisonSessionGuid";

const __resetterState = atom({
  key: "compareCreditOffers/sessionGuidResetter",
  default: 0,
});

const sessionGuidState = selector<string>({
  key: key,
  get: async ({ get }) => {
    // Trigger a reset if the resetter state changes
    get(__resetterState);

    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      return storedValue;
    }

    const isAuthenticated = get(isAuthenticatedState);
    const highbeamApi = get(highbeamApiState("maybeAuthenticated"));

    const data = await highbeamApi.creditComparison.createCreditComparisonSession(
      isAuthenticated ? get(businessGuidState) : null
    );
    const guid = data?.guid ?? null;

    if (guid) {
      localStorage.setItem(key, guid);
      return guid;
    } else {
      throw new Error("Could not create credit comparison session");
    }
  },
  set: ({ set }, newValue) => {
    // Clear local storage and trigger a reset
    if (newValue instanceof DefaultValue) {
      localStorage.removeItem(key);
      set(__resetterState, (oldValue) => oldValue + 1);
    }
  },
});

export default sessionGuidState;
