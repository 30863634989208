import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";
import { HighbeamReceivedAchTransaction } from "utils/types/transactionsTypes";

import TraceNumberLabel from "./TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";

type Props = {
  transaction: HighbeamReceivedAchTransaction;
};

const ReceivedAchTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>ACH transaction details</Heading3>
    <MetadataList>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.description}</MetadataList.ItemValue>
      </MetadataList.Item>
      {transaction.addenda !== "" && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Additional details</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{transaction.addenda}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      <MetadataList.Item>
        <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.counterpartyRoutingNumber}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>
          <TraceNumberLabel />
        </MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.traceNumber}</MetadataList.ItemValue>
      </MetadataList.Item>
    </MetadataList>
  </Section>
);

export default ReceivedAchTransactionDetailsSection;
