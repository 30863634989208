import { MapPin } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import { FC } from "react";

import BusinessAddressForm from "./BusinessAddressForm";

const BusinessAddressSection: FC = () => {
  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header
        subheading="This address is the primary address associated with your Highbeam account. It will be
        used as the billing address for all of your cards."
      >
        <DashboardPage.Section.HeaderHeading icon={<MapPin />}>
          Business address
        </DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <BusinessAddressForm />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};
export default BusinessAddressSection;
