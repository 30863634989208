import { Money } from "./Money";

namespace BillRep {
  export enum State {
    Processing = "Processing",
    Draft = "Draft",
    Open = "Open",
    Paid = "Paid",
    Closed = "Closed",
  }

  export interface Complete {
    readonly id: string;
    readonly businessGuid: string;
    readonly amount: Money | null;
    readonly state: State;
    readonly duplicateOfBillId: string | null;
    readonly invoiceDate: string | null; // LocalDate
    readonly invoiceDueDate: string | null; // LocalDate
    readonly invoiceNumber: string | null;
    readonly payeeGuid: string | null;
    readonly memo: string | null;
    readonly paymentTerms: string | null;
    readonly purchaseOrderNumber: string | null;
  }

  export interface Creator {
    readonly businessGuid: string;
    readonly amount: Money | null;
    readonly invoiceDate: string | null; // LocalDate
    readonly invoiceDueDate: string | null; // LocalDate
    readonly invoiceNumber: string | null;
    readonly payeeGuid: string | null;
    readonly memo: string | null;
    readonly paymentTerms: string | null;
    readonly purchaseOrderNumber: string | null;
  }

  export interface Updater {
    readonly amount?: Money | null;
    readonly invoiceDate?: string | null; // LocalDate
    readonly invoiceDueDate?: string | null; // LocalDate
    readonly invoiceNumber?: string | null;
    readonly payeeGuid?: string | null;
    readonly memo?: string | null;
    readonly paymentTerms?: string | null;
    readonly purchaseOrderNumber?: string | null;
  }
}

export default BillRep;
