import PayeeLegalAddress from "pages/SendMoneyPage/SendMoneySteps/PaymentInfo/PayeeLegalAddress";
import { useContext, useEffect, useMemo } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { adressState } from "state/payments/international/usAdress";
import InfoIcon from "ui/icons/InfoIcon";
import CollapsibleFieldset from "ui/inputs/CollapsibleFieldset";
import Helper from "ui/inputs/Helper";
import TextInput, { Filter } from "ui/inputs/TextInput";
import { Heading4 } from "ui/typography";

import { CreatePayeeFormContext } from "./CreatePayeeForm";
import styles from "./CreatePayeePage.module.scss";

const WireInfoSection = () => {
  const form = useContext(CreatePayeeFormContext);
  const [address, setAddress] = useRecoilState(adressState);
  const wire = form.paymentInfo.wire;
  const resetAddress = useResetRecoilState(adressState);
  const hasError = useMemo(() => wire.hasError[0], [wire.hasError]);

  useEffect(() => {
    resetAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const heading = (
    <>
      <Heading4>Wire</Heading4>
      {hasError && <InfoIcon size={20} variant="warning" />}
    </>
  );

  return (
    <CollapsibleFieldset heading={heading}>
      {hasError && (
        <Helper iconVariant="warning" className={styles.span2}>
          If you’d like to send Wire payments to this recipient, please complete all Wire fields.
          Otherwise, you can leave them blank.
        </Helper>
      )}
      <TextInput
        label="Routing number"
        className={styles.span2}
        maxLength={9}
        inputFilter={Filter.DIGITS}
        {...wire.routingNumber}
      />
      <TextInput
        label="Account number"
        className={styles.span2}
        maxLength={17}
        inputFilter={Filter.DIGITS}
        {...wire.accountNumber}
      />
      <div className={styles.span2}>
        <PayeeLegalAddress
          address={address}
          setAddress={setAddress}
          setHasLegalAddressError={() => {}}
        />
      </div>
    </CollapsibleFieldset>
  );
};

export default WireInfoSection;
