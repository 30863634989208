import ApSettingsRep from "reps/ApSettingsRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import apSettingsQueryHooks from "../queries/apSettingsQueryHooks";

const useUpdateApSettingsMutation = (
  apSettingsId: string,
  additionalOptions: MutationAdditionalOptions<ApSettingsRep.Complete, ApSettingsRep.Updater>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshApSettingsQuery = apSettingsQueryHooks.useRefreshQuery({});

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => highbeamApi.apSettings.update(apSettingsId, variables),
      onSuccess: async () => {
        await refreshApSettingsQuery();
      },
    },
    additionalOptions
  );
};

export default useUpdateApSettingsMutation;
