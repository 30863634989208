import { ComponentProps, FC, ReactNode } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import capitalAccountSummaryQueryHooks from "resources/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import chargeCardAccountByUnitCoCreditAccountIdQueryHooks from "resources/charge-cards/queries/chargeCardAccountByUnitCoCreditAccountIdQueryHooks";
import AccountBar from "ui/data-display/AccountBar";

import CapitalAccountIcon from "../CapitalAccountIcon";

type Props = Omit<ComponentProps<typeof AccountBar>, "icon" | "accountName"> & {
  capitalAccount: CapitalAccountRep.Complete | CapitalAccountSummaryRep.Complete;
};

const CapitalAccountBar: FC<Props> = ({ capitalAccount, ...props }) => {
  return (
    <AccountBar
      icon={<CapitalAccountIcon capitalAccountType={capitalAccount.type} />}
      accountName={capitalAccount.name}
      disableTextMarginBottom
      {...props}
    />
  );
};

export default CapitalAccountBar;

// Wrappers

type CapitalAccountBarByCapitalAccountGuidProps = Omit<Props, "capitalAccount"> & {
  capitalAccountGuid: string;
  notFoundFallback?: ReactNode;
};

export const CapitalAccountBarByCapitalAccountGuid: FC<
  CapitalAccountBarByCapitalAccountGuidProps
> = ({ capitalAccountGuid, notFoundFallback = null, ...props }) => {
  const { data: capitalAccount, isLoading } = capitalAccountSummaryQueryHooks.useQuery({
    capitalAccountGuid,
  });

  if (isLoading) {
    return <AccountBar.Loading {...props} />;
  }

  if (!capitalAccount) {
    return <>{notFoundFallback}</>;
  }

  return <CapitalAccountBar capitalAccount={capitalAccount} {...props} />;
};

type CapitalAccountBarByUnitCoCreditAccountIdProps = Omit<Props, "capitalAccount"> & {
  unitCoCreditAccountId: string;
  notFoundFallback?: ReactNode;
};

export const CapitalAccountBarByUnitCoCreditAccountId: FC<
  CapitalAccountBarByUnitCoCreditAccountIdProps
> = ({ unitCoCreditAccountId, notFoundFallback = null, ...props }) => {
  const { data: chargeCardAccount, isLoading: isChargeCardAccountLoading } =
    chargeCardAccountByUnitCoCreditAccountIdQueryHooks.useQuery({
      unitCoCreditAccountId: unitCoCreditAccountId,
    });

  const { data: capitalAccount, isLoading: isCapitalAccountLoading } =
    capitalAccountSummaryQueryHooks.useQuery({
      capitalAccountGuid: chargeCardAccount?.capitalAccountGuid ?? null,
      enabled: !isChargeCardAccountLoading,
    });

  const isLoading = isChargeCardAccountLoading || isCapitalAccountLoading;

  if (isLoading) {
    return <AccountBar.Loading {...props} />;
  }

  if (!capitalAccount) {
    return <>{notFoundFallback}</>;
  }

  return <CapitalAccountBar capitalAccount={capitalAccount} {...props} />;
};
