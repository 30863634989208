import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

const apSettingsQueryHooks = makeQueryHooks({
  name: "ap-settings",
  useQueryVariables() {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  useQueryFnMaker({ businessGuid }) {
    const highbeamApi = useHighbeamApi();
    return () => highbeamApi.apSettings.get(businessGuid);
  },
});

export default apSettingsQueryHooks;

//
// Hooks
//

export const useApSettings = () => apSettingsQueryHooks.useData({});
