import BankAccountRep from "reps/BankAccountRep";
import getAccountDisplayName from "ui/data-display/AccountBar/getAccountDisplayName";

type Options = {
  showStatus?: boolean;
  showMaskedAccountNumber?: boolean;
};

const getBankAccountDisplayName = (bankAccount: BankAccountRep.Complete, options: Options = {}) => {
  const { showStatus = true, showMaskedAccountNumber = true } = options;

  return getAccountDisplayName({
    accountName: bankAccount.name,
    accountNumber:
      // NB(alex): High yield account numbers are only hidden on the frontend. A proper fix would require routing the endpoint through the backend.
      showMaskedAccountNumber && bankAccount.highbeamType.name !== "HighYield"
        ? bankAccount.accountNumber
        : undefined,
    status:
      showStatus && bankAccount.status !== BankAccountRep.Status.OPEN
        ? bankAccount.status
        : undefined,
  });
};

export default getBankAccountDisplayName;
