import React, { useEffect, useRef } from "react";
import cn from "utils/tailwind/cn";

import { State } from "..";

import ChatConversationShimmer from "./ChatConversationShimmer";
import ChatRequest from "./ChatRequest";
import ChatResponse from "./ChatResponse";

type Props = {
  className?: string;
  state: State;
};

const ChatConversationSection: React.FC<Props> = ({ className, state }) => {
  const conversationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const conversation = conversationRef.current!;
    conversation.scrollTop = conversation.scrollHeight;
  }, [state]);

  return (
    <div ref={conversationRef} className={cn("flex flex-col items-center", className)}>
      <div className="flex w-full max-w-2xl flex-col px-8 py-3">
        {state.map((exchange, i) => {
          return (
            <div
              key={i}
              className="flex flex-col gap-6 border-t border-grey-200 pb-10 pt-8 first-of-type:border-t-0"
            >
              <ChatRequest request={exchange.request} />
              {exchange.response ? (
                <ChatResponse response={exchange.response} />
              ) : (
                <ChatConversationShimmer />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChatConversationSection;
