import { Info } from "@phosphor-icons/react";
import dayjs from "dayjs";
import DailyPlReportRep from "reps/DailyPlReportRep";
import { Money } from "reps/Money";
import { useDailyPlReportQuery } from "resources/daily-pl-report/useDailyPlReport";
import Banner from "ui/data-display/Banner";
import MoneyCell from "ui/table/MoneyCell";
import Table, { Column } from "ui/table/Table";
import Text from "ui/typography/Text";

const DailyPlTable = () => {
  const { data: report, isPending } = useDailyPlReportQuery();

  const columns = deriveColumns(report);
  const data = report ? transformData(report) : undefined;

  return (
    <>
      <h2 style={{ fontSize: "18px" }}>Daily P&L</h2>
      {report?.columns.length === 0 && (
        <Banner
          icon={<Info size={24} />}
          color="grey"
          title={
            <>
              No Shopify orders found; if you recently signed up please try again in a few minutes.
            </>
          }
        />
      )}
      <Table isLoading={isPending} rowKey={(row) => row.title} data={data} columns={columns} />
    </>
  );
};

export default DailyPlTable;

type Datum = {
  title: string;
  values: Map<string, Money>;
};

const zeroMoney: Money = {
  amount: "0",
  currency: "USD",
};

const deriveColumns = (report: DailyPlReportRep | undefined): Column<Datum>[] => {
  if (!report) {
    return Array.from({ length: 8 }, (_, i) => ({ title: i.toString(), cellRender: null }));
  }

  // eslint-disable-next-line functional/no-let
  let results: Column<Datum>[] = [
    { title: "", cellRender: (datum) => <Text size={14}>{datum.title}</Text> },
  ];
  for (
    // eslint-disable-next-line functional/no-let
    let date = dayjs(report.dateRange.start);
    !date.isAfter(dayjs(report.dateRange.endInclusive));
    date = date.add(1, "d")
  ) {
    results.push({
      title: date.format("YYYY-MM-DD"),
      cellRender: (datum) => {
        const money = datum.values.get(date.format("YYYY-MM-DD")) || zeroMoney;
        return <MoneyCell amount={money.amount} currencyCode={money.currency} />;
      },
    });
  }
  return results;
};

const transformData = (report: DailyPlReportRep): Datum[] => {
  return [
    {
      title: "Gross sales",
      values: new Map(report.columns.map((column) => [column.date, column.grossSales])),
    },
    {
      title: "Discounts",
      values: new Map(report.columns.map((column) => [column.date, column.discounts])),
    },
    {
      title: "Net sales",
      values: new Map(report.columns.map((column) => [column.date, column.netSales])),
    },
    {
      title: "Shipping",
      values: new Map(report.columns.map((column) => [column.date, column.shipping])),
    },
    {
      title: "Duties",
      values: new Map(report.columns.map((column) => [column.date, column.duties])),
    },
    {
      title: "Taxes",
      values: new Map(report.columns.map((column) => [column.date, column.taxes])),
    },
    {
      title: "Tips",
      values: new Map(report.columns.map((column) => [column.date, column.tips])),
    },
  ];
};
