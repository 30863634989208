import { INTERNATIONAL_WIRE_INPUTS } from "pages/SendMoneyPage/internationalWires";
import { selector } from "recoil";

import { isLocalPaymentState } from "./isLocalPayment";
import { selectedBankCountryInternationalWireOptionState } from "./selectedBankCountry";

const requiredFieldsState = selector<Set<INTERNATIONAL_WIRE_INPUTS> | null>({
  key: "payments/international/requiredFields",
  get: ({ get }) => {
    const selectedCountryOption = get(selectedBankCountryInternationalWireOptionState);
    if (!selectedCountryOption) return null;

    const isLocalPayment = get(isLocalPaymentState);

    return isLocalPayment
      ? selectedCountryOption?.local.requiredFields
      : selectedCountryOption?.usd.requiredFields;
  },
});

export default requiredFieldsState;
