import BusinessMemberSummaryRep from "reps/BusinessMemberSummaryRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import { ROOT_BUSINESSES_QUERY_KEY } from "./businessesQueryHooks";

type Params = {
  userGuid: string;
};

const businessSummariesByMemberUserQueryHooks = makeQueryHooks({
  rootName: ROOT_BUSINESSES_QUERY_KEY,
  name: "businessSummariesByMemberUser",
  useQueryVariables: ({ userGuid }: Params) => {
    return {
      userGuid: userGuid,
    };
  },
  useQueryFnMaker: ({ userGuid }) => {
    const highbeamApi = useHighbeamApi();
    return () => {
      return highbeamApi.businessMemberSummary.listByUser(userGuid);
    };
  },
});

export default businessSummariesByMemberUserQueryHooks;

// Utils

export const filterActiveAndPendingBusinessSummaries = (
  businessSummaries: BusinessMemberSummaryRep.Complete[]
) => {
  return businessSummaries.filter(
    ({ businessStatus }) => businessStatus === "Active" || businessStatus === "PendingReview"
  );
};
