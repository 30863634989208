import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";

const useCanUseCommandPalette = () => {
  // NB(alex): We want to enable command palette for all users soon, but for now you can only use it if you can superuser.
  const canSuperuser = useCanSuperuser();

  return canSuperuser;
};

export default useCanUseCommandPalette;
