import classNames from "classnames";
import React, { ReactNode } from "react";
import Text from "ui/typography/Text";
import { TextWeight } from "ui/typography/Text/TextTypes";
import { v4 as uuidv4 } from "uuid";

import styles from "./CheckboxLabel.module.scss";

type Props = {
  checked: boolean;
  label: ReactNode;
  labelClassName?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  textWeight?: TextWeight;
  disabled?: boolean;
};

const CheckboxLabel = ({
  checked,
  label,
  labelClassName,
  textWeight = "bold",
  disabled,
  id = uuidv4(),
  ...props
}: Props) => (
  <div className={styles.container}>
    <input
      type="checkbox"
      className={styles.checkbox}
      disabled={disabled}
      checked={checked}
      id={id}
      {...props}
    />
    <label className={classNames(styles.label, labelClassName)} htmlFor={id}>
      <Text size={14} weight={textWeight}>
        {label}
      </Text>
    </label>
  </div>
);

export default CheckboxLabel;
