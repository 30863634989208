import classNames from "classnames";

import styles from "./ProgressBar.module.scss";

type percent = {
  percent: number;
  color: string;
};
type Props = {
  percent: number | percent[];
  color?: string;
  className?: string;
};

const ProgressBar: React.FC<Props> = ({ percent, color, className }) => {
  if (Array.isArray(percent)) {
    return (
      <div className={classNames(styles.container, className)}>
        {percent.map((percentValue) => (
          <div
            key={percentValue.color}
            className={styles.bar}
            style={{
              width: `${percentValue.percent}%`,
              backgroundColor: `${percentValue.color}`,
            }}
          ></div>
        ))}
      </div>
    );
  }

  return (
    <div className={classNames(styles.container, className)}>
      <div
        className={classNames(styles.bar)}
        style={{ width: `${percent}%`, backgroundColor: `${color}` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
