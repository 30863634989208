import { CalendarBlank as CalendarBlankIcon } from "@phosphor-icons/react";
import { FC, useState } from "react";
import Card from "ui/data-display/Card";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import { Paragraph, Heading3 } from "ui/typography";
import { formatDate } from "utils/date";
import useHasPermission from "utils/permissions/useHasPermission";

import { useApSettings } from "../../queries/apSettingsQueryHooks";
import UpdateBooksClosedDateModal from "../UpdateBooksClosedDateModal";

const BooksClosedDateSection: FC = () => {
  const { closeBooksDate } = useApSettings();
  const [isUpdateBooksClosedDateModalOpen, setIsUpdateBooksClosedDateModalOpen] = useState(false);
  const canUpdateBooksClosedDate = useHasPermission("accountsPayableSettings:write");

  return (
    <>
      <Card
        className="flex items-center justify-between gap-5 border border-grey-200 px-6 py-4"
        shadow="xs"
      >
        <div className="flex flex-col gap-2">
          <Heading3 className="text-sm font-regular text-grey-600">Books closed date</Heading3>

          <Paragraph className="flex items-center gap-2 text-sm font-medium">
            <CalendarBlankIcon className="size-4 text-grey-600" />
            <span>{closeBooksDate ? formatDate(closeBooksDate) : <>No date selected</>}</span>
          </Paragraph>

          <Paragraph className="text-sm text-grey-600">
            You cannot edit any bookkeeping related details on bills with invoice dates on or before
            the books closed date. You can still make payments on the bills.
          </Paragraph>
        </div>
        <div>
          <ButtonWithTooltip
            disabled={!canUpdateBooksClosedDate}
            tooltip={
              !canUpdateBooksClosedDate &&
              "You do not have permission to edit the books closed date."
            }
            onClick={() => setIsUpdateBooksClosedDateModalOpen(true)}
          >
            {closeBooksDate ? <>Edit</> : <>Select date</>}
          </ButtonWithTooltip>
        </div>
      </Card>

      {isUpdateBooksClosedDateModalOpen && (
        <UpdateBooksClosedDateModal
          onClose={() => setIsUpdateBooksClosedDateModalOpen(false)}
          onSuccess={() => setIsUpdateBooksClosedDateModalOpen(false)}
        />
      )}
    </>
  );
};

const AccountingSyncSettings: FC = () => <BooksClosedDateSection />;

export default AccountingSyncSettings;
