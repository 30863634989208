import { useState } from "react";
import { useRecoilValue } from "recoil";
import BankAccountRep from "reps/BankAccountRep";
import TextInput from "ui/inputs/TextInput";
import ModalV3 from "ui/overlay/ModalV3";

import useEditAccountNameMutation from "./hooks/useEditAccountNameMutation";
import editBankAccountNameModalState, {
  useEditAccountNameModal,
} from "./state/editAccountNameModalState";

const EDIT_ACCOUNT_FORM = "edit-account-name-form";

type Props = {
  account: BankAccountRep.Complete;
  onClose: () => void;
};

const Form: React.FC<Props> = ({ account, onClose }) => {
  const [accountName, setAccountName] = useState(account.name);
  const { mutate: editAccountName, isPending } = useEditAccountNameMutation();
  const { close: closeEditAccountNameModal } = useEditAccountNameModal();

  return (
    <form
      id={EDIT_ACCOUNT_FORM}
      onSubmit={(e) => {
        e.preventDefault();
        editAccountName({
          accountGuid: account.guid,
          accountName: accountName,
        });
        closeEditAccountNameModal();
      }}
    >
      <ModalV3.Header onClose={onClose}>Edit account</ModalV3.Header>

      <ModalV3.Body>
        <TextInput label="Account name" value={accountName} onChange={setAccountName} />
      </ModalV3.Body>

      <ModalV3.Footer>
        <ModalV3.Footer.SubmitButton
          variant="primary"
          autoFocus
          form={EDIT_ACCOUNT_FORM}
          isLoading={isPending}
        >
          Save changes
        </ModalV3.Footer.SubmitButton>

        <ModalV3.Footer.CloseButton onClick={onClose} />
      </ModalV3.Footer>
    </form>
  );
};

const EditBankAccountModal = () => {
  const state = useRecoilValue(editBankAccountNameModalState);
  const { isOpen, close: onClose } = useEditAccountNameModal();

  return (
    <ModalV3 isOpen={isOpen} onClose={onClose}>
      {state.isOpen && <Form account={state.bankAccount} onClose={onClose} />}
    </ModalV3>
  );
};

export default EditBankAccountModal;
