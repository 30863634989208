import { FC, useId, useState } from "react";
import { Document, Page } from "react-pdf";
import { File } from "react-pdf/dist/cjs/shared/types";

import PdfDisplayLoading from "./PdfDisplayLoading";

type Props = {
  file?: File;
  gap?: 32 | 24 | 16 | number;
  // NB(alex): The default behavior is to use the PDF file's width. I recommend setting this prop, but don't want to make it required.
  width?: number;
};

const PdfDisplay: FC<Props> = ({ file, gap, width }) => {
  const [numPages, setNumPages] = useState<number>();
  const keyPrefix = useId();

  return (
    <Document
      file={file}
      loading={<PdfDisplayLoading />}
      onLoadSuccess={({ numPages }) => {
        setNumPages(numPages);
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: gap }}>
        {numPages &&
          Array.from({ length: numPages }).map((_, index) => (
            <Page key={`${keyPrefix}-${index}`} pageIndex={index} width={width} />
          ))}
      </div>
    </Document>
  );
};

export default Object.assign(PdfDisplay, {
  Loading: PdfDisplayLoading,
});
