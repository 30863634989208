import ActionItemRep from "reps/ActionItemRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ActionItemApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async set(creation: ActionItemRep.Creation): Promise<ActionItemRep.Complete> {
    const url = `/action-items`;
    return (await this.api.put<ActionItemRep.Complete>(url, creation))!;
  }

  async getByMilestoneName(
    ownerGuid: string,
    milestoneName: string
  ): Promise<ActionItemRep.Complete[]> {
    const queryParams = new URLSearchParams({ ownerGuid, milestoneName });
    const url = `/action-items?${queryParams.toString()}`;
    return (await this.api.get<ActionItemRep.Complete[]>(url))!;
  }

  async update(
    ownerGuid: string,
    actionItemGuid: string,
    update: ActionItemRep.Update
  ): Promise<ActionItemRep.Complete | null> {
    const queryParams = new URLSearchParams({ ownerGuid });
    const url = `/action-items/${actionItemGuid}?${queryParams.toString()}`;
    return await this.api.patch<ActionItemRep.Complete>(url, update);
  }
}
