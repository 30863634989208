import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

const BASE_CHARGE_CARD_REPAYMENT_INFO_QUERY_KEY = "charge-card-repayment-info";

const useChargeCardRepaymentInfoQueryOptions = (chargeCardAccountGuid?: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [BASE_CHARGE_CARD_REPAYMENT_INFO_QUERY_KEY, { businessGuid, chargeCardAccountGuid }],
    queryFn: () => {
      if (!chargeCardAccountGuid) {
        return null;
      }

      return highbeamApi.chargeCardRepayment.getInfo(businessGuid, chargeCardAccountGuid);
    },
  });
};

export const useChargeCardRepaymentInfoQuery = (chargeCardAccountGuid?: string) => {
  return useQuery(useChargeCardRepaymentInfoQueryOptions(chargeCardAccountGuid));
};

const useChargeCardRepaymentInfo = <TRequired extends boolean>(
  chargeCardAccountGuid: string,
  params?: RequiredParam<TRequired>
) => {
  const { data } = useSuspenseQuery(useChargeCardRepaymentInfoQueryOptions(chargeCardAccountGuid));

  if (params?.required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useChargeCardRepaymentInfo;
