import { FC } from "react";
import AccentCard from "ui/data-display/AccentCard";
import { Heading3, Paragraph } from "ui/typography";

type Props = {
  image: string;
  primaryText: string;
  secondaryText?: string;
};

const EmptyState: FC<Props> = ({ image, primaryText, secondaryText }) => {
  return (
    <AccentCard className="p-10 text-center">
      <img src={image} alt={primaryText} height={96} className="mb-8" />

      <Heading3 className="font-medium">{primaryText}</Heading3>

      {secondaryText && (
        <Paragraph className="whitespace-pre-line text-xs">{secondaryText}</Paragraph>
      )}
    </AccentCard>
  );
};

export default EmptyState;
