import { FC } from "react";
import Shimmer from "ui/feedback/ShimmerV2";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import cn from "utils/tailwind/cn";

const LOADING_COLUMNS: Column<string>[] = [
  {
    title: "",
    key: "1",
    headerRender: () => <Shimmer className="h-2.5 max-w-36" />,
    cellRender: null,
  },
  {
    title: "",
    key: "2",
    cellRender: null,
  },
  {
    title: "",
    key: "3",
    cellRender: null,
  },
  {
    title: "",
    key: "4",
    headerRender: () => <Shimmer className="ml-auto h-2.5 max-w-16" />,
    align: TableColumnAlignment.RIGHT,
    cellRender: null,
  },
];

type SectionLoadingProps = {
  className?: string;
};

const SectionLoading: FC<SectionLoadingProps> = ({ className }) => {
  return (
    <div className={cn("flex flex-col", className)}>
      <Table isLoading columns={LOADING_COLUMNS} rowKey={"unused"} data={undefined} />
    </div>
  );
};

export default SectionLoading;
