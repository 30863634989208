import classNames from "classnames";
import { FC, HTMLAttributes } from "react";

import Helper from "../Helper";

import styles from "./Fieldset.module.scss";

type Width = "narrow" | "wide";
type NumberOfColumns = 2 | 3;

type FieldsetProps = HTMLAttributes<HTMLDivElement> & {
  width: Width;
  columns?: NumberOfColumns;
  errorMessage?: string;
};

const Fieldset: FC<FieldsetProps> = ({
  className,
  width,
  children,
  columns = 2,
  errorMessage,
  ...props
}) => {
  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.fieldset,
          widthClassName(width),
          columnsClassName(columns),
          className
        )}
        {...props}
      >
        {children}
      </div>

      {errorMessage && <Helper iconVariant="error">{errorMessage}</Helper>}
    </div>
  );
};

export default Fieldset;

const widthClassName = (width: Width): string => {
  switch (width) {
    case "narrow":
      return styles.narrow;
    case "wide":
      return styles.wide;
  }
};

const columnsClassName = (columns: NumberOfColumns): string => {
  switch (columns) {
    case 2:
      return styles.twoColumns;
    case 3:
      return styles.threeColumns;
  }
};
