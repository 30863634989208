import { FC, ReactElement } from "react";
import IconTile from "ui/icons/IconTile";
import { Span } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

type ProductOverviewListItemProps = PropsWithChildrenAndClassName & {
  icon: ReactElement;
};

const ProductOverviewListItem: FC<ProductOverviewListItemProps> = ({
  children,
  className,
  icon,
}) => (
  <li
    className={cn(
      "relative my-3 flex items-start before:absolute before:bottom-[-21px] before:left-[29px] before:top-[63px] before:w-0.5 before:bg-purple-100 before:content-[''] first-of-type:mt-0 last-of-type:mb-0 last-of-type:before:hidden",
      className
    )}
  >
    <IconTile icon={icon} roundedness="circle" className="mr-4 size-[3.75rem] shrink-0" />
    <Span className="pt-4 text-md">{children}</Span>
  </li>
);

const ProductOverviewList: FC<PropsWithChildrenAndClassName> = ({ children, className }) => (
  <ul className={cn("flex list-none flex-col items-start", className)}>{children}</ul>
);

export default Object.assign(ProductOverviewList, {
  Item: ProductOverviewListItem,
});
