import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { CardAvatarBarByCardId } from "resources/cards/components/CardAvatarBar";
import { useCardQueryOptions } from "resources/cards/queries/useCard";
import colors from "styles/colors";
import Section from "ui/data-display/Section";
import Text from "ui/typography/Text";
import { getCardIdFromHighbeamTransaction } from "utils/transactions";
import { HighbeamTransaction, isBookTransaction } from "utils/types/transactionsTypes";

import BankAccountDetail from "./BankAccountDetail";
import CounterpartyDetail from "./CounterpartyDetail";
import TransactionDetailBubble from "./TransactionDetailBubble";
import styles from "./TransactionInfoFlexpaneToFromSection.module.scss";

type Props = {
  transaction: HighbeamTransaction;
};

const TransactionInfoFlexpaneToFromSection: React.FC<Props> = ({ transaction }) => {
  const positive = transaction.direction === "Credit";
  const cardId = getCardIdFromHighbeamTransaction(transaction);

  const { data: card } = useQuery({
    ...useCardQueryOptions({ cardId: cardId! }), // TODO(alex) HB-5669: Need to figure out how to make `useCardQuery` type-safe when `enabled: false`.
    enabled: Boolean(cardId),
  });

  const bankAccountDetail = (
    <BankAccountDetail accountName={transaction.accountName} transaction={transaction} />
  );
  const counterpartyDetail = isBookTransaction(transaction) ? (
    <BankAccountDetail accountName={transaction.counterpartyName} transaction={transaction} />
  ) : (
    <CounterpartyDetail transaction={transaction} />
  );

  return (
    <Section>
      <div className={styles.container}>
        <div className={styles.column}>
          <Text size={14} color={colors.grey[900]}>
            From
          </Text>
          {card ? (
            <CardAvatarBarByCardId cardId={card.id} showTypeLabel={false} showIcon={false} />
          ) : (
            <>{positive ? counterpartyDetail : bankAccountDetail}</>
          )}
        </div>
        <div className={classNames(styles.column, styles.center)}>
          <TransactionDetailBubble transaction={transaction} />
        </div>
        <div className={styles.column}>
          <Text size={14} color={colors.grey[900]}>
            To
          </Text>
          {positive ? bankAccountDetail : counterpartyDetail}
        </div>
      </div>
    </Section>
  );
};

export default TransactionInfoFlexpaneToFromSection;
