import { ROOT_BUSINESSES_QUERY_KEY } from "resources/businesses/queries/businessesQueryHooks";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useBusinessDetailsQueryOptions = () => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [ROOT_BUSINESSES_QUERY_KEY, "businessDetails", { businessGuid }],
    queryFn: () => highbeamApi.businessDetails.get(businessGuid),
  });
};

export default useBusinessDetailsQueryOptions;
