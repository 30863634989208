import { useController } from "react-hook-form";
import CapitalAccountBar from "resources/capital-accounts/components/CapitalAccountBar";
import Dropdown from "ui/inputs/Dropdown";

import { useDrawdownAmountFormContext } from "../context/DrawdownProvider";

const DrawdownFromCapitalAccountDropdown = () => {
  const { control } = useDrawdownAmountFormContext();

  const { field } = useController({
    control: control,
    name: "drawdownFromCapitalAccount",
  });

  return (
    <>
      <Dropdown
        disabled
        id="from"
        label="From"
        isSearchable={false}
        options={[]} // Render all capital account options once we fully implement multiple capital accounts
        value={{
          label: <CapitalAccountBar capitalAccount={field.value} className="mt-1" />,
          value: field.value.name,
        }}
      />
    </>
  );
};

export default DrawdownFromCapitalAccountDropdown;
