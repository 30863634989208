export const isNotNull = <T extends unknown>(item: T | null): item is T => item !== null;

export type Falsy = false | null | undefined;

export const isNotFalsy = <T extends unknown>(item: T | Falsy): item is T => {
  return Boolean(item);
};

/**
 * Immutable helper for removing an item from an array by index.
 */
export const removeItemAtIndex = <T extends unknown>(array: T[], index: number): T[] => {
  if (index < 0 || index >= array.length) {
    return array;
  }
  return [...array.slice(0, index), ...array.slice(index + 1)];
};
