import {
  ListDashes as ListDashesIcon,
  ChatDots as ChatDotsIcon,
  CheckCircle as CheckCircleIcon,
  CheckSquareOffset as CheckSquareOffsetIcon,
} from "@phosphor-icons/react";
import { FC } from "react";
import AssignedBillSummaryRep from "reps/AssignedBillSummaryRep";
import useGrantBillApprovalMutation from "resources/bill-approvals/mutations/useGrantBillApprovalMutation";
import BillLineItemsMobile from "resources/bill-line-items/components/BillLineItemsMobile";
import { useAssignedBillRequired } from "resources/bills/queries/assignedBillQueryHooks";
import businessMembersQueryHooks from "resources/business-members/queries/businessMembersQueryHooks";
import getBusinessMemberByBusinessMemberGuid from "resources/business-members/utils/getBusinessMemberByBusinessMemberGuid";
import DetailsSidebarBody from "ui/data-display/DetailsSidebarBody";
import DetailsSidebarPanel from "ui/data-display/DetailsSidebarPanel";
import MoneyAmount from "ui/data-display/money/MoneyAmountV2";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import TabsV2 from "ui/navigation/TabsV2";
import NotFoundCell from "ui/table/NotFoundCell";
import { Paragraph, Span, Strong, Heading3 } from "ui/typography";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { formatDate } from "utils/date";
import { useIsMobile } from "utils/device/useMediaQuery";
import { TabConfig, useSearchParamTabState } from "utils/tabs/useTabState";
import cn from "utils/tailwind/cn";

import { useBillCommentsCountQuery } from "../../queries/useBillComments";
import BillComments from "../BillComments";

type AssignedBillSummaryDetailsProps = {
  assignedBill: AssignedBillSummaryRep.Complete;
};

const DETAILS_TR_CLASSES = cn(
  "group border-b border-grey-100 py-4 first-of-type:border-t-0 last-of-type:border-b-0"
);

const DETAILS_CELL_BASE_CLASSES = cn(
  "py-4 text-sm group-first-of-type:pt-0 group-last-of-type:pb-0"
);

const DETAILS_TH_CLASSES = cn(DETAILS_CELL_BASE_CLASSES, "w-1/3 font-regular text-grey-600");

const DETAILS_TD_CLASSES = cn(
  DETAILS_CELL_BASE_CLASSES,
  "text-right text-sm font-medium text-grey-900"
);

const AssignedBillSummaryDetails: FC<AssignedBillSummaryDetailsProps> = ({ assignedBill }) => {
  const {
    payee,
    amount,
    invoiceDate,
    invoiceDueDate,
    invoiceNumber,
    paymentTerms,
    purchaseOrderNumber,
    memo,
  } = assignedBill;

  return (
    <table className="w-full">
      <tbody>
        <tr className={DETAILS_TR_CLASSES}>
          <th className={DETAILS_TH_CLASSES}>Payee</th>
          <td className={DETAILS_TD_CLASSES}>{payee ? payee.name : <NotFoundCell />}</td>
        </tr>
        <tr className={DETAILS_TR_CLASSES}>
          <th className={DETAILS_TH_CLASSES}>Amount</th>
          <td className={DETAILS_TD_CLASSES}>
            {amount ? (
              <MoneyAmount
                amount={amount.amount}
                currencyCode={amount.currency}
                showCurrencySymbol
                showTrailingCurrencyCode
                currencyCodeTextWeight="medium"
              />
            ) : (
              <NotFoundCell />
            )}
          </td>
        </tr>
        <tr className={DETAILS_TR_CLASSES}>
          <th className={DETAILS_TH_CLASSES}>Invoice date</th>
          <td className={DETAILS_TD_CLASSES}>
            {invoiceDate ? formatDate(invoiceDate) : <NotFoundCell />}
          </td>
        </tr>
        <tr className={DETAILS_TR_CLASSES}>
          <th className={DETAILS_TH_CLASSES}>Due date</th>
          <td className={DETAILS_TD_CLASSES}>
            {invoiceDueDate ? formatDate(invoiceDueDate) : <NotFoundCell />}
          </td>
        </tr>
        <tr className={DETAILS_TR_CLASSES}>
          <th className={DETAILS_TH_CLASSES}>Invoice number</th>
          <td className={DETAILS_TD_CLASSES}>{invoiceNumber ?? <NotFoundCell />}</td>
        </tr>
        <tr className={DETAILS_TR_CLASSES}>
          <th className={DETAILS_TH_CLASSES}>Payment terms</th>
          <td className={DETAILS_TD_CLASSES}>{paymentTerms ?? <NotFoundCell />}</td>
        </tr>
        <tr className={DETAILS_TR_CLASSES}>
          <th className={DETAILS_TH_CLASSES}>
            <abbr title="Purchase order">PO</abbr> number
          </th>
          <td className={DETAILS_TD_CLASSES}>{purchaseOrderNumber ?? <NotFoundCell />}</td>
        </tr>
        <tr className={DETAILS_TR_CLASSES}>
          <th className={DETAILS_TH_CLASSES}>Memo</th>
          <td className={DETAILS_TD_CLASSES}>{memo ?? <NotFoundCell />}</td>
        </tr>
      </tbody>
    </table>
  );
};

type Props = {
  billId: string;
};

const BillDetailsInfoEmployeeView: FC<Props> = ({ billId }) => {
  const { data: commentsCount } = useBillCommentsCountQuery(billId);

  const tabs: TabConfig = {
    details: {
      icon: <ListDashesIcon />,
      label: "Details",
    },
    comments: {
      icon: <ChatDotsIcon />,
      label: "Comments",
      count: commentsCount || undefined,
    },
  };

  const [activeTab, setActiveTab] = useSearchParamTabState("detail-tab", tabs);

  const assignedBill = useAssignedBillRequired(billId);
  const { billApproval } = assignedBill;
  const billApprovalId = billApproval.id;
  const isBillApprovalGranted = billApproval.status === "Granted";

  const { requestedByBusinessMemberGuid, requestedAt } = billApproval;
  const businessMembers = businessMembersQueryHooks.useData({});
  const requestedBusinessMember = getBusinessMemberByBusinessMemberGuid(
    businessMembers,
    requestedByBusinessMemberGuid
  );

  const { mutate: grantBillApproval, isPending: isGrantingBillApproval } =
    useGrantBillApprovalMutation(billId, {
      onSuccess: () => {
        notify("success", "Bill approved");
      },
    });

  const billPayLineItemsEnabled = useFeatureFlag("BILL_PAY_LINE_ITEMS_UI");
  const isMobile = useIsMobile();

  return (
    <DetailsSidebarPanel>
      <DetailsSidebarPanel.Header>
        <TabsV2
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
          justify="space-around"
          className="px-8"
        />
      </DetailsSidebarPanel.Header>

      <DetailsSidebarPanel.Body>
        {activeTab === "details" && (
          <DetailsSidebarBody
            main={
              <DetailsSidebarBody.Main>
                <DetailsSidebarBody.Section className="border-b border-grey-100 py-10">
                  <Paragraph className="flex items-center gap-2 text-md text-grey-800">
                    <CheckSquareOffsetIcon size={20} />
                    <Span>
                      Approval requested by{" "}
                      <Strong>
                        {requestedBusinessMember?.displayName ?? "Deactivated member"}
                      </Strong>{" "}
                      on <Strong>{formatDate(requestedAt!)}</Strong>
                    </Span>
                  </Paragraph>
                </DetailsSidebarBody.Section>
                <DetailsSidebarBody.Section className="border-b border-grey-100 pb-10">
                  <Heading3 className="mb-4">Bill info</Heading3>
                  <AssignedBillSummaryDetails assignedBill={assignedBill} />
                </DetailsSidebarBody.Section>
              </DetailsSidebarBody.Main>
            }
            footer={
              <DetailsSidebarBody.Footer>
                {billPayLineItemsEnabled && isMobile && <BillLineItemsMobile billId={billId} />}

                <div className="flex flex-col gap-4">
                  <ButtonWithTooltip
                    variant="primary"
                    className="w-full"
                    isLoading={isGrantingBillApproval}
                    disabled={isBillApprovalGranted}
                    tooltip={
                      isBillApprovalGranted ? "You've already approved this bill." : undefined
                    }
                    onClick={() => grantBillApproval({ billApprovalId })}
                  >
                    {!isGrantingBillApproval && <CheckCircleIcon size={20} />}
                    Approve bill
                  </ButtonWithTooltip>
                  <Button
                    variant="tertiary"
                    className="w-full"
                    onClick={() => setActiveTab("comments")}
                  >
                    <ChatDotsIcon size={20} />
                    Leave a comment
                  </Button>
                </div>
              </DetailsSidebarBody.Footer>
            }
          />
        )}

        {activeTab === "comments" && <BillComments billId={billId} />}
      </DetailsSidebarPanel.Body>
    </DetailsSidebarPanel>
  );
};

export default BillDetailsInfoEmployeeView;
