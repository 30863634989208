import { Trash } from "@phosphor-icons/react";
import Dropdown from "ui/inputs/Dropdown";
import { ActionButton } from "ui/inputs/FileDropzone/SelectedFile/ActionButtons";
import TextInput from "ui/inputs/TextInput";
import Text from "ui/typography/Text";
import { countryOptions } from "utils/address";

import styles from "./CopackerInformationCard.module.scss";
import { Copacker, CopackersValidationErrors } from "./inventoryUtils";

type Props = {
  copacker: Copacker;
  copackerIdx: number;
  onCopackerInfoChange: (copacker: Copacker, index: number) => void;
  onCopackerDelete: (idxToDelete: number) => void;
  validationErrors: CopackersValidationErrors;
  onValidationError: (error: CopackersValidationErrors, index: number) => void;
};

const CopackerInformationCard: React.FC<Props> = ({
  copacker,
  copackerIdx,
  onCopackerInfoChange,
  onCopackerDelete,
  validationErrors,
  onValidationError,
}) => {
  const { address } = copacker;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeading}>
        <Text size={14}>Copacker information</Text>
        <ActionButton
          title="Delete"
          icon={<Trash size={16} onClick={() => onCopackerDelete(copackerIdx)} />}
        />
      </div>

      <div className={styles.cardBody}>
        <TextInput
          label="Business name"
          value={copacker?.businessName}
          hasError={Boolean(validationErrors?.businessName)}
          errorMessage="Business name is required"
          onBlur={() =>
            onValidationError(
              {
                ...validationErrors,
                businessName: !Boolean(copacker?.businessName) ? "Business name is required" : "",
              },
              copackerIdx
            )
          }
          onChange={(businessName) => {
            onCopackerInfoChange({ ...copacker, businessName }, copackerIdx);
            if (Boolean(businessName)) {
              onValidationError({ ...validationErrors, businessName: "" }, copackerIdx);
            } else {
              onValidationError(
                { ...validationErrors, businessName: "Business name is required" },
                copackerIdx
              );
            }
          }}
        />

        <div className={styles.inputWrapper}>
          <Dropdown
            id="country"
            onBlur={() =>
              onValidationError(
                {
                  ...validationErrors,
                  country: !Boolean(copacker?.address?.country) ? "Country is required" : "",
                },
                copackerIdx
              )
            }
            onChange={(country) => {
              onCopackerInfoChange(
                { ...copacker, address: { ...address, country: country.label?.toString() || "" } },
                copackerIdx
              );
              if (Boolean(country)) {
                onValidationError({ ...validationErrors, country: "" }, copackerIdx);
              }
            }}
            value={{ value: address?.country, label: address?.country }}
            label="Country"
            options={countryOptions}
            hasError={Boolean(validationErrors?.country)}
            errorMessage={validationErrors?.country}
          />
        </div>
      </div>
    </div>
  );
};

export default CopackerInformationCard;
