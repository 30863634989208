import { CheckCircle } from "@phosphor-icons/react";
import { FC } from "react";
import Card from "ui/data-display/Card";
import ButtonLink from "ui/inputs/ButtonLink";
import { Heading1, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
};

const UserInvitationAlreadyAcceptedCard: FC<Props> = ({ className }) => {
  return (
    <Card
      shadow="xs"
      className={cn("flex w-full max-w-2xl flex-col items-center justify-center p-8", className)}
    >
      <CheckCircle weight="fill" className="text-green-500" size={28} />
      <Heading1 className="mb-1 mt-4">Invitation already accepted</Heading1>
      <Paragraph className="max-w-xl text-center text-sm text-grey-600">
        You have already accepted this invitation and signed up for Highbeam. Please select{" "}
        <span className="text-nowrap">“Sign in”</span> below to sign into your account.
      </Paragraph>

      <ButtonLink className="mb-4 mt-6" variant="secondary" to="/sign-in">
        Sign in
      </ButtonLink>
    </Card>
  );
};

export default UserInvitationAlreadyAcceptedCard;
