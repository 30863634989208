export const BILL_LINE_ITEMS_OPEN_REQUESTED_EVENT = "billLineItemsOpenRequested";

export type BillLineItemsOpenRequestedEvent = CustomEvent<{
  billId: string;
}>;

export const makeBillLineItemsOpenRequestedEvent = (
  billId: string
): BillLineItemsOpenRequestedEvent =>
  new CustomEvent(BILL_LINE_ITEMS_OPEN_REQUESTED_EVENT, {
    detail: { billId },
  });
