import InternationalAccountRep from "reps/InternationalAccountRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BankAccountApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByBusiness(businessGuid: string): Promise<InternationalAccountRep.Complete | null> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/international-bank-accounts?${queryParams.toString()}`;
    return await this.api.get<InternationalAccountRep.Complete>(url);
  }
}
