import { CheckDeposit } from "@highbeam/unit-node-sdk";

// NB(alex): We should generalize / share this somewhere and make sure it stays up to date, ideally generated by the backend.
type CheckDepositTags = {
  userProvidedCounterpartyName?: string;
};

const getCheckDepositCounterpartyName = (checkDeposit: CheckDeposit) => {
  return (
    (checkDeposit.attributes.tags as CheckDepositTags)?.userProvidedCounterpartyName ||
    checkDeposit.attributes.counterparty?.name ||
    "Check Deposit"
  );
};

export default getCheckDepositCounterpartyName;
