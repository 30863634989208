import { useRecoilValueLoadable } from "recoil";
import businessGuidState from "state/auth/businessGuid";
import { DataRequired, LoginRequiredParam } from "utils/react-query/require-data";

import useJwt from "./useJwt";

// TODO(alex) HB-5859: We need to fix the underlying typescript types here now that we've enabled multi-business.
const useBusinessGuid = <TRequired extends boolean = true>(
  params: LoginRequiredParam<TRequired> = { loginRequired: true as TRequired }
) => {
  const jwt = useJwt(params);
  const businessGuid = useRecoilValueLoadable(businessGuidState);
  return (jwt ? businessGuid.getValue() : null) as DataRequired<string, TRequired>;
};

export default useBusinessGuid;
