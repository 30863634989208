import { ArrowsLeftRight } from "@phosphor-icons/react";
import { usePayoutInstructionsModal } from "pages/PayoutInstructionModal/state/payoutInstructionsModalState";
import ConnectedStoreIcon from "resources/connected-stores/components/ConnectedStoreIcon";
import { ConnectedStoreType } from "resources/connected-stores/types";
import Button from "ui/inputs/Button";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  connectedStoreType: ConnectedStoreType;
  className?: string;
};

const ActivationPendingBannerItem: React.FC<Props> = ({ connectedStoreType, className }) => {
  const { open: openPayoutInstructionsModal } = usePayoutInstructionsModal();

  return (
    <div
      className={cn(
        "flex flex-col justify-between gap-x-4 gap-y-6 px-8 py-6 tablet:flex-row",
        className
      )}
    >
      <div className="flex gap-x-4">
        <ConnectedStoreIcon connectedStoreType={connectedStoreType} className="size-4.5" />

        <div className="flex flex-1 flex-col gap-y-1">
          <Span className="text-sm font-medium">
            Update your {connectedStoreType} payouts to flow through Highbeam
          </Span>
          <Span className="text-sm font-medium text-grey-400">
            Make sure {connectedStoreType} deposits flow through Highbeam
          </Span>
        </div>
      </div>
      <Button
        variant="tertiary"
        onClick={() => openPayoutInstructionsModal({ activeTab: connectedStoreType })}
      >
        <ArrowsLeftRight size={16} />
        Reroute payouts
      </Button>
    </div>
  );
};

export default ActivationPendingBannerItem;
