import { Authorization, Transaction } from "@highbeam/unit-node-sdk";

import { CounterpartyVendorKey } from "./counterpartyVendorNames";

/* eslint sort-keys: ["error", "asc", { "natural": true }] */

const counterpartyVendorMatchers = {
  adobe(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        (transaction.attributes.merchant.name?.startsWith("ADOBE") ||
          transaction.attributes.merchant.name?.startsWith("PP*ADOBE"))
    );
  },
  affirm(transaction) {
    return (
      transaction.type === "receivedAchTransaction" &&
      transaction.attributes.companyName === "SHOPPAYINST AFRM"
    );
  },
  amazon(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        (transaction.attributes.merchant.name?.startsWith("AMAZON.COM") ||
          transaction.attributes.merchant.name?.startsWith("AMZN Mktp"))
    );
  },
  amazonPayout(transaction) {
    return (
      transaction.type === "receivedAchTransaction" &&
      transaction.attributes.companyName.startsWith("AMAZON.C")
    );
  },
  americanExpress(transaction) {
    return (
      transaction.type === "receivedAchTransaction" &&
      transaction.attributes.companyName.startsWith("AMEX") &&
      !this["americanExpressPayment"](transaction)
    );
  },
  americanExpressPayment(transaction) {
    return (
      transaction.type === "receivedAchTransaction" &&
      transaction.attributes.companyName.startsWith("AMEX") &&
      transaction.attributes.summary === "AMEX EPAYMENT  |  ACH PMT"
    );
  },
  easypost(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        transaction.attributes.merchant.name?.startsWith("EASYPOST")
    );
  },
  facebook(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        transaction.attributes.merchant.name?.startsWith("FACEBK")
    );
  },
  google(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        transaction.attributes.merchant.name?.toLowerCase()?.startsWith("google")
    );
  },
  // TODO(alex): Need to figure out how to handle this case.
  // interest(transaction) {
  //   return (transaction.type === "interestTransaction")
  // }
  klaviyo(transaction) {
    return (
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
      transaction.attributes.merchant.name === "KLAVIYO INC. SOFTWARE"
    );
  },
  microsoft(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        transaction.attributes.merchant.name?.startsWith("Microsoft")
    );
  },
  paypal(transaction) {
    return (
      transaction.type === "receivedAchTransaction" &&
      transaction.attributes.companyName === "PAYPAL"
    );
  },
  paypalPayout(transaction) {
    return (
      transaction.type === "receivedAchTransaction" &&
      transaction.attributes.companyName === "PAYPAL" &&
      transaction.attributes.description === "TRANSFER"
    );
  },
  shopify(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        transaction.attributes.merchant.name?.toLowerCase().startsWith("shopify")
    );
  },
  shopifyCapital(transaction) {
    return (
      transaction.type === "receivedAchTransaction" &&
      transaction.attributes.companyName.toLowerCase().startsWith("shopify capital")
    );
  },
  shopifyPayout(transaction) {
    return (
      transaction.type === "receivedAchTransaction" &&
      transaction.attributes.summary.toLowerCase().startsWith("shopify") &&
      transaction.attributes.summary.endsWith("|  TRANSFER")
    );
  },
  stripe(transaction) {
    return (
      transaction.type === "receivedAchTransaction" &&
      transaction.attributes.companyName.toLowerCase().startsWith("stripe") &&
      transaction.attributes.summary.endsWith("|  TRANSFER")
    );
  },
  tiktok(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        transaction.attributes.merchant.name?.startsWith("TIKTOK")
    );
  },
  typeform(transaction) {
    return (
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
      transaction.attributes.merchant.name === "TYPEFORM, S.L."
    );
  },
  uber(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        transaction.attributes.merchant.name?.startsWith("UBER")
    );
  },
  walmart(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        transaction.attributes.merchant.name?.startsWith("Walmart.com")
    );
  },
  wayflyer(transaction) {
    return (
      transaction.type === "receivedAchTransaction" &&
      transaction.attributes.companyName === "Wayflyer"
    );
  },
  wholeFoods(transaction) {
    return Boolean(
      (transaction.type === "purchaseTransaction" || transaction.type === "authorization") &&
        transaction.attributes.merchant.name?.startsWith("WHOLEFDS")
    );
  },
} satisfies {
  [key in CounterpartyVendorKey]: (transaction: Transaction | Authorization) => boolean;
};

export default counterpartyVendorMatchers;
