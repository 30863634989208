import { Dayjs } from "dayjs";
import {
  LineOfCreditAgreementUrlRep,
  LineOfCreditUpdateUserActionsRep,
  LineOfCreditAgreementMetadataRep,
} from "reps/LineOfCreditAgreementMetadataRep";
import RevenueForecastRep, { IntervalType } from "reps/RevenueForecastRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class LineOfCreditApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getLineOfCreditAgreement(
    businessGuid: string,
    lineOfCreditGuid: string,
    unsignedAgreementGuid?: string,
    signedAgreementGuid?: string
  ): Promise<LineOfCreditAgreementUrlRep | null> {
    const queryParams = new URLSearchParams({
      ...(unsignedAgreementGuid ? { unsignedAgreementGuid } : {}),
      ...(signedAgreementGuid ? { signedAgreementGuid } : {}),
    });
    const url = `/businesses/${businessGuid}/lines-of-credit/${lineOfCreditGuid}/agreements?${queryParams}`;
    return (await this.api.get<LineOfCreditAgreementUrlRep | null>(url))!;
  }

  async acceptLineOfCreditOffer(
    businessGuid: string,
    lineOfCreditGuid: string,
    userGuid: string
  ): Promise<LineOfCreditAgreementMetadataRep | null> {
    const queryParams = new URLSearchParams({
      ...(userGuid ? { userGuid } : {}),
    });
    const url = `/businesses/${businessGuid}/lines-of-credit/${lineOfCreditGuid}/accept-offer?${queryParams.toString()}`;

    return await this.api.post<LineOfCreditAgreementMetadataRep | null>(url);
  }

  async updateLineOfCreditUserActionsMetadata(
    businessGuid: string,
    lineOfCreditGuid: string,
    update: LineOfCreditUpdateUserActionsRep.Update
  ): Promise<LineOfCreditAgreementMetadataRep | null> {
    const url = `/businesses/${businessGuid}/lines-of-credit/${lineOfCreditGuid}/user-actions-metadata`;
    return await this.api.post<LineOfCreditAgreementMetadataRep | null>(url, update);
  }

  async getRevenueForecast(
    businessGuid: string,
    fromDate: Dayjs,
    intervalType: IntervalType,
    numberOfIntervals: number
  ): Promise<RevenueForecastRep | null> {
    const queryParams = new URLSearchParams({
      fromDate: fromDate.format("YYYY-MM-DD"),
      intervalType,
      numberOfIntervals: String(numberOfIntervals),
    });

    const url = `/businesses/${businessGuid}/revenue-forecast?${queryParams.toString()}`;
    return await this.api.get<RevenueForecastRep | null>(url);
  }
}
