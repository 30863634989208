import { DownloadSimple, Eye, Trash } from "@phosphor-icons/react";
import classNames from "classnames";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";

import styles from "./ActionButtons.module.scss";

type ActionButtonProps = JSX.IntrinsicElements["button"] & {
  isLoading?: boolean;
  icon: React.ReactNode;
};

export const ActionButton: React.FC<ActionButtonProps> = ({
  isLoading,
  icon,
  className,
  ...buttonProps
}) => (
  <button
    title="Preview"
    type="button"
    className={classNames(styles.iconButton, className)}
    {...buttonProps}
  >
    {isLoading ? <AnimatedSpinner /> : icon}
  </button>
);

/**
 * Commonly used action buttons
 */

type CommonActionButtonProps = Omit<ActionButtonProps, "icon">;

export const PreviewFileButton: React.FC<CommonActionButtonProps> = (props) => (
  <ActionButton title="Preview" icon={<Eye size={16} />} {...props} />
);

export const DownloadFileButton: React.FC<CommonActionButtonProps> = (props) => (
  <ActionButton title="Download" icon={<DownloadSimple size={16} />} {...props} />
);

export const DeleteFileButton: React.FC<CommonActionButtonProps> = (props) => (
  <ActionButton title="Delete" icon={<Trash size={16} />} {...props} />
);

/**
 * Wrapper component for file action buttons. Is currently just a fragment but is used for namespacing.
 */
const ActionButtons: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;

export default ActionButtons;
