import { ComponentProps, FC, ReactNode } from "react";
import { RequireOneOrNone } from "type-fest";
import InfoIcon, { InfoIconVariant } from "ui/icons/InfoIcon";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";

// NB(alex): This is subject to change. For now, we wrap `InfoIcon` but this may deviate.
const HelperIcon = (props: ComponentProps<typeof InfoIcon>) => {
  return <InfoIcon size={20} {...props} />;
};

type Props = {
  iconVariant?: InfoIconVariant;
  className?: string;
  children: ReactNode;
} & RequireOneOrNone<{
  icon: ReactNode;
  iconVariant: InfoIconVariant;
}>;

const Helper: FC<Props> = ({ children, className, icon, iconVariant, ...textProps }) => {
  return (
    <div className={cn("mt-2 inline-flex gap-x-2 px-1 text-sm leading-4 text-grey-600", className)}>
      {iconVariant ? <HelperIcon variant={iconVariant} /> : icon}

      <Span className="mt-0.5 flex-1" {...textProps}>
        {children}
      </Span>
    </div>
  );
};

export default Object.assign(Helper, {
  Icon: HelperIcon,
});
