import CheckDepositImagesFlexpaneSection, {
  checkDepositImagesTabs,
} from "resources/unit-co-check-deposits/components/CheckDepositImagesFlexpaneSection";
import useSearchParamOption from "utils/search-params/useSearchParamOption";
import getObjectKeys from "utils/ts/getObjectKeys";
import { HighbeamCheckDepositTransaction } from "utils/types/transactionsTypes";

type Props = {
  transaction: HighbeamCheckDepositTransaction;
};

const CheckDepositTransactionDetailsSection: React.FC<Props> = ({ transaction }) => {
  const [activeTab, setActiveTab] = useSearchParamOption(
    "side",
    getObjectKeys(checkDepositImagesTabs),
    "front-image"
  );

  return (
    <CheckDepositImagesFlexpaneSection
      checkDepositId={transaction.checkDepositId}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};

export default CheckDepositTransactionDetailsSection;
