import env from "env";
import LoadingPage from "modules/loading/pages/LoadingPage";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { useEffect } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import useSignUpWithRedirect from "resources/auth/hooks/useSignUpWithRedirect";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

const getEmailQueryParams = (emailAddress: string | null) => {
  return emailAddress ? createSearchParams({ email: emailAddress }).toString() : "";
};

/**
 * Redirects the browser to Auth0 to handle sign up.
 */
const SignUpPage = () => {
  const signUpWithRedirect = useSignUpWithRedirect();

  const highbeamApi = useHighbeamApi({ authMode: "maybeAuthenticated" });

  const navigate = useNavigate();
  const [search] = useSearchParams();
  const emailAddress = search.get("email");
  const source = search.get("source");

  useEffect(() => {
    async function setLead(emailAddress: string, source: string | null) {
      try {
        await highbeamApi.lead.set({ emailAddress, source });
      } catch {
        // ignore
      }
    }

    async function redirect(emailAddress: string | null, source: string | null) {
      if (source === "guides/guide-to-banking") {
        window.location.replace(env.GUIDE_TO_BANKING_LINK);
        return;
      }
      if (source === "guides/guide-to-credit") {
        window.location.replace(env.GUIDE_TO_CREDIT_LINK);
        return;
      }
      if (source === "value/call") {
        const url = `${env.CALENDLY_DEMO_LINK}?${getEmailQueryParams(emailAddress)}`;
        window.location.replace(url);
        return;
      }
      if (source === "whatstheapr") {
        navigate(
          {
            pathname: CREDIT_COMPARISON_PATH,
            search: getEmailQueryParams(emailAddress),
          },
          { replace: true }
        );
        return;
      }
      // eslint-disable-next-line camelcase
      await signUpWithRedirect({ login_hint: emailAddress ?? "", signupSource: source });
    }

    (async () => {
      if (emailAddress) {
        await setLead(emailAddress, source);
      }
      await redirect(emailAddress, source);
    })();
  }, [emailAddress, highbeamApi.lead, navigate, source, signUpWithRedirect]);

  return <LoadingPage location={SignUpPage.name} />;
};

export default SignUpPage;
