import FullPageLayout, { FullPageLayoutProps } from "layouts/FullPageLayout";
import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { onboardingProgressState } from "state/onboarding/progress";
import BarChart from "ui/data-visualization/BarChart";

type Props = FullPageLayoutProps & {
  showProgressBar?: boolean;
  children: ReactNode;
};

const OnboardingLayout: FC<Props> = ({
  children,
  showProgressBar = true,
  ...fullPageLayoutProps
}) => {
  const progress = useRecoilValue(onboardingProgressState);

  return (
    <>
      {showProgressBar && (
        <BarChart className="absolute w-full" height={8} roundedCornerVariant="rounded-none">
          <BarChart.Bar color="purple" widthPercentage={progress} className="rounded-l-none" />
        </BarChart>
      )}

      <FullPageLayout {...fullPageLayoutProps}>{children}</FullPageLayout>
    </>
  );
};

export default OnboardingLayout;
