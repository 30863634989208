import { Money } from "@phosphor-icons/react";
import ActionItemBanner from "components/common/banners/ActionItemBanner";
import colors from "styles/colors";
import Button from "ui/inputs/Button";

import { useOpenActivateThreadView } from "../ActivateThreadView/state/activateThreadViewState";

import useShouldShowActivateThreadBanner from "./hooks/useShouldShowActivateThreadBanner";

const ActivateThreadBanner = ({ headerText = "Activate Thread accounts" }) => {
  const openActivateThreadView = useOpenActivateThreadView();
  const shouldRenderBanner = useShouldShowActivateThreadBanner();

  if (!shouldRenderBanner) {
    return null;
  }

  return (
    <ActionItemBanner
      icon={<Money size={24} weight="thin" color={colors.purple[500]} />}
      text={headerText}
      subtext="Access features like $3M FDIC insurance and up to 4.5% APY with Thread accounts"
      actionButton={
        <Button variant="primary" onClick={() => openActivateThreadView()}>
          Review and activate
        </Button>
      }
    />
  );
};

export default ActivateThreadBanner;
