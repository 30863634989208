import { FC } from "react";
import useUpdateCounterpartyAliasMutation from "resources/counterparty-report/mutations/useUpdateCounterpartyAliasMutation";
import ModalV3 from "ui/overlay/ModalV3";

import UpdateCounterpartyAliasDropdown from "./components/UpdateCounterpartyAliasDropdown";
import UpdateCounterpartyAliasOriginalNameHelperText from "./components/UpdateCounterpartyAliasOriginalNameHelperText";
import {
  UpdateCounterpartyAliasModalParams,
  UpdateCounterpartyAliasModalProvider,
  useUpdateCounterpartyAliasModalContext,
} from "./context";

const UpdateCounterpartyAliasModalContent = () => {
  const { direction, onClose, form, originalCounterparties } =
    useUpdateCounterpartyAliasModalContext();

  const { mutateAsync: updateCounterpartyAlias, isPending } = useUpdateCounterpartyAliasMutation();

  const onSubmit = form.handleSubmit(async (data) => {
    await updateCounterpartyAlias({
      counterparty: data.counterpartyName,
      direction,
      originalCounterparties: originalCounterparties,
    });

    onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <ModalV3.Header onClose={onClose}>
        Edit {direction === "money-in" ? "source" : "vendor"} name
      </ModalV3.Header>

      <ModalV3.Body>
        <UpdateCounterpartyAliasDropdown />
        <UpdateCounterpartyAliasOriginalNameHelperText />
      </ModalV3.Body>

      <ModalV3.Footer>
        <ModalV3.Footer.SubmitButton isLoading={isPending}>
          Save changes
        </ModalV3.Footer.SubmitButton>
        <ModalV3.Footer.CloseButton onClick={onClose}>Cancel</ModalV3.Footer.CloseButton>
      </ModalV3.Footer>
    </form>
  );
};

type Props = {
  params?: UpdateCounterpartyAliasModalParams;
  onClose: () => void;
};

const UpdateCounterpartyAliasModal: FC<Props> = ({ params, onClose }) => {
  const isOpen = Boolean(params);

  return (
    <ModalV3 isOpen={isOpen} onClose={onClose} dropdownOverflowHack>
      {params && (
        <UpdateCounterpartyAliasModalProvider params={params} onClose={onClose}>
          <UpdateCounterpartyAliasModalContent />
        </UpdateCounterpartyAliasModalProvider>
      )}
    </ModalV3>
  );
};

export default UpdateCounterpartyAliasModal;
