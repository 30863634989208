import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useBusiness from "./useBusiness";

const useBusinessUnitCoCustomerId = <TRequired extends boolean>(
  params?: RequiredParam<TRequired>
) => {
  const business = useBusiness({ loginRequired: params?.required });

  const unitCoCustomerId = business?.unitCoCustomerId;

  if (params?.required && !unitCoCustomerId) {
    throw new RequiredButNotFoundError();
  }

  return unitCoCustomerId as DataRequired<typeof unitCoCustomerId, TRequired>;
};

export default useBusinessUnitCoCustomerId;
