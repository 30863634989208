import { Receipt } from "@phosphor-icons/react";
import classNames from "classnames";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./FileDropzoneHeader.module.scss";

type Props = {
  isDragActive: boolean;
  text?: React.ReactNode;
  subtext?: React.ReactNode;
  onClickBrowse: () => void;
};

const FileDropzoneHeader: React.FC<Props> = ({
  isDragActive,
  text = "Drag & drop or browse.",
  subtext,
  onClickBrowse,
}) => (
  <div className={styles.container}>
    <div className={classNames(styles.iconContainer, isDragActive && styles.dragActive)}>
      <Receipt size={16} />
    </div>

    <div className={styles.textContainer}>
      <Text size={14} color={colors.grey[900]} className={styles.text}>
        {text}
      </Text>

      {subtext && (
        <Text size={12} color={colors.grey[400]} className={styles.subtext}>
          {subtext}
        </Text>
      )}
    </div>

    <Button onClick={onClickBrowse} variant="tertiary" className={styles.browseButton} size="sm">
      Browse
    </Button>
  </div>
);

export default FileDropzoneHeader;
