import {
  CurrencyOption,
  shouldDisableDecimals,
  supportedInternationalWireCurrencies,
  usdCurrencyOption,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isMoreThanAllowedLocalPaymentAmountState } from "state/payments/international/isMoreThanAllowedLocalPaymentAmount";
import { quoteCalculatedLocalAmountInCentsState } from "state/payments/international/quoteCalculatedLocalAmountInCents";
import { quoteCalculatedUsdAmountInCentsState } from "state/payments/international/quoteCalculatedUsdAmountInCents";
import { quoteCurrencyState } from "state/payments/international/quoteCurrency";
import { quoteFixedSideState } from "state/payments/international/quoteFixedSideState";
import { quoteLocalAmountInCentsState } from "state/payments/international/quoteLocalAmountInCents";
import { quoteUsdAmountInCentsState } from "state/payments/international/quoteUsdAmountInCents";
import { selectedCountryOptionState } from "state/payments/international/selectedCountry";
import CurrencyInput from "ui/inputs/CurrencyInput";
import Dropdown from "ui/inputs/Dropdown";
import { getCentsFromDollars, getDollarsFromCents, parseMoneyFloat } from "utils/money";
import { formatAmount } from "utils/string";

import PaymentCurrency from "../PaymentCurrency";

import styles from "./PaymentAmount.module.scss";

type Props = {
  // Legacy props to support the old SendMoneyPage state management
  setAmount: (amount: string) => void;
  hasError: boolean;
  getErrorMessage: () => string | undefined;
};
const PaymentAmount: React.FC<Props> = ({ setAmount, hasError, getErrorMessage }) => {
  const country = useRecoilValue(selectedCountryOptionState);
  const setUsdAmountInCents = useSetRecoilState(quoteUsdAmountInCentsState);
  const setLocalAmountInCents = useSetRecoilState(quoteLocalAmountInCentsState);
  const internationalQuoteCurrencyOption = useRecoilValue(quoteCurrencyState);
  const calculatedLocalAmountInCents = useRecoilValue(quoteCalculatedLocalAmountInCentsState);
  const calculatedUsdAmountInCents = useRecoilValue(quoteCalculatedUsdAmountInCentsState);
  const [quoteFixedSide, setQuoteFixedSide] = useRecoilState(quoteFixedSideState);

  // We need to maintain the raw string to persist decimal places
  // This does not need to be in Recoil because it is only used locally for this component
  const [sendAmountRawValue, setSendAmountRawValue] = useState(
    calculatedUsdAmountInCents !== 0
      ? getDollarsFromCents(calculatedUsdAmountInCents).toString()
      : ""
  );
  const [receiveAmountRawvalue, setReceiveAmountRawValue] = useState(
    calculatedLocalAmountInCents !== 0
      ? getDollarsFromCents(calculatedLocalAmountInCents).toString()
      : ""
  );

  const isMoreThanAllowedLocalPaymentAmount = useRecoilValue(
    isMoreThanAllowedLocalPaymentAmountState
  );
  // Needed to support legacy international wire flow and maintaining amounts across payment methods
  useEffect(() => {
    setAmount(getDollarsFromCents(calculatedUsdAmountInCents).toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedUsdAmountInCents]);

  const isUsdToUsd = internationalQuoteCurrencyOption.value === "USD";

  return (
    <fieldset>
      <div className={styles.row}>
        <CurrencyInput
          className={styles.sendAmount}
          label={`You send${quoteFixedSide === "Send" && !isUsdToUsd ? " exactly" : ""}`}
          prefixValue="$"
          value={
            quoteFixedSide === "Send"
              ? sendAmountRawValue
              : (formatAmount(getDollarsFromCents(calculatedUsdAmountInCents), {
                  currency: "USD",
                  rawValue: true,
                }) ?? "")
          }
          onChange={(val) => {
            quoteFixedSide !== "Send" && setQuoteFixedSide("Send");
            setSendAmountRawValue(val);
            setUsdAmountInCents(parseMoneyFloat(val) ? getCentsFromDollars(val) : 0);
          }}
          hasError={hasError}
          errorMessage={getErrorMessage()}
        />
        <div className={styles.currency}>
          <Dropdown
            hideCaret
            hideCursor
            disabled
            className={styles.usdCurrencyDropdown}
            value={usdCurrencyOption}
            label="Currency"
            options={[usdCurrencyOption]}
          />
        </div>
      </div>

      <div className={styles.row}>
        <CurrencyInput
          className={styles.receiveAmount}
          label={`Payee receives${quoteFixedSide === "Receive" && !isUsdToUsd ? " exactly" : ""}`}
          prefixValue={internationalQuoteCurrencyOption.currencySymbol}
          value={
            quoteFixedSide === "Receive"
              ? receiveAmountRawvalue
              : (formatAmount(getDollarsFromCents(calculatedLocalAmountInCents), {
                  currency: internationalQuoteCurrencyOption.value,
                  rawValue: true,
                }) ?? "")
          }
          onChange={(val) => {
            quoteFixedSide !== "Receive" && setQuoteFixedSide("Receive");
            setReceiveAmountRawValue(val);
            setLocalAmountInCents(parseMoneyFloat(val) ? getCentsFromDollars(val) : 0);
          }}
          allowDecimals={!shouldDisableDecimals(internationalQuoteCurrencyOption.value)}
          hasError={isMoreThanAllowedLocalPaymentAmount}
          errorMessage={`${
            internationalQuoteCurrencyOption.value
          } exchanges are limited to ${formatAmount(
            getDollarsFromCents(country?.local.paymentLimitinCents!),
            {
              currency: internationalQuoteCurrencyOption.value,
            }
          )}`}
        />
        <div className={styles.currency}>
          <PaymentCurrency className={styles.localCurrencyDropdown} includeLegend={false} />
        </div>
      </div>
    </fieldset>
  );
};

export const PaymentAmountFallback = () => {
  const [internationalQuoteCurrencyOption, setInternationalQuoteCurrencyOption] =
    useRecoilState(quoteCurrencyState);
  const usdAmountInCents = useRecoilValue(quoteUsdAmountInCentsState);
  const localAmountInCents = useRecoilValue(quoteLocalAmountInCentsState);
  return (
    <fieldset>
      <div className={styles.row}>
        <CurrencyInput
          disabled
          className={styles.sendAmount}
          label="You send"
          value={getDollarsFromCents(usdAmountInCents).toString() ?? ""}
        />
        <div className={styles.currency}>
          <Dropdown
            hideCaret
            hideCursor
            disabled
            className={styles.usdCurrencyDropdown}
            value={usdCurrencyOption}
            label="Currency"
            options={[usdCurrencyOption]}
          />
        </div>
      </div>
      <div className={styles.row}>
        <CurrencyInput
          disabled
          className={styles.receiveAmount}
          label="You send"
          value={getDollarsFromCents(localAmountInCents).toString() ?? ""}
        />
        <div className={styles.currency}>
          <Dropdown
            className={styles.localCurrencyDropdown}
            onChange={(val) => setInternationalQuoteCurrencyOption(val as CurrencyOption)}
            value={internationalQuoteCurrencyOption}
            label="Currency"
            options={supportedInternationalWireCurrencies}
          />
        </div>
      </div>
    </fieldset>
  );
};

export const PaymentAmountError = () => {
  const [internationalQuoteCurrencyOption, setInternationalQuoteCurrencyOption] =
    useRecoilState(quoteCurrencyState);

  return (
    <fieldset>
      <div className={styles.row}>
        <CurrencyInput disabled className={styles.sendAmount} label="You send" value={""} />
        <div className={styles.currency}>
          <Dropdown
            hideCaret
            hideCursor
            disabled
            className={styles.usdCurrencyDropdown}
            value={usdCurrencyOption}
            label="Currency"
            options={[usdCurrencyOption]}
          />
        </div>
      </div>
      <div className={styles.row}>
        <CurrencyInput
          disabled
          className={styles.receiveAmount}
          label="You send"
          value={""}
          hasError
          errorMessage={`${internationalQuoteCurrencyOption.value} exchanges are temporarily unavailable, please try again later.`}
        />
        <div className={styles.currency}>
          <Dropdown
            className={styles.localCurrencyDropdown}
            onChange={(val) => setInternationalQuoteCurrencyOption(val as CurrencyOption)}
            value={internationalQuoteCurrencyOption}
            label="Currency"
            options={supportedInternationalWireCurrencies}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default PaymentAmount;
