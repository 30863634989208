import { ErrorBoundary } from "@sentry/react";
import ErrorPage from "modules/error/pages/ErrorPage";
import React, { Suspense } from "react";
import DotsPageLoader from "ui/feedback/DotsLoaderPage";

import styles from "./Dashboard.module.scss";
import DashboardSidebar from "./DashboardSidebar";

// Used for getting the content's containing element to control the scroll position.
export const DASHBOARD_PAGE_CONTENT_ID = "dashboard-page-content";

const DashboardLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.container}>
      <DashboardSidebar />

      <ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
        <Suspense fallback={<DotsPageLoader variant="page" />}>
          <div className={styles.content} id={DASHBOARD_PAGE_CONTENT_ID}>
            {children}
          </div>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default DashboardLayout;
