import { atom } from "recoil";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

const cashFlowSyncSheetModalState = atom<DialogState>({
  key: "cash-flow/cashFlowSyncSheetModalState",
  default: { isOpen: false },
});

export default cashFlowSyncSheetModalState;

export const useCashFlowSyncSheetModal = () => {
  return useModal(cashFlowSyncSheetModalState);
};
