import HighbeamBaseApi from "./HighbeamBaseApi";

export default class UserInvitationActionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async reSendEmail(userInvitationGuid: string): Promise<void> {
    const url = `/user-invitations/${userInvitationGuid}/re-send-email`;
    return (await this.api.post(url))!;
  }
}
