import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const PAYMENT_METADATA_QUERY_KEY = "payment-metadata";

type Params = {
  paymentMetadataGuid?: string;
};

const usePaymentMetadataQueryOptions = ({ paymentMetadataGuid }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [PAYMENT_METADATA_QUERY_KEY, { businessGuid, paymentMetadataGuid }],
    queryFn: () => {
      if (!paymentMetadataGuid) {
        return null;
      }

      return highbeamApi.generalPaymentMetadata.get(businessGuid, paymentMetadataGuid);
    },
  });
};

export default usePaymentMetadataQueryOptions;
