import UserReferralRep from "reps/ReferralLinkRep";
import Divider from "ui/data-display/Divider";

import styles from "../Referral.module.scss";

import apyBanner from "./apy-banner.png";
import ReferralContent from "./ReferralContent";
import ReferralFooter from "./ReferralFooter";
import ReferralHeader from "./ReferralHeader";

type Props = {
  referral: UserReferralRep;
  referrerAvatar: string | null;
};

const ReferralApy: React.FC<Props> = ({ referral, referrerAvatar }) => {
  return (
    <div className={styles.container}>
      <img src={apyBanner} alt="apy-banner" className={styles.topBanner} />
      <div className={styles.top}>
        <ReferralHeader referral={referral} referrerAvatar={referrerAvatar} />
        <ReferralContent referral={referral} />
        <Divider className="my-0 self-stretch" />
        <ReferralFooter />
      </div>
    </div>
  );
};

export default ReferralApy;
