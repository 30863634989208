import CapitalDrawdownApprovalRep from "reps/CapitalDrawdownApprovalRep";
import CapitalDrawdownApprovalUploadUrlRep from "reps/CapitalDrawdownApprovalUploadUrlRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CapitalDrawdownApprovalApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(
    capitalAccountGuid: string,
    businessGuid: string
  ): Promise<CapitalDrawdownApprovalRep.Complete[]> {
    const qp = new URLSearchParams({ businessGuid, capitalAccountGuid });
    const path = `/capital-transactions/drawdown-approval?${qp}`;
    return (await this.api.get<CapitalDrawdownApprovalRep.Complete[]>(path))!;
  }

  async create(
    businessGuid: string,
    capitalAccountGuid: string,
    creator: CapitalDrawdownApprovalRep.Creator
  ): Promise<CapitalDrawdownApprovalRep.Complete> {
    const qp = new URLSearchParams({ businessGuid, capitalAccountGuid });
    const url = `/capital-transactions/drawdown-approval?${qp}`;
    return (await this.api.post(url, creator))!;
  }

  async uploadUrl(
    guid: string,
    businessGuid: string
  ): Promise<CapitalDrawdownApprovalUploadUrlRep.Complete> {
    const qp = new URLSearchParams({ businessGuid });
    const path = `/capital-transactions/drawdown-approval/${guid}/upload-url?${qp}`;
    return (await this.api.get<CapitalDrawdownApprovalUploadUrlRep.Complete>(path))!;
  }
}
