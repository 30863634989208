import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import ArchivedCardsLists from "./ArchivedCardsList";
import ArchivedCardsPageHeader from "./ArchivedCardsPageHeader";

const ArchivedCardsPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/cards">Cards</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Archived</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <ArchivedCardsPageHeader />
        <ArchivedCardsLists />
      </DashboardPage>
    </>
  );
};

export default ArchivedCardsPage;
