import { X } from "@phosphor-icons/react";
import { ClearIndicatorProps, GroupBase, components } from "react-select";

import styles from "./DropdownClearIndicator.module.scss";

const DropdownClearIndicator = <
  TOption,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
  props: ClearIndicatorProps<TOption, TIsMulti, TGroup>
) => {
  return (
    <components.ClearIndicator className={styles.clear} {...props}>
      <X size={16} />
    </components.ClearIndicator>
  );
};

export default DropdownClearIndicator;
