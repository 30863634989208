import classNames from "classnames";
import { ComponentProps, FC } from "react";
import { Heading3 } from "ui/typography";

import styles from "./TransactionFlexpaneDetails.module.scss";

const TransactionFlexpaneDetails: FC<JSX.IntrinsicElements["div"]> = ({
  children,
  className,
  ...divProps
}) => {
  return (
    <div className={classNames(className, styles.container)} {...divProps}>
      {children}
    </div>
  );
};

const TransactionFlexpaneDetailsHeading: FC<ComponentProps<typeof Heading3>> = (props) => {
  return <Heading3 {...props} />;
};

export default Object.assign(TransactionFlexpaneDetails, {
  Heading: TransactionFlexpaneDetailsHeading,
});
