export const createPopup = (
  url: string,
  target: string = "",
  width: number = 700,
  height: number = 500
) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  return window.open(url, target, `width=${width},height=${height},left=${left},top=${top}`);
};
