import RutterConnectionRep from "reps/RutterConnectionRep";

export const isRutterCommercePlatform = (
  platform: string
): platform is RutterConnectionRep.RutterCommercePlatform =>
  platform === "SHOPIFY" || platform === "AMAZON";

export const isRutterAccountingPlatform = (
  platform: string
): platform is RutterConnectionRep.RutterAccountingPlatform => {
  return RutterConnectionRep.rutterAccountingPlatforms.includes(
    platform as RutterConnectionRep.RutterAccountingPlatform
  );
};

export const isRutterPaymentProcessor = (
  platform: string
): platform is RutterConnectionRep.RutterPaymentProcessor =>
  platform === "STRIPE" || platform === "PAYPAL";

export const isRutterCommercePlatformOrPaymentProcessor = (
  platform: RutterConnectionRep.RutterPlatform
): platform is
  | RutterConnectionRep.RutterCommercePlatform
  | RutterConnectionRep.RutterPaymentProcessor => {
  return isRutterCommercePlatform(platform) || isRutterPaymentProcessor(platform);
};
