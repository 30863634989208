import { Dialog, DialogBackdrop } from "@headlessui/react";
import React, { useRef } from "react";

import styles from "./BusinessSwitcherDialog.module.scss";
import BusinessSwitcherImpl from "./BusinessSwitcherImpl";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const BusinessSwitcherDialog: React.FC<Props> = ({ isOpen, onClose: handleClose }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Dialog initialFocus={inputRef} open={isOpen} onClose={handleClose}>
      <DialogBackdrop className={styles.backdrop} onClick={handleClose} />
      <div className={styles.container}>
        <div className={styles.content}>
          <BusinessSwitcherImpl inputRef={inputRef} />
        </div>
      </div>
    </Dialog>
  );
};

export default BusinessSwitcherDialog;
