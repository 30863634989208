import { DEFAULT_MONTHS_SINCE } from "config/constants/filters";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Option } from "ui/inputs/Dropdown";
import { usePagination } from "ui/navigation/PageIndicator";
import { HighbeamTransaction } from "utils/types/transactionsTypes";

export type AccountOption = {
  balance?: number;
} & Option;

export const ALL_ACCOUNTS_OPTION: AccountOption = {
  label: "All accounts",
  value: "all",
};

export const useHighbeamTransactions = () => {
  const [transactions, setTransactions] = useState<HighbeamTransaction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState<AccountOption[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<AccountOption | null>(ALL_ACCOUNTS_OPTION);
  const [searchQuery, setSearchQuery] = useState("");

  const [now] = useState(dayjs());
  const [fromDate, setFromDate] = useState<Date | null>(
    now.subtract(DEFAULT_MONTHS_SINCE, "month").add(1, "day").toDate()
  );
  // We start as one year into the future since that is the farthest into the future users are
  // allowed to schedule a future transaction. But we disable it for some customers using a feature
  // flag for simplicity. We plan to split out scheduled payments from transactions table soon.
  // TODO: https://linear.app/highbeam/issue/PRD-83/scheduled-payments-v2
  const [toDate, setToDate] = useState<Date | null>(now.toDate());

  const pagination = usePagination();

  // Reset current page to 0 whenever the search query changes.
  useEffect(() => {
    pagination.setCurrentPage(0);
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    transactions,
    setTransactions,
    isLoading,
    setIsLoading,
    accountOptions,
    setAccountOptions,
    selectedAccount,
    setSelectedAccount,
    searchQuery,
    setSearchQuery,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    pagination,
  };
};
