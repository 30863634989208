/* eslint-disable no-use-before-define */

import React, { MouseEventHandler, ReactNode } from "react";

import Body from "./Body";
import Head from "./Head";
import Header from "./Header";
import PageIndicator from "./PageIndicator";
import Table from "./Table";
import TableWrapper from "./TableWrapper";

export type Align = "left" | "right";

export class Column<T> {
  align: Align;
  cellClassName: string | undefined;
  defaultSortDirection: SortDirection;
  key: React.Key;
  left: number | undefined = undefined;
  renderCell: (datum: T, options: RenderCellOptions) => ReactNode;
  renderHeader: (options: RenderHeaderOptions) => ReactNode;
  right: number | undefined = undefined;
  sortable: boolean;
  sticky: StickySide | undefined;
  title: string;
  width: number | undefined;

  constructor(column: ColumnProps<T>) {
    this.align = column.align ?? "left";
    this.cellClassName = column.cellClassName;
    this.defaultSortDirection = column.defaultSortDirection ?? "ascending";
    this.key = column.key;
    this.renderCell = column.renderCell;
    this.renderHeader = column.renderHeader ?? TableV2.Header;
    this.sortable = column.sortable ?? false;
    this.sticky = column.sticky;
    this.title = column.title;
    this.width = column.width;
  }
}

export type ColumnProps<T> = {
  /**
   * Specifies cell alignment, including header cells.
   */
  align?: Align;

  /**
   * CSS class name applied to cells, including header cells.
   */
  cellClassName?: string;

  /**
   * The default sort direction for this column.
   */
  defaultSortDirection?: SortDirection;

  /**
   * Unique key for this column.
   */
  key: React.Key;

  /**
   * Called to render each cell in the column, excluding header cells.
   */
  renderCell: (datum: T, options: RenderCellOptions) => ReactNode;

  /**
   * Called to render the header cell for the column.
   */
  renderHeader?: (options: RenderHeaderOptions) => ReactNode;

  /**
   * Specifies whether the column can be sorted by.
   */
  sortable?: boolean;

  /**
   * Whether the column is sticky, and to which side.
   *
   * If more than one column is sticky to the same side, column widths must be specified.
   * This is a browser constraint.
   */
  sticky?: StickySide;

  /**
   * The column title.
   */
  title: string;

  /**
   * Sets the cell's width in pixels.
   */
  width?: number;
};

export type FetchTableData<T> = (options: {
  offset: number | undefined;
  pageSize: number | undefined;
  sort: Sort | undefined;
}) => Promise<{ data: T[]; totalCount?: number }>;

export type RenderCellOptions = {
  i: number;
  total: number;
};

export type RenderHeaderOptions = {
  align: Align;
  onClick: MouseEventHandler<HTMLButtonElement>;
  sortByThis: boolean;
  sortDirection: SortDirection;
  sortable: boolean;
  title: string;
};

export type Scroll = {
  isAtStart: boolean;
  isAtEnd: boolean;
};

export type Sort = {
  by: React.Key;
  direction: SortDirection;
};

export type SortDirection = "ascending" | "descending";

export type StickySide = "left" | "right";

const TableV2 = Object.assign(TableWrapper, {
  Head: Head,
  Header: Header,
  PageIndicator: PageIndicator,
  Table: Table,
  Body: Body,
});

export default TableV2;
