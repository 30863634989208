import CreditComparisonTable from "../components/CreditComparisonTable";
import FromControls from "../components/FromControls";

import styles from "./CreditComparisonTableView.module.scss";

const CreditComparisonTableView = () => (
  <div className={styles.container}>
    <CreditComparisonTable />

    <FromControls isLoading={false} isSubmitDisabled />
  </div>
);

export default CreditComparisonTableView;
