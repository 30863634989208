import ClickToCopyInviteLink from "pages/settings/SettingsUsersPage/ClickToCopyInviteLink";
import { FC } from "react";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import UserAvatarBar from "ui/data-display/UserAvatarBar";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";

export type InvitationDetails = {
  initials: string;
  displayName: string;
  slug: string;
};

type Props = {
  invitationDetails: InvitationDetails;
  onClose: () => void;
};

const InvitationDetailsModal: FC<Props> = ({
  invitationDetails: { initials, displayName, slug },
  onClose,
}) => {
  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Form
        onSubmit={(_e, { closeModalWithAnimation }) => {
          closeModalWithAnimation();
        }}
      >
        <ModalV4.Header>Invitation details</ModalV4.Header>

        <ModalV4.Body>
          <div className="flex flex-col gap-y-4">
            <UserAvatarBar initials={initials} fullName={displayName} />

            <Divider className="my-0" />

            <Text size={14} color={colors.grey[600]}>
              Share this link with {displayName} and they will be able to join Highbeam and access
              your account.
            </Text>
            <ClickToCopyInviteLink slug={slug} />
          </div>
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.SubmitButton>Close</ModalV4.SubmitButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default InvitationDetailsModal;
