import { captureException } from "@sentry/react";
import FullPageLayout from "layouts/FullPageLayout";
import ErrorCard from "modules/error/components/ErrorCard";
import React, { ReactNode } from "react";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useMountEffect from "utils/customHooks/useMountEffect";

type Props = {
  readonly error?: Error;
  header?: ReactNode;
};

/**
 * This full-page error page should only be used when something goes terribly wrong and a partial
 * state can't even be shown.
 *
 * Keep this as vanilla as possible to avoid potential errors get thrown from within the error page.
 */
const ErrorPage: React.FC<Props> = ({ error, header }) => {
  const isSuperusering = useIsSuperusering();

  useMountEffect(() => {
    if (isSuperusering) return;
    captureException(error);
  });

  return (
    <FullPageLayout
      backgroundColor="bg-white"
      backgroundImage="none"
      header={header}
      className="w-full"
    >
      <ErrorCard error={error} className="mt-12 tablet:mt-64" />
    </FullPageLayout>
  );
};

export default ErrorPage;
