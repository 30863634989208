import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  ActiveCapitalAccountWithChargeCard,
  checkIsActiveCapitalAccountWithChargeCard,
} from "resources/capital-accounts/hooks/useActiveCapitalAccountsWithChargeCard";
import { NonTerminatedCapitalAccountWithChargeCard } from "resources/capital-accounts/hooks/useNonTerminatedCapitalAccountsWithChargeCard";
import { CardVirtualOrPhysical } from "resources/cards/types";
import { z } from "zod";

type DebitOption = { type: "debit" };
type CreditOption = { type: "credit"; capitalAccount: NonTerminatedCapitalAccountWithChargeCard };

export type CreditOrDebitOption = DebitOption | CreditOption;

type CreditTransformedOption = {
  type: "credit";
  capitalAccount: ActiveCapitalAccountWithChargeCard;
};

type CreditOrDebitTransformedOption = DebitOption | CreditTransformedOption;

const schema = z.object({
  virtualOrPhysical: z.custom<CardVirtualOrPhysical>(),
  creditOrDebitOption: z
    .custom<CreditOrDebitOption>()
    .transform<CreditOrDebitTransformedOption>((arg, ctx) => {
      if (arg.type === "debit") {
        return arg;
      }
      if (checkIsActiveCapitalAccountWithChargeCard(arg.capitalAccount)) {
        return {
          type: "credit",
          capitalAccount: arg.capitalAccount,
        };
      } else {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Capital account is not active",
        });
        return z.NEVER;
      }
    }),
});

export type CreateCardModalSelectCardTypeFormInputs = z.input<typeof schema>;
export type CreateCardModalSelectCardTypeFormOutputs = z.output<typeof schema>;

type Params = {
  defaultValues: CreateCardModalSelectCardTypeFormInputs;
};

const useCreateCardModalSelectCardTypeForm = ({ defaultValues }: Params) => {
  return useForm<
    CreateCardModalSelectCardTypeFormInputs,
    object,
    CreateCardModalSelectCardTypeFormOutputs
  >({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });
};

export default useCreateCardModalSelectCardTypeForm;
