import classNames from "classnames";
import { FC, ReactNode } from "react";

import Step from "./Step";
import styles from "./Steps.module.scss";

type Props = {
  children: ReactNode;
};

const StepsV3: FC<Props> = ({ children }) => (
  <div className={classNames("hide-scrollbars", styles.container)}>{children}</div>
);

export default Object.assign(StepsV3, {
  Step: Step,
});
