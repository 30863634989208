import BusinessMemberRep from "reps/BusinessMemberRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BusinessMemberApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByBusiness(businessGuid: string): Promise<BusinessMemberRep.Complete[]> {
    const url = `/businesses/${businessGuid}/members`;
    return (await this.api.get<BusinessMemberRep.Complete[]>(url))!;
  }

  async getByMember(
    businessGuid: string,
    userGuid: string
  ): Promise<BusinessMemberRep.Complete | null> {
    const qp = new URLSearchParams({ userGuid });
    const url = `/businesses/${businessGuid}/members?${qp}`;
    return this.api.get<BusinessMemberRep.Complete>(url);
  }

  async update(
    businessGuid: string,
    memberGuid: string,
    rep: BusinessMemberRep.Updater
  ): Promise<BusinessMemberRep.Complete> {
    const url = `/businesses/${businessGuid}/members/${memberGuid}`;
    return (await this.api.patch<BusinessMemberRep.Complete>(url, rep))!;
  }

  async delete(businessGuid: string, memberGuid: string): Promise<BusinessMemberRep.Complete> {
    const url = `/businesses/${businessGuid}/members/${memberGuid}`;
    return (await this.api.delete<BusinessMemberRep.Complete>(url))!;
  }
}
