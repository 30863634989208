import { FC, useMemo } from "react";
import { Controller } from "react-hook-form";
import CapitalAccountBar from "resources/capital-accounts/components/CapitalAccountBar";
import { ActiveCapitalAccountWithChargeCard } from "resources/capital-accounts/hooks/useActiveCapitalAccountsWithChargeCard";
import capitalAccountSummaryQueryHooks from "resources/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import getCapitalAccountAvailablePercentage from "resources/capital-accounts/utils/getCapitalAccountAvailablePercentage";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import BarChart from "ui/data-visualization/BarChart";
import Shimmer from "ui/feedback/ShimmerV2";
import Button from "ui/inputs/Button";
import DropdownV2 from "ui/inputs/DropdownV2";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";
import { FormSubmitHandlerProps } from "utils/react-hook-form/GetUseFormSubmitHandler";

import { SectionHeading, SectionSubheading } from "../headings";

import useCreateCardModalSelectCapitalAccountForm, {
  CreateCardModalSelectCapitalAccountFormOutputs,
} from "./useCreateCardModalSelectCapitalAccountForm";

type AvailableBalanceHelperProps = {
  capitalAccountGuid: string;
};

const AvailableBalanceHelper: FC<AvailableBalanceHelperProps> = ({ capitalAccountGuid }) => {
  const { data: capitalAccountSummary } = capitalAccountSummaryQueryHooks.useQuery({
    capitalAccountGuid,
  });

  return (
    <div className="mt-4">
      {capitalAccountSummary ? (
        <BarChart height={10} backgroundVariant="grey-solid">
          <BarChart.Bar
            color="green"
            widthPercentage={getCapitalAccountAvailablePercentage(capitalAccountSummary)}
          />
        </BarChart>
      ) : (
        <Shimmer className="h-2.5" />
      )}

      <div className="mt-2 flex items-center justify-between">
        <Text color={colors.grey[900]} weight="medium" size={14}>
          Available
        </Text>

        <div className="flex items-center gap-x-1">
          {capitalAccountSummary ? (
            <MoneyAmount
              cents={capitalAccountSummary.available}
              color={colors.grey[800]}
              size={14}
              weight="medium"
              withCents={capitalAccountSummary.available !== capitalAccountSummary.details.limit}
            />
          ) : (
            <Shimmer className="w-16" />
          )}

          <Text size={14} color={colors.grey[500]} weight="medium">
            {" / "}
          </Text>

          {capitalAccountSummary ? (
            <MoneyAmount
              weight="medium"
              cents={capitalAccountSummary.details.limit}
              color={colors.grey[500]}
              size={14}
              withCents={capitalAccountSummary.details.limit % 100 !== 0}
            />
          ) : (
            <Shimmer className="w-16" />
          )}
        </div>
      </div>
    </div>
  );
};

type Props = FormSubmitHandlerProps<typeof useCreateCardModalSelectCapitalAccountForm> & {
  onBack: () => void;
  activeCapitalAccountWithChargeCard: ActiveCapitalAccountWithChargeCard;
  submittedDataDefaultValues: CreateCardModalSelectCapitalAccountFormOutputs | null;
};

const CreateCardModalSelectCapitalAccountForm: FC<Props> = ({
  onValid,
  onInvalid,
  onBack,
  activeCapitalAccountWithChargeCard,
  submittedDataDefaultValues,
}) => {
  // NB(alex): We were thinking of allowing users to select between capital accounts if they have multiple capital accounts with the same credit terms.
  // Filtering by this is hard so for now we select the capital account in the previous step and only render that single option here. https://highbeamco.slack.com/archives/C03R625F068/p1728420007446949
  const filteredActiveCapitalAccountsWithChargeCard = useMemo(
    () => [activeCapitalAccountWithChargeCard],
    [activeCapitalAccountWithChargeCard]
  );

  // NB(alex): Defaults to the first item in the dropdown if the `submittedDataDefaultValues` doesn't have a match.
  // This fixes a bug where `submittedDataDefaultValues.capitalAccount` selects a value that doesn't exist in `filteredActiveCapitalAccountsWithChargeCard`.
  // See more details about the bug here: https://highbeamco.slack.com/archives/C07QS4WCETD/p1733258951162499
  // Technically we don't have to do all this, but this logic will be correct if/when we actually allow users to select from multiple capital accounts.
  const defaultCapitalAccount = useMemo(() => {
    return (
      filteredActiveCapitalAccountsWithChargeCard.find(
        (capitalAccount) => capitalAccount.guid === submittedDataDefaultValues?.capitalAccount?.guid
      ) ?? filteredActiveCapitalAccountsWithChargeCard[0]
    );
  }, [filteredActiveCapitalAccountsWithChargeCard, submittedDataDefaultValues]);

  const form = useCreateCardModalSelectCapitalAccountForm({
    defaultValues: {
      capitalAccount: defaultCapitalAccount,
    },
  });

  return (
    <ModalV4.Form onSubmit={form.handleSubmit(onValid, onInvalid)}>
      <ModalV4.Body>
        <SectionHeading>Capital account</SectionHeading>
        <SectionSubheading>
          All spend on this card will be made from the associated account.
        </SectionSubheading>

        <Controller
          control={form.control}
          name="capitalAccount"
          render={({ field }) => {
            return (
              <div>
                <DropdownV2
                  label="Account"
                  options={filteredActiveCapitalAccountsWithChargeCard}
                  disabled={filteredActiveCapitalAccountsWithChargeCard.length === 1}
                  {...field}
                  renderOption={(optionProps) => {
                    return (
                      <DropdownV2.Option {...optionProps}>
                        <CapitalAccountBar capitalAccount={optionProps.data} />
                      </DropdownV2.Option>
                    );
                  }}
                  components={{
                    // NB(alex): Should probably move this to `renderSingleValue`.
                    SingleValue: (singleValueProps) => {
                      return (
                        <DropdownV2.SingleValue {...singleValueProps}>
                          <CapitalAccountBar
                            capitalAccount={singleValueProps.data}
                            className="mt-1" // NB(alex): Looks slightly off without this. Might be worth including this as a prop for the component.
                          />
                        </DropdownV2.SingleValue>
                      );
                    },
                  }}
                />

                <AvailableBalanceHelper capitalAccountGuid={field.value.guid} />
              </div>
            );
          }}
        />
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.SubmitButton>Next</ModalV4.SubmitButton>
        <Button onClick={onBack} variant="ghost">
          Back
        </Button>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

export default CreateCardModalSelectCapitalAccountForm;
