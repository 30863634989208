/**
 * File type configs for `react-dropzone`.
 */
export const fileTypeExtensions = {
  jpeg: {
    "image/jpeg": [".jpeg", ".jpg"],
  },
  pdf: {
    "application/pdf": [".pdf"],
  },
  png: {
    "image/png": [".png"],
  },
  excel: {
    "application/vnd.ms-excel": [".xsl"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  },
  word: {
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  },
  csv: {
    "text/csv": [".csv", ".tsv"],
  },

  // ... add more file types here!

  get image() {
    return {
      ...this.jpeg,
      ...this.png,
    };
  },
} satisfies { [key: string]: Record<string, string[]> };

export type FileType = keyof typeof fileTypeExtensions;
