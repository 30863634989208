import DashboardLayout from "layouts/Dashboard";
import BusinessDisambiguator from "modules/business-disambiguator/BusinessDisambiguator";
import OnboardingRouter from "pages/Onboarding/OnboardingRouter";
import SettingsRouter from "pages/settings/SettingsRouter";
import { Navigate, Route, Routes } from "react-router-dom";

const NonOnboardedRouter = () => (
  <Routes>
    <Route path="/businesses" element={<BusinessDisambiguator />} />

    <Route path="/onboarding/*" element={<OnboardingRouter />} />
    <Route
      path="/settings/*"
      element={
        <DashboardLayout>
          <SettingsRouter />
        </DashboardLayout>
      }
    />
    <Route path="*" element={<Navigate replace to="/onboarding" />} />
  </Routes>
);

export default NonOnboardedRouter;
