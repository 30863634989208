import classNames from "classnames";

import styles from "./Modal.module.scss";

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const ModalFooter: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(styles.footer, className)}>{children}</div>
);

export default ModalFooter;
