import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const ROOT_BUSINESS_MEMBERS_QUERY_KEY = "businessMembers-root";

export const useRefreshAllBusinessMembersQueries = () => {
  return useRefreshQuery([ROOT_BUSINESS_MEMBERS_QUERY_KEY]);
};

const businessMembersQueryHooks = makeQueryHooks({
  rootName: ROOT_BUSINESS_MEMBERS_QUERY_KEY,
  name: "businessMembers",
  useQueryVariables: () => {
    const businessGuid = useBusinessGuid();
    return { businessGuid };
  },
  useQueryFnMaker: ({ businessGuid }) => {
    const highbeamApi = useHighbeamApi();
    return () => {
      return highbeamApi.businessMember.getByBusiness(businessGuid);
    };
  },
});

export default businessMembersQueryHooks;
