import { FC } from "react";
import { ConversionSide } from "reps/payments-v2/ConversionSide";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

type Props = {
  fixedSide: ConversionSide;
  cents: number;
  receiveCurrency: string;
};

const DraftPaymentExchangeRateTooltipContent: FC<Props> = ({
  fixedSide,
  cents,
  receiveCurrency,
}) => {
  if (fixedSide === "Send") {
    return (
      <Text size={14}>
        We will convert this amount when the payment is approved. The amount of {receiveCurrency}{" "}
        the payee receives may change slightly, but{" "}
        <strong>
          you will send exactly <MoneyAmount cents={cents} />
        </strong>
        .
      </Text>
    );
  } else {
    return (
      <Text size={14}>
        We will convert this amount when the payment is approved. The amount you send may change
        slightly, but{" "}
        <strong>
          the payee will receive exactly{" "}
          <MoneyAmount cents={cents} currency={receiveCurrency} trailingCurrencyCode />
        </strong>
        .
      </Text>
    );
  }
};

export default DraftPaymentExchangeRateTooltipContent;
