import { FC } from "react";
import cn from "utils/tailwind/cn";

import Shimmer from "../ShimmerV2";

// NB(alex): We should co-locate this with the `HighbeamCard` component but storybook crashes because `VGSShow` is not defined.
export const highbeamCardSizeClasses =
  "w-[19.5rem] h-[12.875rem] tablet:w-[23.5rem] tablet:h-[14.5rem]";

const CardShimmer: FC<{ className?: string }> = ({ className }) => {
  return <Shimmer className={cn(highbeamCardSizeClasses, className)} />;
};

export default CardShimmer;
