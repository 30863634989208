import { Address, Card } from "@highbeam/unit-node-sdk";
import BankAccountRep from "reps/BankAccountRep";
import getBankAccountDisplayName from "resources/bank-accounts/utils/getBankAccountDisplayName";
import { checkIsBusinessCard } from "resources/cards/utils/typeguards";
import { CardTags } from "utils/types/transactionsTypes";

export const getCardName = (card: Card) => (card.attributes.tags as CardTags).name || "Card";

export const getCardAccount = (card: Card, relatedAccounts: BankAccountRep.Complete[]) => {
  const accountId = card.relationships.account.data.id;
  return relatedAccounts.find(
    (account) => account.unitCoDepositAccountId === accountId
  ) as BankAccountRep.Complete;
};

export const getCardholderName = (card: Card): string | null => {
  if (checkIsBusinessCard(card)) {
    return `${card.attributes.fullName.first} ${card.attributes.fullName.last}`.trim();
  }
  return null;
};

export const getCardholderFirstName = (card: Card): string | null => {
  if (checkIsBusinessCard(card)) {
    return card.attributes.fullName.first.trim();
  }
  return null;
};

export const getCardholderLastName = (card: Card): string | null => {
  if (checkIsBusinessCard(card)) {
    return card.attributes.fullName.last.trim();
  }
  return null;
};

export const getCardBillingAddress = (card: Card): Address | null => {
  if (checkIsBusinessCard(card)) {
    return card.attributes.address as Address;
  }
  return null;
};

export const getCardAccountName = (card: Card, relatedAccounts: BankAccountRep.Complete[]) => {
  const account = getCardAccount(card, relatedAccounts);
  return getBankAccountDisplayName(account);
};

export const isCardLocked = (card: Card) => {
  return card.attributes.status === "Frozen";
};

export const getBaseVgsCss = ({
  monospace = false,
  numeric = false,
}: {
  monospace?: boolean;
  numeric?: boolean;
} = {}) => {
  const result: any = {
    fontSize: "14px",
    letterSpacing: "0.125px",
    "-webkit-font-smoothing": "antialiased",
    display: "flex",
    alignItems: "center",
  };

  if (monospace) {
    result.fontFamily = "monospace";
  } else {
    result["@font-face"] = {
      "font-family": "Satoshi",
      src: 'local("Satoshi"), url("../assets/fonts/Satoshi-Variable.woff2") format("woff2"), url("../assets/fonts/Satoshi-Variable.woff") format("woff"), url("../assets/fonts/Satoshi-Variable.ttf") format("truetype")',
      "font-weight": "300 900",
      "font-display": "swap",
      "font-style": "normal",
    };
    result.fontFamily = "Satoshi, sans-serif";
  }

  if (numeric) {
    result.fontFeatureSettings = '"tnum", "lnum"';
  }

  return result;
};
