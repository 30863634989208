import { atom } from "recoil";
import BankAccountRep from "reps/BankAccountRep";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

type CloseBankAccountModalParams = {
  bankAccount: BankAccountRep.Complete;
};

const closeBankAccountModalState = atom<DialogState<CloseBankAccountModalParams>>({
  key: "bankAccounts/closeBankAccountModalState",
  default: { isOpen: false },
});

export default closeBankAccountModalState;

export const useCloseBankAccountModal = () => {
  return useModal<CloseBankAccountModalParams>(closeBankAccountModalState);
};
