import { forwardRef } from "react";

// Use this util if you need to preserve your generic types! `forwardRef` does not preserve typescript generics by default. https://www.totaltypescript.com/forwardref-with-generic-components

const forwardRefPreserveGenerics = <T, P>(
  render: (props: P, ref: React.Ref<T>) => React.ReactElement
): ((props: P & React.RefAttributes<T>) => React.ReactElement) => {
  return forwardRef(render) as any;
};

export default forwardRefPreserveGenerics;
