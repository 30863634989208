import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";
import { bankingDay, formatDate } from "utils/date";
import { HighbeamFailedPaymentTransaction } from "utils/types/transactionsTypes";

import Reason, { Status, StatusLabel, rejectedStatusLabel } from "./Reason";

type Props = {
  transaction: HighbeamFailedPaymentTransaction;
};

const TransactionInfoFlexpaneReasonSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>Reason</Heading3>
    <Reason status={getStatus(transaction)} />
  </Section>
);

export default TransactionInfoFlexpaneReasonSection;

function getStatus(transaction: HighbeamFailedPaymentTransaction): Status {
  return {
    label: getStatusLabel(transaction) ?? rejectedStatusLabel,
    description: transaction.summary,
    date: formatDate(bankingDay().toDate()),
  };
}

function getStatusLabel(transaction: HighbeamFailedPaymentTransaction): StatusLabel | undefined {
  switch (transaction.paymentStatus) {
    case "Rejected":
      return rejectedStatusLabel;
    default:
      return undefined;
  }
}
