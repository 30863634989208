import { CaretDown, CaretUp } from "@phosphor-icons/react";
import classNames from "classnames";
import { DropdownIndicatorProps, components, GroupBase } from "react-select";

import styles from "./DropdownIndicator.module.scss";

type Props<TOption> = DropdownIndicatorProps<TOption, false, GroupBase<TOption>>;

const DropdownIndicator = <TOption,>({ selectProps, isDisabled, ...props }: Props<TOption>) => {
  const Caret = selectProps.menuIsOpen ? CaretUp : CaretDown;

  return (
    <components.DropdownIndicator selectProps={selectProps} isDisabled={isDisabled} {...props}>
      <Caret size={16} className={classNames(styles.caret, isDisabled && styles.disabled)} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
