import { TrendUp } from "@phosphor-icons/react";
import { captureException } from "@sentry/react";
import classNames from "classnames";
import dayjs from "dayjs";
import equal from "fast-deep-equal";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import CreditComparisonSnapshotRep from "reps/CreditComparisonSnapshotRep";
import offerInputsState from "state/compareCreditOffers/inputs/offerInputs/offerInputs";
import revenueProjectionInputsState from "state/compareCreditOffers/inputs/revenueProjectionInputs";
import isRevenueProjectionsFormValidState from "state/compareCreditOffers/isRevenueProjectionsFormValid";
import { isRevenueProjectionStepNavigableState } from "state/compareCreditOffers/isStepNavigable";
import sessionGuidState from "state/compareCreditOffers/sessionGuid";
import snapshotState from "state/compareCreditOffers/snapshot";
import colors from "styles/colors";
import { notify } from "ui/feedback/Toast";
import CurrencyInput from "ui/inputs/CurrencyInput";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import highlightInputTextOnFocus from "utils/highlightInputTextOnFocus";

import FromControls from "../components/FromControls";
import { CREDIT_OFFER_PARAM_PREFIX } from "../constants";
import filterOfferInputsWithoutProviderName from "../utils/filterOfferInputsWithoutProviderName";
import getFeeAmountByInputType from "../utils/getFeeAmountByInputType";

import styles from "./Form.module.scss";

const RevenueProjectionForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const snapshot = useRecoilValue(snapshotState);

  const isRevenueProjectionStepNavigable = useRecoilValue(isRevenueProjectionStepNavigableState);

  const [revenueProjectionInputs, setRevenueProjectionInputs] = useRecoilState(
    revenueProjectionInputsState
  );

  const replicateFirstInputIfOthersAreBlankOnBlur = () => {
    const existingProjection = revenueProjectionInputs.find(
      (amount, i) => i !== 0 && Number(amount) > 0
    );
    // Do nothing if at least one other input already has a value
    if (existingProjection) return;
    const firstInputValue = revenueProjectionInputs[0];
    setRevenueProjectionInputs(revenueProjectionInputs.map(() => firstInputValue));
  };

  const navigate = useNavigate();

  const isRevenueProjectionsFormValid = useRecoilValue(isRevenueProjectionsFormValidState);
  const isSubmitDisabled = !isRevenueProjectionsFormValid;

  const offerInputs = useRecoilValue(offerInputsState);
  const highbeamApi = useHighbeamApi({ authMode: "maybeAuthenticated" });
  const sessionGuid = useRecoilValue(sessionGuidState);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const now = new Date();

      const prevSnapshotResponses = {
        offers: snapshot?.offers,
        revenueProjections: snapshot?.revenueProjections,
      };

      const body = {
        offers: offerInputs
          .filter((offerInput) => offerInput.providerName !== "")
          .map((offerInput) => {
            const offerCreator: CreditComparisonSnapshotRep.Offer = {
              providerName: offerInput.providerName as CreditComparisonSnapshotRep.ProviderName,
              loanAmount: Number(offerInput.loanAmount) * 100,
              fee: {
                amount: getFeeAmountByInputType(
                  offerInput.loanAmount || 0,
                  offerInput.fee || 0,
                  offerInput.feeInputType
                ),
                type: "FlatFee",
              },
              repaymentOption: {
                type: "CollectionPercentage",
                remittanceRate: Number(offerInput.remittanceRate),
                weeklyCap:
                  offerInput.weeklyCap === null ? null : Number(offerInput.weeklyCap) * 100,
              },
            };
            return offerCreator;
          }),
        revenueProjections: revenueProjectionInputs.map((amount, index) => {
          const month = dayjs(now).add(index, "months").startOf("month");
          return {
            start: month.format("YYYY-MM-DD"),
            end: month.endOf("month").format("YYYY-MM-DD"),
            amount: Number(amount),
          };
        }),
      };

      // Only submit form if the responses have changed
      const isBodySameAsPrev = equal(prevSnapshotResponses, body);
      if (isBodySameAsPrev) {
        return navigate(`${CREDIT_COMPARISON_PATH}/results/${snapshot?.slug}`);
      }

      const finalSnapshot = await highbeamApi.creditComparison.createCreditComparisonSnapshot(
        sessionGuid,
        body
      );

      if (finalSnapshot?.slug) {
        navigate(`${CREDIT_COMPARISON_PATH}/results/${finalSnapshot.slug}`);
      } else {
        notify("info", "Something went wrong! Please try again.");
        captureException(new Error("No snapshot slug returned from API"));
      }
    } catch {
      notify("info", "Something went wrong! Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (!isRevenueProjectionStepNavigable) {
    const offerInputsWithProviderName = filterOfferInputsWithoutProviderName(offerInputs);
    const offerIndex = offerInputsWithProviderName.length - 1;
    const to = `${CREDIT_COMPARISON_PATH}/${CREDIT_OFFER_PARAM_PREFIX}${offerIndex}`;
    return <Navigate to={to} />;
  }
  return (
    <form className={classNames(styles.form, styles.revenueProjection)} onSubmit={handleSubmit}>
      <div className={styles.titleIcon}>
        <TrendUp size={20} />
      </div>
      <Heading2 className={styles.heading}>Revenue projection</Heading2>
      <Text color={colors.grey[400]} size={14} className={styles.desc}>
        Enter in your revenue projects for the next six months. The more accurate your projections,
        the better we can calculate an estimated repayment period and APR.
      </Text>

      {revenueProjectionInputs.map((value, index) => (
        <CurrencyInput
          key={index}
          allowDecimals={false}
          prefixValue="$"
          onFocus={(e) => {
            const didReplicateValue = revenueProjectionInputs[0] === value;
            if (didReplicateValue) {
              highlightInputTextOnFocus(e);
            }
          }}
          label={dayjs()
            .add(index + 1, "month")
            .format("MMMM YYYY")}
          value={value}
          onChange={(val) => {
            setRevenueProjectionInputs((prev) => {
              const newProjections = [...prev];
              newProjections[index] = val;
              return newProjections;
            });
          }}
          className={styles.textInput}
          onBlur={index === 0 ? replicateFirstInputIfOthersAreBlankOnBlur : undefined}
          autoFocus={index === 0}
        />
      ))}

      <FromControls isLoading={isLoading} isSubmitDisabled={isSubmitDisabled} />
    </form>
  );
};

export default RevenueProjectionForm;
