import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import BankAccountBalanceMoneyAmountByBankAccountGuid from "resources/bank-accounts/components/BankAccountBalanceMoneyAmountByBankAccountGuid";
import { BankAccountBarByGuid } from "resources/bank-accounts/components/BankAccountBar";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import HighlightItemV2 from "ui/data-display/HighlightItemV2";
import { Span } from "ui/typography";

type Props = {
  capitalAccount: CapitalAccountRep.ChargeCardOnlyComplete;
  className?: string;
};

const ChargeCardAutoPayBankAccountHighlightItem: FC<Props> = ({ capitalAccount, className }) => {
  const primaryAccount = usePrimaryBankAccount({ required: true });

  return (
    <HighlightItemV2 className={className}>
      <HighlightItemV2.Heading>Auto-pay account</HighlightItemV2.Heading>

      <HighlightItemV2.Body>
        <BankAccountBarByGuid
          bankAccountGuid={capitalAccount.details.repayment.bankAccountGuid ?? primaryAccount.guid}
          className="overflow-hidden overflow-ellipsis"
        />
      </HighlightItemV2.Body>

      <HighlightItemV2.Footer>
        <Span className="text-xs text-grey-600">
          Balance:{" "}
          <BankAccountBalanceMoneyAmountByBankAccountGuid
            bankAccountGuid={
              capitalAccount.details.repayment.bankAccountGuid ?? primaryAccount.guid
            }
            size={12}
            weight="regular"
            centsTextWeight="regular"
          />
        </Span>
      </HighlightItemV2.Footer>
    </HighlightItemV2>
  );
};

export default ChargeCardAutoPayBankAccountHighlightItem;

// Wrappers

type ChargeCardAutoPayBankAccountHighlightItemByCapitalAccountGuidProps = {
  capitalAccount: CapitalAccountRep.ChargeCardOnlyComplete;
  className?: string;
};

export const ChargeCardAutoPayBankAccountHighlightItemByCapitalAccountGuid: FC<
  ChargeCardAutoPayBankAccountHighlightItemByCapitalAccountGuidProps
> = ({ capitalAccount, className }) => {
  return (
    <ChargeCardAutoPayBankAccountHighlightItem
      capitalAccount={capitalAccount}
      className={className}
    />
  );
};
