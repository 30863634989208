import { FC } from "react";
import Address, { AddressProps } from "ui/data-display/Address";
import MetadataList from "ui/data-display/MetadataList";

type Props = {
  description?: string;
  bicSwift?: string;
  iban?: string;
  accountNumber?: string;
  bankCode?: string;
  branchCode?: string;
  bsbCode?: string;
  clabe?: string;
  cnaps?: string;
  ifsc?: string;
  sortCode?: string;
  payeeType?: string;
  address?: AddressProps;
};

const InternationalWiresMetadataListItems: FC<Props> = ({
  description,
  bicSwift,
  iban,
  accountNumber,
  bankCode,
  branchCode,
  bsbCode,
  clabe,
  cnaps,
  ifsc,
  sortCode,
  payeeType,
  address,
}) => {
  return (
    <>
      {description && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{description}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {bicSwift && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>BIC Swift</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{bicSwift}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {iban && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>IBAN</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{iban}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {accountNumber && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{accountNumber}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {bankCode && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Bank code</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{bankCode}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {branchCode && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Branch code</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{branchCode}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {bsbCode && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Bank state branch code</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{bsbCode}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {clabe && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>CLABE</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{clabe}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {cnaps && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>CNAPS code</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{cnaps}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {ifsc && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>IFSC</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{ifsc}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {sortCode && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Sort code</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{sortCode}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {payeeType && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Payee type</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{payeeType}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {address && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Address</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            <Address
              address={address}
              size={14} // TODO(alex): This should inherit font size.
            />
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}
    </>
  );
};

export default InternationalWiresMetadataListItems;
