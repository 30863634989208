import { ArrowDownRight, ArrowUpRight } from "@phosphor-icons/react";
import { FC } from "react";
import Tabs from "ui/navigation/Tabs";

import { CASH_FLOW_TABS, CashFlowTab, useCashFlowContext } from "../../providers/CashFlowProvider";

const CashFlowTabLabel: FC<{ tab: CashFlowTab }> = ({ tab }) => {
  switch (tab) {
    case "money-in":
      return (
        <>
          <ArrowDownRight size={24} /> Money in
        </>
      );
    case "money-out":
      return (
        <>
          <ArrowUpRight size={24} /> Money out
        </>
      );
  }
};

const CashFlowTabs = () => {
  const { activeTab, setActiveTab } = useCashFlowContext();

  return (
    <Tabs
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      tabs={CASH_FLOW_TABS.map((value) => ({ id: value, label: <CashFlowTabLabel tab={value} /> }))}
    />
  );
};

export default CashFlowTabs;
