import classNames from "classnames";
import { getIcon } from "components/Accounts/AccountLabel";
import styles from "modules/plaid/components/connection-cards/PlaidConnectionCardContents/PlaidConnectionCardContents.module.scss";
import PlaidConnectionHealthIndicator from "modules/plaid/components/PlaidConnectionHealthIndicator";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import Shimmer from "ui/feedback/ShimmerV2";
import Text from "ui/typography/Text";

export type PlaidPendingConnection = {
  linkSuccessMetadata: PlaidLinkOnSuccessMetadata;
  modifyingExistingConnection?: PlaidConnectionRep.Complete;
};

type Props = {
  pendingConnection: PlaidPendingConnection;
};

/**
 * Placeholder loading content for a connection that succeeded in Link and is pending finalization with our backend.
 */
const PlaidPendingConnectionCardContents: React.FC<Props> = ({ pendingConnection }) => {
  const institution = pendingConnection.linkSuccessMetadata.institution!;
  return (
    <div className={styles.plaidConnectionCardContents}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          {getIcon(institution.institution_id)}
          <Text size={14} weight="bold">
            {institution.name}
          </Text>
        </div>
        {pendingConnection.modifyingExistingConnection?.isActive && (
          <PlaidConnectionHealthIndicator isActive />
        )}
      </div>
      <div className={classNames(styles.plaidAccountList)}>
        <div className={styles.plaidAccountRow}>
          <Shimmer className="h-5" />
        </div>
        <div className={styles.plaidAccountRow}>
          <Shimmer className="h-5" />
        </div>
      </div>
    </div>
  );
};

export default PlaidPendingConnectionCardContents;
