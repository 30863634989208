import useCreateNewBusiness from "modules/business/useCreateNewBusiness";
import { FC, useRef } from "react";
import ModalV4, { ModalRef } from "ui/overlay/ModalV4";

type Props = {
  onClose: () => void;
};

const StartNewBankingApplicationConfirmationModal: FC<Props> = ({ onClose }) => {
  const modalRef = useRef<ModalRef>(null);

  const { mutate: createNewBusiness, isPending } = useCreateNewBusiness({
    onSuccess: () => modalRef.current?.closeModalWithAnimation(),
  });

  return (
    <ModalV4 onClose={onClose} modalRef={modalRef}>
      <ModalV4.Header>Start banking application?</ModalV4.Header>
      <ModalV4.Body>
        To add a new business to Highbeam, you will need to complete a new banking application for
        that business.
      </ModalV4.Body>
      <ModalV4.Footer>
        <ModalV4.SubmitButton isLoading={isPending} onClick={() => createNewBusiness()}>
          Start application
        </ModalV4.SubmitButton>
        <ModalV4.CloseButton variant="ghost">Cancel</ModalV4.CloseButton>
      </ModalV4.Footer>
    </ModalV4>
  );
};

export default StartNewBankingApplicationConfirmationModal;
