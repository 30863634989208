import { Info, WarningCircle } from "@phosphor-icons/react";
import AddressAutocompleteDropdown from "modules/address-autocomplete/AddressAutocompleteDropdown";
import { FC } from "react";
import { Controller } from "react-hook-form";
import useBusinessAddressForm from "resources/business-details/hooks/useBusinessAddressForm";
import useUpdateBusinessAddressMutation from "resources/business-details/hooks/useUpdateBusinessAddressMutation";
import useIsAllowedToChangeAddressToCalifornia from "resources/capital/utils/useIsAllowedToChangeAddressToCalifornia";
import colors from "styles/colors";
import Banner from "ui/data-display/Banner";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import DropdownV2 from "ui/inputs/DropdownV2";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import Helper from "ui/inputs/Helper";
import TextInputV2 from "ui/inputs/TextInputV2";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph } from "ui/typography";
import Text from "ui/typography/Text";
import { stateOptions, states, US_COUNTRY_OPTION } from "utils/address";
import useIsAllowedToUpdateBusinessAddress from "utils/permissions/useIsAllowedToUpdateBusinessAddress";

const UnsupportedStateWarningTooltip: React.FC<{ label: string }> = ({ label }) => (
  <ItemWithTooltip
    tooltip={
      <Paragraph>
        We can’t currently accept business addresses located in {label} for customers who are using
        our capital products.
      </Paragraph>
    }
    asChild
  >
    <div className="flex items-center gap-x-2">
      <WarningCircle size={16} color={colors.yellow[600]} />
      <Text size={14} color={colors.yellow[700]}>
        {label}
      </Text>
    </div>
  </ItemWithTooltip>
);

const BusinessAddressForm: FC = () => {
  const form = useBusinessAddressForm();
  const { mutate, isPending } = useUpdateBusinessAddressMutation();

  const hasWritePermission = useIsAllowedToUpdateBusinessAddress();

  const isAllowedToChangeAddressToCalifornia = useIsAllowedToChangeAddressToCalifornia();

  const selectedState = form.watch("state");

  const disableSubmitForCalifornia =
    selectedState === "CA" && !isAllowedToChangeAddressToCalifornia;

  const handleSubmit = form.handleSubmit(async (data) => {
    return mutate({
      line1: data.street1,
      line2: data.street2 || null,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      country: data.country,
    });
  });

  return (
    <form className="flex flex-col gap-y-8" onSubmit={handleSubmit}>
      <FieldsetV2
        className="max-w-[51rem]" // NB(alex): Matches `Fieldset` v1, which we should sunset but is still used by the `Business details` section, but should be updated to `max-w-4xl`.
      >
        <FieldsetV2.Row columns={2}>
          <Controller
            control={form.control}
            name="autocompleteOption"
            render={({ field }) => {
              return (
                <AddressAutocompleteDropdown
                  hideClearIndicator
                  hideCaret
                  componentRestrictionsCountry="US"
                  onSelectAddress={(address) => {
                    form.reset(address);
                  }}
                  {...field}
                />
              );
            }}
          />

          <Controller
            name="street2"
            control={form.control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextInputV2
                  label="Address line 2"
                  showErrorOutline={Boolean(errors.street2)}
                  disabled={!hasWritePermission}
                  {...field}
                />
                {errors.street2 && (
                  <Helper icon={<Helper.Icon variant="error" />}>{errors.street2.message}</Helper>
                )}
              </div>
            )}
          />
        </FieldsetV2.Row>

        <FieldsetV2.Row columns={2}>
          <Controller
            name="city"
            control={form.control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextInputV2
                  label="City"
                  showErrorOutline={Boolean(errors.city)}
                  disabled={!hasWritePermission}
                  {...field}
                />
                {errors.city && <Helper iconVariant="error">{errors.city.message}</Helper>}
              </div>
            )}
          />
          <Controller
            name="state"
            control={form.control}
            render={({ field: { value, onChange, ...field }, formState: { errors } }) => {
              return (
                <div>
                  <DropdownV2
                    label="State"
                    options={stateOptions}
                    value={value ? { label: states[value], value: value } : null}
                    getOptionLabel={(option) => option.label}
                    onChange={(option) => {
                      onChange(option?.value ?? null);
                    }}
                    showErrorOutline={Boolean(errors.state)}
                    isOptionDisabled={(option) => {
                      return option.value === "CA" && !isAllowedToChangeAddressToCalifornia;
                    }}
                    renderOption={(optionProps) => {
                      return (
                        <DropdownV2.Option {...optionProps}>
                          {optionProps.isDisabled ? (
                            <UnsupportedStateWarningTooltip label={optionProps.label} />
                          ) : (
                            optionProps.label
                          )}
                        </DropdownV2.Option>
                      );
                    }}
                    components={{
                      SingleValue: (singleValueProps) => {
                        // NB(alex): Unfortunately we can't get the associated `isDisabled` from the `isOptionDisabled` prop the way we can in `renderOption`.
                        const isDisabled =
                          singleValueProps.data.value === "CA" &&
                          !isAllowedToChangeAddressToCalifornia;

                        return (
                          <DropdownV2.SingleValue {...singleValueProps}>
                            {isDisabled ? (
                              <UnsupportedStateWarningTooltip label={singleValueProps.data.label} />
                            ) : (
                              singleValueProps.data.label
                            )}
                          </DropdownV2.SingleValue>
                        );
                      },
                    }}
                    {...field}
                  />
                  {errors.state && <Helper iconVariant="error">{errors.state.message}</Helper>}
                </div>
              );
            }}
          />
        </FieldsetV2.Row>

        <FieldsetV2.Row columns={2}>
          <Controller
            name="postalCode"
            control={form.control}
            render={({ field, formState: { errors } }) => (
              <div>
                <TextInputV2
                  label="ZIP"
                  showErrorOutline={Boolean(errors.postalCode)}
                  disabled={!hasWritePermission}
                  {...field}
                />
                {errors.postalCode && (
                  <Helper iconVariant="error">{errors.postalCode.message}</Helper>
                )}
              </div>
            )}
          />

          <DropdownV2
            label="Country"
            disabled
            options={[US_COUNTRY_OPTION]}
            value={US_COUNTRY_OPTION}
          />
        </FieldsetV2.Row>

        <Banner
          color="blue"
          icon={<Info />}
          paragraph="We may email you for additional documentation to verify your new address."
        />
      </FieldsetV2>

      <div>
        <ButtonWithTooltip
          isLoading={isPending}
          type="submit"
          variant="primary"
          disabled={!hasWritePermission || !form.formState.isDirty || disableSubmitForCalifornia}
          tooltip={!hasWritePermission && "You don’t have permission to edit this information."}
        >
          Save changes
        </ButtonWithTooltip>
      </div>
    </form>
  );
};

export default BusinessAddressForm;
