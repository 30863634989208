import { captureMessage } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import getFileTypeFromBlob from "utils/blob/getFileTypeFromBlob";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { downloadBlob, downloadWith404Retry } from "utils/download";

type DownloadDocumentVariables = {
  documentGuid: string;
  documentName: string;
};

const useDownloadDocumentMutation = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useMutation({
    mutationFn: async (variables: DownloadDocumentVariables) => {
      const { documentGuid, documentName } = variables;
      const documentUrl = await highbeamApi.documents.getDocumentUrl(businessGuid, documentGuid);
      const blob = await downloadWith404Retry(documentUrl);
      const fileType = await getFileTypeFromBlob(blob);
      downloadBlob(`${documentName}.${fileType}`, window.URL.createObjectURL(blob));
    },
    onError: () => {
      notify(
        "error",
        "Unable to download your document. Please contact support if this problem persists."
      );
      captureMessage("Download agreement failed");
    },
  });
};

export default useDownloadDocumentMutation;
