import BankingInfo from "components/BankingInfo";
import BankAccountRep from "reps/BankAccountRep";
import ExternalLink from "ui/navigation/ExternalLink";
import Text from "ui/typography/Text";
import { getAccountType } from "utils/account";

import styles from "../PayoutInstructionModal.module.scss";

type Props = {
  accountToDisplay?: BankAccountRep.Complete;
};

const PayPalInstructions: React.FC<Props> = ({ accountToDisplay }) => {
  return (
    <>
      <div className={styles.step}>
        <div className={styles.stepNumber}>1</div>
        <Text size={14}>
          Go to Finance {">"} Wallet {">"} and click{" "}
          <ExternalLink href="https://www.paypal.com/businessmanage/account/money">
            Money
          </ExternalLink>
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>2</div>
        <Text size={14}>
          Click <strong>More Actions</strong>
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>3</div>
        <Text size={14}>
          Click{" "}
          <ExternalLink href="https://www.paypal.com/fundstransfer/onestep/at">
            Set up Auto Transfers
          </ExternalLink>
        </Text>
      </div>
      <div className={styles.step}>
        <div className={styles.stepNumber}>4</div>
        <Text size={14}>
          Click <strong>Link a bank</strong>, search <strong>"Highbeam"</strong> and manually enter
          your Highbeam bank account information:
        </Text>
      </div>
      {accountToDisplay && (
        <BankingInfo>
          <BankingInfo.Key>Routing number</BankingInfo.Key>
          <BankingInfo.Value textToCopy={accountToDisplay.routingNumber}>
            {accountToDisplay.routingNumber}
          </BankingInfo.Value>

          <BankingInfo.Key>Account number</BankingInfo.Key>
          <BankingInfo.Value textToCopy={accountToDisplay.accountNumber}>
            {accountToDisplay.accountNumber}
          </BankingInfo.Value>

          <BankingInfo.Key>Account type</BankingInfo.Key>
          <BankingInfo.Value>{getAccountType(accountToDisplay)}</BankingInfo.Value>
        </BankingInfo>
      )}

      <div className={styles.step}>
        <div className={styles.stepNumber}>5</div>
        <Text size={14}>
          Click <strong>Authorize and link</strong> to connect your Highbeam account
        </Text>
      </div>

      <div className={styles.step}>
        <div className={styles.stepNumber}>6</div>
        <Text size={14}>
          A popup will appear explaining how Paypal will send <strong>1-2 test deposits.</strong>{" "}
          Please read this information then close the popup
        </Text>
      </div>

      <div className={styles.step}>
        <div className={styles.stepNumber}>7</div>
        <Text size={14}>
          Back on the auto transfer page, set the frequency to <strong>daily</strong>
        </Text>
      </div>

      <div className={styles.step}>
        <div className={styles.stepNumber}>8</div>
        <Text size={14}>
          Under <strong>Amount</strong> select <strong>Entire balance</strong>
        </Text>
      </div>

      <div className={styles.step}>
        <div className={styles.stepNumber}>7</div>
        <Text size={14}>
          Click <strong>Turn on automatic transfer</strong> to finish your setup
        </Text>
      </div>
    </>
  );
};

export default PayPalInstructions;
