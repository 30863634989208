import { MinusCircle } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import Button from "ui/inputs/Button";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import Text from "ui/typography/Text";

import styles from "./ProviderDropdown.module.scss";

type Props = {
  label: string;
  setSelectedOption: (option: Option | null) => void;
  onClickRemove: () => void;
  value: string;
  isRemoveable: boolean;
};

export const providerOption: Option[] = [
  {
    label: (
      <div className={styles.itemRow}>
        <img className={styles.icon} src="/assets/merchants/shopify.svg" alt="shopify" />
        <Text size={14}>Shopify</Text>
      </div>
    ),
    value: "Shopify",
  },
  {
    label: (
      <div className={styles.itemRow}>
        <img className={styles.icon} src="/assets/merchants/wayflyer.svg" alt="wayflyer" />
        <Text size={14}>Wayflyer</Text>
      </div>
    ),
    value: "Wayflyer",
  },
  {
    label: (
      <div className={styles.itemRow}>
        <img className={styles.icon} src="/assets/merchants/clearco.svg" alt="clearco" />
        <Text size={14}>Clearco</Text>
      </div>
    ),
    value: "Clearco",
  },
  {
    label: (
      <div className={styles.itemRow}>
        <img className={styles.icon} src="/assets/merchants/genericbank-icon.svg" alt="custom" />
        <Text size={14}>Custom</Text>
      </div>
    ),
    value: "Custom",
  },
];

const ProviderDropdown: React.FC<Props> = ({
  label,
  setSelectedOption,
  onClickRemove,
  value,
  isRemoveable,
}) => {
  const [option, setOption] = useState<Option | null>(null);

  useEffect(() => {
    setOption(providerOption.find((obj) => obj.value === value) ?? null);
  }, [value]);

  return (
    <div className={styles.container}>
      <div className={styles.dropdownDiv}>
        <Dropdown
          hideCursor={Boolean(option)}
          value={option ?? null}
          label={label}
          options={providerOption}
          isClearable
          onChange={(selected) => {
            setOption(selected);
            setSelectedOption(selected);
          }}
        />
      </div>
      <Button
        variant="danger"
        paddingVariant="square"
        onClick={onClickRemove}
        disabled={!isRemoveable}
      >
        <MinusCircle />
      </Button>
    </div>
  );
};

export default ProviderDropdown;
