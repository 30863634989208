import classNames from "classnames";
import { ReactNode } from "react";

import styles from "../Teaser.module.scss";

type Props = {
  className?: string;
  children: ReactNode;
};

const TeaserMainSection: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(styles.text, className)}>{children}</div>
);

export default TeaserMainSection;
