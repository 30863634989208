import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import Flexpane from "ui/overlay/Flexpane";
import { HighbeamTransaction } from "utils/types/transactionsTypes";

type Props = {
  transaction: HighbeamTransaction;
};

const BaseTransactionInfoFlexpaneAmountSection: React.FC<Props> = ({ transaction }) => (
  <Flexpane.Section>
    <TransactionFlexpaneAmount
      cents={transaction.amountInCents}
      direction={transaction.direction === "Credit" ? "positive" : "negative"}
    />
  </Flexpane.Section>
);

export default BaseTransactionInfoFlexpaneAmountSection;
