import { FC } from "react";
import { usePayeeQuery } from "resources/payees/queries/usePayee";
import Shimmer from "ui/feedback/Shimmer";
import NotFoundCell from "ui/table/NotFoundCell";
import TextCell from "ui/table/TextCell";

type Props = {
  payeeGuid: string;
};

const PayeeNameCell: FC<Props> = ({ payeeGuid }) => {
  const { data: payee, isLoading } = usePayeeQuery(payeeGuid);

  if (isLoading) {
    return <Shimmer />;
  }

  if (payee) {
    return (
      <TextCell className="font-medium" darken>
        {payee.name}
      </TextCell>
    );
  }

  return <NotFoundCell />;
};

export default PayeeNameCell;
