import useFeatureFlag from "utils/customHooks/useFeatureFlag";

const DEFAULT_LARGE_AMOUNT_FLAG_VALUE = 1_000_000_00;

const useCapitalDrawdownApprovalLargeAmountInCentsFlagValue = (): number => {
  const largeAmountInCentsFlagValue = useFeatureFlag(
    "CAPITAL_DRAWDOWN_LARGE_AMOUNT_PENDING_IN_CENTS"
  );
  return typeof largeAmountInCentsFlagValue === "number"
    ? largeAmountInCentsFlagValue
    : DEFAULT_LARGE_AMOUNT_FLAG_VALUE;
};

export default useCapitalDrawdownApprovalLargeAmountInCentsFlagValue;
