import PayeeRep from "reps/PayeeRep";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";

import useDeletePayeeMutation from "../mutations/useDeletePayeeMutation";

import styles from "./RemovePayeeModal.module.scss";

type Props = {
  payee: PayeeRep.Complete;
  onClose: () => void;
};

const RemovePayeeModal: React.FC<Props> = ({ payee, onClose }) => {
  const { mutate: deletePayee, isPending } = useDeletePayeeMutation();

  return (
    <Modal
      title="Remove payee?"
      buttonText="Remove payee"
      buttonVariant="danger"
      isLoading={isPending}
      showCancel
      onClick={() => deletePayee(payee)}
      onClose={onClose}
      bodyClassName={styles.container}
    >
      <div className={styles.remove}>
        <div>
          <Text size={14} weight="bold">
            {payee.name}
          </Text>
        </div>
        <Divider />
        <div className={styles.bottom}>
          <Text size={14} color={colors.grey[600]}>
            Are you sure that you want to remove this payee?
          </Text>
          <Text size={12} color={colors.grey[600]}>
            You will have to add this payee and re-enter their account number(s) to send them money
            again. account.
          </Text>
        </div>
      </div>
    </Modal>
  );
};

export default RemovePayeeModal;
