import { useQuery } from "@tanstack/react-query";
import shopifyIconGrey from "assets/shopify-icon-grey.svg";
import shopifyIconLightning from "assets/shopify-icon-lightning.svg";
import { usePayoutInstructionsModal } from "pages/PayoutInstructionModal/state/payoutInstructionsModalState";
import { FC } from "react";
import useShopifyConnectionsQueryOptions from "resources/connected-stores/queries/useShopifyConnectionsQueryOptions";
import Button from "ui/inputs/Button";
import Tooltip from "ui/overlay/Tooltip";
import cn from "utils/tailwind/cn";

const InactiveTooltipContent = () => {
  const { open: openPayoutInstructionsModal } = usePayoutInstructionsModal();

  return (
    <div className="flex flex-col items-start gap-y-2">
      Set up automatic payouts to fund your Highbeam account.
      <Button
        variant="tertiary"
        size="sm"
        onClick={() => openPayoutInstructionsModal({ activeTab: "Shopify" })}
      >
        Set up payouts
      </Button>
    </div>
  );
};

type Props = {
  className?: string;
};

const ShopifyPayoutsStatusIcon: FC<Props> = ({ className }) => {
  const { data: shopifyConnections } = useQuery(useShopifyConnectionsQueryOptions());

  if (!shopifyConnections || shopifyConnections.length === 0) {
    return null;
  }

  const hasActiveShopifyConnections = shopifyConnections.some((connection) => connection.isActive);

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <img
          onClick={(e) => e.stopPropagation()}
          className={cn("cursor-default", className)}
          src={hasActiveShopifyConnections ? shopifyIconLightning : shopifyIconGrey}
          alt="Shopify"
        />
      </Tooltip.Trigger>

      <Tooltip.Content className="max-w-72">
        {hasActiveShopifyConnections ? (
          <>Shopify payouts are active.</>
        ) : (
          <InactiveTooltipContent />
        )}
      </Tooltip.Content>
    </Tooltip>
  );
};

export default ShopifyPayoutsStatusIcon;
