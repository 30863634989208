namespace CreditApplicationRep {
  export const newCreditApplicationStates = ["New"] as const;

  export const inReviewCreditApplicationStates = [
    "Submitted",
    "Approved",
    "Rejected",
    "ApprovalProcessed",
  ] as const;

  export const otherCreditApplicationStates = [
    "OfferNotified",
    "OfferExpired",
    "OfferRejected",
    "OfferAccepted",
    "PayoutsConnected",
    "Activated",
    "RejectionNotified",
    "Canceled",
  ] as const;

  export enum CreditApplicationIndustry {
    Apparel = "Apparel",
    Beauty = "Beauty",
    Collectibles = "Collectibles",
    Electronics = "Electronics",
    FoodAndBeverage = "Food and beverage",
    Gifts = "Gifts",
    HealthAndNutrition = "Health and nutrition",
    HomeGoods = "Home goods",
    Jewelry = "Jewelry",
    ProfessionalServices = "Professional services",
    SportingGoods = "Sporting goods",
    Other = "Other",
  }

  export type NewCreditApplicationState = (typeof newCreditApplicationStates)[number];
  export type InReviewState = (typeof inReviewCreditApplicationStates)[number];
  export type OtherState = (typeof otherCreditApplicationStates)[number];

  export type State = NewCreditApplicationState | InReviewState | OtherState;

  export type SkipFinancialsRequirementReasonRep =
    | "FinancialsNotUpToDate"
    | "DoNotTrackFinancials"
    | "CombinedDocuments"
    | "Other";

  export type SkipInventoryRequirementReasonRep = "DoNotCarryInventory" | "Other";

  export enum BooksClosedAtType {
    Exactly = "Exactly",
    Before = "Before",
  }

  interface Offer {
    readonly initialLimit: number;
    readonly nextLimit: number;
    readonly goalLimit: number;
    readonly apr: number;
  }
  interface SkipFinancialsRequirementReason {
    readonly reason: SkipFinancialsRequirementReasonRep;
    readonly explanation?: string;
  }
  interface SkipInventoryRequirementReason {
    readonly reason: SkipInventoryRequirementReasonRep;
    readonly explanation?: string;
  }
  export interface AddressRep {
    readonly street?: string;
    readonly street2?: string | null;
    readonly city?: string;
    readonly state?: string;
    readonly postalCode?: string;
    readonly country: string;
  }
  export interface CopackerRep {
    readonly businessName: string;
    readonly address: AddressRep;
  }
  interface BaseBooksClosedAtOption {
    readonly type: BooksClosedAtType;
    readonly yearMonth: string;
  }
  interface BooksClosedExactYearMonthOption extends BaseBooksClosedAtOption {
    readonly type: BooksClosedAtType.Exactly;
  }
  interface BooksClosedBeforeYearMonthOption extends BaseBooksClosedAtOption {
    readonly type: BooksClosedAtType.Before;
  }

  type BooksClosedAtRep = BooksClosedExactYearMonthOption | BooksClosedBeforeYearMonthOption;

  interface UserProvidedDetails {
    readonly userNotes?: string;
    readonly requestedAmount?: number;
    readonly reasonForRequest?: string;
    readonly skipFinancialsRequirementReason?: SkipFinancialsRequirementReason;
    readonly skipInventoryRequirementReason?: SkipInventoryRequirementReason;
    readonly inventoryLeadTime?: number;
    readonly copackers?: CopackerRep[];
    readonly numberOfRetailLocations?: number;
    readonly industry?: CreditApplicationIndustry;
    readonly otherIndustry?: string;
    readonly securedLenders?: boolean;
    readonly securedLendersExplanation?: string;
    readonly orgStructure?: string;
    readonly singleEntityBusiness?: boolean;
    readonly firstSaleAt?: string;
    readonly booksClosedAt?: BooksClosedAtRep | null;
    readonly repaymentTermsPreference?: number | null;
  }

  export interface Complete {
    readonly guid: string;
    readonly state: State;
    readonly submittedAt: string | null;
    readonly offer: Offer | null;
    readonly userProvidedDetails: UserProvidedDetails | null;
  }

  export interface Updater {
    readonly userProvidedDetails?: UserProvidedDetails;
  }
}

export default CreditApplicationRep;
