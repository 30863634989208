import { FC, ReactNode } from "react";
import cn from "utils/tailwind/cn";

type Props = {
  withTopBorder?: boolean;
  className?: string;
  children: ReactNode;
};

const AccentCard: FC<Props> = ({ withTopBorder, className, children }) => (
  <div
    className={cn(
      "bg-accent-gradient-purple",
      "flex flex-col rounded-lg p-6",
      withTopBorder && "border-t-[3px] border-t-purple-500",
      className
    )}
  >
    {children}
  </div>
);

export default AccentCard;
