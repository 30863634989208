import { FC, ReactNode } from "react";
import Card from "ui/data-display/Card";
import cn from "utils/tailwind/cn";
import variants from "utils/ts/variants";

export type PlaidConnectionCardSize = "small" | "normal";

type Props = {
  size?: PlaidConnectionCardSize;
  children: ReactNode;
  className?: string;
};

const PlaidConnectionCard: FC<Props> = ({ size = "normal", children, className }) => {
  return (
    <Card
      className={cn(
        "flex min-h-80 w-full flex-col border border-grey-200",
        variants(size, {
          normal: "tablet:w-96",
          small: "tablet:w-80",
        }),
        className
      )}
      shadow="xs"
    >
      {children}
    </Card>
  );
};

export default PlaidConnectionCard;
