import BillLineItemRep from "reps/BillLineItemRep";
import { useRefreshBillLineItemsQuery } from "resources/bill-line-items/queries/useBillLineItems";
import billSyncQueryHooks from "resources/bill-syncs/queries/billSyncQueryHooks";
import shouldBillSyncBeDryRun from "resources/bill-syncs/utils/shouldBillSyncBeDryRun";
import useBill, { useRefreshBillQuery } from "resources/bills/queries/useBill";
import { useRefreshBillAuditLogsQuery } from "resources/bills/queries/useBillAuditLogs";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useUpdateBillLineItemMutation = (
  billLineItemId: string,
  billId: string,
  additionalOptions?: MutationAdditionalOptions<BillLineItemRep.Complete, BillLineItemRep.Updater>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshBillQuery = useRefreshBillQuery(billId);
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);
  const refreshBillAuditLogsQuery = useRefreshBillAuditLogsQuery(billId);
  const bill = useBill(billId, { required: true });
  const dryRun = shouldBillSyncBeDryRun(bill);
  const refreshBillSyncQuery = billSyncQueryHooks.useRefreshQuery({ billId, dryRun });

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => {
        return highbeamApi.billLineItem.update(billLineItemId, variables);
      },

      onSuccess: async () => {
        await Promise.all([
          refreshBillQuery(),
          refreshBillAuditLogsQuery(),
          refreshBillLineItemsQuery(),
          refreshBillSyncQuery(),
        ]);
      },
    },
    additionalOptions || {}
  );
};

export default useUpdateBillLineItemMutation;
