import { Card } from "@highbeam/unit-node-sdk";
import HighbeamCard from "components/HighbeamCard";
import React, { Suspense } from "react";

import styles from "./CardPreview.module.scss";

type Props = {
  card: Card;
};

const CardPreview: React.FC<Props> = ({ card }) => {
  return (
    <div className={styles.container}>
      <Suspense fallback={null}>
        <HighbeamCard card={card} showDetailsButton showStatus clickable={false} />
      </Suspense>
    </div>
  );
};

export default CardPreview;
