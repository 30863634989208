import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { ErrorBoundary } from "@sentry/react";
import ErrorText from "modules/error/components/ErrorText";
import React, { Fragment, ReactNode, Suspense } from "react";
import LoadingFallback from "ui/feedback/LoadingFallback";
import cn from "utils/tailwind/cn";

import styles from "./Flexpane.module.scss";
import FlexpaneHeader from "./FlexpaneHeader";
import FlexpaneSection, {
  FlexpaneSectionHeading,
  FlexpaneSectionShimmer,
  FlexpaneSectionSubheading,
} from "./FlexpaneSection";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  unmountChildrenWhenClosed?: boolean;
  disableInitialFocus?: boolean;
};

const Flexpane: React.FC<Props> = ({
  isOpen,
  onClose,
  unmountChildrenWhenClosed = true,
  children,
  disableInitialFocus,
}) => {
  // NB(alex): This is a hack that allows us to disable headlessui's annoying focus management. We just set the initial focus to the overlay's div, which does nothing.
  const disableInitialFocusRef = React.useRef<HTMLDivElement>(null);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={onClose}
        initialFocus={disableInitialFocus ? disableInitialFocusRef : undefined}
      >
        <TransitionChild
          as={Fragment}
          enter={styles.enterBackdrop}
          enterFrom={styles.enterFromBackdrop}
          enterTo={styles.enterToBackdrop}
          leave={styles.leaveBackdrop}
          leaveFrom={styles.leaveFromBackdrop}
          leaveTo={styles.leaveToBackdrop}
        >
          <DialogBackdrop
            className={cn("fixed left-0 top-0 h-full w-full overflow-hidden", styles.backdrop)}
            ref={disableInitialFocusRef}
            onClick={onClose}
          />
        </TransitionChild>

        <TransitionChild
          as={Fragment}
          enter={styles.enterFlexpane}
          enterFrom={styles.enterFromFlexpane}
          leave={styles.leaveFlexpane}
          leaveTo={styles.leaveToFlexpane}
        >
          <div className={styles.container}>
            <DialogPanel className="h-full overflow-y-auto pb-24">
              <ErrorBoundary
                fallback={({ error }) => (
                  <div className="flex flex-col gap-y-8 p-8">
                    <ErrorText error={error} />
                  </div>
                )}
              >
                <Suspense fallback={<LoadingFallback variant="dots-3" />}>
                  {unmountChildrenWhenClosed && !isOpen ? null : children}
                </Suspense>
              </ErrorBoundary>
            </DialogPanel>
          </div>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
};

export default Object.assign(Flexpane, {
  Header: FlexpaneHeader,
  Section: FlexpaneSection,
  SectionHeading: FlexpaneSectionHeading,
  SectionSubheading: FlexpaneSectionSubheading,
  SectionShimmer: FlexpaneSectionShimmer,
});
