import { Check } from "@phosphor-icons/react";
import hbLogo from "assets/highbeam-logo.svg";
import env from "env";
import { CAPITAL_APR_RANGE } from "pages/capital/constants";
import { useRecoilValue } from "recoil";
import { useIsCurrentBusinessMemberOnboarded } from "resources/business-members/queries/businessMemberQueryHooks";
import isAuthenticatedState from "state/auth/isAuthenticated";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import TextLink from "ui/navigation/TextLink";
import Text from "ui/typography/Text";

import CreditComparisonColumnLayout from "./CreditComparisonColumnLayout";
import styles from "./CreditComparisonTable.module.scss";

const HighbeamCreditComparisonColumn = () => {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const isOnboarded = useIsCurrentBusinessMemberOnboarded();
  const calendlyLink = new URL(env.CALENDLY_DEMO_LINK);

  const scheduleACall = () => {
    window.open(calendlyLink.href, "_blank");
  };

  const checkIcon = <Check size={14} color={colors.green[500]} />;

  return (
    <CreditComparisonColumnLayout
      className={styles.hbCol}
      rows={[
        <img key="hb-row-0" src={hbLogo} alt="counterparty icon" className={styles.icon} />,
        <>
          Revolving line of credit
          <Text color={colors.grey[400]} size={12}>
            Control how much you draw down and how much you pay back.
          </Text>
        </>,
        <>The faster you pay back, the lower the fee</>,
        <></>,
        <>{CAPITAL_APR_RANGE}</>,

        <>
          Configurable by you
          <Text color={colors.grey[400]} size={12}>
            Max 6 months
          </Text>
        </>,
        <>
          Configurable by you
          <Text color={colors.grey[400]} size={12}>
            Percent of your weekly sales
          </Text>
        </>,
        <>{checkIcon}</>,
        <>{checkIcon}</>,
        <>{checkIcon}</>,
        <>{checkIcon}</>,
        <>
          <TextLink
            className={styles.noUnderline}
            to={
              isOnboarded
                ? "/capital"
                : isAuthenticated
                  ? "/onboarding/bank-application"
                  : `${env.HIGHBEAM_MARKETING_SITE_URL}/capital`
            }
            external={!isOnboarded}
          >
            <Button className={styles.joinHighbeamButton} variant="secondary" size="sm">
              {isOnboarded ? "Return to Highbeam" : "Learn more"}
            </Button>
          </TextLink>

          <Button
            className={styles.scheduleCallButton}
            variant="primary"
            size="sm"
            onClick={scheduleACall}
          >
            Schedule a call
          </Button>
        </>,
      ]}
    />
  );
};

export default HighbeamCreditComparisonColumn;
