import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const PLAID_CONNECTIONS_QUERY_KEY = "plaid-connections";

const usePlaidConnectionsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [PLAID_CONNECTIONS_QUERY_KEY, { businessGuid }],
    queryFn: () => {
      return highbeamApi.plaid.getPlaidConnectionsByBusiness(businessGuid);
    },
  });
};

export default usePlaidConnectionsQueryOptions;
