import { DownloadSimple } from "@phosphor-icons/react";
import React from "react";
import BankAccountRep from "reps/BankAccountRep";
import MetadataList from "ui/data-display/MetadataList";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import ItemWithCopyTextTooltip from "ui/overlay/ItemWithCopyTextTooltip";
import Text from "ui/typography/Text";
import {
  getAccountType,
  getAccountPartnerBank,
  getPartnerBankAddress,
  getPartnerBankAddressCityState,
  maskAccountNumber,
} from "utils/account";

import styles from "./AccountInformation.module.scss";
import useDownloadBankVerificationLetterMutation from "./hooks/useDownloadBankVerificationLetterMutation";

type DomesticAccountInformationProps = {
  account: BankAccountRep.Complete;
  isAllowedToViewAccountNumbers: boolean;
};

const DomesticAccountInformation: React.FC<DomesticAccountInformationProps> = ({
  account,
  isAllowedToViewAccountNumbers,
}) => {
  const { mutate: downloadStatement, isPending: isDownloading } =
    useDownloadBankVerificationLetterMutation();

  return (
    <MetadataList>
      {account.highbeamType.showDetailsToUser && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            <ItemWithCopyTextTooltip textToCopy={account.routingNumber}>
              {account.routingNumber}
            </ItemWithCopyTextTooltip>
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      {account.highbeamType.showDetailsToUser && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {isAllowedToViewAccountNumbers ? (
              <ItemWithCopyTextTooltip textToCopy={account.accountNumber}>
                {account.accountNumber}
              </ItemWithCopyTextTooltip>
            ) : (
              maskAccountNumber(account.accountNumber)
            )}
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      <MetadataList.Item>
        <MetadataList.ItemLabel>Account type</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{getAccountType(account)}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Partner bank</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          <div>
            <Text>{getAccountPartnerBank(account)}</Text>
            <Text>{getPartnerBankAddress(account)}</Text>
            <Text>{getPartnerBankAddressCityState(account)}</Text>
          </div>
        </MetadataList.ItemValue>
      </MetadataList.Item>
      {account.highbeamType.showDetailsToUser && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Account verification letter</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            <ButtonWithTooltip
              variant="tertiary"
              size="xs"
              className={styles.letterButton}
              onClick={() => downloadStatement(account)}
              disabled={!isAllowedToViewAccountNumbers || isDownloading}
              tooltip={
                !isAllowedToViewAccountNumbers &&
                "You don’t have permission to download the bank verification letter."
              }
            >
              {isDownloading ? <AnimatedSpinner /> : <DownloadSimple size={14} />}
              Download PDF
            </ButtonWithTooltip>
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}
    </MetadataList>
  );
};

export default DomesticAccountInformation;
