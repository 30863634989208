import { CaretRight } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import { FC, useState } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import { ChargeCardAutoPayBankAccountHighlightItemByCapitalAccountGuid } from "resources/capital-accounts/components/ChargeCardAutoPayBankAccountHighlightItem";
import ChargeCardCapitalAccountBarChart from "resources/capital-accounts/components/ChargeCardCapitalAccountBarChart";
import { ChargeCardCapitalAccountBarChartLegendByCapitalAccountGuid } from "resources/capital-accounts/components/ChargeCardCapitalAccountBarChartLegend";
import { ChargeCardCapitalAccountMetadataListByCapitalAccountGuid } from "resources/capital-accounts/components/ChargeCardCapitalAccountMetadataList";
import NextAutoPaymentHighlightItem from "resources/capital-accounts/components/NextAutoPaymentHighlightItem";
import EditCapitalAccountAutoPayAccountModal from "resources/capital-accounts/dialogs/EditCapitalAccountAutoPayAccountModal";
import { useNextCapitalAutoPayAmount } from "resources/capital-accounts/queries/capitalRepaymentAmountsQueryHooks";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import Divider from "ui/data-display/Divider";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import VirtualButton from "ui/inputs/VirtualButton";

import CapitalAccountAssociatedCardsSection from "../../components/CapitalAccountAssociatedCardsSection";
import ChargeCardRepayments from "../../components/ChargeCardRepayments";
import CapitalAccountBanners from "../../sections/CapitalAccountBanners";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardViewV2: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const [
    isEditCapitalAccountAutoPayAccountModalOpen,
    setIsEditCapitalAccountAutoPayAccountModalOpen,
  ] = useState(false);

  const nextAutoPayAmount = useNextCapitalAutoPayAmount({
    capitalAccountGuid: capitalAccountSummaryWithChargeCard.guid,
    enabled: capitalAccountSummaryWithChargeCard.state === CapitalAccountRep.State.Active,
  });

  return (
    <>
      {isEditCapitalAccountAutoPayAccountModalOpen && (
        <EditCapitalAccountAutoPayAccountModal
          capitalAccountSummary={capitalAccountSummaryWithChargeCard}
          onClose={() => setIsEditCapitalAccountAutoPayAccountModalOpen(false)}
        />
      )}

      <CapitalAccountBanners capitalAccountSummary={capitalAccountSummaryWithChargeCard} />

      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummaryWithChargeCard} />

      <DashboardPage.Section className="grid grid-cols-1 gap-14 @4xl:grid-cols-2">
        <div className="flex-1">
          <div className="mb-1 flex justify-between">
            <DashboardPage.Section.HeaderHeading className="font-medium">
              Available
            </DashboardPage.Section.HeaderHeading>
          </div>

          <MoneyAmountFraction
            className="mb-6 mt-2"
            variant="headline"
            numeratorInCents={capitalAccountSummaryWithChargeCard.available}
            denominatorInCents={capitalAccountSummaryWithChargeCard.details.limit}
          />

          <ChargeCardCapitalAccountBarChart
            capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
            height={32}
          />

          <ChargeCardCapitalAccountBarChartLegendByCapitalAccountGuid
            capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
          />

          <VirtualButton
            className="mt-6 flex cursor-pointer rounded-lg border border-grey-200"
            onClick={() => setIsEditCapitalAccountAutoPayAccountModalOpen(true)}
          >
            <NextAutoPaymentHighlightItem
              className="px-6 py-4"
              nextAutoPayAmount={nextAutoPayAmount}
            />

            <Divider orientation="vertical" className="my-6" />

            <ChargeCardAutoPayBankAccountHighlightItemByCapitalAccountGuid
              capitalAccount={capitalAccountSummaryWithChargeCard}
              className="flex max-w-80 flex-col overflow-auto whitespace-nowrap px-6 py-4"
            />

            <CaretRight
              size={24}
              className="ml-auto mr-6 h-4 self-center text-grey-500"
              weight="light"
            />
          </VirtualButton>
        </div>

        <div className="flex-1">
          <DashboardPage.Section.HeaderHeading className="mb-1 font-medium">
            Details
          </DashboardPage.Section.HeaderHeading>

          <ChargeCardCapitalAccountMetadataListByCapitalAccountGuid
            capitalAccountGuid={capitalAccountSummaryWithChargeCard.guid}
            capitalAccountState={capitalAccountSummaryWithChargeCard.state}
          />
        </div>
      </DashboardPage.Section>

      <CapitalAccountAssociatedCardsSection
        capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
      />

      <DashboardPage.Section>
        <ChargeCardRepayments
          capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
        />
      </DashboardPage.Section>
    </>
  );
};

export default ChargeCardViewV2;
