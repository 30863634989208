import { useEffect, useState } from "react";

const useImportScript = (resourceUrl: string) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.async = true;
    script.onload = () => setHasLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl]);

  return hasLoaded;
};

export default useImportScript;
