import { StatementsListParams } from "@highbeam/unit-node-sdk";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const ROOT_UNIT_CO_STATEMENTS_QUERY_NAME = "unitCoStatements-root";

export const useRefreshAllUnitCoStatementsQueries = () => {
  return useRefreshQuery([ROOT_UNIT_CO_STATEMENTS_QUERY_NAME]);
};

const unitCoStatementsQueryHooks = makeQueryHooks({
  rootName: ROOT_UNIT_CO_STATEMENTS_QUERY_NAME,
  name: "unitCoStatements",
  useQueryVariables: (params: StatementsListParams) => {
    const bankAccountUnitIds = bankAccountsQueryHooks.useData({
      status: "all",
      select: (bankAccounts) =>
        bankAccounts.map((bankAccount) => bankAccount.unitCoDepositAccountId),
    });

    const defaults: Partial<StatementsListParams> = {
      sort: "-period",
      accountIds: bankAccountUnitIds,
    };

    return {
      ...defaults,
      ...params,
    };
  },
  useQueryFnMaker: (variables) => {
    const unitApi = useUnitApi();

    return () => {
      return unitApi.statements.list(variables);
    };
  },
});

export default unitCoStatementsQueryHooks;
