import env from "env";
import { find } from "lodash-es";
import { FC, useMemo } from "react";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import DropdownV2, { DropdownProps } from "ui/inputs/DropdownV2";

import { AddressSchema } from "../addressSchema";

export type AddressAutocompleteOption = {
  streetAddress: string;
  fullAddress: string;
  googleMapsPlaceId: string;
};

const convertGoogleMapsPredictionToAddressAutocompleteOption = (
  prediction: google.maps.places.AutocompletePrediction
): AddressAutocompleteOption => {
  return {
    streetAddress: prediction.structured_formatting.main_text,
    fullAddress: prediction.description,
    googleMapsPlaceId: prediction.place_id,
  };
};

type InheritedDropdownProps = Omit<
  DropdownProps<AddressAutocompleteOption>,
  "options" | "onChange" | "getOptionLabel" | "getOptionValue" | "components" | "value"
>;

type Props = InheritedDropdownProps & {
  onSelectAddress: (address: Partial<AddressSchema>) => void;
  componentRestrictionsCountry: string | null; // ISO 3166-1 Alpha-2
  value: AddressAutocompleteOption | null;
  onChange: (value: AddressAutocompleteOption | null) => void;
};

const AddressAutocompleteDropdown: FC<Props> = ({
  onSelectAddress,
  componentRestrictionsCountry,
  label = "Street address",
  value,
  onChange,
  onInputChange,
  ...props
}) => {
  const {
    placesService,
    placePredictions,
    isPlacePredictionsLoading,
    getPlacePredictions,
    refreshSessionToken,
  } = usePlacesAutocompleteService({
    apiKey: env.GOOGLE_MAPS_AND_PLACES_API_KEY,
    debounce: 200,
    sessionToken: true,
  });

  const options = useMemo(() => {
    return placePredictions.map(convertGoogleMapsPredictionToAddressAutocompleteOption);
  }, [placePredictions]);

  return (
    <DropdownV2
      options={options}
      value={value}
      isClearable
      label={label}
      getOptionLabel={(option) => option.fullAddress}
      getOptionValue={(option) => option.streetAddress}
      isLoading={isPlacePredictionsLoading}
      filterOption={() => {
        // NB(alex): This is ridiculous!! for some reason without doing this some options get filtered out.
        return true;
      }}
      components={{
        SingleValue: (props) => {
          return (
            <DropdownV2.SingleValue {...props}>{props.data.streetAddress}</DropdownV2.SingleValue>
          );
        },
      }}
      onInputChange={(value, actionMeta) => {
        getPlacePredictions({
          input: value,
          componentRestrictions: { country: componentRestrictionsCountry },
        });
        onInputChange?.(value, actionMeta);
      }}
      onChange={(autocompleteOption) => {
        onChange(autocompleteOption);

        if (autocompleteOption && placesService) {
          placesService.getDetails(
            {
              placeId: autocompleteOption.googleMapsPlaceId,
              fields: ["address_components"],
            },
            (details) => {
              if (!details) return;

              const city = find(details.address_components, (component) =>
                component.types.includes("locality")
              )?.long_name;

              const state = find(details.address_components, (component) =>
                component.types.includes("administrative_area_level_1")
              )?.short_name;

              // eslint-disable-next-line functional/no-let
              let postalCode = find(details.address_components, (component) =>
                component.types.includes("postal_code")
              )?.long_name;

              // We always want a 5-digit postal code for US addresses.
              if (componentRestrictionsCountry === "US") {
                postalCode = postalCode?.slice(0, 5);
              }

              const country = find(details.address_components, (component) =>
                component.types.includes("country")
              )?.short_name;

              onSelectAddress({
                autocompleteOption: autocompleteOption,
                street1: autocompleteOption.streetAddress,
                street2: undefined,
                city,
                state,
                postalCode,
                country,
              });
            }
          );
        }

        // NB(alex): I don't fully understand how this works, but this is what we do in `useGooglePlacesAutocomplete`.
        refreshSessionToken();
      }}
      {...props}
    />
  );
};

export default AddressAutocompleteDropdown;
