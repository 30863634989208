import { AchReceivedPayment } from "@highbeam/unit-node-sdk";

import { Counterparty } from "./types/counterparty";

export function getReceivedAchPaymentCounterparty(payment: AchReceivedPayment): Counterparty {
  return {
    name: payment.attributes.companyName || "-",
    routingNumber: payment.attributes.counterpartyRoutingNumber,
    accountNumber: payment.attributes.counterpartyAccountNumber,
  };
}
