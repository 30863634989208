import RutterConnectionRep from "reps/RutterConnectionRep";

import { ConnectedStoreType } from "../types";

const getConnectedStoreTypeFromRutterPlatform = (
  rutterPlatform: RutterConnectionRep.RutterPlatform
): ConnectedStoreType | undefined => {
  switch (rutterPlatform) {
    case "SHOPIFY":
      return "Shopify";
    case "AMAZON":
      return "Amazon";
    case "PAYPAL":
      return "PayPal";
    case "STRIPE":
      return "Stripe";
    default:
      return undefined;
  }
};

export default getConnectedStoreTypeFromRutterPlatform;
