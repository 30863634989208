import { FC } from "react";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import { Span } from "ui/typography";

// NB(alex): We should refactor this with `<BarChart>`, but it doesn't support this style yet.

type CardLimitBarProps = {
  current: number;
  total: number;
};

const CardLimitBar: FC<CardLimitBarProps> = ({ current, total }) => {
  const percentage = (current / total) * 100;

  return (
    <Span className="mt-6 flex items-center gap-x-4">
      <div className="h-2 w-full rounded bg-orange-200">
        <div className="h-2 rounded-l-full bg-orange-500" style={{ width: `${percentage}%` }} />
      </div>

      <Span className="whitespace-nowrap text-sm font-medium">
        <MoneyAmountFraction numeratorInCents={current} denominatorInCents={total} />
      </Span>
    </Span>
  );
};

export default CardLimitBar;
