import { ComponentProps, FC } from "react";
import cn from "utils/tailwind/cn";

const FieldsetLegend: FC<ComponentProps<"legend">> = ({
  className,
  children,
  ...legendAndTextProps
}) => {
  return (
    <legend className={cn("mb-6 text-sm font-bold", className)} {...legendAndTextProps}>
      {children}
    </legend>
  );
};

export default FieldsetLegend;
