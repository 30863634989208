import { Minus, Receipt } from "@phosphor-icons/react";
import { CardTransactionsTableTransaction } from "modules/card-transactions-table/queries/useCardTransactionsTableData";
import { FC } from "react";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";

import useCardDetailsPageCardTransactionsTablePaymentMetadata from "../../hooks/useCardDetailsPageCardTransactionsTablePaymentMetadata";
import CardTransactionsTableReceiptTooltip from "../CardTransactionsTableReceiptTooltip";

type Props = {
  transaction: CardTransactionsTableTransaction;
};

const CardTransactionsTableReceiptCell: FC<Props> = ({ transaction }) => {
  const paymentMetadataItem = useCardDetailsPageCardTransactionsTablePaymentMetadata(
    transaction.attributes.tags?.generalPaymentMetadataGuid ?? undefined
  );

  if (
    !paymentMetadataItem ||
    !paymentMetadataItem.invoiceGuid ||
    !paymentMetadataItem.invoiceName
  ) {
    return (
      <div>
        <Minus size={16} />
      </div>
    );
  }

  return (
    <div>
      <ItemWithTooltip
        tooltip={
          <CardTransactionsTableReceiptTooltip
            invoiceGuid={paymentMetadataItem.invoiceGuid}
            invoiceName={paymentMetadataItem.invoiceName}
          />
        }
        asChild
      >
        <Receipt size={24} />
      </ItemWithTooltip>
    </div>
  );
};

export default CardTransactionsTableReceiptCell;
