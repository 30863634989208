import React, { Ref } from "react";
import { useRecoilState } from "recoil";
import TextInput from "ui/inputs/TextInput";

import styles from "./BusinessSwitcherImpl.module.scss";
import BusinessSwitcherResults from "./BusinessSwitcherResults";
import BusinessSwitcherTopRow from "./BusinessSwitcherTopRow";
import superuserInputState from "./state/superuserInputState";

type Props = {
  inputRef?: Ref<HTMLInputElement>;
};

const BusinessSwitcherImpl: React.FC<Props> = ({ inputRef }) => {
  const [query, setQuery] = useRecoilState(superuserInputState);

  return (
    <div className={styles.container}>
      <BusinessSwitcherTopRow />
      <TextInput ref={inputRef} value={query} onChange={setQuery} />
      <BusinessSwitcherResults />
    </div>
  );
};

export default BusinessSwitcherImpl;
