export type EntityType = "Individual" | "Company";
export const EntityIndividual: EntityType = "Individual";
export const EntityCompany: EntityType = "Company";

export type Entity = {
  readonly entityType: EntityType;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly companyName?: string;
  readonly companyBankHolderName?: string;
};
