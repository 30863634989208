import { selectorFamily } from "recoil";

import feeState from "./inputs/offerInputs/fee";
import loanAmountState from "./inputs/offerInputs/loanAmount";
import remittanceRateState from "./inputs/offerInputs/remittanceRate";
import weeklyCapState from "./inputs/offerInputs/weeklyCap";

const isOfferFormValidState = selectorFamily<boolean, number>({
  key: "isOfferFormValidState",
  get:
    (offerIndex) =>
    ({ get }) =>
      Boolean(
        get(loanAmountState(offerIndex)) &&
          get(feeState(offerIndex)) &&
          get(remittanceRateState(offerIndex)) &&
          get(weeklyCapState(offerIndex)) !== "0"
      ),
});

export default isOfferFormValidState;
