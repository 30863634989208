import BusinessRep from "reps/BusinessRep";

export const NEW_BUSINESS_PLACEHOLDER_NAME = "New business";

type Params = {
  displayName: string | null;
  status: BusinessRep.Status;
};

const getBusinessSafeDisplayName = ({ displayName, status }: Params): string => {
  const applicationStatusSuffix =
    status === "PendingReview" ? (displayName ? "(in review)" : "(application in progress)") : "";
  return `${displayName || NEW_BUSINESS_PLACEHOLDER_NAME} ${applicationStatusSuffix}`;
};

export default getBusinessSafeDisplayName;
