import { AuthorizationQueryParams } from "@highbeam/unit-node-sdk";
import useBusinessUnitCoCustomerId from "resources/business/queries/useBusinessUnitCoCustomerId";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import { Simplify } from "type-fest";
import useQueryOptions from "utils/react-query/useQueryOptions";

type Params = Simplify<
  AuthorizationQueryParams & {
    customerId?: never; // Always use the customerId from the business state
  }
>;

const useUnitCoAuthorizationsQueryOptions = (params: Params) => {
  const unitApi = useUnitApi();
  const customerId = useBusinessUnitCoCustomerId({ required: true });

  return useQueryOptions({
    queryKey: ["unit-co-authorizations", customerId, params],
    queryFn: async () => {
      const response = await unitApi.authorizations.list({
        sort: "-createdAt",
        ...params,
        customerId: customerId,
      });

      return {
        authorizations: response.data,
        pagination: response.meta.pagination,
      };
    },
  });
};

export default useUnitCoAuthorizationsQueryOptions;
