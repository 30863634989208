import DotsLoader from "ui/feedback/DotsLoader";

import styles from "./PdfDisplayLoading.module.scss";

const PdfDisplayLoading = () => {
  return (
    <div className={styles.container}>
      <DotsLoader dots={3} />
    </div>
  );
};

export default PdfDisplayLoading;
