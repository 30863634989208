import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import classNames from "classnames";

import styles from "./StepHeader.module.scss";

type Props = {
  title: string;
  stepNumber: number;
  disableBackButton?: boolean;
  disableForwardButton?: boolean;
  handleBackButtonClick?: () => void;
  handleForwardButtonClick?: () => void;
};

const StepHeader: React.FC<Props> = ({
  title,
  stepNumber,
  disableBackButton,
  disableForwardButton,
  handleBackButtonClick,
  handleForwardButtonClick,
}) => (
  <header className={styles.container}>
    <div className={styles.titleContainer}>
      <div className={styles.stepNumber}>{stepNumber}</div>
      <h2 className={styles.title}>{title}</h2>
    </div>
    <div>
      <button
        disabled={disableBackButton}
        onClick={handleBackButtonClick}
        className={classNames(styles["button--back"], styles.button)}
      >
        <ArrowLeft size={20} />
      </button>
      <button
        disabled={disableForwardButton}
        onClick={handleForwardButtonClick}
        className={classNames(styles.button)}
      >
        <ArrowRight size={20} />
      </button>
    </div>
  </header>
);

export default StepHeader;
