// COPIED FROM MITEK EXAMPLE
export const autoCaptureHints: { [name: string]: string } = {
  MITEK_FIRST_MESSAGE: "Center check on a dark background",
  MITEK_ERROR_GLARE: "There is too much glare",
  MITEK_ERROR_FOUR_CORNER: "Scanning for check",
  MITEK_ERROR_TOO_DARK: "There is not enough light on the check",
  MITEK_ERROR_FOCUS: "Unable to focus on the check",
  CV_NO_BARCODE_FOUND: "Scanning for barcode",
  MITEK_ERROR_TOO_FAR: "The check is too far",
  MITEK_ERROR_TOO_CLOSE: "The check is too close",
  MITEK_ERROR_NOT_CENTERED: "The check is not centered",
  MITEK_ERROR_MIN_PADDING: "The check is too close",
  MITEK_ERROR_HORIZONTAL_FILL: "The check is too far",
};

export const uploadHints: { [name: string]: string } = {
  MITEK_ERROR_FOUR_CORNER:
    "We couldn't detect the corners of the check. Make sure all 4 corners are visible.",
  MITEK_ERROR_TOO_DARK: "The image is too dark.",
  MITEK_ERROR_FOCUS: "The image is too blurry.",
  MITEK_ERROR_GLARE: "The image has glare.",
  MITEK_ERROR_MIN_PADDING: "Move the camera further away from the check.",
  MITEK_ERROR_HORIZONTAL_FILL: "Move the camera closer to the check.",
  MITEK_ERROR_SKEW_ANGLE: "The check isn’t angled properly. Make sure it is positioned straight.",
  MITEK_ERROR_LOW_CONTRAST: "Center the check on a dark background.",
  MITEK_ERROR_BUSY_BACKGROUND: "The background is too busy. Please use a solid background.",
  IMAGE_SMALLER_THAN_MIN_SIZE: "The image resolution needs to be at least 640x480 pixels.",
  CORRUPT_IMAGE: "The image is corrupted.",
  DEFAULT_HINT: "Error processing image, please try camera mode.",
};
