import { Payment } from "@highbeam/unit-node-sdk";
import { FC } from "react";
import PaymentDetailsRep from "reps/PaymentDetailsRep";
import { useInternationalWirePaymentMetadata } from "resources/international-wire-payment-metadata/queries/useInternationalWirePaymentMetadata";
import { InternationalWireUnitCoPayment } from "resources/unit-co-payments/types";
import getIsInternationalWireUnitCoPayment from "resources/unit-co-payments/utils/getIsInternationalWireUnitCoPayment";
import Pill, { PillColor } from "ui/data-display/Pill";

type StatusLabel = {
  text: string;
  pillColor: PillColor;
};

const pending: StatusLabel = {
  text: "Pending",
  pillColor: "grey-light",
};

const clearing: StatusLabel = {
  text: "Clearing",
  pillColor: "grey-legacy",
};

const failed: StatusLabel = {
  text: "Failed",
  pillColor: "pink-dark",
};

const canceled: StatusLabel = {
  text: "Canceled",
  pillColor: "orange-legacy",
};

const completed: StatusLabel = {
  text: "Completed",
  pillColor: "green-light",
};

const getLabel = (payment: Payment): StatusLabel | undefined => {
  switch (payment.attributes.status) {
    case "Pending":
      return pending;
    case "Clearing":
      return clearing;
    case "Rejected":
    case "Returned":
      return failed;
    case "Canceled":
      return canceled;
    case "Sent":
      return completed;
    default:
      return undefined;
  }
};

const getLabelFromInternationalWirePayment = (
  paymentMetadata: PaymentDetailsRep.Complete
): StatusLabel | undefined => {
  switch (paymentMetadata.paymentStatus) {
    case "failed":
      return failed;
    case "completed":
      return completed;
    default:
      return pending;
  }
};

type Props = {
  payment: Payment;
};

const DomesticPaymentStatusPill: FC<Props> = ({ payment }) => {
  const label = getLabel(payment);

  if (!label) return null;

  return (
    <Pill bordered color={label.pillColor}>
      {label.text}
    </Pill>
  );
};

type InternationalPaymentStatusProps = {
  payment: InternationalWireUnitCoPayment;
};

const InternationalWirePaymentStatusPill: FC<InternationalPaymentStatusProps> = ({ payment }) => {
  const paymentMetadata = useInternationalWirePaymentMetadata({
    paymentMetadataGuid: payment.attributes.tags.paymentMetadataGuid,
  });

  if (!paymentMetadata) {
    return null;
  }
  const label = getLabelFromInternationalWirePayment(paymentMetadata);

  if (!label) return null;

  return (
    <Pill bordered color={label.pillColor}>
      {label.text}
    </Pill>
  );
};

const PaymentStatusPill: FC<Props> = ({ payment }) => {
  if (getIsInternationalWireUnitCoPayment(payment)) {
    return <InternationalWirePaymentStatusPill payment={payment} />;
  } else {
    return <DomesticPaymentStatusPill payment={payment} />;
  }
};

export default PaymentStatusPill;
