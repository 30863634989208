import env from "env";
import { Link } from "react-router-dom";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "../Referral.module.scss";

const ReferralFooter = () => (
  <div className={styles.promoTerms}>
    <Text size={12} color={colors.grey[500]}>
      * By clicking “Join Highbeam”, I agree to the{" "}
      <Link
        to={`${env.HIGHBEAM_MARKETING_SITE_URL}/legal/2024-bfcm-promotion`}
        target="_blank"
        rel="noreferrer"
      >
        <span className={styles.promoTermsLink}>promotion terms</span>
      </Link>
      .
    </Text>
  </div>
);

export default ReferralFooter;
