import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { UNIT_CO_CHECK_DEPOSITS_QUERY_KEY } from "./useUnitCoCheckDepostsQueryOptions";

type Params = {
  side: "front" | "back";
  checkDepositId: string;
};

const useUnitCoCheckImageQueryOptions = ({ side, checkDepositId }: Params) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: [UNIT_CO_CHECK_DEPOSITS_QUERY_KEY, { side, checkDepositId }],
    queryFn: async () => {
      const image = (await unitApi.checkDeposits.getImage(
        checkDepositId,
        side === "front"
      )) as unknown as Blob; // Unit SDK is not typed correctly
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(image);
      });
    },
  });
};

export const useUnitCoCheckImageQuery = (params: Params) => {
  return useQuery(useUnitCoCheckImageQueryOptions(params));
};

const useUnitCoCheckImage = (params: Params) => {
  return useSuspenseQuery(useUnitCoCheckImageQueryOptions(params)).data;
};

export default useUnitCoCheckImage;
