import DashboardPage from "layouts/DashboardPage";
import bankAccountsQueryHooks from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import ButtonLinkWithTooltip from "ui/inputs/ButtonLink/ButtonLinkWithTooltip";
import useIsAllowedToCreateBankAccounts from "utils/permissions/useIsAllowedToCreateBankAccounts";

import BankAccountsTable from "../../components/BankAccountsTable";

const ManageAccountsButton = () => {
  const isAllowedToCreateBankAccounts = useIsAllowedToCreateBankAccounts();

  return (
    <ButtonLinkWithTooltip
      to="/accounts/manage-accounts"
      tooltip={
        !isAllowedToCreateBankAccounts && "You don’t have permission to create bank accounts."
      }
      variant="tertiary"
      disabled={!isAllowedToCreateBankAccounts}
    >
      Manage accounts
    </ButtonLinkWithTooltip>
  );
};

const BankAccountsSection = () => {
  // NB(alex): Hack until we have empty states. Matches the query in `BankAccountsTable`.
  const bankAccounts = bankAccountsQueryHooks.useData({ status: "open" });

  if (bankAccounts.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Bank accounts</DashboardPage.Section.HeaderHeading>

        <DashboardPage.Section.HeaderEnd>
          <ManageAccountsButton />
        </DashboardPage.Section.HeaderEnd>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <BankAccountsTable />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default BankAccountsSection;
