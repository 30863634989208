import { ArrowsClockwise as ArrowsClockwiseIocn } from "@phosphor-icons/react";
import { FC } from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import { Span } from "ui/typography";
import cn from "utils/tailwind/cn";

import useBillSyncIsSyncing from "../../hooks/useBillSyncIsSyncing";
import useBillSyncQueryIfEnabled from "../../hooks/useBillSyncQueryIfEnabled";

type Props = {
  billId: string;
  className?: string;
};

const BillSyncStatus: FC<Props> = ({ billId, className }) => {
  const isSyncing = useBillSyncIsSyncing(billId);
  const { data: billSync, error: billSyncEndpointError } = useBillSyncQueryIfEnabled(billId);
  const errors = billSync?.errors ?? [];
  const hasErrors = Boolean(billSyncEndpointError) || errors.length > 0;

  return (
    <Span
      className={cn(
        "flex items-center gap-1 text-xs font-regular text-grey-600 tablet:text-sm",
        className
      )}
    >
      {billSync && !isSyncing && (
        <>
          <ArrowsClockwiseIocn
            className={cn("h-4 w-4 text-green-500", hasErrors && "text-grey-500")}
          />
          {hasErrors ? <>Not synced</> : <>Synced</>}
        </>
      )}

      {isSyncing && (
        <>
          <AnimatedSpinner icon={ArrowsClockwiseIocn} />
          Syncing
        </>
      )}
    </Span>
  );
};

export default BillSyncStatus;
