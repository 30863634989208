import { FC, ReactElement, ReactNode } from "react";
import colors from "styles/colors";
import Text, { TextProps } from "ui/typography/Text";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
  children: ReactNode;
};

const MetadataList: FC<Props> = ({ className, children }) => (
  <div className={cn("@container", className)}>{children}</div>
);

type MetadataListItemProps = {
  label?: ReactNode;
  hideBorder?: boolean;
  children: [ReactElement, ReactElement];
  className?: string;
};

const MetadataListItem: FC<MetadataListItemProps> = ({
  children: [label, value],
  hideBorder = false,
  className,
}) => {
  return (
    <div
      className={cn(
        "grid gap-x-4 @xs:grid-cols-2",
        "border-t border-t-grey-100 first-of-type:border-t-0",
        hideBorder && "border-t-0",
        className
      )}
    >
      {label}
      {value}
    </div>
  );
};

type MetadataListItemLabelProps = TextProps & {
  children: ReactNode;
};

const MetadataListItemLabel: FC<MetadataListItemLabelProps> = ({
  children,
  className,
  ...textProps
}) => {
  return (
    <Text
      as="div"
      size={14}
      color={colors.grey[600]}
      className={cn("flex items-center gap-1 text-nowrap", "pb-2 pt-4 @xs:py-4", className)}
      {...textProps}
    >
      {children}
    </Text>
  );
};

type MetadataListItemValueProps = TextProps & {
  children: ReactNode;
};

const MetadataListItemValue: FC<MetadataListItemValueProps> = ({
  children,
  className,
  ...textProps
}) => {
  return (
    <Text
      as="div"
      size={14}
      color={colors.grey[900]}
      weight="medium"
      className={cn(
        "flex items-center gap-1",
        "pb-4 @xs:justify-end @xs:py-4 @xs:text-right",
        className
      )}
      {...textProps}
    >
      {children}
    </Text>
  );
};

export default Object.assign(MetadataList, {
  Item: MetadataListItem,
  ItemLabel: MetadataListItemLabel,
  ItemValue: MetadataListItemValue,
});
