import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import classNames from "classnames";
import ActionItemDrawer from "components/ActionItemDrawer";
import Emoji from "components/Emoji";
import { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { useRecoilState, useRecoilValue } from "recoil";
import isGettingStartedSetupGuideOpenState from "resources/action-items/get-started/state/gettingStartedSetupGuide";
import { Milestone } from "resources/action-items/types";
import allActionItemsInteractedWith from "resources/action-items/utils/allActionItemsInteractedWith";
import actionItemsState from "state/actionItems/actionItems";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";
import useIsAllowedToCreateCards from "utils/permissions/useIsAllowedToCreateCards";

import styles from "./GetStartedSetupGuide.module.scss";

const GetStartedActionItemDrawer = () => {
  const actionItems = useRecoilValue(actionItemsState(Milestone.GetStarted));
  return <ActionItemDrawer title="Get the best out of Highbeam" actionItems={actionItems} />;
};

type Props = {
  buttonClassName?: string;
};

const GetStartedSetupGuide: React.FC<Props> = ({ buttonClassName }) => {
  const actionItems = useRecoilValue(actionItemsState(Milestone.GetStarted));
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles: popperClassNames, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [73, 10],
        },
      },
    ],
  });
  const [showGetStarted, setShowGetStarted] = useRecoilState(isGettingStartedSetupGuideOpenState);

  useEffect(() => {
    const handleClickOutsidePopover = (event: MouseEvent) => {
      if (
        showGetStarted &&
        referenceElement &&
        popperElement &&
        !referenceElement.contains(event.target as HTMLElement) &&
        !popperElement.contains(event.target as HTMLElement)
      ) {
        setShowGetStarted(false);
      }
    };

    if (showGetStarted) {
      document.addEventListener("click", handleClickOutsidePopover);
    }

    return () => {
      document.removeEventListener("click", handleClickOutsidePopover);
    };
  }, [showGetStarted, referenceElement, popperElement, setShowGetStarted]);

  const incompleteItemsCount = actionItems.reduce(
    (acc, item) => acc + (item.finishedActionItemRep?.state === "Complete" ? 0 : 1),
    0
  );

  useKeyboardEvent(
    (e) => Boolean(showGetStarted && e.key === "Escape"),
    () => setShowGetStarted(false),
    [showGetStarted]
  );

  const isAllowedToConnectStores = useIsAllowedToConnectStores();
  const isAllowedToCreateCards = useIsAllowedToCreateCards();
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();

  if (
    !isAllowedToConnectStores ||
    !isAllowedToCreateCards ||
    !isAllowedToConnectBankAccounts ||
    allActionItemsInteractedWith(actionItems)
  ) {
    return null;
  }

  return (
    <Popover>
      <PopoverButton
        ref={setReferenceElement}
        className={classNames(styles.button, buttonClassName)}
        as={Button}
        variant="tertiary"
        onClick={() => setShowGetStarted(!showGetStarted)}
      >
        <Emoji className={styles.waveEmoji} animation="wave">
          👋
        </Emoji>{" "}
        Get started
        {incompleteItemsCount > 0 && (
          <Text size={10} weight="bold" className={styles.incompleteActionItemsCount}>
            {incompleteItemsCount}
          </Text>
        )}
      </PopoverButton>
      {showGetStarted && (
        <PopoverPanel
          ref={setPopperElement}
          style={popperClassNames.popper}
          {...attributes.popper}
          static
        >
          <GetStartedActionItemDrawer />
        </PopoverPanel>
      )}
    </Popover>
  );
};

export default GetStartedSetupGuide;
