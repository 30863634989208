import UserRep from "reps/UserRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class UserApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(userGuid: string): Promise<UserRep.Complete | null> {
    const url = `/users/${userGuid}`;
    return await this.api.get<UserRep.Complete>(url);
  }
}
