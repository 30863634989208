import useIsAllowedToApprovePayments from "./useIsAllowedToApprovePayments";
import useIsAllowedToCreateDraftPayments from "./useIsAllowedToCreateDraftPayments";

// TODO(alex): Replace this with `useIsAllowedToViewPaymentApprovals`.
const useIsAllowedToViewSendMoney = () => {
  const isAllowedToCreatePaymentDrafts = useIsAllowedToCreateDraftPayments();
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  return isAllowedToCreatePaymentDrafts || isAllowedToApprovePayments;
};

export default useIsAllowedToViewSendMoney;
