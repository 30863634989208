import React from "react";
import { formatValue } from "react-currency-input-field";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import ChatMessageContent from "reps/chat/ChatMessageContent";
import colors from "styles/colors";

const CHART_COLORS = [
  colors.purple[500],
  colors.green[400],
  colors.orange[500],
  colors.yellow[500],
  colors.red[500],
  colors.blue[500],
  // Add more brand colors as needed
];

type Props = {
  section: ChatMessageContent.LineChart;
};

const ChatResponseLineChart: React.FC<Props> = ({ section }) => {
  const seriesLabels = [...new Set(section.series.flatMap((series) => series.name))];

  const data = section.xAxis.map((x) => ({
    label: x,
    series: Object.fromEntries(
      section.series.map((series) => [
        series.name,
        series.data.find((datum) => datum.x === x)?.y ?? 0,
      ])
    ),
  }));

  return (
    <ResponsiveContainer height={256}>
      <LineChart data={data}>
        <CartesianGrid stroke={colors.grey[200]} strokeDasharray="3 3" />
        <XAxis
          dataKey="label"
          tick={{ fontSize: 12, fill: colors.grey[900] }}
          tickMargin={8}
          stroke={colors.grey[500]}
        />
        <YAxis
          tick={{ fontSize: 12, fill: colors.grey[900] }}
          tickFormatter={(value) => `$${formatValue({ value: value.toString() })}`}
          tickMargin={4}
          stroke={colors.grey[500]}
        />
        <Legend wrapperStyle={{ fontSize: 12 }} />
        {seriesLabels.map((label, i) => (
          <Line
            key={label}
            dataKey={(obj) => obj.series[label]}
            name={label}
            stroke={CHART_COLORS[i % CHART_COLORS.length]}
            type="monotone"
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChatResponseLineChart;
