import {
  ArchiveTray as ArchiveTrayIcon,
  FilePlus as FilePlusIcon,
  ArrowsClockwise as ArrowsClockwiseIcon,
} from "@phosphor-icons/react";
import { FC } from "react";
import ProductOverviewList from "ui/data-display/ProductOverviewList";
import PaymentIcon from "ui/icons/PaymentIcon";
import { Heading3 } from "ui/typography";

const LaunchHowItWorks: FC = () => (
  <div className="rounded-lg border border-grey-200 px-10 py-8">
    <Heading3 className="mb-6">How it works</Heading3>

    <ProductOverviewList>
      <ProductOverviewList.Item icon={<ArchiveTrayIcon />}>
        Forward invoices to your custom Highbeam email address.
      </ProductOverviewList.Item>
      <ProductOverviewList.Item icon={<FilePlusIcon />}>
        Highbeam Intelligence extracts details from the invoices for your review.
      </ProductOverviewList.Item>
      <ProductOverviewList.Item icon={<PaymentIcon />}>
        Pay bills quickly and securely from your Highbeam accounts.
      </ProductOverviewList.Item>
      <ProductOverviewList.Item icon={<ArrowsClockwiseIcon />}>
        Sync bills to your accounting software automatically.
      </ProductOverviewList.Item>
    </ProductOverviewList>
  </div>
);

export default LaunchHowItWorks;
