import { zodResolver } from "@hookform/resolvers/zod";
import { LockSimple } from "@phosphor-icons/react";
import React, { Dispatch, useCallback, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import ChatMessageRequest from "reps/chat/ChatMessageRequest";
import useCreateChatMessageMutation from "resources/chat-messages/mutations/useCreateChatMessageMutation";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import TextArea from "ui/inputs/TextArea";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";
import { z } from "zod";

import { Action } from "..";

const createChatMessageFormSchema = z.object({
  content: z.string().nonempty(),
});

type CreateChatMessageFormInputs = z.infer<typeof createChatMessageFormSchema>;

const useCreateChatMessageForm = () =>
  useForm<CreateChatMessageFormInputs>({
    resolver: zodResolver(createChatMessageFormSchema),
    defaultValues: {
      content: "",
    } satisfies CreateChatMessageFormInputs,
  });

type Props = {
  className?: string;
  dispatch: Dispatch<Action>;
};

const ChatInputSection: React.FC<Props> = ({ className, dispatch }) => {
  const ref = useRef<HTMLFormElement>(null);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useCreateChatMessageForm();

  const { mutate, isPending } = useCreateChatMessageMutation({
    onMutate: (request) => {
      reset();
      dispatch({ type: "request", request });
    },
    onSuccess: (response) => {
      dispatch({ type: "response", response });
    },
    onError: () => {
      dispatch({
        type: "response",
        response: {
          content: [{ type: "markdown", markdown: "Something went wrong! Please try again." }],
        },
      });
    },
  });

  const onSubmit = useCallback(
    (data: Omit<ChatMessageRequest, "businessGuid" | "userGuid">) => {
      if (isPending) return;
      mutate({
        content: data.content,
      });
    },
    [isPending, mutate]
  );

  return (
    <div className={cn("flex flex-none flex-col items-center border-t border-grey-200", className)}>
      <form
        ref={ref}
        className="flex w-full max-w-2xl flex-col gap-4 p-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="content"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              placeholder="Ask any question about your business's finances."
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  ref.current!.requestSubmit();
                }
              }}
            />
          )}
        />
        <Button
          type="submit"
          className="w-full"
          variant="primary"
          disabled={!isValid}
          isLoading={isPending}
        >
          Submit
        </Button>
        <div className="flex flex-row items-center gap-2">
          <LockSimple size={16} color={colors.grey[600]} />
          <Text as="p" size={12} color={colors.grey[600]}>
            Your data is stored in Highbeam. We never allow third parties to train models on your
            data.
          </Text>
        </div>
      </form>
    </div>
  );
};

export default ChatInputSection;
