import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CapitalAccountApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getAll(businessGuid: string): Promise<CapitalAccountRep.Complete[]> {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/capital-accounts?${qp}`;
    return (await this.api.get<CapitalAccountRep.Complete[]>(url))!;
  }

  async update(
    businessGuid: string,
    capitalAccountGuid: string,
    update: CapitalAccountRep.Update
  ): Promise<CapitalAccountRep.Complete | null> {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/capital-accounts/${capitalAccountGuid}?${qp}`;
    return (await this.api.patch<CapitalAccountRep.Complete>(url, update))!;
  }

  async updateRepaymentAccount(
    businessGuid: string,
    capitalAccountGuid: string,
    update: CapitalAccountRep.UpdateRepaymentAccount
  ): Promise<CapitalAccountRep.Complete | null> {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/capital-accounts/${capitalAccountGuid}/details/repayment-account?${qp}`;
    return (await this.api.patch<CapitalAccountRep.Complete>(url, update))!;
  }

  async getSummary(
    businessGuid: string,
    capitalAccountGuid: string
  ): Promise<CapitalAccountSummaryRep.Complete> {
    const qp = new URLSearchParams({ businessGuid });
    const url = `/capital-accounts/${capitalAccountGuid}/summary?${qp}`;
    return (await this.api.get<CapitalAccountSummaryRep.Complete>(url))!;
  }
}
