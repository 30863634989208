import { CheckCircle, LinkBreak } from "@phosphor-icons/react";
import Text from "ui/typography/Text";

import styles from "./PlaidConnectionHealthIndicator.module.scss";

type Props = {
  isActive: boolean;
};

const PlaidConnectionHealthIndicator: React.FC<Props> = ({ isActive }) => (
  <div className={styles.indicator}>
    {isActive ? (
      <div className={styles.synced}>
        <CheckCircle size={16} className={styles.check} />
        <Text className={styles.text} size={14}>
          Connected
        </Text>
      </div>
    ) : (
      <div className={styles.reconnection}>
        <LinkBreak size={20} className={styles.disconnectedIcon} />
        <Text className={styles.text} size={14}>
          Disconnected
        </Text>
      </div>
    )}
  </div>
);

export default PlaidConnectionHealthIndicator;
