import { CalendarX, ArrowRight } from "@phosphor-icons/react";
import env from "env";
import { useState } from "react";
import UserReferralRep from "reps/ReferralLinkRep";
import useSignUpWithRedirect from "resources/auth/hooks/useSignUpWithRedirect";
import colors from "styles/colors";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import { Heading1, Paragraph, Span } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  referral: UserReferralRep;
  className?: string;
};

const ExpiredReferralCard: React.FC<Props> = ({ referral, className }) => {
  const [ctaPending, setCtaPending] = useState(false);

  const signUpWithRedirect = useSignUpWithRedirect();

  const acceptInvite = () => {
    setCtaPending(true);
    signUpWithRedirect({
      referralLinkSlug: referral.slug,
    });
  };

  return (
    <Card
      shadow="xs"
      className={cn("flex w-full max-w-2xl flex-col items-center justify-center p-8", className)}
    >
      <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-purple-100">
        <CalendarX size={24} color={colors.purple[500]} />
      </div>

      <Heading1 className="mb-1 mt-4">
        {referral.senderName ? (
          <>{referral.senderName}’s invitation has expired</>
        ) : (
          <>Invitation has expired</>
        )}
      </Heading1>

      <Paragraph className="max-w-xl text-center text-sm text-grey-600">
        This invitation has expired, but you can still sign up for Highbeam.
      </Paragraph>

      <Button variant="primary" className="mb-4 mt-6" onClick={acceptInvite} isLoading={ctaPending}>
        Join Highbeam
        <ArrowRight size={20} />
      </Button>

      <div className="mt-4 flex items-center gap-x-8">
        <Span className="whitespace-nowrap text-sm text-grey-600">Have questions?</Span>

        <ButtonLink
          paddingVariant="bare"
          className="w-full tablet:w-auto"
          to={env.CALENDLY_DEMO_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Book a demo
        </ButtonLink>
      </div>
    </Card>
  );
};

export default ExpiredReferralCard;
