import chargeCardAccountByUnitCoCreditAccountIdQueryHooks from "resources/charge-cards/queries/chargeCardAccountByUnitCoCreditAccountIdQueryHooks";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import { ROOT_CAPITAL_ACCOUNTS_QUERY_KEY } from "./capitalAccountsQueryHooks";

type Params = {
  unitCoCreditAccountId: string;
};

const capitalAccountSummaryByUnitCoCreditAccountIdQueryHooks = makeQueryHooks({
  rootName: ROOT_CAPITAL_ACCOUNTS_QUERY_KEY,
  name: "capitalAccountSummaryByUnitCoCreditAccountId",
  useQueryVariables: ({ unitCoCreditAccountId }: Params) => {
    const businessGuid = useBusinessGuid();
    return { businessGuid, unitCoCreditAccountId };
  },
  useQueryFnMaker: ({ businessGuid, unitCoCreditAccountId }) => {
    const { refetch: fetchChargeCardAccount } =
      chargeCardAccountByUnitCoCreditAccountIdQueryHooks.useQuery({
        unitCoCreditAccountId,
        enabled: false,
      });
    const highbeamApi = useHighbeamApi();

    return async () => {
      const { data: chargeCardAccount } = await fetchChargeCardAccount();

      if (!chargeCardAccount) {
        return null;
      }

      return highbeamApi.capitalAccount.getSummary(
        businessGuid,
        chargeCardAccount.capitalAccountGuid
      );
    };
  },
});

export default capitalAccountSummaryByUnitCoCreditAccountIdQueryHooks;
