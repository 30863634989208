import { Card } from "@highbeam/unit-node-sdk";
import IconWithTooltip from "components/common/IconWithTooltip";
import { capitalize } from "lodash-es";
import { FC } from "react";
import { BankAccountBarByUnitCoDepositAccountId } from "resources/bank-accounts/components/BankAccountBar";
import { CapitalAccountBarByUnitCoCreditAccountId } from "resources/capital-accounts/components/CapitalAccountBar";
import capitalAccountSummaryByUnitCoCreditAccountIdQueryHooks from "resources/capital-accounts/queries/capitalAccountSummaryByUnitCoCreditAccountIdQueryHooks";
import CardSpendLimitBarChart from "resources/card-spend-limit/components/CardSpendLimitBarChart";
import cardSpendLimitQueryHooks from "resources/card-spend-limit/queries/cardSpendLimitQueryHooks";
import getCopyForCardLimitTimeLeftUntilReset from "resources/card-spend-limit/utils/getCopyForCardLimitTimeLeftUntilReset";
import { getCardBillingAddress } from "resources/cards/utils";
import {
  checkIsCreditCard,
  checkIsDebitCard,
  CreditCard,
  DebitCard,
} from "resources/cards/utils/typeguards";
import Address, { convertUnitAddressToAddressComponentProp } from "ui/data-display/Address";
import MetadataList from "ui/data-display/MetadataList";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import useIsAllowedToReadCapitalAccounts from "utils/permissions/useIsAllowedToReadCapitalAccounts";
import useIsAllowedToSeeAssociatedBankAccount from "utils/permissions/useIsAllowedToSeeAssociatedBankAccount";

import SetSpendLimitButton from "./SetSpendLimitButton";

const AssociatedBankAccountField: FC<{ card: DebitCard }> = ({ card }) => {
  return (
    <MetadataList.Item>
      <MetadataList.ItemLabel>Associated account</MetadataList.ItemLabel>
      <MetadataList.ItemValue>
        <BankAccountBarByUnitCoDepositAccountId
          unitCoDepositAccountId={card.relationships.account.data.id}
        />
      </MetadataList.ItemValue>
    </MetadataList.Item>
  );
};

const CapitalAccountField: FC<{ card: CreditCard }> = ({ card }) => {
  return (
    <MetadataList.Item>
      <MetadataList.ItemLabel>Capital account</MetadataList.ItemLabel>
      <MetadataList.ItemValue>
        <CapitalAccountBarByUnitCoCreditAccountId
          unitCoCreditAccountId={card.relationships.account.data.id}
        />
      </MetadataList.ItemValue>
    </MetadataList.Item>
  );
};

const CapitalAccountLimitTooltipAdditionalContent: FC<{ card: CreditCard }> = ({ card }) => {
  const capitalAccountSummary =
    capitalAccountSummaryByUnitCoCreditAccountIdQueryHooks.useDataRequired({
      unitCoCreditAccountId: card.relationships.account.data.id,
    });

  return (
    <>
      This is different from your capital limit of{" "}
      <MoneyAmount cents={capitalAccountSummary.details.limit} />
    </>
  );
};

type Props = {
  card: Card;
};

const CardDetailsMetadata: FC<Props> = ({ card }) => {
  const billingAddress = getCardBillingAddress(card);
  const isCardArchived = card?.attributes.status === "ClosedByCustomer";
  const isAllowedToSeeAssociatedBankAccount = useIsAllowedToSeeAssociatedBankAccount();
  const spendLimit = cardSpendLimitQueryHooks.useData({ cardId: card.id });
  const isAllowedToReadCapitalAccounts = useIsAllowedToReadCapitalAccounts();

  return (
    <div className="row-gap-2 flex w-full max-w-md flex-1 flex-col tablet:ml-6 tablet-landscape:ml-9 large-desktop:ml-16 extra-large-desktop:ml-24">
      <MetadataList className="w-full">
        <MetadataList.Item>
          <MetadataList.ItemLabel>
            Billing address
            <IconWithTooltip
              tooltip={
                "Your business address is used as billing address for all cards. To your business address go to Settings."
              }
            />
          </MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {billingAddress && (
              <Address
                address={convertUnitAddressToAddressComponentProp(billingAddress)}
                size={14}
                weight="medium"
              />
            )}
          </MetadataList.ItemValue>
        </MetadataList.Item>

        {isAllowedToSeeAssociatedBankAccount && checkIsDebitCard(card) && (
          <AssociatedBankAccountField card={card} />
        )}

        {isAllowedToReadCapitalAccounts && checkIsCreditCard(card) && (
          <CapitalAccountField card={card} />
        )}

        {!isCardArchived && (
          <MetadataList.Item>
            {spendLimit ? (
              <MetadataList.ItemLabel>
                {capitalize(spendLimit.period)} spend limit
                <IconWithTooltip
                  tooltip={
                    <>
                      {getCopyForCardLimitTimeLeftUntilReset(spendLimit.period)}
                      {isAllowedToReadCapitalAccounts && checkIsCreditCard(card) && (
                        <>
                          {" "}
                          <CapitalAccountLimitTooltipAdditionalContent card={card} />
                        </>
                      )}
                    </>
                  }
                />
              </MetadataList.ItemLabel>
            ) : (
              <MetadataList.ItemLabel>
                No spend limit{" "}
                <IconWithTooltip
                  tooltip={
                    checkIsDebitCard(card)
                      ? "This card has no budget, and can spend up to 100% of the associated account balance."
                      : "This card has no budget, and can spend up to 100% of available usage."
                  }
                />
              </MetadataList.ItemLabel>
            )}
            <MetadataList.ItemValue>
              {spendLimit ? (
                <MoneyAmount cents={spendLimit.limitInCents} />
              ) : (
                <div className="flex justify-end">
                  <SetSpendLimitButton card={card} />
                </div>
              )}
            </MetadataList.ItemValue>
          </MetadataList.Item>
        )}
      </MetadataList>

      {!isCardArchived && spendLimit && <CardSpendLimitBarChart spendLimit={spendLimit} />}
    </div>
  );
};

export default CardDetailsMetadata;
