import ApSettingsRep from "reps/ApSettingsRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useCreateApSettingsMutation = (
  additionalOptions?: MutationAdditionalOptions<ApSettingsRep.Complete>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const { segmentTrack } = useSegment();

  return useMutationWithDefaults(
    {
      mutationFn: () =>
        highbeamApi.apSettings.create({
          businessGuid,
          accountingSyncEnabled: false,
          consolidateLineItems: true,
          closeBooksDate: null,
          billApprovalReminderAutoSendDay: null,
        }),
      onSuccess: async () => {
        segmentTrack(SEGMENT_EVENTS.BILL_PAY_SETTINGS_CREATED);
      },
    },
    additionalOptions || {}
  );
};

export default useCreateApSettingsMutation;
