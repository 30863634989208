import LegalFootnote from "components/LegalFootnote";

const CreditApplicationLegalFootnote = () => (
  <LegalFootnote>
    <LegalFootnote.DividerLine />

    <LegalFootnote.Paragraph>
      If your application for business credit is denied, you have the right to a written statement
      of the specific reasons for the denial. To obtain the statement, please contact Highbeam,
      Inc., 510 LaGuardia Pl, New York, NY 10012, (844) 426-6966 within 60 days from the date you
      are notified of our decision. We will send you a written statement of reasons for the denial
      within 30 days of receiving your request for the statement.
    </LegalFootnote.Paragraph>

    <br />
    <br />

    <LegalFootnote.Title>Notice</LegalFootnote.Title>

    <br />

    <LegalFootnote.Paragraph>
      The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against
      credit applicants on the basis of race, color, religion, national origin, sex, marital status,
      age (provided the applicant has the capacity to enter into a binding contract); because all or
      part of the applicant’s income derives from any public assistance program; or because the
      applicant has in good faith exercised any right under the Consumer Credit Protection Act. The
      Federal agency that administers compliance with this law concerning this creditor is Federal
      Trade Commission, Equal Credit Opportunity, Washington, DC 20580."
    </LegalFootnote.Paragraph>
  </LegalFootnote>
);

export default CreditApplicationLegalFootnote;
