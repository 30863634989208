import * as PopoverPrimitive from "@radix-ui/react-popover";
import { FC, ComponentPropsWithoutRef } from "react";
import useTopLayerPortalContainer from "ui/hooks/useTopLayerPortalContainer";
import cn from "utils/tailwind/cn";

const PopoverTrigger = PopoverPrimitive.Trigger;

type PopoverContentProps = ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>;

const PopoverContent: FC<PopoverContentProps> = ({
  children,
  className,
  align = "center",
  sideOffset = 4,
  collisionPadding = 16,
  ...props
}) => {
  const portalContainer = useTopLayerPortalContainer();

  return (
    <PopoverPrimitive.Portal container={portalContainer}>
      <PopoverPrimitive.Content
        align={align}
        sideOffset={sideOffset}
        collisionPadding={collisionPadding}
        className={cn(
          "z-50 w-72 rounded-md border border-grey-200 bg-white shadow-sm outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          className
        )}
        {...props}
      >
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  );
};

const Popover = PopoverPrimitive.Root;

export default Object.assign(Popover, {
  Trigger: PopoverTrigger,
  Content: PopoverContent,
});
