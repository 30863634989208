import { Minus, Plus } from "@phosphor-icons/react";
import classNames from "classnames";
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "ui/inputs/Button";
import Fieldset from "ui/inputs/Fieldset";
import { transitionDuration } from "utils/transitions";

import styles from "./CollapsibleFieldset.module.scss";

type Props = {
  heading: ReactNode;
} & PropsWithChildren;

const CollapsibleFieldset: React.FC<Props> = ({ heading, children }) => {
  const fieldsetWrapperRef = useRef<HTMLDivElement>(null);
  const hasChildren = Boolean(children);
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = useCallback(() => {
    if (!hasChildren) return;
    setIsOpen((currVal) => !currVal);
  }, [hasChildren]);

  /**
   * It's not possible to do vertical animations in CSS,
   * so we do some magic in the [useEffect] to dynamically set the height to a specific value.
   */
  useEffect(() => {
    const ref = fieldsetWrapperRef.current;
    if (!ref) return;
    if (isOpen) {
      ref.style.height = `${ref.scrollHeight}px`;
      setTimeout(() => (ref.style.height = "auto"), transitionDuration);
    } else {
      ref.style.height = `${ref.scrollHeight}px`;
      setTimeout(() => (ref.style.height = "0"), 0);
    }
  }, [isOpen]);

  const headingIcon = useMemo(
    () =>
      hasChildren ? (
        isOpen ? (
          <Minus size={16} className={styles.headingIcon} />
        ) : (
          <Plus size={16} className={styles.headingIcon} />
        )
      ) : null,
    [hasChildren, isOpen]
  );

  return (
    <div className={classNames(styles.container, { [styles.isOpen]: isOpen })}>
      <Button variant="tertiary" onClick={toggleIsOpen} className={styles.heading}>
        {heading}
        {headingIcon}
      </Button>
      <div className={classNames(styles.fieldsetWrapper)} ref={fieldsetWrapperRef}>
        <Fieldset className={styles.fieldset} width="narrow">
          {children}
        </Fieldset>
      </div>
    </div>
  );
};

export default CollapsibleFieldset;
