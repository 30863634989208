import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const DOCUMENTS_QUERY_KEY = "documents";
export const DOCUMENT_CAPITAL_ACCOUNT_SUMMARY = "document-capital-account-summary";

const useDocumentsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [DOCUMENTS_QUERY_KEY, businessGuid],
    queryFn: () => {
      return highbeamApi.documents.getDocuments(businessGuid);
    },
  });
};

export const useDocumentsQuery = () => {
  return useQuery(useDocumentsQueryOptions());
};

const useDocuments = () => {
  const { data } = useSuspenseQuery(useDocumentsQueryOptions());
  return data;
};

export default useDocuments;
