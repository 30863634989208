import { useRef, useState } from "react";
import ModalV4, { ModalRef } from "ui/overlay/ModalV4";

import { useConfirmationModal, useConfirmationModalState } from "./confirmationModalState";

const ConfirmationModal = () => {
  const state = useConfirmationModalState();
  const { close: closeConfirmationModal } = useConfirmationModal();
  const modalRef = useRef<ModalRef>(null);

  const [isSuccessLoading, setIsSuccessLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const onClose = async () => {
    setIsCancelLoading(true);
    state.isOpen && (await state.onCancel?.());
    setIsCancelLoading(false);
    modalRef.current?.closeModalWithAnimation();
    state.isOpen && state.resolvePromise(false); // When confirmed, promise gets resolved to `true` before it gets resolved to `false` here. Not sure if there's a cleaner way to do this but leaving this note here for clarity saying this is intentional.
  };

  const onSubmit = async () => {
    setIsSuccessLoading(true);
    state.isOpen && (await state.onConfirm?.());
    setIsSuccessLoading(false);
    modalRef.current?.closeModalWithAnimation();
    state.isOpen && state.resolvePromise(true);
  };

  if (!state.isOpen) {
    return null;
  }

  return (
    <ModalV4 onClose={closeConfirmationModal} modalRef={modalRef}>
      <ModalV4.Form onSubmit={onSubmit}>
        <ModalV4.Header>{state.header}</ModalV4.Header>

        <ModalV4.Body>
          <ModalV4.Paragraph>{state.message}</ModalV4.Paragraph>
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.SubmitButton
            variant={state.variant}
            isLoading={isSuccessLoading}
            disabled={isCancelLoading}
          >
            {state.buttonLabel}
          </ModalV4.SubmitButton>
          <ModalV4.CloseButton
            isLoading={isCancelLoading}
            disabled={isSuccessLoading}
            onClick={onClose}
          >
            Cancel
          </ModalV4.CloseButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default ConfirmationModal;
