import dayjs from "dayjs";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useShopifyPayoutsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["shopify-payouts", businessGuid],
    queryFn: () => {
      const now = dayjs();
      const params = { businessGuid, pending: false, fromDate: now.subtract(7, "d") };
      return highbeamApi.shopifyPayouts.getByBusiness(params);
    },
  });
};

export default useShopifyPayoutsQueryOptions;
