import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { z } from "zod";

type GetSchemaParams = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const getSchema = ({ capitalAccountSummary }: GetSchemaParams) => {
  return z
    .object({
      chargeCardAgreementConfirmed: z.boolean(),
      readAgreementConfirmed: z.boolean(),
    })
    .superRefine((data, ctx) => {
      if (capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly) {
        if (!data.chargeCardAgreementConfirmed || !data.readAgreementConfirmed) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please check both checkboxes to agree to the terms.",
          });
        }
      } else {
        if (!data.readAgreementConfirmed) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please check the checkbox agree to the terms.",
          });
        }
      }
    });
};

export type ReviewLineOfferAgreementFormInputs = z.infer<ReturnType<typeof getSchema>>;

type Params = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const useReviewLineOfferAgreementForm = ({ capitalAccountSummary }: Params) => {
  return useForm({
    resolver: zodResolver(getSchema({ capitalAccountSummary })),
    defaultValues: {
      chargeCardAgreementConfirmed: false,
      readAgreementConfirmed: false,
    },
  });
};

export default useReviewLineOfferAgreementForm;
