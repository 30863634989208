import Text from "ui/typography/Text";

import styles from "./TransferPlaidReAuth.module.scss";

const TransferPlaidReAuth = () => (
  <Text size={12} className={styles.reauth}>
    Reconnection required
  </Text>
);
export default TransferPlaidReAuth;
