import classNames from "classnames";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./AddToHomeScreenView.module.scss";
import addToHomeScrenResult from "./assets/add-to-home-screen-result.png";
import addToHomeScreen from "./assets/add-to-home-screen.png";
import chromeShare from "./assets/chrome-share.png";
import safariShare from "./assets/safari-share.png";

const AddToHomeScreenView = () => {
  return (
    <>
      <div className={styles.stepContainer}>
        <div className={styles.stepHeader}>
          <div className={styles.stepNumber}>
            <Text numeric color={colors.grey[900]} weight="medium" size={12}>
              1
            </Text>
          </div>
          <Text color={colors.grey[900]} size={14} weight="medium">
            Tap the <strong>“Share”</strong> button in your browser
          </Text>
        </div>

        <div className={classNames(styles.stepImageSection, styles.sideBySideImages)}>
          <img src={chromeShare} alt="Chrome share button location" width="100%" />
          <img src={safariShare} alt="Safari share button location" width="100%" />
        </div>
      </div>

      <div className={styles.stepContainer}>
        <div className={styles.stepHeader}>
          <div className={styles.stepNumber}>
            <Text numeric color={colors.grey[900]} weight="medium" size={12}>
              2
            </Text>
          </div>
          <Text color={colors.grey[900]} size={14} weight="medium">
            Scroll down and tap <strong>“Add to home screen”</strong>
          </Text>
        </div>

        <div className={styles.stepImageSection}>
          <img src={addToHomeScreen} alt="Add to home screen option" width="100%" />
        </div>
      </div>

      <div className={styles.stepContainer}>
        <div className={styles.stepHeader}>
          <div className={styles.stepNumber}>
            <Text numeric color={colors.grey[900]} weight="medium" size={12}>
              3
            </Text>
          </div>
          <Text color={colors.grey[900]} size={14} weight="medium">
            Tap <strong>“Add”</strong> and Highbeam will show on your phone’s home screen!
          </Text>
        </div>
        <div className={styles.stepImageSection}>
          <img src={addToHomeScrenResult} alt="Added to home screen result" width="100%" />
        </div>
      </div>
    </>
  );
};

export default AddToHomeScreenView;
