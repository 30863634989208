import RutterBalanceRep from "reps/RutterBalanceRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class RutterBalanceApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getCurrentBalance(businessGuid: string): Promise<RutterBalanceRep.Complete[]> {
    const url = `/businesses/${businessGuid}/rutter-balances`;
    return (await this.api.get<RutterBalanceRep.Complete[]>(url))!;
  }
}
