import { useQuery } from "@tanstack/react-query";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import useNonTerminatedCapitalAccounts from "resources/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import useConnectedStoresQueryOptions from "resources/connected-stores/queries/useConnectedStoresQueryOptions";
import { ConnectedStore, ConnectedStoreType } from "resources/connected-stores/types";
import getConnectedStoreIcon from "resources/connected-stores/utils/getConnectedStoreIcon";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import SectionLoading from "ui/feedback/SectionLoading";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useIsAllowedToNavigateToConnectedStoresPage from "utils/permissions/navigation/useIsAllowedToNavigateToConnectedStoresPage";
import { getRutterPlatformByConnectedStoreType } from "utils/rutter";
import { addAOrAn } from "utils/string";
import cn from "utils/tailwind/cn";

import AddIntegration from "../SettingsPage/AddIntegration";
import CannotDeleteStoreModal from "../SettingsPage/CannotDeleteStoreModal";
import DeleteStoreModal from "../SettingsPage/DeleteStoreModal";
import ConnectRutter from "../SettingsPage/Integrations/ConnectRutter";
import ConnectShopify from "../SettingsPage/Integrations/ConnectShopify";
import RutterConnectionCard from "../SettingsPage/RutterConnectionCard";

const connectedStoreTypes: ConnectedStoreType[] = ["Shopify", "Amazon", "Stripe", "PayPal"];

const SettingsStoresPageContent = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showCannotDeleteConfirmationModal, setShowCannotDeleteConfirmationModal] = useState(false);
  const [currentStore, setCurrentStore] = useState<ConnectedStore>();
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    data: connectedStores = [],
    isLoading,
    refetch,
  } = useQuery(useConnectedStoresQueryOptions());
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();
  const hasNonTerminatedCapitalAccounts = nonTerminatedCapitalAccounts.length > 0;

  // NB(alex): do not use this pattern. We should probably group by type and map that array instead.
  const connectedStoresByType = (type: ConnectedStoreType) =>
    connectedStores.filter((integration) => integration.type === type);

  // NB(alex): leaving this for now -- was refactoring but the way to clean this up is to refactor the `DeleteStoreModal` state
  const deleteConnection = async (connectedStoreToDelete: ConnectedStore | undefined) => {
    if (connectedStoreToDelete === undefined) return;

    setIsDeleting(true);
    switch (connectedStoreToDelete.type) {
      case "Shopify":
        await highbeamApi.shopifyConnection.delete(businessGuid, connectedStoreToDelete.id);
        break;
      case "Amazon":
      case "Stripe":
      case "PayPal":
        await highbeamApi.rutterConnection.delete(businessGuid, connectedStoreToDelete.id);
        break;
    }

    await refetch();

    setShowDeleteConfirmationModal(false);
    setIsDeleting(false);
  };

  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to="/settings" />
        <DashboardPage.Header.Title>Stores</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        {isLoading && <SectionLoading />}

        {!isLoading &&
          connectedStoreTypes.map((type, index) => (
            <section key={type}>
              <img
                title={type}
                src={getConnectedStoreIcon({ connectedStoreType: type })}
                alt="Platform logo"
                className="mb-5 size-5"
              />
              <div
                className={cn(
                  "mb-8 flex flex-wrap gap-5 border-b border-grey-100 pb-8",
                  index === connectedStoreTypes.length - 1 && "border-b-0"
                )}
              >
                {(connectedStoresByType(type) || []).map((store) => (
                  <RutterConnectionCard
                    className="max-w-72"
                    name={store.name}
                    hasSynced={store.hasSynced}
                    key={store.id}
                    onDisconnect={
                      hasNonTerminatedCapitalAccounts
                        ? () => {
                            setShowCannotDeleteConfirmationModal(true);
                          }
                        : async () => {
                            setCurrentStore(store);
                            setShowDeleteConfirmationModal(true);
                          }
                    }
                  />
                ))}
                {type === "Shopify" ? (
                  <ConnectShopify redirectPathKey="settings">
                    {({ openModal }) => (
                      <AddIntegration
                        className="max-w-72"
                        title={`Add ${addAOrAn(type)} store`}
                        description={`Connect your ${type} store to get sales data and insights.`}
                        addText={`Connect ${type} store`}
                        onAdd={openModal}
                      />
                    )}
                  </ConnectShopify>
                ) : (
                  <ConnectRutter
                    platform={getRutterPlatformByConnectedStoreType(type)}
                    onConnect={(_, callbacks) => {
                      refetch();
                      callbacks.closeModal();
                    }}
                  >
                    {({ openModal }) => (
                      <AddIntegration
                        className="max-w-72"
                        title={`Add ${addAOrAn(type)} store`}
                        description={`Connect your ${type} store to get sales data and insights.`}
                        addText={`Connect ${type} store`}
                        onAdd={openModal}
                      />
                    )}
                  </ConnectRutter>
                )}
              </div>
            </section>
          ))}
      </DashboardPage.Section>

      {showDeleteConfirmationModal && (
        <DeleteStoreModal
          onClose={() => setShowDeleteConfirmationModal(false)}
          onDelete={() => deleteConnection(currentStore)}
          isLoading={isDeleting}
        />
      )}

      {showCannotDeleteConfirmationModal && (
        <CannotDeleteStoreModal onClose={() => setShowCannotDeleteConfirmationModal(false)} />
      )}
    </>
  );
};

const SettingsStoresPage = () => {
  const isAllowedToNavigateToConnectedStoresPage = useIsAllowedToNavigateToConnectedStoresPage();

  if (!isAllowedToNavigateToConnectedStoresPage) {
    return <Navigate to="/settings" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/settings">Settings</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Stores</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <SettingsStoresPageContent />
      </DashboardPage>
    </>
  );
};

export default SettingsStoresPage;
