import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery, { UseRefreshQueryOptions } from "utils/react-query/useRefreshQuery";

const makeQueryKey = (billId: string, businessGuid: string) => [
  "bill-audit-logs",
  { billId, businessGuid },
];

const useBillAuditLogsQueryOptions = (billId: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: makeQueryKey(billId, businessGuid),
    queryFn: async () => {
      return highbeamApi.auditTrail.getBillAuditLogs(billId, businessGuid);
    },
  });
};

const useBillAuditLogs = (billId: string) => {
  const { data } = useSuspenseQuery(useBillAuditLogsQueryOptions(billId));

  return data;
};

export const useRefreshBillAuditLogsQuery = (
  billId: string,
  options: UseRefreshQueryOptions = {}
) => {
  const businessGuid = useBusinessGuid();

  return useRefreshQuery(makeQueryKey(billId, businessGuid), options);
};

export default useBillAuditLogs;
