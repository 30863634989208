import { Bank, CreditCard } from "@phosphor-icons/react";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import Text from "ui/typography/Text";
import { getPlaidBankAccountDisplayName } from "utils/account";

import styles from "./PlaidConnectionCardContents.module.scss";

type Props = {
  account: PlaidBankAccountRep.Complete;
};

const ActivePlaidAccountRow = ({ account }: Props) => {
  return (
    <div className={styles.plaidAccountRow}>
      {account.accountType === "CREDIT" ? <CreditCard size={20} /> : <Bank size={20} />}
      <Text size={14} weight="medium">
        {getPlaidBankAccountDisplayName(account)}
      </Text>
    </div>
  );
};

export default ActivePlaidAccountRow;
