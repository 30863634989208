import PaymentSwitcherAutoPaymentGroupRep from "reps/PaymentSwitcherAutoPaymentGroupRep";
import PaymentSwitcherAutoPaymentRep from "reps/PaymentSwitcherAutoPaymentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class PaymentSwitcherAutoPaymentApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async search(businessGuid: string): Promise<PaymentSwitcherAutoPaymentGroupRep[]> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/insights/payment-switcher/auto-payments?${queryParams}`;
    return (await this.api.get<PaymentSwitcherAutoPaymentGroupRep[]>(url))!;
  }

  async set(creator: PaymentSwitcherAutoPaymentRep.Creator): Promise<void> {
    const url = "/insights/payment-switcher/auto-payments";
    return (await this.api.put<void>(url, creator))!;
  }
}
