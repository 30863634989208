import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";

// Omit providing a default value for the context. Consumers must be wrapped in a provider,
// which the useEventBus hook (implemented below) will enforce.
const EventBusContext = createContext<EventTarget>(undefined as any);

const makeEventBus = () => new EventTarget();

export const EventBusProvider: FC<PropsWithChildren> = ({ children }) => {
  const [eventBus] = useState(makeEventBus);

  return <EventBusContext.Provider value={eventBus}>{children}</EventBusContext.Provider>;
};

export const useEventBus = () => {
  const eventBus = useContext(EventBusContext);
  if (!eventBus) {
    throw new Error("useEventBus() must be used within an EventBusProvider");
  }
  return eventBus;
};

export const useEventBusDispatcher = <TEvent extends Event>() => {
  const eventBus = useEventBus();

  return useCallback(
    (event: TEvent) => {
      eventBus.dispatchEvent(event);
    },
    [eventBus]
  );
};

// This is a convenience hook for registering and unregistering an event listener
// on the event bus (it will automatically clean up the listener when the component
// is unmounted).
// NB(lev): The *callback* parameter is the event handler function. If a given event handler
// has dependencies (e.g. on component state), it *MUST* be wrapped in a useCallback before
// being passed to this hook (otherwise, the effect managed by this hook has no knowledge of
// the handler's dependencies and will not update the listener when they change).
export const useEventBusListener = <TEvent extends Event>(
  eventType: string,
  callback: (event: TEvent) => void
) => {
  const eventBus = useEventBus();

  useEffect(() => {
    eventBus.addEventListener(eventType, callback as EventListener);
    return () => eventBus.removeEventListener(eventType, callback as EventListener);
  }, [eventBus, eventType, callback]);
};
