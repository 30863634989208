import classNames from "classnames";
import React from "react";

import styles from "./DotsLoader.module.scss";

type Props = {
  dots: number;
  small?: boolean;
};

const DotsLoader: React.FC<Props> = ({ dots, small = false }) => {
  const dotLoader = Array(dots)
    .fill(undefined)
    .map((_, i) => <div key={i} className={classNames(styles.dot, small && styles.small)} />);

  return <div className={classNames(styles.dots, small && styles.small)}>{dotLoader}</div>;
};

export default DotsLoader;
