import { LockKey } from "@phosphor-icons/react";
import { captureException } from "@sentry/react";
import Auth0Api from "api/Auth0Api";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import useMfa from "modules/mfa/useMfa";
import { useState } from "react";
import useUser from "resources/user/queries/useUser";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";

import styles from "./SettingsSecurityPage.module.scss";

const SettingsSecurityPage = () => {
  const [passwordResetMessage, setPasswordResetMessage] = useState("");
  const user = useUser();

  const { mfa } = useMfa();

  const onSubmit = async () => {
    await mfa();
    const auth0Api = new Auth0Api();
    auth0Api
      .passwordReset(user.emailAddress)
      .then((val) => setPasswordResetMessage(val))
      .catch((e) => {
        notify("error", "There was a problem resetting your password. Please try again.");
        captureException(e);
      });
  };

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/settings">Settings</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Security</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.BackCaret to="/settings" />
          <DashboardPage.Header.Title>Security</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <DashboardPage.Section>
          <div className={styles.resetPasswordCard}>
            <div className={styles.resetPasswordTitle}>
              <LockKey color={colors.grey[500]} size={24} />
              <Heading2>Change password</Heading2>
            </div>

            <Text color={colors.grey[600]} size={14}>
              In order to make sure it’s you, we’ll send a password reset link to your email (
              {user.emailAddress}).
            </Text>
            <Divider className={styles.divider} />
            <Button variant="primary" onClick={onSubmit}>
              Send password reset email
            </Button>

            {passwordResetMessage && (
              <Text size={12} className={styles.passwordResetMessageText}>
                {passwordResetMessage}
              </Text>
            )}
          </div>
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default SettingsSecurityPage;
