import classNames from "classnames";
import { ReactNode } from "react";

import styles from "./PageIndicator.module.scss";

type Props = {
  active?: boolean;
  onClick: () => void;
  children: ReactNode;
};

const PageButton: React.FC<Props> = ({ active, onClick, children }) => (
  <button className={classNames(styles.number, active && styles.active)} onClick={onClick}>
    {children}
  </button>
);

export default PageButton;
