import { FC, ReactNode } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./OfferDetailsItem.module.scss";

type Props = {
  label: ReactNode;
  value: ReactNode;
};

const OfferDetailsItem: FC<Props> = ({ label, value }) => {
  return (
    <div className={styles.container}>
      <Text color={colors.grey[600]} size={14} weight="medium" className={styles.label}>
        {label}
      </Text>
      <Text color={colors.grey[900]} size={16} weight="medium">
        {value}
      </Text>
    </div>
  );
};

export default OfferDetailsItem;
