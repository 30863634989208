import Pill, { PillColor } from "ui/data-display/Pill";
import {
  HighbeamTransaction,
  isFailedPaymentTransaction,
  isInternationalWireTransaction,
} from "utils/types/transactionsTypes";

type TransactionStatus = "completed" | "pending" | "failed" | "declined" | "canceled";

const getTransactionStatus = (transaction: HighbeamTransaction): TransactionStatus => {
  if (isInternationalWireTransaction(transaction)) {
    /*
      Possible currency cloud statuses. We have not seen the error paths so we only handle the happy
      path and error path for now

      new
      ready_to_send
      completed
      failed
      released
      suspended
      awaiting_authorisation
      submitted
      authorised
      deleted
    */
    if (transaction.internationalWireStatus === "completed") {
      return "completed";
    } else if (transaction.internationalWireStatus === "failed") {
      return "failed";
    } else {
      return "pending";
    }
  }

  if (isFailedPaymentTransaction(transaction)) {
    return "failed";
  }
  if (transaction.paymentStatus === "Pending" || transaction.paymentStatus === "Clearing") {
    return "pending";
  }

  return "completed";
};

const labelMappings: { [key in TransactionStatus]: string } = {
  completed: "Completed",
  pending: "Pending",
  failed: "Failed",
  declined: "Declined",
  canceled: "Cancelled",
};

const pillColorMappings: { [key in TransactionStatus]: PillColor } = {
  completed: "green-light",
  pending: "grey-light",
  failed: "pink-dark",
  declined: "pink-dark",
  canceled: "pink-dark",
};

type Props = {
  transaction: HighbeamTransaction;
  showCompleted: boolean;
  className?: string;
};

const TransactionStatusPill: React.FC<Props> = ({ transaction, showCompleted, className }) => {
  const transactionStatus = getTransactionStatus(transaction);

  if (transactionStatus === "completed" && !showCompleted) {
    return null;
  }

  return (
    <Pill color={pillColorMappings[transactionStatus]} bordered className={className}>
      {labelMappings[transactionStatus]}
    </Pill>
  );
};

export default TransactionStatusPill;
