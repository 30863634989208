const shadows = {
  inset: "inset 0 0 3px rgba(82, 82, 91, 0.22)",
  xs: "0 1px 2px rgba(0, 0, 0, 0.07)",
  sm: "0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)",
  md: "0 2px 5px rgba(0, 0, 0, 0.06), 0 4px 6px -1px rgba(0, 0, 0, 0.1)",
  lg: "0 4px 6px -2px rgba(0, 0, 0, 0.06), 0 10px 15px -3px rgba(0, 0, 0, 0.1)",
  xl: "0 10px 10px -5px rgba(0, 0, 0, 0.06), 0 20px 25px -5px rgba(0, 0, 0, 0.1)",
} as const;

export default shadows;
