import { AtomEffect } from "recoil";

export const booleanLocalStorageEffect: (key: string) => AtomEffect<boolean> = (key: string) =>
  localStorageEffect<boolean>(
    key,
    (value) => JSON.stringify(value),
    (value) => JSON.parse(value)
  );

export const stringLocalStorageEffect: (key: string) => AtomEffect<string> = (key: string) =>
  localStorageEffect<string>(
    key,
    (value) => value,
    (value) => value
  );

/**
 * https://recoiljs.org/docs/guides/atom-effects/#local-storage-persistence
 */
const localStorageEffect: <T>(
  key: string,
  serialize: (value: T) => string,
  deserialize: (value: string) => T
) => AtomEffect<T> =
  (key, serialize, deserialize) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue) {
      setSelf(deserialize(savedValue));
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, serialize(newValue));
      }
    });
  };
