import { useMutation } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { LIST_PAYMENT_APPROVALS_QUERY_KEY } from "../queries/usePaymentApprovalsQueryOptions";

type Variables = {
  paymentGuid: string;
};

const useRejectPaymentMutation = () => {
  const highbeamApi = useHighbeamApi();
  const refreshPaymentApprovals = useRefreshQuery([LIST_PAYMENT_APPROVALS_QUERY_KEY]);

  return useMutation({
    mutationFn: ({ paymentGuid }: Variables) => {
      return highbeamApi.paymentAction.reject(paymentGuid);
    },
    onSuccess: () => {
      refreshPaymentApprovals();
    },
  });
};

export default useRejectPaymentMutation;
