import {
  getValidator,
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import TextInput, { useTextInput } from "ui/inputs/TextInput";

type Props = {
  selectedCountry: string;
};

const Cnaps: React.FC<Props> = ({ selectedCountry }) => {
  const [internationalBankingInfo, setInternationalBankingInfo] = useRecoilState(
    internationalBankingInfoState
  );
  const Cnaps = useTextInput({
    initialValue: internationalBankingInfo.cnaps?.value,
    onChange: getValidator(selectedCountry, INTERNATIONAL_WIRE_INPUTS.CNAPS),
  });

  useEffect(() => {
    setInternationalBankingInfo({
      ...internationalBankingInfo,
      cnaps: {
        inputName: INTERNATIONAL_WIRE_INPUTS.CNAPS,
        value: Cnaps.value,
        isValid: Cnaps.isValid,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cnaps.value, Cnaps.isValid]);

  return (
    <TextInput
      type="text"
      value={Cnaps.value}
      onChange={(value) => {
        Cnaps.onChange(value);
      }}
      label={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.CNAPS].label}
      onBlur={Cnaps.onBlur}
      hasError={Cnaps.hasError}
      errorMessage={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.CNAPS].errorMessage}
    />
  );
};

export default Cnaps;
