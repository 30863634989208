import { Account, Customer, Include, Transaction, UnitResponse } from "@highbeam/unit-node-sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessUnitCoCustomerId from "resources/business/queries/useBusinessUnitCoCustomerId";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const UNIT_CO_TRANSACTIONS_QUERY_KEY = "unit-co-transactions";

type Params = {
  accountId: string;
  transactionId?: string;
};

type Response<T extends Transaction> = UnitResponse<T> & Include<Account[] | Customer[]>;

const useUnitCoTransactionsQueryOptions = <T extends Transaction>(params: Params) => {
  const unitApi = useUnitApi();
  const customerId = useBusinessUnitCoCustomerId({ required: true });

  return useQueryOptions({
    queryKey: [UNIT_CO_TRANSACTIONS_QUERY_KEY, customerId, params],
    queryFn: async () => {
      if (!params.transactionId) {
        return null;
      }
      return (await unitApi.transactions.get(
        params.accountId,
        params.transactionId,
        customerId
      )) as Response<T>;
    },
  });
};

export const useUnitCoTransaction = <T extends Transaction>(params: Params) => {
  const { data: response } = useSuspenseQuery(useUnitCoTransactionsQueryOptions<T>(params));

  return response?.data;
};
