export type InterestTier = {
  range: string;
  interestBps: number;
};

export const CHECKING_INTEREST_TIERS = [
  {
    range: "$0 - $100K",
    interestBps: 0,
  },
  {
    range: "$100K - $250K",
    interestBps: 102,
  },
  {
    range: "$250K+",
    interestBps: 237,
  },
] as const satisfies readonly InterestTier[];

export const HIGH_YIELD_INTEREST_TIERS = [
  {
    range: "$0 - $1M",
    interestBps: 332,
  },
  {
    range: "$1M - $5M",
    interestBps: 378,
  },
  {
    range: "$5M+",
    interestBps: 429,
  },
] as const satisfies readonly InterestTier[];
