import BillPaymentRep from "reps/BillPaymentRep";
import BillRep from "reps/BillRep";
import billSyncQueryHooks from "resources/bill-syncs/queries/billSyncQueryHooks";
import shouldBillSyncBeDryRun from "resources/bill-syncs/utils/shouldBillSyncBeDryRun";
import useRefreshBillsQueries from "resources/bills/queries/useRefreshBillsQueries";
import { useCurrentBusinessMember } from "resources/business-members/queries/businessMemberQueryHooks";
import { useIsSuperusering } from "state/auth/isSuperusering";
import { OmitDeep } from "type-fest";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useBill from "../queries/useBill";

const useMarkBillAsPaidMutation = (
  billId: string,
  additionalOptions: MutationAdditionalOptions<
    BillRep.Complete,
    OmitDeep<BillPaymentRep.ManualCreator, "method.type" | "method.businessMemberGuid">
  >
) => {
  const highbeamApi = useHighbeamApi();
  const isSuperusering = useIsSuperusering();
  // NB(lev): `businessMemberGuid` is a required field for the mutation, but we
  // won't have a reference to the current business member if the user is
  // superusering. The caller of this mutation is responsible for ensuring
  // that marking a bill as paid is not allowed when the user is superusering.
  const currentBusinessMember = useCurrentBusinessMember();

  const bill = useBill(billId, { required: true });
  const billSyncDryRun = shouldBillSyncBeDryRun(bill);
  const refreshBillsQueries = useRefreshBillsQueries();
  const refreshBillSyncQuery = billSyncQueryHooks.useRefreshQuery({
    billId,
    dryRun: billSyncDryRun,
  });

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => {
        if (isSuperusering) {
          throw new Error("Cannot mark bill as paid while superusering.");
        }

        return highbeamApi.billAction.markAsPaid(billId, {
          amount: variables.amount,
          method: {
            type: "Manual",
            businessMemberGuid: currentBusinessMember!.guid,
            ...variables.method,
          },
          memo: variables.memo,
        });
      },
      onSuccess: async () => {
        // We refresh *all* the bills queries so that the user can be redirected
        // back to the index view after marking a bill as paid.
        await Promise.all([refreshBillsQueries(), refreshBillSyncQuery()]);
      },
    },
    additionalOptions
  );
};

export default useMarkBillAsPaidMutation;
