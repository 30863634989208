import { ComponentProps, FC } from "react";

import MoneyAmount from "../MoneyAmount";

type Props = ComponentProps<typeof MoneyAmount>;

const CashDisplay: FC<Props> = ({ cents, ...moneyAmountProps }) => {
  return (
    <MoneyAmount
      cents={cents}
      size={28}
      centsTextSize={16}
      weight="medium"
      withCents={cents % 100 !== 0}
      {...moneyAmountProps}
    />
  );
};

export default CashDisplay;
