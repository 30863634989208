import amazonIcon from "assets/amazon-logo-without-label.svg";
import paypalIcon from "assets/paypal-icon.svg";
import netSuiteIcon from "assets/rutter/netsuite-logo.svg";
import quickbooksIcon from "assets/rutter/quickbooks-logo.svg";
import xeroIcon from "assets/rutter/xero-logo.svg";
import shopifyIcon from "assets/shopify-icon.svg";
import stripeIcon from "assets/stripe-icon.svg";
import RutterConnectionRep from "reps/RutterConnectionRep";
import { ConnectedStoreType } from "resources/connected-stores/types";

export const getRutterPlatformName = (platform: RutterConnectionRep.RutterPlatform): string => {
  switch (platform) {
    case "AMAZON":
      return "Amazon";
    case "NETSUITE":
      return "NetSuite";
    case "PAYPAL":
      return "PayPal";
    case "QUICKBOOKS":
      return "QuickBooks";
    case "SHOPIFY":
      return "Shopify";
    case "STRIPE":
      return "Stripe";
    case "XERO":
      return "Xero";
  }
};

export const getPlatformAccountName = (
  platform: RutterConnectionRep.RutterPlatform,
  pluralize = false
): string => {
  switch (platform) {
    case "AMAZON":
    case "SHOPIFY":
      return pluralize ? "stores" : "store";
    case "NETSUITE":
    case "PAYPAL":
    case "QUICKBOOKS":
    case "STRIPE":
    case "XERO":
      return pluralize ? "accounts" : "account";
  }
};

export const getRutterPlatformByConnectedStoreType = (
  type: ConnectedStoreType
): RutterConnectionRep.RutterPlatform => {
  switch (type) {
    case "Shopify":
      return "SHOPIFY";
    case "Stripe":
      return "STRIPE";
    case "PayPal":
      return "PAYPAL";
    case "Amazon":
      return "AMAZON";
  }
};

export const getRutterIcon = (platform: RutterConnectionRep.RutterPlatform): string => {
  switch (platform) {
    case "AMAZON":
      return amazonIcon;
    case "NETSUITE":
      return netSuiteIcon;
    case "PAYPAL":
      return paypalIcon;
    case "QUICKBOOKS":
      return quickbooksIcon;
    case "SHOPIFY":
      return shopifyIcon;
    case "STRIPE":
      return stripeIcon;
    case "XERO":
      return xeroIcon;
  }
};
