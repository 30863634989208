import LineOfCreditPlatformConnectionRep from "reps/LineOfCreditPlatformConnectionRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class LineOfCreditBalanceRecoveryApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getMandatoryConnections(
    businessGuid: string,
    lineOfCreditGuid: string
  ): Promise<LineOfCreditPlatformConnectionRep.Complete[]> {
    const url = `/businesses/${businessGuid}/lines-of-credit/${lineOfCreditGuid}/mandatory-connections`;
    return (await this.api.get<LineOfCreditPlatformConnectionRep.Complete[]>(url))!;
  }
}
