import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import showConfirmResetModalState from "state/capital/creditCalculator/showConfirmResetModal";
import offerInputsState from "state/compareCreditOffers/inputs/offerInputs/offerInputs";
import revenueProjectionInputsState from "state/compareCreditOffers/inputs/revenueProjectionInputs";
import sessionGuidState from "state/compareCreditOffers/sessionGuid";
import snapshotState from "state/compareCreditOffers/snapshot";

const useResetComparisonSession = () => {
  const resetSessionGuid = useResetRecoilState(sessionGuidState);
  const snapshot = useRecoilValue(snapshotState);
  const resetOfferInputs = useResetRecoilState(offerInputsState);
  const resetRevenueProjectionInputs = useResetRecoilState(revenueProjectionInputsState);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setShowConfirmResetModal = useSetRecoilState(showConfirmResetModalState);

  const resetComparisonSession = () => {
    resetOfferInputs();
    resetRevenueProjectionInputs();

    if (snapshot) {
      resetSessionGuid();
    }

    navigate({
      pathname: CREDIT_COMPARISON_PATH,
      search: searchParams.toString(),
    });

    setShowConfirmResetModal(false);
  };

  return resetComparisonSession;
};

export default useResetComparisonSession;
