import CapitalRepaymentRep from "reps/CapitalRepaymentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CapitalRepaymentApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(
    businessGuid: string,
    capitalAccountGuid: string,
    creator: CapitalRepaymentRep.Creator
  ): Promise<void> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      capitalAccountGuid: capitalAccountGuid,
    });

    const url = `/capital-repayment/repayments?${qp}`;

    return (await this.api.post<void>(url, creator))!;
  }
}
