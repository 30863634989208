import env from "env";
import { FC, useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import UserInvitationRep from "reps/UserInvitationRep";
import auth0ClientState from "state/auth/auth0Client";
import businessGuidState from "state/auth/businessGuid";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import TextLink from "ui/navigation/TextLink";
import { Heading1, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  invitation: UserInvitationRep.Complete;
  userExists: boolean;
  className?: string;
};

const UserInvitationCard: FC<Props> = ({ invitation, userExists, className }) => {
  const auth0 = useRecoilValue(auth0ClientState);

  // Currently, if a user hits this component, they are never authenticated and always get redirected to Auth0 and then redirected back to the app.
  // When they are redirected back, we want to ensure their business guid is set to `invitation.businessGuid` to handle the subsequent onboarding or app logic properly.
  const setBusinessGuid = useSetRecoilState(businessGuidState);
  useEffect(() => {
    setBusinessGuid(invitation.businessGuid);
  }, [invitation.businessGuid, setBusinessGuid]);

  const [isPending, setIsPending] = useState(false);

  const onAcceptInvite = useCallback(() => {
    setIsPending(true);
    void auth0.loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}/onboarding`,
      },
      authorizationParams: {
        screen_hint: userExists ? "login" : "signup", // eslint-disable-line camelcase
        login_hint: invitation.emailAddress, // eslint-disable-line camelcase
        redirectMethod: "replace",
        userInvitationSlug: invitation.slug,
      },
    });
    // Redirects so we don't set `setIsPending(false)`.
  }, [auth0, invitation.emailAddress, invitation.slug, userExists]);

  // Always redirect the user to the onboarding flow if they have already accepted the invitation.
  useEffect(() => {
    if (invitation.accepted) {
      onAcceptInvite();
    }
  }, [onAcceptInvite, invitation.accepted]);

  // Redirecting...
  if (invitation.accepted) {
    return null;
  }

  return (
    <Card
      shadow="xs"
      className={cn("flex w-full max-w-2xl flex-col items-center gap-x-8", className)}
    >
      <div className="flex flex-col items-center gap-y-6 rounded-lg bg-white px-8 py-8">
        <div className="flex flex-col gap-y-2">
          <Heading1 className="text-center">
            {invitation.senderName} invited you to{" "}
            {invitation.businessDisplayName ? (
              <>access the Highbeam account for {invitation.businessDisplayName}</>
            ) : (
              <TextLink external to={env.HIGHBEAM_MARKETING_SITE_URL}>
                Highbeam
              </TextLink>
            )}
            .
          </Heading1>
          <Paragraph className="mb-1 mt-1 text-center text-md text-grey-500">
            Once you accept the invite, you will be able to access their Highbeam account.
          </Paragraph>
        </div>
        <Button
          variant="primary"
          className="mb-2 w-full tablet:w-auto"
          onClick={onAcceptInvite}
          isLoading={isPending}
        >
          Accept invite
        </Button>
      </div>
    </Card>
  );
};

export default UserInvitationCard;
