import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";

type ManualTransferFallbackModalState = {
  isOpen: boolean;
};

const manualTransferFallbackModalState = atom<ManualTransferFallbackModalState>({
  key: "transferMoney/manualTransferFallbackModalState",
  default: { isOpen: false },
});

export default manualTransferFallbackModalState;

export const useOpenManualTransferFallbackModal = () => {
  const setModalState = useSetRecoilState(manualTransferFallbackModalState);
  return useCallback(() => setModalState({ isOpen: true }), [setModalState]);
};
export const useCloseManualTransferFallbackModal = () => {
  const setModalState = useSetRecoilState(manualTransferFallbackModalState);
  return useCallback(() => setModalState({ isOpen: false }), [setModalState]);
};
