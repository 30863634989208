import TransactionsTable from "components/Transactions";
import { useEffect } from "react";

import { useAccountsTransactions, accountTransactionsTabs } from "./utils";

type Props = {
  scrollRef?: React.RefObject<HTMLDivElement>;
};

const Transactions: React.FC<Props> = ({ scrollRef }) => {
  const {
    transactions,
    isLoading,
    activeTab,
    setActiveTab,
    accountOptions,
    selectedAccount,
    setSelectedAccount,
    searchQuery,
    setSearchQuery,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    pagination,
    refreshTransactions,
  } = useAccountsTransactions();

  // Accounts options include the "AL OPTIONS" view.
  // We need at least 3 options to show the all accounts view
  const hasMultipleAccounts = accountOptions.length > 2;

  useEffect(() => {
    if (scrollRef) {
      scrollRef.current?.scrollIntoView({
        block: "start",
        inline: "start",
      });
    }
  }, [scrollRef]);

  return (
    <div ref={scrollRef}>
      <TransactionsTable
        isLoading={isLoading}
        transactions={transactions}
        // If there is only 1 account, we can avoid passing in account options
        // This will result in us not rendering the account filter
        accountOptions={hasMultipleAccounts ? accountOptions : undefined}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        pagination={pagination}
        hasExport
        unitCoDepositAccountId={
          selectedAccount && selectedAccount.value !== "all" ? selectedAccount.value : undefined
        }
        tabs={accountTransactionsTabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        refreshTransactions={refreshTransactions}
      />
    </div>
  );
};

export default Transactions;
