import logoWithoutLabel from "assets/highbeam-logo-without-label.svg";
import logoWithLabel from "assets/highbeam-logo.svg";
import { CSSProperties } from "react";

// NB(alex): We probably only want to use this when `withText: true`, and prefer `PlatformIcon` otherwise.
// Also, arguably, this should just be an image, not a component.

type Props = {
  className?: string;
  withText?: boolean;
  style?: CSSProperties;
};

const HighbeamLogo: React.FC<Props> = ({ className, withText, style }) => (
  <img
    src={withText ? logoWithLabel : logoWithoutLabel}
    className={className}
    alt="Highbeam"
    style={style}
  />
);

export default HighbeamLogo;
