import confettiGif from "assets/onboarding-confetti.gif";
import OnboardingLayout from "layouts/OnboardingLayout";
import ApplicationEvaluationPage from "pages/ApplicationEvaluationPage";
import useGetStarted from "pages/Onboarding/hooks/useGetStarted";
import { useEffect, useState } from "react";
import useBusiness from "resources/business/queries/useBusiness";
import InfoIcon from "ui/icons/InfoIcon";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

import styles from "./OnboardingGetStartedPage.module.scss";

const OnboardingGetStartedPage = () => {
  const { getStarted, isPending } = useGetStarted({
    onSuccess: () => {
      window.location.href = "/"; // Intended to force a refresh.
    },
  });

  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 1800);
    return () => clearTimeout(timer);
  });

  const business = useBusiness();

  const isBusinessActive = business.status === "Active";

  if (!isBusinessActive) {
    return <ApplicationEvaluationPage />;
  }

  return (
    <OnboardingLayout showProgressBar={false} backgroundColor="bg-purple-50" backgroundImage="none">
      {showConfetti ? (
        <img src={confettiGif} alt="confetti" />
      ) : (
        <div
          className={cn("mt-32 flex w-full flex-col items-center bg-purple-50 p-8", styles.title)}
        >
          <InfoIcon variant="success" size={70} className={styles.successIcon} />
          <div className={cn("mb-8 mt-2 flex flex-col gap-y-4")}>
            <Text align="center" size={28} weight="bold">
              Your banking just got a lot more exciting!
            </Text>
            <Text align="center" size={16}>
              We’re glad to have you on board.
            </Text>
          </div>
          <Button
            className={styles.button}
            variant="primary"
            isLoading={isPending}
            onClick={getStarted}
          >
            Take me to Highbeam
          </Button>
        </div>
      )}
    </OnboardingLayout>
  );
};

export default OnboardingGetStartedPage;
