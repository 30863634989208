import TransactionsTable from "components/Transactions";
import DashboardPage from "layouts/DashboardPage";
import BankAccountRep from "reps/BankAccountRep";

import {
  accountTransactionsTabs,
  useAccountsTransactions,
} from "../../AccountsTransactionsPage/Transactions/utils";

type Props = {
  account: BankAccountRep.Complete;
};

const AccountDetailsAccountTransactionsSection: React.FC<Props> = ({ account }) => {
  const {
    transactions,
    isLoading,
    activeTab,
    setActiveTab,
    searchQuery,
    setSearchQuery,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    pagination,
    refreshTransactions,
  } = useAccountsTransactions(account.unitCoDepositAccountId);

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Transactions</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <TransactionsTable
          isLoading={isLoading}
          transactions={transactions}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          pagination={pagination}
          hasExport
          unitCoDepositAccountId={account.unitCoDepositAccountId}
          tabs={accountTransactionsTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          refreshTransactions={refreshTransactions}
        />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default AccountDetailsAccountTransactionsSection;
