import useCardTransactionsTableData from "modules/card-transactions-table/queries/useCardTransactionsTableData";
import { useCardDetailsPageContext } from "pages/cards/CardDetailsPage/providers/CardDetailsPageProvider";
import useCardDetailsPageSearchParams from "pages/cards/CardDetailsPage/state/useCardDetailsPageSearchParams";
import { useEffect, useState } from "react";
import { startOfBankingDay } from "utils/date";

const useCardDetailsPageCardTransactionsTableData = () => {
  const { card } = useCardDetailsPageContext();

  const {
    activeTab,
    page,
    setPage,
    since: sinceParam,
    until: untilParam,
    search,
    setSearch,
    offset,
    limit,
  } = useCardDetailsPageSearchParams();

  const since = startOfBankingDay(sinceParam, { keepLocalTime: true }).format();
  const until = startOfBankingDay(untilParam, { keepLocalTime: true }).add(1, "day").format();

  // Clear search & reset page when tab changes
  const [prevActiveTab, setPrevActiveTab] = useState(activeTab);
  useEffect(() => {
    if (activeTab !== prevActiveTab) {
      setPrevActiveTab(activeTab);
      setSearch("");
      setPage(1);
    }
  }, [activeTab, prevActiveTab, setSearch, setPage]);

  return useCardTransactionsTableData({
    cardId: card.id,
    activeTab,
    page,
    since,
    until,
    search,
    offset,
    limit,
  });
};

export default useCardDetailsPageCardTransactionsTableData;
