import { FC, ReactNode } from "react";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import cn from "utils/tailwind/cn";

import Button, { ButtonProps } from ".";

export type ButtonWithTooltipFullWidthOption = boolean | "flex-1" | "flex-1 w-full";

export type SharedButtonWithTooltipProps = {
  tooltip?: ReactNode;
  /**
   * Work-around for ensuring the span also has full width styles applied because `className` does not get applied to it.
   */
  fullWidth?: ButtonWithTooltipFullWidthOption;
};

type Props = ButtonProps & SharedButtonWithTooltipProps;

const ButtonWithTooltip: FC<Props> = ({ tooltip, disabled, fullWidth, className, ...props }) => {
  const button = (
    <Button disabled={disabled} className={cn(fullWidth && "w-full", className)} {...props} />
  );

  return (
    <ItemWithTooltip tooltip={tooltip}>
      {disabled ? (
        <span className={cn("inline-block", fullWidth && "w-full")} tabIndex={0}>
          {button}
        </span>
      ) : (
        button
      )}
    </ItemWithTooltip>
  );
};

export default ButtonWithTooltip;
