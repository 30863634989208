import { CreditCard } from "@phosphor-icons/react";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import colors from "styles/colors";

import CashAccessLineIcon from "./assets/cash-access-line-icon.svg?react";

// NB(alex): This component needs to be audited / is still a WIP.

type Props = {
  capitalAccountType: CapitalAccountRep.Type;
  size?: number;
};

const CapitalAccountIcon: FC<Props> = ({ capitalAccountType, size = 24 }) => {
  switch (capitalAccountType) {
    case CapitalAccountRep.Type.ChargeCardOnly:
      return <CreditCard weight="light" color={colors.purple[500]} size={size} />;
    case CapitalAccountRep.Type.CashAccessOnly:
      // NB(alex): Hack to get the icon size to look right next to the credit card icon. Slack thread for context/discussion: https://highbeamco.slack.com/archives/C01HJUSDSMA/p1716933920365409
      const cashAccessLineIconSize = size * 1.2;
      return <CashAccessLineIcon width={cashAccessLineIconSize} height={cashAccessLineIconSize} />;
  }
};

export default CapitalAccountIcon;
