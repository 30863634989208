import { forwardRef, ForwardRefRenderFunction, useId } from "react";

import Switch, { SwitchProps } from "../Switch";
import WithLabel, { SharedWithLabelProps } from "../WithLabel";

type Props = SharedWithLabelProps & SwitchProps;

const SwitchWithLabel: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { label, className, id: idProp, ...switchProps },
  ref
) => {
  const internalId = useId();
  const id = idProp ?? internalId;

  return (
    <WithLabel htmlFor={id} label={label} disabled={switchProps.disabled} className={className}>
      <Switch ref={ref} id={id} {...switchProps} />
    </WithLabel>
  );
};

export default forwardRef(SwitchWithLabel);
