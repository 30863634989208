import { ArrowFatLinesUp } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import MultiStep from "layouts/MultiStep";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountBreadcrumbItem from "resources/capital-accounts/components/CapitalAccountBreadcrumbItem";
import capitalAccountSummaryQueryHooks from "resources/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import useIsAllowedToRepayCapital from "utils/permissions/useIsAllowedtoRepayCapital";

import RepayLineOfCreditSteps from "./components/RepayLineOfCreditSteps";
import RepayLineOfCreditAmountView from "./views/RepayLineOfCreditAmountView";
import RepayLineOfCreditConfirmationView from "./views/RepayLineOfCreditConfirmationView";

type CapitalRepayLineOfCreditPageContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalRepayLineOfCreditPageContent = ({
  capitalAccountSummary,
}: CapitalRepayLineOfCreditPageContentProps) => {
  const isAllowedToRepayCapital = useIsAllowedToRepayCapital();

  if (!isAllowedToRepayCapital) {
    return <Navigate to={`/capital/${capitalAccountSummary.guid}`} />;
  }

  return (
    <>
      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to={`/capital/${capitalAccountSummary.guid}`} />
        <DashboardPage.Header.IconTile icon={<ArrowFatLinesUp size={20} weight="light" />} />
        <DashboardPage.Header.Title>Repay line of credit</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <MultiStep steps={<RepayLineOfCreditSteps capitalAccountSummary={capitalAccountSummary} />}>
          <Routes>
            <Route
              index
              element={
                <RepayLineOfCreditAmountView capitalAccountSummary={capitalAccountSummary} />
              }
            />
            <Route
              path="/confirm"
              element={
                <RepayLineOfCreditConfirmationView capitalAccountSummary={capitalAccountSummary} />
              }
            />
          </Routes>
        </MultiStep>
      </DashboardPage.Section>
    </>
  );
};

const CapitalRepayLineOfCreditPage = () => {
  const { capitalAccountGuid } = useParams();
  const { data: capitalAccountSummary } = capitalAccountSummaryQueryHooks.useQuery({
    capitalAccountGuid: capitalAccountGuid ?? null,
  });

  if (capitalAccountSummary === null) {
    return <Navigate to="/capital" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>
          <CapitalAccountBreadcrumbItem
            capitalAccountSummary={capitalAccountSummary}
            isCurrentItem={false}
          />
          <Breadcrumbs.CurrentItem>Repay credit</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        {capitalAccountSummary ? (
          <CapitalRepayLineOfCreditPageContent capitalAccountSummary={capitalAccountSummary} />
        ) : (
          <DashboardPage.DotsLoader />
        )}
      </DashboardPage>
    </>
  );
};

export default CapitalRepayLineOfCreditPage;
