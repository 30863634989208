import UserExistenceRep from "reps/UserExistenceRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class UserExistenceApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByEmailAddress(emailAddress: string): Promise<UserExistenceRep> {
    const queryParams = new URLSearchParams({ emailAddress });
    const url = `/user-existences?${queryParams}`;
    return (await this.api.get<UserExistenceRep>(url))!;
  }
}
