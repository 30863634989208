import { Note } from "@phosphor-icons/react";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import Banner from "ui/data-display/Banner";
import IconTile from "ui/icons/IconTile";
import ButtonLink from "ui/inputs/ButtonLink";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  className?: string;
};

const UpdatedLineOfCreditAgreementBanner: FC<Props> = ({ capitalAccountSummary, className }) => {
  return (
    <Banner
      color="white"
      padding="lg"
      className={cn("border-grey-200", className)}
      icon={<IconTile icon={<Note />} />}
      title="Important changes to your Highbeam Capital account"
      paragraph="To avoid any potential disruptions, kindly review and accept your updated Highbeam Capital terms."
      button={
        <ButtonLink
          variant="primary"
          to={`/capital/${capitalAccountSummary.guid}/review-updated-line-agreement`}
          className="w-full tablet:w-auto"
        >
          <Text size={14} weight="medium">
            Review updates
          </Text>
        </ButtonLink>
      }
    />
  );
};

export default UpdatedLineOfCreditAgreementBanner;
