import filterOfferInputsWithoutProviderName from "pages/capital/CreditComparisonPage/utils/filterOfferInputsWithoutProviderName";
import { selector, selectorFamily } from "recoil";

import offerInputsState from "./inputs/offerInputs/offerInputs";
import isOfferFormValidState from "./isOfferFormValid";
import isRevenueProjectionsFormValidState from "./isRevenueProjectionsFormValid";
import isSelectCreditOfferProvidersFormValidState from "./isSelectCreditOfferProvidersFormValid";
import snapshotState from "./snapshot";

export const isOfferStepNavigableState = selectorFamily<boolean, number>({
  key: "isOfferStepNavigableState",
  get:
    (offerIndex) =>
    ({ get }) => {
      const offerInputsWithProviderName = filterOfferInputsWithoutProviderName(
        get(offerInputsState)
      );

      return offerInputsWithProviderName.length > offerIndex && offerIndex > 0
        ? get(isOfferFormValidState(offerIndex - 1)) // Previous step must be valid
        : get(isSelectCreditOfferProvidersFormValidState); // Initial select providers form must be valid
    },
});

export const isRevenueProjectionStepNavigableState = selector<boolean>({
  key: "isRevenueProjectionStepNavigableState",
  get: ({ get }) => {
    const offerInputs = filterOfferInputsWithoutProviderName(get(offerInputsState));
    const isSelectCreditOfferProvidersFormValid = get(isSelectCreditOfferProvidersFormValidState);

    const areAllPreviousFormsFinished =
      isSelectCreditOfferProvidersFormValid &&
      offerInputs.every((_, index) => get(isOfferFormValidState(index)));

    return areAllPreviousFormsFinished;
  },
});

const isStepNavigableState = selectorFamily<boolean, number>({
  key: "isStepNavigableState",
  get:
    (stepIndex) =>
    ({ get }) => {
      if (stepIndex === 0) {
        return true;
      }

      const offerInputs = filterOfferInputsWithoutProviderName(get(offerInputsState));
      const isOfferStepIndex = stepIndex > 0 && stepIndex <= offerInputs.length;
      if (isOfferStepIndex) {
        return get(isOfferStepNavigableState(stepIndex - 1));
      }

      // revenue projections step
      if (stepIndex === offerInputs.length + 1) {
        return get(isRevenueProjectionStepNavigableState);
      }

      // results step
      if (stepIndex === offerInputs.length + 2) {
        return get(isRevenueProjectionsFormValidState) && Boolean(get(snapshotState)?.slug);
      }

      return false;
    },
});

export default isStepNavigableState;
