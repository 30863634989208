import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalPromotionalAprLabel from "resources/capital-accounts/components/CapitalPromotionalAprLabel";
import colors from "styles/colors";
import MetadataList from "ui/data-display/MetadataList";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import variants from "utils/ts/variants";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const LineOfCreditDetailsSection: React.FC<Props> = ({ capitalAccountSummary }) => {
  const isCapitalPromotionalRateEnabled = useFeatureFlag("CAPITAL_PROMOTIONAL_RATES");

  return (
    <MetadataList>
      {capitalAccountSummary.details.netApr > 0 && (
        <>
          {isCapitalPromotionalRateEnabled ? (
            <MetadataList.Item>
              <MetadataList.ItemLabel>
                <CapitalPromotionalAprLabel weight="regular" />
              </MetadataList.ItemLabel>

              <MetadataList.ItemValue>
                10.00%
                <Text as="span" size={14} color={colors.grey[600]} lineThrough className="ml-2">
                  {capitalAccountSummary.details.netApr * 100}%
                </Text>
              </MetadataList.ItemValue>
            </MetadataList.Item>
          ) : (
            <MetadataList.Item>
              <MetadataList.ItemLabel>APR (Fixed)</MetadataList.ItemLabel>
              <MetadataList.ItemValue>
                {capitalAccountSummary.details.netApr * 100}%
              </MetadataList.ItemValue>
            </MetadataList.Item>
          )}
        </>
      )}

      <MetadataList.Item>
        <MetadataList.ItemLabel>Repayment term</MetadataList.ItemLabel>
        <MetadataList.ItemValue>
          {capitalAccountSummary.details.targetRepaymentDays} days
        </MetadataList.ItemValue>
      </MetadataList.Item>

      {capitalAccountSummary.details.lineType && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Line type</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {variants(capitalAccountSummary.details.lineType, {
              Advance: "Advance",
              Revolving: "Revolving",
              NonRevolving: "Non-revolving",
              InRepayment: "In repayment",
            })}
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}

      {capitalAccountSummary.details.securedStatus && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Collateral</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {capitalAccountSummary.details.securedStatus}
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}
    </MetadataList>
  );
};

export default LineOfCreditDetailsSection;
