import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { Navigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import offerInputsState from "state/compareCreditOffers/inputs/offerInputs/offerInputs";
import { isOfferStepNavigableState } from "state/compareCreditOffers/isStepNavigable";

import { CREDIT_OFFER_PARAM_PREFIX } from "../../constants";

import DefaultCreditOfferDetailsForm from "./DefaultCreditOfferDetailsForm";
import ShopifyOfferDetailsForm from "./ShopifyOfferDetailsForm";

const getCreditOfferProviderIndexFromParam = (param: string): number => {
  const [_prefix, numberString] = param.split(CREDIT_OFFER_PARAM_PREFIX);
  const number = Number(numberString);
  return number > 0 ? number - 1 : -1;
};

const CreditOfferDetailsForm = () => {
  const { offer } = useParams();
  const offerIndex = offer ? getCreditOfferProviderIndexFromParam(offer) : -1;

  const isOfferStepNavigable = useRecoilValue(isOfferStepNavigableState(offerIndex));

  const offerInputs = useRecoilValue(offerInputsState);
  const providerName = offerIndex === -1 ? undefined : offerInputs[offerIndex]?.providerName;

  // Redirect to credit comparison index page if offer index is not defined (invalid param)
  if (!isOfferStepNavigable || !providerName) {
    return <Navigate to={CREDIT_COMPARISON_PATH} />;
  }

  // Ensures react knows the forms are different when navigating between them. Prevents an issue where the same input stays in focus between form submissions.
  const key = `${offerIndex}-${providerName}`;

  switch (providerName) {
    case "Shopify":
      return <ShopifyOfferDetailsForm key={key} offerIndex={offerIndex} />;
    case "Wayflyer":
    case "Clearco":
    case "Custom":
      return (
        <DefaultCreditOfferDetailsForm
          key={key}
          offerIndex={offerIndex}
          providerName={providerName}
        />
      );
  }
};

export default CreditOfferDetailsForm;
