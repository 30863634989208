import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";

type ActivateThreadViewState = {
  isOpen: boolean;
};

const activateThreadViewState = atom<ActivateThreadViewState>({
  key: "accounts/activateThreadViewState",
  default: { isOpen: false },
});

export default activateThreadViewState;

// Controls

export const useOpenActivateThreadView = () => {
  const setState = useSetRecoilState(activateThreadViewState);
  return useCallback(() => {
    setState({ isOpen: true });
  }, [setState]);
};

export const useCloseActivateThreadView = () => {
  const setState = useSetRecoilState(activateThreadViewState);
  return useCallback(() => {
    setState({ isOpen: false });
  }, [setState]);
};
