import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import BankAccountRep from "reps/BankAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { z } from "zod";

const schema = z.object({
  amount: z.number().positive(),
  drawdownFromCapitalAccount: z.custom<CapitalAccountSummaryRep.Complete>(
    (value) => Boolean(value),
    "Please select a capital account."
  ),
  drawdownToBankAccount: z.custom<BankAccountRep.Complete>(
    (value) => Boolean(value),
    "Please select a bank account."
  ),
});

export type DrawdownAmountFormInputs = z.infer<typeof schema>;

type Params = {
  defaultValues: DrawdownAmountFormInputs;
};

const useDrawdownAmountForm = ({ defaultValues }: Params) => {
  return useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });
};

export default useDrawdownAmountForm;
