import emptyImg from "assets/empty-state.svg";
import dayjs from "dayjs";
import { FC, Suspense, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AssignedBillSummaryRep from "reps/AssignedBillSummaryRep";
import BillRep from "reps/BillRep";
import assignedBillsQueryHooks from "resources/bills/queries/assignedBillsQueryHooks";
import Button from "ui/inputs/Button";
import { DISABLE_SCROLL_TO_TOP_STATE } from "ui/navigation/ScrollToTopOnNavigate";
import DateCell from "ui/table/DateCell";
import EmptyState from "ui/table/EmptyState";
import MoneyCell from "ui/table/MoneyCell";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import TextCell from "ui/table/TextCell";
import WarningCellDecorator from "ui/table/WarningCellDecorator";
import { Paragraph } from "ui/typography";
import { formatBankingDate } from "utils/date";
import cn from "utils/tailwind/cn";

export const assignedBillRequestedColumn: Column<AssignedBillSummaryRep.Complete> = {
  title: "Requested",
  cellRender: (assignedBill) => {
    const requestedAt = assignedBill.billApproval.requestedAt;
    if (!requestedAt) {
      return <NotFoundCell />;
    }
    return (
      <DateCell
        date={requestedAt}
        hideCurrentYear
        tooltip={<Paragraph>{formatBankingDate(requestedAt)}</Paragraph>}
        darken
      />
    );
  },
};

export const assignedBillInvoiceNumberColumn: Column<AssignedBillSummaryRep.Complete> = {
  title: "Invoice #",
  cellRender: (assignedBill) => {
    const { invoiceNumber } = assignedBill;
    if (!invoiceNumber) {
      return <NotFoundCell />;
    }
    return <TextCell darken>{invoiceNumber}</TextCell>;
  },
};

export const assignedBillPayeeColumn: Column<AssignedBillSummaryRep.Complete> = {
  title: "Payee",
  cellRender: (assignedBill) => {
    const { payee } = assignedBill;
    if (!payee) {
      return <NotFoundCell />;
    }
    return (
      <TextCell className="font-medium" darken>
        {payee.name}
      </TextCell>
    );
  },
};

type AssignedBillDueCellProps = {
  assignedBill: AssignedBillSummaryRep.Complete;
};

const AssignedBillDueCell: FC<AssignedBillDueCellProps> = ({ assignedBill }) => {
  const { invoiceDueDate, state } = assignedBill;
  const isOverdue = useMemo(() => {
    if (!invoiceDueDate || state !== BillRep.State.Open) {
      return false;
    }
    return dayjs(invoiceDueDate).isBefore(dayjs());
  }, [invoiceDueDate, state]);

  if (!invoiceDueDate) {
    return <NotFoundCell />;
  }

  const dateCell = (
    <DateCell
      className={cn(isOverdue && "text-yellow-700")}
      date={invoiceDueDate}
      hideCurrentYear
      tooltip={<Paragraph>{formatBankingDate(invoiceDueDate)}</Paragraph>}
    />
  );

  return isOverdue ? (
    <WarningCellDecorator tooltip={<Paragraph>This bill is overdue</Paragraph>}>
      {dateCell}
    </WarningCellDecorator>
  ) : (
    dateCell
  );
};

export const assignedBillDueColumn: Column<AssignedBillSummaryRep.Complete> = {
  title: "Due",
  cellRender: (assignedBill) => <AssignedBillDueCell assignedBill={assignedBill} />,
};

export const assignedBillAmountColumn: Column<AssignedBillSummaryRep.Complete> = {
  title: "Bill amount",
  align: TableColumnAlignment.RIGHT,
  cellRender: (assignedBill) => {
    const { amount } = assignedBill;
    if (!amount) {
      return <NotFoundCell />;
    }
    return <MoneyCell amount={amount.amount} currencyCode={amount.currency} darken />;
  },
};

const columns: Column<AssignedBillSummaryRep.Complete>[] = [
  assignedBillRequestedColumn,
  assignedBillInvoiceNumberColumn,
  assignedBillPayeeColumn,
  assignedBillDueColumn,
  assignedBillAmountColumn,
  {
    title: "Actions",
    cellRender: () => (
      <Button variant="secondary" size="sm">
        Approve
      </Button>
    ),
  },
];

const AssignedRequestedBillsView: FC = () => {
  const { data: assignedRequestedBills, isPending } = assignedBillsQueryHooks.useQuery({
    approvalStatus: "Requested",
  });
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <div>
      <Suspense fallback={<Table.Loading columns={columns} isLoadingRows={2} />}>
        {!isPending && assignedRequestedBills && !assignedRequestedBills.length ? (
          <EmptyState image={emptyImg} primaryText="Bills needing your approval will show here." />
        ) : (
          <Table
            isLoading={isPending}
            rowKey={(assignedBill) => assignedBill.billId}
            onRowClick={({ billId }) =>
              navigate(`/bills/${billId}${search}`, { state: DISABLE_SCROLL_TO_TOP_STATE })
            }
            data={assignedRequestedBills}
            columns={columns}
          />
        )}
      </Suspense>
    </div>
  );
};

export default AssignedRequestedBillsView;
