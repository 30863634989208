import dayjs from "dayjs";
import { FC } from "react";

const TotalYieldInfoTooltipContent: FC = () => {
  const today = dayjs();
  const nextMonth = dayjs().add(1, "month");
  return (
    <>
      Total yield earned across all accounts for {today.year()} to-date. Yield accrues daily and is
      paid out during first week of every month. This yield total will update in the first week of{" "}
      {nextMonth.format("MMMM")}.
    </>
  );
};

export default TotalYieldInfoTooltipContent;
