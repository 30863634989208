import { captureMessage } from "@sentry/react";
import UserRoleRep from "reps/UserRoleRep";

/**
 * Gets the user role display name by the user role guids.
 *
 * NB(alex): the BE returns an array of user guids, but the UI only expects one role per user,
 * so for now we just say "Multiple roles" in the off chance that a user has more than one role.
 * Slack thread for context: https://highbeamco.slack.com/archives/C059DBA38M8/p1690313979842549
 */
const getUserRoleNameForRoleGuids = (
  userRoleGuids: string[],
  userRoles: UserRoleRep.Complete[]
) => {
  const hasMoreThanOneUserRoleGuid = userRoleGuids.length > 1;
  if (hasMoreThanOneUserRoleGuid) {
    return "Multiple roles";
  }
  const userRoleGuid = userRoleGuids[0];
  if (!userRoleGuid) {
    captureMessage("Alert(alex): No user role guid found");
  }
  const userRole = userRoles.find((userRole) => userRole.guid === userRoleGuid);

  if (!userRole) {
    captureMessage(`Alert(alex): No user role found for userRoleGuid: ${userRoleGuid}`);
    return "";
  }

  return userRole.name;
};

export default getUserRoleNameForRoleGuids;
