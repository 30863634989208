import Tabs, { Tab } from "ui/navigation/Tabs";

type Props<TFilterTab extends string = string> = {
  tabs: readonly Tab<TFilterTab>[];
  activeTab: string;
  setActiveTab: (tabId: TFilterTab) => void;
};

const FilterTabs = <TFilterTab extends string = string>({
  tabs,
  activeTab,
  setActiveTab,
}: Props<TFilterTab>) => {
  return (
    <Tabs
      className="w-full tablet:w-fit"
      variant="rounded"
      tabs={tabs}
      noBorder
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};

export default FilterTabs;
