import ErrorPage from "modules/error/pages/ErrorPage";
import LoadingPage from "modules/loading/pages/LoadingPage";
import React, { useState } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMountEffect from "utils/customHooks/useMountEffect";

/**
 * See [https://shopify.dev/docs/apps/build/authentication-authorization/access-tokens/authorization-code-grant].
 */
const ShopifyRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/installation-request" element={<InstallationRequest />} />
      <Route path="/authorization-code" element={<AuthorizationCode />} />
    </Routes>
  );
};

export default ShopifyRouter;

const InstallationRequest: React.FC = () => {
  const businessGuid = useBusinessGuid({ loginRequired: false });
  const highbeamApi = useHighbeamApi({ authMode: "maybeAuthenticated" });
  const [params] = useSearchParams();

  const [error, setError] = useState<any>();

  useMountEffect(() => {
    (async () => {
      try {
        params.delete("hmac"); // Shopify tells us to DELETE this.
        const shop = params.get("shop")!;
        const storeName = shop.replace(".myshopify.com", "");
        const { url } = await highbeamApi.shopifyConnection.createRedirectUrl(
          businessGuid,
          `${window.location.origin}/shopify/authorization-code`,
          storeName
        );
        // eslint-disable-next-line require-atomic-updates
        window.location.href = url;
      } catch (e) {
        setError(e);
      }
    })();
  });

  if (error) return <ErrorPage error={error} />;
  return <LoadingPage location={InstallationRequest.name} />;
};

const AuthorizationCode: React.FC = () => {
  const businessGuid = useBusinessGuid({ loginRequired: false });
  const highbeamApi = useHighbeamApi({ authMode: "maybeAuthenticated" });
  const [params] = useSearchParams();

  const [redirect, setRedirect] = useState<string>();
  const [error, setError] = useState<any>();

  useMountEffect(() => {
    (async () => {
      try {
        const hmac = params.get("hmac")!;
        params.delete("hmac"); // Shopify tells us to DELETE this.
        params.sort();
        await highbeamApi.shopifyConnection.create(
          params.get("code")!,
          businessGuid,
          hmac,
          encodeURI(params.toString()),
          params.get("shop")!,
          params.get("state")!
        );
        setRedirect("/onboarding/connect-stores"); // TODO: Get redirect from query params.
      } catch (e) {
        setError(e);
      }
    })();
  });

  if (error) return <ErrorPage error={error} />;
  if (redirect) return <Navigate to={redirect} />;
  return <LoadingPage location={AuthorizationCode.name} />;
};
