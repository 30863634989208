import React from "react";
import { useCurrentBusinessMember } from "resources/business-members/queries/businessMemberQueryHooks";
import useBusiness from "resources/business/queries/useBusiness";
import getBusinessSafeDisplayName from "resources/business/utils/getBusinessSafeDisplayName";
import colors from "styles/colors";
import UserAvatar from "ui/data-display/UserAvatar";
import VirtualButton from "ui/inputs/VirtualButton";
import Text from "ui/typography/Text";

type Props = {
  onOpenUserMenu: () => void;
};

const UserAvatarMenuButton: React.FC<Props> = ({ onOpenUserMenu: handleOpenUserMenu }) => {
  const business = useBusiness();
  const businessMember = useCurrentBusinessMember();

  const businessDisplayName = getBusinessSafeDisplayName(business);
  const businessMemberDisplayName = businessMember?.displayName;
  const businessMemberInitials = businessMember?.initials ?? "";

  return (
    <VirtualButton onClick={handleOpenUserMenu} className="flex items-center gap-x-4">
      <div className="text-end">
        {businessMemberDisplayName && (
          <Text size={12} weight="medium">
            {businessMemberDisplayName}
          </Text>
        )}
        <Text size={12} weight="regular" color={colors.grey[600]}>
          {businessDisplayName}
        </Text>
      </div>
      <UserAvatar color="purple-light" size={32} initials={businessMemberInitials} />
    </VirtualButton>
  );
};

export default UserAvatarMenuButton;
