import { ClockCounterClockwise } from "@phosphor-icons/react";
import Button from "ui/inputs/Button";
import useIsAllowedToSyncInsightsSheet from "utils/permissions/useIsAllowedToSyncInsightsSheet";

import { useCashFlowSyncSheetModal } from "../CashFlowSyncSheetModal/state/cashFlowSyncSheetModalState";

const CashFlowSyncSheetButton = () => {
  const isAllowedToSyncInsightsSheet = useIsAllowedToSyncInsightsSheet();
  const { open: openModal } = useCashFlowSyncSheetModal();
  return (
    <Button variant="secondary" disabled={!isAllowedToSyncInsightsSheet} onClick={openModal}>
      <ClockCounterClockwise weight="light" size={24} />
      Sync to Google sheet
    </Button>
  );
};

export default CashFlowSyncSheetButton;
