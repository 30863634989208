import BankApplicationRep from "reps/BankApplicationRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BankApplicationApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async createBankApplication(businessGuid: string): Promise<BankApplicationRep.Complete> {
    const url = `/businesses/${businessGuid}/bank-applications`;
    return (await this.api.post<BankApplicationRep.Complete>(url))!;
  }

  async getByBusiness(businessGuid: string): Promise<BankApplicationRep.Complete[]> {
    const url = `/businesses/${businessGuid}/bank-applications`;
    return (await this.api.get<BankApplicationRep.Complete[]>(url))!;
  }
}
