import { useNavigate } from "react-router-dom";
import Banner from "ui/data-display/Banner";
import InfoIcon from "ui/icons/InfoIcon";
import Button from "ui/inputs/Button";

const ConnectPlaidBanner = () => {
  const navigate = useNavigate();
  const redirectToSettingsBanksAndCards = () => {
    navigate("/settings/banks-and-cards");
  };

  return (
    <Banner
      color="white"
      icon={<InfoIcon size={24} variant="info" />}
      title="Connect other accounts"
      paragraph="Connect your other bank accounts for easy transfers between them and Highbeam."
      className="mt-8"
      button={
        <Button variant="primary" size="sm" onClick={redirectToSettingsBanksAndCards}>
          Connect an account
        </Button>
      }
    />
  );
};

export default ConnectPlaidBanner;
