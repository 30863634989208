import { useMutation } from "@tanstack/react-query";
import { useCancelPaymentModal } from "components/Transactions/TransactionInfoFlexpane/TransactionInfoFlexpaneHeader/CancelPaymentModal/state/cancelPaymentModalState";
import useMfa, { MfaCanceledError } from "modules/mfa/useMfa";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

type Variables = {
  paymentGuid: string;
};

const useCancelPaymentMutation = () => {
  const highbeamApi = useHighbeamApi();
  const { close: closeCancelPaymentModal } = useCancelPaymentModal();
  const { mfa } = useMfa();

  return useMutation({
    mutationFn: async ({ paymentGuid }: Variables) => {
      await mfa();
      return highbeamApi.paymentAction.cancel(paymentGuid);
    },
    onError: (err) => {
      if (err instanceof MfaCanceledError) return;
      notify("error", "There was an error canceling the payment. Please try again.");
    },
    onSuccess: () => {
      closeCancelPaymentModal();
      notify("success", "Payment canceled");
    },
  });
};

export default useCancelPaymentMutation;
