import PayeeRep from "reps/PayeeRep";

const hasLastTransferAt = (
  payee: PayeeRep.Complete
): payee is PayeeRep.Complete & { lastTransferAt: string } => {
  return payee.lastTransferAt !== undefined;
};

const filterAndSortPayeesByLastTransferAt = (payees: PayeeRep.Complete[]) => {
  return payees.filter(hasLastTransferAt).sort((a, b) => {
    return new Date(b.lastTransferAt).valueOf() - new Date(a.lastTransferAt).valueOf();
  });
};

export default filterAndSortPayeesByLastTransferAt;
