import ApSettingsRep from "reps/ApSettingsRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class ApSettingsApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(settings: ApSettingsRep.Creator): Promise<ApSettingsRep.Complete> {
    const url = `/accounts-payable/settings`;
    return (await this.api.post<ApSettingsRep.Complete>(url, settings))!;
  }

  async get(businessGuid: string): Promise<ApSettingsRep.Complete> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/accounts-payable/settings?${queryParams}`;
    return (await this.api.get<ApSettingsRep.Complete>(url))!;
  }

  async update(
    apSettingsId: string,
    updater: ApSettingsRep.Updater
  ): Promise<ApSettingsRep.Complete> {
    const url = `/accounts-payable/settings/${apSettingsId}`;
    return (await this.api.patch<ApSettingsRep.Complete>(url, updater))!;
  }
}
