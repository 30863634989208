import { Card } from "@highbeam/unit-node-sdk";
import { ArrowRight } from "@phosphor-icons/react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardAvatarBarByCardId } from "resources/cards/components/CardAvatarBar";
import { getCardholderName } from "resources/cards/utils";
import UserAvatarBar from "ui/data-display/UserAvatarBar";
import PageIndicator from "ui/navigation/PageIndicator";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import { Span } from "ui/typography";
import getInitials from "utils/string/getInitials";

const columns: Column<Card>[] = [
  {
    title: "Name",
    cellRender: ({ id }) => {
      return <CardAvatarBarByCardId cardId={id} />;
    },
  },
  {
    title: "Cardholder",
    cellRender: (card) => {
      const cardholderName = getCardholderName(card);

      if (!cardholderName) {
        return <NotFoundCell />;
      }

      return (
        <UserAvatarBar
          initials={getInitials(cardholderName)}
          fullName={cardholderName}
          avatarSize={32}
        />
      );
    },
  },
  {
    title: "Action",
    align: TableColumnAlignment.RIGHT,
    cellRender: () => {
      return (
        <Span className="flex items-center gap-x-1 px-3 py-2 text-xs font-medium text-purple-500">
          View card details <ArrowRight size={16} />
        </Span>
      );
    },
  },
];

const MAX_CARDS_PER_PAGE = 3;

type Props = {
  cards: Card[] | undefined;
};

const CapitalAssociatedCardsTableView: FC<Props> = ({ cards }) => {
  const navigate = useNavigate();

  const numCards = cards?.length ?? 0;
  const totalPages = Math.ceil(numCards / MAX_CARDS_PER_PAGE);

  const [currentPage, setCurrentPage] = useState(0);

  const paginatedCards = cards?.slice(
    currentPage * MAX_CARDS_PER_PAGE,
    (currentPage + 1) * MAX_CARDS_PER_PAGE
  );

  return (
    <>
      <Table
        columns={columns}
        data={paginatedCards}
        rowKey="id"
        onRowClick={(card) => {
          navigate(`/cards/${card.id}`);
        }}
      />

      {totalPages > 1 && (
        <PageIndicator
          className="mt-6"
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};

export default CapitalAssociatedCardsTableView;
