import { Info, Money } from "@phosphor-icons/react";
import checkIcon from "assets/check-deposit-icon.svg";
import successGif from "assets/success.gif";
import PaymentDetailsCard from "components/PaymentDetailsCard";
import { CHECK_DEPOSIT_CUTOFF_WARNING } from "config/constants/banking";
import { PaymentMethod } from "pages/SendMoneyPage/utils";
import { useNavigate } from "react-router-dom";
import Banner from "ui/data-display/Banner";
import Button from "ui/inputs/Button";
import { Heading2, Link, Paragraph } from "ui/typography";

type Props = {
  amount: number;
  to: React.ReactNode;
  description: string;
  makeAnotherDeposit: () => void;
};

const CheckDepositProcessed: React.FC<Props> = ({
  amount,
  to,
  description,
  makeAnotherDeposit,
}) => {
  const navigate = useNavigate();
  return (
    <div className="max-w-4xl">
      <div className="mb-5">
        <img className="-ml-6 size-20" src={successGif} alt="success" />
        <Heading2 className="mb-1 font-bold">Check deposit is processing</Heading2>

        <Paragraph className="text-sm">
          You can track the status of the deposit under{" "}
          <Link href="/accounts/transactions">
            <Money size={14} className="mb-0.5 ml-0.5 align-middle" /> Accounts / Transactions.
          </Link>
        </Paragraph>
      </div>

      <PaymentDetailsCard
        paymentMethod={PaymentMethod.CHECK}
        iconBesideAmount={<img src={checkIcon} alt="check icon" className="w-4" />}
        amountInCents={amount}
        to={to}
        descriptionMessage={description}
        toLabel={"Deposit to"}
      />
      <div className="my-5 flex flex-col gap-x-5 gap-y-4 tablet:flex-row">
        <Button variant="tertiary" onClick={makeAnotherDeposit}>
          Make another deposit
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            navigate("/accounts/transactions");
          }}
          autoFocus
        >
          Track status
        </Button>
      </div>

      <Banner icon={<Info />} color="blue" paragraph={CHECK_DEPOSIT_CUTOFF_WARNING} />
    </div>
  );
};

export default CheckDepositProcessed;
