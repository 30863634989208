import BillRep from "reps/BillRep";
import BillSummaryRep from "reps/BillSummaryRep";

const getIsBillFullyPaid = (bill: BillSummaryRep.Complete): boolean => {
  return (
    bill.state === BillRep.State.Paid ||
    bill.state === BillRep.State.Closed ||
    (bill.remainingAmount?.amount !== undefined && Number(bill.remainingAmount.amount) === 0) // State might not be set to `Paid` if payments are scheduled.
  );
};

export default getIsBillFullyPaid;
