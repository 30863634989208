import FullPageLayout from "layouts/FullPageLayout";
import { FC, ReactNode } from "react";
import Card from "ui/data-display/Card";
import Text from "ui/typography/Text";

type Props = {
  children: ReactNode;
};

const BusinessDisambiguatorLayout: FC<Props> = ({ children }) => {
  return (
    <FullPageLayout>
      <Card className="mx-4 mb-64 w-full max-w-screen-mobile-phone p-8 @container">
        <Text as="h3" className="mb-8" size={20} weight="bold">
          Select business
        </Text>
        <div className="flex flex-col">{children}</div>
      </Card>
    </FullPageLayout>
  );
};

export default BusinessDisambiguatorLayout;
