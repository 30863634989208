import { CreditCard, Money, Percent, ShieldCheck } from "@phosphor-icons/react";
import useMfa from "modules/mfa/useMfa";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import IconTile from "ui/icons/IconTile";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

import useSetThreadActionItemMutation from "../migrateToThreadActionItems/useSetThreadActionItemMutation";

import styles from "./ActivateThreadView.module.scss";
import useForceMigrateToThreadView from "./hooks/useForceMigrateToThreadView";
import useMigrateToThreadMutation from "./hooks/useMigrateToThreadMutation";
import { useCloseActivateThreadView } from "./state/activateThreadViewState";

// TODO(alex): Move to shared constants file?
const links = {
  legal: {
    DEPOSIT_ACCOUNT_AGREEMENT_THREAD_BANK:
      "https://www.highbeam.co/legal/deposit-account-agreement-thread-bank",
    DEBIT_CARD_AGREEMENT_THREAD_BANK:
      "https://www.highbeam.co/legal/debit-card-agreement-thread-bank",
    SAVINGS_ACCOUNT_AGREEMENT_THREAD_BANK:
      "https://www.highbeam.co/legal/savings-account-agreement-thread-bank",
  },
};

const ActivateThreadView = () => {
  const { mfa } = useMfa();
  const { segmentTrack } = useSegment();
  const closeActivateThreadView = useCloseActivateThreadView();
  const { mutate: setThreadActionItemMutation } = useSetThreadActionItemMutation();
  const { mutate: migrateToThread, isPending } = useMigrateToThreadMutation();
  const forceMigrateToThread = useForceMigrateToThreadView();

  const handleActivateThreadAccounts = () => {
    segmentTrack(SEGMENT_EVENTS.ACTIVATE_THREAD_CLICKED);
    migrateToThread();
  };

  const handleFinishLater = () => {
    setThreadActionItemMutation({ actionItemName: "ActivateThreadView", state: "Dismissed" });
    closeActivateThreadView();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <IconTile className="size-14" icon={<Money weight="thin" />} />
        </div>

        <div className={styles.header}>
          <Text weight="bold" size={20}>
            Activate Thread bank accounts
          </Text>
          <Text weight="regular" size={16} color={colors.grey[500]}>
            Highbeam is partnering with Thread bank to give you access to features including:
          </Text>
        </div>

        <div className={styles.valuePropsContainer}>
          <div className={styles.valueProp}>
            <ShieldCheck size={24} weight="thin" color={colors.grey[600]} />
            <Text size={14} color={colors.grey[600]}>
              $3M FDIC insurance
            </Text>
          </div>
          <div className={styles.valueProp}>
            <Percent size={24} weight="thin" color={colors.grey[600]} />
            <Text size={14} color={colors.grey[600]}>
              Increased APY up to 4.29%
            </Text>
          </div>
          <div className={styles.valueProp}>
            <CreditCard size={24} weight="thin" color={colors.grey[600]} />
            <Text size={14} color={colors.grey[600]}>
              The new Highbeam Card
            </Text>
          </div>
        </div>

        <div className={styles.explainerContainer}>
          <Text size={16} color={colors.grey[500]}>
            After activating, you will see new <strong>Primary</strong> and{" "}
            <strong>High-yield</strong> accounts in Highbeam.{" "}
            {!forceMigrateToThread && "Your current accounts and funds will not be affected."}
          </Text>
        </div>

        <Divider className="w-full" />

        <div className={styles.footnoteContainer}>
          <Text size={14} color={colors.grey[500]}>
            Please read the{" "}
            <a
              target="_blank"
              href={links.legal.DEPOSIT_ACCOUNT_AGREEMENT_THREAD_BANK}
              rel="noreferrer"
            >
              Deposit
            </a>
            ,{" "}
            <a target="_blank" href={links.legal.DEBIT_CARD_AGREEMENT_THREAD_BANK} rel="noreferrer">
              Debit card
            </a>
            , and{" "}
            <a
              target="_blank"
              href={links.legal.SAVINGS_ACCOUNT_AGREEMENT_THREAD_BANK}
              rel="noreferrer"
            >
              Savings account
            </a>{" "}
            agreements and click “Activate Thread accounts” to create the accounts.
          </Text>
        </div>

        <div className={styles.buttonsContainer}>
          <Button
            variant="primary"
            onClick={() => mfa().then(handleActivateThreadAccounts)}
            isLoading={isPending}
          >
            Activate Thread accounts
          </Button>
          {!forceMigrateToThread && (
            <Button variant="default" onClick={handleFinishLater}>
              Finish later
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivateThreadView;
