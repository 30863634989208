import CapitalRepaymentAmountRep from "reps/CapitalRepaymentAmountRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CapitalRepaymentAmountApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(
    businessGuid: string,
    capitalAccountGuid: string
  ): Promise<CapitalRepaymentAmountRep.Complete> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      capitalAccountGuid: capitalAccountGuid,
    });

    const url = `/capital-repayment/repayment-amounts?${qp}`;

    return (await this.api.get<CapitalRepaymentAmountRep.Complete>(url))!;
  }
}
