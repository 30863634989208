import { atom, selector } from "recoil";

import revenueForecastState from "../revenueForecast";
import snapshotState from "../snapshot";

const revenueProjectionInputsState = atom<string[]>({
  key: "compareCreditOffers/revenueProjectionInputsState",
  default: selector({
    key: "compareCreditOffers/revenueProjectionInputsState/default",
    get: ({ get }) => {
      // First, try to get the saved snapshot revenue projections
      const snapshotRevenueProjections = get(snapshotState)?.revenueProjections ?? null;
      if (snapshotRevenueProjections) {
        return snapshotRevenueProjections.map((x) => x.amount.toString());
      }

      // If there aren't any saved snapshot revenue projections, try to get the revenue forecast for this business (only works when authenticated)
      const revenueForecast = get(revenueForecastState);
      if (revenueForecast) {
        return revenueForecast.revenueProjections.map((x) =>
          // NB(alex): Intentionally converting falsy values including 0s to empty strings
          x.amount ? x.amount.toString() : ""
        );
      }

      return Array(6).fill("");
    },
  }),
});

export default revenueProjectionInputsState;
