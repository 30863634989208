import { Card } from "@highbeam/unit-node-sdk";
import CardRep from "reps/CardRep";

const getCardTypeByCard = (card: Card): CardRep.CardType => {
  switch (card.type) {
    case "individualVirtualDebitCard":
    case "businessVirtualDebitCard":
      return "VIRTUAL";
    case "individualDebitCard":
    case "businessDebitCard":
      return "PHYSICAL";
    case "businessVirtualCreditCard":
      return "VIRTUAL_CREDIT";
    case "businessCreditCard":
      return "PHYSICAL_CREDIT";
  }
};

export default getCardTypeByCard;
