import { Dispatch, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Wraps useSearchParams to specify a single value and preserves other search params on update.
 */
const useSearchParamValue = (
  key: string,
  defaultValue: string = ""
): [string, Dispatch<SetStateAction<string>>] => {
  const [searchParams, setSearchParams] = useSearchParams({
    ...(defaultValue && { [key]: defaultValue }),
  });

  const value = searchParams.get(key) || "";

  const setValue = (value: SetStateAction<string>) => {
    // It's important for us to redefine this here to prevent overwriting params if multiple setters get called at once.
    const currentParams = new URLSearchParams(window.location.search);

    if (typeof value === "function") {
      return setSearchParams({
        ...Object.fromEntries(currentParams.entries()),
        [key]: value(currentParams.get(key) ?? defaultValue),
      });
    }

    return setSearchParams({
      ...Object.fromEntries(currentParams.entries()),
      [key]: value,
    });
  };

  return [value, setValue];
};

export default useSearchParamValue;
