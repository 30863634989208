import { FC, ReactNode, createContext, useContext } from "react";
import useSearchParamOption from "utils/search-params/useSearchParamOption";
import useSearchParamValue from "utils/search-params/useSearchParamValue";

export const CASH_FLOW_DURATION_OPTIONS = [
  "last-3-months",
  "last-6-months",
  "last-12-months",
  "year-to-date",
] as const;

export type CashFlowDurationOption = (typeof CASH_FLOW_DURATION_OPTIONS)[number];

export const CASH_FLOW_TABS = ["money-in", "money-out"] as const;

export type CashFlowTab = (typeof CASH_FLOW_TABS)[number];

type CashFlowContextState = {
  duration: CashFlowDurationOption;
  setDuration: (value: CashFlowDurationOption) => void;
  activeTab: CashFlowTab;
  setActiveTab: (value: CashFlowTab) => void;
  search: string;
  setSearch: (value: string) => void;
};

const CashFlowContext = createContext<CashFlowContextState>({} as CashFlowContextState);

type Props = {
  children: ReactNode;
};

const CashFlowProvider: FC<Props> = ({ children }) => {
  const [duration, setDuration] = useSearchParamOption(
    "duration",
    CASH_FLOW_DURATION_OPTIONS,
    CASH_FLOW_DURATION_OPTIONS[0]
  );

  const [activeTab, setActiveTab] = useSearchParamOption(
    "direction",
    CASH_FLOW_TABS,
    CASH_FLOW_TABS[0]
  );

  const [search, setSearch] = useSearchParamValue("search", "");

  return (
    <CashFlowContext.Provider
      value={{
        duration: duration,
        setDuration: setDuration,
        activeTab: activeTab,
        setActiveTab: setActiveTab,
        search: search,
        setSearch: setSearch,
      }}
    >
      {children}
    </CashFlowContext.Provider>
  );
};

export default CashFlowProvider;

export const useCashFlowContext = () => {
  const cashFlowContext = useContext(CashFlowContext);

  return {
    ...cashFlowContext,
  };
};
