import { z } from "zod";

export const ACCOUNT_NUMBER_MIN_LENGTH = 3;
export const ACCOUNT_NUMBER_MAX_LENGTH = 17;

const accountNumberSchema = z
  .string({
    required_error: "Please enter an account number.", // eslint-disable-line camelcase
  })
  .min(1, "Please enter an account number.")
  .min(ACCOUNT_NUMBER_MIN_LENGTH, "Please enter a valid account number.")
  .max(ACCOUNT_NUMBER_MAX_LENGTH, "Please enter a valid account number.")
  .refine((value) => /^[0-9]+$/.test(value), {
    message: "Please enter a valid account number.",
  });

export default accountNumberSchema;
