import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

export const ROOT_CHARGE_CARD_ACCOUNT_QUERY_KEY = "chargeCardAccount-root";

type Params = {
  capitalAccountGuid: string | null;
};

const chargeCardAccountQueryHooks = makeQueryHooks({
  rootName: ROOT_CHARGE_CARD_ACCOUNT_QUERY_KEY,
  name: "chargeCardAccount",
  useQueryVariables: ({ capitalAccountGuid }: Params) => {
    const businessGuid = useBusinessGuid();
    return { businessGuid, capitalAccountGuid };
  },
  useQueryFnMaker: ({ businessGuid, capitalAccountGuid }) => {
    const highbeamApi = useHighbeamApi();
    return () => {
      if (!capitalAccountGuid) {
        return null;
      }
      return highbeamApi.chargeCardAccount.get(businessGuid, capitalAccountGuid);
    };
  },
});

export default chargeCardAccountQueryHooks;
