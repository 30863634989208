import MultiStep from "layouts/MultiStep";
import { FC } from "react";

import { useReviewLineOfferContext } from "../context/ReviewLineOfferProvider";

type Props = {
  disabled?: boolean;
};

const ReviewLineOfferBackButton: FC<Props> = ({ disabled }) => {
  const { prevPathname } = useReviewLineOfferContext();

  if (!prevPathname) {
    return null;
  }

  return (
    <MultiStep.Controls.BackButtonLink
      disabled={!Boolean(prevPathname) || disabled}
      to={prevPathname}
    />
  );
};

export default ReviewLineOfferBackButton;
