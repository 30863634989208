import AccountingAccountRep from "reps/AccountingAccountRep";

import { EXPENSE_CATEGORY } from "./config";

type AccountingAccountWithDepth = [AccountingAccountRep.Complete, number];

type GroupedAccountingAccounts = {
  category: string;
  accountingAccountsWithDepth: AccountingAccountWithDepth[];
};

const makeParentChildMap = (accountingAccounts: AccountingAccountRep.Complete[]) => {
  // The null key is for accounts with no parent.
  const parentChildMap = new Map<string | null, AccountingAccountRep.Complete[]>();

  accountingAccounts.forEach((accountingAccount) => {
    const { parentRutterId } = accountingAccount;
    if (parentChildMap.has(parentRutterId)) {
      parentChildMap.get(parentRutterId)!.push(accountingAccount);
    } else {
      parentChildMap.set(parentRutterId, [accountingAccount]);
    }
  });

  return parentChildMap;
};

export const sortAccountingAccountsByName = (accountingAccounts: AccountingAccountRep.Complete[]) =>
  accountingAccounts.sort((a, b) => a.name.localeCompare(b.name));

const makeHierarchicallySortedAccountingAccounts = (
  accountingAccounts: AccountingAccountRep.Complete[]
): AccountingAccountWithDepth[] => {
  const parentChildMap = makeParentChildMap(accountingAccounts);

  // Recursively generate a sorted list of accounting accounts by hierarchy,
  // starting with the accounts that don't have a parent.
  const generate = (parentRutterId: string | null, depth: number): AccountingAccountWithDepth[] => {
    const childAccountingAccounts = parentChildMap.get(parentRutterId) || [];

    return sortAccountingAccountsByName(childAccountingAccounts).flatMap((accountingAccount) => [
      [accountingAccount, depth],
      ...generate(accountingAccount.rutterId, depth + 1),
    ]);
  };

  return generate(null, 0);
};

const sortAccountingAccountsGroupsByCategory = (
  accountingAccountsGroups: GroupedAccountingAccounts[]
) =>
  accountingAccountsGroups.sort((a, b) => {
    const categoryA = a.category.toLowerCase();
    const categoryB = b.category.toLowerCase();

    // Pin "expense" category to the top.
    if (categoryA === EXPENSE_CATEGORY) return -1;
    if (categoryB === EXPENSE_CATEGORY) return 1;

    return categoryA.localeCompare(categoryB);
  });

const makeCategoryMap = (accountingAccounts: AccountingAccountRep.Complete[]) => {
  const categoryMap = new Map<string, AccountingAccountRep.Complete[]>();

  accountingAccounts.forEach((accountingAccount) => {
    const { category } = accountingAccount;
    if (categoryMap.has(category)) {
      categoryMap.get(category)!.push(accountingAccount);
    } else {
      categoryMap.set(category, [accountingAccount]);
    }
  });

  return categoryMap;
};

// Transforms a list of accounting accounts into a list of accounting accounts groups.
// See useAccountingAccountsGroups for an example of how this is used.
export const makeAccountingAccountsGroups = (
  accountingAccounts: AccountingAccountRep.Complete[]
) => {
  const accountingAccountsGroups: GroupedAccountingAccounts[] = [];
  const categoryMap = makeCategoryMap(accountingAccounts);

  for (const [category, accountingAccounts] of categoryMap) {
    accountingAccountsGroups.push({
      category,
      accountingAccountsWithDepth: makeHierarchicallySortedAccountingAccounts(accountingAccounts),
    });
  }

  return sortAccountingAccountsGroupsByCategory(accountingAccountsGroups);
};
