import { Card } from "@highbeam/unit-node-sdk";
import { FC, useRef } from "react";
import ModalV4, { ModalRef } from "ui/overlay/ModalV4";

import ActivateCard from "./ActivateCard";
import Success from "./Success";
import { Step, useActivateCardModal } from "./utils";

type Props = {
  onClose: () => void;
  card: Card;
};

const ActivateCardModal: FC<Props> = ({ onClose, card }) => {
  const modalRef = useRef<ModalRef>(null);
  const { step, handleNext, isLoading, hasError } = useActivateCardModal(onClose, card.id);

  return (
    <ModalV4 onClose={onClose} modalRef={modalRef}>
      {step === Step.ACTIVATE && (
        <ActivateCard card={card} hasError={hasError} onSubmit={handleNext} isLoading={isLoading} />
      )}
      {step === Step.SUCCESS && <Success card={card} />}
    </ModalV4>
  );
};

export default ActivateCardModal;
