import IconWithTooltip from "components/common/IconWithTooltip";

import styles from "./TraceNumberLabel.module.scss";

const TraceNumberLabel = () => (
  <div className={styles.traceLabel}>
    Trace number
    <IconWithTooltip
      color="light"
      tooltip="This number is a proof of payment. You can share it with the recipient for them to locate the payment at their bank."
    />
  </div>
);

export default TraceNumberLabel;
