import HeaderTableCell from "ui/table/TableV2/HeaderTableCell";

import { useTable } from "./TableWrapper";

const Head = () => {
  const { columns } = useTable();
  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <HeaderTableCell key={column.key} column={column} />
        ))}
      </tr>
    </thead>
  );
};

export default Head;
