import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const makeQueryKey = (billId: string, businessGuid: string) => [
  "billComments",
  { billId, businessGuid },
];

const useBillCommentsQueryOptions = (billId: string) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: makeQueryKey(billId, businessGuid),
    queryFn: () => {
      return highbeamApi.billComment.search(billId, businessGuid);
    },
  });
};

const useBillComments = (billId: string) => {
  const { data } = useSuspenseQuery(useBillCommentsQueryOptions(billId));

  return data;
};

export const useBillCommentsCountQuery = (billId: string) => {
  return useQuery({
    ...useBillCommentsQueryOptions(billId),
    select: (data) => data.length,
  });
};

export default useBillComments;
