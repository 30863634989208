import BillCommentRep from "reps/BillCommentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillCommentApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async search(billId: string, businessGuid: string): Promise<BillCommentRep.Complete[]> {
    const queryParams = new URLSearchParams({ billId, businessGuid });
    const url = `/accounts-payable/bill-comments?${queryParams}`;
    return (await this.api.get<BillCommentRep.Complete[]>(url))!;
  }

  async create(body: BillCommentRep.Creator): Promise<BillCommentRep.Complete> {
    const url = "/accounts-payable/bill-comments";
    return (await this.api.post<BillCommentRep.Complete>(url, body))!;
  }
}
