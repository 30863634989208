import GeneralPaymentMetadataRep from "reps/GeneralPaymentMetadataRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class GeneralPaymentMetadataApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(
    businessGuid: string,
    creation: GeneralPaymentMetadataRep.Creation,
    unitCoDepositAccountId?: string,
    transactionId?: string
  ): Promise<GeneralPaymentMetadataRep.Complete> {
    const queryParams = new URLSearchParams({
      businessGuid,
      ...(unitCoDepositAccountId ? { unitCoDepositAccountId } : {}),
      ...(transactionId ? { transactionId } : {}),
    });
    const url = `/payment-metadata?${queryParams.toString()}`;
    return (await this.api.post<GeneralPaymentMetadataRep.Complete>(url, creation))!;
  }

  async get(
    businessGuid: string,
    paymentMetadataGuid: string
  ): Promise<GeneralPaymentMetadataRep.Complete | null> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/payment-metadata/${paymentMetadataGuid}?${queryParams.toString()}`;
    return await this.api.get<GeneralPaymentMetadataRep.Complete>(url);
  }

  async batchGet(
    rep: GeneralPaymentMetadataRep.BatchGet
  ): Promise<GeneralPaymentMetadataRep.Complete[]> {
    const url = "/payment-metadata/batch-get";
    return (await this.api.post<GeneralPaymentMetadataRep.Complete[]>(url, rep))!;
  }

  async update(
    businessGuid: string,
    paymentMetadataGuid: string,
    update: GeneralPaymentMetadataRep.Update
  ): Promise<GeneralPaymentMetadataRep.Complete> {
    const queryParams = new URLSearchParams({
      businessGuid,
    });
    const url = `/payment-metadata/${paymentMetadataGuid}?${queryParams.toString()}`;
    return (await this.api.patch<GeneralPaymentMetadataRep.Complete>(url, update))!;
  }

  async deleteInvoice(
    businessGuid: string,
    paymentMetadataGuid: string
  ): Promise<GeneralPaymentMetadataRep.Complete | null> {
    const queryParams = new URLSearchParams({ businessGuid });
    const url = `/payment-metadata/${paymentMetadataGuid}/invoice?${queryParams.toString()}`;
    return await this.api.delete<GeneralPaymentMetadataRep.Complete>(url);
  }
}
