import useHasPermission from "../useHasPermission";

const useIsAllowedToNavigateToBillPayRoutes = () => {
  const hasAccountsPayableReadPermission = useHasPermission("accountsPayableBill:read");
  const hasAccountsPayableReadAssignedPermission = useHasPermission(
    "accountsPayableBill:readAssigned"
  );
  return hasAccountsPayableReadPermission || hasAccountsPayableReadAssignedPermission;
};

export default useIsAllowedToNavigateToBillPayRoutes;
