import classNames from "classnames";
import { FC } from "react";
import colors from "styles/colors";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./ModalBody.module.scss";

type ModalBodyTextProps = TextProps;

export const ModalBodyText: FC<ModalBodyTextProps> = (props) => {
  return <Text size={16} color={colors.grey[600]} {...props} />;
};

type ModalBodyProps = JSX.IntrinsicElements["div"];

const ModalBody: FC<ModalBodyProps> = ({ children, className, ...divProps }) => {
  return (
    <div className={classNames(className, styles.body)} {...divProps}>
      {children}
    </div>
  );
};

export default Object.assign(ModalBody, {
  Text: ModalBodyText,
});
