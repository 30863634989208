import UserRoleRep from "reps/UserRoleRep";

const roleOrder: Record<UserRoleRep.UserRoleType, number> = {
  Admin: 0,
  AccountsPayable: 1,
  Bookkeeper: 2,
  Employee: 3,
  Custom: 4,
};

// NB(alex): feel free to move this elsewhere if this becomes computationally expensive.
const sortUserRoles = (userRoles: UserRoleRep.Complete[]) => {
  return userRoles
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => roleOrder[a.type] - roleOrder[b.type]);
};

export default sortUserRoles;
