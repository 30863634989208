import { FC, ReactNode } from "react";
import { Paragraph } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import useModalContext, { ModalContextValue } from "./useModalContext";

export const ModalParagraph: FC<PropsWithChildrenAndClassName> = ({ className, children }) => {
  return <Paragraph className={cn("text-md text-grey-600", className)}>{children}</Paragraph>;
};

type ModalBodyProps = JSX.IntrinsicElements["div"] & {
  children: ReactNode | ((renderProps: ModalContextValue) => ReactNode);
};

const ModalBody: FC<ModalBodyProps> = ({ children, className, ...divProps }) => {
  const modalContext = useModalContext();

  return (
    <div className={cn("p-6 text-md", className)} {...divProps}>
      {typeof children === "function" ? children(modalContext) : children}
    </div>
  );
};

export default ModalBody;
