import { CheckCircle } from "@phosphor-icons/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./OfferAcceptedView.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const OfferAcceptedView: FC<Props> = ({ capitalAccountSummary }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <CheckCircle size={48} color={colors.green[500]} />
      <div className={styles.offerAcceptedText}>
        <Text size={20} weight="bold">
          Offer accepted!
        </Text>
        <Text size={16}>Click "View details" to get started.</Text>
      </div>
      <Button variant="primary" onClick={() => navigate(`/capital/${capitalAccountSummary.guid}`)}>
        View details
      </Button>
    </div>
  );
};

export default OfferAcceptedView;
