export enum LineOfCreditAgreementType {
  VariableAprRestrictedV1 = "VariableAprRestrictedV1",
  SecuredV1 = "SecuredV1",
}

interface LineOfCreditAgreement {
  readonly apr: number;
  readonly limit: number;
  readonly agreementType: LineOfCreditAgreementType;
}

export interface LineOfCreditAgreementMetadataRep {
  readonly unsignedAgreementGuid?: string;
  readonly signedAgreementGuid?: string;
  readonly unsignedAgreementOpenedAt?: string;
  readonly agreementSignedAt?: string;
  readonly terms: LineOfCreditAgreement;
}

export interface LineOfCreditAgreementUrlRep {
  readonly unsignedAgreementUrl?: string;
  readonly signedAgreementUrl?: string;
}

export namespace LineOfCreditUpdateUserActionsRep {
  export interface Update {
    readonly unsignedAgreementOpenedAt?: string;
    readonly agreementSignedAt?: string;
  }
}
