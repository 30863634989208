import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";

type Props = {
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
};

const DeleteStoreModal: React.FC<Props> = ({ onClose, onDelete, isLoading }) => (
  <Modal
    title={"Disconnect this store?"}
    showCancel
    buttonText={"Disconnect store"}
    isLoading={isLoading}
    focusPrimaryButton
    onClick={onDelete}
    onClose={onClose}
  >
    <Text size={14}>
      Disconnecting this store will remove the connection that it has with Highbeam. Once
      disconnected, Highbeam won’t be able to get the store data.
    </Text>
  </Modal>
);

export default DeleteStoreModal;
