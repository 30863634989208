import AssignedBillSummaryRep from "reps/AssignedBillSummaryRep";
import BillApprovalRep from "reps/BillApprovalRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillSummaryApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(
    billId: string,
    businessMemberGuid: string
  ): Promise<AssignedBillSummaryRep.Complete | null> {
    const qp = new URLSearchParams({ billId, businessMemberGuid });
    const url = `/accounts-payable/assigned-bills?${qp}`;
    return await this.api.get<AssignedBillSummaryRep.Complete>(url);
  }

  async searchByAssigned(
    businessGuid: string,
    businessMemberGuid: string,
    approvalStatus: BillApprovalRep.Status
  ): Promise<AssignedBillSummaryRep.Complete[]> {
    const qp = new URLSearchParams({
      businessGuid,
      businessMemberGuid,
      approvalStatus,
    });

    const url = `/accounts-payable/assigned-bills?${qp}`;
    return (await this.api.get<AssignedBillSummaryRep.Complete[]>(url))!;
  }
}
