import useHasPermission from "../useHasPermission";

const useIsAllowedToNavigateToPaymentsRoutes = () => {
  const payeeRead = useHasPermission("payee:read");
  const paymentRead = useHasPermission("payment:read");

  return payeeRead || paymentRead;
};

export default useIsAllowedToNavigateToPaymentsRoutes;
