import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import Section from "ui/data-display/Section";
import SectionHeader from "ui/data-display/SectionHeader";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import { Heading2 } from "ui/typography";

import CreatePayeeForm from "./CreatePayeeForm";

const CreatePayeePage = () => (
  <>
    <DashboardHeader>
      <Breadcrumbs>
        <Breadcrumbs.Item to="/payments">Payments</Breadcrumbs.Item>
        <Breadcrumbs.Item to="/payments/payees">Payees</Breadcrumbs.Item>
        <Breadcrumbs.CurrentItem>Create payee</Breadcrumbs.CurrentItem>
      </Breadcrumbs>
    </DashboardHeader>

    <Section>
      <SectionHeader>
        <Heading2>Create payee</Heading2>
      </SectionHeader>
    </Section>

    <CreatePayeeForm />
  </>
);

export default CreatePayeePage;
