import { useQueryClient } from "@tanstack/react-query";
import BankAccountRep from "reps/BankAccountRep";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import bankAccountsQueryHooks, { ROOT_BANK_ACCOUNTS_QUERY_KEY } from "./bankAccountsQueryHooks";

export const getBankAccountsByUnitCoDepositAccountId = (
  bankAccounts: BankAccountRep.Complete[]
) => {
  return new Map(bankAccounts.map((account) => [account.unitCoDepositAccountId, account]));
};

type Params = {
  unitCoDepositAccountId: string;
};

const bankAccountByUnitCoDepositAccountIdQueryHooks = makeQueryHooks({
  rootName: ROOT_BANK_ACCOUNTS_QUERY_KEY,
  name: "bankAccountByUnitCoDepositAccountId",
  useQueryVariables: (params: Params) => params,
  useQueryFnMaker: ({ unitCoDepositAccountId }: Params) => {
    const queryClient = useQueryClient();
    const allBankAccountsQueryOptions = bankAccountsQueryHooks.useQueryOptions({ status: "all" });

    return async () => {
      const openBankAccounts = await queryClient.ensureQueryData(allBankAccountsQueryOptions);
      const openBankAccountsByUnitCoDepositAccountId =
        getBankAccountsByUnitCoDepositAccountId(openBankAccounts);
      return openBankAccountsByUnitCoDepositAccountId.get(unitCoDepositAccountId);
    };
  },
});

export default bankAccountByUnitCoDepositAccountIdQueryHooks;

// Hooks

export const useAllBankAccountsByUnitCoDepositAccountId = () => {
  return bankAccountsQueryHooks.useData({
    status: "all",
    select: getBankAccountsByUnitCoDepositAccountId,
  });
};

export const useOpenBankAccountsByUnitCoDepositAccountId = () => {
  return bankAccountsQueryHooks.useData({
    status: "open",
    select: getBankAccountsByUnitCoDepositAccountId,
  });
};
