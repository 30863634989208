import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import BusinessMemberSummaryRep from "reps/BusinessMemberSummaryRep";
import UserInvitationRep from "reps/UserInvitationRep";
import StartNewBankingApplicationConfirmationModal from "resources/bank-application/dialogs/StartNewBankingApplicationConfirmationModal";
import useShouldShowAddNewBusiness from "resources/businesses/hooks/useShouldShowAddNewBusiness";
import businessGuidState from "state/auth/businessGuid";

import {
  BusinessDisambiguatorBusiness,
  BusinessDisambiguatorUserInvitation,
  BusinessDisambiguatorAddNewBusiness,
} from "./BusinessDisambiguatorBusiness";

type Props = {
  businessSummaries: BusinessMemberSummaryRep.Complete[];
  userInvitations: UserInvitationRep.Complete[];
  redirectTo: string;
};

const BusinessDisambiguatorBusinesses: React.FC<Props> = ({
  businessSummaries,
  userInvitations,
  redirectTo,
}) => {
  const shouldShowAddNewBusiness = useShouldShowAddNewBusiness(businessSummaries);

  const setBusinessGuid = useSetRecoilState(businessGuidState);

  const selectBusiness = (businessGuid: string) => {
    setBusinessGuid(businessGuid);
    window.location.href = redirectTo; // Intended to force a refresh.
  };

  const [isApplicationConfirmationModalOpen, setIsApplicationConfirmationModalOpen] =
    useState(false);

  return (
    <>
      {isApplicationConfirmationModalOpen && (
        <StartNewBankingApplicationConfirmationModal
          onClose={() => setIsApplicationConfirmationModalOpen(false)}
        />
      )}
      {businessSummaries.map((businessSummary) => {
        return (
          <BusinessDisambiguatorBusiness
            key={businessSummary.businessGuid}
            businessSummary={businessSummary}
            onClick={() => selectBusiness(businessSummary.businessGuid)}
          />
        );
      })}
      {userInvitations.map((userInvitation) => {
        return (
          <BusinessDisambiguatorUserInvitation
            key={userInvitation.guid}
            userInvitation={userInvitation}
          />
        );
      })}
      {shouldShowAddNewBusiness && (
        <BusinessDisambiguatorAddNewBusiness
          onClick={() => setIsApplicationConfirmationModalOpen(true)}
        />
      )}
    </>
  );
};

export default BusinessDisambiguatorBusinesses;
