import { captureMessage } from "@sentry/react";

/**
 * Gets all focusable elements on the page
 */
const getFocusableElements = () => {
  return Array.from(
    document.querySelectorAll(
      'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
    )
  );
};

export const focusNextElement = () => {
  const activeElement = document.activeElement;

  if (!activeElement) {
    return captureMessage("No active element found");
  }

  const focusableElements = getFocusableElements();
  const currentIndex = focusableElements.indexOf(activeElement);
  const nextIndex = (currentIndex + 1) % focusableElements.length;
  const nextElement = focusableElements[nextIndex];

  // @ts-expect-error NB(alex): Unsure how to specify the element is focusable.
  nextElement.focus?.();
};

export const focusPreviousElement = () => {
  // Get all focusable elements on the page
  const activeElement = document.activeElement;

  if (!activeElement) {
    return captureMessage("No active element found");
  }

  const focusableElements = getFocusableElements();
  const currentIndex = focusableElements.indexOf(activeElement);
  const previousIndex = (currentIndex - 1) % focusableElements.length;
  const previousElement = focusableElements[previousIndex];

  // @ts-expect-error NB(alex): Unsure how to specify the element is focusable.
  previousElement.focus?.();
};
