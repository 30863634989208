import { Buildings } from "@phosphor-icons/react";
import { FC } from "react";
import getInitials from "utils/string/getInitials";
import cn from "utils/tailwind/cn";

export type BusinessAvatarVariant = "purple" | "grey";

type Props = {
  variant: BusinessAvatarVariant;
  businessName: string | null;
  className?: string;
};

const BusinessAvatar: FC<Props> = ({ variant, businessName, className }) => {
  return (
    <span
      className={cn(
        "flex size-12 items-center justify-center rounded-md border p-3.5 text-md font-bold",
        {
          purple: "border-purple-200 bg-purple-100 text-purple-500",
          grey: "border-grey-200 bg-grey-100 text-grey-600",
        }[variant],
        className
      )}
    >
      {businessName ? getInitials(businessName) : <Buildings size={24} />}
    </span>
  );
};

export default BusinessAvatar;
