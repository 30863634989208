import React, { Suspense } from "react";
import DotsPageLoader from "ui/feedback/DotsLoaderPage";

import Header from "./Header";
import styles from "./Public.module.scss";

type Props = {
  header?: React.ReactNode;
} & React.PropsWithChildren;

const PublicLayout: React.FC<Props> = ({ header = <Header />, children }) => (
  <div className={styles.container}>
    {header}
    <div className={styles.content}>
      <Suspense fallback={<DotsPageLoader variant="page" />}>{children}</Suspense>
    </div>
  </div>
);

export default Object.assign(PublicLayout, {
  Header: Header,
});
