import { useRecoilState } from "recoil";
import feeInputTypeState from "state/compareCreditOffers/inputs/offerInputs/feeInputType";
import { FeeInputType } from "state/compareCreditOffers/inputs/offerInputs/offerInputs";
import Tabs, { Tab } from "ui/navigation/Tabs";

const tabs: Tab<FeeInputType>[] = [
  { id: "Percentage", label: "% Percent" },
  { id: "DollarValue", label: "$ USD" },
];

const PercentOrDollarTabs: React.FC<{ offerIndex: number }> = ({ offerIndex }) => {
  const [feeInputType, setFeeDisplayType] = useRecoilState(feeInputTypeState(offerIndex));
  return (
    <Tabs
      variant="rounded"
      noBorder
      tabs={tabs}
      activeTab={feeInputType}
      setActiveTab={setFeeDisplayType}
    />
  );
};

export default PercentOrDollarTabs;
