import { ContactSupportCommandPaletteOption } from "./types";

type Params = {
  inputValue: string;
};

export const useContactSupportCommandPaletteOptions = ({ inputValue }: Params) => {
  return [
    {
      category: "contact-support",
      isSuggested: true,
      label: inputValue ? `Contact support: ${inputValue}` : "Contact support",
      value: {
        commandName: "chat-widget",
        payload: {
          message: inputValue,
        },
      },
    },
  ] satisfies ContactSupportCommandPaletteOption[];
};
