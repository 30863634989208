import { TaxForm } from "@highbeam/unit-node-sdk";
import { DownloadSimple } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import emptyImg from "assets/empty-state.svg";
import { FC } from "react";
import useDownloadTaxFormPdfMutation from "resources/account-tax-forms/mutations/useDownloadTaxFormMutation";
import useUnitCoTaxFormsQueryOptions from "resources/account-tax-forms/useUnitCoTaxFormsQueryOptions";
import useBusiness from "resources/business/queries/useBusiness";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Button from "ui/inputs/Button";
import EmptyState from "ui/table/EmptyState";
import Table, { TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";

import styles from "../AccountsStatements.module.scss";

type DownloadButtonProps = {
  taxForm: TaxForm;
};

const DownloadButton: FC<DownloadButtonProps> = ({ taxForm }) => {
  const { mutate: downloadTaxFormPdf, isPending: isDownloading } = useDownloadTaxFormPdfMutation();
  return (
    <Button
      size="sm"
      variant="tertiary"
      onClick={() => downloadTaxFormPdf(taxForm)}
      disabled={isDownloading}
    >
      {isDownloading ? <AnimatedSpinner /> : <DownloadSimple size={14} />}
      Download
    </Button>
  );
};

type Props = {
  accountId?: string;
};

const AccountTaxForms: FC<Props> = ({ accountId }) => {
  const { unitCoCustomerId: customerId } = useBusiness();

  const { data, isLoading } = useQuery(
    useUnitCoTaxFormsQueryOptions({ customerId: customerId ?? undefined, accountId })
  );

  const taxForms = data?.data ?? [];

  if (!isLoading && taxForms.length === 0)
    return (
      <EmptyState
        image={emptyImg}
        primaryText="No tax forms available."
        secondaryText="Once you have tax forms, they will be shown here."
      />
    );

  return (
    <Table
      rowKey="id"
      className={styles.table}
      isLoading={isLoading}
      columns={[
        {
          title: "Period",
          cellRender: (taxForm: TaxForm) => <Text size={14}>{taxForm.attributes.taxYear}</Text>,
        },
        {
          title: "Form type",
          cellRender: (taxForm: TaxForm) => (
            <Text weight="medium" size={14}>
              {taxForm.attributes.formType}
            </Text>
          ),
        },
        {
          title: "Action",
          align: TableColumnAlignment.RIGHT,
          cellClassName: styles.action,
          cellRender: (taxForm: TaxForm) => <DownloadButton taxForm={taxForm} />,
        },
      ]}
      data={taxForms}
    />
  );
};

export default AccountTaxForms;
