import { UnitError } from "@highbeam/unit-node-sdk";
import { captureException } from "@sentry/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import paymentIdempotencyKeyState from "state/payments/paymentIdempotencyKey";
import { notify } from "ui/feedback/Toast";
import { ApiError } from "utils/ajax";
import { v4 as uuidv4 } from "uuid";

import isTransferErrorState from "../state/isTransferErrorState";
import selectedPaymentMethodOptionState from "../state/selectedPaymentMethodOptionState";
import { getErrorMessage } from "../utils";

const useHandlePaymentError = () => {
  const setPaymentIdempotencyKey = useSetRecoilState(paymentIdempotencyKeyState);
  const setIsTransferError = useSetRecoilState(isTransferErrorState);
  const method = useRecoilValue(selectedPaymentMethodOptionState);

  return ({ message, e }: { message: string; e: unknown }) => {
    setPaymentIdempotencyKey(uuidv4());
    setIsTransferError(true);
    if (e instanceof UnitError) {
      notify("error", getErrorMessage(e.errors[0]?.title));
      captureException(
        new Error(
          `${message}. Unit ${method?.label} payment returned with status ${
            e.errors[0]?.status
          } and data ${JSON.stringify(e)}`
        )
      );
    } else if (e instanceof ApiError) {
      notify("error", getErrorMessage(e.message));
      captureException(
        new Error(
          `${message}. Unit ${method?.label} payment returned with status ${e.statusCode} and data ${e.message}`
        )
      );
    } else {
      notify("error", getErrorMessage(""));
      captureException(e);
    }
  };
};

export default useHandlePaymentError;
