import { BankAccountBarByUnitCoDepositAccountId } from "resources/bank-accounts/components/BankAccountBar";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { formatBankingDate } from "utils/date";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

const BankAccountDetail = () => {
  const { payment } = usePaymentInfoFlexpaneContext();
  const isOutgoingPayment = payment.attributes.direction === "Credit";

  return (
    <>
      <BankAccountBarByUnitCoDepositAccountId
        unitCoDepositAccountId={payment.relationships.account.data.id}
      />
      {isOutgoingPayment && (
        <Text size={14} color={colors.grey[500]}>
          {formatBankingDate(payment.attributes.createdAt)}
        </Text>
      )}
      {payment.type === "achPayment" && payment.attributes.settlementDate && (
        <Text size={14} color={colors.grey[500]}>
          {"Estimated " + formatBankingDate(payment.attributes.settlementDate)}
        </Text>
      )}
    </>
  );
};

export default BankAccountDetail;
