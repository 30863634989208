import UserRoleMembershipRep from "reps/UserRoleMembershipRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class UserRoleMembershipApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByBusiness(businessGuid: string): Promise<UserRoleMembershipRep.Complete[]> {
    const url = `/businesses/${businessGuid}/user-role-memberships`;
    const result = await this.api.get<UserRoleMembershipRep.Complete[]>(url);
    return result === null ? [] : result;
  }

  async setByUser(
    businessGuid: string,
    userGuid: string,
    creator: UserRoleMembershipRep.Creator[]
  ): Promise<UserRoleMembershipRep.Complete[]> {
    const url = `/businesses/${businessGuid}/user-role-memberships/${userGuid}`;
    return (await this.api.put<UserRoleMembershipRep.Complete[]>(url, creator))!;
  }
}
