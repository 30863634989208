import { IconProps, Warning } from "@phosphor-icons/react";
import classNames from "classnames";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./Reason.module.scss";

export type IconColor = "grey" | "red";

export type StatusLabel = {
  title: string;
  icon: React.FC<IconProps>;
  iconColor: IconColor;
};

export type Status = {
  label: StatusLabel;
  date: string;
  description?: string;
};

type Props = {
  status: Status;
};

export const rejectedStatusLabel: StatusLabel = {
  title: "Rejected",
  icon: Warning,
  iconColor: "red",
};

const Reason: React.FC<Props> = ({ status }) => (
  <div className={styles.reason}>
    <div className={classNames(styles.iconContainer, styles[status.label.iconColor])}>
      <status.label.icon size={16} />
    </div>
    <div className={styles.titleDescription}>
      <Text size={14} weight="medium">
        {status.label.title}
      </Text>
      {status.description && (
        <Text size={12} color={colors.grey[500]}>
          {status.description}
        </Text>
      )}
    </div>
    <div>
      <Text size={12} color={colors.grey[500]}>
        {status.date}
      </Text>
    </div>
  </div>
);

export default Reason;
