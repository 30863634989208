import { CaretUp, CaretDown } from "@phosphor-icons/react";
import classNames from "classnames";
import { ReactElement, ReactNode, useEffect, useRef, useContext } from "react";
import Text from "ui/typography/Text";

import styles from "./SidebarMenu.module.scss";
import { SidebarMenuContext, SidebarMenuContextWrapper } from "./SidebarMenuContext";
import SidebarMenuItem from "./SidebarMenuItem";

type Props = {
  customButton?: ReactElement;
  label?: string;
  icon?: ReactElement;
  alignment?: string;
  children: ReactNode;
};

/**
 * @deprecated This should only be used in the sidebar until we fully replace it with a menu component.
 */
const SidebarMenu = ({ label, icon, alignment, children, customButton }: Props) => {
  const dropdownEl: any = useRef(null);
  const { state: isOpen, update: setIsOpen } = useContext(SidebarMenuContext);

  const handleClickOutside = (event: any) => {
    if (dropdownEl.current && !dropdownEl.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const getCustomButtonWithProp = () => {
    if (customButton) {
      return <customButton.type {...customButton.props} onClick={handleButtonClick} />;
    }
    return null;
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const CustomButtonWithProp = getCustomButtonWithProp();
  return (
    <span ref={dropdownEl} className={styles.dropdown}>
      {CustomButtonWithProp ? (
        CustomButtonWithProp
      ) : (
        <button className={styles.dropBtn} onClick={handleButtonClick}>
          {icon}
          <Text size={14} className={styles.label}>
            {label}
          </Text>
          {isOpen ? <CaretUp size={14} /> : <CaretDown size={14} />}
        </button>
      )}
      <div className={styles.space} />
      <ul
        className={classNames(
          styles.dropdownContent,
          alignment === "right" ? styles.right : styles.left,
          isOpen ? styles.open : ""
        )}
      >
        {children}
      </ul>
    </span>
  );
};

const SidebarMenuWithContext = (props: Props) => (
  <SidebarMenuContextWrapper>
    <SidebarMenu {...props} />
  </SidebarMenuContextWrapper>
);

export default Object.assign(SidebarMenuWithContext, { Item: SidebarMenuItem });
