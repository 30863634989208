import classNames from "classnames";
import { FC } from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import DotsLoader from "ui/feedback/DotsLoader";

import styles from "./LoadingFallback.module.scss";

// NB(alex): This component is experimental. I'd like to see if there's a clean way for us to consolidate all fallbacks into a single component.

type Props = JSX.IntrinsicElements["div"] & {
  variant: "spinner" | "dots-3";
  className?: string;
};

const LoadingFallback: FC<Props> = ({ variant, className, ...divProps }) => {
  return (
    <div className={classNames(className, styles.container)} {...divProps}>
      {variant === "spinner" && <AnimatedSpinner />}
      {variant === "dots-3" && <DotsLoader dots={3} />}
    </div>
  );
};

export default LoadingFallback;
