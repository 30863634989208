import CommandPaletteDialog from "modules/command-palette/CommandPaletteDialog";
import { useCallback } from "react";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";

import useCommandPaletteState from "../useCommandPaletteState";

const CommandPalette = () => {
  const [{ isOpen }, setCommandPaletteState] = useCommandPaletteState();

  const filter = useCallback(
    (event: KeyboardEvent) => event.key === "k" && event.metaKey && !event.shiftKey,
    []
  );

  useKeyboardEvent(
    filter,
    (event: KeyboardEvent) => {
      event.preventDefault();
      setCommandPaletteState(({ isOpen }) => ({ isOpen: !isOpen }));
    },
    []
  );

  const handleClose = () => {
    setCommandPaletteState({ isOpen: false });
  };

  return <CommandPaletteDialog isOpen={isOpen} onClose={handleClose} />;
};

export default CommandPalette;
