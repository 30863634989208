import colors from "styles/colors";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";

type Props = {
  isLoading?: boolean;
  onClick: () => void;
  onClose: () => void;
};

const ConfirmResetModal: React.FC<Props> = ({ isLoading, onClick, onClose }) => (
  <Modal
    title="Start over?"
    buttonText="Start over"
    isLoading={isLoading}
    showCancel
    onClick={onClick}
    onClose={onClose}
    focusPrimaryButton
    small
  >
    <Text size={14} color={colors.grey[600]}>
      Are you sure you want to delete your responses and start over?
    </Text>
  </Modal>
);

export default ConfirmResetModal;
