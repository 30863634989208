import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";

type InvitationSuccessfulModalState =
  | { isOpen: false }
  | {
      isOpen: true;
      displayName: string;
      slug: string;
    };

const invitationSuccessfulModalState = atom<InvitationSuccessfulModalState>({
  key: "settings/users/invitationSuccessfulModalState",
  default: { isOpen: false },
});

export default invitationSuccessfulModalState;

// Controls

export const useCloseInvitationSuccessfulModal = () => {
  const setModalState = useSetRecoilState(invitationSuccessfulModalState);

  return useCallback(() => {
    setModalState({ isOpen: false });
  }, [setModalState]);
};

type OpenModalParams = {
  displayName: string;
  slug: string;
};

export const useOpenInvitationSuccessfulModal = () => {
  const setModalState = useSetRecoilState(invitationSuccessfulModalState);

  return useCallback(
    ({ displayName, slug }: OpenModalParams) => {
      setModalState({
        isOpen: true,
        displayName: displayName,
        slug: slug,
      });
    },
    [setModalState]
  );
};
