import { ReactNode, useRef, useState } from "react";
import useInsightsApp from "resources/insights-app/queries/useInsightsApp";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import TextInput, { useTextInput, validator } from "ui/inputs/TextInput";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import Validator from "utils/validator";

import styles from "./ConnectShopify.module.scss";
import { sanitizeStoreName } from "./utils";

type RenderPropsArgs = {
  openModal: () => void;
};

type Props = {
  children: ReactNode | ((args: RenderPropsArgs) => ReactNode);
  redirectPathKey: string;
};

const ConnectShopify: React.FC<Props> = ({ children, redirectPathKey }) => {
  const isInsightsApp = useInsightsApp();

  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const [isStoreNameModalVisible, setIsStoreNameModalVisible] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [isRedirecting, setIsRedirecting] = useState(false);

  const fetchOAuthUrl = async () => {
    setIsRedirecting(true);
    const sanitizedStoreName = sanitizeStoreName(storeName)!;
    const { url } = await highbeamApi.shopifyConnection.createRedirectUrl(
      businessGuid,
      `${window.location.origin}/loading-shopify?redirect=${redirectPathKey}`,
      sanitizedStoreName
    );
    return url;
  };

  const openShopifyStoreNameModal = () => {
    setIsStoreNameModalVisible(true);
  };

  const openShopifyOAuth = async () => {
    if (isInsightsApp) {
      window.location.href = "https://apps.shopify.com/2f8373253660d802d07fa892e81c07c6";
    } else {
      window.location.href = await fetchOAuthUrl();
    }
  };

  const renderPropsArgs = useRef<RenderPropsArgs>({ openModal: openShopifyStoreNameModal }).current;

  return (
    <>
      {isStoreNameModalVisible && (
        <ShopifyStoreNameModal
          isRedirecting={isRedirecting}
          onClose={() => {
            setIsStoreNameModalVisible(false);
          }}
          onClick={openShopifyOAuth}
          setStoreName={setStoreName}
        />
      )}

      {typeof children === "function" && children(renderPropsArgs)}
      {typeof children !== "function" && children}
    </>
  );
};

const ShopifyStoreNameModal: React.FC<{
  isRedirecting: boolean;
  onClose: () => void;
  onClick: () => void;
  setStoreName: (name: string) => void;
}> = ({ isRedirecting, onClose, onClick, setStoreName }) => {
  const isInsightsApp = useInsightsApp();

  const shopifyStoreNameTextInput = useTextInput({
    onChange: validator(Validator.shopifyStoreUrl, "Store URL is invalid"),
  });

  return (
    <Modal
      title="Connect your Shopify store"
      buttonText={isRedirecting ? "Redirecting" : "Connect"}
      isLoading={isRedirecting}
      isPrimaryButtonDisabled={
        !isInsightsApp &&
        (shopifyStoreNameTextInput.hasError || !shopifyStoreNameTextInput.value || isRedirecting)
      }
      onClose={onClose}
      onClick={onClick}
    >
      <div className={styles.modalContainer}>
        {!isInsightsApp ? (
          <Text size={14}>
            Please enter your shopify.com admin URL. It can be found in the browser address bar on
            the
            <a
              href={"https://shopify.com/admin"}
              target="_blank"
              rel="noreferrer"
              className={styles.shopifyAdminLink}
            >
              {" "}
              Shopify admin page
            </a>
            .
          </Text>
        ) : null}

        {!isInsightsApp ? (
          <TextInput
            autoFocus
            label="Store URL"
            value={shopifyStoreNameTextInput.value}
            onChange={(value) => {
              setStoreName(value);
              shopifyStoreNameTextInput.onChange(value);
            }}
            onBlur={shopifyStoreNameTextInput.onBlur}
            hasError={shopifyStoreNameTextInput.hasError}
            errorMessage={shopifyStoreNameTextInput.errorMessage}
            placeholder="admin.shopify.com/store/your-store"
          />
        ) : null}

        <Text size={14}>You will be redirected to Shopify to install the Highbeam app.</Text>
      </div>
    </Modal>
  );
};

export default ConnectShopify;
