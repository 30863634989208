import { captureMessage } from "@sentry/react";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useBankApplicationQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["bankApplication", { businessGuid }],
    queryFn: async () => {
      const bankApplications = await highbeamApi.bankApplication.getByBusiness(businessGuid);

      if (bankApplications.length === 0) {
        return null;
      } else if (bankApplications.length === 1) {
        return bankApplications[0];
      } else {
        captureMessage(`Multiple bank applications found for business ${businessGuid}.`);
        return bankApplications[0];
      }
    },
  });
};

export default useBankApplicationQueryOptions;
