import { ArrowLineUp } from "@phosphor-icons/react";
import Button, { ButtonProps } from "ui/inputs/Button";

export const DEFAULT_BUTTON_CHILDREN = (
  <>
    <ArrowLineUp size={16} />
    Upload file
  </>
);

const NoFilesUploadedUploadFileButton = ({
  children = DEFAULT_BUTTON_CHILDREN,
  ...buttonProps
}: ButtonProps) => {
  return (
    <Button variant="tertiary" size="sm" {...buttonProps}>
      {children}
    </Button>
  );
};

export default NoFilesUploadedUploadFileButton;
