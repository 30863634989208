import { FC, ReactElement, ReactNode } from "react";
import Popover from "ui/overlay/Popover";
import { Heading4, Span } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

import useShowPopover from "./useShowPopover";

type Props = {
  children: ReactNode;
  triggerAsChild?: boolean;
  popoverContent: ReactNode;
};

const DetailsPopover: FC<Props> = ({ children, triggerAsChild, popoverContent }) => {
  const [showPopover, setShowPopover] = useShowPopover(100);

  return (
    <Popover open={showPopover} onOpenChange={setShowPopover}>
      <Popover.Trigger
        onMouseOver={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        asChild={triggerAsChild}
        className="inline-flex"
      >
        {children}
      </Popover.Trigger>

      <Popover.Content
        align="end"
        onCloseAutoFocus={(e) => e.preventDefault()} // Prevents default behavior of focusing the bank account when the popover closes.
        sideOffset={12}
        onMouseOver={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
      >
        {popoverContent}
      </Popover.Content>
    </Popover>
  );
};

const ContentHeader: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <div className={cn("overflow-auto border-b border-grey-100 p-4", className)}>{children}</div>
  );
};

const ContentBody: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("flex flex-col gap-y-4 overflow-auto p-4", className)}>{children}</div>;
};

type ContentItemProps = {
  className?: string;
  children: [ReactElement, ReactElement];
};

const ContentItem: FC<ContentItemProps> = ({ children, className }) => {
  return (
    <div className={cn("flex justify-between", className)}>
      <Heading4 className="text-sm font-regular text-grey-600">{children[0]}</Heading4>
      <Span className="text-sm font-medium text-grey-900">{children[1]}</Span>
    </div>
  );
};

export default Object.assign(DetailsPopover, {
  ContentHeader: ContentHeader,
  ContentBody: ContentBody,
  ContentItem: ContentItem,
});
