import { Check } from "@phosphor-icons/react";
import colors from "styles/colors";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";

import styles from "./SelectedFile.module.scss";

type Props = {
  isLoading?: boolean;
  icon?: React.ReactNode;
  loadingIcon?: React.ReactNode;
  fileName: string;
  actions?: React.ReactNode;
};

const SelectedFile: React.FC<Props> = ({
  isLoading,
  icon = <Check className={styles.checkIcon} size={16} color={colors.green[500]} />,
  loadingIcon = <AnimatedSpinner size={16} />,
  fileName,
  actions,
}) => (
  <div className={styles.container}>
    <div className={styles.iconContainer}>{isLoading ? loadingIcon : icon}</div>
    <div className={styles.textContainer}>
      <div className={styles.fileName}>{fileName}</div>
    </div>
    {actions}
  </div>
);

export default SelectedFile;
