import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import InstitutionRep from "reps/InstitutionRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const BASE_FINANCIAL_INSTITUTIONS_QUERY_KEY = "financial-institutions";

type Params = {
  routingNumber?: string;
};

export const useFinancialInstitutionQueryOptions = ({ routingNumber }: Params) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [BASE_FINANCIAL_INSTITUTIONS_QUERY_KEY, { routingNumber }],
    queryFn: () => {
      if (!routingNumber) {
        return null;
      }
      return highbeamApi.institution.get(routingNumber);
    },
  });
};

type UseFinancialInstitutionQueryParams = Params & {
  enabled?: boolean;
};

export const useFinancialInstitutionQuery = ({
  enabled,
  ...params
}: UseFinancialInstitutionQueryParams) => {
  return useQuery({
    ...useFinancialInstitutionQueryOptions(params),
    enabled: enabled,
  });
};

export const useGetFinancialInstitutionFromQueryCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (routingNumber: string) => {
      const financialInstitutionCache = queryClient.getQueriesData<InstitutionRep.Complete>({
        queryKey: [BASE_FINANCIAL_INSTITUTIONS_QUERY_KEY, { routingNumber }],
      })[0];

      return financialInstitutionCache[1] ?? null;
    },
    [queryClient]
  );
};
