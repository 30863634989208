import { Dayjs } from "dayjs";
import CounterpartyReportRep from "reps/CounterpartyReportRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class CounterpartyReportApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async search(
    businessGuid: string,
    monthRangeStart: Dayjs,
    monthRangeEndInclusive: Dayjs
  ): Promise<CounterpartyReportRep.Complete> {
    const qp = new URLSearchParams({
      businessGuid: businessGuid,
      monthRangeStart: monthRangeStart.format("YYYY-MM"),
      monthRangeEndInclusive: monthRangeEndInclusive.format("YYYY-MM"),
    });
    const url = `/insights/counterparty-reports?${qp}`;
    return (await this.api.get<CounterpartyReportRep.Complete>(url))!;
  }
}
