import { ArrowsClockwise } from "@phosphor-icons/react";
import errorPageIcon from "assets/error-page-icon.svg";
import { useChatWidget } from "components/ChatWidget";
import useDebugSettings from "modules/debug/useDebugSettings";
import { FC } from "react";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import cn from "utils/tailwind/cn";

type Props = {
  readonly error?: Error;
  className?: string;
};

const ErrorCard: FC<Props> = ({ error, className }) => {
  const refreshPage = () => window.location.reload();
  const chat = useChatWidget();

  const { showDebugMessages } = useDebugSettings();
  const errorStack = showDebugMessages ? error?.stack : undefined;

  return (
    <Card className={cn("mx-auto w-full min-w-80 max-w-screen-mobile-phone p-8", className)}>
      <div className="flex justify-center pb-6">
        <img src={errorPageIcon} alt="Oh no, something went wrong!" />
      </div>

      <div className="flex flex-col gap-y-1">
        <Text as="h3" className="text-xl font-bold">
          Oh no, something went wrong!
        </Text>
        <Text as="p" className="border-b border-b-grey-100 pb-6 text-sm text-grey-500">
          We are sorry, there is a temporary issue. Please come back in a few minutes or refresh the
          page. If the issue persists, reach out to support.
        </Text>
      </div>

      <div className="flex flex-col gap-4 pt-6 tablet:flex-row">
        <Button variant="primary" onClick={() => refreshPage()}>
          <ArrowsClockwise size={16} />
          Refresh
        </Button>
        <Button variant="tertiary" onClick={() => chat.show()}>
          Contact support
        </Button>
      </div>

      {errorStack && (
        <div className="mt-6">
          <code className="mt-6">{errorStack}</code>
        </div>
      )}
    </Card>
  );
};

export default ErrorCard;
