import { useRecoilValue } from "recoil";
import allStepsState from "state/capital/creditApplication/allSteps";

import useCurrentStepIndex from "./useCurrentStepIndex";

const useGetNextPathname = (): string | null => {
  const currentStepIndex = useCurrentStepIndex();
  const allSteps = useRecoilValue(allStepsState);
  return allSteps[currentStepIndex + 1]?.path ?? null;
};

export default useGetNextPathname;
