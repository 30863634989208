import { init, reactRouterV6BrowserTracingIntegration, replayIntegration } from "@sentry/react";
import env from "env";
import { MfaCanceledError } from "modules/mfa/useMfa";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const initSentry = () => {
  init({
    environment: env.SENTRY_ENVIRONMENT,
    dsn: "https://af08220e4b354160b0529d2c66d17c4d@o1131189.ingest.sentry.io/6175436",
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation: useLocation,
        useNavigationType: useNavigationType,
        createRoutesFromChildren: createRoutesFromChildren,
        matchRoutes: matchRoutes,
      }),
      replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        networkRequestHeaders: ["Jwt-Payload", "X-Request-Id"],
        networkCaptureBodies: true,
        networkDetailAllowUrls: [
          env.HIGHBEAM_API_ORIGIN_V1,
          env.HIGHBEAM_API_ORIGIN_V2,
          env.UNIT_API_ORIGIN,
        ],
      }),
    ],

    ignoreErrors: [MfaCanceledError.name],

    release: env.RELEASE_SHA,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    maxValueLength: 1000,

    // This sets the sample rate to be 100%. We may want to lower this in the future, when it costs money.
    replaysSessionSampleRate: env.SENTRY_SESSION_REPLAY_SAMPLE_RATE,
    // Always sample when there are errors.
    replaysOnErrorSampleRate: 1.0,
  });
};

export default initSentry;
