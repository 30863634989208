import { zodResolver } from "@hookform/resolvers/zod";
import addressSchema from "modules/address-autocomplete/addressSchema";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  address: addressSchema,
});

export type CreateCardModalShippingAddressFormInputs = z.input<typeof schema>;
export type CreateCardModalShippingAddressFormOutputs = z.output<typeof schema>;

type Params = {
  defaultValues: CreateCardModalShippingAddressFormInputs;
};

const useCreateCardModalShippingAddressForm = ({ defaultValues }: Params) => {
  return useForm<
    CreateCardModalShippingAddressFormInputs,
    object,
    CreateCardModalShippingAddressFormOutputs
  >({
    resolver: zodResolver(schema),
    defaultValues,
  });
};

export default useCreateCardModalShippingAddressForm;
