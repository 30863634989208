import ModalV4 from "ui/overlay/ModalV4";

import InviteUserForm from "./InviteUserForm";
import { useInviteUserModal } from "./state/inviteUserModalState";

const InviteUserModal = () => {
  const { isOpen, close } = useInviteUserModal();

  if (!isOpen) {
    return null;
  }

  return (
    <ModalV4 onClose={close}>
      <InviteUserForm />
    </ModalV4>
  );
};

export default InviteUserModal;
