const uploadArrayBuffer = (uploadUrl: string, arrayBuffer: ArrayBuffer) =>
  fetch(uploadUrl, {
    method: "PUT",
    headers: {
      "Content-Type": "application/octet-stream",
    },
    body: arrayBuffer,
  });

const uploadFile = (file: File, uploadUrl: string) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);

  return new Promise((resolve, reject) => {
    reader.onloadend = async () => {
      if (reader.result instanceof ArrayBuffer) {
        try {
          const response = await uploadArrayBuffer(uploadUrl, reader.result);
          if (response.ok) {
            resolve(response);
          } else {
            reject(await response.text());
          }
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error("Failed to read file"));
      }
    };
  });
};

export default uploadFile;
