import { UnitError } from "@highbeam/unit-node-sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";

export const UNIT_CO_PAYMENTS_QUERY_KEY = "unit-co-payments";

const useAssociatedPaymentForTransactionInfoFlexpane = (paymentId?: string) => {
  const unitApi = useUnitApi();

  const { data } = useSuspenseQuery({
    queryKey: [UNIT_CO_PAYMENTS_QUERY_KEY, { paymentId }],
    queryFn: async () => {
      if (paymentId === undefined) return null;
      const response = await unitApi.payments.get(paymentId).catch((err) => {
        // NB(alex): Handles edge case where payment is not found. Prevents the flexpane from crashing for a) Book payments between 2 different unit customers. b) Line of credit transactions. There may be other places, would be good to keep track in this comment if they come up!
        // Context: https://linear.app/highbeam/issue/HB-4265/fix-flexpane-book-payment-bug
        if (err instanceof UnitError) {
          if (err.errors[0].status === "404") {
            return { data: null };
          }
        }
        throw err;
      });

      return response.data;
    },
  });

  return data;
};

export default useAssociatedPaymentForTransactionInfoFlexpane;
