import DashboardPage from "layouts/DashboardPage";
import useUser from "resources/user/queries/useUser";
import SectionLoading from "ui/feedback/SectionLoading";
import withSuspense from "ui/feedback/withSuspense";
import { Heading4 } from "ui/typography";

import AccountsOverviewPageHeaderActions from "../../components/AccountsOverviewPageHeaderActions";

const AccountsOverviewPageHeader = () => {
  const user = useUser();

  return (
    <DashboardPage.Header actions={<AccountsOverviewPageHeaderActions />}>
      <Heading4 className="mr-2 self-end whitespace-nowrap font-regular">
        Welcome, {user.firstName || user.displayName}
      </Heading4>
    </DashboardPage.Header>
  );
};

export default withSuspense(AccountsOverviewPageHeader, {
  fallback: <SectionLoading />,
});
