import { FC } from "react";
import { Control, useController } from "react-hook-form";
import BankAccountsDropdown from "resources/bank-accounts/components/BankAccountsDropdown";
import { usePaymentEnabledBankAccounts } from "resources/bank-accounts/queries/bankAccountsQueryHooks";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Helper from "ui/inputs/Helper";

import { RepayLineOfCreditFormInputs } from "../hooks/useRepayLineOfCreditForm";

// NB(alex): Hack for making this reusable across different forms for type-casting the passed in `control` prop.
export type RepaymentBankAccountDropdownControl = Control<RepayLineOfCreditFormInputs>;

type Props = {
  control: RepaymentBankAccountDropdownControl;
};

const RepaymentBankAccountDropdown: FC<Props> = ({ control }) => {
  const transferOptions = usePaymentEnabledBankAccounts();

  const { field } = useController({
    control: control,
    name: "repaymentBankAccount",
    defaultValue: transferOptions[0],
  });

  return (
    <>
      <BankAccountsDropdown
        disabled={transferOptions.length === 1}
        id="from"
        label="From"
        isSearchable={false}
        options={transferOptions}
        {...field}
      />

      {field.value && (
        <Helper>
          Account balance: <MoneyAmount weight="medium" cents={field.value.availableBalance} />
        </Helper>
      )}
    </>
  );
};

export default RepaymentBankAccountDropdown;
