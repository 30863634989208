import BillRep from "reps/BillRep";
import { useCurrentBusinessMember } from "resources/business-members/queries/businessMemberQueryHooks";
import { useIsSuperusering } from "state/auth/isSuperusering";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import useRefreshBillsQueries from "../queries/useRefreshBillsQueries";

type Variables = {
  billId: string;
};

const useSaveAsBillMutation = (
  additionalOptions: MutationAdditionalOptions<BillRep.Complete, Variables> = {}
) => {
  const highbeamApi = useHighbeamApi();
  const currentBusinessMember = useCurrentBusinessMember();
  const refreshBills = useRefreshBillsQueries();
  const { segmentTrack } = useSegment();
  const isSuperusering = useIsSuperusering();
  // NB(lev): `businessMemberGuid` is a required field for the mutation, but we
  // won't have a reference to the current business member if the user is
  // superusering. The caller of this mutation is responsible for ensuring
  // that saving a bill is not allowed when the user is superusering.

  return useMutationWithDefaults(
    {
      mutationFn: ({ billId }) => {
        if (isSuperusering) {
          throw new Error("Cannot save bills while superusering.");
        }

        return highbeamApi.billAction.saveAsBill(billId, currentBusinessMember!.guid);
      },
      onSuccess: () => {
        segmentTrack(SEGMENT_EVENTS.BILL_PAY_BILL_SAVED);
        refreshBills();
      },
      onError: (error) => {
        notify(
          "error",
          error.message ?? "Something went wrong. Please try again or contact support."
        );
      },
    },
    additionalOptions
  );
};

export default useSaveAsBillMutation;
