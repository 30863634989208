import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import plaidAccountsQueryHooks from "../queries/plaidAccountsQueryHooks";
import usePlaidConnectionsQueryOptions from "../queries/usePlaidConnectionsQueryOptions";

type Params = {
  plaidAccountId: string;
};

const useDeletePlaidBankAccountMutation = (
  additionalOptions?: MutationAdditionalOptions<PlaidBankAccountRep.Complete, Params>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const refreshPlaidAccountsQuery = plaidAccountsQueryHooks.useRefreshQueries();
  const refreshPlaidConnectionsQuery = useRefreshQuery(usePlaidConnectionsQueryOptions().queryKey);

  return useMutationWithDefaults(
    {
      mutationFn: async (params: Params) => {
        return highbeamApi.plaid.deletePlaidBankAccount({
          businessGuid,
          plaidAccountId: params.plaidAccountId,
        });
      },
      onSuccess: () => {
        refreshPlaidAccountsQuery();
        refreshPlaidConnectionsQuery();
      },
      onError: () => {
        notify("error", "Failed to delete Plaid bank account.");
      },
    },
    additionalOptions || {}
  );
};

export default useDeletePlaidBankAccountMutation;
