import { FC, ReactNode, createContext, useContext } from "react";

// NB(alex): This file is messy, please don't copy the pattern here. Still figuring out how to structure scoped context.

export type MergedCounterpartiesModalParams = {
  counterparty: string;
  originalCounterparties: string[];
};

type MergedCounterpartiesModalContextValue = MergedCounterpartiesModalParams & {
  onClose: () => void;
};

const MergedCounterpartiesModalContext = createContext<MergedCounterpartiesModalContextValue>(
  {} as MergedCounterpartiesModalContextValue
);

type MergedCounterpartiesModalProviderProps = {
  params: MergedCounterpartiesModalParams;
  onClose: () => void;
  children: ReactNode;
};

export const MergedCounterpartiesModalProvider: FC<MergedCounterpartiesModalProviderProps> = ({
  children,
  params,
  onClose,
}) => {
  return (
    <MergedCounterpartiesModalContext.Provider value={{ ...params, onClose }}>
      {children}
    </MergedCounterpartiesModalContext.Provider>
  );
};

export const useMergedCounterpartiesModalContext = () => {
  const context = useContext(MergedCounterpartiesModalContext);

  return {
    ...context,
  };
};
