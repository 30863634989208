import { FC, ReactNode } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./TransactionFlexpaneCounterparty.module.scss";

// NB(alex): This component is a work in progress. We likely need to add props for `icon`, rendering a bank account, and maybe adding a tooltip? Slack thread here: https://highbeamco.slack.com/archives/C04G6ENEJU8/p1710802228238579

type Props = {
  children: ReactNode;
};

const TransactionFlexpaneCounterparty: FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      <Text className={styles.text} size={14} weight="medium" color={colors.grey[800]}>
        {children}
      </Text>
    </div>
  );
};

export default TransactionFlexpaneCounterparty;
