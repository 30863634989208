import { CalendarBlank, CaretLeft, CaretRight } from "@phosphor-icons/react";
import dayjs from "dayjs";
import React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import { CommonInputProps } from "../InputWrapper";
import TextInput from "../TextInput";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./DatePicker.module.scss";

export const isWeekdayOrCurrentDate = (date: Date) => {
  // We always want to allow the current day
  const currentDate = new Date();
  const day = date.getDay();
  return (day !== 0 && day !== 6) || currentDate.toDateString() === date.toDateString();
};

export type DatePickerProps = {
  variant: "start-date" | "end-date" | "no-date";
  endDate?: Date | null;
  startDate?: Date | null;
  filterDate?: (date: Date) => boolean;
  excludeDates?: Date[];
  minDate?: Date | null;
  maxDate?: Date;
  onChange?: (selected: Date | null) => void;
  icon?: React.ReactElement;
  onIconClick?: () => void;
  onClick?: () => void;
  value?: Date | null;
  isClearable?: boolean;
  // NB(alex): These replace `onBlur` and `onFocus` respectively. Upgrading to the latest version of `react-datepicker` _might_ fix this but not sure. Open github issue: https://github.com/Hacker0x01/react-datepicker/issues/2028
  onCalendarClose?: () => void;
  onCalendarOpen?: () => void;
} & CommonInputProps;

const DatePicker: React.FC<DatePickerProps> = ({
  id,
  disabled,
  label,
  endDate,
  startDate,
  filterDate,
  excludeDates,
  minDate,
  maxDate,
  value,
  icon,
  onClick,
  onIconClick,
  errorMessage,
  hasError,
  append,
  prefixValue,
  suffixValue,
  className,
  variant,
  isClearable,
  onChange,
  onCalendarClose,
  onCalendarOpen,
}) => {
  const handleChange: ReactDatePickerProps["onChange"] = (selected) => {
    if (selected instanceof Date) {
      onChange?.(selected);
    } else {
      onChange?.(null);
    }
  };
  return (
    <div className={styles.container}>
      <ReactDatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className={styles.header}>
            <button
              type="button"
              className={styles.button}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <CaretLeft size={20} />
            </button>
            <Text size={14}> {dayjs(date).format("MMMM, YYYY")} </Text>
            <button
              type="button"
              className={styles.button}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <CaretRight size={20} />
            </button>
          </div>
        )}
        popperPlacement="bottom-start"
        useWeekdaysShort
        disabled={disabled}
        selected={value}
        selectsStart={variant === "start-date"}
        selectsEnd={variant === "end-date"}
        onChange={handleChange}
        onKeyDown={(e) => {
          // HACK(alex): Annoying work-around to let us clear the value if the backspace key is pressed.
          if (isClearable && e.key === "Backspace") {
            onChange?.(null);
          }
        }}
        startDate={startDate}
        filterDate={filterDate}
        excludeDates={excludeDates}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate === undefined ? null : maxDate}
        className={className}
        popperModifiers={[{ name: "offset", options: { offset: [0, 2] } }]}
        renderDayContents={(day, _date) => (
          <Text size={12} weight="regular">
            {day}
          </Text>
        )}
        onCalendarClose={onCalendarClose}
        onCalendarOpen={onCalendarOpen}
        customInput={
          <TextInput
            className={styles.input}
            icon={icon ? icon : <CalendarBlank size={16} color={colors.grey[500]} />}
            id={id}
            disabled={disabled}
            label={label}
            onIconClick={onIconClick}
            onClick={onClick}
            prefixValue={prefixValue}
            suffixValue={suffixValue}
            append={append}
            hasError={hasError}
            errorMessage={errorMessage}
          />
        }
      />
    </div>
  );
};

export default DatePicker;
