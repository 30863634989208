import { FC, PropsWithChildren } from "react";

import styles from "./DetailsSidebarPanel.module.scss";

const DetailsSidebarPanelHeader: FC<PropsWithChildren> = ({ children }) => (
  <header className={styles.headerContainer}>{children}</header>
);

const DetailsSidebarPanelBody: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.bodyContainer}>{children}</div>
);

const DetailsSidebarPanel: FC<PropsWithChildren> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default Object.assign(DetailsSidebarPanel, {
  Header: DetailsSidebarPanelHeader,
  Body: DetailsSidebarPanelBody,
});
