import classNames from "classnames";
import { PropsWithChildren } from "react";

import styles from "../Teaser.module.scss";

type Props = {
  className?: string;
} & PropsWithChildren;

const TeaserHero: React.FC<Props> = ({ className, children }) => (
  <div className={classNames(styles.hero, className)}>{children}</div>
);

export default TeaserHero;
