import TableCell from "./TableCell";
import { useTable } from "./TableWrapper";

const Body = () => {
  const { columns, data } = useTable();

  return (
    <tbody>
      {data?.map((datum, i) => (
        <tr key={datum.key}>
          {columns.map((column) => (
            <TableCell key={column.key} column={column}>
              {column.renderCell(datum, { i, total: data.length })}
            </TableCell>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default Body;
