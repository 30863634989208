import { ArrowRight, ChatTeardropDots, UserCircleGear } from "@phosphor-icons/react";
import { cloneElement, FC, ReactElement } from "react";
import { OptionProps } from "react-select";
import colors from "styles/colors";

import { CommandPaletteOption } from "../command-palette-options/types";

type IconElement = ReactElement<{ size: number; color: string }>;

const getCategoryIcon = ({ category }: CommandPaletteOption): IconElement => {
  switch (category) {
    case "contact-support":
      return <ChatTeardropDots />;
    case "superuser":
      return <UserCircleGear />;
    case "navigation":
      return <ArrowRight />;
  }
};

type Props = Omit<OptionProps<CommandPaletteOption, false>, "children">;

const CommandPaletteDropdownOptionCategoryIcon: FC<Props> = ({ data }) => {
  const icon = getCategoryIcon(data);

  return (
    <span
      style={{
        // Preserves icon size / prevents siblings from squishing.
        display: "flex",
      }}
    >
      {cloneElement(icon, {
        size: icon.props.size ?? 16,
        color: icon.props.color ?? colors.grey[500],
      })}
    </span>
  );
};

export default CommandPaletteDropdownOptionCategoryIcon;
