import classNames from "classnames";
import { TabConfig } from "utils/tabs/useTabState";

import TabsTab from "./TabsTab";
import styles from "./TabsV2.module.scss";

type TabsJustify = "start" | "space-around";

type Props<TTabs extends TabConfig> = {
  className?: string;
  activeTab: keyof TTabs;
  setActiveTab: (tab: keyof TTabs) => void;
  tabs: TTabs;
  justify?: TabsJustify;
};

const TabsV2 = <TTabs extends TabConfig>({
  className,
  activeTab,
  setActiveTab,
  tabs,
  justify = "start",
}: Props<TTabs>) => {
  const tabsArray = Object.entries(tabs);
  return (
    <div className={classNames(className, styles.container, styles[`justify-${justify}`])}>
      {tabsArray.map(([tabName, { icon, label, count }]) => {
        const isActive = tabName === activeTab;
        return (
          <button
            key={tabName}
            className={classNames(styles.tabContainer, isActive && styles.active)}
            role="tab"
            type="button"
            aria-selected={isActive}
            onClick={() => setActiveTab(tabName)}
          >
            <TabsTab icon={icon} isActive={isActive} count={count}>
              {label}
            </TabsTab>
          </button>
        );
      })}
    </div>
  );
};

export default Object.assign(TabsV2, {
  Tab: TabsTab,
});
