import { ArrowFatLineUp, CalendarPlus, Eyeglasses } from "@phosphor-icons/react";
import DashboardPage from "layouts/DashboardPage";
import CapitalAccountDetailsRep from "reps/CapitalAccountDetailsRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import FeatureOverviewBar from "ui/data-display/FeatureOverviewBar";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const OfferedCapitalHowItWorks = ({ capitalAccountSummary }: Props) => {
  if (capitalAccountSummary.details.lineType === CapitalAccountDetailsRep.LineType.Advance) {
    return (
      <DashboardPage.Section>
        <DashboardPage.Section.Header heading="How it works" />

        <div className="grid grid-cols-1 gap-x-10 py-8 large-desktop:grid-cols-3">
          <FeatureOverviewBar>
            <FeatureOverviewBar.IconTile color="purple" icon={<CalendarPlus weight="light" />} />
            <FeatureOverviewBar.Paragraph>
              Extend your payment terms for inventory and marketing.
            </FeatureOverviewBar.Paragraph>
          </FeatureOverviewBar>

          <FeatureOverviewBar>
            <FeatureOverviewBar.IconTile color="orange" icon={<Eyeglasses weight="light" />} />
            <FeatureOverviewBar.Paragraph>
              Transparent pricing based only on the cash you use.
            </FeatureOverviewBar.Paragraph>
          </FeatureOverviewBar>

          <FeatureOverviewBar>
            <FeatureOverviewBar.IconTile color="green" icon={<ArrowFatLineUp weight="light" />} />
            <FeatureOverviewBar.Paragraph>
              Higher limits that help cover your largest expenses.
            </FeatureOverviewBar.Paragraph>
          </FeatureOverviewBar>
        </div>
      </DashboardPage.Section>
    );
  } else {
    // NB(alex): We should do this soon but haven't landed on designs/copy yet.
    return null;
  }
};

export default OfferedCapitalHowItWorks;
