import classNames, { Argument } from "classnames";
import { twMerge } from "tailwind-merge";

// Helper function which composes classNames with twMerge.
// See https://ui.shadcn.com/docs/installation/manual#add-a-cn-helper.
//
// In general, this may be used anywhere where we have a component that combines
// a className that has Tailwind utility classes with a custom className passed
// in by the caller (assuming it may also have Tailwind utility classes).
// twMerge allows conflicting Tailwind classes provided by the caller to take
// precedence over the component-defined Tailwind utility classes (e.g. if the
// component has "bg-yellow-500" and the caller wants to override this by passing
// in "bg-red-500").
const cn = (...args: Argument[]) => twMerge(classNames(args));

export default cn;
