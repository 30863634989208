import BillDocumentRep from "reps/BillDocumentRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import { useRefreshBillDocumentsQueries } from "../queries/useBillDocuments";

type Variables = {
  billDocumentId: string;
};

const useDeleteBillDocumentMutation = (
  additionalOptions: MutationAdditionalOptions<BillDocumentRep.Complete, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshBillDocumentsQuery = useRefreshBillDocumentsQueries();

  return useMutationWithDefaults(
    {
      mutationFn: ({ billDocumentId }) => {
        return highbeamApi.billDocument.delete(billDocumentId);
      },
      onSuccess: async () => {
        await refreshBillDocumentsQuery();
      },
    },
    additionalOptions
  );
};

export default useDeleteBillDocumentMutation;
