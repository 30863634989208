import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const useUserQueryOptions = (userGuid: string) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: ["user", { userGuid }],
    queryFn: () => {
      return highbeamApi.user.get(userGuid);
    },
  });
};

export default useUserQueryOptions;
