import Delegate from "ui/navigation/PageIndicator";

import { useTable } from "./TableWrapper";

const PageIndicator = () => {
  const { pagination } = useTable();

  if (!pagination || pagination.totalPages <= 1) return null;

  return <Delegate {...pagination} />;
};

export default PageIndicator;
