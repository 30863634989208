import {
  InternationalWireOption,
  getInternationalWireCountry,
} from "pages/SendMoneyPage/internationalWires";
import { atom, selector } from "recoil";
import { Option } from "ui/inputs/Dropdown";

export const selectedBankCountryOptionState = atom<Option | null>({
  key: "payments/international/selectedBankCountryOption",
  default: null,
});

export const selectedBankCountryInternationalWireOptionState =
  selector<InternationalWireOption | null>({
    key: "payments/international/selectedBankCountryInternationalWireOption",
    get: ({ get }) => {
      const country = get(selectedBankCountryOptionState);
      if (!country) return null;
      return getInternationalWireCountry(country.value);
    },
  });
