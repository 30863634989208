import PlaidLinkModalContents from "modules/plaid/components/PlaidLinkModal/PlaidLinkModalContents";
import { FC } from "react";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import ModalV4 from "ui/overlay/ModalV4";
import { HighbeamPlaidLinkOnSuccess } from "utils/customHooks/useHighbeamPlaidLink";

type Props = {
  onClose: () => void;
  existingConnection?: PlaidConnectionRep.Complete;
  onLinkSuccess?: HighbeamPlaidLinkOnSuccess;
};

const PlaidLinkModal: FC<Props> = ({ onClose, existingConnection, onLinkSuccess }) => {
  return (
    <ModalV4 size={"small"} onClose={onClose}>
      <ModalV4.Header>Heads up!</ModalV4.Header>
      <ModalV4.Body>
        <PlaidLinkModalContents
          existingConnection={existingConnection}
          onLinkSuccess={onLinkSuccess}
        />
      </ModalV4.Body>
    </ModalV4>
  );
};

export default PlaidLinkModal;
