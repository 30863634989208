import PayeeRep from "reps/PayeeRep";

import { BillPaymentMethod, BillPaymentMethodType } from "../queries/useBillPaymentMethods";

const getBillPaymentMethodByPayeeAndBillPaymentMethodType = (
  payee: PayeeRep.Complete,
  billPaymentMethodType: BillPaymentMethodType
): BillPaymentMethod => {
  switch (billPaymentMethodType) {
    case "ach":
      return {
        billPaymentMethodType: "ach",
        payeePaymentMethod: payee.achTransferMethod
          ? {
              method: "ach",
              accountNumber: payee.achTransferMethod.accountNumber,
              routingNumber: payee.achTransferMethod.routingNumber,
            }
          : null,
      };
    case "domestic-wire":
      return {
        billPaymentMethodType: "domestic-wire",
        payeePaymentMethod: payee.wireTransferMethod
          ? {
              method: "domestic-wire",
              accountNumber: payee.wireTransferMethod.accountNumber,
              routingNumber: payee.wireTransferMethod.routingNumber,
            }
          : null,
      };
    case "international-wire-local-currency":
      return {
        billPaymentMethodType: billPaymentMethodType,
        payeePaymentMethod:
          payee.internationalWireTransferMethod &&
          payee.internationalWireTransferMethod.currency !== "USD"
            ? {
                method: "international-wire",
                ...payee.internationalWireTransferMethod,
              }
            : null,
      };

    case "international-wire-usd-swift":
      return {
        billPaymentMethodType: billPaymentMethodType,
        payeePaymentMethod:
          payee.internationalWireTransferMethod &&
          payee.internationalWireTransferMethod.currency === "USD"
            ? {
                method: "international-wire",
                ...payee.internationalWireTransferMethod,
              }
            : null,
      };
  }
};

export default getBillPaymentMethodByPayeeAndBillPaymentMethodType;
