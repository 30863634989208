import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import styles from "styles/transitions.module.scss";

export const transitionDuration: number = parseInt(styles.transitionDuration);

/**
 * Handles state and delayed unmounting for CSS transitions (such as those for Headless UI).
 * [initiateClose] should initiateClose the transition, usually by changing some local state.
 * [onClose] should call the original [handleClose], probably unmounting the component.
 */
export const useTransition = (
  isOpen: boolean,
  { initiateClose, onClose }: { initiateClose: () => void; onClose: () => void }
) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    initiateClose();
    const timer = setTimeout(onClose, transitionDuration);
    return () => clearTimeout(timer);
  }, [initiateClose, onClose]);

  return { show, setShow, handleClose };
};

const transition = (className: string) => ({
  enter: classNames(className, styles.enter),
  enterFrom: classNames(className, styles.enterFrom),
  enterTo: classNames(className), // TODO: styles.enterTo
  leave: classNames(className, styles.leave),
  leaveFrom: classNames(className), // TODO: styles.leaveFrom
  leaveTo: classNames(className, styles.leaveTo),
});

export const transitions = {
  opacity: transition(styles.opacity),
  zoom: transition(styles.zoom),
};
