import useMfa from "modules/mfa/useMfa";
import { useState } from "react";
import { isCardLocked } from "resources/cards/utils";
import Switch from "ui/inputs/Switch";

import LockCardModal from "../../LockCardModal";
import { useCardDetailsPageContext } from "../../providers/CardDetailsPageProvider";

const CardDetailsLockCardSwitch = () => {
  const { card } = useCardDetailsPageContext();
  const { mfa } = useMfa();
  const isLocked = isCardLocked(card);
  const [showLockCardModal, setShowLockCardModal] = useState(false);

  return (
    <>
      {showLockCardModal && (
        <LockCardModal cardId={card.id} onClose={() => setShowLockCardModal(false)} />
      )}

      <Switch
        value={isLocked}
        onChange={() => {
          mfa().then(() => setShowLockCardModal(true));
        }}
      />
    </>
  );
};

export default CardDetailsLockCardSwitch;
