import ArchiveBusinessTaskRep from "reps/ArchiveBusinessTaskRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

import { useRefreshAllBusinessesQueries } from "../queries/businessesQueryHooks";

const useArchiveBusinessMutation = (
  additionalOptions?: MutationAdditionalOptions<void, ArchiveBusinessTaskRep.Creator>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshAllBusinessesQueries = useRefreshAllBusinessesQueries();
  const { segmentTrack } = useSegment();

  return useMutationWithDefaults(
    {
      mutationFn: (creator: ArchiveBusinessTaskRep.Creator) => {
        return highbeamApi.archiveBusiness.archive(creator);
      },
      onSuccess: async () => {
        segmentTrack(SEGMENT_EVENTS.BUSINESS_APPLICATION_ARCHIVED);
        await refreshAllBusinessesQueries();
      },
    },
    additionalOptions || {}
  );
};

export default useArchiveBusinessMutation;
