import OnboardingLayout from "layouts/OnboardingLayout";
import OnboardingCardLayout from "modules/onboarding/layouts/OnboardingCardLayout";

const OnboardingDelayed = () => (
  <OnboardingLayout>
    <OnboardingCardLayout
      title="Something went wrong"
      subtitle="We are sorry, there is a temporary issue with your signup. Please come back in a few minutes, or reach out to support."
    />
  </OnboardingLayout>
);

export default OnboardingDelayed;
