import { Dialog, DialogBackdrop, Transition, TransitionChild } from "@headlessui/react";
import classNames from "classnames";
import { FC, Fragment, ReactNode, Suspense } from "react";

import styles from "./Modal.module.scss";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
import ModalLoadingFallback from "./ModalLoadingFallback";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  unmountChildrenWhenClosed?: boolean;
  size?: "standard" | "small";
  suspenseFallback?: ReactNode;

  /**
   * HACK: Set this to `true` if you have a dropdown component inside the modal body, but gets cut off by the modal's overflow styles.
   * If you enable this, the overflow will no longer scroll, so any overflow will get cut off. We will have to use a different underlying modal component to get around this limitation (I think).
   */
  dropdownOverflowHack?: boolean;
};

const Modal: FC<Props> = ({
  isOpen,
  onClose,
  children,
  className,
  unmountChildrenWhenClosed,
  size = "standard",
  dropdownOverflowHack,
  suspenseFallback = <ModalLoadingFallback />,
}) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter={styles.enterBackdrop}
          enterFrom={styles.enterFromBackdrop}
          enterTo={styles.enterToBackdrop}
          leave={styles.leaveBackdrop}
          leaveFrom={styles.leaveFromBackdrop}
          leaveTo={styles.leaveToBackdrop}
        >
          <DialogBackdrop className={styles.backdrop} onClick={onClose} />
        </TransitionChild>

        <TransitionChild
          as={"div"}
          className={styles.container}
          enter={styles.enterModal}
          enterFrom={styles.enterFromModal}
          leave={styles.leaveModal}
          leaveTo={styles.leaveToModal}
        >
          {unmountChildrenWhenClosed && !isOpen ? null : (
            <div
              className={classNames(className, styles.content, {
                [styles.small]: size === "small",
                [styles.dropdownOverflowHack]: dropdownOverflowHack,
              })}
            >
              <Suspense fallback={suspenseFallback}>{children}</Suspense>
            </div>
          )}
        </TransitionChild>
      </Dialog>
    </Transition>
  );
};

export default Object.assign(Modal, {
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
  LoadingFallback: ModalLoadingFallback,
});
