import { ArrowSquareIn } from "@phosphor-icons/react";
import classNames from "classnames";
import Emoji from "components/Emoji";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import isGettingStartedSetupGuideOpenState from "resources/action-items/get-started/state/gettingStartedSetupGuide";
import { ActionItem } from "resources/action-items/types";
import Text from "ui/typography/Text";

import styles from "./ActionItemDrawer.module.scss";
import ActionItemRow from "./ActionItemRow";

type Props = {
  title: string;
  subtitle?: string;
  fullWidth?: boolean;
  actionItems: ActionItem[];
};

const ActionItemDrawer: React.FC<Props> = ({ title, fullWidth, subtitle, actionItems }) => {
  const [activeAction, setActiveAction] = useState<number | undefined>();
  const [showGetStarted, setShowGetStarted] = useRecoilState(isGettingStartedSetupGuideOpenState);

  useEffect(() => {
    const firstIncompleteAction = actionItems.find((aItem) => !aItem.finishedActionItemRep);
    setActiveAction(firstIncompleteAction?.order);
  }, [actionItems]);

  const sortedActionItems = [...actionItems].sort(
    (a: ActionItem, b: ActionItem) => Number(a.order) - Number(b.order)
  );

  return (
    <div className={classNames(styles.actionItemContainer, fullWidth && styles.fullWidth)}>
      <div className={styles.actionItemHeader}>
        <div className={styles.actionItemHeaderLeftContent}>
          <Text size={16} weight="bold">
            <Emoji className={styles.waveEmoji} animation="wave">
              👋
            </Emoji>{" "}
          </Text>
          <div>
            <Text size={16} weight="bold">
              {title}
            </Text>
            {subtitle && <Text size={14}>{subtitle}</Text>}
          </div>
        </div>
        <button
          title="Preview"
          type="button"
          className={styles.arrowSquareInDown}
          onClick={() => setShowGetStarted(!showGetStarted)}
        >
          <ArrowSquareIn size={24} weight="light" />
        </button>
      </div>
      {sortedActionItems.map((actionItem, i) => (
        <ActionItemRow
          fullWidth={fullWidth}
          key={actionItem.name}
          number={i + 1}
          active={activeAction === actionItem.order}
          setActive={setActiveAction}
          actionItem={actionItem}
        />
      ))}
    </div>
  );
};

export default ActionItemDrawer;
