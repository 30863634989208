import React from "react";
import ReactMarkdown from "react-markdown";
import ChatMessageContent from "reps/chat/ChatMessageContent";
import colors from "styles/colors";
import Text from "ui/typography/Text";

type Props = {
  section: ChatMessageContent.Markdown;
};

const ChatResponseMarkdown: React.FC<Props> = ({ section }) => {
  return (
    <Text as="div" size={16} color={colors.grey[800]} className="flex flex-col gap-3">
      <ReactMarkdown>{section.markdown}</ReactMarkdown>
    </Text>
  );
};

export default ChatResponseMarkdown;
