import Flexpane from "ui/overlay/Flexpane";

import RecurringPaymentFlexpaneProvider from "./context/RecurringPaymentFlexpaneProvider";
import RecurringPaymentInfoFlexpaneAmountSection from "./RecurringPaymentInfoFlexpaneAmountSection/RecurringPaymentInfoFlexpaneAmountSection";
import RecurringPaymentInfoFlexpaneDetailsSection from "./RecurringPaymentInfoFlexpaneDetailsSection/RecurringPaymentDetailsSection";
import RecurringPaymentFlexpaneHeader from "./RecurringPaymentInfoFlexpaneDetailsSection/RecurringPaymentFlexpaneHeader";
import RecurringPaymentInfoFlexpaneToFromSection from "./RecurringPaymentInfoFlexpaneToFromSection";

type Props = {
  recurringPaymentId?: string;
  onClose: () => void;
};

const RecurringPaymentInfoFlexpane: React.FC<Props> = ({ recurringPaymentId, onClose }) => {
  const isOpen = Boolean(recurringPaymentId);

  return (
    <Flexpane isOpen={isOpen} onClose={onClose}>
      {recurringPaymentId && (
        <RecurringPaymentFlexpaneProvider close={onClose} recurringPaymentId={recurringPaymentId}>
          <RecurringPaymentFlexpaneHeader />

          <Flexpane.Section>
            <RecurringPaymentInfoFlexpaneToFromSection />
          </Flexpane.Section>

          <Flexpane.Section>
            <RecurringPaymentInfoFlexpaneAmountSection />
          </Flexpane.Section>

          <Flexpane.Section>
            <RecurringPaymentInfoFlexpaneDetailsSection />
          </Flexpane.Section>
        </RecurringPaymentFlexpaneProvider>
      )}
    </Flexpane>
  );
};

export default RecurringPaymentInfoFlexpane;
