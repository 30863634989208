import HighbeamBaseApi from "api/HighbeamBaseApi";
import CategorizationRuleRep from "reps/Insights/CategorizationRuleRep";

export default class CategorizationRuleApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(
    businessGuid: string | null,
    creator: CategorizationRuleRep.Creator
  ): Promise<CategorizationRuleRep | null> {
    const queryParams = new URLSearchParams({ ...(businessGuid ? { businessGuid } : {}) });
    const url = `/categorization-rules?${queryParams.toString()}`;
    return await this.api.post<CategorizationRuleRep>(url, creator);
  }

  async getByBusiness(businessGuid: string | null): Promise<CategorizationRuleRep[]> {
    const queryParams = new URLSearchParams({ ...(businessGuid ? { businessGuid } : {}) });
    const url = `/categorization-rules?${queryParams.toString()}`;
    return (await this.api.get<CategorizationRuleRep[]>(url))!;
  }

  async update(
    businessGuid: string | null,
    categorizationRuleGuid: string,
    updater: CategorizationRuleRep.Updater
  ): Promise<CategorizationRuleRep | null> {
    const queryParams = new URLSearchParams({ ...(businessGuid ? { businessGuid } : {}) });
    const url = `/categorization-rules/${categorizationRuleGuid}?${queryParams.toString()}`;
    return await this.api.patch<CategorizationRuleRep>(url, updater);
  }
}
