import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import BillDocumentRep from "reps/BillDocumentRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const BILL_DOCUMENTS_QUERY_KEY = "bill-documents";

type Params = {
  billId: string;
  type?: BillDocumentRep.DocumentType[];
};

export const useBillDocumentsQueryOptions = ({ billId, type }: Params) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [BILL_DOCUMENTS_QUERY_KEY, { billId, type }],
    queryFn: async () => {
      const billDocuments = await highbeamApi.billDocument.searchByBillId(billId);
      if (type) {
        return billDocuments.filter((billDocument) => type.includes(billDocument.type));
      }
      return billDocuments;
    },
  });
};

export const useRefreshBillDocumentsQueries = () => {
  return useRefreshQuery([BILL_DOCUMENTS_QUERY_KEY]);
};

export const useBillDocumentsQuery = (params: Params) => {
  return useQuery(useBillDocumentsQueryOptions(params));
};

const useBillDocuments = (params: Params) => {
  const { data } = useSuspenseQuery(useBillDocumentsQueryOptions(params));
  return data;
};

export default useBillDocuments;
