import classNames from "classnames";
import { FC, ReactNode } from "react";
import colors from "styles/colors";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./SummaryItem.module.scss";

const Label: FC<TextProps> = ({ className, ...textProps }) => (
  <Text
    size={14}
    color={colors.grey[500]}
    weight="regular"
    className={classNames(className, styles.label)}
    {...textProps}
  />
);

const Value: FC<TextProps> = ({ className, ...textProps }) => (
  <Text
    size={14}
    color={colors.grey[900]}
    weight="medium"
    className={classNames(className, styles.value)}
    {...textProps}
  />
);

type SummaryItemProps = {
  label?: ReactNode;
  value?: ReactNode;
};

const SummaryItem: FC<SummaryItemProps> = ({ label, value }) => (
  <div className={styles.summaryItem}>
    {label && <Label>{label}</Label>}
    {value && <Value>{value}</Value>}
  </div>
);

export default SummaryItem;
