import { DotsThreeCircle, XCircle } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./UpsellCapitalTeaserStatusBadge.module.scss";

type Props = {
  status: "in-progress" | "not-approved";
};

const UpsellCapitalTeaserStatusBadge: FC<Props> = ({ status }) => {
  switch (status) {
    case "in-progress":
      return (
        <div className={classNames(styles.container, styles.inProgress)}>
          <DotsThreeCircle color={colors.purple[500]} size={20} weight="light" />
          <Text size={14} color={colors.purple[500]}>
            Application in progress
          </Text>
        </div>
      );
    case "not-approved":
      return (
        <div className={classNames(styles.container, styles.notApproved)}>
          <XCircle color={colors.red[500]} size={20} weight="light" />
          <Text size={14} color={colors.red[500]} weight="medium">
            Application not approved
          </Text>
        </div>
      );
  }
};

export default UpsellCapitalTeaserStatusBadge;
