import { FC, ReactElement } from "react";
import RutterConnectionRep from "reps/RutterConnectionRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Card from "ui/data-display/Card";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import { Heading4, Paragraph } from "ui/typography";
import { getRutterIcon } from "utils/rutter";
import getAccountingSoftwareName from "utils/rutter/getAccountingSoftwareName";

type Props = {
  description: ReactElement;
  addText: string;
  type: RutterConnectionRep.RutterAccountingPlatform;
  isAccountingPlatformConnected?: boolean;
  onButtonClick: () => void;
};

const AccountingSoftwareCard: FC<Props> = ({
  type,
  description,
  addText,
  onButtonClick,
  isAccountingPlatformConnected,
}) => {
  const title = getAccountingSoftwareName(type);
  const isSuperusering = useIsSuperusering();

  return (
    <Card
      className="flex h-48 w-full flex-col items-start gap-8 border border-grey-300 p-6"
      shadow="none"
    >
      <div className="flex min-h-16 flex-col items-start gap-2">
        <Heading4 className="flex items-center justify-center gap-2 text-grey-800">
          <img src={getRutterIcon(type)} alt={`${title} logo`} />
          {title}
        </Heading4>
        {description}
      </div>

      <ButtonWithTooltip
        className="w-full"
        variant={isAccountingPlatformConnected ? "danger" : "tertiary"}
        onClick={onButtonClick}
        disabled={isAccountingPlatformConnected && !isSuperusering}
        tooltip={
          isAccountingPlatformConnected && <DisconnectAccountingSoftwareTooltip title={title} />
        }
      >
        {addText}
      </ButtonWithTooltip>
    </Card>
  );
};

const DisconnectAccountingSoftwareTooltip: FC<{ title: string }> = ({ title }) => (
  <Paragraph>
    If you disconnect {title}, then your line of credit will be frozen. If you want to disconnect{" "}
    {title}, please reach out to Highbeam support.
  </Paragraph>
);

export default AccountingSoftwareCard;
