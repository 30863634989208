import { Icon } from "@phosphor-icons/react";
import classNames from "classnames";
import React, {
  useState,
  useRef,
  useCallback,
  Ref,
  ComponentType,
  ReactNode,
  useEffect,
} from "react";
import Select, {
  components,
  GroupBase,
  Props as SelectProps,
  SelectInstance,
  MenuProps as DefaultMenuProps,
  OptionProps as DefaultOptionProps,
} from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Button from "ui/inputs/Button";
import InputWrapper, { shouldShowLabelAsFocused, CommonInputProps } from "ui/inputs/InputWrapper";
import Text from "ui/typography/Text";

import ClearIndicator from "./ClearIndicator";
import CreateLabel from "./CreateLabel";
import styles from "./Dropdown.module.scss";
import DropdownIndicator from "./DropdownIndicator";

export type Option = {
  label: ReactNode;
  value: string;
  description?: ReactNode;
  isDisabled?: boolean;
};

export type OnDropdownChange = {
  error: boolean;
  errorMessage?: string;
};

type DropdownOptions = {
  initialValue?: Option;
  onChange?: (value: Option | null) => Promise<OnDropdownChange> | OnDropdownChange;
  shouldSetTouchedOnChange?: boolean;
};

export type DropdownState = {
  value: Option | null;
  onChange: (selected: Option) => void;
  onBlur: () => void;
  setTouched: () => void;
  isValid: boolean;
  hasError: boolean;
  errorMessage: string | undefined;
};

export const useDropdown = (options: DropdownOptions = {}): DropdownState => {
  const [touched, setTouched] = useState(false);
  const [previousValue, setPreviousValue] = useState<Option | null>(options.initialValue ?? null);
  const [value, setValue] = useState(options.initialValue ?? null);
  const [tic, setTic] = useState<OnDropdownChange>({ error: false });
  const onBlur = useCallback(() => {
    if (value !== previousValue) setTouched(true);
    setPreviousValue(value);
  }, [previousValue, value]);
  useEffect(() => {
    async function onDropdownChange() {
      if (!options.onChange) return;
      if (value === previousValue) return;

      const result = await options.onChange(value);
      if (!result.error) setPreviousValue(value);
      if (options.shouldSetTouchedOnChange && result.error) setTouched(true);

      setTic(result);
    }
    onDropdownChange();
  }, [value, previousValue]); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    value,
    onChange: setValue,
    onBlur,
    setTouched: () => setTouched(true),
    isValid: !tic.error,
    hasError: touched && Boolean(tic.error),
    errorMessage: tic.errorMessage,
  };
};

type BaseProps<T> = Pick<
  SelectProps,
  "isLoading" | "inputValue" | "autoFocus" | "onInputChange" | "isSearchable" | "isClearable"
> &
  CommonInputProps &
  Pick<CreatableProps<T, false, GroupBase<T>>, "formatCreateLabel">;

export type MenuPendProps = {
  icon?: Icon;
  iconClassName?: string;
  text: string;
  onClick?: () => void;
  description?: string;
  // We don't support custom classes on rightIcon at the moment,
  // but easily could by following the pattern in `iconClassName`.
  rightIcon?: Icon;
};

type Props<TOption> = BaseProps<TOption> & {
  placeholder?: string;
  value: TOption | null;
  options: TOption[];
  onChange?: (selected: TOption) => void;
  onClear?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  hideCaret?: boolean;
  hideCursor?: boolean;
  disabled?: boolean;
  autoWidth?: boolean;
  filterOption?: (option: FilterOptionOption<unknown>, inputValue: string) => boolean;
  menuClassName?: string;
  optionClassName?: string;
  className?: string;
  menuPlacement?: "top" | "bottom";
  menuPrepend?: MenuPendProps;
  menuAppend?: MenuPendProps;
  onCreate?: CreatableProps<TOption, false, GroupBase<TOption>>["onCreateOption"];
  isValidNewOption?: CreatableProps<TOption, false, GroupBase<TOption>>["isValidNewOption"];
};
type OptionProps = DefaultOptionProps<Option, false, GroupBase<Option>>;
type MenuProps = DefaultMenuProps<Option, false, GroupBase<Option>>;

const noStyleHandler: ProxyHandler<object> = { get: () => () => ({}) };
const resetStyles = new Proxy({}, noStyleHandler);

const Dropdown = <TOption extends Option>({
  label,
  hasError,
  errorMessage,
  placeholder,
  options,
  value,
  append,
  menuPlacement = "bottom",
  menuPrepend,
  menuAppend,
  onChange,
  onClear,
  onCreate,
  onBlur,
  onFocus,
  formatCreateLabel,
  autoFocus,
  onInputChange,
  isLoading,
  isSearchable,
  isClearable,
  inputValue,
  disabled,
  hideCaret = isLoading || disabled,
  hideCursor,
  menuClassName,
  className,
  autoWidth,
  hideBorders,
  optionClassName,
  filterOption,
  isValidNewOption,
}: Props<TOption>) => {
  const [focusOutline, setFocusOutline] = useState(autoFocus ?? false);
  const selectRef = useRef<SelectInstance<Option>>(null);
  const uncontrolledSelectIndicativeValue = selectRef.current?.hasValue() ? "true" : "";
  const showLabelAsFocused = shouldShowLabelAsFocused(
    label,
    value?.value || uncontrolledSelectIndicativeValue,
    placeholder,
    focusOutline
  );

  const Option = (({ innerRef, innerProps, children, ...props }: OptionProps) => (
    <div
      ref={innerRef}
      {...innerProps}
      className={classNames(
        styles.option,
        !props.isDisabled && props.isFocused && styles["option--focused"],
        props.isDisabled && styles["option--disabled"],
        optionClassName
      )}
      onMouseLeave={() => {
        selectRef?.current?.setState((prev) => ({ ...prev, focusedOption: null }));
      }}
    >
      {typeof children === "string" ? (
        <Text size={14} className={classNames(styles.labelText)}>
          {children}
        </Text>
      ) : (
        children
      )}
      {props.data?.description && (
        <>
          {typeof props.data?.description === "string" ? (
            <Text size={12} className={classNames(styles.descriptionText)}>
              {props.data?.description}
            </Text>
          ) : (
            props.data?.description
          )}
        </>
      )}
    </div>
  )) as ComponentType<any> | undefined;

  const MenuPend: React.FC<MenuPendProps & { prepend?: boolean; append?: boolean }> = (props) => (
    <div
      className={classNames({
        [styles.pend]: true,
        [styles["pend--clickable"]]: Boolean(props.onClick),
        [styles.append]: props.append,
        [styles.prepend]: props.prepend,
      })}
      onTouchEnd={(e) => {
        e.preventDefault();
        // workaround for https://github.com/JedWatson/react-select/issues/4024
        // required for triggering callbacks for mobile
        props.onClick?.();
        selectRef.current?.blur();
      }}
    >
      <Button
        onClick={(e) => {
          e.stopPropagation();
          props.onClick?.();
          selectRef.current?.blur();
        }}
      >
        <div className={styles.pendContents}>
          <div className={styles.pendHeader}>
            {props.icon && (
              <props.icon size={16} className={classNames(styles.pendIcon, props.iconClassName)} />
            )}
            <Text size={14} weight={props.append ? "bold" : "regular"} className={styles.pendText}>
              {props.text}
            </Text>
          </div>
          {props.description && (
            <Text size={12} className={styles.pendDescription}>
              {props.description}
            </Text>
          )}
        </div>
        {props.rightIcon && <props.rightIcon size={20} />}
      </Button>
    </div>
  );
  const Menu = useCallback(
    ({ innerRef, innerProps, children, className, ...props }: MenuProps) => (
      <div
        className={classNames(
          menuClassName,
          className,
          styles.menu,
          {
            [styles["menu--bottom"]]: menuPlacement === "bottom",
            [styles["menu--top"]]: menuPlacement === "top",
          },
          (selectRef.current?.hasOptions() || menuAppend || menuPrepend) &&
            styles["menu--has-children"]
        )}
        ref={innerRef}
        {...innerProps}
      >
        {!props.selectProps.inputValue && menuPrepend && <MenuPend prepend {...menuPrepend} />}
        {children}
        {menuAppend && <MenuPend append {...menuAppend} />}
      </div>
    ),
    [menuPlacement, menuPrepend, menuAppend, menuClassName, selectRef]
  ) as ComponentType<any>;

  const SelectTag = onCreate ? CreatableSelect : Select;

  return (
    <InputWrapper
      label={label}
      hasError={hasError}
      errorMessage={errorMessage}
      focusOutline={focusOutline}
      showLabelAsFocused={showLabelAsFocused}
      hideCursor={hideCursor}
      hasValue={Boolean(selectRef.current?.inputRef?.value || value?.value)}
      append={append}
      className={className}
      disabled={disabled}
      hideBorders={hideBorders}
    >
      <>
        <SelectTag
          className={classNames({
            [styles.container]: true,
            [styles.disabled]: disabled,
            [styles.focused]: showLabelAsFocused,
            [styles["auto-width"]]: autoWidth,
          })}
          classNamePrefix={"dropdown"}
          ref={selectRef as Ref<any>}
          styles={resetStyles}
          onFocus={() => {
            setFocusOutline(true);
            onFocus?.();
          }}
          onBlur={() => {
            setFocusOutline(false);
            onBlur?.();
          }}
          value={value}
          isMulti={false}
          onChange={(option) => {
            if (option) {
              onChange?.(option);
            } else {
              onClear?.();
            }
          }}
          components={{
            ...components,
            DropdownIndicator: (props) => (hideCaret ? null : <DropdownIndicator {...props} />),
            ClearIndicator: ClearIndicator,
            Option,
            Menu,
            LoadingIndicator: () => <AnimatedSpinner size={18} />,
            NoOptionsMessage: () => null,
            LoadingMessage: () => null,
          }}
          isLoading={isLoading}
          inputValue={inputValue}
          onInputChange={onInputChange}
          autoFocus={autoFocus}
          options={options}
          placeholder={placeholder ?? null}
          onCreateOption={onCreate}
          isSearchable={isSearchable}
          isClearable={isClearable}
          isDisabled={disabled}
          filterOption={filterOption}
          formatCreateLabel={formatCreateLabel}
          isValidNewOption={isValidNewOption}
        />
      </>
    </InputWrapper>
  );
};

export default Object.assign(Dropdown, {
  CreateLabel: CreateLabel,
});
