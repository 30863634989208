import Flexpane from "ui/overlay/Flexpane";

import ReceivedPaymentInfoFlexpaneAmountSection from "./components/ReceivedPaymentInfoFlexpaneAmountSection";
import ReceivedPaymentInfoFlexpaneDetailsSection from "./components/ReceivedPaymentInfoFlexpaneDetailsSection";
import ReceivedPaymentInfoFlexpaneFundAccountSection from "./components/ReceivedPaymentInfoFlexpaneFundAccountSection";
import ReceivedPaymentInfoFlexpaneHeaderSection from "./components/ReceivedPaymentInfoFlexpaneHeaderSection";
import ReceivedPaymentInfoFlexpaneRetryButton from "./components/ReceivedPaymentInfoFlexpaneRetryButton";
import ReceivedPaymentInfoFlexpaneToFromSection from "./components/ReceivedPaymentInfoFlexpaneToFromSection";
import ReceivedPaymentInfoFlexpaneProvider from "./context/ReceivedPaymentInfoFlexpaneProvider";

type Props = {
  receivedPaymentId?: string;
  onClose: () => void;
};

const ReceivedPaymentInfoFlexpane: React.FC<Props> = ({ receivedPaymentId, onClose }) => {
  return (
    <Flexpane isOpen={Boolean(receivedPaymentId)} onClose={onClose} disableInitialFocus>
      {receivedPaymentId && (
        <ReceivedPaymentInfoFlexpaneProvider
          onClose={onClose}
          receivedPaymentId={receivedPaymentId}
        >
          <ReceivedPaymentInfoFlexpaneHeaderSection />
          <ReceivedPaymentInfoFlexpaneToFromSection />
          <ReceivedPaymentInfoFlexpaneFundAccountSection />
          <ReceivedPaymentInfoFlexpaneAmountSection />
          <ReceivedPaymentInfoFlexpaneDetailsSection />
          <ReceivedPaymentInfoFlexpaneRetryButton />
        </ReceivedPaymentInfoFlexpaneProvider>
      )}
    </Flexpane>
  );
};

export default ReceivedPaymentInfoFlexpane;
