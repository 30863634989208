import { CheckSquare, FlagCheckered } from "@phosphor-icons/react";
import chase from "assets/bank-logos/chase.png";
import highbeamAndPlaidLogos from "assets/highbeam-and-plaid-logos.webp";
import { FC } from "react";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import usePlaidLinkToken from "resources/plaid-connections/queries/usePlaidLinkToken";
import Button from "ui/inputs/Button";
import useModalContext from "ui/overlay/ModalV4/useModalContext";
import { Heading3, Paragraph } from "ui/typography";
import Text from "ui/typography/Text";
import useHighbeamPlaidLink, {
  HighbeamPlaidLinkOnSuccess,
} from "utils/customHooks/useHighbeamPlaidLink";
import cn from "utils/tailwind/cn";

type Props = {
  existingConnection?: PlaidConnectionRep.Complete;
  onLinkSuccess?: HighbeamPlaidLinkOnSuccess;
};

const shouldShowChaseInfo = (existingConnection?: PlaidConnectionRep.Complete): boolean => {
  return !existingConnection || existingConnection.institutionId === "ins_56";
};

const rowClasses = "flex items-start gap-x-4";
const iconClasses = "mt-0.5 shrink-0 size-5";

const PlaidLinkModalContents: FC<Props> = ({ existingConnection, onLinkSuccess }) => {
  const linkToken = usePlaidLinkToken(existingConnection?.guid);
  const { closeModalWithAnimation } = useModalContext();
  const { openPlaid, isPlaidReady } = useHighbeamPlaidLink({
    linkToken: linkToken ?? null,
    onSuccess: (...args) => {
      closeModalWithAnimation();
      onLinkSuccess?.(...args);
    },
    onError: closeModalWithAnimation,
    existingConnectionBeingUpdated: existingConnection,
  });

  return (
    <div className="flex flex-col items-center gap-6">
      <img src={highbeamAndPlaidLogos} className="w-full max-w-24" alt="Highbeam and Plaid logos" />
      <Text size={16} align="center">
        Please read these instructions before connecting your account with Plaid.
      </Text>
      <div className={rowClasses}>
        <CheckSquare className={iconClasses} />

        <div>
          <Heading3 className="font-medium">Selecting accounts</Heading3>
          {existingConnection ? (
            <Paragraph className="text-sm text-grey-800">
              Make sure to select any previously connected accounts and cards to avoid disconnecting
              them.
            </Paragraph>
          ) : (
            <Paragraph className="text-sm text-grey-800">
              Make sure to select all bank accounts and cards that you want to connect.
            </Paragraph>
          )}
        </div>
      </div>
      <div className={rowClasses}>
        <FlagCheckered className={iconClasses} />

        <div>
          <Heading3 className="font-medium">Finish the flow</Heading3>
          {existingConnection ? (
            <Paragraph className="text-sm text-grey-800">
              Please complete the Plaid connection steps through to the end, otherwise the
              connection will disconnect.
            </Paragraph>
          ) : (
            <Paragraph className="text-sm text-grey-800">
              Please complete the Plaid connection steps through to the end, otherwise the
              connection will not succeed.
            </Paragraph>
          )}
        </div>
      </div>
      {shouldShowChaseInfo(existingConnection) && (
        <div className={cn(rowClasses, "border-t border-t-grey-100 pt-6")}>
          <img src={chase} alt="Chase logo" className={iconClasses} />

          <div>
            <Heading3 className="font-medium">Connecting a Chase account?</Heading3>
            <Paragraph className="text-sm text-grey-800">
              Make sure you are the person who first created the account, or the connection will not
              succeed.
            </Paragraph>
          </div>
        </div>
      )}

      <Button
        type="submit"
        variant="tertiary"
        isLoading={!linkToken || !isPlaidReady}
        onClick={openPlaid}
        className="mt-2 w-full"
      >
        Continue
      </Button>
    </div>
  );
};

export default PlaidLinkModalContents;
