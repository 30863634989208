import { Card } from "@highbeam/unit-node-sdk";
import React from "react";
import { getCardName } from "resources/cards/utils";
import DropdownV2 from "ui/inputs/DropdownV2";

type Props = {
  cards: Card[];
  card: Card | undefined;
  setCard: (card: Card) => void;
};

const CardDropdown: React.FC<Props> = ({ cards, card, setCard }) => {
  return (
    <div>
      <DropdownV2
        label="Card"
        options={cards}
        value={card ?? null}
        getOptionLabel={getCardName}
        getOptionValue={(card) => card.id}
        onChange={(card) => card && setCard(card)}
      />
    </div>
  );
};

export default CardDropdown;
