import { atom } from "recoil";
import BankAccountRep from "reps/BankAccountRep";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

type SetPrimaryAccountModalParams = {
  bankAccount: BankAccountRep.Complete;
};

const setPrimaryAccountModalState = atom<DialogState<SetPrimaryAccountModalParams>>({
  key: "bankAccounts/setPrimaryBankAccountModalState",
  default: { isOpen: false },
});

export default setPrimaryAccountModalState;

export const useSetPrimaryAccountModal = () => {
  return useModal<SetPrimaryAccountModalParams>(setPrimaryAccountModalState);
};
