import { FC, ReactNode } from "react";
import Tooltip from "ui/overlay/Tooltip";
import { formatBankingDate } from "utils/date";
import cn from "utils/tailwind/cn";

import TextCell from "../TextCell";

type Props = {
  date: string;
  className?: string;
  tooltip?: ReactNode;
  hideCurrentYear?: boolean;
  darken?: boolean;
};

const DateCell: FC<Props> = ({
  date,
  className,
  hideCurrentYear = false,
  tooltip = null,
  darken,
}) => {
  return (
    <Tooltip enabled={Boolean(tooltip)}>
      <Tooltip.Trigger asChild>
        <TextCell
          className={cn(
            tooltip && "inline-flex w-auto whitespace-nowrap hover:underline",
            className
          )}
          darken={darken}
        >
          {formatBankingDate(date, hideCurrentYear)}
        </TextCell>
      </Tooltip.Trigger>

      <Tooltip.Content>{tooltip}</Tooltip.Content>
    </Tooltip>
  );
};

export default DateCell;
