import { useSuspenseQuery } from "@tanstack/react-query";

import useBusinessAddressQueryOptions from "./useBusinessAddressQueryOptions";

const useBusinessAddress = () => {
  // NB(alex): We need to come up with a pattern for ensuring data is defined without using the non-null assertion.
  // This works for now, but the app will crash if this hook is used prior to completion of onboarding or if banking application is denied.
  // https://github.com/highbeamco/highbeam/pull/6908/files#r1412476657
  return useSuspenseQuery(useBusinessAddressQueryOptions()).data!;
};

export default useBusinessAddress;
