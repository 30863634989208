import { TaxFormsListParams } from "@highbeam/unit-node-sdk";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const UNIT_CO_TAX_FORMS_QUERY_KEY = "unitTaxForms";

const useUnitCoTaxFormsQueryOptions = (params?: TaxFormsListParams) => {
  const unitApi = useUnitApi();

  return useQueryOptions({
    queryKey: [UNIT_CO_TAX_FORMS_QUERY_KEY, params],
    queryFn: async () =>
      unitApi.taxForms.list({
        ...params,
      }),
  });
};

export default useUnitCoTaxFormsQueryOptions;
