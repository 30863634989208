import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import useUnitCoRecurringPayment from "resources/unit-co-recurring-payments/queries/useUnitCoRecurringPayment";

import { useRecurringPaymentFlexpaneContext } from "../context/RecurringPaymentFlexpaneProvider";

const RecurringPaymentInfoFlexpaneAmountSection = () => {
  const { recurringPaymentId } = useRecurringPaymentFlexpaneContext();
  const recurringPayment = useUnitCoRecurringPayment(recurringPaymentId, { required: true });

  return (
    <TransactionFlexpaneAmount cents={recurringPayment.attributes.amount} direction="negative" />
  );
};

export default RecurringPaymentInfoFlexpaneAmountSection;
