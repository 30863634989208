import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountRepaymentPeriodContent: FC<Props> = ({ capitalAccountSummary }) => {
  const targetRepaymentDays = capitalAccountSummary.details.targetRepaymentDays;

  return <>{targetRepaymentDays === 1 ? "Daily" : `${targetRepaymentDays} days`}</>;
};

export default CapitalAccountRepaymentPeriodContent;
