import { RecurringPayment } from "@highbeam/unit-node-sdk";
import { useQuery } from "@tanstack/react-query";
import { getCounterpartyIdFromRecurringPayment } from "resources/recurring-payments/utils/getCounterpartyIdFromRecurringPayment";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import colors from "styles/colors";
import Shimmer from "ui/feedback/Shimmer";
import NotFoundCell from "ui/table/NotFoundCell";
import Text from "ui/typography/Text";

const RecurringPaymentCounterparty = ({ payment }: { payment: RecurringPayment }) => {
  const unitApi = useUnitApi();

  const counterpartyId = getCounterpartyIdFromRecurringPayment(payment);

  const { data: counterparty, isLoading } = useQuery({
    queryKey: ["counteryparty", { counterpartyId }],
    queryFn: async () => {
      const { data: counterparty } = await unitApi.counterparties.get(counterpartyId);
      return counterparty;
    },
  });

  if (isLoading) {
    return <Shimmer />;
  }

  if (!counterparty) {
    return <NotFoundCell />;
  }

  return (
    <Text size={14} weight="medium" color={colors.grey[800]}>
      {counterparty.attributes.name}
    </Text>
  );
};

export default RecurringPaymentCounterparty;
