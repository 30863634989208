import { IncomingPayment } from "modules/incoming-payments/types";

type IncomingPaymentMethodName = {
  shortMethodName: string;
  longMethodName: string;
};

const getIncomingPaymentMethodName = (
  incomingPayment: IncomingPayment
): IncomingPaymentMethodName => {
  switch (incomingPayment.type) {
    case "achPayment":
      return {
        shortMethodName: "ACH",
        longMethodName: "ACH transaction",
      };
    case "achReceivedPayment":
      return {
        shortMethodName: "ACH",
        longMethodName: "ACH transaction",
      };
    case "bookPayment":
      return {
        shortMethodName: "Book",
        longMethodName: "Book transaction",
      };
    case "checkDeposit":
      return {
        shortMethodName: "Check",
        longMethodName: "Check deposit",
      };
    case "wirePayment":
      return {
        shortMethodName: "Wire",
        longMethodName: "Wire transaction",
      };
    default:
      return {
        shortMethodName: "Unknown",
        longMethodName: "Unknown",
      };
  }
};

export default getIncomingPaymentMethodName;
