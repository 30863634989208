import DashboardPage from "layouts/DashboardPage";
import useNonTerminatedCapitalAccounts from "resources/capital-accounts/hooks/useNonTerminatedCapitalAccounts";

import CapitalAccountsTable from "../../components/CapitalAccountsTable";

const CapitalAccountsSection = () => {
  // NB(alex): CapitalAccountsTable matches the query in `CapitalAccountsTable`. We need this hack until we have empty states for the table.
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();

  if (nonTerminatedCapitalAccounts.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Capital</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <CapitalAccountsTable />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default CapitalAccountsSection;
