import classNames from "classnames";
import { FC, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import Text from "ui/typography/Text";

import styles from "./MenuSubItem.module.scss";

type MenuSubItemProps = {
  text: ReactNode;
  path: string;
  newFeature?: boolean;
  betaFeature?: boolean;
  onClose?: () => void;
  badge?: ReactNode | ((renderProps: { isActive: boolean }) => ReactNode);
};

const MenuSubItem: FC<MenuSubItemProps> = ({
  text,
  path,
  betaFeature,
  newFeature,
  onClose,
  badge,
}) => {
  const pathName = useLocation().pathname;
  const isActive = pathName === path;

  return (
    <Link to={path} onClick={onClose}>
      <div className={styles.wrapper}>
        <Text
          size={14}
          as="div"
          className={classNames({
            [styles.textActive]: isActive,
          })}
        >
          {text}
        </Text>
        {newFeature && (
          <Text size={12} weight={"medium"} className={styles.newFeature}>
            New
          </Text>
        )}
        {betaFeature && (
          <Text size={12} weight={"medium"} className={styles.newFeature}>
            Beta
          </Text>
        )}
        {typeof badge === "function" ? badge({ isActive }) : badge}
      </div>
    </Link>
  );
};

export default MenuSubItem;
