import UnitCoCustomerTokenRep from "reps/UnitCoCustomerTokenRep";
import UnitCoTokenScope from "reps/UnitCoTokenScope";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class UnitCoCustomerTokenApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async create(
    businessGuid: string,
    scopes?: UnitCoTokenScope[]
  ): Promise<UnitCoCustomerTokenRep.Complete> {
    const url = `/unit-co-customer-token`;
    const body: UnitCoCustomerTokenRep.Creation = {
      businessGuid,
      scopes: scopes || null,
    };
    return (await this.api.post<UnitCoCustomerTokenRep.Complete>(url, body))!;
  }
}
