import { HighbeamTransaction, isInternationalWireTransaction } from "utils/types/transactionsTypes";

import BaseTransactionInfoFlexpaneAmountSection from "./BaseTransactionInfoFlexpaneAmountSection";
import InternationalTransactionInfoFlexpaneAmountSection from "./InternationalTransactionInfoFlexpaneAmountSection";

type Props = {
  transaction: HighbeamTransaction;
};

const TransactionInfoFlexpaneAmountSection: React.FC<Props> = ({ transaction }) => {
  if (isInternationalWireTransaction(transaction)) {
    return <InternationalTransactionInfoFlexpaneAmountSection transaction={transaction} />;
  }
  return <BaseTransactionInfoFlexpaneAmountSection transaction={transaction} />;
};

export default TransactionInfoFlexpaneAmountSection;
