import { Plus } from "@phosphor-icons/react";
import { FC, PropsWithChildren } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./CreateLabel.module.scss";

const CreateLabel: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.create}>
      <div className={styles.iconContainer}>
        <Plus size={16} color={colors.grey[900]} />
      </div>
      <div>
        <Text size={14} color={colors.grey[900]}>
          {children}
        </Text>
      </div>
    </div>
  );
};

export default CreateLabel;
