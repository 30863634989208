import colors from "styles/colors";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Text from "ui/typography/Text";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";

import styles from "./TransferBalance.module.scss";

type Props = {
  isLoading: boolean;
  balance: number;
};

const TransferBalance: React.FC<Props> = ({ isLoading = false, balance }) => (
  <div className={styles.balanceContainer}>
    {isLoading && <AnimatedSpinner size={12} />}
    <Text size={12} color={isLoading ? colors.grey[400] : colors.grey[600]}>
      {isLoading ? "Last synced balance: " : "Balance: "}
      {formatAmount(getDollarsFromCents(balance))}
    </Text>
  </div>
);

export default TransferBalance;
