import useHasPermission from "utils/permissions/useHasPermission";

// NB(lev): This is brittle. We determine whether or not to show the "employee view"s by checking
// that the user has the `accountsPayableBill:readAssigned` permission but also
// does not have the `accountsPayableBill:read` permission.
const useShouldShowEmployeeView = () => {
  const hasAccountsPayableReadPermission = useHasPermission("accountsPayableBill:read");
  const hasAccountsPayableReadAssignedPermission = useHasPermission(
    "accountsPayableBill:readAssigned"
  );

  return !hasAccountsPayableReadPermission && hasAccountsPayableReadAssignedPermission;
};

export default useShouldShowEmployeeView;
