import Pill from "ui/data-display/Pill";
import CollapsibleFieldset from "ui/inputs/CollapsibleFieldset";
import { Heading4 } from "ui/typography";

const CheckSection = () => (
  <CollapsibleFieldset
    heading={
      <>
        <Heading4>Check</Heading4>
        <Pill color="grey-light">Coming soon</Pill>
      </>
    }
  />
);

export default CheckSection;
