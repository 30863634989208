import checkBack from "assets/check-back.svg";
import checkFront from "assets/check-front.svg";
import classNames from "classnames";
import { CheckDepositValidation } from "state/checkDeposit";
import colors from "styles/colors";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import InfoIcon from "ui/icons/InfoIcon";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./CaptureCheck.module.scss";

type AutoCaptureProps = {
  direction: string;
  onClick: () => void;
  currentImage: string | null;
};

const ensureSignatureInfo = (
  <div className={styles.info}>
    <InfoIcon size={14} variant={"info"} />
    <Text align="center" size={12} weight="regular">
      Please ensure the check has a signature.
    </Text>
  </div>
);

export const AutoCapturePhotoBody: React.FC<AutoCaptureProps> = ({
  direction,
  onClick,
  currentImage,
}) => (
  <>
    {!currentImage && (
      <>
        <img src={direction === "front" ? checkFront : checkBack} alt="check"></img>
        <div className={styles.tabBodyContainerDescription}>
          <Text align="center" size={14} weight="medium">
            Take a photo of the {direction} of the check
          </Text>{" "}
          <Text align="center" size={12} weight="regular" color={colors.grey[500]}>
            Position the check inside of the rectangle and we’ll automatically take a photo.
          </Text>
        </div>
        {direction === "front" && ensureSignatureInfo}
        <Button variant="primary" onClick={onClick} className={styles.tabBodyContainerButton}>
          Start capture
        </Button>
      </>
    )}
    {currentImage && (
      <img className={classNames(styles.capturedImg)} src={currentImage} alt="img" />
    )}
  </>
);

type UploadProps = {
  direction: string;
  onClick: () => void;
  currentImage: string | null;
  isProcessing: boolean;
  validation: CheckDepositValidation;
};

export const UploadImageBody: React.FC<UploadProps> = ({
  direction,
  onClick,
  currentImage,
  isProcessing,
  validation,
}) => (
  <>
    {!currentImage && (
      <>
        <img src={direction === "front" ? checkFront : checkBack} alt="check"></img>
        <div className={styles.tabBodyContainerDescription}>
          <Text align="center" size={14} weight="medium">
            Upload an image of the {direction} of the check
          </Text>
          <Text align="center" size={12} weight="regular" color={colors.grey[500]}>
            File must be in .jpg or .jpeg format.
          </Text>
        </div>
        {direction === "front" && ensureSignatureInfo}
        <Button variant="primary" onClick={onClick} className={styles.tabBodyContainerButton}>
          Upload image
        </Button>
      </>
    )}
    {currentImage && (
      <img
        className={classNames(
          styles.capturedImg,
          (!validation.isValidated || isProcessing) && styles.imageGreyed
        )}
        src={currentImage}
        alt="img"
      />
    )}
    {currentImage && !validation.isValidated && validation.errorMessage && (
      <Text size={14} color={colors.white} className={styles.processingError}>
        {validation.errorMessage}
      </Text>
    )}
    {isProcessing && <AnimatedSpinner size={20} className={styles.spinner}></AnimatedSpinner>}
  </>
);
