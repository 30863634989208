import classNames from "classnames";
import { FC, memo } from "react";
import { useSetRecoilState } from "recoil";
import BusinessRep from "reps/BusinessRep";
import businessGuidState from "state/auth/businessGuid";
import isSuperuseringState from "state/auth/isSuperusering";

import styles from "./BusinessSwitcherResult.module.scss";
import BusinessSwitcherResultCell from "./BusinessSwitcherResultCell";

type Props = {
  business: BusinessRep.Complete;
};

const BusinessSwitcherResult: FC<Props> = ({ business }) => {
  const setIsSuperusering = useSetRecoilState(isSuperuseringState);
  const setBusinessGuid = useSetRecoilState(businessGuidState);
  const handleSelect = () => {
    setIsSuperusering(true);
    setBusinessGuid(business.guid);
    window.location.reload();
  };

  return (
    <tr
      className={classNames(styles.row)}
      tabIndex={1}
      onClick={handleSelect}
      onMouseOver={(e) => e.currentTarget.focus()}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSelect();
        }
      }}
    >
      <BusinessSwitcherResultCell allowWrap>{business.internalName}</BusinessSwitcherResultCell>
      <BusinessSwitcherResultCell>{business.guid}</BusinessSwitcherResultCell>
      <BusinessSwitcherResultCell>{business.unitCoCustomerId}</BusinessSwitcherResultCell>
      <BusinessSwitcherResultCell>{business.status}</BusinessSwitcherResultCell>
    </tr>
  );
};

export default memo(BusinessSwitcherResult);
