import { FC, ReactElement, ReactNode } from "react";
import Card from "ui/data-display/Card";
import { Heading4, Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  icon: ReactElement<{ size?: number }>;
  title: ReactNode;
  description: ReactNode;
  className?: string;
};

const SectionLinkCard: FC<Props> = ({ icon, title, description, className }) => {
  return (
    <Card
      className={cn(
        "flex h-full min-h-32 w-full flex-col items-start border border-grey-100 p-8",
        className
      )}
      shadow="xs"
    >
      <div className="mb-3 flex size-11 items-center justify-center rounded-md bg-grey-50 [&>svg]:size-6">
        {icon}
      </div>
      <Heading4 className="mb-1 text-grey-800">{title}</Heading4>
      <Paragraph className="text-sm text-grey-600">{description}</Paragraph>
    </Card>
  );
};

export default SectionLinkCard;
