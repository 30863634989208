import dayjs from "dayjs";

/**
 * Year
 */

type Range = [number | null, number | null];

// NB(alex): Somewhat arbitrary, we may want to adjust this.
export const DEFAULT_VALID_YEAR_RANGE: Range = [1900, 2100];

export const isValidYearInput = (year: string, [min, max]: Range = DEFAULT_VALID_YEAR_RANGE) => {
  const yearAsInt = parseInt(year);
  return (!min || yearAsInt >= min) && (!max || yearAsInt <= max);
};

/**
 * Month
 */

export const isValidMonthInput = (month: string) => {
  const monthAsInt = parseInt(month);
  return monthAsInt >= 1 && monthAsInt <= 12;
};

const getDaysInMonth = (month: string, year?: string) => {
  const date = year ? dayjs(`${year}-${month}`, "YYYY-MM") : dayjs(month, "MM");

  // Hard-coded case where year is not defined and is february, we assume it's a leap year.
  if (!year && month === "2") return 29;

  return date.daysInMonth();
};

/**
 * Day
 */

type IsValidDayInputOptions = { month: string } | { month: string; year: string };

// Util to get type-safe options for `isValidDayInput` depending on if month and/or year are provided. Useful for input validation where year/month is dynamic.
export const getIsValidDayInputOptions = (
  month?: string,
  year?: string
): IsValidDayInputOptions | undefined => {
  return month && year ? { month, year } : month ? { month } : undefined;
};

export const isValidDayInput = (day: string, options?: IsValidDayInputOptions): boolean => {
  const dayAsInt = parseInt(day);

  if (options) {
    const daysInMonth =
      "year" in options && isValidYearInput(options.year)
        ? getDaysInMonth(options.month, options.year)
        : getDaysInMonth(options.month);
    return dayAsInt >= 1 && dayAsInt <= daysInMonth;
  } else {
    return dayAsInt >= 1 && dayAsInt <= 31;
  }
};
