import { useState } from "react";
import useUnitCoRecurringPayment from "resources/unit-co-recurring-payments/queries/useUnitCoRecurringPayment";
import isRecurring from "resources/unit-co-recurring-payments/utils/isRecurring";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import FlexpaneHeader from "ui/overlay/Flexpane/FlexpaneHeader";
import useIsAllowedToCancelRecurringPayments from "utils/permissions/useIsAllowedToCancelRecurringPayments";
import useIsAllowedToCancelScheduledPayments from "utils/permissions/useIsAllowedToCancelScheduledPayments";

import { useRecurringPaymentFlexpaneContext } from "../context/RecurringPaymentFlexpaneProvider";

import CancelRecurringPaymentModal from "./CancelRecurringPaymentModal";

const RecurringPaymentFlexpaneHeader = () => {
  const { recurringPaymentId, close } = useRecurringPaymentFlexpaneContext();
  const recurringPayment = useUnitCoRecurringPayment(recurringPaymentId, { required: true });

  const [isCancelRecurringPaymentModalOpen, setIsCancelRecurringPaymentModalOpen] = useState(false);
  const flexpaneTitle = `${isRecurring(recurringPayment) ? "Recurring" : "Scheduled"} payment`;

  const isAllowedToCancelRecurringPayments = useIsAllowedToCancelRecurringPayments();
  const isAllowedToCancelScheduledPayments = useIsAllowedToCancelScheduledPayments();
  const isAllowedToCancelPayments =
    isAllowedToCancelRecurringPayments && isAllowedToCancelScheduledPayments;

  return (
    <>
      {isAllowedToCancelPayments && isCancelRecurringPaymentModalOpen && (
        <CancelRecurringPaymentModal
          onClose={() => setIsCancelRecurringPaymentModalOpen(false)}
          onSuccess={() => {
            close();
            setIsCancelRecurringPaymentModalOpen(false);
          }}
        />
      )}
      <FlexpaneHeader
        right={
          <ButtonWithTooltip
            variant="tertiary"
            onClick={() => setIsCancelRecurringPaymentModalOpen(true)}
            disabled={!isAllowedToCancelPayments}
            tooltip={!isAllowedToCancelPayments && "You don’t have permission to cancel payments."}
          >
            {isRecurring(recurringPayment) ? "Cancel future payments" : "Cancel payment"}
          </ButtonWithTooltip>
        }
        onClose={close}
      >
        {flexpaneTitle}
      </FlexpaneHeader>
    </>
  );
};

export default RecurringPaymentFlexpaneHeader;
