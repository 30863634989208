import { Authorization, Transaction } from "@highbeam/unit-node-sdk";

import counterpartyVendorLogos from "../constants/counterpartyVendorLogos";
import counterpartyVendorMatchers from "../constants/counterpartyVendorMatchers";
import {
  CounterpartyVendorKey,
  counterpartyVendorNames,
} from "../constants/counterpartyVendorNames";

type CounterpartyVendor = {
  key: CounterpartyVendorKey;
  name: string;
  logo: string;
};

const getCounterpartyVendor = (
  transaction: Transaction | Authorization
): CounterpartyVendor | undefined => {
  const counterpartyVendor = Object.entries(counterpartyVendorMatchers).find(
    ([_vendor, matcher]) => {
      return matcher(transaction);
    }
  );

  if (counterpartyVendor) {
    const counterpartyVendorKey = counterpartyVendor[0] as CounterpartyVendorKey;
    return {
      key: counterpartyVendorKey,
      name: counterpartyVendorNames[counterpartyVendorKey],
      logo: counterpartyVendorLogos[counterpartyVendorKey],
    };
  }

  return undefined;
};

export default getCounterpartyVendor;
