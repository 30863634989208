import { useQueryClient } from "@tanstack/react-query";
import makeQueryHooks from "utils/react-query/makeQueryHooks";

import bankAccountsQueryHooks, {
  ROOT_BANK_ACCOUNTS_QUERY_KEY,
  Status,
} from "./bankAccountsQueryHooks";

type Params = {
  status: Status;
  bankAccountGuid: string;
};

const bankAccountQueryHooks = makeQueryHooks({
  rootName: ROOT_BANK_ACCOUNTS_QUERY_KEY,
  name: "bankAccount",
  useQueryVariables: (params: Params) => params,
  useQueryFnMaker: ({ status, bankAccountGuid }) => {
    const queryClient = useQueryClient();
    const bankAccountsQueryOptions = bankAccountsQueryHooks.useQueryOptions({
      status: status,
    });

    return async () => {
      const bankAccounts = await queryClient.ensureQueryData(bankAccountsQueryOptions);
      return bankAccounts.find((bankAccount) => bankAccount.guid === bankAccountGuid);
    };
  },
});

export default bankAccountQueryHooks;

// Hooks

export const useOpenBankAccount = (bankAccountGuid: string) => {
  return bankAccountQueryHooks.useData({
    status: "open",
    bankAccountGuid,
  });
};

export const useOpenBankAccountRequired = (bankAccountGuid: string) => {
  return bankAccountQueryHooks.useDataRequired({
    status: "open",
    bankAccountGuid,
  });
};
