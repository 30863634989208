/**
 * First 2 letters if 2 words, otherwise just get the first letter of the first word.
 */
const getInitials = (name: string): string => {
  const parts = name.split(" ");
  if (parts.length === 2) {
    return parts.map((str) => str[0]).join("");
  } else {
    return parts[0][0] ?? "";
  }
};

export default getInitials;
