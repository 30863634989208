import { FC, PropsWithChildren, ReactNode, createContext, useContext } from "react";
import { FormProvider, useFormContext } from "react-hook-form";

import useUpdateCounterpartyAliasForm, {
  UpdateCounterpartyAliasFormInputs,
} from "../forms/useUpdateCounterpartyAliasForm";

// NB(alex): This file is messy, please don't copy the pattern here. Still figuring out how to structure scoped context.

export type UpdateCounterpartyAliasModalParams = {
  counterparty: string;
  direction: "money-in" | "money-out";
  originalCounterparties: string[];
};

type UpdateCounterpartyAliasModalContextValue = UpdateCounterpartyAliasModalParams & {
  onClose: () => void;
};

const UpdateCounterpartyAliasModalContext = createContext<UpdateCounterpartyAliasModalContextValue>(
  {} as UpdateCounterpartyAliasModalContextValue
);

export const UpdateCounterpartyAliasFormProvider = ({ children }: PropsWithChildren) => {
  const form = useUpdateCounterpartyAliasForm();

  return <FormProvider {...form}>{children}</FormProvider>;
};

type UpdateCounterpartyAliasModalProviderProps = {
  params: UpdateCounterpartyAliasModalParams;
  onClose: () => void;
  children: ReactNode;
};

export const UpdateCounterpartyAliasModalProvider: FC<
  UpdateCounterpartyAliasModalProviderProps
> = ({ children, params, onClose }) => {
  return (
    <UpdateCounterpartyAliasModalContext.Provider value={{ ...params, onClose }}>
      <UpdateCounterpartyAliasFormProvider>{children}</UpdateCounterpartyAliasFormProvider>
    </UpdateCounterpartyAliasModalContext.Provider>
  );
};

export const useUpdateCounterpartyAliasModalContext = () => {
  const context = useContext(UpdateCounterpartyAliasModalContext);
  const form = useFormContext<UpdateCounterpartyAliasFormInputs>();

  return {
    ...context,
    form,
  };
};
