import filterOfferInputsWithoutProviderName from "pages/capital/CreditComparisonPage/utils/filterOfferInputsWithoutProviderName";
import { selector } from "recoil";

import offerInputsState from "./inputs/offerInputs/offerInputs";

const isSelectCreditOfferProvidersFormValidState = selector<boolean>({
  key: "isSelectCreditOfferProvidersFormValidState",
  get: ({ get }) => {
    const offerInputs = get(offerInputsState);
    const offerInputsWithProviderName = filterOfferInputsWithoutProviderName(offerInputs);
    return offerInputsWithProviderName.length > 0;
  },
});

export default isSelectCreditOfferProvidersFormValidState;
