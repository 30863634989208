import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";

const schema = z.object({
  invoice: z.union([z.instanceof(File), z.null()]).refine((value) => value !== null, {
    message: "Please upload an invoice.",
  }),
  reasonForDrawdown: z.string().nonempty("Reason for drawdown is required."),
  idempotencyKey: z.string().uuid(),
});

export type DrawdownInvoiceFormInputs = z.infer<typeof schema>;

const useDrawdownInvoiceForm = () => {
  return useForm<DrawdownInvoiceFormInputs>({
    resolver: zodResolver(schema),
    mode: "onChange",
    // Adding defaultValues with a unique idempotency key to ensure each form submission is unique. Context: https://highbeamco.slack.com/archives/C02M3GGQPHC/p1690914198052609?thread_ts=1690912209.739389&cid=C02M3GGQPHC
    defaultValues: {
      idempotencyKey: uuidv4(),
    },
  });
};

export default useDrawdownInvoiceForm;
