// NB(lev): The initial version of the Bill pay settings page is limited to accounting settings.
//Eventually, we will present this as just "Bill pay settings".

import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { FC } from "react";
import AccountingSyncSettings from "resources/ap-settings/components/AccountingSyncSettings";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

const BillsSettingsPage: FC = () => (
  <>
    <DashboardHeader>
      <Breadcrumbs>
        <Breadcrumbs.Item to="/bills">Bill Pay</Breadcrumbs.Item>
        <Breadcrumbs.CurrentItem>Accounting settings</Breadcrumbs.CurrentItem>
      </Breadcrumbs>
    </DashboardHeader>

    <DashboardPage>
      <DashboardPage.Header>
        <DashboardPage.Header.BackCaret to="/bills" />
        <DashboardPage.Header.Title>Accounting settings</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section className="grid grid-cols-1 gap-14 @4xl:grid-cols-2">
        <AccountingSyncSettings />
      </DashboardPage.Section>
    </DashboardPage>
  </>
);

export default BillsSettingsPage;
