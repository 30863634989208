import BillPaymentRep from "reps/BillPaymentRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillPaymentApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async search(billId: string): Promise<BillPaymentRep.Complete[]> {
    const qp = new URLSearchParams({ billId: billId });

    const url = `/accounts-payable/bill-payments?${qp}`;
    return (await this.api.get<BillPaymentRep.Complete[]>(url))!;
  }
}
