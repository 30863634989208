import { toKebabCase } from "utils/string";

export const MAX_LENGTH = 15; // arbitrary value

/**
 * Converts a string to a react key. Use the `index` param to ensure uniquenss if there is a chance of duplicate strings.
 */
const stringToReactKey = (str: string | number, index?: number): string => {
  const indexPrefix = index ? `${index}-` : "";
  const kebabStr = toKebabCase(`${str}`.toLowerCase());
  return `${indexPrefix}${kebabStr}`.slice(0, MAX_LENGTH);
};

export default stringToReactKey;
