import { selectorFamily } from "recoil";
import highbeamApiState from "state/auth/highbeamApi";

const calculationsState = selectorFamily({
  key: "compareCreditOffers/calculationsState",
  get:
    (snapshotSlug: string) =>
    async ({ get }) => {
      const highbeamApi = get(highbeamApiState("unauthenticated"));
      return await highbeamApi.creditComparison.calculateCreditComparison(snapshotSlug);
    },
});

export default calculationsState;
