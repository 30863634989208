import getPaymentMethodName from "resources/unit-co-payments/utils/getPaymentMethodName";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import { usePaymentInfoFlexpaneContext } from "../../../context/PaymentInfoFlexpaneProvider";
import PaymentStatusPill from "../../PaymentStatusPill";

import styles from "./PaymentDetailBubble.module.scss";

const PaymentDetailBubble = () => {
  const { payment } = usePaymentInfoFlexpaneContext();

  return (
    <div className={styles.container}>
      <PaymentStatusPill payment={payment} />
      <Text size={14} weight="medium" color={colors.grey[800]}>
        {getPaymentMethodName(payment).longMethodName}
      </Text>
    </div>
  );
};

export default PaymentDetailBubble;
