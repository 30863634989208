/**
 * Await for a given amount of time
 * NB(alex): Added this for development purposes. Not sure if we'll end up using this in production. Feel free to delete this comment if it comes up.
 */
const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export default sleep;
