import HighbeamApi from "api/HighbeamApi";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import ActionItemRep from "reps/ActionItemRep";
import isGettingStartedSetupGuideOpenState from "resources/action-items/get-started/state/gettingStartedSetupGuide";
import { Milestone, ActionItemSteps, ActionItem } from "resources/action-items/types";
import actionItemsState, { OWNER_GUID_BY_MILESTONE } from "state/actionItems/actionItems";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

const allActionItemsComplete = (actionItems: ActionItem[]) => {
  return actionItems.every(
    (item) => item.finishedActionItemRep && item.finishedActionItemRep.state === "Complete"
  );
};

const finishActionItem = async (
  highbeamApi: HighbeamApi,
  ownerGuid: string,
  actionItem: ActionItem,
  state: ActionItemRep.State
): Promise<ActionItemRep.Complete> => {
  const finishedActionItem: ActionItemRep.Creation = {
    name: actionItem.name,
    ownerGuid: ownerGuid,
    state: state,
    milestoneName: actionItem.milestone,
  };
  return await highbeamApi.actionItem.set(finishedActionItem);
};

export const useMilestoneActionItems = (milestone: Milestone) => {
  const highbeamApi = useHighbeamApi();
  const ownerGuid = useRecoilValue(OWNER_GUID_BY_MILESTONE[milestone]);
  const [actionItems, setActionItems] = useRecoilState(actionItemsState(milestone));
  const setShowGetStarted = useSetRecoilState(isGettingStartedSetupGuideOpenState);

  function notifyUser(actionItems: ActionItem[]) {
    if (allActionItemsComplete(actionItems) && milestone === Milestone.GetStarted) {
      notify("success", "Congrats on setting up your business on Highbeam!");
    }
  }

  async function finishItemByStep(
    step: ActionItemSteps,
    state: ActionItemRep.State
  ): Promise<void> {
    const actionItemToFinish = actionItems.find((item) => item.name === step);

    // Essentially a create or update, since records may have been created for steps
    // that are skipped already that may then need to be completed.
    if (actionItemToFinish?.finishedActionItemRep) {
      if (actionItemToFinish?.finishedActionItemRep?.state === "Complete") {
        return;
      }

      const completedActionItem = (await highbeamApi.actionItem.update(
        ownerGuid,
        actionItemToFinish.finishedActionItemRep.guid,
        {
          state: "Complete",
          // You should only be able to complete a previously skipped state. Cannot skip a completed state.
        }
      ))!;

      const updatedActionItems = [
        ...actionItems.filter((it) => it.name !== actionItemToFinish.name),
        { ...actionItemToFinish, finishedActionItemRep: completedActionItem },
      ];

      setActionItems(updatedActionItems);
      notifyUser(updatedActionItems);
    } else {
      const finishedActionItem = await finishActionItem(
        highbeamApi,
        ownerGuid,
        actionItemToFinish!,
        state
      );

      const updatedActionItems = [
        ...actionItems.filter((it) => it.name !== actionItemToFinish!.name),
        { ...actionItemToFinish!, finishedActionItemRep: finishedActionItem },
      ];

      setActionItems(updatedActionItems);
      notifyUser(updatedActionItems);
    }

    setShowGetStarted(true);
  }

  return {
    finishItemByStep,
  };
};
