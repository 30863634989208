import HighbeamApi from "api/HighbeamApi";
import { selectorFamily } from "recoil";

import auth0ClientState from "./auth0Client";
import isAuthenticatedState from "./isAuthenticated";

/**
 * "authenticated" returns a HighbeamApi where auth is required and will throw an error if auth is not available.
 * "unauthenticated" returns a HighbeamApi without auth.
 * "maybeAuthenticated" checks to see if the user is authenticated and returns the appropriate HighbeamApi.
 */

export type AuthMode = "authenticated" | "unauthenticated" | "maybeAuthenticated";

type GetJwt = () => Promise<string | undefined>;

export const getJwtState = selectorFamily<GetJwt, AuthMode>({
  key: "auth/getJwt",
  get:
    (authMode) =>
    ({ get }) => {
      const auth0Client = get(auth0ClientState);

      const authenticatedGetJwt = () => auth0Client.getTokenSilently();
      const unauthenticatedGetJwt = () => Promise.resolve(undefined);

      switch (authMode) {
        case "authenticated":
          return authenticatedGetJwt;
        case "unauthenticated":
          return unauthenticatedGetJwt;
        case "maybeAuthenticated":
          const isAuthenticated = get(isAuthenticatedState);
          return isAuthenticated ? authenticatedGetJwt : unauthenticatedGetJwt;
      }
    },
});

const highbeamApiState = selectorFamily<HighbeamApi, AuthMode>({
  key: "auth/highbeamApi",
  get:
    (authMode) =>
    ({ get }) => {
      const getJwt = get(getJwtState(authMode));
      return new HighbeamApi(getJwt);
    },
});

export default highbeamApiState;
