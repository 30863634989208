namespace DarbEncoder {
  const CHUNK_SIZE: number = 4;
  const HEX: RegExp = new RegExp("^[A-Fa-f0-9]*$");

  export const decode = (darb: string): boolean[] => {
    const [size, hex] = getComponents(darb);

    // Map each hex to a list of 4 digits representing the hex in binary.
    const booleanList = hex.split("").flatMap(decodeCharacter);

    // Take the size into account to return a list of the correct length.
    // This will omit between 0 and 3 booleans from the end.
    return booleanList.slice(0, size);
  };

  const getComponents = (darb: string): [number, string] => {
    // DARB always has 2 components separated by a dot, and no dots elsewhere in the syntax.
    const components = darb.split(".");
    if (components.length !== 2) throw new Error("DARB must have 2 components.");

    // The first component is the size (positive).
    const size = Number(components[0]);
    if (size < 0) throw new Error("DARB size cannot be negative.");

    // The second component is the hex, the length of which must correlate with the size.
    const hex = components[1];
    if (hex.length !== Math.floor((size + CHUNK_SIZE - 1) / CHUNK_SIZE)) {
      throw new Error("DARB hex length doesn't match size component.");
    }
    if (!HEX.test(hex)) {
      throw new Error("Invalid DARB hex.");
    }
    return [size, hex];
  };

  const decodeCharacter = (character: string): boolean[] => {
    // intValue is the value of the hex character from 0 to 15 (inclusive).
    const intValue = (() => {
      const charCode = character.charCodeAt(0);
      // 0 through 9 are represented by digits.
      if (charCode >= "0".charCodeAt(0) && charCode <= "9".charCodeAt(0)) {
        return charCode - "0".charCodeAt(0);
      }
      // 10 through 15 are represented by capital letters A through F.
      if (charCode >= "A".charCodeAt(0) && charCode <= "F".charCodeAt(0)) {
        return charCode - "A".charCodeAt(0) + 10;
      }
      if (charCode >= "a".charCodeAt(0) && charCode <= "f".charCodeAt(0)) {
        return charCode - "a".charCodeAt(0) + 10;
      }
      throw new Error("Unsupported character: $character.");
    })();
    return [
      intValue >= 8, // First bit.
      intValue % 8 >= 4, // Second bit.
      intValue % 4 >= 2, // Third bit.
      intValue % 2 >= 1, // Fourth bit.
    ];
  };
}

export default DarbEncoder;
