import { selector } from "recoil";

import { internationalAddressState } from "./address";
import { internationalBankingInfoState } from "./bankingInfo";

const isValidInternationaldressState = selector<boolean>({
  key: "payments/international/isValidAddress",
  get: ({ get }) => {
    const address = get(internationalAddressState);
    return Boolean(address.country && address.addressLine1 && address.city);
  },
});

const isValidInternationalBankingInfoState = selector<boolean>({
  key: "payments/international/isValidBankingInfo",
  get: ({ get }) => {
    const bankingInfo = get(internationalBankingInfoState);
    return Object.values(bankingInfo).every((field) => field.isValid);
  },
});

export const isValidInternationalPaymentState = selector<boolean>({
  key: "payments/international/isValidPayment",
  get: ({ get }) => {
    const validAddress = get(isValidInternationaldressState);
    const validBankingInfo = get(isValidInternationalBankingInfoState);
    return validAddress && validBankingInfo;
  },
});
