import PaymentRep from "reps/payments-v2/PaymentRep";
import { ApprovablePaymentRequest } from "resources/payment-requests/types/paymentRequest";

const convertToPaymentRequest = (payment: PaymentRep.Complete): ApprovablePaymentRequest | null => {
  if ("InternationalWire" in payment.detail) {
    const { detail, ...rest } = payment;
    return {
      type: "international-wire",
      ...detail.InternationalWire,
      ...rest,
      paymentReason: detail.InternationalWire.reason,
    };
  } else if ("UnitPayment" in payment.detail) {
    const { detail, ...rest } = payment;
    return {
      type: "unit-payment",
      ...detail.UnitPayment,
      ...rest,
    };
  }

  return null;
};

export default convertToPaymentRequest;
