import { FC } from "react";
import ChargeCardOfferRep from "reps/ChargeCardOfferRep";

type StatementCycleExplanationProps = {
  creditTerms: ChargeCardOfferRep.ChargeCardCreditTerm;
};

const StatementCycleExplanation: FC<StatementCycleExplanationProps> = ({ creditTerms }) => {
  switch (creditTerms) {
    case ChargeCardOfferRep.ChargeCardCreditTerm.Production:
    case ChargeCardOfferRep.ChargeCardCreditTerm.Sandbox:
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionFlex:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxFlex:
      return (
        <>
          Monthly statements, outstanding balance is due{" "}
          <strong>the first day of the following month</strong>.
        </>
      );
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionExtend:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxExtend:
      return (
        <>
          Monthly statements, outstanding balance is due{" "}
          <strong>30 days after the last statement cycle</strong>.
        </>
      );
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionCash:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxCash:
      return (
        <>
          Daily statements, outstanding balance is due <strong>on the following day</strong>.
        </>
      );
  }
};

export default StatementCycleExplanation;
