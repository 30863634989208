import { ArrowRight } from "@phosphor-icons/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CapitalAccountBar from "resources/capital-accounts/components/CapitalAccountBar";
import useNonTerminatedCapitalAccounts from "resources/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import capitalAccountSummaryQueryHooks from "resources/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import MoneyAmountFraction from "ui/data-display/money/MoneyAmountFraction";
import Table, { TableColumnAlignment } from "ui/table/Table";

import styles from "./CapitalAccountsTable.module.scss";

const CapitalAccountUsageCell: FC<{ capitalAccountGuid: string }> = ({ capitalAccountGuid }) => {
  const capitalAccount = capitalAccountSummaryQueryHooks.useDataRequired({ capitalAccountGuid });

  return (
    <MoneyAmountFraction
      numeratorInCents={capitalAccount.available}
      denominatorInCents={capitalAccount.details.limit}
    />
  );
};

const CapitalAccountsTable = () => {
  const navigate = useNavigate();
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();

  // TODO: Handle empty state https://highbeamco.slack.com/archives/C03R625F068/p1715788676966769

  return (
    <Table
      data={nonTerminatedCapitalAccounts}
      columns={[
        {
          title: "Account",
          cellRender: (capitalAccount) => <CapitalAccountBar capitalAccount={capitalAccount} />,
        },
        {
          title: "Available",
          align: TableColumnAlignment.RIGHT,
          cellRender: (capitalAccount) => (
            <CapitalAccountUsageCell capitalAccountGuid={capitalAccount.guid} />
          ),
        },
        {
          title: "",
          align: TableColumnAlignment.RIGHT,
          width: 160,
          cellRender: () => (
            <div className={styles.details}>
              <ArrowRight size={16} />
            </div>
          ),
        },
      ]}
      rowKey={(capitalAccount) => capitalAccount.guid}
      onRowClick={(capitalAccount) => navigate(`/capital/${capitalAccount.guid}`)}
    />
  );
};

export default CapitalAccountsTable;
