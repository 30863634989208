import { DownloadSimple, Eyeglasses, Note } from "@phosphor-icons/react";
import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "layouts/DashboardPage";
import { FC, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { LineOfCreditAgreementType } from "reps/LineOfCreditAgreementMetadataRep";
import CapitalAccountBreadcrumbItem from "resources/capital-accounts/components/CapitalAccountBreadcrumbItem";
import capitalAccountSummaryQueryHooks from "resources/capital-accounts/queries/capitalAccountSummaryQueryHooks";
import {
  CHARGE_CARD_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL,
  LINE_OF_CREDIT_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL,
} from "resources/capital/constants";
import useLineOfCreditAgreement from "resources/line-of-credit-agreement/queries/useLineOfCreditAgreement";
import useAcceptCreditOfferMutation from "resources/line-of-credit/mutations/useAcceptCreditOfferMutation";
import useDownloadLineOfCreditAgreementMutation from "resources/line-of-credit/mutations/useDownloadLineOfCreditAgreementMutation";
import useUpdateLineOfCreditUserActionsMetadataMutation from "resources/line-of-credit/mutations/useUpdateLineOfCreditUserActionsMetadataMutation";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import IconTile from "ui/icons/IconTile";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import CheckboxLabel from "ui/inputs/CheckboxLabel";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import { Heading1, Heading4, Paragraph, Span } from "ui/typography";
import useIsAllowedToDownloadCreditOfferAgreement from "utils/permissions/useIsAllowedToDownloadCreditOfferAgreement";

import { permissionsTooltipCopy } from "../CapitalAccountPage/constants";

import useReviewUpdatedLineAgreementForm from "./forms/useReviewUpdatedLineAgreementForm";

const REVIEW_UPDATED_AGREEMENT_FORM_ID = "review-updated-agreement-form";

type ReviewUpdatedLineAgreementPageContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const ReviewUpdatedLineAgreementPageContent: FC<ReviewUpdatedLineAgreementPageContentProps> = ({
  capitalAccountSummary,
}) => {
  const lineOfCreditAgreement = useLineOfCreditAgreement(capitalAccountSummary.guid);
  const isSecuredOrVariableAprAgreementType =
    lineOfCreditAgreement?.terms.agreementType === LineOfCreditAgreementType.SecuredV1 ||
    lineOfCreditAgreement?.terms.agreementType ===
      LineOfCreditAgreementType.VariableAprRestrictedV1;

  const isAllowedToDownloadCreditOfferAgreement = useIsAllowedToDownloadCreditOfferAgreement();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutateAsync: downloadLineOfCreditAgreementMutation, isPending } =
    useDownloadLineOfCreditAgreementMutation({ onSuccess: () => setAgreementDownloaded(true) });
  const { mutateAsync: updateLineOfCreditUserActionsMetadataMutation } =
    useUpdateLineOfCreditUserActionsMetadataMutation();
  const { mutateAsync: acceptCreditOffer, isPending: isAcceptCreditOfferLoading } =
    useAcceptCreditOfferMutation({ capitalAccountGuid: capitalAccountSummary.guid });
  const isSuperusering = useIsSuperusering();
  const form = useReviewUpdatedLineAgreementForm();
  const readAgreementConfirmedInputValue = form.watch("readAgreementConfirmed");
  const [agreementDownloaded, setAgreementDownloaded] = useState(false);

  const onDownloadAgreement = async () => {
    await downloadLineOfCreditAgreementMutation({
      lineOfCreditGuid: capitalAccountSummary.guid,
      unsignedAgreementGuid: lineOfCreditAgreement?.unsignedAgreementGuid,
      signedAgreementGuid: lineOfCreditAgreement?.signedAgreementGuid,
    });

    if (!lineOfCreditAgreement?.unsignedAgreementOpenedAt && !isSuperusering) {
      await updateLineOfCreditUserActionsMetadataMutation({
        capitalAccountGuid: capitalAccountSummary.guid,
      });
    }
  };

  if (!lineOfCreditAgreement) {
    return <Navigate to="/capital" />;
  }
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await acceptCreditOffer();
    // Determine the return path. If the user came from the LineOfCreditDrawdownButtonArrayButton component,
    // navigate them back to the draw-down page. Otherwise, navigate them to capital account page.
    const returnPath = location.state?.from || `/capital/${capitalAccountSummary.guid}`;
    navigate(returnPath);
  };

  return (
    <FormProvider {...form}>
      <form id={REVIEW_UPDATED_AGREEMENT_FORM_ID} onSubmit={handleSubmit}>
        <DashboardPage>
          <DashboardPage.Section className="flex h-full w-full items-center justify-center">
            <DashboardPage.Section.Body className="flex flex-col items-center justify-center gap-6 px-8 tablet:px-40">
              <IconTile icon={<Note />} />
              <DashboardPage.Section.Header className="mb-0 flex flex-col gap-2 text-center">
                <Heading1 className="text-xl font-medium">
                  We’ve updated our line of credit agreement
                </Heading1>
                <Paragraph className="text-md text-grey-500">
                  Please take a moment to review and accept our updated agreement to continue using
                  your Highbeam line of credit.
                </Paragraph>
              </DashboardPage.Section.Header>
              {isSecuredOrVariableAprAgreementType && (
                <div className="my-2 rounded-lg border border-grey-200">
                  <div className="flex items-center gap-2 rounded-lg bg-grey-50 px-6 py-4">
                    <Eyeglasses className="size-6 text-grey-700" />
                    <Heading4 className="text-sm font-medium text-grey-600">
                      Plain language summary
                    </Heading4>
                  </div>
                  <Divider className="my-0" />
                  <div className="flex flex-col p-6">
                    <ul className="flex list-disc flex-col gap-4 pl-4 text-sm text-grey-500">
                      <li>
                        Your new line limit will be{" "}
                        <MoneyAmount cents={lineOfCreditAgreement.terms.limit} withCents={false} />{" "}
                        {lineOfCreditAgreement.terms.apr > 0 && (
                          <>with a {lineOfCreditAgreement.terms.apr * 100}% APR</>
                        )}
                        .
                      </li>
                      <li>
                        Highbeam must be your primary operating account. That is, sales, payroll,
                        inventory, marketing and cards must flow through your Highbeam bank accounts
                      </li>
                      <li>
                        Please maintain at least 6 months of cash runway, and taking on additional
                        financing without Highbeam approval will affect your line of credit
                      </li>
                      <li>
                        Highbeam will ask for financials from time to time, at least once a quarter.
                        You’ll have two weeks upon request to provide your financials
                      </li>
                      <li>
                        All financial connections must remain active including: other bank accounts,
                        cards, as well as accounting software
                      </li>
                      {lineOfCreditAgreement.terms.agreementType ===
                        LineOfCreditAgreementType.SecuredV1 && (
                        <li>All assets are used as collateral against this line</li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              <div className="mt-2 flex items-center gap-4">
                <ButtonWithTooltip
                  variant="primary"
                  onClick={onDownloadAgreement}
                  isLoading={isPending}
                  disabled={!isAllowedToDownloadCreditOfferAgreement}
                  tooltip={
                    !isAllowedToDownloadCreditOfferAgreement &&
                    permissionsTooltipCopy.notAllowedToDownloadCreditAgreement
                  }
                >
                  <DownloadSimple size={24} />
                  Download full agreement
                </ButtonWithTooltip>
                <Span className="text-sm text-grey-500">Required</Span>
              </div>

              <Divider className="my-0" />

              <Controller
                name="readAgreementConfirmed"
                control={form.control}
                render={({ field }) => (
                  <CheckboxLabel
                    label={
                      capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly
                        ? CHARGE_CARD_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL
                        : LINE_OF_CREDIT_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL
                    }
                    checked={field.value}
                    textWeight="regular"
                    onChange={field.onChange}
                    disabled={!agreementDownloaded}
                  />
                )}
              />

              <ButtonWithTooltip
                variant="primary"
                type="submit"
                tooltip={
                  !readAgreementConfirmedInputValue &&
                  "Please download and read the agreement first."
                }
                form={REVIEW_UPDATED_AGREEMENT_FORM_ID}
                disabled={!readAgreementConfirmedInputValue}
                isLoading={isAcceptCreditOfferLoading}
              >
                I agree
              </ButtonWithTooltip>
            </DashboardPage.Section.Body>
          </DashboardPage.Section>
        </DashboardPage>
      </form>
    </FormProvider>
  );
};

const ReviewUpdatedLineAgreementPage = () => {
  const { capitalAccountGuid } = useParams();
  const { data: capitalAccountSummary } = capitalAccountSummaryQueryHooks.useQuery({
    capitalAccountGuid: capitalAccountGuid ?? null,
  });

  if (capitalAccountSummary === null) {
    return <Navigate to="/capital" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>

          <CapitalAccountBreadcrumbItem
            capitalAccountSummary={capitalAccountSummary}
            isCurrentItem={false}
          />

          <Breadcrumbs.CurrentItem>Review updated agreement</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      {capitalAccountSummary ? (
        <ReviewUpdatedLineAgreementPageContent capitalAccountSummary={capitalAccountSummary} />
      ) : (
        <DashboardPage.DotsLoader />
      )}
    </>
  );
};

export default ReviewUpdatedLineAgreementPage;
