import successGif from "assets/success.gif";
import { FC } from "react";
import ModalV4 from "ui/overlay/ModalV4";
import { Paragraph } from "ui/typography";

export type EditUserSuccessfulParams = {
  roleName: string;
  displayName: string;
};

type Props = EditUserSuccessfulParams & {
  onClose: () => void;
};

const EditUserSuccessfulModal: FC<Props> = ({ roleName, displayName, onClose }) => {
  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Form
        onSubmit={(_e, { closeModalWithAnimation }) => {
          closeModalWithAnimation();
        }}
      >
        <ModalV4.Header>Change user role</ModalV4.Header>

        <ModalV4.Body>
          <img src={successGif} alt="success" className="size-16" />
          <Paragraph className="text-md font-bold text-grey-600">
            {displayName}’s role has been changed to {roleName}
          </Paragraph>
          <Paragraph className="text-md font-regular text-grey-600">
            Please allow up to 10 minutes for this change to take effect.
          </Paragraph>
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.SubmitButton>Close</ModalV4.SubmitButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default EditUserSuccessfulModal;
