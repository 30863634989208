import { ReactElement, useContext } from "react";
import IconTile from "ui/icons/IconTile";
import Text from "ui/typography/Text";

import { SidebarMenuContext } from "./SidebarMenuContext";
import styles from "./SidebarMenuItem.module.scss";

type Props = {
  label?: string;
  icon?: ReactElement<{ size?: number }>;
  subText?: string;
  onClick?: () => void;
};

const SidebarMenuItem = ({ label, icon, subText, onClick, ...props }: Props) => {
  const { update: setIsOpen } = useContext(SidebarMenuContext);
  return (
    <button
      className={styles.dropdownItem}
      onClick={() => {
        setIsOpen(false);
        if (onClick) {
          onClick();
        }
      }}
      {...props}
    >
      {icon && <IconTile icon={icon} />}

      <div className={styles.rightSide}>
        <Text size={14} className={styles.label}>
          {label}
        </Text>
        {subText && (
          <Text size={12} className={styles.subText}>
            {subText}
          </Text>
        )}
      </div>
    </button>
  );
};

export default SidebarMenuItem;
