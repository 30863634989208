import Button from "ui/inputs/Button";
import cn from "utils/tailwind/cn";

type Props = {
  isPhysicalCard: boolean;
  isRevealed: boolean;
  isLoading: boolean;
  onHide: () => void;
  onReveal: () => void;
};

const HighbeamCardDetailsButton: React.FC<Props> = ({
  isPhysicalCard,
  isRevealed,
  isLoading,
  onHide,
  onReveal,
}) => {
  const onClick = () => (isRevealed ? onHide() : onReveal());

  return (
    <Button
      variant="plain"
      size="sm"
      className={cn(
        "rounded-full border text-sm font-medium",
        isPhysicalCard
          ? "bg-transparent text-white hover:bg-white hover:text-grey-800"
          : "border-grey-800 bg-white text-grey-800 hover:bg-grey-800 hover:text-white"
      )}
      onClick={onClick}
      disabled={isLoading}
    >
      {isRevealed ? "Hide details" : "Show details"}
    </Button>
  );
};

export default HighbeamCardDetailsButton;
