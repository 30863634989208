import { FC, ReactNode } from "react";

import styles from "./OfferDetails.module.scss";
import OfferDetailsItem from "./OfferDetailsItem";

type Props = {
  children: ReactNode;
};

const OfferDetails: FC<Props> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default Object.assign(OfferDetails, {
  Item: OfferDetailsItem,
});
