import { Dialog, DialogBackdrop, Transition, TransitionChild } from "@headlessui/react";
import classNames from "classnames";
import { FC, Fragment, ReactNode, RefObject, Suspense, useId, useImperativeHandle } from "react";
import { useTransition } from "utils/transitions";

import styles from "./Modal.module.scss";
import ModalBody, { ModalParagraph } from "./ModalBody";
import ModalFooter, { ModalCloseButton, ModalSubmitButton } from "./ModalFooter";
import ModalForm from "./ModalForm";
import ModalHeader from "./ModalHeader";
import ModalLoadingFallback from "./ModalLoadingFallback";
import { ModalContextProvider, ModalSize } from "./useModalContext";

export type ModalRef = {
  closeModalWithAnimation: () => void;
};

type Props = {
  onClose: () => void;
  size?: ModalSize;
  children: ReactNode;
  className?: string;
  suspenseFallback?: ReactNode;
  modalRef?: RefObject<ModalRef>;

  /**
   * HACK: Set this to `true` if you have a dropdown component inside the modal body, but gets cut off by the modal's overflow styles.
   * If you enable this, the overflow will no longer scroll, so any overflow will get cut off. We will have to use a different underlying modal component to get around this limitation (I think).
   */
  dropdownOverflowHack?: boolean;
};

const ModalV4: FC<Props> = ({
  children,
  onClose: onCloseProp,
  suspenseFallback = <ModalLoadingFallback />,
  className,
  dropdownOverflowHack,
  size = "standard",
  modalRef,
}) => {
  const {
    show,
    setShow,
    handleClose: closeModalWithAnimation,
  } = useTransition(true, {
    initiateClose: () => setShow(false),
    onClose: onCloseProp,
  });

  useImperativeHandle(modalRef, () => ({
    closeModalWithAnimation,
  }));

  const formId = useId();

  return (
    <ModalContextProvider
      closeModalWithAnimation={closeModalWithAnimation}
      size={size}
      formId={formId}
    >
      <Transition show={show} as={Fragment}>
        <Dialog onClose={closeModalWithAnimation}>
          <TransitionChild
            as={Fragment}
            enter={styles.enterBackdrop}
            enterFrom={styles.enterFromBackdrop}
            enterTo={styles.enterToBackdrop}
            leave={styles.leaveBackdrop}
            leaveFrom={styles.leaveFromBackdrop}
            leaveTo={styles.leaveToBackdrop}
          >
            <DialogBackdrop className={styles.backdrop} onClick={closeModalWithAnimation} />
          </TransitionChild>

          <TransitionChild
            as="div"
            className={styles.container}
            enter={styles.enterModal}
            enterFrom={styles.enterFromModal}
            leave={styles.leaveModal}
            leaveTo={styles.leaveToModal}
          >
            <Suspense fallback={suspenseFallback}>
              <div
                className={classNames(className, styles.content, {
                  [styles.small]: size === "small",
                  [styles.dropdownOverflowHack]: dropdownOverflowHack,
                })}
              >
                {children}
              </div>
            </Suspense>
          </TransitionChild>
        </Dialog>
      </Transition>
    </ModalContextProvider>
  );
};

export default Object.assign(ModalV4, {
  Form: ModalForm,
  Header: ModalHeader,
  Body: ModalBody,
  Paragraph: ModalParagraph,
  Footer: ModalFooter,
  CloseButton: ModalCloseButton,
  SubmitButton: ModalSubmitButton,
  LoadingFallback: ModalLoadingFallback,
});
