import { Clock } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import weekday from "dayjs/plugin/weekday";
import { FC } from "react";
import useLineOfCreditTransactionsQueryOptions from "resources/line-of-credit/queries/useLineOfCreditTransactionsQueryOptions";
import useLocTransactionsSearchParams from "resources/line-of-credit/state/useLocTransactionsSearchParams";
import HighlightItem from "ui/data-display/HighlightItem";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

// Enables methods such as `dayjs().fromNow()`
dayjs.extend(relativeTime);

dayjs.extend(weekday);

type Props = {
  lineUsedAmountInCents: number;
  isLastStatementWarning: boolean;
};

type MostRecentActivity = {
  action: "drawdown" | "repayment";
  date: Dayjs;
};

const LineOfCreditLineUsedHighlightItem: FC<Props> = ({
  lineUsedAmountInCents,
  isLastStatementWarning,
}) => {
  const { since, until } = useLocTransactionsSearchParams();
  const { data: lineOfCreditTransactions = [] } = useQuery(
    useLineOfCreditTransactionsQueryOptions({ since, until })
  );
  const drawdownAndRepaymentTransactions = lineOfCreditTransactions.filter(
    (transaction) => transaction.type === "LineOfCreditPayment"
  );
  const mostRecentActivity = drawdownAndRepaymentTransactions[0]
    ? ({
        action: drawdownAndRepaymentTransactions[0].amount < 0 ? "drawdown" : "repayment",
        date: dayjs(drawdownAndRepaymentTransactions[0].date),
      } satisfies MostRecentActivity)
    : null;

  return (
    <HighlightItem
      label={isLastStatementWarning ? "Overdue balance" : "Line used"}
      value={
        <MoneyAmount
          size={20}
          weight="medium"
          centsTextSize={16}
          centsTextWeight="regular"
          cents={lineUsedAmountInCents}
        />
      }
      subtext={
        isLastStatementWarning ? null : mostRecentActivity && !isLastStatementWarning ? (
          <>
            <Clock />
            Last {mostRecentActivity.action} {mostRecentActivity.date.fromNow()}
          </>
        ) : (
          "No recent activity"
        )
      }
    />
  );
};

export default LineOfCreditLineUsedHighlightItem;
