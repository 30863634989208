import BillSummaryRep from "reps/BillSummaryRep";

// NB(lev): This logic currently is purely based on the bill's state and payment status,
// without taking the user's permissions into account. We may want to add a permission check
// in the future, which is why this is encapsulated in a hook rather than a standalone utility
// function.
const useCanAddApproversToBill = (bill: BillSummaryRep.Complete) => {
  const { state, paymentStatus } = bill;
  const hasNoPayments = paymentStatus === "Unpaid" || paymentStatus === "Overdue";
  return state === "Draft" || (state === "Open" && hasNoPayments);
};

export default useCanAddApproversToBill;
