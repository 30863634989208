import { ArrowSquareOut, SignOut } from "@phosphor-icons/react";
import { FC, memo } from "react";
import { components as defaultComponents, OptionProps } from "react-select";
import { getCardTypeLabelByUnitCoCard } from "resources/cards/utils/get-card-type-label";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Pill from "ui/data-display/Pill";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";

import { CommandPaletteOption } from "../command-palette-options/types";
import CommandPaletteDropdownOptionCategoryIcon from "../CommandPaletteDropdownOptionCategoryIcon";

import styles from "./CommandPaletteDropdownOption.module.scss";

type Props = OptionProps<CommandPaletteOption, false> & {
  onCopy: () => void;
  onCmdEnter: () => void;
};

const CommandPaletteDropdownOption: FC<Props> = ({
  children,
  onCopy,
  onCmdEnter,
  ...optionProps
}) => {
  const { commandName, payload } = optionProps.data.value;

  useKeyboardEvent(
    (e) => {
      return optionProps.isFocused && e.metaKey && e.key === "c";
    },
    onCopy,
    [onCopy, optionProps.isFocused]
  );

  useKeyboardEvent(
    (e) => {
      return optionProps.isFocused && e.key === "Enter" && e.metaKey;
    },
    onCmdEnter,
    [onCmdEnter, optionProps.isFocused]
  );

  return (
    <defaultComponents.Option {...optionProps}>
      <CommandPaletteDropdownOptionCategoryIcon {...optionProps} />

      {children}

      {optionProps.data.category === "superuser" && optionProps.data.data && (
        <div className={styles.pillsContainer}>
          <Pill color="grey-legacy">{optionProps.data.data.status}</Pill>
          <Pill color="grey-legacy">
            Unit ID: {optionProps.data.data.unitCoCustomerId || "None"}
          </Pill>
          <Pill color="grey-legacy">{optionProps.data.data.guid}</Pill>
        </div>
      )}

      {optionProps.data.category === "navigation" && optionProps.data.data && (
        <div className={styles.pillsContainer}>
          {optionProps.data.type === "bank-account" && (
            <Pill color="grey-legacy">
              <MoneyAmount cents={optionProps.data.data.availableBalance} size={12} />
            </Pill>
          )}

          {optionProps.data.type === "card" && (
            <>
              <Pill color="grey-legacy">{optionProps.data.data.attributes.status}</Pill>
              <Pill color="grey-legacy">
                {getCardTypeLabelByUnitCoCard({ card: optionProps.data.data, capitalize: true })}
              </Pill>
            </>
          )}
        </div>
      )}

      <div className={styles.rightContainer}>
        {commandName === "superuser" && payload.businessGuid === null && (
          <SignOut size={16} color={colors.grey[500]} />
        )}

        {commandName === "navigate" && payload.openInNewTab && (
          <ArrowSquareOut size={16} color={colors.grey[500]} />
        )}
      </div>
    </defaultComponents.Option>
  );
};

export default memo(
  CommandPaletteDropdownOption,
  (prev, next) => prev.isFocused === next.isFocused
);
