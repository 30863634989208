import AccountLabel from "components/Accounts/AccountLabel";
import MultiStep from "layouts/MultiStep";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CapitalAccountDetailsRep from "reps/CapitalAccountDetailsRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { useOpenBankAccountRequired } from "resources/bank-accounts/queries/bankAccountQueryHooks";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import CapitalPromotionalAprLabel from "resources/capital-accounts/components/CapitalPromotionalAprLabel";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import ChargeCardOfferRepaymentPeriodContent from "resources/charge-cards/components/ChargeCardOfferRepaymentPeriodContent";
import useAcceptCreditOfferMutation from "resources/line-of-credit/mutations/useAcceptCreditOfferMutation";
import isCapitalAccountWithChargeCard from "resources/line-of-credit/utils/isCapitalAccountWithChargeCard";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import { Span } from "ui/typography";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import ReviewLineOfferBackButton from "../../components/ReviewLineOfferBackButton";

const getCapitalAccountRepaymentOptionTypeLabel = (
  option: CapitalAccountDetailsRep.CapitalRepaymentOption
) => {
  switch (option.type) {
    case CapitalAccountDetailsRep.RepaymentType.PayoutPercentage:
      return "Payout percentage";
    default:
      return "Daily installments";
  }
};

const REVIEW_FORM_ID = "review-form";

const classes = {
  multistepContainer: "flex flex-col gap-y-6",
  reviewAndAcceptCard: "flex flex-col gap-2 rounded-lg border border-grey-200",
  reviewAndAcceptSubtext: "flex items-center justify-between px-6 pb-2 pt-4",
  subtextItem: "flex gap-x-2 items-center",
  reviewAndAcceptContainer: "grid grid-cols-2 pt-4 pb-2 px-8",
};

type ReviewAndAcceptContainerRowProps = {
  label: React.ReactNode;
  value: React.ReactNode;
};

const ReviewAndAcceptContainerRow: React.FC<ReviewAndAcceptContainerRowProps> = ({
  label,
  value,
}) => (
  <>
    <div className="pb-4">{label}</div>
    <div className="pb-4">{value}</div>
  </>
);

type CashAccessReviewCardContentProps = {
  capitalAccountSummaryWithCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const CashAccessReviewCardContent: FC<CashAccessReviewCardContentProps> = ({
  capitalAccountSummaryWithCashAccessOnly,
}) => {
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const repaymentAccount = useOpenBankAccountRequired(
    capitalAccountSummaryWithCashAccessOnly.details.repayment.bankAccountGuid ?? primaryAccount.guid
  );
  const targetRepaymentDays = capitalAccountSummaryWithCashAccessOnly.details.targetRepaymentDays;
  const isCapitalPromotionalRateEnabled = useFeatureFlag("CAPITAL_PROMOTIONAL_RATES");

  return (
    <div className={classes.multistepContainer}>
      <div className={classes.reviewAndAcceptCard}>
        <div className={classes.reviewAndAcceptSubtext}>
          <div className={classes.subtextItem}>
            <CapitalAccountIcon capitalAccountType={capitalAccountSummaryWithCashAccessOnly.type} />
            <Text size={16} color={colors.grey[900]} weight="medium">
              {capitalAccountSummaryWithCashAccessOnly.name}
            </Text>
          </div>
        </div>
        <Divider className="my-0" />

        <div className={classes.reviewAndAcceptContainer}>
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Starting limit
              </Text>
            }
            value={
              <MoneyAmount
                cents={capitalAccountSummaryWithCashAccessOnly.details.limit}
                currency={"USD"}
                weight="medium"
                withCents={capitalAccountSummaryWithCashAccessOnly.details.limit % 100 !== 0}
              />
            }
          />
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Repayment schedule
              </Text>
            }
            value={
              <Text size={14} color={colors.grey[800]} weight="medium">
                {targetRepaymentDays} days
              </Text>
            }
          />
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Auto-pay type
              </Text>
            }
            value={
              <Text size={14} color={colors.grey[800]} weight="medium">
                {getCapitalAccountRepaymentOptionTypeLabel(
                  capitalAccountSummaryWithCashAccessOnly.details.repayment.option
                )}
              </Text>
            }
          />
          {capitalAccountSummaryWithCashAccessOnly.details.apr > 0 && (
            <>
              {isCapitalPromotionalRateEnabled && (
                <ReviewAndAcceptContainerRow
                  label={<CapitalPromotionalAprLabel weight="regular" color={colors.grey[500]} />}
                  value={
                    <div className="flex gap-x-2">
                      <Span className="text-sm font-medium">10.00%</Span>
                      <Span className="text-sm text-grey-600 line-through">
                        {capitalAccountSummaryWithCashAccessOnly.details.apr * 100}%
                      </Span>
                    </div>
                  }
                />
              )}
            </>
          )}
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Auto-pay account
              </Text>
            }
            value={<AccountLabel bankAccount={repaymentAccount} />}
          />
        </div>
      </div>
    </div>
  );
};

type ChargeCardReviewCardContentProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardReviewCardContent: FC<ChargeCardReviewCardContentProps> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const repaymentAccount = useOpenBankAccountRequired(
    capitalAccountSummaryWithChargeCard.details.repayment.bankAccountGuid ?? primaryAccount.guid
  );

  return (
    <div className={classes.multistepContainer}>
      <div className={classes.reviewAndAcceptCard}>
        <div className={classes.reviewAndAcceptSubtext}>
          <div className={classes.subtextItem}>
            <CapitalAccountIcon capitalAccountType={capitalAccountSummaryWithChargeCard.type} />
            <Text size={16} color={colors.grey[900]} weight="medium">
              {capitalAccountSummaryWithChargeCard.name}
            </Text>
          </div>
        </div>
        <Divider className="my-0" />

        <div className={classes.reviewAndAcceptContainer}>
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Limit
              </Text>
            }
            value={
              <MoneyAmount
                cents={capitalAccountSummaryWithChargeCard.details.limit}
                currency={"USD"}
                weight="medium"
                withCents={capitalAccountSummaryWithChargeCard.details.limit % 100 !== 0}
              />
            }
          />
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Repayment period
              </Text>
            }
            value={
              <Text size={14} color={colors.grey[800]} weight="medium">
                <ChargeCardOfferRepaymentPeriodContent
                  capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
                />
              </Text>
            }
          />
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Overdue balance
              </Text>
            }
            value={
              <Text size={14} weight="medium">
                {capitalAccountSummaryWithChargeCard.details.apr * 100}% APR
              </Text>
            }
          />
          <ReviewAndAcceptContainerRow
            label={
              <Text size={14} color={colors.grey[500]}>
                Auto-pay account
              </Text>
            }
            value={<AccountLabel bankAccount={repaymentAccount} />}
          />
        </div>
      </div>
    </div>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const ReviewView: FC<Props> = ({ capitalAccountSummary }) => {
  const navigate = useNavigate();
  const { mutateAsync: acceptCreditOffer, isPending: isAcceptCreditOfferLoading } =
    useAcceptCreditOfferMutation({ capitalAccountGuid: capitalAccountSummary.guid });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await acceptCreditOffer();
    navigate(`/capital/${capitalAccountSummary.guid}/review-line-offer/offer-accepted`);
  };

  return (
    <MultiStep.Form id={REVIEW_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Review and accept</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>
      </MultiStep.Section>

      <MultiStep.Section>
        {isCapitalAccountWithChargeCard(capitalAccountSummary) ? (
          <ChargeCardReviewCardContent
            capitalAccountSummaryWithChargeCard={capitalAccountSummary}
          />
        ) : (
          <CashAccessReviewCardContent
            capitalAccountSummaryWithCashAccessOnly={capitalAccountSummary}
          />
        )}
      </MultiStep.Section>

      <MultiStep.Controls>
        <ReviewLineOfferBackButton disabled={isAcceptCreditOfferLoading} />

        <MultiStep.Controls.NextButton form={REVIEW_FORM_ID} isLoading={isAcceptCreditOfferLoading}>
          Accept offer
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default ReviewView;
