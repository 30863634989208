import dollarSignPurpleIcon from "assets/dollar-sign-purple.svg";
import emptyBalanceGraph from "assets/empty-balance-graph.svg";
import logoWithoutLabel from "assets/highbeam-logo-without-label.svg";
import classNames from "classnames";
import {
  DEFAULT_ACTIVE_TAB,
  usePayoutInstructionsModal,
} from "pages/PayoutInstructionModal/state/payoutInstructionsModalState";
import colors from "styles/colors";
import Card from "ui/data-display/Card";
import CashDisplay from "ui/data-display/money/CashDisplay";
import Button from "ui/inputs/Button";
import { Heading2 } from "ui/typography";
import Text from "ui/typography/Text";

import { sharedCardClasses } from "./FundYourAccount";
import styles from "./HomeBalance.module.scss";

const FundYourAccount = () => {
  const { open: openPayoutInstructionsModal } = usePayoutInstructionsModal();

  return (
    <>
      <div className={classNames(styles.container, styles.containerEmpty)}>
        <div className={styles["balance-container"]}>
          <Heading2>
            <img className={styles.logo} src={logoWithoutLabel} alt="Highbeam logo" />
            Highbeam balance
          </Heading2>
          <div className={styles["balance-container__balance"]}>
            <CashDisplay color={colors.black} cents={0} />
            <Text size={12} className={styles.text}>
              as of {"today"}
            </Text>
          </div>
        </div>

        <div className={styles.emptyBalanceContainer}>
          <Card shadow="none" className={sharedCardClasses}>
            <img src={dollarSignPurpleIcon} alt="dollar sign icon" />
            <div className={styles.fundAccountDetails}>
              <Text size={14} weight="bold">
                Your funds are on the way!
              </Text>
              <Text size={14}>
                In the meantime, connect your store payouts to access them through Highbeam.
              </Text>
            </div>
            <div className={styles.fundAccountButtons}>
              <Button
                variant="primary"
                onClick={() => openPayoutInstructionsModal({ activeTab: DEFAULT_ACTIVE_TAB })}
                size="sm"
              >
                Connect your payouts
              </Button>
            </div>
          </Card>
          <div className={styles.emptyChartContainer}>
            <img src={emptyBalanceGraph} alt="empty balance graph" className={styles.emptyChart} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FundYourAccount;
