import { DownloadSimple } from "@phosphor-icons/react";
import { FC } from "react";
import Button from "ui/inputs/Button";
import { downloadBase64File } from "utils/download";

import base64SampleCreditApplicationDocoments from "../data/base64SampleCreditApplicationDocuments.json";

type DocumentType = keyof typeof base64SampleCreditApplicationDocoments;

type Props = {
  documentType: DocumentType;
};

const CreditApplicationDocumentExampleButton: FC<Props> = ({ documentType }) => (
  <Button
    variant="default"
    onClick={() =>
      base64SampleCreditApplicationDocoments[documentType].forEach(({ fileName, base64String }) =>
        downloadBase64File(base64String, fileName)
      )
    }
  >
    <DownloadSimple />
    Example
  </Button>
);

export default CreditApplicationDocumentExampleButton;
