/* eslint sort-keys: ["error", "asc", { "natural": true }] */

const loadString = (envVarName: string): string => {
  const value = import.meta.env[envVarName];
  if (!value) throw new Error(`Missing env variable: ${envVarName}`);
  return value;
};

const loadBoolean = (envVarName: string): boolean => {
  const value = JSON.parse(loadString(envVarName));
  if (typeof value !== "boolean") throw new Error(`Invalid env variable: ${envVarName}`);
  return value;
};

const loadNumber = (envVarName: string): number => {
  const value = JSON.parse(loadString(envVarName));
  if (typeof value !== "number") throw new Error(`Invalid env variable: ${envVarName}`);
  return value;
};

const env = {
  AP_EMAIL_ALIAS_DOMAIN: loadString("VITE_AP_EMAIL_ALIAS_DOMAIN"),
  AUTH0_CLIENT_ID: loadString("VITE_AUTH0_CLIENT_ID"),
  AUTH0_CONNECTION_NAME: loadString("VITE_AUTH0_CONNECTION_NAME"),
  AUTH0_CUSTOM_DOMAIN: loadString("VITE_AUTH0_CUSTOM_DOMAIN"),
  AUTH0_TENANT_DOMAIN: loadString("VITE_AUTH0_TENANT_DOMAIN"),
  CALENDLY_DEMO_LINK: loadString("VITE_CALENDLY_DEMO_LINK"),
  GOOGLE_MAPS_AND_PLACES_API_KEY: loadString("VITE_GOOGLE_MAPS_AND_PLACES_API_KEY"),
  GUIDE_TO_BANKING_LINK: loadString("VITE_GUIDE_TO_BANKING_LINK"),
  GUIDE_TO_CREDIT_LINK: loadString("VITE_GUIDE_TO_CREDIT_LINK"),
  HIGHBEAM_API_ORIGIN_V1: loadString("VITE_HIGHBEAM_API_ORIGIN_V1"),
  HIGHBEAM_API_ORIGIN_V2: loadString("VITE_HIGHBEAM_API_ORIGIN_V2"),
  HIGHBEAM_MARKETING_SITE_URL: loadString("VITE_HIGHBEAM_MARKETING_SITE_URL"),
  HIGHBEAM_SUPPORT_EMAIL_ADDRESS: loadString("VITE_HIGHBEAM_SUPPORT_EMAIL_ADDRESS"),
  INTERCOM_API_ORIGIN: loadString("VITE_INTERCOM_API_ORIGIN"),
  INTERCOM_APP_ID: loadString("VITE_INTERCOM_APP_ID"),
  INTERCOM_HASH_ENABLED: loadBoolean("VITE_INTERCOM_HASH_ENABLED"),
  LAUNCH_DARKLY_CLIENT_ID: loadString("VITE_LAUNCH_DARKLY_CLIENT_ID"),
  PLAID_ENVIRONMENT: loadString("VITE_PLAID_ENVIRONMENT"),
  RELEASE_SHA: loadString("VITE_RELEASE_SHA"),
  RUTTER_PUBLIC_KEY: loadString("VITE_RUTTER_PUBLIC_KEY"),
  SEGMENT_ENABLED: loadBoolean("VITE_SEGMENT_ENABLED"),
  SEGMENT_ID: loadString("VITE_SEGMENT_ID"),
  SENTRY_ENABLED: loadBoolean("VITE_SENTRY_ENABLED"),
  SENTRY_ENVIRONMENT: loadString("VITE_SENTRY_ENVIRONMENT"),
  SENTRY_SESSION_REPLAY_SAMPLE_RATE: loadNumber("VITE_SENTRY_SESSION_REPLAY_SAMPLE_RATE"),
  SHOW_DEBUG_MESSAGES: loadBoolean("VITE_SHOW_DEBUG_MESSAGES"),
  SUPERBLOCKS_BUSINESS_LOOKUP_APP_LINK: loadString("VITE_SUPERBLOCKS_BUSINESS_LOOKUP_APP_LINK"),
  UNIT_API_ORIGIN: loadString("VITE_UNIT_API_ORIGIN"),
  UNIT_APP_LINK: loadString("VITE_UNIT_APP_LINK"),
  UNIT_VGS_ENVIRONMENT: loadString("VITE_UNIT_VGS_ENVIRONMENT"),
  UNIT_VGS_ORGANIZATION_ID: loadString("VITE_UNIT_VGS_ORGANIZATION_ID"),
  UNIT_VGS_VAULT_ID: loadString("VITE_UNIT_VGS_VAULT_ID"),
  USE_INSIGHTS_APP: loadBoolean("VITE_USE_INSIGHTS_APP"),
};

export default env;
