import { selectorFamily } from "recoil";

import allStepsState from "./allSteps";

const currentStepIndexState = selectorFamily<number, string>({
  key: "capital/creditApplication/currentStepIndexState",
  get:
    (path) =>
    ({ get }) => {
      const allSteps = get(allStepsState);
      const currentStep = allSteps.findIndex((step) => step.path === path);
      return currentStep;
    },
});

export default currentStepIndexState;
