import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import UserInvitationRep from "reps/UserInvitationRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUserInvitationsQueryOptions from "resources/user-invitations/queries/useUserInvitationsQueryOptions";
import useUserRolesQueryOptions from "resources/user-roles/queries/useUserRolesQueryOptions";
import { notify } from "ui/feedback/Toast";
import { HighbeamApiError } from "utils/ajax";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const useCreateUserInvitationMutation = () => {
  const { segmentTrack } = useSegment();
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const refreshUserInvitationsQuery = useRefreshQuery(useUserInvitationsQueryOptions().queryKey);
  const { data: userRoles } = useSuspenseQuery(useUserRolesQueryOptions());

  return useMutation({
    mutationFn: async (body: UserInvitationRep.Creation) =>
      highbeamApi.userInvitation.create(businessGuid, body),
    onError: (err) => {
      if (err instanceof HighbeamApiError) {
        if (err.error === "EmailAddressAlreadyTaken") {
          notify("warning", "This user is already signed up with Highbeam.");
          return;
        }
      }
      notify("error", "Something went wrong! Please try again.");
    },
    onSuccess: async (data) => {
      const userRole = userRoles.find((role) => role.guid === data.userRoleGuids[0]);

      segmentTrack(SEGMENT_EVENTS.USER_INVITE_COMPLETED, {
        guid: data.guid,
        businessName: data.businessDisplayName,
        emailAddress: data.emailAddress,
        userRoleName: userRole?.name,
      });

      await refreshUserInvitationsQuery();
    },
  });
};

export default useCreateUserInvitationMutation;
