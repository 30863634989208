import { z } from "zod";

import { AddressAutocompleteOption } from "./AddressAutocompleteDropdown";

const addressSchema = z.object({
  autocompleteOption: z.custom<AddressAutocompleteOption | null>(),
  street1: z.string().min(1),
  street2: z.string(),
  city: z.string().min(1),
  state: z.string().length(2), // NB(alex): .enum?
  region: z.string().nullish(), // NB(alex): What is this?
  postalCode: z.string().min(4), // NB(alex): Audit length validation, but this should be fine, I think.
  country: z.string().length(2), // NB(alex): .enum?
});

export type AddressSchema = z.output<typeof addressSchema>;

export default addressSchema;
