import { useSetRecoilState } from "recoil";
import BusinessRep from "reps/BusinessRep";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import businessGuidState from "state/auth/businessGuid";
import isSuperuseringState from "state/auth/isSuperusering";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

/**
 * This hook allows creation of a new business.
 * After the business is created, it will always immediately redirect to onboarding
 * due to the "window.location.href = "/"".
 */
const useCreateNewBusiness = (
  additionalOptions?: MutationAdditionalOptions<BusinessRep.Complete, void>
) => {
  const userGuid = useUserGuid();
  const highbeamApi = useHighbeamApi();

  const setIsSuperusering = useSetRecoilState(isSuperuseringState);
  const setBusinessGuid = useSetRecoilState(businessGuidState);
  const { segmentTrack } = useSegment();

  return useMutationWithDefaults(
    {
      mutationFn: () => highbeamApi.business.create({ ownerUserGuid: userGuid }),
      onSuccess: ({ guid: businessGuid }) => {
        segmentTrack(SEGMENT_EVENTS.BUSINESS_APPLICATION_CREATED);
        setIsSuperusering(false);
        setBusinessGuid(businessGuid);
        window.location.href = "/"; // Intended to force a refresh.
      },
      onError: () => {
        notify("error", "Something went wrong! Please try again.");
      },
    },
    additionalOptions || {}
  );
};

export default useCreateNewBusiness;
