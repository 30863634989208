import { usdCurrencyOption } from "pages/SendMoneyPage/internationalWires";
import { selector } from "recoil";

import { quoteCurrencyState } from "./quoteCurrency";

export const isLocalPaymentState = selector<boolean>({
  key: "payments/international/isLocalPayment",
  get: ({ get }) => {
    const currencyOption = get(quoteCurrencyState);
    return currencyOption.value !== usdCurrencyOption.value;
  },
});
