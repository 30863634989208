import { ErrorBoundary } from "@sentry/react";
import React, { useState } from "react";
import StartNewBankingApplicationConfirmationModal from "resources/bank-application/dialogs/StartNewBankingApplicationConfirmationModal";

import UserAvatarMenuButton from "./UserAvatarMenuButton";
import UserAvatarMenuDropdown from "./UserAvatarMenuDropdown";

const UserAvatarMenu: React.FC = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isBankingApplicationModalOpen, setIsBankingApplicationModalOpen] = useState(false);

  const handleOpenUserMenu = () => {
    setIsUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setIsUserMenuOpen(false);
  };

  return (
    <ErrorBoundary fallback={<></>}>
      <div className="tablet:relative">
        {isBankingApplicationModalOpen && (
          <StartNewBankingApplicationConfirmationModal
            onClose={() => setIsBankingApplicationModalOpen(false)}
          />
        )}

        <UserAvatarMenuButton onOpenUserMenu={handleOpenUserMenu} />
        {isUserMenuOpen && (
          <UserAvatarMenuDropdown
            onCloseUserMenu={handleCloseUserMenu}
            onClickAddNewBusiness={() => setIsBankingApplicationModalOpen(true)}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default UserAvatarMenu;
