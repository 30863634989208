import StepsV3 from "ui/navigation/StepsV3";

import { useDrawdownContext } from "../context/DrawdownProvider";

const DrawdownSteps = () => {
  const { steps, currentStepIndex } = useDrawdownContext();

  return (
    <StepsV3>
      {steps.map(({ pathname, title }, index) => (
        <StepsV3.Step
          key={pathname}
          number={index + 1}
          state={
            currentStepIndex === index ? "active" : currentStepIndex > index ? "done" : "incomplete"
          }
          isLast={index + 1 === steps.length}
          to={currentStepIndex <= index ? undefined : pathname}
        >
          {title}
        </StepsV3.Step>
      ))}
    </StepsV3>
  );
};

export default DrawdownSteps;
