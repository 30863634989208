import React from "react";
import ShimmerV2 from "ui/feedback/ShimmerV2";

const ChatConversationShimmer: React.FC = () => {
  return (
    <div className="flex flex-col gap-5">
      <ShimmerV2 className="h-4" />
      <ShimmerV2 className="h-4 w-[80%]" />
      <ShimmerV2 className="h-4 w-[90%]" />
    </div>
  );
};

export default ChatConversationShimmer;
