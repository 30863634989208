import { Dayjs } from "dayjs";
import DailyShopifySummaryRep from "reps/DailyShopifySummaryRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class DailyShopifySummaryApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByBusinessInDateRange(
    businessGuid: string,
    fromDate: Dayjs,
    toDate: Dayjs
  ): Promise<DailyShopifySummaryRep.Complete[]> {
    const queryParams = new URLSearchParams({
      fromDate: fromDate.format("YYYY-MM-DD"),
      toDate: toDate.format("YYYY-MM-DD"),
    });
    const url = `/businesses/${businessGuid}/daily-shopify-summaries?${queryParams.toString()}`;
    return (await this.api.get<DailyShopifySummaryRep.Complete[]>(url))!;
  }
}
