import useMfa from "modules/mfa/useMfa";
import { FC, useState } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import EditCapitalAccountAutoPayAccountModal from "resources/capital-accounts/dialogs/EditCapitalAccountAutoPayAccountModal";
import ButtonWithTooltip from "ui/inputs/Button/ButtonWithTooltip";
import useIsAllowedToChangeAutoPayAccount from "utils/permissions/useIsAllowedToChangeAutoPayAccount";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  className?: string;
};

const ChangeAutoPayAccountButton: FC<Props> = ({ className, capitalAccountSummary }) => {
  const isAllowedToChangeAutoPayAccount = useIsAllowedToChangeAutoPayAccount();
  const { mfa } = useMfa();

  const [isUpdateRepaymentAccountModalOpen, setIsUpdateRepaymentAccountModalOpen] = useState(false);

  return (
    <>
      {isUpdateRepaymentAccountModalOpen && (
        <EditCapitalAccountAutoPayAccountModal
          capitalAccountSummary={capitalAccountSummary}
          onClose={() => setIsUpdateRepaymentAccountModalOpen(false)}
        />
      )}

      <ButtonWithTooltip
        className={className}
        variant="tertiary"
        onClick={async () => {
          await mfa();
          setIsUpdateRepaymentAccountModalOpen(true);
        }}
        disabled={!isAllowedToChangeAutoPayAccount}
        tooltip={
          !isAllowedToChangeAutoPayAccount &&
          "You don’t have permission to change the auto-pay account."
        }
      >
        Change auto-pay account
      </ButtonWithTooltip>
    </>
  );
};

export default ChangeAutoPayAccountButton;
