import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillDocumentActionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async process(documentId: string): Promise<void> {
    const url = `/accounts-payable/documents/${documentId}/process`;
    return (await this.api.post<void>(url))!;
  }
}
