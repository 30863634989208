import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { Link } from "react-router-dom";

import logo from "./whatstheapr-logo.svg";

const WhatsTheAprHeaderLogo = () => (
  <Link to={CREDIT_COMPARISON_PATH}>
    <img src={logo} alt='"What’s the APR?" logo' height={24} />
  </Link>
);

export default WhatsTheAprHeaderLogo;
