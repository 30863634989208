import BillSummaryRep from "reps/BillSummaryRep";

// Even though individual line item amounts have a currency, we always use the bill's currency
// to determine what currency the line item amount is in (and should be by default).
// This is, in part, so that we can do things like display the total in the bill's currency
// even if the bill has no line items (e.g. $0.00 USD).
// The backend MUST guarantee and enforce the invariant that the bill's currency is, in fact,
// the same as the currency of the line item amounts (and that line items are all in the same currency).
const inferBillLineItemCurrencyFromBill = (bill: BillSummaryRep.Complete) => {
  // Default to USD if no currency is set.
  return bill.amount?.currency ?? "USD";
};

export default inferBillLineItemCurrencyFromBill;
