import {
  getValidator,
  INTERNATIONAL_WIRE_INPUTS,
  INTERNATIONAL_WIRE_INPUT_DETAILS,
} from "pages/SendMoneyPage/internationalWires";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { internationalBankingInfoState } from "state/payments/international/bankingInfo";
import TextInput, { useTextInput } from "ui/inputs/TextInput";

type Props = {
  selectedCountry: string;
};

const BsbCode: React.FC<Props> = ({ selectedCountry }) => {
  const [internationalBankingInfo, setInternationalBankingInfo] = useRecoilState(
    internationalBankingInfoState
  );
  const BsbCode = useTextInput({
    initialValue: internationalBankingInfo.bsbCode?.value,
    onChange: getValidator(selectedCountry, INTERNATIONAL_WIRE_INPUTS.BSB_CODE),
  });

  useEffect(() => {
    setInternationalBankingInfo({
      ...internationalBankingInfo,
      bsbCode: {
        inputName: INTERNATIONAL_WIRE_INPUTS.BSB_CODE,
        value: BsbCode.value,
        isValid: BsbCode.isValid,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BsbCode.value, BsbCode.isValid]);

  return (
    <TextInput
      type="text"
      value={BsbCode.value}
      onChange={(value) => {
        BsbCode.onChange(value);
      }}
      label={INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.BSB_CODE].label}
      onBlur={BsbCode.onBlur}
      hasError={BsbCode.hasError}
      errorMessage={
        INTERNATIONAL_WIRE_INPUT_DETAILS[INTERNATIONAL_WIRE_INPUTS.BSB_CODE].errorMessage
      }
    />
  );
};

export default BsbCode;
