import { Card } from "@highbeam/unit-node-sdk";
import HighbeamCard from "components/HighbeamCard";
import useMfa from "modules/mfa/useMfa";
import { FC, useState } from "react";
import ActivateCardModal from "resources/cards/dialogs/ActivateCardModal";
import VirtualButton from "ui/inputs/VirtualButton";
import ItemWithTooltip from "ui/overlay/ItemWithTooltip";
import { Paragraph } from "ui/typography";
import useIsAllowedToActivateCard from "utils/permissions/useIsAllowedToActivateCard";
import cn from "utils/tailwind/cn";

type Props = {
  card: Card;
};

const InactivePhysicalCard: FC<Props> = ({ card }) => {
  const isAllowedtoActivateCard = useIsAllowedToActivateCard(card);
  const { mfa } = useMfa();
  const [activateCard, setActivateCard] = useState<Card>();

  return (
    <>
      {activateCard && (
        <ActivateCardModal card={activateCard} onClose={() => setActivateCard(undefined)} />
      )}

      <ItemWithTooltip
        tooltip={
          !isAllowedtoActivateCard && (
            <Paragraph>You don’t have permission to activate cards.</Paragraph>
          )
        }
        asChild
      >
        <VirtualButton
          onClick={() => {
            if (isAllowedtoActivateCard) {
              mfa().then(() => setActivateCard(card));
            }
          }}
          disabled={!isAllowedtoActivateCard}
        >
          <HighbeamCard
            card={card}
            className={cn(!isAllowedtoActivateCard && "cursor-not-allowed opacity-50")}
            showActivateCardButton
            clickable={isAllowedtoActivateCard}
          />
        </VirtualButton>
      </ItemWithTooltip>
    </>
  );
};

export default InactivePhysicalCard;
