import { atom } from "recoil";

import { ACH_PAYMENT_OPTION, PaymentMethodOption } from "../utils";

// NB(alex): experimenting with co-locating recoil state near their usage. Do not follow this pattern!
const selectedPaymentMethodOptionState = atom<PaymentMethodOption>({
  key: "sendMoney/selectedPaymentMethodOptionState",
  default: ACH_PAYMENT_OPTION,
});

export default selectedPaymentMethodOptionState;
