import { Coins } from "@phosphor-icons/react";
import highYieldIcon from "assets/high-yield-icon.svg";
import highbeamIcon from "assets/highbeam-logo-without-label.svg";
import { CSSProperties, FC } from "react";
import cn from "utils/tailwind/cn";

export type PlatformIconProps = {
  alt?: string;
  className?: string;
  size?: number;
  style?: CSSProperties;
};

export const HighbeamCapitalIcon: FC<PlatformIconProps> = ({ size, className, ...props }) => {
  return (
    <Coins
      alt="Capital"
      className={cn("shrink-0 text-purple-500", className)}
      size={size}
      {...props}
    />
  );
};

export const HighbeamHighYieldIcon: FC<PlatformIconProps> = ({ size, ...props }) => {
  return <img src={highYieldIcon} alt="High yield" width={size} height={size} {...props} />;
};

export const HighbeamIcon: FC<PlatformIconProps> = ({ size, ...props }) => {
  return <img src={highbeamIcon} alt="Highbeam" width={size} height={size} {...props} />;
};
