import TransactionFlexpaneAmount from "components/common/transaction-flexpane/TransactionFlexpaneAmount";
import Flexpane from "ui/overlay/Flexpane";

import { useReceivedPaymentInfoFlexpaneContext } from "../../context/ReceivedPaymentInfoFlexpaneProvider";

const ReceivedPaymentInfoFlexpaneAmountSection = () => {
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();

  return (
    <Flexpane.Section>
      <TransactionFlexpaneAmount
        cents={receivedPayment.attributes.amount}
        direction={receivedPayment.attributes.direction === "Debit" ? "negative" : "positive"}
      />
    </Flexpane.Section>
  );
};

export default ReceivedPaymentInfoFlexpaneAmountSection;
