import { atom } from "recoil";
import BankAccountRep from "reps/BankAccountRep";
import { DialogState } from "utils/dialog/useDialog";
import useModal from "utils/dialog/useModal";

type CardLimitsModalOpenState = {
  account: BankAccountRep.Complete;
  currentPurchase: number;
};

type CardLimitsModalState = DialogState<CardLimitsModalOpenState>;

const cardLimitsModalState = atom<CardLimitsModalState>({
  key: "CardLimitsModal",
  default: { isOpen: false },
});

export default cardLimitsModalState;

export const useCardLimitsModal = () => {
  return useModal<CardLimitsModalOpenState>(cardLimitsModalState);
};
