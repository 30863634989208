import { Dayjs } from "dayjs";
import ShopifyPayoutRep from "reps/ShopifyPayoutRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

type Params = {
  businessGuid: string;
  pending: boolean;
  fromDate?: Dayjs;
  toDate?: Dayjs;
};

export default class ShopifyPayoutApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async getByBusiness(params: Params): Promise<ShopifyPayoutRep.Complete[]> {
    const queryParams = new URLSearchParams({
      pending: params.pending.toString(),
      ...(params.fromDate ? { fromDate: params.fromDate.format("YYYY-MM-DD") } : {}),
      ...(params.toDate ? { toDate: params.toDate.format("YYYY-MM-DD") } : {}),
    });
    const url = `/businesses/${params.businessGuid}/payouts?${queryParams.toString()}`;
    return (await this.api.get<ShopifyPayoutRep.Complete[]>(url))!;
  }
}
