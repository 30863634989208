import BillApprovalRep from "reps/BillApprovalRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillApprovalApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(billApprovalId: string): Promise<BillApprovalRep.Complete | null> {
    const url = `/accounts-payable/bill-approvals/${billApprovalId}`;
    return await this.api.get<BillApprovalRep.Complete>(url);
  }

  async search(billId: string): Promise<BillApprovalRep.Complete[]> {
    const queryParams = new URLSearchParams({ billId });

    const url = `/accounts-payable/bill-approvals?${queryParams}`;
    return (await this.api.get<BillApprovalRep.Complete[]>(url))!;
  }

  async create(body: BillApprovalRep.Creator): Promise<BillApprovalRep.Complete> {
    const url = "/accounts-payable/bill-approvals";
    return (await this.api.post<BillApprovalRep.Complete>(url, body))!;
  }

  async delete(billApprovalId: string): Promise<BillApprovalRep.Complete> {
    const url = `/accounts-payable/bill-approvals/${billApprovalId}`;
    return (await this.api.delete<BillApprovalRep.Complete>(url))!;
  }
}
