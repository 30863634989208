import HighbeamBaseApi from "api/HighbeamBaseApi";
import TransactionRep from "reps/Insights/TransactionRep";
import VendorSummaryRep from "reps/Insights/VendorSummaryRep";
import { LocalMonth } from "utils/types/date";

export default class VendorListApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async search(
    businessGuid: string,
    since: LocalMonth,
    until: LocalMonth,
    sources?: TransactionRep.TransactionSource[]
  ): Promise<VendorSummaryRep[]> {
    const queryParams = new URLSearchParams({
      businessGuid,
      since: since.toString(),
      until: until.toString(),
    });
    sources?.forEach((source) => queryParams.append("sources", source));
    const url = `/insights/vendor-list?${queryParams.toString()}`;
    return (await this.api.get<VendorSummaryRep[]>(url))!;
  }
}
