import { PlusCircle, ShieldStar } from "@phosphor-icons/react";
import { getIcon } from "components/Accounts/AccountLabel";
import ActivePlaidAccountRow from "modules/plaid/components/connection-cards/PlaidConnectionCardContents/ActivePlaidAccountRow";
import { ConnectPlaidButton } from "modules/plaid/components/connection-cards/PlaidConnectionCardContents/ConnectPlaidButton";
import DeletePlaidConnectionModal from "modules/plaid/components/connection-cards/PlaidConnectionCardContents/DeletePlaidConnectionModal";
import InactivePlaidAccountRow from "modules/plaid/components/connection-cards/PlaidConnectionCardContents/InactivePlaidAccountRow";
import PlaidConnectionHealthIndicator from "modules/plaid/components/PlaidConnectionHealthIndicator";
import { useCallback, useState } from "react";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import ScrollWithInsetShadow from "ui/data-display/ScrollWithInsetShadow";
import Button from "ui/inputs/Button";
import { Heading3 } from "ui/typography";
import Text from "ui/typography/Text";
import { sortPlaidAccounts } from "utils/account";

import styles from "./PlaidConnectionCardContents.module.scss";

type Props = {
  connection: PlaidConnectionRep.Complete;
  openPlaidLinkModal: () => void;
};

const PlaidConnectionCardContents: React.FC<Props> = ({ connection, openPlaidLinkModal }) => {
  const [showDeleteConnectionModal, setShowDeleteConnectionModal] = useState(false);
  const isSuperusering = useIsSuperusering();
  const openItemInPlaidDashboard = useCallback(() => {
    const url = `https://dashboard.plaid.com/activity/debugger/${connection.plaidItemId}`;
    window.open(url, "_blank")?.focus();
  }, [connection]);
  const reconnectionNeeded =
    !connection.isActive || connection.accounts.filter((account) => !account.isActive).length > 0;
  // The connection status pill shows healthy "Connected" if the connection is active
  // and if there is at least one connected account within it. If all accounts are inactive,
  // indicate the connection is unhealthy.
  const connectionStatusShowHealthy =
    connection.isActive && connection.accounts.filter((account) => account.isActive).length > 0;

  return (
    <>
      {showDeleteConnectionModal && (
        <DeletePlaidConnectionModal
          onClose={() => {
            setShowDeleteConnectionModal(false);
          }}
          onDelete={() => {
            setShowDeleteConnectionModal(false);
          }}
          connectionGuid={connection.guid}
          name={connection.institutionName}
        />
      )}
      <div className={styles.plaidConnectionCardContents}>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            {getIcon(connection.institutionId)}
            <Text size={14} weight="bold">
              {connection.institutionName}
            </Text>
          </div>

          <PlaidConnectionHealthIndicator isActive={connectionStatusShowHealthy} />
        </div>
        <ScrollWithInsetShadow className={styles.plaidAccountList}>
          {connection.accounts.sort(sortPlaidAccounts).map((account) => (
            <div key={account.plaidAccountId}>
              {account.isActive ? (
                <ActivePlaidAccountRow account={account} />
              ) : (
                <InactivePlaidAccountRow account={account} />
              )}
            </div>
          ))}
        </ScrollWithInsetShadow>
        <ConnectPlaidButton
          variant={reconnectionNeeded ? "primary" : "tertiary"}
          className={styles.addAccountButton}
          openPlaidLinkModal={openPlaidLinkModal}
        >
          {reconnectionNeeded ? (
            "Reconnect"
          ) : (
            <>
              <PlusCircle size={16} /> Add from login
            </>
          )}
        </ConnectPlaidButton>
        {isSuperusering ? (
          <div className={styles.superuserArea}>
            <Heading3>Superuser</Heading3>
            <Button variant="secondary" onClick={openItemInPlaidDashboard}>
              <ShieldStar size={16} /> Open in Plaid
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConnectionModal(true);
              }}
            >
              <ShieldStar size={16} /> Delete connection
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PlaidConnectionCardContents;
