import CapitalTransactionRep from "reps/CapitalTransactionRep";

export type CapitalTransactionType = "repayment" | "drawdown";

const getCapitalTransactionType = (
  capitalTransaction: CapitalTransactionRep.Complete
): CapitalTransactionType => {
  return capitalTransaction.amount >= 0 ? "repayment" : "drawdown";
};

export default getCapitalTransactionType;
