namespace RutterConnectionRep {
  export type RutterCommercePlatform = "SHOPIFY" | "AMAZON";
  export const rutterAccountingPlatforms = ["QUICKBOOKS", "XERO", "NETSUITE"] as const;
  export type RutterAccountingPlatform = (typeof rutterAccountingPlatforms)[number];
  export type RutterPaymentProcessor = "STRIPE" | "PAYPAL";

  // Aggregate all
  export type RutterPlatform =
    | RutterCommercePlatform
    | RutterAccountingPlatform
    | RutterPaymentProcessor;

  export interface Creation {
    readonly publicToken: string;
  }

  interface BaseComplete {
    readonly guid: string;
    readonly shopName: string;
    readonly isActive: boolean;
    readonly isReady: boolean;
    readonly platformName: RutterPlatform;
    readonly hasSyncedBalances: boolean;
    readonly hasSyncedTransactions: boolean;
    readonly hasSyncedPayouts: boolean;
  }

  export interface RutterCommercePlatformComplete extends BaseComplete {
    readonly platformName: RutterCommercePlatform;
  }

  export interface RutterAccountingPlatformComplete extends BaseComplete {
    readonly platformName: RutterAccountingPlatform;
  }

  export interface RutterPaymentProcessorComplete extends BaseComplete {
    readonly platformName: RutterPaymentProcessor;
  }

  export type Complete =
    | RutterCommercePlatformComplete
    | RutterAccountingPlatformComplete
    | RutterPaymentProcessorComplete;
}

export default RutterConnectionRep;
