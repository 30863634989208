import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react";
import { Command as CommandPrimitive } from "cmdk";
import { FC, ComponentPropsWithoutRef } from "react";
import cn from "utils/tailwind/cn";

type CommandInputProps = ComponentPropsWithoutRef<typeof CommandPrimitive.Input>;

const CommandInputWrapper: FC<CommandInputProps> = ({ className, ...props }) => (
  <div
    className={cn("flex items-center border-b border-grey-200 pl-2.5", className)}
    cmdk-input-wrapper=""
  >
    <MagnifyingGlassIcon className="mr-2 h-4 w-4 shrink-0 text-grey-500" />
    <CommandPrimitive.Input
      className={
        "flex h-10 w-full rounded-md bg-transparent py-2.5 pr-2.5 text-sm outline-none placeholder:text-grey-400 disabled:cursor-not-allowed disabled:opacity-50"
      }
      {...props}
    />
  </div>
);

type CommandListProps = ComponentPropsWithoutRef<typeof CommandPrimitive.List>;

const CommandList: FC<CommandListProps> = ({ className, ...props }) => (
  <CommandPrimitive.List
    className={cn("max-h-96 overflow-y-auto overflow-x-hidden", className)}
    {...props}
  />
);

type CommandEmptyProps = ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>;

const CommandEmpty: FC<CommandEmptyProps> = ({ className, ...props }) => (
  <CommandPrimitive.Empty
    className={cn("px-4 py-2.5 text-sm text-grey-400", className)}
    {...props}
  />
);

type CommandGroupProps = ComponentPropsWithoutRef<typeof CommandPrimitive.Group>;

const CommandGroup: FC<CommandGroupProps> = ({ className, ...props }) => (
  <CommandPrimitive.Group
    className={cn(
      "[&_[cmdk-group-heading]]:bg-grey-50 [&_[cmdk-group-heading]]:px-3 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-grey-500",
      className
    )}
    {...props}
  />
);

type CommandItemProps = ComponentPropsWithoutRef<typeof CommandPrimitive.Item>;

const CommandItem: FC<CommandItemProps> = ({ className, ...props }) => (
  <CommandPrimitive.Item
    className={cn(
      "flex w-full cursor-pointer select-none items-center justify-between px-4 py-2.5 text-sm font-medium data-[disabled=true]:pointer-events-none data-[disabled=true]:bg-grey-100 data-[selected=true]:bg-grey-50 data-[disabled=true]:text-grey-400",
      className
    )}
    {...props}
  />
);

type Props = ComponentPropsWithoutRef<typeof CommandPrimitive>;

const Command: FC<Props> = ({ children, className, ...props }) => (
  <CommandPrimitive
    className={cn(
      "flex h-full w-full flex-col overflow-hidden rounded-md text-grey-900",
      className
    )}
    {...props}
  >
    {children}
  </CommandPrimitive>
);

export default Object.assign(Command, {
  InputWrapper: CommandInputWrapper,
  List: CommandList,
  Empty: CommandEmpty,
  Group: CommandGroup,
  Item: CommandItem,
});
