import classNames from "classnames";
import IconWithTooltip from "components/common/IconWithTooltip";
import { FC } from "react";
import Text from "ui/typography/Text";
import { TextWeight } from "ui/typography/Text/TextTypes";

import styles from "./CapitalPromotionalAprLabel.module.scss";

type Props = {
  withGradient?: boolean;
  weight?: TextWeight;
  color?: string;
};

const CapitalPromotionalAprLabel: FC<Props> = ({
  withGradient = false,
  weight = "bold",
  color,
}) => (
  <Text
    className={classNames(styles.promotionalApr, withGradient && styles.withGradient)}
    weight={weight}
    size={14}
    color={color}
  >
    Promotional APR
    <IconWithTooltip
      color="light"
      tooltip="Annual percentage rate. Promotional rates are applied via refunds at the end of each month. Effective through December 2, 2024."
    />
  </Text>
);

export default CapitalPromotionalAprLabel;
