import IconWithTooltip from "components/common/IconWithTooltip";
import { FC } from "react";
import { ChargeCardProgram } from "resources/capital-account-charge-card-program/queries/chargeCardProgramQueryHooks";
import { Paragraph } from "ui/typography";

type Props = {
  chargeCardProgram: ChargeCardProgram;
};

const ChargeCardCashBackInfoIconWithTooltip: FC<Props> = ({ chargeCardProgram }) => {
  return (
    <IconWithTooltip
      color="dark"
      tooltip={
        <Paragraph>
          {chargeCardProgram.maxCashback}% on all qualifying ad spend, and{" "}
          {chargeCardProgram.minCashback}% all other spend. Cash back is paid directly to your
          Highbeam bank account.
        </Paragraph>
      }
    />
  );
};

export default ChargeCardCashBackInfoIconWithTooltip;
