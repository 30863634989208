/**
 * Gets file type from a blob. I ChatGPT'd this, please do not assume it is right, but it seems to work.
 */
const getFileTypeFromBlob = (blob: Blob): Promise<"pdf" | "png" | "jpg" | null> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const arr = new Uint8Array(reader.result as ArrayBuffer).subarray(0, 4);

      // NB(alex): Looks like this is the only way to map over a `Uint8Array`: https://chatgpt.com/share/a1e2d032-279e-4483-b834-6d6a60edfa4b
      // eslint-disable-next-line functional/no-let
      let header = "";
      // eslint-disable-next-line functional/no-let
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      // Check file signature
      switch (header) {
        case "25504446":
          return resolve("pdf");
        case "89504e47":
          return resolve("png");
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
          return resolve("jpg");
        default:
          return resolve(null);
      }
    };
    reader.readAsArrayBuffer(blob);
  });
};

export default getFileTypeFromBlob;
