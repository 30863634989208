import InstitutionRep from "reps/InstitutionRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class InstitutionApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(routingNumber: string): Promise<InstitutionRep.Complete | null> {
    const url = `/institutions/${routingNumber}`;
    return await this.api.get<InstitutionRep.Complete>(url);
  }
}
