import { useQuery } from "@tanstack/react-query";
import useUserRoleMembershipsQueryOptions from "resources/user-roles/queries/useUserRoleMembershipsQueryOptions";
import useUserRolesQueryOptions from "resources/user-roles/queries/useUserRolesQueryOptions";

const useUserRoleMembershipDisplayName = (
  businessGuid: string,
  userGuid: string
): string | null | undefined => {
  const { data: userRoles = [], isLoading: isUserRolesLoading } = useQuery(
    useUserRolesQueryOptions(businessGuid)
  );
  const { data: userRoleMemberships = [], isLoading: isUserRoleMembershipsLoading } = useQuery(
    useUserRoleMembershipsQueryOptions(businessGuid)
  );
  const userRoleMembershipsForUser = userRoleMemberships.filter(
    (userRoleMembership) => userRoleMembership.userGuid === userGuid
  );
  if (userRoleMembershipsForUser.length !== 1) return;
  const userRoleMembership = userRoleMembershipsForUser[0];
  const userRole = userRoles.find((userRole) => userRole.guid === userRoleMembership.userRoleGuid);

  if (isUserRolesLoading || isUserRoleMembershipsLoading) return undefined;

  return userRole?.name ?? null;
};

export default useUserRoleMembershipDisplayName;
