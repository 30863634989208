import { Card } from "@highbeam/unit-node-sdk";
import { capitalize as lodashCapitalize } from "lodash-es";
import CardRep from "reps/CardRep";

import { CardAttributes } from "../types";

import getCardAttributesByCardType from "./getCardAttributesByCardType";
import getCardTypeByCard from "./getCardTypeByCard";

type GetCardTypeLabelByAttributesParams = CardAttributes & {
  capitalize?: boolean;
};

export const getCardTypeLabelByAttributes = ({
  creditOrDebit,
  virtualOrPhysical,
  capitalize,
}: GetCardTypeLabelByAttributesParams) => {
  const label = `${virtualOrPhysical === "virtual" ? "virtual " : ""}${creditOrDebit === "credit" ? "Highbeam Card" : "debit card"}`;
  return capitalize ? lodashCapitalize(label) : label;
};

type GetCardTypeLabelByCardTypeParams = {
  cardType: CardRep.CardType;
  capitalize?: boolean;
};

export const getCardTypeLabelByCardType = ({
  cardType,
  capitalize,
}: GetCardTypeLabelByCardTypeParams) => {
  const cardAttributes = getCardAttributesByCardType(cardType);
  return getCardTypeLabelByAttributes({ ...cardAttributes, capitalize });
};

type GetCardTypeLabelByCardParams = {
  card: Card;
  capitalize?: boolean;
};

export const getCardTypeLabelByUnitCoCard = ({
  card,
  capitalize,
}: GetCardTypeLabelByCardParams) => {
  const cardType = getCardTypeByCard(card);
  return getCardTypeLabelByCardType({ cardType, capitalize });
};
