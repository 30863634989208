import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import getIsInternationalWireUnitCoPayment from "resources/unit-co-payments/utils/getIsInternationalWireUnitCoPayment";
import Flexpane from "ui/overlay/Flexpane";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

import BankAccountDetail from "./BankAccountDetail";
import CounterpartyDetail from "./CounterpartyDetail";
import PaymentDetailBubble from "./PaymentDetailBubble";

const PaymentInfoFlexpaneToFromSection = () => {
  const { payment } = usePaymentInfoFlexpaneContext();

  if (getIsInternationalWireUnitCoPayment(payment)) {
    return <InternationalWireInfoFlexpaneToFromSection />;
  }
  return <AchPaymentInfoFlexpaneToFromSection />;
};

const AchPaymentInfoFlexpaneToFromSection = () => {
  const { payment } = usePaymentInfoFlexpaneContext();
  const positive = payment.attributes.direction === "Debit";

  return (
    <Flexpane.Section>
      <TransactionFlexpaneParties
        from={positive ? <CounterpartyDetail /> : <BankAccountDetail />}
        status={<PaymentDetailBubble />}
        to={positive ? <BankAccountDetail /> : <CounterpartyDetail />}
      />
    </Flexpane.Section>
  );
};

const InternationalWireInfoFlexpaneToFromSection = () => {
  return (
    <Flexpane.Section>
      <TransactionFlexpaneParties
        from={<BankAccountDetail />}
        status={<PaymentDetailBubble />}
        to={<CounterpartyDetail />}
      />
    </Flexpane.Section>
  );
};

export default PaymentInfoFlexpaneToFromSection;
