import applePayLogo from "assets/apple-pay.svg";
import { Paragraph } from "ui/typography";
import cn from "utils/tailwind/cn";

type Props = {
  className?: string;
};

const ApplePayInfoText: React.FC<Props> = ({ className }) => (
  <div className={cn("flex items-center justify-center rounded-md bg-grey-100 p-3", className)}>
    <Paragraph className="flex items-center gap-x-1 text-sm">
      Open the wallet app on your phone and add the card to start using it with{" "}
      <img src={applePayLogo} className="mb-[-1px]" alt="Apple Pay" />
    </Paragraph>
  </div>
);

export default ApplePayInfoText;
