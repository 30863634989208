import { DownloadSimple } from "@phosphor-icons/react";
import { FC, useMemo } from "react";
import useDownloadInvoiceMutation from "resources/line-of-credit/mutations/useDownloadInvoiceMutation";
import colors from "styles/colors";
import InvoiceUploadedCard from "ui/data-display/InvoiceUploadedCard";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import FileIcon from "ui/icons/FileIcon";
import Text from "ui/typography/Text";
import { getFileExtension } from "utils/url";

import styles from "./Invoice.module.scss";

type Props = {
  invoiceUrl: string;
};

const Invoice: FC<Props> = ({ invoiceUrl }) => {
  // Extract the file name from the invoiceUrl
  const invoiceName = invoiceUrl.split("/").pop()?.split("?")[0] || "";
  const { mutate: downloadInvoice, isPending } = useDownloadInvoiceMutation();

  const fileExtension = useMemo(() => getFileExtension(invoiceName || ""), [invoiceName]);

  return (
    <InvoiceUploadedCard>
      <div className={styles.invoice}>
        <FileIcon fileExtension={fileExtension} />
        <Text size={14} color={colors.grey[900]} weight="medium" className={styles.invoiceName}>
          {invoiceName}
        </Text>
        <button
          type="button"
          title="Download"
          onClick={() => downloadInvoice({ invoiceUrl })}
          className={styles.downloadButton}
        >
          {isPending ? <AnimatedSpinner /> : <DownloadSimple size={14} />}
        </button>
      </div>
    </InvoiceUploadedCard>
  );
};

export default Invoice;
