import { ConnectedStoreType } from "../types";

/**
 * Maps the connected store type to a readable name.
 * NB(alex): This function currently looks redundant but it is useful to have if we ever add `ConnectedStoreType` that isn't readable.
 */
const getConnectedStoreName = (connectedStoreType: ConnectedStoreType): string => {
  switch (connectedStoreType) {
    case "Shopify":
      return "Shopify";
    case "Amazon":
      return "Amazon";
    case "PayPal":
      return "PayPal";
    case "Stripe":
      return "Stripe";
  }
};

export default getConnectedStoreName;
