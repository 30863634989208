import { captureException } from "@sentry/react";
import { ErrorCode } from "react-dropzone";
import { Controller } from "react-hook-form";
import FileDropzone from "ui/inputs/FileDropzone";
import { DeleteFileButton } from "ui/inputs/FileDropzone/SelectedFile/ActionButtons";

import { useDrawdownInvoiceFormContext } from "../context/DrawdownProvider";

const DrawdownInvoiceUploadSection = () => {
  const { control, watch } = useDrawdownInvoiceFormContext();
  const invoiceValue = watch("invoice");

  return (
    <Controller
      control={control}
      name="invoice"
      render={({ field }) => (
        <FileDropzone
          text={"Drag and drop an invoice or browse"}
          fileTypes={["image", "pdf", "word", "excel", "csv"]}
          maxSize={52428800} // 50mb
          multiple={false} // we only allow one file upload per drawdown request
          onDropRejected={(fileRejections) => {
            fileRejections.forEach((fileRejection) => {
              fileRejection.errors.forEach(({ code }) => {
                if (code === ErrorCode.FileTooLarge) {
                  captureException(new Error(`File too large: ${fileRejection.file.name}`));
                }
              });
            });
          }}
          onDropAccepted={(acceptedFiles) => {
            // onDropAccepted returns an array even with multiple={false}.
            if (acceptedFiles.length > 0) {
              field.onChange(acceptedFiles[0]);
            }
          }}
        >
          {invoiceValue && (
            <FileDropzone.SelectedFile
              fileName={invoiceValue.name}
              actions={<DeleteFileButton onClick={() => field.onChange(null)} />}
            />
          )}
        </FileDropzone>
      )}
    />
  );
};

export default DrawdownInvoiceUploadSection;
