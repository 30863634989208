import { ComponentProps, FC, ReactElement } from "react";
import IconTile from "ui/icons/IconTile";
import { Paragraph } from "ui/typography";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

type Props = {
  children: [ReactElement, ReactElement];
  className?: string;
};

const FeatureOverviewBar: FC<Props> = ({ children, className }) => {
  return <div className={cn("flex items-center gap-x-7", className)}>{children}</div>;
};

const FeatureOverviewBarIconTile: FC<ComponentProps<typeof IconTile>> = ({
  className,
  ...props
}) => {
  return <IconTile className={cn("size-16", className)} roundedness="circle" {...props} />;
};

const FeatureOverviewBarParagraph: FC<PropsWithChildrenAndClassName> = ({
  children,
  className,
}) => {
  return <Paragraph className={cn("text-sm text-grey-600", className)}>{children}</Paragraph>;
};

export default Object.assign(FeatureOverviewBar, {
  IconTile: FeatureOverviewBarIconTile,
  Paragraph: FeatureOverviewBarParagraph,
});
