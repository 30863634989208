import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";
import { HighbeamWireTransaction } from "utils/types/transactionsTypes";

import TraceNumberLabel from "./TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";

type Props = {
  transaction: HighbeamWireTransaction;
};

const WireTransactionDetailsSection: React.FC<Props> = ({ transaction }) => (
  <Section>
    <Heading3>Wire transfer details</Heading3>
    <MetadataList>
      {transaction.description && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
          <MetadataList.ItemValue>{transaction.description}</MetadataList.ItemValue>
        </MetadataList.Item>
      )}
      <MetadataList.Item>
        <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.counterpartyRoutingNumber}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.counterpartyAccountNumber}</MetadataList.ItemValue>
      </MetadataList.Item>
      <MetadataList.Item>
        <MetadataList.ItemLabel>Account type</MetadataList.ItemLabel>
        <MetadataList.ItemValue>{transaction.counterpartyAccountType}</MetadataList.ItemValue>
      </MetadataList.Item>
      {transaction.imadOmad && (
        <MetadataList.Item>
          <MetadataList.ItemLabel>
            <TraceNumberLabel />
          </MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {transaction.imadOmad
              ? `IMAD: ${transaction.imadOmad.imad}, OMAD: ${transaction.imadOmad.omad}`
              : "Available after payment sends"}
          </MetadataList.ItemValue>
        </MetadataList.Item>
      )}
    </MetadataList>
  </Section>
);

export default WireTransactionDetailsSection;
