import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

const getRemittanceRate = (
  capitalAccountSummary: CapitalAccountSummaryRep.Complete
): number | undefined => {
  // NB(alex): "percentage" here is a number between 0 and 1, so I'm calling this the "remittance rate", because we often convert this to a percentage in the UI.
  return capitalAccountSummary.details.repayment.option.type === "PayoutPercentage"
    ? capitalAccountSummary.details.repayment.option.percentage
    : undefined;
};

export default getRemittanceRate;
