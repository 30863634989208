namespace BillDocumentRep {
  export enum DocumentType {
    Invoice = "Invoice",
    Other = "Other",
  }

  export interface Complete {
    readonly id: string;
    readonly businessGuid: string;
    readonly billId: string;
    readonly type: DocumentType;
    readonly documentId: string;
    readonly signedDocumentUrl: string;
    readonly originalFileName: string | null;
  }

  export interface Creator {
    readonly businessGuid: string;
    readonly billId: string;
    readonly type: DocumentType;
    readonly fileName: string;
  }
}

export default BillDocumentRep;
