import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

const BASE_REFERRAL_QUERY_KEY = ["user-referral"];

type Params = {
  referralSlug: string | undefined;
};

const useReferralQueryOptions = ({ referralSlug }: Params) => {
  const highbeamApi = useHighbeamApi({ authMode: "maybeAuthenticated" });

  return useQueryOptions({
    queryKey: [BASE_REFERRAL_QUERY_KEY, { referralSlug }],
    queryFn: () => {
      if (!referralSlug) {
        return null;
      }
      return highbeamApi.referralLink.get(referralSlug);
    },
  });
};

export const useReferralQuery = (params: Params) => {
  return useQuery(useReferralQueryOptions(params));
};

const useReferral = (params: Params) => {
  return useSuspenseQuery(useReferralQueryOptions(params)).data;
};

export default useReferral;
