import { WirePayment, WireTransaction } from "@highbeam/unit-node-sdk";
import TraceNumberLabel from "components/Transactions/TransactionInfoFlexpane/TransactionInfoFlexpaneDetailsSection/TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";
import { useUnitCoTransaction } from "resources/unit-co-transactions/queries/useUnitCoTransactionQueryOptions";
import MetadataList from "ui/data-display/MetadataList";
import Section from "ui/data-display/Section";
import { Heading3 } from "ui/typography";
import { HighbeamWireImadOmad } from "utils/types/transactionsTypes";

type Props = {
  payment: WirePayment;
};

const WirePaymentDetailsSection: React.FC<Props> = ({ payment }) => {
  const transaction = useUnitCoTransaction<WireTransaction>({
    accountId: payment.relationships.account.data.id,
    transactionId: payment.relationships.transaction?.data.id,
  });

  const imadOmad = transaction?.attributes.imadOmad as HighbeamWireImadOmad;

  return (
    <Section>
      <Heading3>Wire transfer details</Heading3>
      <MetadataList>
        {payment.attributes.description && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>Description</MetadataList.ItemLabel>
            <MetadataList.ItemValue>{payment.attributes.description}</MetadataList.ItemValue>
          </MetadataList.Item>
        )}
        <MetadataList.Item>
          <MetadataList.ItemLabel>Routing number</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {payment.attributes.counterparty.routingNumber}
          </MetadataList.ItemValue>
        </MetadataList.Item>
        <MetadataList.Item>
          <MetadataList.ItemLabel>Account number</MetadataList.ItemLabel>
          <MetadataList.ItemValue>
            {payment.attributes.counterparty.accountNumber}
          </MetadataList.ItemValue>
        </MetadataList.Item>
        {transaction?.attributes.counterparty.accountType && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>Account type</MetadataList.ItemLabel>
            <MetadataList.ItemValue>
              {transaction.attributes.counterparty.accountType}
            </MetadataList.ItemValue>
          </MetadataList.Item>
        )}
        {imadOmad && (
          <MetadataList.Item>
            <MetadataList.ItemLabel>
              <TraceNumberLabel />
            </MetadataList.ItemLabel>
            <MetadataList.ItemValue>
              {`IMAD: ${imadOmad.imad}, OMAD: ${imadOmad.omad}`}
            </MetadataList.ItemValue>
          </MetadataList.Item>
        )}
      </MetadataList>
    </Section>
  );
};

export default WirePaymentDetailsSection;
