import { CSSProperties, FC, ReactNode } from "react";
import cn from "utils/tailwind/cn";

export type PageHeaderProps = {
  className?: string;
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
};

const PageHeader: FC<PageHeaderProps> = ({ className, left, center, right }) => {
  return (
    <header className={cn("grid w-full grid-cols-3 justify-center p-8", className)}>
      <div className="flex items-center justify-start">{left}</div>

      <div className="flex items-center justify-center">{center}</div>

      <div className="flex items-center justify-end">{right}</div>
    </header>
  );
};

type PageBodyCenterProps = {
  className?: string;
  children: ReactNode;
};

const PageBodyCenter: FC<PageBodyCenterProps> = ({ className, children }) => {
  return (
    <div className={cn("relative flex w-full flex-1 flex-col items-center", className)}>
      {children}
    </div>
  );
};

type Props = {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
};

const Page: FC<Props> = ({ className, style, children }) => {
  return (
    <div className={cn("flex min-h-full flex-col items-center", className)} style={style}>
      {children}
    </div>
  );
};

export default Object.assign(Page, {
  Header: PageHeader,
  BodyCenter: PageBodyCenter,
});
