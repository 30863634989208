import makeAccountLimitWarnings from "../utils/makeAccountLimitWarnings";

import useAccountLimitInfo from "./useAccountLimitInfo";

const useAccountLimitWarnings = (amountInCents: number, unitCoDepositAccountId?: string) => {
  const accountLimitInfo = useAccountLimitInfo(unitCoDepositAccountId);

  return makeAccountLimitWarnings(accountLimitInfo, amountInCents);
};

export default useAccountLimitWarnings;
