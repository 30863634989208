import { useIsFetching } from "@tanstack/react-query";
import useBill from "resources/bills/queries/useBill";

import billSyncQueryHooks from "../queries/billSyncQueryHooks";
import shouldBillSyncBeDryRun from "../utils/shouldBillSyncBeDryRun";

const useBillSyncIsSyncing = (billId: string) => {
  const bill = useBill(billId, { required: true });
  const dryRun = shouldBillSyncBeDryRun(bill);
  const queryKey = billSyncQueryHooks.useQueryKey({ billId, dryRun });

  // useIsFetching returns the number of queries that are fetching, *not* a boolean.
  const isFetching = useIsFetching({ queryKey });
  return isFetching > 0;
};

export default useBillSyncIsSyncing;
