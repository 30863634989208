import DashboardHeader from "layouts/Dashboard/DashboardHeader/DashboardHeader";
import { useParams } from "react-router-dom";
import Section from "ui/data-display/Section";
import SectionHeader from "ui/data-display/SectionHeader";
import BackCaret from "ui/navigation/BackCaret";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import { Heading2 } from "ui/typography";

import UpdatePayee from "./UpdatePayee";

const EditPayeePage = () => {
  const params = useParams();
  const payeeGuid = params.payeeGuid!;
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/payees">Payees</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Edit payee</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <Section>
        <SectionHeader>
          <BackCaret to={`/payments/payees/${payeeGuid}`} />
          <Heading2>Payee info</Heading2>
        </SectionHeader>
      </Section>

      <UpdatePayee payeeGuid={payeeGuid} />
    </>
  );
};

export default EditPayeePage;
