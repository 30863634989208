import { Option } from "ui/inputs/Dropdown";

import { ALL_BANK_ACCOUNTS_OPTION } from "./getBankAccountDropdownOptions";

// DEPRECATED

// NB(alex): This replaces the old `allBankAccountsOptionsByUnitIdState` recoil state. We should delete this once we have a composable `<Dropdown>` component.

const getBankAccountOptionsByUnitId = (bankAccountOptions: Option[]) => {
  return (
    bankAccountOptions.reduce(
      (map: Record<string, Option>, option: Option) => ({
        ...map,
        [option.value]: option,
      }),
      { [ALL_BANK_ACCOUNTS_OPTION.value]: ALL_BANK_ACCOUNTS_OPTION }
    ) || {}
  );
};

export default getBankAccountOptionsByUnitId;
