import { z } from "zod";

export const ROUTING_NUMBER_LENGTH = 9;

// NB(alex): Intentionally decoupled from `InstitutionRep.Complete` but should share fields for the most part.
export type FinancialInstitution = {
  isAchSupported: boolean;
  isDomesticWireSupported: boolean;
  name: string;
};

export type RoutingNumberSchemaParams = {
  fetchFinancialInstitutionByRoutingNumber: (
    routingNumber: string
  ) => Promise<FinancialInstitution | null>;
  paymentMethod: "ach" | "domestic-wire" | "international-wire";
};

const routingNumberSchema = ({
  fetchFinancialInstitutionByRoutingNumber,
  paymentMethod,
}: RoutingNumberSchemaParams) => {
  return z
    .string({
      required_error: "Please enter a routing number.", // eslint-disable-line camelcase
    })
    .length(ROUTING_NUMBER_LENGTH, `Routing number must be ${ROUTING_NUMBER_LENGTH} digits.`)
    .superRefine(async (routingNumber, ctx) => {
      // NB(alex): We have to check this again even though we get the error with `.length` above. Would be nice if `ctx` had the error, but it doesn't.
      if (routingNumber.length !== ROUTING_NUMBER_LENGTH) {
        return z.NEVER;
      }

      if (!/^[0-9]+$/.test(routingNumber)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a valid routing number.",
        });
        return z.NEVER;
      }

      const institution = await fetchFinancialInstitutionByRoutingNumber(routingNumber);

      if (!institution) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Institution with this routing number not found.",
        });
      } else {
        if (paymentMethod === "ach" && !institution.isAchSupported) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `This ${institution.name} routing number does not support ACH transfers.`,
          });
        }
        if (paymentMethod === "domestic-wire" && !institution.isDomesticWireSupported) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `This ${institution.name} routing number does not support wires.`,
          });
        }
      }
    });
};

export default routingNumberSchema;
