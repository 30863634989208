import { Dayjs } from "dayjs";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

type Params = {
  since: Dayjs;
  until: Dayjs;
};

// NB(alex): we would like to pass `search` to the transactions query but the backend does not support it yet, so we filter transactions here on the frontend
const useLineOfCreditTransactionsQueryOptions = ({ since, until }: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: ["lineOfCreditTransactions", { businessGuid, since, until }],
    queryFn: () => {
      return highbeamApi.lineOfCreditTransactions.getTransactions(businessGuid, since, until);
    },
  });
};

export default useLineOfCreditTransactionsQueryOptions;
