import { PlusCircle } from "@phosphor-icons/react";
import { FC } from "react";
import { useDropzone } from "react-dropzone";
import BillDocumentRep from "reps/BillDocumentRep";
import useUploadBillDocumentsMutation from "resources/bill-documents/mutations/useUploadBillDocumentsMutation";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import { fileTypeExtensions } from "utils/dropzone/file-types";

type Props = {
  billId: string;
};

const BillDetailsDocumentsViewAddDocumentsButton: FC<Props> = ({ billId }) => {
  const { mutate: uploadBillDocuments, isPending: isUploadingBillDocuments } =
    useUploadBillDocumentsMutation();

  const { open } = useDropzone({
    onDropAccepted: (files) => {
      uploadBillDocuments({
        files,
        billId: billId,
        type: BillDocumentRep.DocumentType.Other,
      });
    },
    onDropRejected: () => {
      notify("error", "Something went wrong. Please try again or contact support.");
    },
    accept: {
      ...fileTypeExtensions.pdf,
      ...fileTypeExtensions.image,
    },
  });

  return (
    <Button variant="default" onClick={open} isLoading={isUploadingBillDocuments}>
      <PlusCircle size={20} /> Add
    </Button>
  );
};

export default BillDetailsDocumentsViewAddDocumentsButton;
