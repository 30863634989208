import { FC, type PropsWithChildren } from "react";
import { useCurrentBusinessMember } from "resources/business-members/queries/businessMemberQueryHooks";
import useBusiness from "resources/business/queries/useBusiness";
import getBusinessSafeDisplayName from "resources/business/utils/getBusinessSafeDisplayName";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useUserRoleMembershipDisplayName from "resources/user-roles/queries/useUserRoleMembershipDisplayName";
import colors from "styles/colors";
import Pill from "ui/data-display/Pill";
import UserAvatar from "ui/data-display/UserAvatar";
import Text from "ui/typography/Text";
import { SingleRenderedElement } from "utils/react-helpers/types";

type Props = {
  onClickAvatar: () => void;
};

const UserAvatarMenuDropdownHeader: FC<Props> = ({ onClickAvatar }) => {
  const business = useBusiness();
  const businessMember = useCurrentBusinessMember();
  const userRoleMembershipDisplayName = useUserRoleMembershipDisplayName(
    business.guid,
    useUserGuid()
  );

  const businessDisplayName = getBusinessSafeDisplayName(business);
  const businessMemberDisplayName = businessMember?.displayName;
  const businessMemberInitials = businessMember?.initials ?? "";

  return (
    <div className="flex items-center justify-between gap-x-4 px-4 py-3">
      <div className="flex flex-col justify-center gap-y-0.5">
        <div className="flex items-center gap-x-3">
          <BusinessMemberDisplayName>{businessMemberDisplayName}</BusinessMemberDisplayName>
          {userRoleMembershipDisplayName && (
            <UserRoleMembershipDisplayName>
              {userRoleMembershipDisplayName}
            </UserRoleMembershipDisplayName>
          )}
        </div>
        <BusinessDisplayName>{businessDisplayName}</BusinessDisplayName>
      </div>

      <button onClick={onClickAvatar}>
        <UserAvatar color="purple-light" size={32} initials={businessMemberInitials} />
      </button>
    </div>
  );
};

export default UserAvatarMenuDropdownHeader;

const BusinessMemberDisplayName: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text size={14} weight="bold">
      {children}
    </Text>
  );
};

const UserRoleMembershipDisplayName: FC<{ children: SingleRenderedElement }> = ({ children }) => {
  return (
    <Pill size="2xs" color="grey-light" className="min-w-11">
      {children}
    </Pill>
  );
};

const BusinessDisplayName: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text size={12} weight="regular" color={colors.grey[600]}>
      {children}
    </Text>
  );
};
